<template>
  <v-container v-if="loaded">
    <v-toolbar
      border
      flat
    >
      <v-toolbar-title>
        <span
          @click="
            $refs.webpageDialog.open({
              name: webpage.name,
              slug: webpage.slug,
            })
          "
          class="pointer"
        >
          {{ webpage.name }}
        </span>
      </v-toolbar-title>

      <v-spacer />

      <v-btn-toggle
        v-model="mode"
        class="me-3"
        density="compact"
        rounded="pill"
        selected-class="bg-primary"
        variant="outlined"
        divided
        mandatory
      >
        <v-btn value="edit">
          <v-icon :color="mode === 'edit' ? 'white' : 'black'"> edit </v-icon>
        </v-btn>

        <v-btn value="view">
          <v-icon :color="mode === 'view' ? 'white' : 'black'"> preview </v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-btn-toggle
        v-model="size"
        class="me-3"
        density="compact"
        rounded="pill"
        selected-class="bg-primary"
        variant="outlined"
        divided
        mandatory
      >
        <v-btn value="lg">
          <v-icon :color="size === 'lg' ? 'white' : 'black'"> laptop </v-icon>
        </v-btn>

        <v-btn value="md">
          <v-icon :color="size === 'md' ? 'white' : 'black'"> tablet </v-icon>
        </v-btn>

        <v-btn value="sm">
          <v-icon :color="size === 'sm' ? 'white' : 'black'"> smartphone </v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-menu>
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            variant="flat"
            bordered
            rounded
          >
            {{ lang.toUpperCase() }}
            <v-icon end> expand_more </v-icon>
          </v-btn>
        </template>

        <v-list
          class="rounded"
          elevation="0"
          variant="outlined"
        >
          <v-list-item
            v-for="(language, index) in $store.state.languages"
            @click="setLang(language)"
            :key="index"
          >
            <v-list-item-title>
              {{ language.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu>
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="primary"
            size="x-large"
            variant="text"
            icon
          >
            <v-icon>add_circle_outline</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="addElement('text')">
            <v-list-item-title> Text </v-list-item-title>
          </v-list-item>

          <v-list-item @click="addElement('image')">
            <v-list-item-title> Image </v-list-item-title>
          </v-list-item>

          <v-list-item @click="addElement('button')">
            <v-list-item-title> Button </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        @click="save()"
        :loading="processing"
        class="me-3"
        color="primary"
      >
        Save
      </v-btn>

      <v-btn
        @click="publish"
        :loading="processing"
        class="me-3"
        color="green"
      >
        Publish
      </v-btn>

      <v-btn
        :to="{
          name: 'PublicWebpageShow',
          params: {
            webpageId: route.params.webpageId,
          },
          query: {
            lang: lang,
          },
        }"
        color="primary"
        target="_blank"
        variant="outlined"
      >
        Open
      </v-btn>
    </v-toolbar>

    <v-container
      v-show="size == 'lg'"
      class="my-8 rounded mxw-1200"
      data-testid="grid-lg"
    >
      <grid-layout
        v-model:layout="lgLayout"
        :auto-size="true"
        :col-num="36"
        :is-draggable="mode === 'edit'"
        :is-resizable="mode === 'edit'"
        :row-height="8"
      >
        <grid-item
          v-for="item in lgLayout"
          :key="item.i"
          :h="item.h"
          :i="item.i"
          :w="item.w"
          :x="item.x"
          :y="item.y"
          drag-allow-from=".draggable-handle"
          drag-ignore-from=".no-drag"
        >
          <PageElement
            v-model="elements[item.i]"
            @destroy="destroy(item.i)"
            :item="elements[item.i]"
            :lang="lang"
            :mode="mode"
          />
        </grid-item>
      </grid-layout>
    </v-container>

    <v-container
      v-show="size == 'md'"
      class="my-8 rounded mxw-800"
    >
      <grid-layout
        v-model:layout="mdLayout"
        :auto-size="true"
        :col-num="36"
        :is-draggable="mode == 'edit'"
        :is-resizable="mode == 'edit'"
        :row-height="8"
      >
        <grid-item
          v-for="item in mdLayout"
          :key="item.i"
          :h="item.h"
          :i="item.i"
          :w="item.w"
          :x="item.x"
          :y="item.y"
          drag-allow-from=".draggable-handle"
          drag-ignore-from=".no-drag"
        >
          <PageElement
            v-model="elements[item.i]"
            @destroy="destroy(item.i)"
            :item="elements[item.i]"
            :lang="lang"
            :mode="mode"
          />
        </grid-item>
      </grid-layout>
    </v-container>

    <v-container
      v-show="size == 'sm'"
      class="my-8 rounded mxw-440 py-2"
    >
      <grid-layout
        v-model:layout="smLayout"
        :auto-size="true"
        :col-num="36"
        :is-draggable="mode == 'edit'"
        :is-resizable="mode == 'edit'"
        :row-height="8"
      >
        <grid-item
          v-for="item in smLayout"
          :key="item.i"
          :h="item.h"
          :i="item.i"
          :w="item.w"
          :x="item.x"
          :y="item.y"
          drag-allow-from=".draggable-handle"
          drag-ignore-from=".no-drag"
        >
          <PageElement
            v-model="elements[item.i]"
            @destroy="destroy(item.i)"
            :item="elements[item.i]"
            :lang="lang"
            :mode="mode"
          />
        </grid-item>
      </grid-layout>
    </v-container>

    <ResourceDialog
      @save="save"
      ref="webpageDialog"
      :fields="[
        { text: 'Name', value: 'name' },
        { text: 'Slug', value: 'slug' },
      ]"
      :processing="processing"
      title="Webpage"
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import PageElement from '@/shared/components/PageElement.vue';
import { v4 as uuidv4 } from 'uuid';
import baseLocales from '@/shared/assets/locale.json';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { GridLayout, GridItem } from 'grid-layout-plus';

const eventBus = useEventBus();
const route = useRoute();
const { setLocaleMessage } = useI18n();
const webpageDialog = ref(null);

const elements = reactive({});
const lang = ref('en');
const lgLayout = ref([]);
const loaded = ref(false);
const mdLayout = ref([]);
const mode = ref('edit');
const processing = ref(false);
const smLayout = ref([]);
const size = ref('lg');
const webpage = reactive({});

function addElement(type) {
  const h = type === 'button' ? 3 : 10;
  const id = uuidv4();
  const w = 12;
  const x = 0;

  elements[id] = { i: id, type, url: null, outlined: false, text: { en: null, es: null } };

  lgLayout.value.push({ i: id, x, y: nextOpen(lgLayout), w, h });
  mdLayout.value.push({ i: id, x, y: nextOpen(mdLayout), w, h });
  smLayout.value.push({ i: id, x, y: nextOpen(smLayout), w, h });
}

function findIndex(layout, elementId) {
  return layout.findIndex((index) => index.i === elementId);
}

function destroy(elementId) {
  lgLayout.value.splice(findIndex(lgLayout.value, elementId), 1);
  mdLayout.value.splice(findIndex(mdLayout.value, elementId), 1);
  smLayout.value.splice(findIndex(smLayout.value, elementId), 1);
}

async function load() {
  const { data } = await Api.organization.webpage.get(route.params.webpageId);
  Object.assign(webpage, data);
  lgLayout.value = webpage.draft_layout.lg || [];
  mdLayout.value = webpage.draft_layout.md || [];
  smLayout.value = webpage.draft_layout.sm || [];
  const newElements = {};

  webpage.draft_elements.forEach((draftElement) => {
    newElements[draftElement.i] = draftElement;
  }, this);

  Object.assign(elements, newElements);
  loaded.value = true;
}

function nextOpen(elements) {
  if (elements.length > 0) {
    // eslint-disable-next-line prefer-spread
    return (
      Math.max.apply(
        Math,
        elements.map((element) => element.y),
      ) + 1
    );
  }

  return 0;
}

async function publish() {
  webpage.publish = true;
  await save();
}

async function save(vals = null) {
  if (vals) {
    webpage.name = vals.name;
    webpage.slug = vals.slug;
  }

  processing.value = true;
  webpage.draft_layout = {
    lg: lgLayout,
    md: mdLayout,
    sm: smLayout,
  };
  webpage.draft_elements = Object.values(elements);

  const resp = await Api.organization.webpage.update(webpage.id, webpage);
  processing.value = false;
  if (resp.status !== 200) return;

  webpageDialog.value.close();
  eventBus.chime('Saved');
}

async function setLang(language) {
  const locale = language.value;
  const translationKeyValueData = await Api.translations.index(locale);

  setLocaleMessage(locale, {
    ...baseLocales[locale],
    ...translationKeyValueData,
  });

  lang.value = locale;
}

onMounted(load);
</script>

<style>
button[bordered] {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.v-btn__content {
  white-space: pre-wrap;
}
</style>
