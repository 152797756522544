export default {
  fields(def) {
    let keys = JSON.parse(JSON.stringify(Object.keys(def.properties)));
    if (def.properties.custom) {
      keys.splice(keys.indexOf('custom'), 1);
      const custom = Object.keys(def.properties.custom.properties).map((key) => `custom.${key}`);
      keys = keys.concat(custom);
    }
    if (def.properties.sources) {
      keys.splice(keys.indexOf('sources'), 1);
    }
    return keys;
  },

  fieldToProperty(field, def) {
    const fieldParts = field.split('.');
    if (fieldParts.length === 1) {
      return def.properties[fieldParts[0]];
    }
    if (fieldParts[0] === 'custom') {
      return def.properties.custom.properties[fieldParts[1]];
    }
    throw 'Unknown field type';
  },
};
