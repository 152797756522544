<template>
  <v-card
    class="fill-height"
    elevation="2"
    tile
  >
    <v-card-title class="bb-1 bc-very-light-grey fs-16 fw-600 pa-5 d-flex">
      <span v-t="'Messages'" />
      <v-spacer />
      <v-btn
        @click="$emit('close')"
        icon="close"
        variant="text"
      />
    </v-card-title>

    <v-card-text class="pt-4">
      <div
        v-for="message in messages"
        :key="message.id"
        class="mb-3"
      >
        <div
          :class="
            message.member_id == currentMemberId
              ? 'c-white bg-dark-blue'
              : 'c-black bg-super-light-blue'
          "
          class="pa-3 w-90pc b-radius-8"
        >
          <MarkdownContent
            :content="message.text"
            class="fs-16 fw-500 message-container"
          />
        </div>
        <div
          v-text="messageAuthor(message)"
          class="fs-12 c-light-black"
        />
      </div>

      <v-divider class="my-6" />

      <v-textarea
        v-model="newMessageText"
        variant="filled"
      />
      <v-btn
        @click="createMessage"
        :disabled="processing"
        color="primary"
        block
      >
        Send message
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    MarkdownContent,
  },

  props: {
    grant: {
      type: Object,
      default: null,
    },
  },

  emits: ['close'],

  data() {
    return {
      currentMemberId: null,
      messages: [],
      newMessageText: null,
      processing: null,
    };
  },
  created() {
    this.currentMemberId = this.$store.state.profile.id;
    this.loadMessages();
  },

  methods: {
    createMessage() {
      this.processing = true;
      Api.organization.message.create(
        { business_id: this.grant.business_id, text: this.newMessageText },
        (resp) => {
          this.newMessageText = null;
          this.messages.push(resp.data);
          this.processing = false;
        },
        (err) => {
          this.$eventBus.$emit('error', err);
          this.processing = false;
        },
      );
    },

    loadMessages() {
      Api.organization.message.index({ business_id: this.grant.business_id }, (resp) => {
        this.messages = resp.data.reverse();
      });
    },

    messageAuthor(message) {
      if (message.meta.member_is_specialist) {
        return [message.meta.member_name, this.$store.state.brand.organization_name].join(' @ ');
      }
      return message.meta.member_name;
    },
  },
};
</script>
