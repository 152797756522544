<template>
  <v-card
    v-if="group"
    id="activity"
    border
    flat
    tile
  >
    <v-card-title>
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col>
          {{ $t('Activity') }}
        </v-col>

        <v-col
          v-if="$store.state.profile.org_groups_edit"
          class="d-flex justify-end"
        >
          <v-btn
            @click="$refs.groupActivityDialog.open(group)"
            color="primary"
            prepend-icon="add"
            variant="flat"
          >
            {{ $t('New activity') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />

    <v-card-text>
      <v-data-table
        @click:row="editActivity"
        :headers="tableHeaders"
        :hide-default-footer="activities.length == 0"
        :hide-default-header="activities.length == 0"
        :items="activities"
        item-key="id"
        disable-sort
        fixed-header
      >
        <template #no-data>
          <NullState
            class="my-4"
            title="No activities logged yet"
          />
        </template>
      </v-data-table>
    </v-card-text>

    <v-navigation-drawer
      :model-value="selectedActivity"
      :scrim="false"
      attach="#tabs"
      location="right"
      width="400px"
      disable-resize-watcher
      disable-route-watcher
    >
      <template v-if="selectedActivity">
        <v-card
          elevation="0"
          border
          flat
          tile
        >
          <v-card-text>
            <v-row>
              <v-col cols="10">
                <div
                  v-text="selectedActivity.name"
                  class="c-black bc-h6"
                />
              </v-col>
              <v-col
                class="ta-right"
                cols="2"
              >
                <v-btn
                  @click="selectedActivity = null"
                  variant="text"
                  icon
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row
              class="mb-3"
              dense
            >
              <v-col
                class="c-light-black bc-body"
                cols="12"
              >
                {{ $t('Date of activity') }}
              </v-col>
              <v-col
                class="c-black fs-16 fw-500"
                cols="12"
              >
                {{ selectedActivity.activity_date_short }}
              </v-col>
            </v-row>

            <template v-if="selectedActivity.group">
              <v-row class="mb-3">
                <v-col cols="12">
                  <div class="c-light-black bc-body">
                    {{ $t(`Related ${terms.group}`) }}
                  </div>
                  <div
                    @click="
                      $router.push({
                        name: 'GroupShow',
                        params: { groupId: selectedActivity.group.id },
                      })
                    "
                    v-text="selectedActivity.group.name"
                    class="c-primary bc-body bc-link-hover"
                  />
                </v-col>
              </v-row>
            </template>

            <v-row
              class="mb-3"
              dense
            >
              <v-col
                class="c-light-black bc-body"
                cols="12"
              >
                {{ $t('Notes') }}
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="selectedActivity.notes"
                  placeholder="Activity notes"
                  variant="outlined"
                  hide-details
                />
              </v-col>
            </v-row>

            <v-row
              class="mb-3"
              dense
            >
              <v-col
                class="c-light-black bc-body"
                cols="12"
              >
                {{ $t('Attachments') }}
              </v-col>
              <v-col
                v-for="(file, index) in selectedActivity.files"
                :key="index"
                cols="12"
              >
                <div
                  @click="downloadFile(file)"
                  v-text="file.name"
                  class="fs-15 c-primary underlined pointer"
                />
              </v-col>
            </v-row>

            <template v-if="$store.state.profile.org_groups_edit">
              <v-row>
                <v-col>
                  <v-btn
                    @click="updateActivity(selectedActivity)"
                    class="me-3"
                    color="primary"
                    variant="outlined"
                  >
                    {{ $t('Save') }}
                  </v-btn>
                </v-col>
                <v-col class="ta-right">
                  <v-btn
                    @click="destroyActivity(selectedActivity)"
                    class="me-3"
                    color="red"
                    variant="text"
                  >
                    {{ $t('Delete activity') }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </template>
    </v-navigation-drawer>

    <NewGroupActivityDialog
      v-if="group"
      @create="loadActivities()"
      ref="groupActivityDialog"
      :group="group"
    />
  </v-card>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import NewGroupActivityDialog from '@/specialist/components/group/NewGroupActivityDialog.vue';
import NullState from '@/shared/components/NullState.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import useTerms from '@/shared/composables/useTerms';

const eventBus = useEventBus();
const route = useRoute();
const { terms } = useTerms();
const { t } = useI18n();

const activities = ref([]);
const group = ref(null);
const selectedActivity = ref(null);

const tableHeaders = computed(() => {
  return [
    { title: t('Type of activity'), value: 'name' },
    { title: t('Date'), value: 'activity_date_short' },
    { title: t('Created by'), value: 'created_by' },
  ];
});

watch(
  () => route.params.groupId,
  () => {
    loadGroup();
    loadActivities();
  },
  { immediate: true },
);

function editActivity(event, { item: activity }) {
  selectedActivity.value = activity;
}

function destroyActivity(activity) {
  // eslint-disable-next-line no-alert
  if (!confirm('Are you sure you want to delete this activity?')) return;

  api.organization.group.group_activity.destroy(route.params.groupId, activity.id, () => {
    selectedActivity.value = null;
    eventBus.chime('Activity deleted');
    loadActivities();
  });
}

function loadActivities() {
  api.organization.group.group_activity.index(route.params.groupId, (resp) => {
    activities.value = resp.data;
  });
}

function loadGroup() {
  api.organization.group.get(route.params.groupId, (resp) => {
    group.value = resp.data;
  });
}

function downloadFile(file) {
  api.organization.group.group_activity.file.download(
    route.params.groupId,
    selectedActivity.value.id,
    file.id,
    file.name,
  );
}

function updateActivity() {
  api.organization.group.group_activity.update(
    route.params.groupId,
    selectedActivity.value.id,
    selectedActivity.value,
    () => {
      loadActivities();
      selectedActivity.value = null;
      eventBus.chime('Activity saved');
    },
    (err) => {
      eventBus.error(err);
    },
  );
}
</script>

<style>
#activity {
  .v-data-table-header__content {
    font-weight: 800;
  }
}
</style>
