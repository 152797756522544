<template>
  <v-dialog
    v-model="visible"
    max-width="500"
    scrim="transparent"
    scrollable
  >
    <v-card
      v-if="owner"
      border
      flat
      tile
    >
      <v-card-title class="d-flex">
        <span>{{ $t('Add/remove labels') }}</span>
        <v-spacer />
        <v-btn
          @click="close()"
          :aria-label="$t('Close')"
          icon="close"
          variant="text"
        />
      </v-card-title>
      <v-divider class="mb-2" />
      <v-card-text>
        <v-text-field
          v-if="displaySearch"
          v-model="labelSearchVal"
          id="label_search"
          :aria-label="$t('Filter labels by name')"
          :placeholder="$t('Filter labels by name')"
          color="primary"
          density="compact"
          prepend-inner-icon="search"
          variant="filled"
          hide-details
          tracked
        />

        <v-list
          v-model:selected="owner.relationships.labels.data"
          density="compact"
          select-strategy="independent"
        >
          <v-list-item
            v-for="label in filteredAppliedLabels"
            @click="toggleLabel(label)"
            :key="['applied', label.id].join('-')"
            :title="$t(label.name)"
            :value="label"
          >
            <template #prepend="{ isSelected }">
              <v-checkbox-btn
                :model-value="isSelected"
                :ripple="false"
                density="compact"
                tabindex="-1"
              />
            </template>
            <template #append>
              <v-avatar
                :color="label.color || 'grey'"
                size="20"
                dense
              />
            </template>
          </v-list-item>

          <v-divider
            v-if="filteredAppliedLabels?.length > 0 && filteredAvailableProgramLabels?.length > 0"
          />

          <v-list-item
            v-for="label in filteredAvailableProgramLabels"
            @click="toggleLabel(label)"
            :key="['avail', label.id].join('-')"
            :title="$t(label.name)"
            :value="label"
          >
            <template #prepend="{ isSelected }">
              <v-checkbox-btn
                :model-value="isSelected"
                :ripple="false"
                density="compact"
                tabindex="-1"
              />
            </template>
            <template #append>
              <v-avatar
                :color="label.color || 'grey'"
                size="20"
                dense
              />
            </template>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider class="my-2" />
      <v-card-actions class="bg-white">
        <v-spacer />
        <v-btn
          @click="close()"
          :disabled="processing"
          color="primary"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import API from '@/shared/mixins/api';
const LABEL_SEARCH_DISPLAY_COUNT_MIN = 10;

export default {
  compatConfig: { MODE: 2 },

  mixins: [API],

  props: {
    labelOwnerType: {
      type: String,
      default: null,
    },
    programLabels: {
      type: Array,
      default: null,
    },
  },

  emits: ['change', 'close'],

  data() {
    return {
      labelSearchVal: null,
      processing: false,
      owner: null,
      visible: false,
    };
  },

  computed: {
    displaySearch() {
      return this.programLabels.length > LABEL_SEARCH_DISPLAY_COUNT_MIN;
    },

    filteredAppliedLabels() {
      const appliedLabels = this.owner.relationships.labels.data;

      if (!this.displaySearch || !this.labelSearchVal) return appliedLabels;
      return appliedLabels.filter((label) =>
        label.name.toLowerCase().includes(this.labelSearchVal.toLowerCase()),
      );
    },

    filteredAvailableProgramLabels() {
      const appliedLabelIds = this.filteredAppliedLabels.map((label) => label.id);
      if (!this.displaySearch || !this.labelSearchVal) {
        return this.programLabels.filter((label) => !appliedLabelIds.includes(label.id));
      }

      return this.programLabels.filter(
        (label) =>
          !appliedLabelIds.includes(label.id) &&
          label.name.toLowerCase().includes(this.labelSearchVal.toLowerCase()),
      );
    },
  },

  methods: {
    async open(owner) {
      this.setOwnerInfo();
      const resp = await this.ownerInfo.repository.get(owner.id);
      this.$emit('change', resp.data);
      this.owner = resp.data;
      this.visible = true;
    },

    close() {
      this.$emit('close');
      this.labelSearchVal = null;
      this.owner = null;
      this.visible = false;
    },

    setOwnerInfo() {
      // eslint-disable-next-line default-case
      switch (this.labelOwnerType) {
        case 'Course':
          this.ownerInfo = {
            assignmentField: 'course_id',
            repository: this.api.organization.course,
          };
          break;
        case 'Form':
          this.ownerInfo = {
            assignmentField: 'form_id',
            repository: this.api.organization.form,
          };
          break;
        case 'Grant':
          this.ownerInfo = {
            assignmentField: 'grant_id',
            repository: this.api.organization.grant,
          };
          break;
        case 'Subsidy':
          this.ownerInfo = {
            assignmentField: 'subsidy_id',
            repository: this.api.organization.subsidy,
          };
          break;
        case 'FamilySubsidy':
          this.ownerInfo = {
            assignmentField: 'family_subsidy_id',
            repository: this.api.organization.family_subsidy,
          };
          break;
      }
    },

    async toggleLabel(label) {
      this.processing = true;
      await this.$nextTick();
      const adding = this.owner.relationships.labels.data.includes(label);

      if (adding) {
        await this.api.organization.label_assignment.create({
          label_id: label.id,
          [this.ownerInfo.assignmentField]: this.owner.id,
        });
      } else {
        // TO-DO : Determine if race condition of deleting an assignment that has already been deleted is
        //         acceptable (currently reloads view for user - somewhat disruptive)
        await this.api.organization.label_assignment.destroy({
          [this.ownerInfo.assignmentField]: this.owner.id,
          label_id: label.id,
        });
      }

      const resp = await this.ownerInfo.repository.get(this.owner.id);

      this.$set(this.owner.relationships.labels, 'data', resp.data.relationships.labels.data);
      this.$emit('change', resp.data);
      this.processing = false;
    },
  },
};
</script>
