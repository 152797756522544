<template>
  <div v-if="grantProgram">
    <BreadcrumbTitle
      :back-back-name="grantProgram.name"
      back-name="Messaging"
      name="Event alerts"
    />

    <EventAlertConfigurations
      v-if="!processing"
      :owner="grantProgram"
      owner-type="GrantProgram"
    />
  </div>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import EventAlertConfigurations from '@/admin/components/event-alert-configurations/EventAlertConfigurations.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const processing = ref(true);
const grantProgram = ref(null);

onMounted(async () => {
  loadGrantProgram();
});

function loadGrantProgram() {
  api.organization.grant_program.get(route.params.grantProgramId, (resp) => {
    grantProgram.value = resp.data;
    processing.value = false;
  });
}
</script>
