<template>
  <v-list-item
    :class="{ rail: rail }"
    :to="{ name: route, params: params, query: query }"
    active-class="bg-white c-primary fw-600"
  >
    <v-list-item-title class="d-flex align-center">
      <v-icon
        v-if="icon"
        :class="{ 'me-3': !rail }"
        :icon="icon"
        active-class="c-primary"
        class="material-icons-outlined"
        size="22"
      />

      <span
        v-if="!rail"
        class="fs-16 fw-500 hover-td-none"
      >
        {{ $t(title) }}
      </span>
    </v-list-item-title>
  </v-list-item>
</template>

<script setup>
import vuetify from '@/plugins/vuetify';
import { useStore } from 'vuex';

const store = useStore();

defineProps({
  icon: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
  params: {
    type: Object,
    default: undefined,
  },
  query: {
    type: Object,
    default: undefined,
  },
  route: {
    type: String,
    default: null,
  },
});

const rail = computed(() => {
  return store.state.miniNav && !vuetify.display.mobile.value;
});
</script>
