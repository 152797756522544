<template>
  <td
    class="bb-1 br-1 bc-extra-light-grey mnw-200 pa-2 fs-14 focus-visible"
    tabindex="0"
  >
    <render />
  </td>
</template>

<script setup>
import { h } from 'vue';
import { RouterLink } from 'vue-router';

const props = defineProps({
  column: {
    type: Object,
    required: true,
  },
  row: {
    type: Object,
    required: true,
  },
});

function render() {
  if (props.column.type === 'boolean') {
    if (props.row[props.column.key] === null || props.row[props.column.key] === undefined) {
      return '';
    }
    return props.row[props.column.key] ? 'true' : 'false';
  }

  switch (props.column.data_type) {
    case 'Subsidy':
      return renderSubsidyReportField();
    default:
      return props.row[props.column.key];
  }
}

function renderSubsidyReportField() {
  switch (props.column.field) {
    case 'id':
      return renderSubsidyShowLink(props.row[props.column.key]);
    default:
      return props.row[props.column.key];
  }
}

function renderSubsidyShowLink(subsidyId, content = null) {
  return h(
    RouterLink,
    {
      to: {
        name: 'SubsidyShow',
        params: {
          id: subsidyId,
        },
      },
      target: '_blank',
    },
    () => content || subsidyId,
  );
}
</script>
