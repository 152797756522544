<template>
  <v-card
    class="mb-4"
    border
    flat
    tile
  >
    <v-card-title class="tab-title d-flex">
      <v-row>
        <v-col
          class="d-flex align-center"
          cols="4"
        >
          <span v-t="title" />
        </v-col>
        <v-col>
          <div class="d-flex justify-end">
            <v-select
              @update:model-value="updateQuery({ subsidyProgramId: $event })"
              :items="subsidyPrograms"
              :model-value="$route.query.subsidyProgramId"
              class="me-3 mxw-350"
              density="compact"
              item-title="name"
              item-value="id"
              variant="filled"
              hide-details
            />
            <v-text-field
              v-model="query"
              :placeholder="$t('Search')"
              class="mxw-250"
              density="compact"
              prepend-inner-icon="search"
              variant="solo-filled"
              flat
              hide-details
              rounded
            />
          </div>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider class="mb-4" />

    <v-card-text v-if="enrollments">
      <v-row
        v-if="dashboard"
        class="d-flex justify-space-between fs-18 mb-1"
      >
        <v-col
          v-for="group in dashboard.enrollment_status_groups"
          :key="group.status"
        >
          <v-row dense>
            <v-col class="d-flex justify-center">{{ $t(getStatusText(group.status)) }}</v-col>
          </v-row>
          <v-row dense>
            <v-col class="c-primary d-flex justify-center fs-20 pointer underlined">
              <template v-if="group.status == 'Enrolled'">
                <span
                  @click="updateQuery({ tab: 'students', status: null })"
                  class="px-3"
                >
                  {{ group.count || 0 }}
                </span>
              </template>
              <template v-else>
                <span
                  @click="updateQuery({ tab: 'interested', status: group.status })"
                  class="px-3"
                >
                  {{ group.count || 0 }}
                </span>
              </template>
            </v-col>
          </v-row>
        </v-col>
        <!-- TODO: The below does not render due to index being outside of the v-for scope : no quick fix found -->
        <!-- <v-divider :key="index" vertical v-if="index < dashboard.enrollment_status_groups.length - 1"></v-divider> -->
      </v-row>

      <v-row class="d-flex align-center mb-2">
        <v-col
          class="d-flex align-center"
          cols="12"
          md="6"
        >
          <template v-if="pages > 1">
            <v-pagination
              v-model="page"
              :length="pages"
              :total-visible="8"
              class="d-inline-block"
            />
          </template>
        </v-col>

        <v-col
          class="d-flex justify-end px-6"
          cols="12"
          md="6"
        >
          <FilterMenu
            :actions="false"
            :active="!!$route.query.status"
            :title="$route.query.status || 'Status'"
            color="white"
            left
          >
            <template #card>
              <v-radio-group
                @update:model-value="updateQuery({ status: $event })"
                :model-value="$route.query.status"
                class="mt-0"
                hide-details
              >
                <v-radio
                  v-for="status in allStatuses"
                  :key="status.value"
                  :label="$t(status.text)"
                  :value="status.value"
                  class="c-black mt-0"
                  color="primary"
                  hide-details
                />
              </v-radio-group>
            </template>
          </FilterMenu>
        </v-col>
      </v-row>

      <v-card
        border
        flat
        tile
      >
        <template v-if="$vuetify.display.smAndUp">
          <v-card
            class="bb-1 pa-0"
            flat
            tile
          >
            <v-card-text>
              <v-row>
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :md="waitlist ? '2' : '4'"
                  :sort-dir="filters.sort_dir"
                  :sort-field="filters.sort_field"
                  field="name"
                  title="Child's name"
                />
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="filters.sort_dir"
                  :sort-field="filters.sort_field"
                  field="dob"
                  md="2"
                  title="DOB"
                />
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="filters.sort_dir"
                  :sort-field="filters.sort_field"
                  field="address"
                  md="2"
                  title="Home address"
                />
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="filters.sort_dir"
                  :sort-field="filters.sort_field"
                  field="status"
                  md="2"
                  title="Status"
                />
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="filters.sort_dir"
                  :sort-field="filters.sort_field"
                  field="status_updated_at"
                  md="2"
                  title="Status Updated"
                />
                <template v-if="waitlist">
                  <SortableTableHeader
                    @toggle="toggleSort($event)"
                    :sort-dir="filters.sort_dir"
                    :sort-field="filters.sort_field"
                    field="waitlist"
                    md="2"
                    title="Waitlist #"
                  />
                </template>
              </v-row>
            </v-card-text>
          </v-card>

          <v-divider />
        </template>

        <v-card
          flat
          tile
        >
          <v-card-text class="fs-15">
            <v-row dense>
              <v-col class="d-flex align-center">
                <template v-if="filterSummary">
                  <v-icon start> filter_alt </v-icon>
                  <span
                    v-t="'Filtering by:'"
                    class="me-1"
                  />
                  <span class="fw-600">{{ $t(filterSummary) }}</span>
                  <span>.&nbsp;</span>
                </template>
                <span>
                  {{ $t('Displaying first') }} <strong>{{ enrollments.length }}</strong>
                  {{ $t('results') }}, {{ $t('out of ') }} <strong>{{ total }}</strong>
                  {{ $t('total results') }}.
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider />

        <NullState
          v-if="enrollments.length == 0"
          class="mt-8"
          hide-new-button
        />

        <div
          v-for="(er, index) in enrollments"
          :key="er.id"
        >
          <EnrollmentRequestRow
            :er="er"
            :waitlist="waitlist"
            hide-selector
          />
          <v-divider v-if="index < enrollments.length - 1" />
        </div>

        <template v-if="pages > 1">
          <v-divider />
          <v-pagination
            v-model="page"
            :length="pages"
            :total-visible="8"
            class="my-2"
          />
        </template>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { scrollTo } from 'vuetify/lib/composables/goto';
import API from '@/shared/mixins/api';
import EnrollmentRequestRow from '@/specialist/components/enrollments/EnrollmentRequestRow.vue';
import RouterHelper from '@/shared/mixins/RouterHelper';
import SortableTableHeader from '@/shared/components/SortableTableHeader.vue';
import Statuses from '@/shared/mixins/statuses';

export default {
  compatConfig: { MODE: 3 },

  components: {
    EnrollmentRequestRow,
    SortableTableHeader,
  },
  mixins: [API, RouterHelper, Statuses],

  props: {
    title: {
      type: String,
      default: null,
    },
    waitlist: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      dashboard: null,
      enrollments: null,
      filters: {
        provider_id: this.$route.query.providerId,
        sort_dir: 'asc',
        sort_field: 'waitlist',
      },
      page: 1,
      pages: null,
      processing: false,
      subsidyPrograms: [],
      query: null,
      queryCount: 0,
    };
  },

  computed: {
    filterSummary() {
      const appliedFilters = [];
      if (this.$route.query.status) appliedFilters.push(this.$route.query.status);
      if (this.subsidyProgramFilter) appliedFilters.push(this.subsidyProgramFilter);
      return appliedFilters.length > 0 ? appliedFilters.join(', ') : null;
    },

    subsidyProgramFilter() {
      if (this.subsidyPrograms.length > 0) {
        return this.subsidyPrograms.find((sp) => sp.id === this.$route.query.subsidyProgramId)
          ?.name;
      }
      return null;
    },
  },

  watch: {
    page() {
      this.loadEnrollments();
      scrollTo(0);
    },

    query(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.page = 1;
        this.loadEnrollments();
      }
    },

    '$route.query.status': {
      immediate: true,
      deep: true,
      handler() {
        this.page = 1;
        this.loadEnrollments();
      },
    },

    '$route.query.subsidyProgramId': {
      immediate: true,
      handler() {
        this.page = 1;
        this.loadEnrollments();
        this.loadDashboard();
      },
    },
  },

  created() {
    this.load();
  },

  methods: {
    clearAndReload() {
      this.$refs.detailsDialog.close();
      this.loadEnrollments();
    },

    loadEnrollments() {
      this.processing = true;
      this.filters.provider_id = this.$route.params.providerId;
      this.filters.page = this.page;
      this.filters.query = this.query;
      this.filters.status = this.$route.query.status;
      this.filters.subsidy_program_id = this.$route.query.subsidyProgramId;
      this.queryCount += 1;
      const currentQueryCount = this.queryCount;
      const newFilters = { ...this.filters };
      if (!newFilters.status || newFilters.status.length === 0) {
        newFilters.status = this.pendingStatuses.map((status) => status.value);
      }
      this.api.organization.enrollment.index(newFilters, (resp) => {
        if (this.queryCount === currentQueryCount) {
          this.enrollments = resp.data.map((er) => ({ ...er, checked: false }));
          this.pages = parseInt(resp.headers['x-page-count'] || 0, 10);
          this.total = parseInt(resp.headers['x-total-count'] || 0, 10);
          this.processing = false;
        }
      });
    },

    async loadDashboard() {
      if (!this.$route.query.subsidyProgramId) return;
      const filters = { subsidy_program_id: this.$route.query.subsidyProgramId };
      const resp = await this.api.organization.provider.dashboard.get(
        this.$route.params.providerId,
        filters,
      );
      this.dashboard = resp.data;
    },

    async load() {
      const resp = await this.api.organization.subsidy_program.index();
      this.subsidyPrograms = resp.data;
      if (this.$route.query.subsidyProgramId) {
        this.loadDashboard();
        this.loadEnrollments();
      } else {
        await this.updateQuery({ subsidyProgramId: this.subsidyPrograms[0]?.id });
      }
    },

    toggleSort(field) {
      if (this.filters.sort_field !== field) {
        this.filters.sort_dir = 'desc';
      } else {
        this.filters.sort_dir = this.filters.sort_dir === 'asc' ? 'desc' : 'asc';
      }
      this.filters.sort_field = field;
      this.loadEnrollments();
    },
  },
};
</script>
