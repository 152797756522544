<template>
  <v-expansion-panel>
    <v-expansion-panel-title
      :disabled="counted"
      class="bb-1 bc-very-light-grey pb-1 pt-1"
      disable-icon-rotate
    >
      <div class="d-flex align-center px-3">
        <v-icon
          color="primary"
          size="22"
          start
        >
          {{ counted ? 'numbers' : 'expand_more' }}
        </v-icon>
        <span
          v-text="displayName"
          class="fs-16 fw-500"
        />
      </div>
      <template #actions>
        <template v-if="added">
          <template v-if="join.required === false">
            <v-btn
              @click.stop="$emit('change:required')"
              :disabled="report.locked"
              :loading="processing"
              class="me-3"
              color="primary"
              size="small"
              variant="text"
              icon
            >
              <v-icon color="primary"> join_full </v-icon>
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              @click.stop="$emit('change:optional')"
              :aria-label="'Join' + dataType + ' table'"
              :disabled="report.locked"
              :loading="processing"
              class="me-3"
              size="small"
              variant="text"
              icon
            >
              <v-icon color="primary"> join_inner </v-icon>
            </v-btn>
          </template>
          <v-divider
            class="me-3"
            vertical
          />
          <v-btn
            @click.stop="remove()"
            :aria-label="'Join ' + dataType + ' table'"
            :disabled="report.locked"
            :loading="processing"
            class="me-3"
            size="small"
            variant="text"
            icon
          >
            <v-icon color="primary"> check_box </v-icon>
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            @click.stop="draftJoin"
            :aria-label="'Join ' + dataType + ' table'"
            :disabled="report.locked || counted"
            :loading="processing"
            class="me-3"
            size="small"
            variant="text"
            icon
          >
            <v-icon color="primary"> check_box_outline_blank </v-icon>
          </v-btn>
        </template>
      </template>
    </v-expansion-panel-title>

    <v-expansion-panel-text>
      <p
        v-if="!added"
        v-t="'Add this table to your report to view available fields.'"
        class="pt-4 ps-4 pb-0 my-0 fs-14"
      />
      <v-list
        class="pt-0 mt-0"
        density="compact"
      >
        <v-list-item
          v-for="field in report.valid_fields[dataType]"
          :key="field.key"
          class="px-3"
        >
          <template #prepend>
            <v-checkbox-btn
              @update:model-value="
                $emit('column', {
                  field: field,
                  dataType: dataType,
                  newVal: $event,
                  schemaId: schemaId,
                })
              "
              :disabled="locked"
              :model-value="
                report.columns.find(
                  (column) =>
                    column.field == field.value && column.data_type == dataType && !column.list,
                )
              "
              :value="
                report.columns.find(
                  (column) =>
                    column.field == field.value && column.data_type == dataType && !column.list,
                )
              "
            />
          </template>
          <v-list-item-title class="fs-15">
            {{ field.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-expansion-panel-text>

    <ResourceDialog
      @save="addJoin"
      ref="schemaDialog"
      :fields="draftSchemaFields"
      title="Join table"
    />
  </v-expansion-panel>
</template>

<script>
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    ResourceDialog,
  },

  props: {
    processing: Boolean,
    report: {
      type: Object,
      default: null,
    },
    dataType: {
      type: String,
      default: null,
    },
    displayName: {
      type: String,
      default: null,
    },
    locked: Boolean,
    reportTypes: {
      type: Array,
      default: null,
    },
    schemas: {
      type: Array,
      default: null,
    },
  },

  emits: ['add', 'change:optional', 'change:required', 'column', 'remove'],

  data() {
    return {
      dataTypes: this.reportTypes,
    };
  },

  computed: {
    added() {
      return !!this.join;
    },

    counted() {
      return this.report.columns
        .filter((column) => column.count)
        .some((column) => column.filters.some((filter) => filter.data_type === this.dataType));
    },

    draftSchemaFields() {
      return [
        {
          text: 'Select schema type',
          value: 'schema_id',
          items: this.validSchemas,
          itemText: 'name',
          itemValue: 'id',
        },
      ];
    },

    join() {
      return this.report.joins.find((join) => join.data_type === this.dataType);
    },

    schemaId() {
      return this.join?.schema_id;
    },

    schemaRequired() {
      return this.dataTypes.find((item) => item.value === this.dataType)?.schematized;
    },

    validSchemas() {
      let effectiveSchema;

      if (this.dataType === 'GroupData') {
        effectiveSchema = 'Group';
      } else if (this.dataType === 'ChildData') {
        effectiveSchema = 'Child';
      } else {
        effectiveSchema = this.dataType;
      }

      return this.schemas.filter((schema) => schema.data_type === effectiveSchema && !!schema.id);
    },
  },

  methods: {
    addJoin(newVal) {
      this.$emit('add', newVal.schema_id);
      this.$refs.schemaDialog.close();
    },

    draftJoin() {
      if (this.schemaRequired) {
        this.$refs.schemaDialog.open({});
      } else {
        this.$emit('add');
      }
    },

    remove() {
      // eslint-disable-next-line no-alert
      if (confirm('Are you sure you want to remove this table from the query?')) {
        this.$emit('remove');
      }
    },
  },
};
</script>
