<template>
  <div v-if="assessment && provider && schema">
    <v-app-bar
      :height="$vuetify.display.mdAndUp ? 60 : 120"
      color="white"
      scroll-behavior="hide inverted"
    >
      <v-row
        class="d-flex align-center px-4"
        dense
      >
        <v-col
          cols="12"
          sm="6"
        >
          <v-toolbar-title class="fs-16 fw-600 c-black">
            {{ $t(selectedSheet ? selectedSheet.name : assessment.provider_name) }}
          </v-toolbar-title>
        </v-col>

        <v-col
          class="d-flex align-center justify-end"
          cols="12"
          sm="6"
        >
          <v-btn-toggle
            v-model="rightBarTab"
            class="me-3"
            selected-class="bg-primary c-white"
            variant="outlined"
            divided
            tile
          >
            <v-btn
              :aria-label="$t('Display notes')"
              variant="outlined"
              icon
            >
              <v-icon
                :color="rightBarTab == 0 ? 'white' : 'black'"
                class="material-icons-outlined"
                icon="assignment"
                size="20"
              />
            </v-btn>

            <v-btn
              :aria-label="$t('Display documents')"
              variant="outlined"
              icon
            >
              <v-icon
                :color="rightBarTab == 1 ? 'white' : 'black'"
                class="material-icons-outlined"
                icon="folder_open"
                size="20"
              />
            </v-btn>

            <v-btn
              v-if="$store.state.pages.Messaging.enabled"
              :aria-label="$t('Display messages')"
              variant="outlined"
              icon
            >
              <v-icon
                :color="rightBarTab == 2 ? 'white' : 'black'"
                class="material-icons-outlined"
                icon="forum"
                size="20"
              />
            </v-btn>
          </v-btn-toggle>

          <template v-if="selectedSheet">
            <v-btn
              v-if="!!selectedSheet.completed_at"
              @click="completeWorksheet()"
              :loading="processing"
              class="bg-white me-3"
              color="primary"
              size="large"
              variant="outlined"
            >
              {{ $t('Mark incomplete') }}
            </v-btn>

            <v-btn
              v-else
              @click="completeWorksheet()"
              :disabled="worksheetIncomplete"
              :loading="processing"
              class="bg-white me-3"
              color="primary"
              size="large"
              variant="outlined"
            >
              {{ $t(worksheetIncomplete ? 'Worksheet incomplete' : 'Mark complete') }}
            </v-btn>

            <div class="ta-right">
              <v-btn
                @click="saveWorksheet()"
                :disabled="!!selectedSheet.completed_at"
                :loading="processing"
                color="primary"
                size="large"
                variant="flat"
              >
                {{ $t('Save') }}
              </v-btn>
            </div>
          </template>

          <template v-else>
            <template v-if="!!assessment.completed_at">
              <v-btn
                @click="complete()"
                :loading="processing"
                class="bg-white me-3"
                color="primary"
                size="large"
                variant="outlined"
              >
                {{ $t('Mark incomplete') }}
              </v-btn>
            </template>

            <template v-else>
              <v-btn
                @click="complete()"
                :disabled="changed || assessmentIncomplete"
                :loading="processing"
                class="bg-white me-3"
                color="primary"
                size="large"
                variant="outlined"
              >
                {{ $t(assessmentIncomplete ? 'Assessment incomplete' : 'Mark complete') }}
              </v-btn>
            </template>

            <div class="ta-right">
              <v-btn
                @click="save()"
                :disabled="!!assessment.completed_at"
                :loading="processing"
                color="primary"
                size="large"
                variant="flat"
              >
                {{ $t('Save') }}
              </v-btn>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-toolbar
      :height="$vuetify.display.mdAndUp ? 60 : 120"
      class="border-b"
      style="z-index: 1; left: var(--v-layout-left); right: 0; width: auto"
      absolute
    >
      <v-row
        class="d-flex align-center px-4"
        dense
      >
        <v-col
          class="d-flex align-center"
          cols="12"
          sm="6"
        >
          <v-btn
            :aria-label="$t('Back to Assessments index')"
            :to="{ name: 'AssessmentIndex' }"
            class="me-2"
            size="small"
            variant="text"
            icon
          >
            <v-icon
              class="c-black"
              icon="west"
              size="20"
            />
          </v-btn>

          <h1
            v-text="assessment.provider_name"
            class="fw-600 fs-16 me-1"
          />

          <ActionMenu
            :button-icon-size="18"
            :items="[
              {
                title: 'Download',
                event: 'download',
                href: downloadLink(),
              },
            ]"
            button-icon="more_vert"
            x-small
          />
        </v-col>

        <v-col
          class="d-flex align-center justify-end pa-0"
          cols="12"
          sm="6"
        >
          <v-btn-toggle
            v-model="rightBarTab"
            class="me-1"
            selected-class="bg-primary c-white"
            variant="outlined"
            divided
            tile
          >
            <v-btn
              :aria-label="$t('Display notes')"
              variant="outlined"
              icon
            >
              <v-icon
                :color="rightBarTab == 0 ? 'white' : 'black'"
                class="material-icons-outlined"
                icon="assignment"
                size="20"
              />
            </v-btn>

            <v-btn
              :aria-label="$t('Display documents')"
              variant="outlined"
              icon
            >
              <v-icon
                :color="rightBarTab == 1 ? 'white' : 'black'"
                class="material-icons-outlined"
                icon="folder_open"
                size="20"
              />
            </v-btn>

            <v-btn
              v-if="$store.state.pages.Messaging.enabled"
              :aria-label="$t('Display messages')"
              variant="outlined"
              icon
            >
              <v-icon
                :color="rightBarTab == 2 ? 'white' : 'black'"
                class="material-icons-outlined"
                icon="forum"
                size="20"
              />
            </v-btn>
          </v-btn-toggle>

          <v-menu
            v-if="schema.enable_stages"
            v-model="openStageDialog"
            :min-width="350"
            max-width="500"
            offset="5"
          >
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                color="primary"
                data-cy="stage-selector"
                prepend-icon="keyboard_arrow_down"
                size="large"
                variant="outlined"
                tile
              >
                {{ $t(currentStage.title) }}
              </v-btn>
            </template>

            <v-list
              v-model:selected="currentStageId"
              class="bc-light-gray b-1 py-0"
              tile
            >
              <template
                v-for="(stage, index) in schema.stages"
                :key="stage.id"
              >
                <v-list-item
                  :class="{
                    'bg-primary-extra-light':
                      assessment.stage_id == stage.id || currentStageIndex == index,
                  }"
                  :title="$t(stage.title)"
                  :value="stage.id"
                  lines="two"
                >
                  <template
                    v-if="index < schema.stages.length - 1"
                    #append
                  >
                    <template v-if="index < currentStageIndex">
                      <v-hover v-slot="{ hover }">
                        <v-btn
                          @click.stop="setStage(index)"
                          :loading="processing"
                          :ripple="false"
                          :variant="hover ? 'outlined' : undefined"
                          color="primary"
                          data-cy="set-stage-button"
                          icon="check"
                        />
                      </v-hover>
                    </template>

                    <template v-else>
                      <v-btn
                        v-if="assessment.stage_id === stage.id || currentStageIndex === index"
                        @click.stop="completeStage()"
                        :loading="processing"
                        class="ms-2"
                        color="primary"
                        data-cy="complete-stage-button"
                      >
                        {{ $t('Complete') }}
                      </v-btn>
                    </template>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < schema.stages.length - 1"
                  :key="index"
                />
              </template>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-navigation-drawer
      v-model="rightBarVisible"
      :scrim="false"
      :style="$vuetify.display.mdAndUp ? 'top: 61px' : 'top: 121px'"
      location="right"
      width="30%"
    >
      <v-card v-show="rightBarVisible">
        <v-card-text>
          <v-window
            v-model="rightBarTab"
            :mandatory="false"
          >
            <v-window-item value="notes">
              <div
                v-for="note in openNotes"
                :key="note.id"
                class="mb-3"
              >
                <div class="bg-super-light-blue pa-3 fs-16 fw-500">
                  <template v-if="note.isEditing">
                    <v-row dense>
                      <v-col>
                        <v-textarea
                          v-model="note.editedText"
                          :model-value="note.editedText"
                          variant="outlined"
                          hide-details
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col class="d-flex justify-space-between">
                        <v-btn
                          @click="toggleEditNote(note)"
                          :loading="processingNoteUpdate"
                          size="small"
                          variant="text"
                        >
                          {{ $t('Cancel') }}
                        </v-btn>

                        <v-btn
                          @click="saveNote(note)"
                          :disabled="note.editedText.length === 0"
                          :loading="processingNoteUpdate"
                          color="primary"
                          size="small"
                        >
                          {{ $t('Save') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>

                  <div
                    v-else
                    class="d-flex justify-space-between"
                  >
                    <div v-text="note.text" />

                    <ActionMenu
                      v-if="note.member_id == $store.state.profile.id"
                      @click:action:destroy="destroyNote(note)"
                      @click:action:edit="toggleEditNote(note)"
                      :button-icon-size="18"
                      :items="noteActionMenuItems"
                      button-class="ms-3"
                      button-icon="more_vert"
                    />
                  </div>
                </div>

                <LongDateTime
                  v-if="!note.isEditing"
                  :date="note.created_at"
                  :prefix="note.meta.member.name + ' - '"
                  class="fs-12"
                />

                <span
                  v-if="note.created_at != note.updated_at"
                  v-t="' - Edited'"
                  class="fs-12 c-primary"
                />
              </div>

              <v-divider class="my-6" />

              <v-textarea
                v-model="newNoteText"
                variant="filled"
              />
              <v-btn
                @click="createNote"
                :disabled="!newNoteText"
                :loading="processing"
                color="primary"
                block
              >
                {{ $t('Add note') }}
              </v-btn>
            </v-window-item>

            <v-window-item value="attachments">
              <v-text-field
                v-model="documentQuery"
                placeholder="Search attachments"
                prepend-inner-icon="search"
                variant="solo-filled"
                flat
                hide-details
                rounded
              />

              <v-divider class="my-6" />

              <AttachmentList
                v-if="queriedAttachments.length > 0"
                @change="loadAttachments"
                @delete="loadAttachments"
                :attachments="queriedAttachments"
                dense
              />

              <v-divider class="my-5" />

              <AttachmentUploader
                @uploaded="load()"
                ref="attachment_uploader"
                :owner="{
                  type: 'Assessment',
                  id: assessment.id,
                }"
              />
            </v-window-item>

            <v-window-item
              value="messaging"
              eager
            >
              <AssessmentMessages
                @close="rightBarTab = null"
                :assessment="assessment"
                :business-id="provider.business_id"
              />
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>

    <v-container
      :style="$vuetify.display.mdAndUp ? 'padding-top: 70px' : 'padding-top: 130px'"
      fluid
    >
      <v-row
        :class="$vuetify.display.smAndDown ? 'flex-column-reverse' : ''"
        class="mb-16"
        dense
      >
        <v-col
          v-if="!selectedSchema || questions.length == 0"
          class="ta-center pa-8"
          cols="12"
          md="9"
        >
          <v-progress-circular
            size="40"
            indeterminate
          />
        </v-col>

        <v-col
          v-if="questions.length > 0"
          cols="12"
          md="9"
        >
          <ParticipantsShow
            v-if="selectedSheet"
            :business-id="provider.business_id"
            :participatable-id="selectedSheet.id"
            class="mt-2 mb-2"
            participatable-type="Worksheet"
          />

          <ParticipantsShow
            v-else
            :business-id="provider.business_id"
            :participatable-id="assessment.id"
            class="mt-2 mb-2"
            participatable-type="Assessment"
          />

          <QuestionSet
            v-if="selectedSheet"
            v-model="selectedSheet"
            @change="changed = true"
            @change:attachments="loadAttachments"
            @click:notes="viewNotes"
            :attachment-owner-id="selectedSheet.id"
            :attachment-owner-type="'Worksheet'"
            :attachment-tags-supplements="[assessment.id]"
            :attachments="selectedSheetAttachments"
            :color="null"
            :landscape="$vuetify.display.mdAndUp"
            :notes="relevantNotes"
            :processing="processing"
            :questions="questions"
            :readonly="!!selectedSheet.completed_at"
            :schema="selectedSchema.definition"
            attachment-status
            border
            display-notes
            expanded
            flat
            hide-actions
            inline-verification
            tile
            very-dense
          />

          <QuestionSet
            v-else
            v-model="assessment"
            @change="changed = true"
            @change:attachments="loadAttachments"
            @click:notes="viewNotes"
            :attachment-owner-id="assessment.id"
            :attachment-owner-type="'Assessment'"
            :attachments="attachments"
            :color="null"
            :landscape="$vuetify.display.mdAndUp"
            :notes="notes"
            :processing="processing"
            :questions="questions"
            :readonly="!!assessment.completed_at"
            :schema="selectedSchema.definition"
            attachment-status
            display-notes
            expanded
            flat
            hide-actions
            inline-verification
            outlined
            tile
            very-dense
          />

          <div class="d-flex align-center mt-4">
            <template v-if="selectedSheet">
              <template v-if="!!selectedSheet.completed_at">
                <v-btn
                  @click="completeWorksheet()"
                  :block="$vuetify.display.smallAndDown"
                  :loading="processing"
                  class="bg-white me-3"
                  color="primary"
                  size="large"
                  variant="outlined"
                >
                  <span v-t="'Mark incomplete'" />
                </v-btn>
              </template>

              <v-btn
                v-else
                @click="completeWorksheet()"
                :block="$vuetify.display.smallAndDown"
                :disabled="changed || worksheetIncomplete"
                :loading="processing"
                class="bg-white me-3"
                color="primary"
                size="large"
                variant="outlined"
              >
                {{ $t(worksheetIncomplete ? 'Worksheet incomplete' : 'Mark complete') }}
              </v-btn>

              <v-btn
                @click="saveWorksheet()"
                :block="$vuetify.display.smallAndDown"
                :disabled="!!selectedSheet.completed_at"
                :loading="processing"
                color="primary"
                size="large"
              >
                {{ $t('Save') }}
              </v-btn>

              <v-spacer />
            </template>

            <template v-else>
              <template v-if="!!assessment.completed_at">
                <v-btn
                  @click="complete()"
                  :block="$vuetify.display.smallAndDown"
                  :loading="processing"
                  class="bg-white me-3"
                  color="primary"
                  size="large"
                  variant="outlined"
                >
                  {{ $t('Mark incomplete') }}
                </v-btn>
              </template>

              <v-btn
                v-else
                @click="complete()"
                :block="$vuetify.display.smallAndDown"
                :disabled="changed || assessmentIncomplete"
                :loading="processing"
                class="bg-white me-3"
                color="primary"
                size="large"
                variant="outlined"
              >
                {{ $t(assessmentIncomplete ? 'Assessment incomplete' : 'Mark complete') }}
              </v-btn>

              <v-btn
                @click="save()"
                :block="$vuetify.display.smallAndDown"
                :disabled="!!assessment.completed_at"
                :loading="processing"
                color="primary"
                size="large"
              >
                {{ $t('Save') }}
              </v-btn>

              <v-spacer />

              <v-btn
                @click="destroy()"
                :block="$vuetify.display.smallAndDown"
                :loading="processing"
                color="red"
                size="large"
                variant="outlined"
              >
                {{ $t('Delete') }}
              </v-btn>
            </template>
          </div>
        </v-col>

        <v-col
          class="mt-2"
          cols="12"
          md="3"
        >
          <v-card
            class="mb-4"
            border
            tile
          >
            <v-card-title class="fs-18 fw-500 bb-1 bc-very-light-grey mb-0">
              {{ $t('Overview') }}
            </v-card-title>

            <v-list>
              <v-list-item
                :to="{ query: {} }"
                exact
              >
                <v-list-item-title class="d-flex justify-space-between align-center">
                  <div
                    v-t="schema.name"
                    class="mxw-300 o-dotted"
                  />

                  <v-icon
                    v-if="!!assessment.completed_at"
                    color="primary"
                    icon="check_circle"
                  />
                </v-list-item-title>
              </v-list-item>

              <v-list-group :model-value="true">
                <template #activator="{ props }">
                  <v-list-item v-bind="props">
                    <v-list-item-title class="fs-6 fw-500">
                      {{ $t('Worksheets') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>

                <v-list-item
                  v-for="worksheet in worksheets"
                  :key="worksheet.id"
                  :to="{ query: { worksheet_id: worksheet.id } }"
                  class="px-0"
                  lines="two"
                  exact
                >
                  <v-list-item-title class="d-flex justify-space-between align-center">
                    <div class="mxw-300">
                      <div
                        v-t="worksheet.name"
                        class="fs-16 mb-2 o-dotted"
                      />

                      <div
                        v-t="
                          worksheetSchemas.find(
                            (worksheetSchema) => worksheetSchema.id == worksheet.schema_id,
                          ).name
                        "
                        class="fs-14 c-light-black o-dotted"
                      />
                    </div>
                  </v-list-item-title>

                  <template #append>
                    <v-icon
                      v-if="!!worksheet.completed_at"
                      color="primary"
                      icon="check_circle"
                    />

                    <v-menu
                      location="bottom left"
                      dense
                    >
                      <template #activator="{ props }">
                        <v-btn
                          @click.prevent
                          v-bind="props"
                          class="text-decoration-none"
                          color="black"
                          icon="more_vert"
                          size="x-small"
                          variant="text"
                        />
                      </template>

                      <v-list
                        class="py-0"
                        density="compact"
                        border
                        tile
                      >
                        <v-list-item @click="downloadWorksheet(worksheet)">
                          <template #prepend>
                            <v-icon
                              color="black"
                              icon="download"
                            />
                          </template>

                          <v-list-item-title class="fs-14">
                            {{ $t('Download') }}
                          </v-list-item-title>
                        </v-list-item>

                        <v-divider />

                        <v-list-item @click="destroyWorksheet(worksheet)">
                          <template #prepend>
                            <v-icon
                              color="black"
                              icon="delete"
                            />
                          </template>

                          <v-list-item-title class="fs-14">
                            {{ $t('Remove') }}
                          </v-list-item-title>
                        </v-list-item>

                        <v-divider />

                        <v-list-item @click="$refs.renameWorksheetDialog.open(worksheet)">
                          <template #prepend>
                            <v-icon
                              color="black"
                              icon="edit"
                            />
                          </template>

                          <v-list-item-title class="fs-14">
                            {{ $t('Rename') }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-list-item>

                <v-list-item
                  v-if="worksheets.length == 0"
                  density="compact"
                >
                  <template #prepend>
                    <v-icon
                      color="grey"
                      icon="block"
                    />
                  </template>

                  <v-list-item-title color="grey">
                    {{ $t('No worksheets') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>

              <v-divider class="my-2" />

              <v-list-item>
                <v-btn
                  @click="draftWorksheet"
                  color="primary"
                  prepend-icon="add"
                  block
                >
                  {{ $t('Add worksheet') }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="draftWorksheetDialogIsVisible">
      <v-card
        class="mx-auto"
        max-width="600px"
      >
        <v-card-title>
          {{ $t('New worksheet') }}
        </v-card-title>

        <v-card-text>
          <v-row>
            <LabeledTextfield
              v-model="newWorksheet.name"
              message="Name"
            />

            <LabeledSimpleSelect
              v-model="newWorksheet.schema_id"
              :items="worksheetSchemas"
              item-title="name"
              item-value="id"
              message="Type"
            />
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            @click="draftWorksheetDialogIsVisible = false"
            variant="text"
          >
            {{ $t('Cancel') }}
          </v-btn>

          <v-spacer />

          <v-btn
            @click="createWorksheet"
            :disabled="!newWorksheet.name || !newWorksheet.schema_id"
            :loading="processing"
            color="primary"
          >
            {{ $t('Create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ResourceDialog
      @save="renameWorksheet"
      ref="renameWorksheetDialog"
      :fields="[{ text: 'Name', value: 'name' }]"
      :processing="processing"
      title="Rename worksheet"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import API from '@/shared/mixins/api';
import Assessment from '@/shared/mixins/assessment';
import AssessmentMessages from '@/specialist/components/AssessmentMessages.vue';
import LinkedList from '@/shared/services/linked-list';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import ParticipantsShow from '@/specialist/components/ParticipantsShow.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { mapUpdate } from '@/shared/services/utils';

export default {
  compatConfig: { MODE: 3 },

  components: {
    ActionMenu,
    AssessmentMessages,
    LongDateTime,
    ParticipantsShow,
    QuestionSet,
    ResourceDialog,
  },

  mixins: [API, Assessment],

  data() {
    return {
      assessmentIncomplete: true,
      attachments: [],
      changed: false,
      currentMemberId: this.$store.state.profile.id,
      schema: null,
      assessment: null,
      draftWorksheetDialogIsVisible: false,
      documentQuery: null,
      messages: [],
      newNoteText: null,
      newWorksheet: {},
      newMessageText: null,
      noteActionMenuItems: [
        { event: 'edit', avatar: 'edit', title: 'Edit' },
        { event: 'destroy', avatar: 'delete', title: 'Delete' },
      ],
      notes: [],
      openStageDialog: false,
      processing: false,
      processingNoteUpdate: false,
      provider: null,
      questions: [],
      rightBarTab: null,
      rightBarVisible: false,
      unreadMessageCount: 0,
      worksheets: [],
      worksheetSchemas: [],
      selectedQuestion: null,
      selectedSheet: null,
      selectedSchema: null,
      worksheetIncomplete: true,
    };
  },

  computed: {
    openNotes() {
      if (this.selectedQuestion) {
        return this.notes.filter(
          (note) =>
            note.question_id === this.selectedQuestion.id &&
            note.worksheet_id === this.selectedSheet?.id,
        );
      }
      return this.notes.filter((note) => !note.question_id);
    },

    relevantNotes() {
      if (this.selectedSheet) {
        return this.notes.filter((note) => note.worksheet_id === this.selectedSheet.id);
      }
      return this.notes;
    },

    selectedSheetAttachments() {
      if (!this.selectedSheet) return;

      return this.attachments.filter((attachment) => attachment.owner_id === this.selectedSheet.id);
    },
  },

  watch: {
    rightBarTab(newVal) {
      if (newVal != null) {
        this.rightBarVisible = true;
      } else {
        this.rightBarVisible = false;
        this.selectedQuestion = null;
      }
    },

    '$route.params.assessmentId': {
      immediate: true,
      handler() {
        this.load();
      },
    },

    '$route.query.worksheet_id': {
      immediate: true,
      handler() {
        this.load();
      },
    },
  },

  methods: {
    createNote() {
      this.processing = true;

      this.api.organization.note.create(
        {
          owner_id: this.$route.params.assessmentId,
          owner_type: 'Assessment',
          question_id: this.selectedQuestion?.id,
          text: this.newNoteText,
          worksheet_id: this.$route.query.worksheet_id,
        },
        (response) => {
          this.notes.push(response.data);
          this.newNoteText = null;
          this.processing = false;
        },
        (err) => {
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
          this.processing = false;
        },
      );
    },

    createWorksheet() {
      this.processing = true;
      this.api.organization.worksheet.create(
        this.newWorksheet,
        (response) => {
          this.worksheets.push(response.data);
          this.$eventBus.$emit('chime', 'Worksheet created');
          this.draftWorksheetDialogIsVisible = false;
          this.$router.push({ query: { worksheet_id: response.data.id } });
          this.processing = false;
        },
        (err) => {
          this.processing = false;
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },

    complete() {
      this.save({ completed: !this.assessment.completed_at });
    },

    completeStage() {
      this.save({ stage_id: this.schema.stages[this.currentStageIndex + 1].id });
    },

    completeWorksheet() {
      this.saveWorksheet({ completed: !this.selectedSheet.completed_at }, true);
    },

    async destroyNote(toDelete) {
      this.processingNoteUpdate = true;
      await this.api.organization.note.destroy(toDelete);
      this.notes = _.reject(this.notes, { id: toDelete.id });
      this.processingNoteUpdate = false;
    },

    destroy() {
      // eslint-disable-next-line no-alert, no-restricted-globals
      if (!confirm('Are you sure you want to delete this assessment?')) return;

      this.api.organization.assessment.destroy(this.$route.params.assessmentId, () =>
        this.$router.replace({ name: 'AssessmentIndex' }),
      );
    },

    destroyWorksheet(worksheet) {
      // eslint-disable-next-line no-alert, no-restricted-globals
      if (!confirm('Are you sure you want to delete this worksheet?')) return;

      this.processing = true;

      this.api.organization.worksheet.destroy(worksheet.id, () => {
        this.loadAssessment();
        this.$router.push({ query: {} });
        this.processing = false;
      });
    },

    downloadLink() {
      return this.api.organization.assessment.downloadUrl(this.assessment.id, this.assessment.name);
    },

    downloadWorksheet(worksheet) {
      window.open(this.api.organization.worksheet.downloadUrl(worksheet.id, worksheet.name));
    },

    draftWorksheet() {
      this.draftWorksheetDialogIsVisible = true;
      this.newWorksheet = { assessment_id: this.$route.params.assessmentId };
    },

    getAssessmentIncomplete() {
      if (this.worksheets.some((worksheet) => !worksheet.completed_at)) return true;

      const mandatoryQuestions = this.questions.filter(
        (question) => !question.is_section && question.mandatory && question.valid,
      );

      return mandatoryQuestions.some((question) =>
        question.synced_attributes.some((syncedAttribute) => {
          const attrParts = syncedAttribute.name.split('.');
          let value;
          if (attrParts[0] === 'custom') {
            value = this.assessment.custom[attrParts[attrParts.length - 1]];
          } else {
            value = this.assessment[attrParts[attrParts.length - 1]];
          }
          return value === null || value === undefined || (value === '' && value !== false);
        }),
      );
    },

    getWorksheetIncomplete() {
      if (this.selectedSheet) {
        const mandatoryQuestions = this.questions.filter(
          (question) => !question.is_section && question.mandatory,
        );

        const incompleteMandatoryQuestions = mandatoryQuestions.filter((question) =>
          question.synced_attributes.some((syncedAttribute) => {
            const attrParts = syncedAttribute.name.split('.');
            let value;
            if (attrParts[0] === 'custom') {
              value = this.selectedSheet.custom[attrParts[attrParts.length - 1]];
            } else {
              value = this.selectedSheet[attrParts[attrParts.length - 1]];
            }
            return value === null || value === undefined || (value === '' && value !== false);
          }),
        );
        return incompleteMandatoryQuestions.length > 0;
      }
      return true;
    },

    load() {
      this.selectedSchema = null;
      this.processing = true;
      this.selectedSheet = null;
      this.questions = [];
      this.loadAssessment();
      this.loadAttachments();
      this.loadNotes();
    },

    async loadAttachments() {
      this.processing = true;

      const assessmentResponse = await this.api.organization.attachment.index({
        owner_type: 'Assessment',
        owner_id: this.$route.params.assessmentId,
      });

      if (assessmentResponse?.status !== 200) {
        this.processing = false;
        return;
      }

      const worksheetResponse = await this.api.organization.attachment.index({
        owner_type: 'Worksheet',
        tags: [this.$route.params.assessmentId],
      });

      if (worksheetResponse?.status !== 200) {
        this.processing = false;
        return;
      }

      this.attachments = (assessmentResponse?.data || []).concat(worksheetResponse?.data || []);

      this.processing = false;
    },

    loadAssessment() {
      this.api.organization.assessment.get(this.$route.params.assessmentId, (response) => {
        this.assessment = response.data;
        this.currentStageId = this.assessment.stage_id;
        this.schema = Object.values(this.$store.state.schemas).find(
          (schema) => schema.id === this.assessment.schema_id,
        );
        this.worksheetSchemas = Object.values(this.$store.state.schemas)
          .filter((schema) => this.schema.associated_schema_ids.includes(schema.id))
          .sort((a, b) => (a.name > b.name ? 1 : -1));
        this.loadWorksheets();

        this.api.organization.provider.get(this.assessment.provider_id, (providerResponse) => {
          this.provider = providerResponse.data;
        });

        if (!this.$route.query.worksheet_id) {
          this.selectedSchema = this.schema;
          this.loadQuestions(this.schema.id);
        }

        this.processing = false;
      });
    },

    loadQuestions(ownerId, callback) {
      this.api.public_api.organization.question.index(
        { owner_id: ownerId, owner_type: 'Schema' },
        (response) => {
          this.validateQuestions(
            LinkedList.sort(response.data).filter((question) => question.published),
            callback,
          );
        },
      );
    },

    loadWorksheets() {
      this.api.organization.worksheet.index(
        { assessment_id: this.$route.params.assessmentId },
        (response) => {
          this.worksheets = response.data
            .filter((worksheet) =>
              this.worksheetSchemas.find(
                (worksheetSchema) => worksheetSchema.id === worksheet.schema_id,
              ),
            )
            .sort((a, b) =>
              this.$store.state.schemas[a.schema_id].name >
              this.$store.state.schemas[b.schema_id].name
                ? 1
                : -1,
            );

          if (this.$route.query.worksheet_id) {
            this.selectedSheet = this.worksheets.find(
              (worksheet) => worksheet.id === this.$route.query.worksheet_id,
            );
            this.selectedSchema = this.worksheetSchemas.find(
              (worksheetSchema) => worksheetSchema.id === this.selectedSheet.schema_id,
            );
            this.loadQuestions(this.selectedSheet.schema_id, () => {
              this.worksheetIncomplete = this.getWorksheetIncomplete();
            });
          }
          this.assessmentIncomplete = this.getAssessmentIncomplete();
        },
      );
    },

    loadMessages() {
      this.api.organization.message.index(
        { business_id: this.provider.business_id },
        (response) => {
          this.messages = response.data;
        },
      );
    },

    async loadNotes() {
      const response = await this.api.organization.note.index({
        owner_id: this.$route.params.assessmentId,
      });
      this.notes = response.data;
    },

    messageAuthor(message) {
      if (message.meta.member_is_specialist) {
        return [message.meta.member_name, this.$store.state.brand.organization_name].join(' @ ');
      }
      return message.meta.member_name;
    },

    renameWorksheet(worksheet) {
      this.processing = true;
      this.api.organization.worksheet.update(worksheet.id, { name: worksheet.name }, () => {
        this.$refs.renameWorksheetDialog.close();
        this.processing = false;
        this.loadAssessment();
      });
    },

    save(newVal) {
      this.processing = true;
      this.api.organization.assessment.update(
        this.assessment.id,
        newVal || this.assessment,
        (response) => {
          this.assessment = response.data;
          this.changed = false;
          this.loadQuestions(this.assessment.schema_id, () => {
            this.$eventBus.$emit('chime', 'Saved');
            this.processing = false;
            this.assessmentIncomplete = this.getAssessmentIncomplete();
          });
        },
        (err) => {
          this.processing = false;
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },

    saveWorksheet(newVal, reload = false) {
      this.processing = true;
      this.api.organization.worksheet.update(
        this.selectedSheet.id,
        newVal || this.selectedSheet,
        (response) => {
          if (reload) {
            this.load();
          } else {
            this.selectedSheet = response.data;
            this.changed = false;
            const worksheetIndex = this.worksheets.findIndex(
              (worksheet) => worksheet.id === response.data,
            );
            this.worksheets[worksheetIndex] = response.data;
            this.loadQuestions(this.selectedSheet.schema_id, () => {
              this.$eventBus.$emit('chime', 'Saved');
              this.processing = false;
              this.worksheetIncomplete = this.getWorksheetIncomplete();
            });
          }
        },
        (err) => {
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
          this.processing = false;
        },
      );
    },

    async saveNote(note) {
      this.processingNoteUpdate = true;

      const updatedNote = note;

      updatedNote.text = updatedNote.editedText;
      const update = await this.api.organization.note.update(updatedNote);

      if (update) this.notes = mapUpdate(this.notes, update);
      this.processingNoteUpdate = false;
    },

    setStage(index) {
      if (index < this.currentStageIndex) {
        // eslint-disable-next-line no-alert, no-restricted-globals
        if (!confirm('Are you sure you want to move back to a previous stage?')) return;

        this.save({ stage_id: this.schema.stages[index].id });
      }
    },

    selectSheet(worksheet) {
      this.$router.push({ query: { worksheet_id: worksheet.id } });
    },

    toggleEditNote(note) {
      this.notes = mapUpdate(this.notes, {
        ...note,
        isEditing: !note.isEditing,
        editedText: note.text,
      });
    },

    viewNotes(question) {
      this.selectedQuestion = question;
      this.rightBarTab = 0;
      this.rightBarVisible = true;
    },
  },
};
</script>

<style>
.text-decoration-none span:hover {
  text-decoration: none !important;
}
</style>
