<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle title="Learning" />

    <v-tabs
      v-model="tab"
      @update:model-value="tabChange"
      bg-color="surface"
      grow
    >
      <v-tab value="cohorts">
        {{ $t('Cohorts') }}
      </v-tab>

      <v-tab
        @click="openCourseTab"
        value="courses"
      >
        {{ $t('Courses') }}
      </v-tab>
    </v-tabs>

    <v-window
      v-model="tab"
      class="border-t"
    >
      <v-window-item value="cohorts">
        <CohortIndex />
      </v-window-item>

      <v-window-item
        v-if="isLearningAdmin(false)"
        value="courses"
      >
        <CourseIndex />
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script setup>
import CohortIndex from '@/specialist/views/learning/CohortIndex.vue';
import CourseIndex from '@/specialist/views/learning/CourseIndex.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();

const tab = ref('cohorts');

function isLearningAdmin(showError = true) {
  if (store.state.profile?.org_learning_admin) return true;

  if (showError) eventBus.error("You don't have permission to create and manage courses");
  return false;
}

function openCourseTab() {
  if (!isLearningAdmin()) tabChange('cohorts');
}

function load() {
  if (route.query.tab === 'courses' && isLearningAdmin()) tabChange('courses');
}

function tabChange(newValue) {
  tab.value = newValue;
  router.push({
    query: { tab: tab.value },
  });
}

onMounted(load);
</script>
