<template>
  <v-btn
    @click="dialogIsVisible = !dialogIsVisible"
    :color="overallColor"
    class="ms-3"
    size="small"
    variant="flat"
    rounded
  >
    <v-icon
      color="white"
      role="presentation"
      aria-hidden
    >
      {{ overallIcon }}
    </v-icon>

    <v-dialog
      v-model="dialogIsVisible"
      max-width="600"
      scrim="transparent"
    >
      <v-card
        class="py-0 px-0"
        border
        flat
        tile
      >
        <v-card-text class="pt-2 pb-0 px-0">
          <SubsidyEligibilityCard
            :elevation="0"
            :funding-sources="fundingSources"
            :projected-eligibility="projectedEligibility"
            expanded
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="dialogIsVisible = false"
            color="primary"
            variant="flat"
          >
            <span v-t="'Close'" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
import SubsidyEligibilityCard from '@/shared/components/subsidy/SubsidyEligibilityCard.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    SubsidyEligibilityCard,
  },

  props: {
    fundingSources: {
      type: Array,
      default: null,
    },
    projectedEligibility: Boolean,
  },

  data() {
    return {
      dialogIsVisible: false,
    };
  },

  computed: {
    overallColor() {
      return this.projectedEligibility ? 'green' : 'red';
    },

    overallIcon() {
      return this.projectedEligibility ? 'check_circle' : 'cancel';
    },
  },
};
</script>
