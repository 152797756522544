<template>
  <ProgramMessaging
    @change="changed = $event"
    @load="load"
    @new-message-template="addMessageTemplate"
    @save="save"
    :back-route="{ name: 'ChildSubsidyProgramShow' }"
    :changed="changed"
    :event-names="eventNames"
    :owner-type="'SubsidyProgram'"
    :processing="processing"
    :program="subsidyProgram"
  />
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ProgramMessaging from '@/admin/components/ProgramMessaging.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute } from 'vue-router';

const eventBus = useEventBus();
const route = useRoute();

const changed = ref(true);
const eventNames = ref(null);
const subsidyProgram = ref({});
const subsidyProgramId = ref(route.params.subsidyProgramId);
const processing = ref(false);

onMounted(() => {
  processing.value = true;
  load();
});

function addMessageTemplate(messageTemplate) {
  subsidyProgram.value.message_templates.push(messageTemplate);
  changed.value = true;
}

function load() {
  changed.value = false;
  loadSubsidyProgram();
}

function loadSubsidyProgram() {
  Api.organization.subsidy_program.get(subsidyProgramId.value, (response) => {
    subsidyProgram.value = response.data;
    processing.value = false;
    eventNames.value = subsidyProgram.value.event_names;
  });
}

async function save() {
  processing.value = true;
  Api.organization.subsidy_program.update(
    subsidyProgramId.value,
    {
      enable_parent_subsidy_submitted_alert:
        subsidyProgram.value.enable_parent_subsidy_submitted_alert,
      parent_subsidy_submitted_alert_subject_template:
        subsidyProgram.value.parent_subsidy_submitted_alert_subject_template,
      parent_subsidy_submitted_alert_template:
        subsidyProgram.value.parent_subsidy_submitted_alert_template,
      message_templates: subsidyProgram.value.message_templates,
    },
    (response) => {
      processing.value = false;
      subsidyProgram.value = response.data;
      changed.value = false;
      eventBus.chime('Saved');
    },
    (error) => {
      load();
      processing.value = false;
      eventBus.chime(error.response.data.errors[0]);
    },
  );
}
</script>
