<template>
  <v-col class="d-flex justify-end align-center">
    <v-icon
      :color="iconColor"
      size="large"
      start
    >
      {{ icon }}
    </v-icon>

    <v-btn
      @click="$emit('toggle')"
      :data-cy="dataCy"
      :loading="processing"
      :outlined="outlined"
      :variant="variant"
      color="primary"
      block
    >
      <span>{{ $t(buttonText) }}</span>
    </v-btn>
  </v-col>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    enabled: Boolean,
    enabledText: {
      type: String,
      default: null,
    },
    dataCy: {
      type: String,
      default: null,
    },
    disabledText: {
      type: String,
      default: null,
    },
    processing: Boolean,
  },

  emits: ['toggle'],

  computed: {
    buttonText() {
      return this.enabled ? this.enabledText : this.disabledText;
    },

    icon() {
      return this.enabled ? 'verified' : 'hide_source';
    },

    iconColor() {
      return this.enabled ? 'green' : 'gray';
    },

    outlined() {
      return !this.enabled;
    },
    variant() {
      return this.enabled ? 'flat' : 'outlined';
    },
  },
};
</script>
