<template>
  <v-card
    class="mb-8"
    border
    flat
    tile
  >
    <v-card-title class="fs-20">
      {{ $t('Age groups') }}
    </v-card-title>

    <v-divider />

    <v-card-text
      v-if="ageGroups.length > 0"
      class="px-4"
    >
      <AgeGroupCard
        v-for="ageGroup in ageGroups"
        @change="load"
        :key="ageGroup.id"
        :value="ageGroup"
        class="mb-4"
      />
    </v-card-text>

    <ResourceDialog
      @save="create"
      ref="newAgeGroupDialog"
      :fields="NEW_AGE_GROUP_FIELDS"
      :processing="processing"
      title="New age group"
    />

    <v-card-actions>
      <v-btn
        @click="$refs.newAgeGroupDialog.open({})"
        color="primary"
      >
        {{ $t('New age group') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import AgeGroupCard from '@/admin/components/AgeGroupCard.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

const NEW_AGE_GROUP_FIELDS = [
  { text: 'Name', value: 'name' },
  { text: 'Min', value: 'min' },
  { text: 'Max', value: 'max' },
  { text: 'Title', value: 'title' },
  { text: 'Subtitle', value: 'subtitle' },
];

async function create(newVal) {
  processing.value = true;
  const resp = await api.organization.age_group.create(newVal);
  processing.value = false;
  if (resp.status !== 201) return;

  ageGroups.value.push(resp.data);
  newAgeGroupDialog.value.close();
}

async function load() {
  const resp = await api.organization.age_group.index();
  ageGroups.value.splice(0);
  ageGroups.value.push(...resp.data);
}

const ageGroups = ref([]);
const newAgeGroupDialog = ref(null);
const processing = ref(false);

onMounted(load);
</script>
