<template>
  <v-card
    class="pa-0 ma-0"
    flat
    tile
  >
    <v-card-title class="d-flex align-center px-0">
      <v-spacer />

      <v-btn
        @click="add"
        color="primary"
        prepend-icon="add"
        variant="flat"
      >
        {{ $t('Add criteria') }}
      </v-btn>
    </v-card-title>

    <v-card-text class="px-0">
      <v-card
        v-for="(criterion, index) in modelValue"
        :key="index"
        class="mb-8"
        border
        flat
        tile
      >
        <v-card-title class="d-flex align-center">
          <span class="fs-20"> {{ $t('Criteria') }} #{{ index + 1 }} </span>

          <v-spacer />

          <v-btn
            @click="remove(index)"
            color="red"
            icon="close"
            size="small"
            variant="text"
          />
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-row class="d-flex align-center">
            <template v-if="schemas.length > 1">
              <LabeledAutocomplete
                v-model="criterion.data_type"
                @input="$emit('change')"
                :items="dataTypeItems"
                cols="3"
                message="Source"
                clearable
              />
            </template>

            <template v-else>
              <LabeledTextfield
                :model-value="schemas[0]?.data_type"
                cols="3"
                message="Source"
                readonly
              />
            </template>

            <LabeledAutocomplete
              v-model="criterion.field"
              @input="$emit('change')"
              :items="fieldItems(criterion)"
              cols="4"
              item-title="text"
              item-value="value"
              message="Field"
              chips
              deletable-chips
              filled
              hide-details
            />

            <LabeledAutocomplete
              v-model="criterion.comparator"
              @input="$emit('change')"
              :items="comparators"
              cols="3"
              item-title="text"
              item-value="value"
              message="Comparison"
            />

            <LabeledTextfield
              v-if="
                comparators.find((comp) => comp.value == criterion.comparator && comp.comparable)
              "
              v-model="criterion.value"
              @input="$emit('change')"
              cols="2"
              message="Value"
            />
          </v-row>

          <v-row v-if="formula">
            <LabeledTextarea
              v-model="criterion.formula"
              @input="$emit('change')"
              message="Formula"
            />
          </v-row>

          <v-row v-if="message">
            <LabeledTextfield
              v-model="criterion.message"
              @input="$emit('change')"
              message="Message"
            />
          </v-row>
        </v-card-text>
      </v-card>

      <div
        v-if="modelValue.length === 0"
        class="fs-16"
      >
        {{ $t('No criteria set.') }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';

const emit = defineEmits(['change', 'update:modelValue']);
const props = defineProps({
  comparators: {
    default() {
      return [
        { text: 'equals', value: 'equals', comparable: true },
        { text: 'not equals', value: 'not_equals', comparable: true },
        { text: 'is true', value: 'is_true', comparable: false },
        { text: 'is false', value: 'is_false', comparable: false },
        { text: 'contains', value: 'contains', comparable: true },
        { text: 'not_contains', value: 'not_contains', comparable: true },
        { text: 'blank', value: 'blank', comparable: false },
        { text: 'not blank', value: 'not_blank', comparable: false },
        { text: 'earlier than', value: 'earlier_than', comparable: true },
        { text: 'later than', value: 'later_than', comparable: true },
        { text: 'greater than', value: 'greater_than', comparable: true },
        { text: 'greater than or equal to', value: 'greater_than_or_equals', comparable: true },
        { text: 'less than', value: 'less_than', comparable: true },
        { text: 'less than or equal to', value: 'less_than_or_equals', comparable: true },
        { text: 'in', value: 'in', comparable: true },
        { text: 'not in', value: 'not_in', comparable: true },
      ];
    },
    type: Array,
  },
  formula: {
    default: false,
    type: Boolean,
  },
  message: {
    default: false,
    type: Boolean,
  },
  schemas: {
    default: () => [],
    type: Array,
  },
  modelValue: {
    default: () => [],
    type: Array,
  },
});

const dataTypeItems = computed(() => {
  if (props.schemas) return props.schemas.map((schema) => schema.data_type);
  return [];
});

function add() {
  // If only one schema is present, the LabeledAutocomplete is automatically set and can't be changed
  const dataType = props.schemas.length > 1 ? null : props.schemas[0]?.data_type;

  emit('update:modelValue', [
    ...props.modelValue,
    {
      comparator: null,
      value: null,
      data_type: dataType,
    },
  ]);
}

function fieldItems(criterion) {
  if (!props.schemas?.length) return [];
  if (props.schemas.length === 1 && props.schemas[0]) return props.schemas[0].reportable_fields;
  if (criterion.data_type) {
    return props.schemas.find((schema) => schema.data_type == criterion.data_type)
      .reportable_fields;
  }
  return [];
}

function remove(index) {
  emit(
    'update:modelValue',
    props.modelValue.filter((_, i) => i !== index),
  );
  emit('change');
}
</script>
