<template>
  <v-card
    border
    flat
    tile
  >
    <template v-if="title">
      <v-card-title>
        <v-row>
          <v-col cols="8">
            <h2 class="fs-20 fw-800 ws-pre-line">
              <span>{{ $t(title) }}</span>
            </h2>
          </v-col>
          <v-col
            class="d-flex align-start justify-end"
            cols="4"
          >
            <slot name="title-append" />
          </v-col>
        </v-row>
      </v-card-title>
    </template>
    <v-card-text>
      <slot />
    </v-card-text>
    <template v-if="actions.length > 0">
      <v-card-actions>
        <v-btn
          v-for="action in actions"
          @click="$emit(`click:action:${action.event}`)"
          :key="action.title"
          :color="action.color || 'primary'"
          :loading="processing"
          :variant="action.outlined ? 'outlined' : 'flat'"
        >
          <span>{{ $t(action.title) }}</span>
        </v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    processing: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },

  // TODO: Determine how to manage 'dynamic' emit action names --> click:action:$(action.event)
  // emits: [`click:action:$(action.event)`]
};
</script>
