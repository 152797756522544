<template>
  <v-dialog
    v-model="visible"
    max-width="768"
  >
    <v-card>
      <v-card-title>{{ $t('Create a new provider') }}</v-card-title>
      <v-card-text>
        <v-row>
          <LabeledTextfield
            v-model="provider.name"
            cols="12"
            data-cy="provider-name-input"
            md="6"
            message="Name (required)"
          />
          <LabeledTextfield
            v-model="provider.owner_email"
            cols="12"
            data-cy="provider-email-input"
            md="6"
            message="Provider registered/owner email"
          />
        </v-row>
        <v-row>
          <LabeledTextfield
            v-model="provider.state_provider_id"
            cols="12"
            data-cy="provider-license-input"
            md="6"
            message="License # if applicable"
          />
          <LabeledSimpleSelect
            v-model="provider.schema_id"
            :items="providerSchemas"
            cols="12"
            item-title="name"
            item-value="id"
            md="6"
            message="Provider type"
          />
        </v-row>
      </v-card-text>
      <v-card-actions class="bc-very-light-gray bt-1 px-4 py-4">
        <v-btn
          @click="visible = false"
          :loading="processing"
          variant="text"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          @click="create()"
          :disabled="disableCreate"
          :loading="processing"
          color="secondary"
          data-cy="create-button"
          variant="flat"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LabeledTextfield,
    LabeledSimpleSelect,
  },

  emits: ['create'],

  data() {
    return {
      visible: false,
      processing: false,
      provider: '',
    };
  },

  computed: {
    disableCreate() {
      return !this.provider.name || !this.provider.schema_id;
    },

    providerSchemas() {
      return Object.values(this.$store.state.schemas).filter(
        (schema) => schema.data_type === 'Provider',
      );
    },
  },

  methods: {
    open(businessId) {
      this.processing = false;
      this.provider = this.blankProvider(businessId);
      this.visible = true;
    },

    blankProvider(businessId) {
      return {
        business_id: businessId,
        name: null,
        schema_id: null,
        state: null,
        state_provider_id: null,
      };
    },

    create() {
      this.processing = true;
      this.provider.state =
        this.$store.state.schemas[this.provider.schema_id].definition.properties.state.default;
      Api.organization.provider.create(
        this.provider,
        (resp) => {
          this.visible = false;
          this.$emit('create', resp.data);
        },
        (err) => {
          this.processing = false;
          this.$eventBus.$emit('chime', err.response.data.errors.join('. '));
        },
      );
    },
  },
};
</script>
