<template>
  <div>
    <ResourceDialog
      @close="updateQuery({ action: null })"
      @save="create"
      ref="dialog"
      :fields="fields"
      :max-width="800"
      :processing="processing"
      save-button-text="Create"
      title="New application"
    >
      <template #append="{ localValue }">
        <template v-if="localValue.terms_agreement_id">
          <v-row>
            <v-col>
              <v-btn
                @click="openTermsAgreement(localValue.terms_agreement_id)"
                color="primary"
                variant="outlined"
              >
                {{ $t('Review Terms of Service') }}
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-if="duplicateGroupId">
          <v-alert
            class="mt-4"
            color="primary"
            text-color="white"
          >
            <p class="c-white">
              {{
                $t(
                  "An account for this family already exists. The name and/or phone number are already associated with an existing account. To continue creating an application, access the family's profile and start an application for the specific child.",
                )
              }}
            </p>
            <v-btn
              @click="goToGroupShow(duplicateGroupId)"
              append-icon="east"
              variant="outlined"
            >
              {{ $t('Go to existing family') }}
            </v-btn>
          </v-alert>
        </template>
      </template>
    </ResourceDialog>

    <ContentDialog
      ref="agreementDialog"
      :max-width="800"
    >
      <template #content="{ data }">
        <MarkdownContent
          v-if="data"
          :content="data.termsAgreement.content_html"
          class="fs-16"
        />
      </template>
    </ContentDialog>
  </div>
</template>

<script>
import API from '@/shared/mixins/api';
import ContentDialog from '@/shared/components/ContentDialog.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import RouterHelper from '@/shared/mixins/RouterHelper';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ContentDialog,
    MarkdownContent,
    ResourceDialog,
  },

  mixins: [API, RouterHelper],

  props: {
    selectedSubsidyProgram: {
      type: Object,
      default: () => null,
    },
    subsidyPrograms: {
      type: Array,
      default: () => [],
    },
    teams: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['change'],

  data() {
    return {
      duplicateGroupId: null,
      processing: false,
      sites: [],
      termsAgreements: [],
    };
  },

  computed: {
    fields() {
      const fieldsAry = [
        {
          cols: '12',
          text: 'Application',
          value: 'subsidy_program_id',
          items: this.subsidyPrograms,
          itemText: 'name',
          itemValue: 'id',
          required: true,
        },
        {
          cols: '12',
          text: 'Language preference',
          value: 'default_locale',
          items: this.$store.state.config.languages,
          itemText: 'text',
          itemValue: 'value',
          required: true,
        },
        {
          cols: '6',
          text: 'Primary parent/guardian first name',
          value: 'primary_first_name',
          required: true,
        },
        {
          cols: '6',
          text: 'Primary parent/guardian last name',
          value: 'primary_last_name',
          required: true,
        },
        {
          cols: '6',
          text: 'Primary parent/guardian email',
          value: 'primary_email',
          required: false,
        },
        {
          cols: '6',
          mask: '(###) ###-####',
          text: 'Primary parent/guardian phone',
          value: 'primary_phone',
          required: false,
        },
        {
          cols: '6',
          text: 'Child first name',
          value: 'first_name',
          required: true,
        },
        {
          cols: '6',
          text: 'Child last name',
          value: 'last_name',
          required: true,
        },
        {
          cols: '12',
          text: 'Child date of birth',
          value: 'dob',
          type: 'dob',
          required: true,
        },
        {
          cols: '12',
          text: 'Primary site',
          value: 'site_id',
          items: this.sites,
          itemText: 'name',
          itemValue: 'id',
          required: true,
        },
      ];

      if (this.selectedSubsidyProgram) {
        if (this.teams.length > 0 && this.selectedSubsidyProgram.enable_team_review) {
          fieldsAry.push({
            cols: '12',
            text: 'Team assignment',
            value: 'team_id',
            items: this.teams,
            itemText: 'name',
            itemValue: 'id',
            required: false,
          });
        }
      }

      if (this.termsAgreements.length > 0) {
        fieldsAry.push(
          {
            cols: '12',
            text: 'Applicable Terms of Service',
            value: 'terms_agreement_id',
            items: this.termsAgreements,
            itemText: 'name',
            itemValue: 'id',
            required: true,
          },
          {
            cols: '12',
            text: 'I accept the Terms of Service',
            value: 'terms_agreement_accepted',
            type: 'boolean',
            required: true,
          },
        );
      }

      fieldsAry.push({
        cols: '12',
        hidden: (localValue) => !localValue?.primary_phone,
        text: 'I consent on behalf of the member to receive communication by text message to the cellphone number provided above',
        type: 'boolean',
        value: 'text_opted_in',
      });

      return fieldsAry;
    },
  },

  watch: {
    '$route.query.action': {
      handler() {
        this.checkAction();
      },
    },
  },

  mounted() {
    this.checkAction();
  },

  methods: {
    async checkAction() {
      if (this.$route.query.action === 'create') {
        this.duplicateGroupId = null;
        this.member = null;
        await this.loadSites();
        this.loadTermsAgreements();
        this.$refs?.dialog?.open({
          default_locale: this.$store.state.profile?.default_locale,
          site_id: this.sites[0]?.id,
          subsidy_program_id: this.selectedSubsidyProgram?.id,
        });
      } else {
        this.$refs?.dialog?.close();
      }
    },

    async create(newChild) {
      this.processing = true;
      this.duplicateGroupId = null;
      await this.api.organization.member.create(
        {
          is_parent: true,
          first_name: newChild.primary_first_name,
          last_name: newChild.primary_last_name,
          email: newChild.primary_email,
          phone: newChild.primary_phone,
          site_id: newChild.site_id,
          terms_agreement_id: newChild.terms_agreement_id,
          terms_agreement_accepted: newChild.terms_agreement_accepted,
          text_opted_in: newChild.text_opted_in,
          default_locale: newChild.default_locale,
        },
        async (memberResp) => {
          const childParams = {
            dob: newChild.dob,
            first_name: newChild.first_name,
            last_name: newChild.last_name,
          };
          const childResp = await this.api.organization.group.child.create(
            memberResp.data.group_id,
            childParams,
          );

          const subsidyParams = {
            child_id: childResp.data.id,
            subsidy_program_id: newChild.subsidy_program_id,
            team_id: newChild.team_id,
          };
          const subsidyResp = await this.api.organization.subsidy.create(subsidyParams);
          if (subsidyResp.data) this.$emit('change', subsidyResp.data);
        },
        async (memberError) => {
          this.processing = false;
          let existingMember;
          const error = memberError.response.data.errors[0];
          if (error === 'Email already taken') {
            const params = { query: newChild.primary_email, is_parent: true };
            existingMember = await this.api.organization.member.index(params);
          } else if (error === 'Phone already taken') {
            const params = {
              query: newChild.primary_phone.match(/\d+/g).join(''),
              is_parent: true,
            };
            existingMember = await this.api.organization.member.index(params);
          } else {
            this.$eventBus.$emit('error', memberError);
            return;
          }
          this.duplicateGroupId = existingMember.data[0].group_id;
        },
      );
    },

    goToGroupShow(groupId) {
      this.$router.push({ name: 'GroupShow', params: { groupId } });
    },

    async loadSites() {
      const resp = await this.api.organization.site.index({ site_type: 'parent' });
      this.sites = resp.data;
    },

    async loadTermsAgreements() {
      const response = await this.api.organization.termsAgreement.index();
      this.termsAgreements = response.data;
    },

    openTermsAgreement(termsAgreementId) {
      const termsAgreement = this.termsAgreements.find((ta) => ta.id === termsAgreementId);
      this.$refs.agreementDialog.open({ termsAgreement });
    },
  },
};
</script>
