import Api from '@/shared/services/bright_finder';
import LinkedList from '@/shared/services/linked-list';
import ValueExtractor from '@/shared/services/ValueExtractor';

/**
 * @deprecated This mixin is replaced by useGrant.js
 * see GrantNew.vue for usage
 */
export default {
  computed: {
    grantRecord() {
      if (this.localGrant) return this.localGrant;
      return this.grant;
    },

    validEligibilityQuestions() {
      return this.sortedQuestions(this.questions, 'Grant', true).filter(
        (question) => question.valid && (this.displayAll || question.published),
      );
    },

    validQuestions() {
      return this.sortedQuestions(this.questions, 'Grant', false).filter(
        (question) => question.valid && (this.displayAll || question.published),
      );
    },

    validVerificationQuestions() {
      return this.sortedVerification(this.questions, 'Grant').filter(
        (question) => question.valid && (this.displayAll || question.published),
      );
    },
  },

  data() {
    return {
      displayAll: false,
      providerSchema: null,
      questions: [],
      grantProgramId: null,
    };
  },

  methods: {
    async validate(callback) {
      const assertions = this.questions.map((question) => {
        const value = ValueExtractor.extract(
          this.grantRecord,
          question.conditions.map((condition) => condition.field),
        );

        return {
          assertion: {
            conditions: question.conditions,
            labels: [question.id],
            value,
          },
        };
      });

      const { data } = await Api.public_api.assertion.promiseBulkCreate(assertions);

      data.results.forEach((result) => {
        let question = null;
        question = this.questions.find((question) => result.labels.includes(question.id));
        if (question) question.valid = result.result;
      });

      if (callback) {
        callback();
      }
    },

    sortedQuestions(questions, syncedModel, eligibility) {
      return LinkedList.sort(
        questions.filter(
          (question) =>
            question.synced_model === syncedModel && question.eligibility === eligibility,
        ),
      );
    },

    sortedVerification(questions, syncedModel) {
      return questions.filter(
        (question) => question.synced_model === syncedModel && question.verification,
      );
    },

    loadQuestions(callback) {
      Api.public_api.organization.question.index(
        { owner_id: this.grantProgramId, owner_type: 'GrantProgram' },
        (resp) => {
          this.questions = resp.data;

          if (callback) {
            callback();
          }
        },
      );
    },

    async loadGrantProgram() {
      const { data } = await Api.public_api.organization.grant_program.get(this.grantProgramId);
      this.grantProgram = data;
    },
  },
};
