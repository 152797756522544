<template>
  <v-card
    elevation="0"
    border
    tile
  >
    <v-card-title>
      <h3
        v-t="draft.name"
        class="fs-22 fw-500"
      />
    </v-card-title>

    <v-card-text>
      <v-row>
        <LabeledTextfield
          v-model="draft.name"
          @update:model-value="handleInput"
          message="Name"
        />
        <LabeledTextfield
          v-model="draft.external_id"
          @input="handleInput"
          message="External ID"
        />
        <LabeledTextfield
          v-model="draft.title"
          @input="handleInput"
          message="Title"
        />
        <LabeledTextfield
          v-model="draft.subtitle"
          @input="handleInput"
          message="Subtitle"
        />
        <LabeledTextfield
          v-model.number="draft.min"
          @input="handleInput"
          cols="6"
          message="Min"
        />
        <LabeledTextfield
          v-model.number="draft.max"
          @input="handleInput"
          cols="6"
          message="Max"
        />
        <LabeledSwitch
          v-model="draft.hidden"
          @input="handleInput"
          title="Hidden"
        />
      </v-row>
    </v-card-text>

    <v-card-actions v-if="changed">
      <v-spacer />
      <v-btn
        @click="save"
        :loading="processing"
        color="primary"
      >
        {{ $t('Save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const props = defineProps({
  value: {
    type: Object,
    default: () => ({}),
  },
});

const changed = ref(false);
const draft = reactive(JSON.parse(JSON.stringify(props.value)));
const processing = ref(false);

function handleInput() {
  changed.value = true;
}

async function save() {
  processing.value = true;
  const resp = await api.organization.age_group.update(draft.id, draft);
  processing.value = false;
  if (resp.status !== 200) return;

  changed.value = false;
  eventBus.chime('Saved');
}
</script>
