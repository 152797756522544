<template>
  <v-container
    v-if="schema"
    class="mxw-1200"
  >
    <TitleBar
      :back-name="schema.data_type + ' schemas'"
      :back-route="{ name: 'SchemaIndex', query: { data_type: schema.data_type } }"
      :name="schema.name"
    />

    <SectionHeader title="Overview" />

    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <LabeledTextfield
            v-model="schema.name"
            @input="changed = true"
            message="Name"
          />
          <LabeledTextarea
            v-model="schema.description"
            @input="changed = true"
            message="Description"
          />
          <LabeledTextfield
            v-model="schema.image_url"
            @input="changed = true"
            message="Image URL"
          />
          <LabeledSimpleSelect
            v-model="schema.associated_data_types"
            @input="changed = true"
            :items="dataTypes"
            cols="6"
            item-title="name"
            item-value="id"
            message="Associated data types"
            chips
            deletable-chips
            multiple
          />
          <LabeledSimpleSelect
            v-model="schema.associated_schema_ids"
            @input="changed = true"
            :items="relevantSchemas"
            cols="6"
            item-title="name"
            item-value="id"
            message="Associated schemas"
            chips
            deletable-chips
            multiple
          />
        </v-row>
      </v-card-text>
    </v-card>

    <SchemaForm
      v-if="schema.schema_for_meta.properties"
      v-model="schema.meta"
      @update:model-value="changed = true"
      :schema="schema.schema_for_meta"
      title="Settings"
      flat
      outlined
    />

    <v-card
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <v-col>
            <v-btn
              @click="$refs.file_selector.click()"
              class="me-3"
              color="primary"
            >
              Import
            </v-btn>
            <input
              @change="importJson"
              id="file_selector"
              ref="file_selector"
              accept=".json"
              class="hidden"
              type="file"
            />

            <v-menu>
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  color="primary"
                >
                  Export
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="exportAs('text/csv')"> Export as CSV </v-list-item>
                <v-list-item @click="exportAs('application/json')"> Export as JSON </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-6" />

    <SectionHeader title="Teams" />

    <v-card
      v-for="team in teamAssignments"
      :key="team.id"
      class="mb-2 pa-3"
      border
      flat
      tile
    >
      <v-row class="d-flex align-center">
        <v-col>
          <span v-text="team.name" />
        </v-col>
        <v-col class="ta-right">
          <v-btn
            @click="removeTeam(team)"
            color="red"
            variant="text"
            icon
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <p
      v-if="schema.team_assignments.length == 0"
      v-t="'No assigned teams.'"
      class="mb-0"
    />

    <v-btn
      @click="$refs.newTeamAssignment.open()"
      class="mt-4"
      color="primary"
    >
      Add team
    </v-btn>

    <v-divider class="my-6" />

    <ResourceDialog
      @save="addTeamAssignment"
      ref="newTeamAssignment"
      :fields="teamAssignmentValues"
      title="Add team"
    />

    <DangerBar
      @destroy="destroy"
      @duplicate="duplicate"
      :processing="processing"
    />

    <SaveBar
      @cancel="loadSchema"
      @save="save"
      :model-value="changed"
      :processing="processing"
    />
  </v-container>
</template>

<script>
import Api from '@/shared/services/all_bright_finder';
import DangerBar from '@/shared/components/DangerBar.vue';
import SchemaForm from '@/admin/components/schemas/SchemaForm.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import SectionHeader from '@/shared/components/content/SectionHeader.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import { SCHEMA_DATA_TYPES } from '@/shared/assets/constants';

export default {
  compatConfig: { MODE: 2 },

  components: {
    DangerBar,
    LabeledTextfield,
    LabeledTextarea,
    LabeledSimpleSelect,
    ResourceDialog,
    SaveBar,
    SchemaForm,
    SectionHeader,
    TitleBar,
  },

  data() {
    return {
      changed: false,
      dataTypes: Object.values(SCHEMA_DATA_TYPES),
      processing: false,
      schema: null,
      schemas: [],
      teams: [],
    };
  },

  computed: {
    relevantSchemas() {
      return this.schemas.filter((schema) =>
        this.schema.associated_data_types.includes(schema.data_type),
      );
    },

    teamAssignments() {
      return this.teams.filter((team) =>
        this.schema.team_assignments.find((teamAssignment) => teamAssignment.team_id === team.id),
      );
    },

    teamAssignmentValues() {
      return [
        {
          text: 'New team',
          value: 'team_id',
          items: this.unassignedTeams,
          itemText: 'name',
          itemValue: 'id',
        },
      ];
    },

    unassignedTeams() {
      return this.teams.filter(
        (team) =>
          !this.schema.team_assignments.find(
            (teamAssignment) => teamAssignment.team_id === team.id,
          ),
      );
    },
  },

  watch: {
    '$route.params.schemaId': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.schema = null;
          this.loadSchemas();
          this.loadSchema();
          this.loadTeams();
        }
      },
    },
  },

  methods: {
    addTeamAssignment(team) {
      this.schema.team_assignments.push(team);
      this.$refs.newTeamAssignment.close();
      this.changed = true;
    },

    destroy() {
      // eslint-disable-next-line no-alert
      if (confirm('Are you sure you want to destroy this schema?')) {
        this.processing = true;
        Api.organization.schema.destroy(
          this.$route.params.schemaId,
          () => {
            this.$router.push({ name: 'SchemaIndex', query: { data_type: this.schema.data_type } });
          },
          (err) => {
            this.$eventBus.$emit('chime', err.response.data.errors[0]);
          },
        );
      }
    },

    duplicate() {
      // eslint-disable-next-line no-alert
      if (confirm('Are you sure you want to duplicate this schema?')) {
        this.processing = true;
        Api.organization.schema.create(
          { copy_id: this.$route.params.schemaId },
          (resp) => {
            this.$router.push({ name: 'SchemaShow', params: { schemaId: resp.data.id } });
          },
          (err) => {
            this.$eventBus.$emit('chime', err.response.data.errors[0]);
          },
        );
      }
    },

    async exportAs(format) {
      const url = [
        Api.organization.schema.url(this.$route.params.schemaId),
        format.split('/')[1],
      ].join('.');
      window.open(url);
    },

    importJson(selectEvent) {
      const reader = new FileReader();
      reader.addEventListener('load', (loadEvent) => {
        this.importMeta(loadEvent.target.result);
      });
      reader.readAsText(selectEvent.target.files[0]);
    },

    importMeta(jsonString) {
      this.processing = true;
      Api.organization.schema.update(
        this.$route.params.schemaId,
        { meta: JSON.parse(jsonString).meta },
        (resp) => {
          this.schema = resp.data;
          this.processing = false;
        },
        (err) => {
          this.$eventBus.$emit('error', err);
        },
      );
    },

    async loadSchema() {
      this.processing = true;
      const { data } = await Api.organization.schema.get(this.$route.params.schemaId);
      this.schema = data;
      this.processing = false;
      this.changed = false;
    },

    loadSchemas() {
      Api.public_api.organization.schema.index((resp) => {
        this.schemas = resp.data.filter(
          (schema) => schema.id && schema.id !== this.$route.params.schemaId,
        );
      });
    },

    loadTeams() {
      Api.organization.team.index({}, (resp) => {
        this.teams = resp.data;
      });
    },

    removeTeam(team) {
      this.schema.team_assignments.splice(
        this.schema.team_assignments.findIndex(
          (teamAssignment) => teamAssignment.team_id === team.id,
        ),
        1,
      );
      this.changed = true;
    },

    save() {
      this.processing = true;
      Api.organization.schema.update(
        this.$route.params.schemaId,
        this.schema,
        (resp) => {
          this.processing = false;
          this.schema = resp.data;
          this.changed = false;
        },
        (err) => {
          this.processing = false;
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },
  },
};
</script>
