export default class SearchFromSubsidyParams {
  constructor(subsidy, child, group, schema_id) {
    this.subsidy = subsidy;
    this.child = child;
    this.group = group;
    this.schema_id = schema_id;
  }

  asJson() {
    const home = this.formattedHome();
    return {
      children: [this.child],
      formatted_origin: this.group.formatted_home_address,
      group_id: this.group.id,
      location_type: 'home',
      origin: home,
      schema_id: this.schema_id,
      travel_mode: this.group.travel_mode || 'DRIVING',
      unformatted_origin: this.unformattedOrigin(),
      subsidy_program_id: this.subsidy.subsidy_program_id,
    };
  }

  formattedHome() {
    let formatted = {};
    if (this.group.home_latitude) {
      formatted = {
        address: this.group.home_address,
        city: this.group.home_city,
        latitude: this.group.home_latitude,
        longitude: this.group.home_longitude,
        state: this.group.home_state,
        zip: this.group.home_zip,
      };
    }
    return formatted;
  }

  unformattedOrigin() {
    let unformatted = [];
    if (this.group.home_address) {
      unformatted = [
        this.group.home_address,
        this.group.home_city,
        this.group.home_state,
        this.group.home_zip,
      ].join(', ');
    }
    return unformatted;
  }
}
