<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <PageTitle
      title="My profile"
      flat
      outlined
    />

    <v-container>
      <v-card
        class="mb-4"
        border
        flat
        tile
      >
        <v-card-title>Contact information</v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <LabeledTextfield
              v-model="profile.first_name"
              cols="6"
              message="First name"
            />
            <LabeledTextfield
              v-model="profile.last_name"
              cols="6"
              message="Last name"
            />
            <LabeledTextfield
              v-model="profile.email"
              cols="6"
              message="Email"
            />
            <LabeledTextfield
              v-model="profile.phone"
              cols="6"
              message="Business phone"
            />
          </v-row>
        </v-card-text>
        <v-divider class="my-4" />
        <v-card-actions>
          <v-btn
            @click="updateProfile"
            :loading="processing"
            color="primary"
            size="x-large"
          >
            <span v-t="'Update profile'" />
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
        v-show="$store.state.config.enable_password_authentication"
        class="mb-4"
        border
        flat
        tile
      >
        <v-card-title>Password</v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <LabeledTextfield
              v-model="newPassword"
              cols="6"
              message="New password"
              type="password"
            />
            <LabeledTextfield
              v-model="newPasswordConfirmation"
              cols="6"
              message="Confirm new password"
              type="password"
            />
          </v-row>
        </v-card-text>
        <v-divider class="my-4" />
        <v-card-actions>
          <v-btn
            @click="updatePassword"
            :disabled="!newPassword || newPassword != newPasswordConfirmation"
            :loading="processing"
            color="primary"
            size="x-large"
          >
            Update password
          </v-btn>
        </v-card-actions>
      </v-card>

      <MFASettingsCard
        v-show="$store.state.config.enable_mfa_authentication"
        @change="load"
        :profile="profile"
      />

      <VerticalSpacer :min-height="60" />
    </v-container>
  </div>
</template>

<script>
import API from '@/shared/mixins/api';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import MFASettingsCard from '@/shared/components/MFASettingsCard.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LabeledTextfield,
    MFASettingsCard,
    PageTitle,
    VerticalSpacer,
  },

  mixins: [API],

  data() {
    return {
      loaded: false,
      newPassword: null,
      newPasswordConfirmation: null,
      processing: false,
      profile: {},
    };
  },

  created() {
    this.load();
  },

  methods: {
    load() {
      this.api.organization.profile.get((resp) => {
        this.profile = resp.data;
      });
    },

    updatePassword() {
      this.processing = true;
      this.api.organization.profile.update(
        { password: this.newPassword },
        (resp) => {
          this.profile = resp.data;
          this.$eventBus.$emit('chime', 'Password saved');
          this.processing = false;
        },
        (err) => {
          this.$eventBus.$emit('chime', err);
          this.processing = false;
        },
      );
    },

    updateProfile() {
      this.processing = true;
      this.api.organization.profile.update(
        this.profile,
        (resp) => {
          this.profile = resp.data;
          this.$eventBus.$emit('chime', 'Saved');
          this.processing = false;
        },
        (err) => {
          this.$eventBus.$emit('error', err);
          this.processing = false;
        },
      );
    },
  },
};
</script>
