<template>
  <v-hover v-slot="{ hover }">
    <v-card
      @click="readonly ? false : $emit('click')"
      :class="cardClass"
      :elevation="hover || selected ? 4 : 1"
      :ripple="false"
      data-testid="choice-box-card"
    >
      <v-img
        :src="src"
        class="mx-auto"
        height="100"
        width="200"
      >
        <v-icon
          v-if="selected"
          class="fs-30"
          color="primary"
          style="float: right"
        >
          check_circle
        </v-icon>
      </v-img>
      <v-card-text>
        <div class="fs-22 fw-800 c-black ta-center">
          <slot name="text">
            <div v-text="text" />
          </slot>
        </div>
        <div class="fs-16 fw-500 c-black ta-center">
          <slot name="subtext">
            <div
              v-if="subtext"
              v-text="subtext"
            />
          </slot>
        </div>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script setup>
defineEmits(['click']);

const props = defineProps({
  readonly: Boolean,
  selected: Boolean,
  src: {
    type: String,
    default: null,
  },
  text: {
    type: String,
    default: null,
  },
  subtext: {
    type: String,
    default: null,
  },
});

const cardClass = computed(() => {
  let base = 'pt-5 pb-2 px-5 fullwidth mxw-400 mx-auto';
  if (props.readonly) {
    base += ' cursor-default';
  }
  return base;
});
</script>

<style>
.cursor-default {
  cursor: default !important;
}
</style>
