<template>
  <div v-if="loaded">
    <BreadcrumbTitle
      :back-name="schemaName"
      name="Schema shares"
    />

    <NullState
      v-if="schemaShares.length == 0"
      @new="createDialog.open()"
      new-button-title="Add schema share"
    />

    <v-row>
      <v-col>
        <DataItem
          v-for="schemaShare in schemaShares"
          @remove="destroy(schemaShare)"
          :key="schemaShare.id"
          :header="schemaShare.organization_name"
          :name="schemaShare.from_name"
        />
      </v-col>
    </v-row>

    <v-btn
      v-if="schemaShares.length > 0"
      @click="createDialog.open()"
      class="mt-4"
      color="primary"
    >
      {{ $t('Add schema share') }}
    </v-btn>

    <ResourceDialog
      @save="create"
      ref="createDialog"
      :fields="createFields"
      :processing="processing"
      title="New schema share"
    />
  </div>
</template>

<script setup>
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import DataItem from '@/shared/components/form/DataItem.vue';
import organizationApi from '@/specialist/services/bright_finder';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute } from 'vue-router';

const route = useRoute();
const eventBus = useEventBus();

const createDialog = ref(null);
const loaded = ref(false);
const processing = ref(false);
const publicSchemas = ref([]);
const schemaId = ref(null);
const schemaName = ref(null);
const schemaShares = ref([]);
const createFields = computed(() => {
  return [
    {
      text: 'Source',
      value: 'schemaId',
      items: publicSchemas.value,
      itemText: (i) => `${i.name} (${i.organization_name})`,
      itemValue: 'id',
      required: true,
    },
  ];
});

onMounted(load);

async function load() {
  loaded.value = false;
  schemaId.value = route.params.schemaId;
  await loadSchema();
  await loadPublicSchemas();
  await loadSchemaShares();
  loaded.value = true;
}

async function loadSchema() {
  const { data } = await organizationApi.organization.schema.get(schemaId.value);
  schemaName.value = data.name;
}

async function loadSchemaShares() {
  const { data } = await organizationApi.organization.schema_share.index(schemaId.value);
  schemaShares.value = data.map((schemaShare) => {
    const publicSchema = publicSchemas.value.find((schema) => schema.id === schemaShare.from_id);
    return {
      id: schemaShare.id,
      from_id: schemaShare.from_id,
      from_name: publicSchema?.name,
      organization_name: publicSchema?.organization_name,
    };
  });
  publicSchemas.value = publicSchemas.value.filter(
    (schema) => !schemaShares.value.find((schemaShare) => schemaShare.from_id === schema.id),
  );
}

async function loadPublicSchemas() {
  const { data } = await organizationApi.organization.public_schema.index();
  publicSchemas.value = data;
}

async function create(newVal) {
  processing.value = true;
  const resp = await organizationApi.organization.schema_share.create(
    schemaId.value,
    newVal.schemaId,
  );
  processing.value = false;
  if (resp?.status !== 201) return;

  eventBus.chime('Schema share created');
  createDialog.value.close();
  await load();
}

async function destroy(schemaShare) {
  processing.value = true;
  const resp = await organizationApi.organization.schema_share.destroy(schemaShare);
  processing.value = false;
  if (resp?.status !== 200) return;

  eventBus.chime('Schema share deleted');
  await load();
}
</script>
