<template>
  <div v-if="provider">
    <v-card
      :border="outlined"
      class="c-black"
      tile
    >
      <template v-if="ordered">
        <v-row class="d-flex align-center">
          <v-col
            class="d-flex align-center justify-start"
            cols="8"
          >
            <div class="pa-3">
              <template v-if="canEditPreferences">
                <EditableSelectionRanking
                  @move="$emit('move', $event)"
                  :index="index"
                  :length="length"
                />
              </template>
              <template v-else>
                <SelectionRanking :index="index" />
              </template>
            </div>
          </v-col>
          <v-col class="d-flex align-center justify-end me-4">
            <template v-if="displayPublicStatus">
              <v-chip v-show="enrollment.status != enrollment.public_status">
                <v-icon start> public </v-icon>
                <span v-t="getStatusText(enrollment.public_status)" />
              </v-chip>
            </template>
          </v-col>
        </v-row>

        <v-divider />
      </template>

      <v-card-text class="pa-3">
        <v-row
          class="d-flex align-center"
          dense
        >
          <v-col cols="8">
            <span
              v-t="provider.name"
              class="c-black fw-600 fs-20"
              dense
            />
          </v-col>
          <v-col
            v-if="enrollment"
            class="d-flex justify-end"
            cols="4"
          >
            <v-btn
              @click="openEditDialog"
              :disabled="!canEditEnrollment"
              aria-label="Edit enrollment"
              class="me-3"
              color="primary"
              variant="outlined"
              tile
            >
              {{ $t(getDisplayStatus(enrollment)) }}
            </v-btn>

            <template v-if="canDelete">
              <ActionMenu
                @click:action:destroy="destroy"
                @click:action:draft-due-date="$refs.dueDateDialog.open(enrollment)"
                :items="actionItems"
                left
              />
            </template>

            <ResourceDialog
              @change="checkStatusChange($event)"
              @save="confirmStatusChangeAndUpdate"
              ref="editDialog"
              :fields="editFields"
              :loading="processing"
              :max-width="700"
              :processing="processing"
              title="Edit enrollment"
            >
              <template
                v-if="canEditEnrollmentDates"
                #append="{ localValue }"
              >
                <template v-if="DUE_DATE_STATUSES.includes(localValue.status)">
                  <v-divider class="my-2" />
                  <div class="my-4">
                    <p
                      v-t="'Response deadline'"
                      class="c-black fs-16 fw-500 mb-2"
                    />

                    <v-date-picker
                      @update:model-value="setEnrollmentResponseDueDate"
                      :landscape="$vuetify.display.mdAndUp"
                      :min="formatDate(today)"
                      :model-value="enrollmentResponseDueDateObject"
                      class="b-1 dense bc-primary"
                      data-testid="enrollment-due-date-picker"
                      full-width
                    />
                  </div>
                </template>
                <template v-else-if="localValue.status == ENROLLMENT_STATUSES.ENROLLED">
                  <template v-if="subsidyProgram.enrollment_start_date_required">
                    <v-divider class="my-2" />
                    <div class="my-4">
                      <p
                        v-t="'Start date'"
                        class="c-black fs-16 fw-500 mb-2"
                      />

                      <v-date-picker
                        @update:model-value="setEnrollmentStartDate"
                        :landscape="$vuetify.display.mdAndUp"
                        :model-value="enrollmentStartDateObject"
                        class="b-1 dense bc-primary"
                        data-testid="enrollment-start-date-picker"
                        full-width
                      />
                    </div>
                  </template>
                </template>

                <template v-else-if="END_DATE_STATUSES.includes(localValue.status)">
                  <v-divider class="my-2" />
                  <div
                    v-if="unenrollFutureStartDate(localValue.status)"
                    class="my-4"
                  >
                    <p
                      v-t="
                        `By unenrolling before the start date, you're confirming the child never attended.`
                      "
                    />
                  </div>

                  <div
                    v-else
                    class="my-4"
                  >
                    <p
                      v-t="'Enter the last day the child attended the program'"
                      class="c-black fs-16 fw-500 mb-2"
                    />

                    <v-date-picker
                      @update:model-value="setEnrollmentEndDate"
                      :landscape="$vuetify.display.mdAndUp"
                      :max="formatDate(today)"
                      :min="minimumEnrollmentEndDate"
                      :model-value="enrollmentEndDateObject"
                      class="b-1 dense bc-primary"
                      data-testid="enrollment-end-date-picker"
                      full-width
                    />
                  </div>
                </template>

                <template v-if="showStatusPublishesNotice(localValue.status)">
                  <p class="mt-3 fs-16">
                    <strong
                      v-t="'Note:'"
                      class="me-1"
                    />
                    <span
                      v-t="
                        'This enrollment status change will be automatically published to the family.'
                      "
                    />
                  </p>
                </template>
              </template>
            </ResourceDialog>
          </v-col>
        </v-row>

        <v-row
          class="ma-0 pa-0"
          dense
        >
          <v-col class="d-flex align-center">
            <div
              v-if="providerAddress()"
              class="d-flex"
            >
              <div class="fs-16 fw-400">
                {{ providerAddress() }}
              </div>
            </div>
            <template v-if="$store.state.profile.org_enrollments_access">
              <span class="mx-3 fw-400 fw-14">|</span>
              <router-link
                :to="{ name: 'ProviderShow', params: { providerId: provider.id } }"
                class="fs-14 fw-400"
                color="secondary"
                target="_blank"
                small
              >
                <span v-t="'View profile'" />
              </router-link>
            </template>
          </v-col>
        </v-row>

        <v-row
          v-if="provider.quality_rating_score > 0"
          dense
        >
          <v-col class="d-flex align-center justify-start">
            <img
              v-if="
                $store.state.site['Custom Ratings'] &&
                $store.state.site['Custom Ratings'].length > 0
              "
              :src="$store.state.site['Custom Ratings'][0].pass"
              alt="Custom Quality Rating Score"
              height="20"
            />
            <v-icon
              v-else
              alt=""
              color="#FFD200"
              role="presentation"
              size="20"
            >
              star
            </v-icon>
            <span class="fs-16 fw-400">
              {{ $t(provider.quality_rating_name) }}: {{ provider.quality_rating_score }}
              {{ $t('out of') }} {{ provider.quality_rating_max }}
            </span>
          </v-col>
        </v-row>

        <template v-if="programs.length > 0 && enrollment.program_id">
          <v-divider class="my-4" />
          <v-card flat>
            <v-card-text class="pa-0">
              <v-row
                class="d-flex align-center"
                dense
              >
                <v-col class="d-flex align-center fs-16 fw-500">
                  <v-icon
                    color="black"
                    size="20"
                    start
                  >
                    chair_alt
                  </v-icon>
                  <span
                    v-text="programs.find((program) => program.id == enrollment.program_id).name"
                  />
                </v-col>
                <v-col class="d-flex justify-end">
                  <template v-if="enrollment.relationships.seat_type">
                    <v-chip
                      @click:close="releaseSeat(enrollment.relationships.seat.data.id)"
                      :closable="
                        [ENROLLMENT_STATUSES.DECLINED, ENROLLMENT_STATUSES.WITHDRAWN].includes(
                          enrollment.status,
                        )
                      "
                    >
                      {{ enrollment.relationships.seat_type?.data?.attributes?.name }}
                    </v-chip>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>

        <template v-if="enrollment.due_date || enrollment.start_date || enrollment.end_date">
          <v-divider class="my-4" />
          <v-card flat>
            <v-card-text class="pa-0">
              <v-row
                class="d-flex align-center"
                dense
              >
                <v-col class="fs-16">
                  <LongDate
                    :date="enrollment.due_date"
                    prefix="Response deadline:"
                  />
                  <br v-if="enrollment.due_date" />
                  <LongDate
                    :date="enrollment.start_date"
                    prefix="Enrollment start date:"
                  />
                  <br v-if="enrollment.start_date" />
                  <LongDate
                    :date="enrollment.end_date"
                    prefix="Enrollment end date:"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>

        <template v-if="fundingSources.length > 0 && enrollment.funding_source_ids.length > 0">
          <v-divider class="my-4" />
          <v-chip
            v-for="fsid in enrollment.funding_source_ids"
            :key="fsid"
            class="me-3"
          >
            {{ fundingSources.find((fundingSource) => fundingSource.id == fsid).name }}
          </v-chip>
        </template>
      </v-card-text>
    </v-card>

    <FormReviewDialog
      ref="formReviewDialog"
      show-unpublished-questions
    />

    <FormCard
      v-for="form in forms"
      @destroy="destroyForm(form)"
      @status="setFormStatus(form, $event)"
      :key="form.id"
      :form="form"
      :programs="programs"
      :provider="provider"
      back="subsidy"
    />

    <template v-if="enrollment && subsidyProgram?.enrolled_form_schema_id">
      <FormDialog
        @save="update"
        ref="enrolledDialog"
        :enrollment-id="enrollment.id"
        :group-id="enrollment.group_id"
        :provider-id="enrollment.provider_id"
        :schema-id="subsidyProgram.enrolled_form_schema_id"
        :subsidy-id="enrollment.subsidy_id"
        show-unpublished-questions
      />
    </template>

    <template v-if="enrollment && subsidyProgram?.unenroll_form_schema_id">
      <FormDialog
        @save="update"
        ref="unenrollDialog"
        :enrollment-id="enrollment.id"
        :group-id="enrollment.group_id"
        :provider-id="enrollment.provider_id"
        :schema-id="subsidyProgram.unenroll_form_schema_id"
        :subsidy-id="enrollment.subsidy_id"
      />
    </template>

    <template v-if="enrollment && subsidyProgram?.withdraw_form_schema_id">
      <FormDialog
        @save="update"
        ref="withdrawDialog"
        :enrollment-id="enrollment.id"
        :group-id="enrollment.group_id"
        :provider-id="enrollment.provider_id"
        :schema-id="subsidyProgram.withdraw_form_schema_id"
        :subsidy-id="enrollment.subsidy_id"
      />
    </template>

    <ResourceDialog
      @save="updateDueDate"
      ref="dueDateDialog"
      :fields="[{ text: 'Set response deadline', value: 'due_date', type: 'date' }]"
      title="Update response deadline"
    />
  </div>
</template>

<script>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import AgeCalculations from '@/shared/mixins/age_calculations';
import API from '@/shared/mixins/api';
import EditableSelectionRanking from '@/shared/components/subsidy/EditableSelectionRanking.vue';
import { ENROLLMENT_STATUSES } from '@/shared/assets/constants';
import { formatAddress } from '@/shared/services/address';
import FormCard from '@/specialist/components/forms/FormCard.vue';
import FormReviewDialog from '@/shared/components/form/FormReviewDialog.vue';
import LongDate from '@/shared/components/LongDate.vue';
import SelectionRanking from '@/shared/components/subsidy/SelectionRanking.vue';
import Statuses from '@/shared/mixins/statuses';
import FormDialog from '@/shared/components/form/FormDialog.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import Terms from '@/shared/mixins/terms';
import { newUtcDate } from '@/shared/services/date-helper';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ActionMenu,
    EditableSelectionRanking,
    FormCard,
    FormDialog,
    FormReviewDialog,
    LongDate,
    ResourceDialog,
    SelectionRanking,
  },

  mixins: [AgeCalculations, API, Statuses, Terms],

  props: {
    enrollment: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    length: {
      type: Number,
      default: 0,
    },
    outlined: Boolean,
    fundingSources: {
      type: Array,
      default: () => [],
    },
    ordered: Boolean,
    readonly: Boolean,
    subsidy: {
      type: Object,
      default: null,
    },
    subsidyProgram: {
      type: Object,
      default: null,
    },
  },

  emits: ['change', 'change:destroy', 'move'],

  data() {
    return {
      ENROLLMENT_STATUSES,
      DUE_DATE_STATUSES: [ENROLLMENT_STATUSES.OFFERED, ENROLLMENT_STATUSES.ACCEPTED],
      END_DATE_STATUSES: [ENROLLMENT_STATUSES.UNENROLLED, ENROLLMENT_STATUSES.GRADUATED],
      canDelete:
        this.$store.state.profile.org_enrollments_delete ||
        (this.$store.state.profile.org_enrollments_agent && !this.subsidy.locked),
      deferredAcceptanceEnabled: this.subsidyProgram.enable_deferred_acceptance,
      forms: [],
      processing: false,
      programs: [],
      provider: null,
      enrollmentResponseDueDate: null,
      enrollmentEndDate: null,
      enrollmentStartDate: null,
      statusMenu: false,
      updatedEnrollment: null,
    };
  },

  computed: {
    actionItems() {
      const items = [{ event: 'destroy', title: 'Delete', avatar: 'warning' }];
      if (!this.deferredAcceptanceEnabled) return items;
      if (!this.canEditEnrollmentDates) return items;

      if (
        this.enrollment.status === ENROLLMENT_STATUSES.OFFERED ||
        this.enrollment.status === ENROLLMENT_STATUSES.ACCEPTED
      ) {
        items.push({ event: 'draft-due-date', title: 'Set due date', avatar: 'event' });
      }

      return items;
    },

    editFields() {
      if (this.$store.state.profile.org_enrollments_edit) {
        const fields = [
          {
            text: this.terms.program,
            value: 'program_id',
            items: this.programs,
            itemText: 'name',
            itemValue: 'id',
          },
          {
            text: 'Status',
            value: 'status',
            items: this.getValidStatusChanges(
              this.enrollment.status,
              this.deferredAcceptanceEnabled,
              this.$store.state.profile,
            ),
            itemText: 'text',
            itemValue: 'value',
          },
        ];

        if (this.fundingSources.length > 0) {
          fields.push({
            text: 'Funding sources',
            value: 'funding_source_ids',
            items: this.fundingSources,
            itemText: 'name',
            itemValue: 'id',
            multiple: true,
          });
        }

        return fields;
      }

      if (this.$store.state.profile.org_enrollments_agent) {
        return [
          {
            text: 'Status',
            value: 'status',
            items: this.getValidStatusChanges(
              this.enrollment.status,
              this.deferredAcceptanceEnabled,
              this.$store.state.profile,
            ),
          },
        ];
      }

      return [];
    },

    enrollmentStartDateObject() {
      return this.dateAsObject(this.enrollmentStartDate);
    },

    enrollmentEndDateObject() {
      return this.dateAsObject(this.enrollmentEndDate);
    },

    enrollmentResponseDueDateObject() {
      return this.dateAsObject(this.enrollmentResponseDueDate);
    },

    canEditEnrollment() {
      if (this.$store.state.profile.org_enrollments_edit) return true;

      if (this.$store.state.profile.org_enrollments_agent) {
        return this.enrollment.status === ENROLLMENT_STATUSES.OFFERED;
      }

      return false;
    },

    canEditEnrollmentDates() {
      return this.$store.state.profile.org_enrollments_edit;
    },

    canEditPreferences() {
      if (this.$store.state.profile.org_enrollments_edit) return true;

      if (this.$store.state.profile.org_enrollments_agent) {
        return !this.subsidy.locked || this.subsidy.preferences_unlocked;
      }

      return false;
    },

    displayPublicStatus() {
      return this.deferredAcceptanceEnabled && this.$store.state.profile.org_enrollments_access;
    },

    today() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
    },
  },

  created() {
    this.load();
    this.loadForms();
  },

  methods: {
    checkStatusChange(newVal) {
      if (newVal[0] === 'status') {
        const newStatus = newVal[1];
        this.enrollmentResponseDueDate = null;

        if (newStatus === ENROLLMENT_STATUSES.ACCEPTED) {
          const programDueDate = this.subsidyProgram.enrollment_due_date;
          const rollingDueDateDays = this.subsidyProgram.enrollment_rolling_due_date_days;

          if (programDueDate && this.today < new Date(programDueDate)) {
            this.enrollmentResponseDueDate = programDueDate;
          } else if (rollingDueDateDays) {
            // If you directly try to assign the result of setDate to a constant, you’re actually assigning the timestamp (number) to the constant so rollingDueDate will be a number
            // not a Date object, resulting in undefined
            const newDate = new Date();
            newDate.setDate(this.today.getDate() + rollingDueDateDays);
            const rollingDueDate = newDate;
            this.enrollmentResponseDueDate = new Date(rollingDueDate);
          }
        } else if (newStatus === ENROLLMENT_STATUSES.ENROLLED) {
          const startDate = this.subsidyProgram.enrollment_start_date;

          if (this.enrollment.start_date) {
            this.enrollmentStartDate = this.enrollment.start_date;
          } else if (startDate && this.today < new Date(startDate)) {
            this.enrollmentStartDate = startDate;
          }
        }
      }
    },

    // Take a Date or a String as an argument and return a Date object.
    // If the argument is a Date object, return it; otherwise, assume the object
    // is a string, parse it, and return the resulting Date object.
    dateAsObject(dateObjectOrString) {
      if (dateObjectOrString instanceof Date && !isNaN(dateObjectOrString))
        return dateObjectOrString;

      return dateObjectOrString ? this.$vuetify.date.parseISO(dateObjectOrString) : null;
    },

    setEnrollmentStartDate(newDateString) {
      this.enrollmentStartDate = newDateString;
    },

    setEnrollmentEndDate(newDateString) {
      this.enrollmentEndDate = newDateString;
    },

    setEnrollmentResponseDueDate(newDateString) {
      this.enrollmentResponseDueDate = newDateString;
    },

    unenrollFutureStartDate(status) {
      return (
        status === ENROLLMENT_STATUSES.UNENROLLED &&
        new Date(this.enrollment.start_date) > this.today
      );
    },

    confirmStatusChangeAndUpdate(newVal) {
      this.updatedEnrollment = newVal;
      const newStatus = newVal.status;

      if (this.enrollment.status === newStatus) {
        this.update();
        return;
      }

      let providerAndProgram = this.provider.name;
      const program = this.programs.find((program) => program.id === this.enrollment.program_id);
      if (program) providerAndProgram += `/${program.name}`;

      // eslint-disable-next-line no-alert
      if (
        !confirm(
          `Are you certain you want to change the status for this child's enrollment at ${providerAndProgram} to ${this.getStatusText(newStatus).toUpperCase()}?`,
        )
      )
        return;

      if (
        newStatus === ENROLLMENT_STATUSES.ENROLLED &&
        this.subsidyProgram.enrolled_form_schema_id
      ) {
        this.$refs.enrolledDialog.open();
      } else if (
        newStatus === ENROLLMENT_STATUSES.UNENROLLED &&
        this.subsidyProgram.unenroll_form_schema_id
      ) {
        this.$refs.unenrollDialog.open();
      } else if (
        newStatus === ENROLLMENT_STATUSES.WITHDRAWN &&
        this.subsidyProgram.withdraw_form_schema_id
      ) {
        this.$refs.withdrawDialog.open();
      } else {
        this.update();
      }
    },

    async destroy() {
      // eslint-disable-next-line no-alert
      if (!confirm('Are you sure you want to delete this enrollment?')) return;

      this.processing = true;
      const response = await this.api.organization.enrollment.destroy(this.enrollment.id);
      this.processing = false;

      if (response?.status !== 200) return;

      this.$emit('change:destroy', response.data);
    },

    async destroyForm(form) {
      // eslint-disable-next-line no-alert
      if (!confirm('Are you sure you want to destroy this form?')) return;

      await this.api.organization.form.destroy(form.id);
      this.loadForms();
    },

    formatDate(date) {
      return date.toISOString().substring(0, 10);
    },

    getDisplayStatus(enrollment) {
      if (this.$store.state.profile.org_enrollments_access)
        return this.getStatusText(enrollment.status);
      return this.getStatusText(enrollment.public_status);
    },

    async load() {
      await this.loadProvider();
      await this.loadPrograms();
    },

    async loadForms() {
      const params = {
        enrollment_id: this.enrollment.id,
        subsidy_id: this.enrollment.subsidy_id,
      };

      const { data } = await this.api.organization.form.index(params);
      this.forms = data;
    },

    async loadPrograms() {
      const params = { provider_id: this.enrollment.provider_id };
      const resp = await this.api.public_api.organization.program.index(params);
      this.programs = resp.data;
    },

    async loadProvider() {
      const resp = await this.api.public_api.organization.provider.get(this.enrollment.provider_id);
      this.provider = resp.data;
    },

    openEditDialog() {
      this.enrollmentResponseDueDate = this.enrollment.due_date;
      this.enrollmentStartDate = this.enrollment.start_date;

      // v-date-picker model expects UTC date format like 'Fri Jun 14 2024 05:32:46 GMT-0700', not string '2024-06-13'
      const endDateUtc = this.enrollment.end_date ? newUtcDate(this.enrollment.end_date) : null;
      const todayUtc = this.today ? newUtcDate(this.today) : null;

      this.enrollmentEndDate = endDateUtc || todayUtc;
      this.minimumEnrollmentEndDate = this.enrollment.start_date;
      this.$refs.editDialog.open(JSON.parse(JSON.stringify(this.enrollment)));
    },

    async releaseSeat(seatId) {
      // eslint-disable-next-line no-alert
      if (!confirm('Are you sure you want to release this seat?')) return;

      const params = {
        id: seatId,
        enrollment_id: null,
      };
      await this.api.organization.seat.update(params);
      // eslint-disable-next-line vue/no-mutating-props
      this.enrollment.relationships = {};
    },

    providerAddress() {
      if (!this.provider) return null;

      return formatAddress(this.provider.address1, this.provider.city, this.provider.zip);
    },

    async update(form) {
      this.processing = true;
      const params = {
        funding_source_ids: this.updatedEnrollment.funding_source_ids,
        program_id: this.updatedEnrollment.program_id,
        status: this.updatedEnrollment.status,
      };

      if (
        this.updatedEnrollment.status === ENROLLMENT_STATUSES.OFFERED ||
        this.updatedEnrollment.status === ENROLLMENT_STATUSES.ACCEPTED
      ) {
        params.due_date = this.enrollmentResponseDueDate;
        params.publish = true;
      } else if (
        this.updatedEnrollment.status === ENROLLMENT_STATUSES.UNENROLLED &&
        new Date(this.enrollment.start_date) > this.today
      ) {
        params.end_date = null;
      } else if (this.updatedEnrollment.status === ENROLLMENT_STATUSES.ENROLLED) {
        params.enrolled_form_id = form?.id;
        params.start_date = this.enrollmentStartDate;
        params.end_date = null;
      } else if (
        this.updatedEnrollment.status === ENROLLMENT_STATUSES.UNENROLLED ||
        this.updatedEnrollment.status === ENROLLMENT_STATUSES.GRADUATED
      ) {
        params.end_date = this.enrollmentEndDate;
      }

      const resp = await this.api.organization.enrollment.update(this.enrollment.id, params);
      this.processing = false;
      if (resp?.status !== 200) return false;

      this.$emit('change', resp.data);
      this.$refs.editDialog.close();
      this.load();
      if (form) this.loadForms();
      return true;
    },

    setFormStatus(form, newVal) {
      let status;
      if (newVal.title === 'Approve') status = 'Approved';
      else if (newVal.title === 'Deny') status = 'Denied';

      this.updateForm(form.id, { status });
    },

    showStatusPublishesNotice(status) {
      return (
        this.isPublishNoticeStatus(status) &&
        this.subsidyProgram.enable_deferred_acceptance === true
      );
    },

    async updateDueDate(newVal) {
      this.processing = true;
      await this.api.organization.enrollment.update(this.enrollment.id, {
        due_date: newVal.due_date,
      });
      this.$refs.dueDateDialog.close();
      this.processing = false;
      this.$emit('change');
    },

    async updateForm(formId, newVal) {
      await this.api.organization.form
        .update(formId, newVal)
        .catch((error) => this.$eventBus.$emit('error', error));
      this.loadForms();
    },
  },
};
</script>
