<template>
  <div v-if="revision">
    <p class="mb-4">
      <MarkdownContent
        :content="revision?.description"
        class="c-light-black fs-16 mt-0"
      />
    </p>

    <div v-if="revision.author_signature_image">
      <v-row dense>
        <v-text-field
          :model-value="revision.author_signed_name"
          data-cy="text-name-input"
          label="Full name"
          variant="filled"
          readonly
        />
      </v-row>
      <v-row dense>
        <v-col
          class="ta-center"
          cols="12"
        >
          <canvas
            id="draw-canvas"
            data-cy="esign-canvas"
            height="125"
            width="350"
          />
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row dense>
        <v-text-field
          :model-value="revision.author_signed_name"
          data-cy="text-name-input"
          label="Full name"
          variant="filled"
          readonly
        />
      </v-row>
      <v-row dense>
        <v-col
          class="esign-preview"
          cols="12"
        >
          <div class="esign-preview-box d-flex align-center">
            <div
              v-text="revision.author_signed_name"
              class="esign-preview-text"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script setup>
import MarkdownContent from '@/shared/components/MarkdownContent.vue';

const props = defineProps({
  revision: {
    type: Object,
    default: null,
  },
});

onMounted(load);

async function load() {
  if (props.revision && props.revision.author_signature_image) {
    const canvas = document.getElementById('draw-canvas');
    const context = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      context.drawImage(img, 0, 0); // Or at whatever offset you like
    };
    img.src = props.revision.author_signature_image;
  }
}
</script>

<style>
.esign-preview {
  vertical-align: middle;
  background-color: #eee;
  padding: 16px;
  overflow-x: scroll;
}

.esign-preview-box {
  height: 125px;
}

.esign-preview-text {
  white-space: nowrap;
  font-size: 44px;
  font-family: 'The Nautigal', cursive;
}
</style>
