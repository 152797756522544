<template>
  <div v-if="subsidyProgram">
    <BreadcrumbTitle
      :back-name="subsidyProgram.name"
      name="Stages"
    />

    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <LabeledSwitch
            v-model="subsidyProgram.enable_stages"
            @input="changed = true"
            subtitle="You can customize your application review process by creating stages for each step in your workflow."
            title="Enable stages"
            divided-bottom
          />

          <LabeledSwitch
            v-model="subsidyProgram.enable_skipping_stages"
            @input="changed = true"
            :disabled="!subsidyProgram.enable_stages"
            title="Enable skipping stages"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <NullState
      v-if="subsidyProgram.stages.length == 0"
      @new="$refs.newStageDialog.open({})"
      :new-button-disabled="!subsidyProgram.enable_stages"
      new-button-title="New stage"
    />

    <div
      v-for="(stage, index) in subsidyProgram.stages"
      :key="index"
      class="mb-4"
    >
      <StageCard
        @change="changed = true"
        @move="reorder(index, index + $event)"
        @remove="remove(index)"
        :features="features"
        :index="index"
        :length="subsidyProgram.stages.length"
        :owners="ownerItems"
        :stage="stage"
        :teams="teams"
      />
    </div>

    <v-btn
      v-if="subsidyProgram.stages.length > 0"
      @click="$refs.newStageDialog.open({})"
      class="mt-4"
      color="primary"
    >
      New
    </v-btn>

    <ResourceDialog
      @save="addStage"
      ref="newStageDialog"
      :fields="stageValues"
      :processing="processing"
      title="New stage"
    />

    <VerticalSpacer :min-height="24" />

    <SaveBar
      @cancel="load"
      @save="save"
      :model-value="changed"
      :processing="processing"
      flat
    />
  </div>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import StageCard from '@/specialist/components/StageCard.vue';
import Subsidy from '@/shared/mixins/subsidy';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  compatConfig: { MODE: 2 },

  components: {
    BreadcrumbTitle,
    LabeledSwitch,
    NullState,
    ResourceDialog,
    SaveBar,
    StageCard,
    VerticalSpacer,
  },

  mixins: [Subsidy],

  props: {
    api: {
      type: Object,
      default() {
        return Api;
      },
    },
  },

  data() {
    return {
      changed: false,
      draftDialogIsVisible: false,
      features: [
        { title: 'Enable application', field: 'enable_application' },
        { title: 'Enable eligibility', field: 'enable_eligibility' },
        { title: 'Enable reviews', field: 'enable_reviews' },
        { title: 'Enable enrollment', field: 'enable_enrollment' },
        { title: 'Enable funding', field: 'enable_funding' },
      ],
      newOwnerId: null,
      newTitle: null,
      ownerItems: [],
      processing: false,
      subsidyProgram: null,
      teams: [],
    };
  },

  computed: {
    stageValues() {
      return [
        { text: 'Title', value: 'title' },
        {
          text: 'Stage owner',
          value: 'owner_id',
          items: this.ownerItems,
          itemText: 'name',
          itemValue: 'id',
        },
      ];
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    'subsidyProgram.enable_stages': function (newVal) {
      if (!newVal) {
        this.subsidyProgram.enable_skipping_stages = false;
        this.changed = true;
      }
    },
  },

  created() {
    this.subsidyProgramId = this.$route.params.subsidyProgramId;
    this.load();
  },

  methods: {
    addStage(newVal) {
      const stage = newVal;
      stage.id = uuidv4();
      this.subsidyProgram.stages.push(stage);
      this.$refs.newStageDialog.close();
      this.changed = true;
    },

    load() {
      this.changed = false;
      this.loadSubsidyProgram(true);
      Api.organization.member.index({ is_specialist: true }, (resp) => {
        this.ownerItems = resp.data;
      });

      Api.organization.team.index({}, (resp) => {
        this.teams = resp.data;
      });
    },

    loadSubsidyProgram() {
      this.api.organization.subsidy_program.get(this.subsidyProgramId, (resp) => {
        this.subsidyProgram = resp.data;
      });
    },

    remove(index) {
      // eslint-disable-next-line no-alert
      if (confirm('Are you sure you want to remove this stage?')) {
        this.subsidyProgram.stages.splice(index, 1);
        this.changed = true;
        this.save();
      }
    },

    reorder(fromIndex, toIndex) {
      const element = this.subsidyProgram.stages[fromIndex];
      this.subsidyProgram.stages.splice(fromIndex, 1);
      this.subsidyProgram.stages.splice(toIndex, 0, element);
      this.changed = true;
    },

    save() {
      this.processing = true;
      Api.organization.subsidy_program.update(
        this.$route.params.subsidyProgramId,
        {
          enable_stages: this.subsidyProgram.enable_stages,
          enable_skipping_stages: this.subsidyProgram.enable_skipping_stages,
          stages: this.subsidyProgram.stages,
        },
        (resp) => {
          this.processing = false;
          this.subsidyProgram = resp.data;
          this.changed = false;
        },
        (err) => {
          this.load();
          this.processing = false;
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },
  },
};
</script>
