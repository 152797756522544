<template>
  <v-card
    border
    flat
    tile
  >
    <v-card-text>
      <v-row role="row">
        <TableCell
          :content="group.name"
          :to="{ name: 'GroupShow', params: { groupId: group.id } }"
          cols="3"
          target="_self"
        />
        <TableCell
          :content="group.primary_email"
          cols="3"
        />
        <TableCell
          :content="group.primary_phone"
          cols="2"
        />
        <TableCell
          :content="location"
          cols="3"
        />
        <TableCell
          :content="group.created_at"
          cols="1"
          transform="date"
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    group: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      location: this.getGroupLocation(),
      name: this.getName(),
    };
  },

  methods: {
    getGroupLocation() {
      return [
        this.group.home_address1,
        this.group.home_city,
        this.group.home_zip,
        this.group.home_county,
        this.group.home_state,
      ]
        .filter((el) => !!el)
        .join(', ');
    },

    getName() {
      return [this.group.primary_first_name, this.group.primary_last_name]
        .filter((el) => !!el)
        .join(', ');
    },
  },
};
</script>
