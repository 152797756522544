<template>
  <v-card
    v-if="site_template"
    class="px-6 pt-0 pb-6"
  >
    <v-row dense>
      <v-col cols="12">
        <div class="fs-28 fw-600 c-black mb-3" />
      </v-col>
      <v-col cols="12">
        <div class="fs-18 fw-600 c-black">What is the name of this template?</div>
      </v-col>
      <v-col
        class="mb-4"
        cols="12"
      >
        <v-text-field
          v-model="draft.name"
          placeholder="Name"
          variant="solo"
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <div class="fs-18 fw-600 c-black">How would you describe your new template?</div>
      </v-col>
      <v-col
        class="mb-4"
        cols="12"
      >
        <v-textarea
          v-model="draft.description"
          placeholder="Description"
          variant="solo"
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <div class="fs-18 fw-600 c-black">Public template</div>
      </v-col>
      <v-col
        class="mb-6 py-0"
        cols="12"
      >
        <v-checkbox
          v-model="draft.is_public"
          class="mt-2"
          label="Make this template publicly available."
          hide-details
          solo
        />
      </v-col>
      <v-col
        class="fs-18 fw-600"
        cols="12"
      >
        What type of template would you like to create?
      </v-col>
      <v-col
        class="pa-3"
        cols="12"
        md="4"
      >
        <ChoiceBox
          @click="draft.site_type = 'parent'"
          :selected="draft.site_type == 'parent'"
          :src="$a.url('images.search')"
          subtext="A search tool that allows families to find local child care providers."
          text="For Families"
        />
      </v-col>
      <v-col
        class="pa-3"
        cols="12"
        md="4"
      >
        <ChoiceBox
          @click="draft.site_type = 'manager'"
          :selected="draft.site_type == 'manager'"
          :src="$a.url('images.providers')"
          subtext="A site that allows child care providers to update their profile."
          text="For providers"
        />
      </v-col>
      <v-col
        class="pa-3"
        cols="12"
        md="4"
      >
        <ChoiceBox
          @click="draft.site_type = 'specialist'"
          :selected="draft.site_type == 'specialist'"
          :src="$a.url('images.organizations')"
          subtext="A site that allows your team to support families and providers."
          text="For my team"
        />
      </v-col>
      <v-col
        v-if="draft.id"
        class="mt-4"
        cols="12"
      >
        <div class="fs-18 fw-600 c-black">Update template from JSON file:</div>
      </v-col>
      <v-col
        v-if="draft.id"
        class="mb-6 py-0"
        cols="12"
      >
        <v-file-input
          v-model="json_file"
          @change="attachJsonUpload"
          accept="application/json"
          class="mt-2"
          label="Optional"
          variant="solo"
          hide-details
        />
      </v-col>
      <v-col
        class="mt-4"
        cols="12"
      >
        <v-btn
          @click="submit()"
          :disabled="processing"
          color="primary"
          rounded
        >
          {{ draft.id ? 'Save' : 'Create' }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import { useRouter } from 'vue-router';
import Api from '@/admin/services/bright_finder';
import useEventBus from '@/shared/composables/useEventBus';
import ChoiceBox from '@/admin/components/ChoiceBox.vue';

const emit = defineEmits(['refresh']);
const eventBus = useEventBus();
const props = defineProps({
  // eslint-disable-next-line vue/prop-name-casing
  site_template: {
    type: Object,
    default: null,
  },
});

const draft = ref(JSON.parse(JSON.stringify(props.site_template)));
const json_file = ref(null);
const processing = ref(false);
const router = useRouter();

function attachJsonUpload() {
  if (json_file.value) {
    const reader = new FileReader();
    reader.readAsText(json_file.value);
    reader.onload = () => {
      try {
        JSON.parse(reader.result);
        draft.value.json_upload = reader.result;
      } catch {
        eventBus.chime('Uploaded file has invalid JSON.');
        json_file.value = null;
      }
    };
  }
}

function submit() {
  processing.value = true;
  Api.organization.siteTemplate.updateOrCreate(
    {
      description: draft.value.description,
      id: draft.value.id,
      is_public: draft.value.is_public,
      json_upload: draft.value.json_upload,
      name: draft.value.name,
      site_type: draft.value.site_type,
    },
    (resp) => {
      if (draft.value.id) {
        eventBus.chime('Template saved.');
        emit('refresh');
        processing.value = false;
      } else {
        router.push({
          name: 'SiteTemplateShow',
          params: { siteTemplateId: resp.data.id },
        });
        processing.value = false;
      }
    },
    (err) => {
      eventBus.chime(err.response.data.errors[0]);
      processing.value = false;
    },
  );
}
</script>
