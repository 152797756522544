<template>
  <div v-if="grantProgram">
    <BreadcrumbTitle
      :back-name="grantProgram.name"
      name="Stages"
    />

    <v-card class="mb-8">
      <v-card-text>
        <v-row>
          <LabeledSwitch
            v-model="grantProgram.enable_stages"
            @input="changed = true"
            subtitle="You can customize your application review process by creating stages for each step in your workflow."
            title="Enable stages"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <NullState
      v-if="grantProgram.stages.length == 0"
      @new="$refs.newStageDialog.open({})"
      :new-button-disabled="!grantProgram.enable_stages"
      new-button-title="New stage"
    />

    <div
      v-for="(stage, index) in grantProgram.stages"
      :key="index"
      class="mb-4"
    >
      <StageCard
        @change="changed = true"
        @move="reorder(index, index + $event)"
        @remove="remove(index)"
        :features="features"
        :index="index"
        :length="grantProgram.stages.length"
        :owners="ownerOptions"
        :stage="stage"
        :teams="teams"
      />
    </div>

    <v-btn
      v-if="grantProgram.stages.length > 0"
      @click="$refs.newStageDialog.open({})"
      class="mt-4"
      color="primary"
      prepend-icon="add"
      block
    >
      {{ $t('New stage') }}
    </v-btn>

    <ResourceDialog
      @save="addStage"
      ref="newStageDialog"
      :fields="newStageFields"
      :processing="processing"
      title="New stage"
    />

    <ConfirmDialog ref="confirmDialog" />

    <VerticalSpacer :min-height="24" />

    <SaveBar
      @cancel="load"
      @save="save"
      :model-value="changed"
      :processing="processing"
      flat
    />
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import StageCard from '@/specialist/components/StageCard.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute } from 'vue-router';
import { v4 as uuidv4 } from 'uuid';

const eventBus = useEventBus();
const route = useRoute();

const features = [
  { title: 'Enable application', field: 'enable_application' },
  { title: 'Enable eligibility', field: 'enable_eligibility' },
  { title: 'Enable reviews', field: 'enable_reviews' },
  { title: 'Enable funding', field: 'enable_funding' },
];

const changed = ref(true);
const confirmDialog = ref(null);
const newStageDialog = ref(null);
const ownerOptions = ref([]);
const processing = ref(false);
const grantProgram = ref(null);
const grantProgramId = ref(null);
const teams = ref([]);

const newStageFields = computed(() => {
  return [
    { text: 'Title', value: 'title' },
    {
      text: 'Stage owner',
      value: 'owner_id',
      items: ownerOptions.value,
      itemText: 'name',
      itemValue: 'id',
    },
  ];
});

onMounted(() => {
  grantProgramId.value = route.params.grantProgramId;
  load();
});

function addStage(stage) {
  grantProgram.value.stages.push({ ...stage, id: uuidv4() });
  newStageDialog.value?.close();
  changed.value = true;
}

function load() {
  changed.value = false;
  loadGrantProgram();
  Api.organization.member.index({ is_specialist: true }, (resp) => {
    ownerOptions.value = resp.data;
  });
  Api.organization.team.index({}, (resp) => {
    teams.value = resp.data;
  });
}

function loadGrantProgram() {
  Api.organization.grant_program.get(grantProgramId.value, (resp) => {
    grantProgram.value = resp.data;
  });
}

async function remove(index) {
  const confirm = await confirmDialog.value.confirmWithText(
    'Are you sure you want to remove this stage?',
  );

  if (confirm) {
    grantProgram.value.stages.splice(index, 1);
    changed.value = true;
    save();
  }
}

function reorder(fromIndex, toIndex) {
  const element = grantProgram.value.stages[fromIndex];
  grantProgram.value.stages.splice(fromIndex, 1);
  grantProgram.value.stages.splice(toIndex, 0, element);
  changed.value = true;
}

function save() {
  processing.value = true;
  Api.organization.grant_program.update(
    grantProgramId.value,
    { enable_stages: grantProgram.value.enable_stages, stages: grantProgram.value.stages },
    (resp) => {
      processing.value = false;
      grantProgram.value = resp.data;
      changed.value = false;
    },
    (err) => {
      load();
      processing.value = false;
      eventBus.chime(err.response.data.errors[0]);
    },
  );
}
</script>
