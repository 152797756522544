<template>
  <ResourceDialog
    @change="handleChange"
    @save="handleSave"
    ref="dialog"
    :fields="createFields"
    :processing="processing"
    title="Create and link new record"
  />
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import store from '@/specialist/store';

const emit = defineEmits(['change']);

const props = defineProps({
  caseId: {
    type: String,
    required: true,
  },
  caseType: {
    type: String,
    required: true,
  },
  linkedRecords: {
    type: Array,
    required: true,
  },
  schema: {
    type: Object,
    required: true,
  },
});

const dialog = ref(false);
const processing = ref(false);
const selectedType = ref(null);
const assessmentSchemas = ref(
  Object.values(store.state.schemas).filter((schema) => schema.data_type === 'Assessment'),
);
const complaintSchemas = ref(
  Object.values(store.state.schemas).filter((schema) => schema.data_type === 'Complaint'),
);
const formSchemas = ref(
  Object.values(store.state.schemas).filter((schema) => schema.data_type === 'Form'),
);
const licenseSchemas = ref(
  Object.values(store.state.schemas).filter((schema) => schema.data_type === 'License'),
);

const createFields = computed(() => {
  const selectTypeField = {
    items: getRecordTypes(),
    text: 'Type',
    value: 'type',
    required: true,
    multiple: false,
  };
  const fields = [selectTypeField];

  let items;

  if (selectedType.value) {
    if (selectedType.value === 'Assessment') {
      items = assessmentSchemas.value;
    } else if (selectedType.value === 'Complaint') {
      items = complaintSchemas.value;
    } else if (selectedType.value === 'License') {
      items = licenseSchemas.value;
    } else if (selectedType.value === 'Form') {
      items = formSchemas.value;
    }

    const selectSchemaField = {
      items: items,
      itemText: 'name',
      itemValue: 'id',
      multiple: false,
      required: true,
      search: true,
      text: 'Type',
      value: 'schema_id',
    };

    fields.push(selectSchemaField);
  }

  return fields;
});

function getRecordTypes() {
  if (store.state.profile.enable_labs_feature_complaints_and_licenses === true) {
    return ['Assessment', 'Complaint', 'Form', 'License'];
  } else {
    return ['Assessment', 'Form'];
  }
}

function handleChange(value) {
  if (value[0] === 'type') {
    selectedType.value = value[1];
  }
}

async function handleSave(value) {
  processing.value = true;
  let childRecordId;
  const linkedProvider = props.linkedRecords.find((record) => record.type === 'Provider');

  if (value.type === 'Assessment') {
    const { data: assessmentData } = await api.organization.assessment.create({
      provider_id: linkedProvider.id,
      schema_id: value.schema_id,
    });
    childRecordId = assessmentData.id;
  }

  if (value.type === 'Complaint') {
    const { data: complaintData } = await api.organization.complaint.create({
      provider_id: linkedProvider.id,
      schema_id: value.schema_id,
    });
    childRecordId = complaintData.id;
  }

  if (value.type === 'Form') {
    const { data: formData } = await api.organization.form.create({
      provider_id: linkedProvider.id,
      schema_id: value.schema_id,
    });
    childRecordId = formData.id;
  }

  if (value.type === 'License') {
    const { data: licenseData } = await api.organization.license.create({
      provider_id: linkedProvider.id,
      schema_id: value.schema_id,
    });
    childRecordId = licenseData.id;
  }

  const resp = await api.organization.linkedRecord.create({
    record_id: props.caseId,
    record_type: props.caseType,
    child_record_id: childRecordId,
    child_record_type: value.type,
  });
  processing.value = false;
  if (resp.status !== 201) return;

  emit('change');
  dialog.value.close();
}

function open() {
  selectedType.value = null;
  dialog.value.open();
}

defineExpose({ open });
</script>
