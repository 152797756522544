<template>
  <div class="fill-height">
    <v-toolbar
      class="bc-outlined-gray bb-1"
      extension-height="70"
      height="100"
      style="z-index: 1; left: var(--v-layout-left); right: 0; width: auto"
      absolute
      flat
    >
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col
          class="d-flex align-center"
          cols="12"
          dense
        >
          <TitleBar
            :name="pageTitle || 'Providers'"
            class="mt-1"
            dense
          >
            <template #actions>
              <v-btn
                @click="$refs.download.open()"
                variant="outlined"
                >{{ $t('Download') }}</v-btn
              >

              <Download
                ref="download"
                :lists="lists"
              />

              <template v-if="$route.query.subsidy_id">
                <v-btn
                  :to="{ name: 'SubsidyShow', params: { id: $route.query.subsidy_id } }"
                  class="ms-3"
                  color="primary"
                >
                  <v-icon class="me-2"> close </v-icon>
                  <span>Done</span>
                </v-btn>
              </template>

              <template v-else-if="$route.query.group_id">
                <v-btn
                  :to="{ name: 'GroupShow', params: { groupId: $route.query.group_id } }"
                  class="ms-3"
                  color="primary"
                >
                  <v-icon class="me-2"> close </v-icon>
                  <span>Done</span>
                </v-btn>
              </template>
            </template>
          </TitleBar>
        </v-col>

        <v-col
          class="mxw-1200 px-4"
          cols="12"
        >
          <v-row dense>
            <v-col
              v-if="schemas.length > 1"
              cols="3"
            >
              <v-select
                v-model="search.schema_id"
                @update:model-value="setSchema()"
                :aria-label="$t(ariaLabel)"
                :items="schemas"
                density="compact"
                item-title="name"
                item-value="id"
                variant="filled"
                hide-details
              >
                <template #prepend-inner>
                  <v-icon
                    class="me-1 pt-2px"
                    color="primary"
                  >
                    storefront
                  </v-icon>
                </template>
              </v-select>
            </v-col>

            <v-col cols="5">
              <div class="d-flex">
                <v-text-field
                  :model-value="search.formatted_origin"
                  append-inner-icon="expand_more"
                  aria-label="Search by location"
                  class="grey-placeholder"
                  density="compact"
                  placeholder="Search by location"
                  variant="filled"
                  hide-details
                  readonly
                  single-line
                >
                  <template #prepend-inner>
                    <v-icon
                      class="me-1 pt-2px"
                      color="primary"
                    >
                      location_on
                    </v-icon>
                  </template>
                  <v-menu
                    v-model="locationMenu"
                    :close-on-content-click="false"
                    activator="parent"
                    offset="5"
                  >
                    <v-card>
                      <v-card-text class="py-4">
                        <div
                          v-t="'Search for care near:'"
                          class="c-black fs-16 fw-600 mb-1"
                        />
                        <SearchLocation
                          @close="locationMenu = false"
                          :disable-mode="true"
                          :open="locationMenu"
                          :value="search"
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-text-field>
              </div>
            </v-col>

            <v-col
              v-if="search"
              cols="2"
            >
              <v-text-field
                v-if="search.origin"
                :model-value="travelMode"
                append-inner-icon="expand_more"
                density="compact"
                variant="filled"
                hide-details
                readonly
                single-line
              >
                <template #prepend-inner>
                  <v-icon
                    class="me-1 pt-2px"
                    color="primary"
                  >
                    directions_car
                  </v-icon>
                </template>
                <v-menu
                  activator="parent"
                  offset="5"
                >
                  <v-list
                    v-model:selected="search.travel_mode"
                    @update:selected="updateOrCreateSearch()"
                  >
                    <v-list-item
                      v-for="type in $a.assets.transit_options"
                      :key="type.value"
                      :title="$t(type.text)"
                      :value="type.value"
                    />
                  </v-list>
                </v-menu>
              </v-text-field>
            </v-col>

            <v-col
              v-if="search.children && search.children.length > 0"
              cols="2"
            >
              <v-text-field
                :model-value="childrenValue"
                append-inner-icon="expand_more"
                density="compact"
                variant="filled"
                hide-details
                readonly
              >
                <template #prepend-inner>
                  <v-icon
                    class="me-1 pt-2px"
                    color="primary"
                  >
                    person_outline
                  </v-icon>
                </template>
                <v-menu
                  v-model="childrenMenu"
                  :close-on-content-click="false"
                  activator="parent"
                  max-width="600"
                  offset="5"
                >
                  <v-card
                    border
                    tile
                  >
                    <v-card-text class="pt-2 pb-4">
                      <v-checkbox
                        v-model="search.include_all_children"
                        :label="$t('Only show me results that serve all ages selected')"
                        hide-details
                      />
                      <v-divider class="my-4" />
                      <SearchChildren :search="search" />
                      <v-btn
                        @click="childrenMenu = false"
                        color="primary"
                      >
                        Apply
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <template #extension>
        <v-row
          class="c-black px-4"
          dense
          justify-space-between
        >
          <v-col class="d-flex align-center">
            <FilterMenu
              v-if="!$route.query.subsidy_id"
              :title="groupName"
              tid="specialist_search_group_filter"
            >
              <template #card>
                <GroupFilter
                  v-model="group"
                  @change="changeGroup"
                  :disable-mode="true"
                />
              </template>
            </FilterMenu>

            <FilterBar
              v-model="search"
              @change="updateOrCreateSearch()"
              :group="group"
            />

            <FilterMenu
              v-if="lists.length > 0"
              tid="specialist_search_favorite_filter"
              title="Referrals"
            >
              <template #icon-prefix>
                <v-icon class="fs-22 me-2 c-secondary">
                  {{ $store.state.pages.Search['Favorite icon'] || 'check_circle' }}
                </v-icon>
              </template>
              <template #card>
                <div
                  v-for="list in lists"
                  :key="list.id"
                  class="pa-3 hover-bg-secondary-lite"
                >
                  <v-checkbox
                    v-model="listFilter"
                    @update:model-value="updateOrCreateSearch()"
                    :label="list.name"
                    :value="list"
                    class="mt-0"
                    color="secondary"
                    hide-details
                  />
                </div>
              </template>
            </FilterMenu>
          </v-col>
        </v-row>
      </template>
    </v-toolbar>

    <Results
      @change:page="page = $event"
      @favorite:add="createFavorite($event[0], $event[1])"
      @favorite:list="createListAndFavorite($event[0], $event[1])"
      @favorite:remove="removeFavorite($event)"
      @redo="updateOrCreateSearch"
      ref="results"
      :default-center="default_center"
      :favorites="favorites"
      :lists="lists"
      :matches="matches"
      :page="page"
      :pages="pages"
      :processing="processing"
      :search="search"
      :top="110"
    />
  </div>
</template>
<script>
import Api from '@/specialist/services/bright_finder';
import Download from '@/shared/components/search/download.vue';
import FilterBar from '@/shared/components/search/FilterBar.vue';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import GroupFilter from '@/specialist/components/GroupFilter.vue';
import SearchChildren from '@/specialist/components/search/children.vue';
import SearchLocation from '@/shared/components/search/location.vue';
import Results from '@/shared/components/search/results.vue';
import { Coord } from '@/shared/services/coord';
import { SearchParams } from '@/shared/services/search_params';
import SearchFromGroupParams from '@/specialist/services/search_from_group_params';
import SearchFromSubsidyParams from '@/specialist/services/search_from_subsidy_params';
import Terms from '@/shared/mixins/terms';

export default {
  compatConfig: { MODE: 3 },

  components: {
    Download,
    FilterBar,
    FilterMenu,
    GroupFilter,
    Results,
    SearchLocation,
    SearchChildren,
  },

  mixins: [Terms],

  beforeRouteEnter(_to, _from, next) {
    next((vm) => {
      vm.load();
    });
  },

  data() {
    return {
      backRoute: null,
      center: null,
      childrenMenu: false,
      defaultCareNeededDate: new Date().toISOString().split('T')[0],
      default_center: new Coord(
        this.$store.state.profile.default_search_location || { lat: 37.0902, lng: -95.7129 },
      ),
      favorites: [],
      group: null,
      lists: [],
      listFilter: null,
      loaded: false,
      locationMenu: false,
      matches: [],
      moreFiltersDialogIsVisible: false,
      page: 1,
      pages: null,
      pageTitle: null,
      processing: false,
      query: null,
      schemas: Object.values(this.$store.state.schemas).filter(
        (schema) => schema.data_type === 'Provider',
      ),
      search: this.defaultSearch(),
      selectedProvider: null,
      searchedProviders: [],
      subsidy: null,
      travel_mode: 'DRIVING',
      user_location: null,
    };
  },

  computed: {
    ariaLabel() {
      const selectedSchema = this.schemas.find((schema) => schema.id === this.search.schema_id);
      if (selectedSchema) return `Select ${this.$t(selectedSchema.name)}`;

      return this.$t('Please create a schema');
    },

    childrenValue() {
      const sum = this.search.children.length;
      if (sum === 1) {
        return ['1', this.$t('child')].join(' ');
      }
      return [sum, this.$t('children')].join(' ');
    },

    groupName() {
      if (this.group?.name) {
        return this.group.name;
      }
      return this.terms.group;
    },

    travelMode() {
      if (this.search && this.search.travel_mode) {
        return (
          this.search.travel_mode.charAt(0).toUpperCase() +
          this.search.travel_mode.slice(1).toLowerCase()
        );
      }
      return 'Driving';
    },

    validSearch() {
      return !(this.search.origin == null);
    },
  },

  watch: {
    childrenMenu(newVal) {
      if (!newVal) {
        this.updateOrCreateSearch();
      }
    },

    locationMenu(newVal) {
      if (!newVal) {
        this.updateOrCreateSearch();
      }
    },

    page() {
      this.refreshSearchResults();
    },

    // eslint-disable-next-line func-names
    '$route.query': function () {
      this.load();
    },

    selectedProvider(val) {
      if (val) {
        this.$router.push({ name: 'ProviderShow', params: { providerId: val.id } });
        this.query = null;
        this.searchedProviders = [];
        this.selectedProvider = null;
      }
    },

    query(val) {
      if (val) this.searchProviders();
    },
  },

  methods: {
    applyCareNeededDateToChildren(children) {
      // eslint-disable-next-line no-return-assign, no-param-reassign
      children.forEach((child) => (child.care_needed_date = this.defaultCareNeededDate));
    },

    createSearch() {
      this.processing = true;
      this.matches = [];
      this.pages = null;

      const params = new SearchParams(this.search).asJson();
      Api.organization.search.create(params, (resp) => {
        this.refreshSearchResults(resp.data);
      });
    },

    createGroupSearch(groupId) {
      this.processing = true;
      this.matches = [];
      this.pages = null;
      this.loadGroup(groupId, () => {
        const params = new SearchFromGroupParams(this.group, this.search.schema_id).asJson();
        this.applyCareNeededDateToChildren(params.children);

        Api.organization.search.create(
          {
            ...params,
            subsidy_program_id: this.subsidyProgram?.id,
          },
          (searchResp) => {
            this.refreshSearchResults(searchResp.data);
          },
          (err) => {
            this.$eventBus.$emit('chime', err.response.data.errors[0]);
          },
        );
      });
    },

    async createSubsidySearch(subsidyId) {
      this.processing = true;
      this.matches = [];
      this.pages = null;
      await this.loadSubsidy(subsidyId);

      this.pageTitle = [this.subsidy.meta.child.first_name, this.subsidy.meta.child.last_name].join(
        ' ',
      );
      this.backRoute = { name: 'SubsidyShow', params: { id: this.subsidy.id } };
      await this.loadSubsidyProgram(this.subsidy.subsidy_program_id);

      const params = new SearchFromSubsidyParams(
        this.subsidy,
        this.subsidy.meta.child,
        this.subsidy.meta.group,
        this.search.schema_id,
      ).asJson();
      this.applyCareNeededDateToChildren(params.children);

      Api.organization.search.create(
        params,
        (searchResp) => {
          this.refreshSearchResults(searchResp.data);
        },
        (err) => {
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },

    createFavorite(listId, providerId) {
      Api.organization.favorite.create(
        { group_id: this.$route.query.group_id, list_id: listId, provider_id: providerId },
        () => {
          this.loadFavorites();
        },
      );
    },

    createListAndFavorite(listName, providerId) {
      Api.organization.list.create(
        { group_id: this.$route.query.group_id, name: listName },
        (resp) => {
          this.createFavorite(resp.data.id, providerId);
        },
      );
    },

    removeFavorite(favoriteId) {
      Api.organization.favorite.destroy(favoriteId, () => {
        this.loadFavorites();
      });
    },

    defaultSearch() {
      return {
        categories: [],
        county: [],
        exclude_closed: this.$store.state.pages.Search.features.enable_closed_filter,
        custom: {},
        highlights: {},
        license_type: [],
        location_type: 'home',
        minimum_quality_rating: null,
        origin: null,
        payment_subsidies_accepted: [],
        payment_other_programs: [],
        program_season: null,
        program_types: [],
        schema_id: Object.values(this.$store.state.schemas).find(
          (schema) => schema.data_type === 'Provider',
        ).id,
        travel_mode: 'DRIVING',
        week_days: [],
        vacancy: null,
      };
    },

    changeGroup(newVal) {
      this.group = newVal;
      if (this.group && this.group.id) {
        this.$router.push({ name: 'ProviderSearch', query: { group_id: this.group.id } });
      } else {
        this.$router.push({ name: 'ProviderSearch' });
      }
    },

    load() {
      this.pageTitle = null;
      this.backRoute = null;
      if (this.$route.query.subsidy_id) {
        this.createSubsidySearch(this.$route.query.subsidy_id);
      } else if (this.$route.query.group_id) {
        this.createGroupSearch(this.$route.query.group_id);
      } else {
        this.loadDefault();
      }

      if (this.$route.query.group_id) {
        this.loadFavorites();
      }
    },

    loadDefault() {
      this.favorites = [];
      this.group = null;
      this.lists = [];
      this.search = this.defaultSearch();
      this.matches = [];
      this.pages = null;
      this.loaded = true;
    },

    loadFavorites() {
      Api.organization.favorite.index({ group_id: this.$route.query.group_id }, (resp) => {
        this.favorites = resp.data;
      });

      Api.organization.list.index({ group_id: this.$route.query.group_id }, (resp) => {
        this.lists = resp.data;
      });
    },

    async loadGroup(id, callback) {
      Api.organization.group.get(id, async (groupResp) => {
        this.group = groupResp.data;

        const childResp = await Api.organization.group.child.index(this.group.id);
        this.group.children = childResp.data;
        this.loadFavorites();
        callback();
      });
    },

    async loadSubsidy(id) {
      const response = await Api.organization.subsidy.get(id);
      this.subsidy = response.data;
      this.group = this.subsidy.meta.group;
    },

    async loadSubsidyProgram(id) {
      const response = await Api.organization.subsidy_program.get(id);
      const enrollmentStartDate = response.data?.search_for_care_as_of_date;
      if (!enrollmentStartDate) return;

      this.defaultCareNeededDate = enrollmentStartDate;
    },

    searchProviders() {
      const vm = this;
      Api.public_api.provider.index(
        {
          organization_id: window.organization_id,
          query: this.query,
          schema_id: this.search.schema_id,
          subsidy_program_id: this.subsidyProgram?.id,
        },
        (resp) => {
          vm.searchedProviders = resp.data;
        },
      );
    },

    setSchema() {
      this.search.categories = [];
      this.updateOrCreateSearch();
    },

    setOrigin(location) {
      this.search.origin = location;
      this.search.sw_lat = null;
      this.search.sw_lng = null;
      this.search.ne_lat = null;
      this.search.ne_lng = null;
      this.updateOrCreateSearch();
    },

    refreshSearchResults(search) {
      if (search) this.search = search;

      this.matches = [];
      this.pages = null;
      this.processing = true;
      const filters = { page: this.page, subsidy_program_id: this.subsidy?.subsidy_program_id };

      if (this.listFilter) {
        filters.list_id = this.listFilter.id;
      }
      Api.organization.search.match.index(this.search.id, filters, (resp) => {
        this.pages = parseInt(resp.headers['x-page-count'] || 0, 10);
        this.loaded = true;
        this.matches = resp.data;
        this.processing = false;
        this.$store.commit('changeMode', 'list');
        this.$refs.results.triggerRefit();
      });
    },

    updateOrCreateSearch(bounds) {
      this.page = 1;
      if (bounds) {
        this.search.sw_lat = bounds[0].lat();
        this.search.sw_lng = bounds[0].lng();
        this.search.ne_lat = bounds[1].lat();
        this.search.ne_lng = bounds[1].lng();
      } else {
        this.search.sw_lat = null;
        this.search.sw_lng = null;
        this.search.ne_lat = null;
        this.search.ne_lng = null;
      }
      if (this.validSearch) {
        this.moreFiltersDialogIsVisible = false;
        if (this.search.id) {
          const params = new SearchParams(this.search).asJson();
          Api.organization.search.update(
            this.search.id,
            params,
            (resp) => {
              this.refreshSearchResults(resp.data);
            },
            (err) => {
              this.$eventBus.$emit('chime', err.response.data.errors[0]);
            },
          );
        } else if (this.group?.id) {
          this.createGroupSearch(this.group.id);
        } else {
          this.createSearch();
        }
      }
    },
  },
};
</script>

<style>
.b-0 fieldset,
.b-0.v-text-field > .v-input__control > .v-input__slot:before {
  border: none !important;
}

.mxw-500 {
  max-width: 500px;
}
</style>
