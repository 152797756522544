<template>
  <div>
    <v-container
      class="px-3 py-0"
      fluid
    >
      <v-row class="my-2">
        <v-col class="d-flex align-center">
          <v-text-field
            @update:model-value="updateQuery"
            class="mxw-400"
            density="compact"
            prepend-inner-icon="search"
            variant="solo-filled"
            flat
            hide-details
            rounded
          />
          <v-pagination
            v-show="pages > 1"
            v-model="page"
            :length="pages"
            :total-visible="8"
          />
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            @click="draftAnnouncement"
            color="primary"
            prepend-icon="add"
            variant="flat"
          >
            <span v-t="'New'" />
          </v-btn>
        </v-col>

        <ResourceDialog
          @save="createAnnouncement"
          @search="loadReports"
          ref="newAnnouncementDialog"
          :fields="draftAnnouncementFields"
          title="Create announcement"
        />
      </v-row>

      <v-card
        class="bb-1 pa-0"
        border
        flat
        tile
      >
        <v-card-text>
          <v-row role="row">
            <TableHeader
              cols="4"
              title="Name"
            />
            <TableHeader
              cols="3"
              title="Subject"
            />
            <TableHeader
              cols="4"
              title="Sent at"
            />
            <TableHeader
              cols="1"
              title=""
            />
          </v-row>
        </v-card-text>
      </v-card>

      <v-card
        v-for="announcement in announcements"
        :key="announcement.id"
        data-testid="announcementRow"
        border
        flat
        tile
      >
        <v-card-text>
          <v-row
            :key="announcement.id"
            role="row"
          >
            <TableCell
              :content="announcement.name"
              :to="{ name: 'AnnouncementShow', params: { announcementId: announcement.id } }"
              cols="4"
              target="_top"
            />
            <TableCell
              :content="announcement.subject"
              cols="3"
            />
            <TableCell
              :content="
                announcement.sent_at ? new Date(announcement.sent_at).toLocaleString() : 'Draft'
              "
              cols="4"
            />
            <TableCell cols="1">
              <ActionMenu
                @click:action:duplicate="openDuplicateAnnouncementDialog(announcement)"
                :items="getAnnouncementRowItems()"
                button-class="ms-3"
                button-icon="more_vert"
                x-small
              />
            </TableCell>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>

    <NullState
      v-if="announcements.length == 0 && !processing"
      src="https://res.cloudinary.com/bridgecare/image/upload/v1628229103/media/trumpet_ydq1qk.svg"
      title="You have not sent any announcements. Once you do they will appear here. Sending an announcement will send an email to a list of contacts."
      hide-new-button
    />

    <v-pagination
      v-show="pages > 1"
      v-model="page"
      :length="pages"
      :total-visible="8"
      class="mt-4"
    />

    <DecisionDialog
      @confirm="duplicateAnnouncement"
      ref="duplicateAnnouncementDialog"
      :content="getDuplicateAnnouncementContent()"
      :processing="false"
      confirm-button-text="Duplicate"
      title="Duplicate"
    />
  </div>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Api from '@/specialist/services/bright_finder';
import DecisionDialog from '@/shared/components/DecisionDialog.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import TableCell from '@/shared/components/TableCell.vue';
import TableHeader from '@/shared/components/TableHeader.vue';
import { useRouter } from 'vue-router';

const announcements = ref([]);
const currentAnnouncement = ref(null);
const duplicateAnnouncementDialog = ref(null);
const page = ref(1);
const pages = ref(0);
const processing = ref(null);
const query = ref(null);
const queryCounter = ref(0);
const reports = ref([]);
const router = useRouter();
const newAnnouncementDialog = ref(null);

const draftAnnouncementFields = computed(() => {
  return [
    {
      text: 'Name',
      value: 'name',
      required: true,
    },
    {
      text: 'Report',
      value: 'report_id',
      search: true,
      items: reports.value,
      itemText: 'name',
      itemValue: 'id',
      required: true,
    },
  ];
});

onMounted(loadAnnouncements);

watch(page, () => loadAnnouncements());
watch(query, () => loadAnnouncements());

function updateQuery(value) {
  query.value = value;
}

async function createAnnouncement(announcement) {
  const resp = await Api.organization.announcement.create(announcement);

  if (!resp.data) return;

  router.push({ name: 'AnnouncementShow', params: { announcementId: resp.data.id } });
}

async function draftAnnouncement() {
  await loadReports();
  newAnnouncementDialog.value.open({});
}

async function loadAnnouncements() {
  processing.value = true;
  const currentCount = queryCounter.value;
  const response = await Api.organization.announcement.index({
    page: page.value,
    query: query.value,
  });
  if (currentCount !== queryCounter.value) return;

  announcements.value = response.data || [];
  pages.value = parseInt(response.headers['x-page-count'] || 0, 10);
  processing.value = false;
}

async function loadReports(newQueryEvent) {
  const filters = {};
  if (newQueryEvent) filters.query = newQueryEvent[1];
  const resp = await Api.organization.report.index(filters);
  reports.value = resp.data;
}

// extend here when we want to add Delete
function getAnnouncementRowItems() {
  return [{ event: 'duplicate', avatar: 'edit', title: 'Duplicate' }];
}

async function openDuplicateAnnouncementDialog(announcement) {
  currentAnnouncement.value = announcement;
  duplicateAnnouncementDialog.value.open();
}

async function duplicateAnnouncement() {
  await createAnnouncement({
    report_id: currentAnnouncement.value.report_id,
    name: `Copy of ${currentAnnouncement.value.name}`,
    subject: currentAnnouncement.value.subject,
    body: currentAnnouncement.value.body,
    from_sender_name: currentAnnouncement.value.from_sender_name,
    reply_to_email: currentAnnouncement.value.reply_to_email,
    email_column: currentAnnouncement.value.email_column,
    phone_column: currentAnnouncement.value.phone_column,
  });
}

function getDuplicateAnnouncementContent() {
  return `Are you sure you want to duplicate ${currentAnnouncement.value?.name}?`;
}
</script>
