<template>
  <ResourceDialog
    @save="handleSave"
    ref="dialog"
    :max-width="700"
    title="Find asset"
  >
    <template #form>
      <v-autocomplete
        v-model="selectedAsset"
        @update:search="load($event)"
        @vue:mounted="load('')"
        :items="collection"
        :placeholder="$t('Find by name')"
        :rules="[validateSelection]"
        data-testid="asset-finder-input"
        item-title="name"
        item-value="url"
        prepend-inner-icon="search"
        variant="filled"
        clearable
        hide-details
        round
      >
        <template #item="{ props, item }">
          <v-list-item v-bind="props">
            <v-list-item-subtitle :class="{ 'red--text': validateSelection(item.raw) !== true }">
              {{ formatSubtitle(item.raw) }}
            </v-list-item-subtitle>
          </v-list-item>
        </template>
      </v-autocomplete>
    </template>
  </ResourceDialog>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { capitalize } from '@/plugins/filters';
import useEventBus from '@/shared/composables/useEventBus';
import { useI18n } from 'vue-i18n';

defineExpose({ open });
const emit = defineEmits(['save']);
const eventBus = useEventBus();

const props = defineProps({
  assetUrl: {
    type: String,
    default: null,
  },
  isValidSelection: {
    type: Function,
    default: () => true,
  },
});

const { t } = useI18n();

const autocomplete = ref(null);
const collection = ref([]);
const dialog = ref(null);
const queryCount = ref(0);
const selectedAsset = ref(props.assetUrl);

function formatSubtitle(asset) {
  const mimeType = asset.file.metadata.mime_type;
  const mimeTypeTuple = mimeType.split('/');

  if (mimeTypeTuple.length !== 2) return `${t('File')} - ${t('Unknown')}`;

  const [fileType, extension] = mimeTypeTuple;
  const validation = validateSelection(asset);
  const fileSupportWarning = validation === true ? null : validation;

  return [t(fileType), extension, fileSupportWarning].filter(Boolean).map(capitalize).join(' | ');
}

function handleSave() {
  if (!selectedAsset.value) {
    eventBus.chime('Please select an asset.');
    autocomplete.value.$refs.input.focus();
    return;
  }

  if (validateSelection(selectedAsset.value) !== true) {
    eventBus.chime('Please select a different asset.');
    autocomplete.value.$refs.input.focus();
    return;
  }

  emit('save', selectedAsset.value);
  dialog.value.close();
}

function load(query) {
  queryCount.value += 1;
  const localQuery = queryCount.value;

  Api.organization.asset.index({ query }, (resp) => {
    if (queryCount.value === localQuery) {
      collection.value = resp.data;
    }
  });
}

function open() {
  dialog.value.open({});
}

function validateSelection(selection) {
  if (!selection) return true;

  // assume this will either be a URL or an asset itself
  const asset =
    typeof selection === 'string'
      ? collection.value.find((item) => item.url === selection)
      : selection;

  const validation = props.isValidSelection(asset);

  switch (validation) {
    case true:
      return true;
    case false:
      return t('Unsupported file type.');
    default:
      return validation;
  }
}
</script>
