<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <router-view />
  </v-container>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/shared/services/bright_finder';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

watch(
  route,
  async (newValue) => {
    if (newValue?.params?.lessonId) await load();
  },
  {
    flush: 'pre',
    immediate: true,
    deep: true,
  },
);

async function load() {
  const response = await Api.public_api.organization.assignment.index({
    lesson_id: route.params.lessonId,
  });
  const assignments = response.data;

  const assignmentSectionItems = _.map(assignments, (assignment) => ({
    active: assignment.id === route.params.assignmentId,
    title: assignment.name,
    route: 'CohortLessonAssignment',
    params: {
      assignmentId: assignment.id,
      cohortId: route.params.cohortId,
      courseId: route.params.courseId,
      lessonId: assignment.lesson_id,
    },
    query: {
      tab: 'media',
    },
  }));
  // QUESTION: What happens if there are no assignments/is that possible?

  store.commit('setNavigation', null);
  store.commit('setNavigation', [
    {
      type: 'back',
      title: 'Back to Cohort',
      route: 'CohortShow',
      params: {
        cohortId: route.params.cohortId,
      },
      query: {
        tab: 'course',
      },
    },
    {
      type: 'section',
      items: assignmentSectionItems,
    },
  ]);
}

onBeforeUnmount(() => {
  store.commit('setNavigation', null);
});
</script>
