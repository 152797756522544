<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      :back-name="'Courses'"
      :back-route="{
        name: 'LearningIndex',
        query: {
          tab: 'courses',
        },
      }"
      :title="course?.name"
      flat
    />

    <v-tabs
      v-model="tab"
      @update:model-value="tabChange"
      bg-color="surface"
      grow
    >
      <v-tab value="settings">
        {{ $t('Settings') }}
      </v-tab>

      <v-tab value="lessons">
        {{ $t('Lessons') }}
      </v-tab>
    </v-tabs>

    <v-window
      v-if="course"
      v-model="tab"
      class="pt-3 border-t"
    >
      <v-window-item value="settings">
        <CourseSettings
          @reload="loadCourse()"
          :initial-course="course"
        />
      </v-window-item>

      <v-window-item value="lessons">
        <CourseLessons :initial-course="course" />
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import CourseLessons from '@/specialist/views/learning/course/CourseLessons.vue';
import CourseSettings from '@/specialist/views/learning/course/CourseSettings.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    CourseLessons,
    CourseSettings,
  },

  mixins: [API],

  data() {
    return {
      course: null,
      tab: 'settings',
    };
  },

  watch: {
    '$route.params.courseId': {
      immediate: true,
      handler() {
        this.loadCourse();
      },
    },
  },

  created() {
    if (this.$route.query?.tab) {
      this.tab = this.$route.query.tab;
    } else {
      this.tabChange('settings');
    }
  },

  methods: {
    async loadCourse() {
      const { data } = await this.api.organization.course.get(this.$route.params.courseId);
      this.course = data;
    },

    tabChange(tab) {
      this.$router.push({
        query: { tab },
      });
    },
  },
};
</script>
