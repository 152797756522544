<template>
  <div>
    <v-card
      class="mb-4"
      border
      tile
    >
      <v-card-title class="fs-22 fw-600">
        <v-row
          class="d-flex align-center"
          dense
        >
          <v-col
            cols="12"
            sm="9"
          >
            {{ $t('Application') }}
          </v-col>
          <v-col
            class="d-flex justify-end"
            cols="3"
            sm="3"
          >
            <v-btn
              :disabled="!grant.submitted_at"
              :href="link"
              color="primary"
              variant="outlined"
            >
              {{ $t('Download') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider class="mb-3" />
      <v-card-text>
        <div v-if="loaded">
          <QuestionSet
            v-model="localGrant"
            @change="changed = true"
            :color="null"
            :elevation="0"
            :processing="processing"
            :questions="validEligibilityQuestions"
            :readonly="!$store.state.profile.org_grants_edit"
            :schema="grantSchema.definition"
            expanded
            hide-actions
            outlined
            tile
            very-dense
          />

          <QuestionSet
            v-model="localGrant"
            @change="changed = true"
            :color="null"
            :elevation="0"
            :processing="processing"
            :questions="validQuestions"
            :readonly="!$store.state.profile.org_grants_edit"
            :schema="grantSchema.definition"
            expanded
            hide-actions
            outlined
            tile
            very-dense
          />

          <div
            v-for="question in validVerificationQuestions"
            :key="question.id"
            class="mb-6"
          >
            <FormQuestion
              :color="null"
              :elevation="0"
              :subtitle="question.verification_subtitle"
              :title="question.verification_title"
              hide-actions
              outlined
              tile
              very-dense
            >
              <AttachmentUploader
                @uploaded="attachments.push($event)"
                :ref="['uploader', question.id].join('')"
                :owner="{
                  business_id: grant.business_id,
                  type: 'GrantProgram',
                  id: grantProgram.id,
                  tag: question.id,
                  tags: [question.id, grant.id],
                }"
                class="mb-4"
              />
              <AttachmentList
                @delete="loadAttachments"
                :attachments="attachments.filter((attachment) => attachment.tag == question.id)"
                class="mb-6"
              />
            </FormQuestion>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <SaveBar
      @cancel="cancel"
      @save="save"
      :model-value="changed"
      :processing="processing"
    />
  </div>
</template>

<script>
import API from '@/shared/mixins/api';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import Grant from '@/shared/mixins/grant';

export default {
  compatConfig: { MODE: 3 },

  components: {
    FormQuestion,
    QuestionSet,
  },

  mixins: [API, Grant],

  props: {
    grant: {
      type: Object,
      default: null,
    },
    grantProgram: {
      type: Object,
      default: null,
    },
  },

  emits: ['change', 'reset'],

  data() {
    return {
      attachments: [],
      changed: false,
      displayAll: this.grantProgram.enable_reviewer_show_all,
      grantSchema: null,
      localGrant: this.grant,
      processing: false,
    };
  },

  computed: {
    link() {
      return this.api.organization.grant.downloadUrl(this.$route.params.id, 'Application');
    },

    loaded() {
      return this.grantSchema && this.questions.length > 0;
    },
  },

  watch: {
    loaded: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.validate();
        }
      },
    },

    displayAll() {
      this.validate();
    },
  },

  created() {
    this.grantProgramId = this.grantProgram.id;
    this.grantSchema = this.$store.state.schemas[this.grant.schema_id];
    this.loadAttachments();
    this.loadQuestions(() => {
      this.validate();
    });
  },

  methods: {
    cancel() {
      this.$emit('reset');
      this.changed = false;
    },

    async loadAttachments() {
      const params = {
        owner_type: 'Grant',
        owner_id: this.grant.id,
      };
      const resp = await this.api.organization.attachment.index(params);
      if (resp?.status !== 200) return;

      this.attachments = resp.data;
    },

    async save() {
      this.processing = true;

      await this.api.organization.grant.update(this.grant.id, this.grant);

      this.$emit('change');
      this.validate();
      this.processing = false;
      this.changed = false;
      this.$eventBus.$emit('chime', 'Saved');
    },
  },
};
</script>
