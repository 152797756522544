<template>
  <v-dialog
    v-model="visible"
    max-width="600"
    scrim="transparent"
  >
    <template #activator="{ props }">
      <v-btn
        v-if="advancedFilters"
        v-show="advancedFilters.length > 0"
        v-bind="props"
        :variant="!(field && query) ? 'outlined' : 'flat'"
        aria-label="Advanced filter button"
        color="primary"
        data-testid="advanced-filter-button"
        tile
      >
        <v-icon class="material-icons-outlined"> filter_alt </v-icon>
      </v-btn>
    </template>

    <v-card
      border
      tile
    >
      <v-card-title>{{ $t('Advanced search options') }}</v-card-title>

      <v-divider class="mb-3" />

      <v-card-text>
        <v-row>
          <LabeledSimpleSelect
            v-model="field"
            :item-title="(item) => `${item.schema} - ${item.text}`"
            :items="advancedFilters"
            cols="6"
            item-value="value"
            message="Field"
          />

          <LabeledTextfield
            v-model="query"
            :disabled="!field"
            cols="6"
            message="Search value"
          />
        </v-row>
      </v-card-text>

      <v-divider class="mb-3" />

      <v-card-actions class="mt-0 px-6 pb-4">
        <v-row>
          <v-col>
            <v-btn
              @click="visible = false"
              color="primary"
              size="x-large"
              variant="outlined"
            >
              <span v-t="'Cancel'" />
            </v-btn>
          </v-col>

          <v-col class="d-flex justify-end">
            <v-btn
              @click="clear"
              color="primary"
              size="x-large"
              variant="text"
            >
              <span v-t="'Clear'" />
            </v-btn>

            <v-btn
              @click="search"
              color="primary"
              size="x-large"
              variant="flat"
            >
              <span v-t="'Search'" />
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import API from '@/shared/mixins/api';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LabeledSimpleSelect,
    LabeledTextfield,
  },

  mixins: [API],

  props: {
    fieldValue: {
      type: String,
      default: null,
    },
    queryValue: {
      type: String,
      default: null,
    },
    subsidyProgramId: {
      type: String,
      default: null,
    },
  },

  emits: ['change'],

  data() {
    return {
      advancedFilters: null,
      field: this.fieldValue,
      query: this.queryValue,
      visible: false,
    };
  },

  watch: {
    subsidyProgramId: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.loadAdvancedFilters();
        } else {
          this.advancedFilters = [];
        }
      },
    },
  },

  methods: {
    clear() {
      this.field = null;
      this.query = null;
      this.search();
    },

    async loadAdvancedFilters() {
      const resp = await this.api.organization.subsidy_program.advanced_filter.index(
        this.subsidyProgramId,
      );

      if (!resp?.data) return;
      this.advancedFilters = resp.data;
    },

    search() {
      this.$emit('change', [this.field, this.query]);
      this.visible = false;
    },
  },
};
</script>
