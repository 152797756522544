<template>
  <v-container v-if="loaded">
    <TitleBar
      class="mb-2"
      name="Organization settings"
    />

    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-title class="fs-20"> General configuration </v-card-title>
      <v-divider />
      <v-card-text class="fs-16">
        <v-row dense>
          <v-col cols="12">
            <div class="fs-16 fw-500 c-black">Organization ID</div>
          </v-col>
          <v-col
            class="mb-4"
            cols="12"
          >
            <v-text-field
              v-model="organization.id"
              @update:model-value="handleOrganizationChange"
              label="Organization ID"
              variant="filled"
              hide-details
              readonly
            />
          </v-col>
          <v-col cols="12">
            <div class="fs-16 fw-500 c-black">Organization name</div>
          </v-col>
          <v-col
            class="mb-4"
            cols="12"
          >
            <v-text-field
              v-model="organization.name"
              @update:model-value="handleOrganizationChange"
              placeholder="Name"
              variant="filled"
              hide-details
            />
          </v-col>
          <v-col
            class="fs-16 fw-500"
            cols="12"
          >
            What states does this organization operate in? Comma seperate multiple states.
          </v-col>
          <v-col
            class="mb-4"
            cols="12"
          >
            <v-text-field
              v-model="organization.operating_states"
              @update:model-value="handleOrganizationChange"
              placeholder="Example: CA, OR"
              variant="filled"
              hide-details
            />
          </v-col>
          <v-col
            class="fs-16 fw-500"
            cols="12"
          >
            What ZIP codes does this organization operate in? Comma separate multiple ZIP codes.
          </v-col>
          <v-col
            class="mb-4"
            cols="12"
          >
            <v-textarea
              v-model="organization.zips"
              @update:model-value="handleOrganizationChange"
              placeholder="Example: 94611, 94062"
              variant="filled"
              hide-details
            />
          </v-col>
          <v-col
            class="fs-16 fw-500"
            cols="12"
          >
            What is the default search location?
          </v-col>
          <v-col
            class="mb-4"
            cols="12"
          >
            <LocationAutocomplete
              @change-location="setDefaultSearchLocation"
              :outlined="false"
              :starting-location="organization.default_search_location"
              filled
            />
          </v-col>
          <LabeledSimpleSelect
            v-model="organization.time_zone"
            @input="handleOrganizationChange"
            :items="$a.assets.time_zones"
            message="What is the default time zone?"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-title class="fs-20"> Brand </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <LabeledSimpleSelect
            v-model="organization.brand_id"
            @change="handleOrganizationChange"
            :items="brands"
            item-title="name"
            item-value="id"
            message="Primary brand"
          />
          <LabeledTextfield
            v-model="organization.brand_logo_url"
            @change="handleOrganizationChange"
            message="Logo url (PNG)"
          />
          <LabeledTextfield
            v-model="organization.brand_primary_color"
            @change="handleOrganizationChange"
            message="Primary color (hex value, but do not include #)"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-title class="fs-20"> Schemas </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <LabeledSimpleSelect
            v-model="organization.operating_log_schema_id"
            @change="handleOrganizationChange"
            :items="schemas.filter((schema) => schema.data_type == 'OperatingLog')"
            item-title="name"
            item-value="id"
            message="Operating log schema"
          />
          <LabeledSimpleSelect
            v-model="organization.payment_schema_id"
            @change="handleOrganizationChange"
            :items="schemas.filter((schema) => schema.data_type == 'Payment')"
            item-title="name"
            item-value="id"
            message="Payment schema"
          />
          <LabeledSimpleSelect
            v-model="organization.payment_adjustment_schema_id"
            @change="handleOrganizationChange"
            :items="schemas.filter((schema) => schema.data_type == 'PaymentAdjustment')"
            item-title="name"
            item-value="id"
            message="Payment adjustment schema"
          />
          <LabeledSimpleSelect
            v-model="organization.funds_transfer_schema_id"
            @change="handleOrganizationChange"
            :items="schemas.filter((schema) => schema.data_type == 'FundsTransfer')"
            item-title="name"
            item-value="id"
            message="Funds transfer schema"
          />
          <LabeledSimpleSelect
            v-model="organization.provider_onboarding_form_schema_id"
            @change="handleOrganizationChange"
            :items="schemas.filter((schema) => schema.data_type == 'Form')"
            item-title="name"
            item-value="id"
            message="Provider onboarding form schema"
            clearable
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-title class="fs-20"> Email </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <LabeledSwitch
            v-model="organization.enable_favorite_open_alerts"
            @update:model-value="handleOrganizationChange"
            title="Enable favorite open alerts"
          />
          <LabeledSwitch
            v-model="organization.enable_instant_messaging"
            @update:model-value="handleOrganizationChange"
            title="Enable instant messaging"
          />
          <LabeledSwitch
            v-model="organization.enable_provider_invite_emails"
            @update:model-value="handleOrganizationChange"
            title="Enable provider invite email notifications"
          />
          <LabeledTextfield
            v-model="organization.email_from"
            @update:model-value="handleOrganizationChange"
            message="Email from"
          />
          <LabeledTextfield
            v-model="organization.email_bcc"
            @update:model-value="handleOrganizationChange"
            message="Email BCC"
          />
          <LabeledTextfield
            v-model="organization.parent_url"
            @change="handleOrganizationChange"
            message="Parent URL"
          />
          <LabeledTextfield
            v-model="organization.parent_inquiry_email_template_id"
            @change="handleOrganizationChange"
            message="Parent inquiry email template ID"
          />
          <LabeledTextfield
            v-model="organization.provider_url"
            @change="handleOrganizationChange"
            message="Provider URL"
          />
          <LabeledTextfield
            v-model="organization.provider_reminder_email_template_id"
            @change="handleOrganizationChange"
            message="Provider reminder email template ID"
          />
          <LabeledTextfield
            v-model="organization.specialist_url"
            @change="handleOrganizationChange"
            message="Specialist URL"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <StatusSettings :organization="organization" />

    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-title class="fs-20"> Advanced configurations </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <LabeledSwitch
            v-model="organization.enable_attendance"
            @input="handleOrganizationChange"
            :subtitle="`Attendance reports will be automatically created each month and as students are enrolled.`"
            :title="`Enable attendance`"
          />
          <LabeledSwitch
            v-model="organization.enable_report_sharing"
            @input="handleOrganizationChange"
            subtitle="Contact support@getbridgecare.com if you would like to enable report sharing"
            title="Enable report sharing"
            readonly
          />
          <LabeledSwitch
            v-model="organization.enable_contact_confirmation"
            @input="handleOrganizationChange"
            subtitle="Members are required to confirm their phone or email after registration before logging in."
            title="Enable contact confirmation"
          />
          <LabeledSwitch
            v-model="organization.enable_clean_providers"
            @input="handleOrganizationChange"
            subtitle="Automatically humanize casing for provider names and locations"
            title="Enable provider case standardization"
          />
          <LabeledSwitch
            v-model="organization.enable_automatic_translations"
            @input="handleOrganizationChange"
            subtitle="Automatically translate select open-text provider fields for families"
            title="Enable translation of provider-enabled content"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <AgeGroupIndex />

    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-title class="fs-20"> Tour Requests </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <LabeledSwitch
            v-model="organization.enable_tour_reservation_enrollment_creation"
            @input="handleOrganizationChange"
            subtitle="Creates an enrollment in Selected status when a family requests a tour from a provider."
            title="Enable enrollment creation"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <SaveBar
      @cancel="cancel"
      @save="save"
      :model-value="changed"
      :processing="processing"
    />
  </v-container>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import AgeGroupIndex from '@/admin/components/AgeGroupIndex.vue';
import LocationAutocomplete from '@/specialist/components/LocationAutocomplete.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import StatusSettings from '@/admin/components/StatusSettings.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

async function cancel() {
  await load();
  changed.value = false;
}

async function load() {
  const { data: brandData } = await api.organization.brand.index();
  brands.value = brandData;

  const { data: organizationData } = await api.admin.organization.get();
  Object.assign(organization, organizationData);

  const { data: schemaData } = await api.organization.schema.index({});
  schemas.value = schemaData;

  loaded.value = true;
}

async function save() {
  await api.admin.organization.update(organization);
  await load();
  changed.value = false;
  eventBus.chime('Saved');
}

function setDefaultSearchLocation(location) {
  organization.default_search_location = location;
  changed.value = true;
}

const brands = ref([]);
const changed = ref(false);
const loaded = ref(false);
const organization = reactive({});
const processing = ref(false);
const schemas = ref([]);

function handleOrganizationChange() {
  changed.value = true;
}

onMounted(async () => {
  await load();
});
</script>
