<template>
  <v-row
    class="bg-super-light-blue w-100pc oy-hidden bottom-0 p-absolute"
    style="top: 112px; bottom: 0px"
    no-gutters
  >
    <v-col
      v-show="$vuetify.display.mdAndUp || activeThread == false"
      class="bc-extra-light-gray bt-1 bg-white h-100pc oy-hidden-scroll br-1 pb-16"
      cols="12"
      md="4"
    >
      <div
        class="pa-4 bb-1 bc-extra-light-gray d-flex align-center"
        data-cy="conversation-type-selector"
      >
        <v-select
          v-model="audience"
          :items="['Parents', 'Providers']"
          :prepend-inner-icon="audience == 'Parents' ? 'escalator_warning' : 'storefront'"
          bg-color="primary"
          class="w-50pc"
          density="compact"
          item-color="primary"
          variant="filled"
          hide-details
        />
        <v-text-field
          v-model="query"
          :aria-label="$t('Search')"
          :placeholder="$t('Search')"
          density="compact"
          prepend-inner-icon="search"
          variant="filled"
          hide-details
        />
        <v-btn
          @click="loadMessages"
          :loading="processing"
          aria-label="Refresh"
          class="ms-1 focus-visible"
          variant="text"
          icon
        >
          <v-icon>refresh</v-icon>
        </v-btn>
      </div>
      <div
        v-for="meta in filteredThreads"
        @click="switchThread(meta)"
        :key="[meta.group_id, meta.business_id].join('')"
        class="pa-4 bb-1 bc-extra-light-gray d-flex align-center pointer"
      >
        <div>
          <div
            v-text="meta.name"
            class="fs-18 fw-500"
          />
          <MarkdownContent
            :content="messagesByThread[[meta.group_id, meta.business_id]][0]?.text"
            class="fs-16 c-light-black mb-1 message-container h-20 oy-hidden"
          />
          <LongDateTime
            :date="messagesByThread[[meta.group_id, meta.business_id]][0].created_at"
            class="fs-14 c-light-black"
          />
        </div>
        <v-spacer />
        <v-icon
          v-if="
            [messagesByThread[[meta.group_id, meta.business_id]][0]].some(
              (message) =>
                message.member_id && !message.meta.member_is_specialist && !message.read_at,
            )
          "
          class="mx-1"
          color="green"
        >
          circle
        </v-icon>
      </div>
      <v-btn
        @click="goBack()"
        :loading="processing"
        class="mt-2 focus-visible"
        color="primary"
        variant="text"
      >
        View older messages
      </v-btn>
    </v-col>

    <v-col
      v-show="$vuetify.display.mdAndUp || activeThread != false"
      class="h-100pc oy-hidden-scroll p-relative bg-white"
      cols="12"
      md="8"
    >
      <div class="bc-extra-light-gray bt-1 h-100pc w-100pc">
        <MessageThreadHeader
          v-if="activeThread"
          @back="switchThread(false)"
          :subtitle="'Last message sent ' + lastMessageDate"
          :title="activeThread.name"
        />

        <transition-group name="fade">
          <div
            v-for="meta in filteredThreads"
            id="messages_list"
            :key="[meta.group_id, meta.business_id].join('')"
            :class="messageListClass"
            style="bottom: 242px"
          >
            <MessageItem
              v-for="message in currentMessageThread"
              :key="message.id"
              :message="message"
            />
          </div>
        </transition-group>

        <div
          class="bc-extra-light-gray bg-white w-100pc bottom-0 p-absolute"
          style="height: 242px"
        >
          <div class="pa-2">
            <v-row dense>
              <v-col>
                <v-card
                  class="bb-1 bc-very-light-grey"
                  style="background: rgba(0, 0, 0, 0.04)"
                  flat
                  tile
                >
                  <v-btn
                    @click="$refs.attachmentDialog.open({})"
                    class="focus-visible"
                    color="transparent"
                    data-cy="attach-button"
                    variant="flat"
                  >
                    <v-icon start> attachment </v-icon>
                    <span>Attach document</span>
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
            <AttachmentDialog
              @save="createAttachmentMessages"
              @upload="uploadedAttachments.push($event)"
              ref="attachmentDialog"
              :processing="processing"
            />
            <v-textarea
              v-model="newMessageText"
              aria-label="Type message"
              class="mb-2"
              data-cy="message-textarea"
              rows="4"
              variant="filled"
              hide-details
            />
            <v-btn
              @click="createMessage"
              :disabled="!newMessageText"
              :loading="processing"
              class="focus-visible"
              color="primary"
              data-cy="send-message-button"
              size="x-large"
              block
            >
              Send message
            </v-btn>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import AttachmentDialog from '@/shared/components/attachments/AttachmentDialog.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import MessageItem from '@/shared/components/MessageItem.vue';
import MessageThreadHeader from '@/shared/components/MessageThreadHeader.vue';
import vuetify from '@/plugins/vuetify';

export default {
  compatConfig: { MODE: 2 },

  components: {
    AttachmentDialog,
    LongDateTime,
    MarkdownContent,
    MessageItem,
    MessageThreadHeader,
  },

  data() {
    return {
      audience: 'Parents',
      activeThread: vuetify.display.mdAndUp ? null : false,
      currentMessageThread: [],
      currentMemberId: this.$store.state.profile.id,
      days: 30,
      threads: [],
      messageListClass: 'bg-white w-100pc d-flex flex-column pa-4 top-100 oy-scroll p-absolute',
      messages: [],
      messagesByThread: {},
      mode: null,
      newMessageText: null,
      processing: false,
      query: null,
      uploadedAttachments: [],
    };
  },
  computed: {
    lastMessageDate() {
      return new Date(
        this.messagesByThread[[this.activeThread.group_id, this.activeThread.business_id]][
          this.messagesByThread[[this.activeThread.group_id, this.activeThread.business_id]]
            .length - 1
        ].created_at,
      ).toLocaleString();
    },

    threadsForAudience() {
      const filterFn = (thread) =>
        this.audience === 'Parents' ? thread.group_id : thread.business_id;

      return this.threads.filter(filterFn);
    },

    filteredThreads() {
      const queryExists = this.query && this.query !== '';
      const filterFn = queryExists
        ? (thread) => thread.name.toLowerCase().includes(this.query.toLowerCase())
        : () => true;

      return this.threadsForAudience.filter(filterFn).slice();
    },
  },

  watch: {
    audience() {
      this.sortMessages();
    },
  },

  created() {
    this.loadMessages();
  },

  methods: {
    async createAttachmentMessages() {
      this.processing = true;

      const params = {
        business_id: this.activeThread.business_id,
        group_id: this.activeThread.group_id,
      };

      await Promise.all(
        this.uploadedAttachments.map(async (asset) => {
          const { data } = await Api.organization.attachment.create({
            ...asset,
            group_id: params.group_id,
            business_id: params.business_id,
          });
          const resp = await Api.organization.message.create({ ...params, attachment_id: data.id });
          this.messages.push(resp.data);
        }),
      );

      this.$refs.attachmentDialog.close();
      this.uploadedAttachments = [];
      this.processing = false;
      this.newMessageText = null;
      this.sortMessages();
      this.scrollToBottom();
    },

    createMessage() {
      this.processing = true;

      let params;
      if (this.activeThread) {
        params = {
          text: this.newMessageText,
          business_id: this.activeThread.business_id,
          group_id: this.activeThread.group_id,
        };
      } else {
        params = { text: this.newMessageText };
      }

      Api.organization.message.create(
        params,
        () => {
          this.loadThreadMessages();
          this.processing = false;
          this.newMessageText = null;
        },
        (err) => {
          this.processing = false;
          this.$eventBus.$emit('error', err);
        },
      );
    },

    goBack() {
      this.days += 30;
      this.loadMessages();
    },

    async loadThreadMessages() {
      if (!this.activeThread) {
        return;
      }

      const lastActiveThread = [this.activeThread.group_id, this.activeThread.business_id].join('');
      await Api.organization.message.index(
        {
          days: 1000,
          group_id: this.activeThread.group_id,
          business_id: this.activeThread.business_id,
        },
        (resp) => {
          if (
            lastActiveThread ===
            [this.activeThread.group_id, this.activeThread.business_id].join('')
          ) {
            this.messagesByThread[[this.activeThread.group_id, this.activeThread.business_id]] =
              resp.data;
            this.currentMessageThread = this.messagesByThread[
              [this.activeThread.group_id, this.activeThread.business_id]
            ]
              .slice()
              .reverse();
            const msg = this.currentMessageThread[this.currentMessageThread.length - 1];
            msg.read_at = true;
            Api.organization.message.get(msg.id); // allow server to potentially mark the message as read
            setTimeout(this.scrollToBottom, 100);
          }
        },
      );
    },

    async loadMessages() {
      this.processing = true;
      await Api.organization.message.index({ days: this.days }, (resp) => {
        this.messages = resp.data;
        this.sortMessages();
        this.processing = false;
      });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const list = document.getElementById('messages_list');

        if (!list) {
          return;
        } // ? race condition with setTimeout

        list.scrollTop = list.scrollHeight;
      });
    },

    sortMessages() {
      this.messagesByThread = {};

      this.messages.forEach((message) => {
        if (this.messagesByThread[[message.group_id, message.business_id]] === undefined) {
          this.messagesByThread[[message.group_id, message.business_id]] = [];
        }
        this.messagesByThread[[message.group_id, message.business_id]].push(message);

        const key = {
          group_id: message.group_id,
          business_id: message.business_id,
          name: message.meta.group_name || message.meta.business_name,
        };

        if (
          !this.threads.find(
            (threadKey) =>
              threadKey.group_id === key.group_id && threadKey.business_id === key.business_id,
            this,
          )
        ) {
          this.threads.push(key);
        }
      }, this);

      this.switchThread(this.filteredThreads[0]);
    },

    switchThread(thread) {
      this.activeThread = thread;
      this.loadThreadMessages();
    },
  },
};
</script>
