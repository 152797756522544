<template>
  <v-expansion-panels
    v-model="open"
    class="bg-transparent"
    flat
    tile
  >
    <v-expansion-panel
      :class="depth == 0 ? 'embedded bg-transparent' : 'bb-1 bc-outlined-gray'"
      :ripple="false"
      expand
    >
      <v-expansion-panel-title disable-icon-rotate>
        <template v-if="isChild">
          <SchemaElementTitle
            :field="field"
            :property="property"
          />
        </template>

        <template v-else>
          <span class="fs-20 fw-500">{{ $t(title || property.alias) }}</span>
        </template>

        <template #actions>
          <ActionMenu
            v-if="removable"
            @click:action:delete="removeElement"
            @click:action:duplicate="draftDuplicate"
            @click:action:rename="$refs.renameDialog.open(property)"
            :items="[
              { event: 'delete', title: $t('Remove property'), avatar: 'delete' },
              { event: 'rename', title: $t('Rename property'), avatar: 'edit' },
              { event: 'duplicate', title: $t('Duplicate property'), avatar: 'content_copy' },
            ]"
            button-icon="more_vert"
            left
          />
        </template>
      </v-expansion-panel-title>

      <v-expansion-panel-text :class="{ 'px-6': isChild, 'py-2': true }">
        <template v-if="isChild">
          <v-row class="mb-1">
            <LabeledTextfield
              v-model="localField"
              append-inner-icon="lock"
              cols="4"
              message="Name"
              hide-details
              readonly
            />
            <v-col cols="4">
              <div class="c-black fs-16 fw-500 mb-2">{{ $t('Type') }}</div>
              <v-select
                v-model="property.type"
                @update:model-value="$emit('change', property)"
                :append-inner-icon="lockType ? 'lock' : null"
                :items="TYPES_OTHER_THAN_OBJECT"
                :readonly="lockType"
                variant="filled"
                hide-details
              />
            </v-col>
            <v-col cols="4">
              <div v-show="property.type == 'string'">
                <div class="c-black fs-16 fw-500 mb-2">{{ $t('Format') }}</div>
                <v-select
                  v-model="property.format"
                  @update:model-value="$emit('change', property)"
                  :append-inner-icon="lockFormat ? 'lock' : null"
                  :items="FORMAT_OPTIONS"
                  :readonly="lockFormat"
                  variant="filled"
                  hide-details
                />
              </div>
            </v-col>
          </v-row>
          <v-row
            class="mb-4"
            dense
          >
            <LabeledTextfield
              v-model="property.alias"
              @input="$emit('change', property)"
              cols="12"
              message="Alias"
              hide-details
            />
          </v-row>

          <div class="c-black fs-16 fw-500 mb-2">{{ $t('Role') }}</div>
          <v-select
            v-model="property.role"
            @update:model-value="$emit('change', property)"
            :items="newPropertyRoleOptions"
            class="mb-4"
            variant="filled"
            hide-details
          />

          <div
            v-if="property.role == 'formula'"
            class="mb-4"
          >
            <div class="fs-16 fw-500 mb-2">{{ $t('Formula') }}:</div>
            <prism-editor
              v-model="property.expression"
              @input="$emit('change', property)"
              :highlight="highlighter"
              class="my-editor"
            />
          </div>

          <v-row v-show="property.type == 'number' || property.type == 'integer'">
            <v-col>
              <div class="fs-16 fw-500 mb-2">{{ $t('Minimum') }}:</div>
              <v-text-field
                v-model.number="property.minimum"
                @update:model-value="$emit('change', property)"
                type="number"
                variant="filled"
                hide-details
              />
            </v-col>

            <v-col>
              <div class="fs-16 fw-500 mb-2">{{ $t('Maximum') }}:</div>
              <v-text-field
                v-model.number="property.maximum"
                @update:model-value="$emit('change', property)"
                type="number"
                variant="filled"
                hide-details
              />
            </v-col>
          </v-row>

          <v-row
            v-show="property.type == 'string' && property.format == 'date'"
            class="mb-4"
            dense
          >
            <LabeledTextfield
              v-model="property.dateGreaterOrEqualToDate"
              @input="$emit('change', property)"
              message="Greater than or equal to date"
            />

            <LabeledTextfield
              v-model="property.dateLessOrEqualToDate"
              @input="$emit('change', property)"
              message="Less than or equal to date"
            />

            <LabeledTextfield
              v-model="property.dateGreaterOrEqualToDaysFromToday"
              @input="$emit('change', property)"
              message="Greater than or equal to days from today"
            />

            <LabeledTextfield
              v-model="property.dateLessOrEqualToDaysFromToday"
              @input="$emit('change', property)"
              message="Less than or equal to days from today"
            />

            <LabeledTextfield
              v-model="property.dateGreaterOrEqualToValue"
              @input="$emit('change', property)"
              message="Greater than or equal to value"
            />

            <LabeledTextfield
              v-model="property.dateLessOrEqualToValue"
              @input="$emit('change', property)"
              message="Less than or equal to value"
            />

            <v-col
              cols="12"
              md="6"
            >
              <div class="fw-500 mb-2">{{ $t('Minimum') }}:</div>
              <v-date-picker
                v-model="minimumDate"
                @update:model-value="updateMinimumDate"
                hide-details
                landscape
              />
              <v-btn
                @click="clear('minimum')"
                variant="text"
                block
              >
                <v-icon
                  icon="close"
                  start
                />
                <span>{{ $t('Clear') }}</span>
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <div class="fw-500 mb-2">{{ $t('Maximum') }}:</div>
              <v-date-picker
                v-model="maximumDate"
                @update:model-value="updateMaximumDate"
                hide-details
                landscape
              />
              <v-btn
                @click="clear('maximum')"
                variant="text"
                block
              >
                <v-icon
                  icon="close"
                  start
                />
                <span>{{ $t('Clear') }}</span>
              </v-btn>
            </v-col>
          </v-row>

          <div v-show="property.type == 'string' && !property.role">
            <div class="mb-4 fs-16">
              <div class="fw-500 mb-2">{{ $t('Mask') }}:</div>
              <v-text-field
                v-model="property.mask"
                @update:model-value="$emit('change', property)"
                variant="filled"
                hide-details
              />
            </div>
            <div class="mb-4">
              <div class="fw-500 mb-2 fs-16">{{ $t('Placeholder') }}:</div>
              <v-text-field
                v-model="property.placeholder"
                @update:model-value="$emit('change', property)"
                variant="filled"
                hide-details
              />
            </div>
            <v-row>
              <LabeledTextfield
                v-model="property.default"
                @input="$emit('change', property)"
                message="Default value"
                hide-details
              />
            </v-row>
          </div>

          <v-row>
            <LabeledSimpleSelect
              v-model="property.mappedModel"
              @change="$emit('change', property)"
              :items="['Child', 'Group', 'Provider', 'SubsidyAward']"
              class="mt-2"
              message="Mapped model"
              chips
              deletable-chips
            />

            <template v-if="property.mappedModel">
              <LabeledSimpleSelect
                v-model="property.mappedAttribute"
                @change="$emit('change', property)"
                :items="mappableFields"
                message="Mapped attribute"
              />
            </template>
          </v-row>

          <v-divider class="my-6" />

          <div class="mb-4">
            <div class="fs-16 fw-500 mb-2">{{ $t('Description') }}:</div>
            <v-text-field
              v-model="property.description"
              @update:model-value="$emit('change', property)"
              variant="filled"
              hide-details
            />
          </div>

          <div class="mb-4">
            <div class="fs-16 fw-500 mb-2">{{ $t('Tooltip') }}:</div>
            <v-text-field
              v-model="property.tooltip"
              @update:model-value="$emit('change', property)"
              variant="filled"
              hide-details
            />
          </div>

          <div>
            <div class="fs-16 fw-500">{{ $t('Icon') }}:</div>
            <v-text-field
              v-model="property.icon"
              @update:model-value="$emit('change', property)"
              variant="filled"
              hide-details
            />
          </div>
        </template>

        <template v-if="property.type == 'object'">
          <div class="mb-4">
            <div
              v-if="entries.length == 0"
              class="fs-16 c-red fw-500 mb-3"
            >
              {{ $t('Element does not have any properties.') }}
            </div>
            <div class="bg-white">
              <v-expansion-panels
                class="mb-3 b-1 bc-outlined-gray"
                flat
                tile
              >
                <SchemaElement
                  v-for="(subentry, subentryIndex) in entries"
                  @change="updateChildProperty(subentry[0], $event)"
                  @duplicate="duplicateChild"
                  @remove="removeEntry(subentry[0])"
                  :key="subentryIndex"
                  :definition="property"
                  :depth="depth + 1"
                  :field="subentry[0]"
                  :index="subentryIndex"
                  :removable="childrenRemovable"
                  :schemas="schemas"
                />
              </v-expansion-panels>
            </div>
            <div
              v-if="property.type == 'object'"
              class="d-flex align-center mb-3"
            >
              <v-btn
                @click="$refs.addPropertyDialog.open()"
                color="primary"
                size="small"
              >
                {{ $t('Add Property') }}
              </v-btn>
            </div>
          </div>
        </template>

        <template v-if="property.type == 'array'">
          <div class="mb-4">
            <v-divider class="my-6" />

            <template v-if="property.items.type == 'object'">
              <div class="d-flex align-center mb-4">
                <span class="fw-500 fs-16">Properties</span>
                <v-spacer />
                <v-btn
                  @click="$refs.itemPropertyDialog.open()"
                  class="me-3"
                  color="primary"
                  size="small"
                >
                  {{ $t('Add property') }}
                </v-btn>
              </div>

              <div
                v-if="itemPropertyEntries.length == 0"
                class="mb-4 fs-16 c-red fw-500"
              >
                {{ $t('Element does not have any properties.') }}
              </div>

              <div
                v-for="(itemProp, itemPropIndex) in itemPropertyEntries"
                :key="itemPropIndex"
              >
                <SchemaElement
                  @change="updateItemProperty(itemProp[0], $event)"
                  @remove="removeItemProperty(itemProp[0])"
                  :definition="{ properties: itemProperties }"
                  :depth="depth + 1"
                  :field="itemProp[0]"
                  :index="itemPropIndex"
                  :schemas="schemas"
                  removable
                />
              </div>
            </template>

            <template v-if="property.items.type == 'string'">
              <div class="d-flex align-center mb-4">
                <span class="fw-500 fs-16">{{ $t('Options') }}</span>
                <v-spacer />
                <v-btn
                  @click="addOption"
                  class="me-3"
                  color="primary"
                  size="small"
                >
                  <v-icon icon="add" />
                </v-btn>
                <v-btn
                  @click="draftMultipleEnum"
                  color="primary"
                  size="small"
                >
                  <v-icon
                    icon="queue"
                    start
                  />
                  <span>{{ $t('Add multiple') }}</span>
                </v-btn>
              </div>
              <div
                v-if="property.items?.enum?.length == 0"
                class="mb-4 fs-16 c-red fw-500"
              >
                {{ $t('Element does not have any options.') }}
              </div>
              <v-row
                v-for="(option, optionIndex) in property.items?.enum"
                :key="optionIndex"
                dense
              >
                <v-col cols="12">
                  <v-text-field
                    v-model="property.items.enum[optionIndex]"
                    @click:append="removeOption(optionIndex)"
                    @update:model-value="$emit('change', property)"
                    append-icon="close"
                    variant="filled"
                    hide-details
                  />
                </v-col>
              </v-row>
            </template>
          </div>
        </template>

        <template v-if="property.type == 'string'">
          <div class="mb-4">
            <v-divider class="my-6" />

            <div class="mb-4 fs-16 fw-500">{{ $t('Options') }}:</div>
            <div
              v-if="!property.enum || property.enum.length == 0"
              class="my-4 fs-16 c-red fw-500"
            >
              {{ $t('Element does not have any options.') }}
            </div>
            <v-row
              v-for="(option, optionIndex) in property.enum"
              v-else
              :key="optionIndex"
              dense
            >
              <v-col cols="12">
                <v-text-field
                  v-model="property.enum[optionIndex]"
                  @click:append="removeEnum(optionIndex)"
                  @update:model-value="$emit('change', property)"
                  append-icon="close"
                  variant="filled"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col>
                <v-btn
                  @click="addEnum"
                  class="me-3"
                  color="primary"
                  size="small"
                >
                  <v-icon icon="add" />
                </v-btn>
                <v-btn
                  @click="draftMultipleEnum"
                  color="primary"
                  size="small"
                >
                  <v-icon
                    icon="queue"
                    start
                  />
                  <span>{{ $t('Add multiple') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </template>

        <template v-if="isChild">
          <v-divider class="my-6" />

          <v-row
            class="d-flex align-center mb-3"
            dense
          >
            <v-col
              class="fw-500 fs-16"
              cols="12"
            >
              {{ $t('Editors') }}
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="property.editRoles"
                @update:model-value="$emit('change', property)"
                :items="ROLE_OPTIONS"
                variant="filled"
                chips
                closable-chips
                hide-details
                multiple
              />
            </v-col>
          </v-row>

          <v-row
            class="d-flex align-center"
            dense
          >
            <v-col
              class="fw-500 fs-16"
              cols="12"
            >
              {{ $t('Viewers') }}
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="property.viewRoles"
                @update:model-value="$emit('change', property)"
                :items="ROLE_OPTIONS"
                variant="filled"
                chips
                closable-chips
                hide-details
                multiple
              />
            </v-col>
          </v-row>

          <v-divider class="my-6" />

          <v-row dense>
            <LabeledSwitch
              v-model="property.enabled"
              @input="$emit('change', property)"
              subtitle="This field will appear in forms, if applicable."
              title="Enabled"
              divided-bottom
            />
            <LabeledSwitch
              v-model="property.readOnly"
              @input="$emit('change', property)"
              subtitle="This field cannot be edited"
              title="Readonly"
              divided-bottom
            />
            <LabeledSwitch
              v-model="property.reportable"
              @input="$emit('change', property)"
              subtitle="This field will be selectable in reports."
              title="Reportable"
              divided-bottom
            >
              <v-row
                v-show="property.reportable"
                class="my-2"
              >
                <LabeledTextfield
                  v-model="property.reportingAlias"
                  @input="$emit('change', property)"
                  description="This alias will be used in reports as the default column title. If not set, the alias or title will be used."
                  message="Reporting alias"
                />
                <v-col>
                  <v-btn
                    @click="$refs.transformDialog.open(property.reportingTransforms || [])"
                    color="primary"
                    size="small"
                    variant="outlined"
                  >
                    <span>{{ $t('Transforms') }} ({{ reportingTransformsLength }})</span>
                  </v-btn>
                </v-col>
              </v-row>
            </LabeledSwitch>
            <LabeledSwitch
              v-model="property.filterable"
              @input="$emit('change', property)"
              subtitle="This field can be used as a filter option, if applicable."
              title="Filterable"
              divided-bottom
            />
            <LabeledSwitch
              v-model="property.identifier"
              @input="$emit('change', property)"
              subtitle="This field can be used as a link to other tables. It must have the same name as the foreign key field in the associated table."
              title="Identifier"
              divided-bottom
            />
            <LabeledSwitch
              v-model="property.foreignKey"
              @input="$emit('change', property)"
              :divided-bottom="!property.foreignKey"
              subtitle="This field can be used as a link to other tables. It must have the same name as the identifier field in the foreign table."
              title="Foreign key"
            />
            <template v-if="property.foreignKey">
              <LabeledSimpleSelect
                v-model="property.foreignKeyTable"
                @change="$emit('change', property)"
                :items="['Group']"
                class="mt-2"
                message="Foreign key table"
                chips
                deletable-chips
              />
              <v-col cols="12">
                <v-divider class="mt-3" />
              </v-col>
            </template>
          </v-row>
        </template>

        <v-dialog
          v-model="addMultipleEnumDialog"
          max-width="600"
        >
          <v-card
            class="mx-auto"
            max-width="600px"
          >
            <v-card-title>{{ $t('Add options') }}</v-card-title>
            <v-card-text>
              <p class="fs-16 c-black">
                {{ $t('Add multiple options below, one on each line.') }}
              </p>
              <v-textarea
                v-model="multipleEnum"
                variant="filled"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn
                @click="addMultipleEnumDialog = false"
                variant="text"
              >
                {{ $t('Cancel') }}
              </v-btn>
              <v-spacer />
              <v-btn
                @click="addMultipeEnum"
                :disabled="!multipleEnum"
                color="primary"
              >
                {{ $t('Add') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-expansion-panel-text>

      <ResourceDialog
        @save="setTransforms"
        ref="transformDialog"
        title="Find & replace"
      >
        <template #form="{ localValue }">
          <p v-if="localValue.length == 0">
            {{ $t('Nothing has been replaced for this column.') }}
          </p>
          <div
            v-for="(transform, transformIndex) in localValue"
            :key="transformIndex"
          >
            <v-row dense>
              <v-col class="d-flex align-center">
                <v-btn
                  @click="localValue.splice(transformIndex, 1)"
                  color="red"
                  variant="text"
                  icon
                >
                  <v-icon icon="close" />
                </v-btn>
                <v-text-field
                  v-model="transform.target"
                  label="Find"
                  variant="filled"
                  hide-details
                />
              </v-col>
              <v-col class="d-flex align-center">
                <v-text-field
                  v-model="transform.value"
                  label="Replace"
                  variant="filled"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-divider
              v-show="transformIndex + 1 < localValue.length"
              class="my-3"
            />
          </div>
          <v-row>
            <v-col class="d-flex justify-center">
              <v-btn
                @click="localValue.push({ target: null, value: null })"
                class="mt-3"
                color="primary"
                size="small"
                variant="text"
                icon
              >
                <v-icon icon="add" />
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </ResourceDialog>

      <ResourceDialog
        @save="addItemProperty"
        ref="itemPropertyDialog"
        :fields="ITEM_PROPERTY_FIELDS"
        :max-width="800"
        save-button-text="Add"
        title="Add property to array item"
        close-on-save
      />

      <ResourceDialog
        @save="addProperty"
        ref="addPropertyDialog"
        :max-width="800"
        :save-button-disabled="!newPropertyName || !newPropertyType"
        save-button-text="Add"
        title="Add property"
      >
        <template #form>
          <v-row>
            <LabeledTextfield
              v-model="newPropertyName"
              :hint="
                $t('Only alphanumeric characters allowed. Must start with a letter or underscore.')
              "
              cols="6"
              message="Name"
              persistent-hint
            />

            <LabeledTextfield
              v-model="newPropertyAlias"
              :hint="$t('Short name recommended to appear concisely in reports')"
              cols="6"
              message="Alias"
              persistent-hint
            />

            <LabeledSimpleSelect
              v-model="newPropertyType"
              :items="TYPES_OTHER_THAN_OBJECT"
              cols="4"
              message="Type"
            />

            <LabeledSimpleSelect
              v-if="newPropertyType == 'string'"
              v-model="newPropertyFormat"
              :items="FORMAT_OPTIONS"
              cols="4"
              message="Format"
            />

            <LabeledSimpleSelect
              v-if="newPropertyType == 'array'"
              v-model="newPropertyItemType"
              :items="ITEM_TYPE_OPTIONS"
              cols="4"
              message="Item type"
            />

            <LabeledSimpleSelect
              v-if="newPropertyType"
              v-model="newPropertyRole"
              :items="newPropertyRoleOptions"
              cols="4"
              message="Role"
            />

            <LabeledSimpleSelect
              v-model="newPropertyEditRoles"
              :items="ROLE_OPTIONS"
              message="Edit Roles"
              multiple
            />
          </v-row>
        </template>
      </ResourceDialog>

      <ResourceDialog
        @save="rename"
        ref="renameDialog"
        :fields="[{ text: 'Alias', value: 'alias' }]"
        save-button-text="Done"
        title="Rename property"
        close-on-save
      />

      <ResourceDialog
        @save="duplicate"
        ref="duplicateDialog"
        save-button-text="Done"
        title="Duplicate property"
        close-on-save
      >
        <template #form>
          <v-row>
            <LabeledTextfield
              v-model="newPropertyName"
              :hint="
                $t('Only alphanumeric characters allowed. Must start with a letter or underscore.')
              "
              message="Property name"
              persistent-hint
            />

            <LabeledTextfield
              v-model="newPropertyAlias"
              message="Alias"
              hide-details
            />
          </v-row>
        </template>
      </ResourceDialog>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import Schema from '@/shared/services/schema';
import SchemaElementTitle from '@/admin/components/schemas/SchemaElementTitle.vue';
import { useDate } from 'vuetify';
import useEventBus from '@/shared/composables/useEventBus';

import { highlight, languages } from 'prismjs/components/prism-core';
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css';
import _ from 'lodash';

const TYPES_OTHER_THAN_OBJECT = [
  { text: 'Array', value: 'array' },
  { text: 'Boolean', value: 'boolean' },
  { text: 'Integer', value: 'integer' },
  { text: 'Number', value: 'number' },
  { text: 'String', value: 'string' },
];

const FORMAT_OPTIONS = [
  { text: 'No format', value: null },
  { text: 'Date-time', value: 'date-time' },
  { text: 'Date', value: 'date' },
  { text: 'Email', value: 'email' },
  { text: 'URI', value: 'uri' },
  { text: 'UUID', value: 'uuid' },
];

const ITEM_PROPERTY_FIELDS = [
  { required: true, text: 'Name', value: 'name' },
  { required: true, text: 'Title', value: 'title' },
  {
    required: true,
    text: 'Type',
    value: 'type',
    items: TYPES_OTHER_THAN_OBJECT,
    itemText: 'text',
    itemValue: 'value',
  },
];

const ITEM_TYPE_OPTIONS = [
  { text: 'String', value: 'string' },
  { text: 'Object', value: 'object' },
];

const ROLE_OPTIONS = [
  { text: 'Manager', value: 'manager' },
  { text: 'Parent', value: 'parent' },
  { text: 'Specialist', value: 'specialist' },
];

const date = useDate();
const emit = defineEmits(['change', 'duplicate', 'remove']);
const eventBus = useEventBus();

const props = defineProps({
  childrenRemovable: {
    default: false,
    type: Boolean,
  },
  definition: {
    type: Object,
    default: null,
  },
  depth: {
    type: Number,
    default: null,
  },
  field: {
    type: String,
    default: null,
  },
  index: {
    type: Number,
    default: null,
  },
  isChild: {
    default: true,
    type: Boolean,
  },
  lockFormat: Boolean,
  lockType: {
    default: true,
    type: Boolean,
  },
  openNow: Boolean,
  removable: {
    default: false,
    type: Boolean,
  },
  schemas: {
    type: Array,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
});

const addMultipleEnumDialog = ref(false);
const addPropertyDialog = ref(null);
const duplicateDialog = ref(null);
const entries = ref([]);
const itemProperties = reactive({});
const localField = ref(props.field);
const maximumDate = ref(null);
const minimumDate = ref(null);
const multipleEnum = ref(null);
const newPropertyAlias = ref(null);
const newPropertyEditRoles = ref([]);
const newPropertyItemType = ref(null);
const newPropertyFormat = ref(null);
const newPropertyName = ref(null);
const newPropertyRole = ref(null);
const newPropertyType = ref(null);
const open = ref(props.openNow ? 0 : -1);
const property = reactive({});
const transformDialog = ref(null);

const itemPropertyEntries = computed(() => {
  return _.sortBy(Object.entries(itemProperties), (object) => object[1].index);
});

const mappableFields = computed(() => {
  if (props.schemas && property.mappedModel) {
    const schema = props.schemas.find((schema) => schema.data_type === property.mappedModel);
    if (schema && schema.definition) return Schema.fields(schema.definition);
  }
  return [];
});

const newPropertyRoleOptions = computed(() => {
  const roleAry = [
    { text: '-', value: null },
    { text: 'Formula', value: 'formula' },
  ];

  if (property.type === 'number' || newPropertyType.value === 'number') {
    roleAry.push({ text: 'Currency - US', value: 'currency-us' });
  }

  if (property.type === 'string' || newPropertyType.value === 'string') {
    roleAry.push({ text: 'Currency - US', value: 'currency-us' });
    roleAry.push({ text: 'Phone - US', value: 'phone-us' });
    roleAry.push({ text: 'Text - long', value: 'text-long' });
  }

  return roleAry;
});

const reportingTransformsLength = computed(() => property.reportingTransforms?.length || 0);

watch(
  () => props.definition,
  () => {
    entries.value = Object.entries(property.properties || {});
  },
);

watch(
  () => props.field,
  () => load(),
);

watch(
  () => newPropertyName.value,
  (newVal) => {
    if (newVal) {
      newPropertyName.value = newVal.toLowerCase().replace(' ', '_');
    }
  },
);

onMounted(() => load());

function addEnum() {
  if (!property.enum) property.enum = [];
  property.enum.push('');
  emit('changed', property);
}

function addItemProperty(newVal) {
  const newProperty = { alias: newVal.title, title: newVal.title, type: newVal.type };
  if (newVal.type === 'array') {
    newProperty.items = { type: 'string', properties: {} };
  }
  itemProperties[newVal.name] = newProperty;
  property.items.properties = itemProperties;
  emit('change', property);
}

function addMultipeEnum() {
  const options = multipleEnum.value.split(/\n/);
  if (property.type === 'array') {
    property.items.enum ||= [];
    property.items.enum = property.items.enum.concat(options);
  } else {
    property.enum ||= [];
    property.enum = property.enum.concat(options);
  }
  addMultipleEnumDialog.value = false;
  emit('change', property);
}

function addOption() {
  property.items.enum ||= [];
  property.items.enum.push('');
  emit('change', property);
}

function addProperty() {
  if (!validNewPropertyName() || !!property.properties[newPropertyName.value]) {
    eventBus.longChime(
      'Property names must be unique and can only contain a-z, 0-9, or _, and must start with a letter.',
    );
    return;
  }

  const newProp = {
    alias: newPropertyAlias.value,
    editRoles: newPropertyEditRoles.value,
    format: newPropertyFormat.value,
    type: newPropertyType.value,
    enabled: true,
    reportable: true,
    role: newPropertyRole.value,
  };

  if (newPropertyType.value === 'array') {
    if (newPropertyItemType.value === 'object') {
      newProp.items = { type: 'object', properties: {} };
    } else {
      newProp.items = { type: 'string', enum: [] };
    }
  }

  property.properties[newPropertyName.value] = newProp;

  entries.value = _.sortBy(Object.entries(property.properties), (object) => object[1].index);

  addPropertyDialog.value?.close();
  newPropertyAlias.value = null;
  newPropertyEditRoles.value = [];
  newPropertyFormat.value = null;
  newPropertyRole.value = null;
  newPropertyType.value = null;
  newPropertyName.value = null;
  emit('change', property);
}

function clear(attr) {
  property[attr] = null;
  emit('change', property);
}

function draftMultipleEnum() {
  multipleEnum.value = null;
  addMultipleEnumDialog.value = true;
}

function draftPropertyFromField() {
  const draft = JSON.parse(JSON.stringify(props.definition.properties[props.field]));
  if (draft.type === 'string' && !draft.enum) draft.enum = [];
  return draft;
}

function draftDuplicate() {
  newPropertyAlias.value = property.alias;
  newPropertyName.value = props.field;
  duplicateDialog.value.open();
}

function duplicate() {
  const newProp = JSON.parse(JSON.stringify(property));
  newProp.alias = newPropertyAlias.value;
  emit('duplicate', { key: newPropertyName.value, property: newProp });
}

function duplicateChild(newProp) {
  if (!newProp.key || property.properties[newProp.key]) {
    eventBus.longChime('Field name already exists or is invalid');
  } else {
    property.properties[newProp.key] = newProp.property;
    entries.value = _.sortBy(Object.entries(property.properties), (object) => object[1].index);
    emit('change', property);
  }
}

function getSubEntries() {
  if (
    props.definition.properties[props.field].type === 'object' &&
    props.definition.properties[props.field].properties
  ) {
    return _.sortBy(
      Object.entries(props.definition.properties[props.field].properties || {}),
      (object) => object[1].index,
    );
  }
  return [];
}

function highlighter(code) {
  return highlight(code, languages.js, 'js');
}

function load() {
  entries.value = getSubEntries();
  Object.assign(property, draftPropertyFromField());
  Object.assign(itemProperties, property.items?.properties || {});

  if (property.type == 'string' && property.format == 'date') {
    maximumDate.value = property.maximum ? date.parseISO(property.maximum) : null;
    minimumDate.value = property.minimum ? date.parseISO(property.minimum) : null;
  }

  if (property.index !== props.index) {
    property.index = props.index;
    emit('change', property);
  }
}

function removeEnum(index) {
  property.enum.splice(index, 1);
  emit('change', property);
}

function removeItemProperty(field) {
  delete itemProperties[field];
  property.items.properties = itemProperties;
  emit('change', property);
}

function removeOption(index) {
  property.items.enum.splice(index, 1);
  emit('change', property);
}

function removeElement() {
  emit('remove');
}

function removeEntry(entryName) {
  delete property.properties[entryName];
  entries.value = Object.entries(property.properties);
  emit('change', property);
}

function rename(newVal) {
  property.alias = newVal.alias;
  emit('change', property);
}

function setTransforms(transforms) {
  property.reportingTransforms = transforms;
  transformDialog.value?.close();
  emit('change', property);
}

function updateChildProperty(childProp, newVal) {
  property.properties[childProp] = newVal;
  emit('change', property);
}

function updateItemProperty(field, newVal) {
  itemProperties[field] = newVal;
  property.items.properties = itemProperties;
  emit('change', property);
}

function updateMaximumDate(newMaximum) {
  property.maximum = newMaximum ? date.toISO(newMaximum) : null;
  emit('change', property);
}

function updateMinimumDate(newMinimum) {
  property.minimum = newMinimum ? date.toISO(newMinimum) : null;
  emit('change', property);
}

function validNewPropertyName() {
  return (
    !!newPropertyName.value &&
    newPropertyName.value.length > 0 &&
    !newPropertyName.value.match(/^[^a-z]/) &&
    !newPropertyName.value.match(/[^a-z_0-9]+/g)
  );
}
</script>

<style>
.embedded > .v-expansion-panel-content > .v-expansion-panel-content__wrap,
.embedded > .v-expansion-panel-header {
  padding: 0px !important;
}

/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #fff;
  color: #38beb5;

  /* you must provide font-family font-size line-height. Example: */
  font-family:
    Fira code,
    Fira Mono,
    Consolas,
    Menlo,
    Courier,
    monospace;
  font-size: 16px;
  line-height: 1.5;
  padding: 10px;
  border: 1px solid #38beb5;
}

.token.punctuation {
  color: #38beb5;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
