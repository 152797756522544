<template>
  <v-row
    class="d-flex align-center"
    dense
  >
    <v-col cols="9">
      <div class="fs-16 fw-500">
        {{ $t(schema.title) }}
      </div>
      <div
        v-if="schema.description"
        v-text="schema.description"
        class="fs-14 c-light-black"
      />
    </v-col>

    <v-col
      v-if="schema.examples && schema.examples.length > 0"
      cols="12"
    >
      Examples: {{ schema.examples.join(',') }}
    </v-col>

    <v-col
      v-if="schema.type == 'object'"
      class="mb-4"
      cols="12"
    >
      <div
        v-if="entries.length == 0"
        class="fs-16 c-red fw-500"
      >
        {{ $t('Element does not have any properties.') }}
      </div>
      <v-expansion-panels flat>
        <SchemaControl
          v-for="(subentry, index) in entries"
          v-model="modelValue.value[subentry[0]]"
          :key="index"
          :depth="depth + 1"
          :field="subentry[0]"
          :schema="subentry[1]"
        />
      </v-expansion-panels>
    </v-col>

    <v-col
      v-if="schema.type == 'array'"
      class="ta-right"
      cols="3"
    >
      <v-btn
        @click="draftItem"
        color="primary"
        size="small"
      >
        {{ $t('Add value') }}
      </v-btn>
    </v-col>

    <template v-if="schema.type == 'array'">
      <v-col
        v-for="(item, index) in modelValue"
        :key="index"
        class="d-flex align-center b-1 bc-very-light-gray px-3 py-3 mb-3 mt-3"
        cols="12"
      >
        <v-row class="d-flex align-center">
          <template v-if="schema.items.type == 'object'">
            <v-col
              v-for="(prop, propIndex) in Object.entries(schema.items.properties)"
              :key="'itemProp' + propIndex"
              :cols="prop[1].cols || 12"
            >
              <div>
                <v-text-field
                  v-if="prop[1].type == 'number'"
                  v-model.number="item[prop[0]]"
                  @update:model-value="updateItem(index, prop[0], parseInt($event))"
                  :label="prop[1].title"
                  type="number"
                  variant="filled"
                  hide-details
                  tile
                />

                <v-checkbox
                  v-else-if="prop[1].type == 'boolean'"
                  v-model="item[prop[0]]"
                  @update:model-value="updateItem(index, prop[0], $event)"
                  :label="prop[1].title"
                  class="mt-0"
                  density="compact"
                  hide-details
                  inset
                  tile
                />

                <v-textarea
                  v-else
                  @update:model-value="updateItem(index, prop[0], $event)"
                  :label="prop[1].title"
                  :model-value="item[prop[0]]"
                  :rows="1"
                  variant="filled"
                  auto-grow
                  hide-details
                  tile
                />
              </div>
            </v-col>
          </template>
          <template v-else>
            <v-col
              class="fs-14"
              cols="12"
            >
              <span v-text="item"></span>
            </v-col>
          </template>
        </v-row>
        <v-btn
          @click="removeItem(index)"
          class="ms-2"
          size="small"
          variant="text"
          icon
        >
          <v-icon icon="close" />
        </v-btn>
      </v-col>
    </template>

    <v-col
      v-if="schema.type == 'string'"
      cols="12"
    >
      <v-textarea
        v-if="schema.role == 'text-long'"
        v-model="modelValue"
        :aria-label="schema.title"
        :rows="5"
        variant="filled"
        auto-grow
        hide-details
        tile
      />

      <v-select
        v-else-if="schema.enum"
        v-model="modelValue"
        :items="schema.enum"
        variant="filled"
        hide-details
        tile
      />

      <v-textarea
        v-else
        v-model="modelValue"
        :aria-label="schema.title"
        :rows="1"
        variant="filled"
        auto-grow
        hide-details
        tile
      />
    </v-col>

    <v-col
      v-if="schema.type == 'number' || schema.type == 'integer'"
      cols="12"
    >
      <v-text-field
        v-model="modelValue"
        :model-value="modelValue"
        type="number"
        variant="filled"
        hide-details
        tile
      />
    </v-col>

    <v-col
      v-if="schema.type == 'boolean'"
      class="d-flex justify-end"
      cols="3"
    >
      <v-switch
        v-model="modelValue"
        class="mt-0"
        density="compact"
        hide-details
        inset
      />
    </v-col>

    <ResourceDialog
      @save="addItem"
      ref="itemDialog"
      :fields="itemFields"
      title="New item"
    />
  </v-row>
</template>

<script setup>
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

const props = defineProps({
  schema: {
    type: Object,
    required: true,
  },
  depth: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['update:modelValue']);

const modelValue = defineModel();
const entries =
  props.schema.type === 'object' && props.schema.properties
    ? Object.entries(props.schema.properties)
    : [];
const itemDialog = ref(null);
const itemFields = getItemFields();

function getItemFields() {
  if (props.schema.type === 'array') {
    if (props.schema.items.type === 'object') {
      return Object.entries(props.schema.items.properties).map((prop) => {
        return {
          value: prop[0],
          text: prop[1].title,
          type: prop[1].type,
          default: prop[1].default,
        };
      });
    } else {
      return [
        {
          value: 'value',
          text: 'Value',
          type: 'string',
          default: '',
        },
      ];
    }
  } else {
    return [];
  }
}

function addItem(newVal) {
  if (props.schema.type === 'array') {
    if (props.schema.items.type === 'object') {
      modelValue.value.push(newVal);
    } else {
      newVal.value.split(/\n/).forEach((name) => {
        modelValue.value.push(name);
      });
    }
  } else {
    modelValue.value.push(JSON.parse(JSON.stringify(newVal)));
  }
  itemDialog.value.close();
  handleModelUpdated();
}

function draftItem() {
  itemDialog.value.open();
}

function handleModelUpdated() {
  emit('update:modelValue', modelValue.value);
}

function removeItem(index) {
  modelValue.value.splice(index, 1);
  handleModelUpdated();
}

function updateItem(index, prop, newPropVal) {
  modelValue.value[index][prop] = newPropVal;
  handleModelUpdated();
}
</script>
