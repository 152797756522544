export default [
  {
    icon: 'request_quote',
    title: 'Grants',
    route: 'GrantProgramIndex',
  },
  {
    icon: 'person',
    title: 'Child',
    route: 'ChildSubsidyProgramIndex',
  },
  {
    icon: 'quiz',
    title: 'Eligibility',
    route: 'EligibilityProgramIndex',
  },
  {
    icon: 'supervisor_account',
    title: 'Family',
    route: 'FamilySubsidyProgramIndex',
  },
  {
    icon: 'account_balance',
    title: 'Funds',
    route: 'FundIndex',
  },
  {
    icon: 'insert_chart',
    title: 'Screener',
    route: 'ScreenerProgramIndex',
  },
];
