<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-card
    class="mb-4"
    border
    flat
    tile
  >
    <v-card-title>
      <v-row class="d-flex align-center">
        <v-col>
          {{ $t('Children') }}
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            @click="$refs.addChildDialog.open()"
            color="primary"
          >
            <v-icon>add</v-icon>
            <span>{{ $t('Add child') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />
    <v-card-text v-if="subsidies">
      <v-card
        v-for="(child, index) in children"
        :key="child.id"
        class="mb-1"
        border
        flat
        tile
      >
        <v-card-title>
          <v-row dense>
            <v-col
              class="d-flex align-center"
              cols="8"
            >
              <span class="fs-22 fw-500 me-2">
                {{ child.name || ['Child', index + 1].join(' ') }}
              </span>
              <span class="fs-18 fw-500 c-light-black">#{{ child.external_id }}</span>
            </v-col>
            <v-col class="d-flex justify-end">
              <ActionMenu
                @click:action:delete="remove(child)"
                :disabled="!store.state.profile.org_groups_delete"
                :items="[{ event: 'delete', title: 'Delete child', avatar: 'delete' }]"
                button-icon="more_vert"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <Child
            @removal="remove(child)"
            :group-id="groupId"
            :initial-child="child"
            :subsidies="subsidies.filter((subsidy) => subsidy.child_id == child.id)"
            :subsidy-programs="subsidyPrograms"
          />
        </v-card-text>
      </v-card>
      <div
        v-if="children.length == 0"
        class="fs-16 fw-500"
      >
        {{ $t('This family has no children.') }}
      </div>
    </v-card-text>

    <ResourceDialog
      @save="addChild()"
      ref="addChildDialog"
      :max-width="950"
      title="Add Child"
      closeable
    >
      <template #form>
        <v-row
          class="fs-16"
          data-testid="addChildDialog"
        >
          <LabeledTextfield
            v-model="newChildData.first_name"
            cols="6"
            data-cy="child_first_name"
            data-testid="child_first_name"
            message="First name"
            mandatory
          />
          <LabeledTextfield
            v-model="newChildData.last_name"
            cols="6"
            data-cy="child_last_name"
            data-testid="child_last_name"
            message="Last name"
            mandatory
          />
        </v-row>
        <v-row class="fs-16">
          <LabeledDateOfBirth
            v-model="newChildData.dob"
            cols="8"
            data-cy="child_dob"
            data-testid="child_dob"
            md="9"
            message="Date of birth"
            mandatory
          />
          <LabeledTextfield
            v-model="childAgeGroup"
            :disabled="!newChildData.dob"
            :placeholder="$t('Enter birth date to calculate')"
            append-inner-icon="sync"
            cols="12"
            md="3"
            message="Age group"
            readonly
          />
        </v-row>
        <v-row class="fs-16">
          <LabeledDatePicker
            v-model="newChildData.care_needed_date"
            :disabled="!newChildData.dob"
            :disallow-before-date="newChildData.dob"
            cols="12"
            disabled-message="Enter birth date"
            md="6"
            message="Date care needed"
          />
          <LabeledTextfield
            v-model="ageGroupWhenCareNeeded"
            :disabled="!newChildData.care_needed_date"
            :placeholder="$t('Enter care needed date to calculate')"
            append-inner-icon="sync"
            cols="12"
            md="6"
            message="Age group at time of care"
            readonly
          />
        </v-row>
      </template>
    </ResourceDialog>
  </v-card>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Child from '@/specialist/components/group/Child.vue';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import LabeledDateOfBirth from '@/shared/components/form/LabeledDateOfBirth.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useAgeCalculations from '@/shared/composables/useAgeCalculations';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';

const { ageGroupFromDate } = useAgeCalculations();
const eventBus = useEventBus();
const store = useStore();

const props = defineProps({
  groupId: {
    type: String,
    default: null,
  },
});

const addChildDialog = ref(null);
const children = ref([]);
const newChildData = ref({});
const subsidies = ref([]);
const subsidyPrograms = ref([]);

const childAgeGroup = computed(() => {
  const noneFoundText = 'No age group found';
  if (newChildData.value.dob) {
    const dob = new Date(newChildData.value.dob);
    const ageGroup = ageGroupFromDate(dob);
    return ageGroup?.name || noneFoundText;
  }
  return null;
});

const ageGroupWhenCareNeeded = computed(() => {
  const noneFoundText = 'No age group found';
  if (newChildData.value.dob && newChildData.value.care_needed_date) {
    const careNeededDate = new Date(newChildData.value.care_needed_date);
    const dob = new Date(newChildData.value.dob);
    const ageGroup = ageGroupFromDate(dob, careNeededDate);
    return ageGroup?.name || noneFoundText;
  }
  return null;
});

const valid = computed(() => {
  const { first_name: firstName, last_name: lastName, dob } = newChildData.value;
  return firstName && lastName && dob;
});

watch(
  () => props.groupId,
  () => load(),
  { immediate: true },
);

async function addChild() {
  if (valid.value) {
    const resp = await api.organization.group.child.create(props.groupId, newChildData.value);
    children.value.push(resp.data);
    newChildData.value = {};
    addChildDialog.value.close();
  } else {
    eventBus.chime('Please ensure all required fields are populated');
  }
}

async function load() {
  const childResp = await api.organization.group.child.index(props.groupId);
  children.value = childResp?.data;

  if (store.state.profile.org_subsidies_access || store.state.profile.org_subsidies_agent) {
    await loadSubsidyPrograms();

    const subsidyResp = await api.organization.subsidy.index({
      group_id: props.groupId,
      draft: true,
    });
    subsidies.value = subsidyResp.data;
  }
}

async function loadSubsidyPrograms() {
  const resp = await api.organization.subsidy_program.index();

  if (store.state.profile.org_subsidies_admin) {
    subsidyPrograms.value = resp.data;
  } else {
    const programs = resp.data.filter(
      (program) => !!program.published_at && programOpened(program),
    );
    subsidyPrograms.value = programs;
  }
}

function programOpened(program) {
  return !program.opens_at || new Date() >= new Date(program.opens_at);
}

async function remove(child) {
  // eslint-disable-next-line no-restricted-globals, no-alert
  if (confirm('Are you sure you want to remove this child?')) {
    await api.organization.group.child.destroy(props.groupId, child.id);
    await load();
  }
}
</script>
