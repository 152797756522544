<template>
  <div class="d-flex justify-center px-2">
    <v-btn
      v-if="rail"
      @click.stop="openSearch()"
      :aria-label="$t('Search providers')"
      class="d-flex"
      color="black"
      icon="search"
      variant="text"
    />

    <v-btn
      v-else
      @click.stop="openSearch()"
      :aria-label="$t('Search providers')"
      :placeholder="$t('Search for providers')"
      class="d-flex justify-start pe-6 c-light-black"
      color="surface-light"
      prepend-icon="search"
      variant="flat"
      block
      rounded
    >
      {{ $t('Search') }}
    </v-btn>

    <v-dialog
      v-model="searchDialogIsVisible"
      content-class="left-0 top-0 right-0"
    >
      <v-card tile>
        <v-card-text class="d-flex align-center justify-center pa-6">
          <v-autocomplete
            v-if="store.state.profile.org_providers_access"
            v-model="selectedProvider"
            v-model:search="query"
            @focus="focused = true"
            ref="providerSearch"
            :items="searchedProviders"
            :placeholder="$t('Search for providers')"
            aria-label="Search for providers"
            color="secondary"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="search"
            variant="solo-filled"
            hide-details
            hide-no-data
            no-filter
            return-object
            rounded
            tracked
          >
            <template #item="{ item, props }">
              <v-list-item
                v-bind="props"
                :subtitle="item.raw.location"
              />
            </template>
          </v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import vuetify from '@/plugins/vuetify';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();

const focused = ref(false);
const query = ref(null);
const router = useRouter();
const providerSearch = ref(null);
const searchDialogIsVisible = ref(false);
const searchedProviders = ref([]);
const selectedProvider = ref(null);

watch(selectedProvider, (newVal) => {
  if (newVal) {
    searchDialogIsVisible.value = false;
    router.push({ name: 'ProviderShow', params: { providerId: newVal.id } });
    query.value = null;
    searchedProviders.value = [];
    selectedProvider.value = null;
  }
});

watch(query, (newVal) => {
  if (newVal) {
    searchProviders();
  }
});

const rail = computed(() => {
  return store.state.miniNav && !vuetify.display.mobile.value;
});

function openSearch() {
  searchDialogIsVisible.value = true;

  setTimeout(() => {
    providerSearch.value?.focus();
  }, 200);
}

function searchProviders() {
  Api.organization.provider.index({ query: query.value, page_size: 200 }, (resp) => {
    searchedProviders.value = resp.data;
  });
}
</script>
