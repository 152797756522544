<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-card
    class="mb-4"
    border
    flat
    tile
  >
    <v-card-title class="tab-title">
      <v-row>
        <v-col class="d-flex align-center">
          <span>{{ $t('Photos') }}</span>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            @click="$refs.newDialog.open()"
            id="manager_add_photo"
            color="primary"
            tracked
          >
            <v-icon
              class="fs-18"
              start
            >
              add
            </v-icon>
            <span>{{ $t('Upload') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider class="mb-4" />

    <v-card-text>
      <ProviderImages
        @destroy="destroy"
        ref="images"
        :provider-id="providerId"
        role="manager"
      />
    </v-card-text>

    <ResourceDialog
      ref="newDialog"
      save-button-text="Done"
      title="Upload"
      close-on-save
    >
      <template #form>
        <dashboard
          :props="uppyProps"
          :uppy="uppy"
        />
      </template>
    </ResourceDialog>
  </v-card>
</template>

<script>
import API from '@/shared/mixins/api';
import ProviderImages from '@/shared/components/provider/ProviderImages.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import Uppy from '@uppy/core';
import UppyUtils from '@/shared/mixins/uppy_utils';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    Dashboard,
    ProviderImages,
    ResourceDialog,
  },

  mixins: [API, UppyUtils],

  props: {
    providerId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      uppy: null,
      uppyProps: {
        proudlyDisplayPoweredByUppy: false,
        inline: true,
        height: 200,
        locale: this.getUppyLocale(),
        width: '100%',
      },
    };
  },

  beforeUnmount() {
    this.uppy.close();
  },

  created() {
    this.uppy = new Uppy().use(XHRUpload, {
      endpoint: this.getUploadAPIEndpoint(),
    });

    this.uppy.on('upload-success', (file, evt) => {
      const asset = {
        file: {
          id: evt.body.id, // remove the Shrine storage prefix
          storage: 'cache',
          metadata: {
            size: file.size,
            filename: file.name,
            mime_type: file.type,
          },
        },
        category: this.category,
        provider_id: this.providerId,
      };

      this.api.organization.asset.create(asset, () => {
        this.$refs.newDialog.close();
        this.$refs.images.load();
      });
    });
  },

  methods: {
    destroy(asset) {
      this.api.organization.asset.destroy(asset.id, () => {
        this.$refs.images.load();
      });
    },
  },
};
</script>
