<template>
  <v-card
    border
    tile
  >
    <v-card-title>
      {{ $t('Danger') }}
    </v-card-title>

    <v-divider />

    <v-card-text>
      <v-row dense>
        <v-col
          class="mb-3"
          cols="12"
        >
          <div class="fs-18 fw-600 c-black">
            {{ $t('Be careful! This cannot be undone.') }}
          </div>
        </v-col>

        <v-col cols="12">
          <v-btn
            @click="destroy()"
            color="red"
          >
            {{ $t('Delete site') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <ConfirmDialog ref="confirmDialog" />
  </v-card>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';

const eventBus = useEventBus();
const router = useRouter();

const props = defineProps({
  site: {
    type: Object,
    required: true,
  },
});

const confirmDialog = ref(null);
const processing = ref(false);

async function destroy() {
  const confirm = await confirmDialog.value.confirmWithText(
    'Are you certain you want to delete this site? This cannot be undone.',
  );
  if (!confirm) return;

  processing.value = true;
  Api.organization.site.destroy(
    props.site.id,
    () => router.push({ name: 'SiteIndex' }),
    (error) => eventBus.chime(error),
  );
}
</script>
