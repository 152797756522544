<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div
          @click="$router.push({ name: 'SiteTemplateIndex' })"
          class="fw-600 bc-h5 d-inline-block me-2 bc-link-hover"
        >
          Templates
        </div>
        <div class="fw-600 bc-h5 d-inline-block me-2">
          <v-icon class="c-black"> chevron_left </v-icon>
        </div>
        <div class="fw-600 bc-h5 d-inline-block me-2">New Template</div>
      </v-col>
    </v-row>
    <SiteTemplateForm :site_template="blank_template" />
  </v-container>
</template>

<script>
import SiteTemplateForm from '@/admin/components/site-templates/SiteTemplateForm.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    SiteTemplateForm,
  },

  data() {
    return {
      blank_template: { description: null, site_type: null, name: null },
    };
  },
};
</script>
