<template>
  <div v-if="fundingSources && grantSchema">
    <div class="mb-4 ta-right">
      <v-btn
        @click="$refs.newDialog.open()"
        class="mt-4"
        color="primary"
        prepend-icon="add"
      >
        {{ $t('Add funding source') }}
      </v-btn>
    </div>
    <div
      v-for="source in fundingSources"
      :key="source.id"
    >
      <FundingSourceCard
        @destroy="loadFundingSources"
        :schemas="[grantSchema]"
        :source="source"
        class="mb-6"
      />
    </div>

    <NullState v-if="fundingSources.length === 0" />

    <ResourceDialog
      @save="create"
      ref="newDialog"
      :fields="fundingSourceFields"
      :processing="processing"
      title="New funding source"
    />
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import FundingSourceCard from '@/admin/components/FundingSourceCard.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();
const fundingSources = ref([]);
const grantSchema = ref(null);
const processing = ref(false);
const newDialog = ref(null);
const funds = ref([]);

const fundingSourceFields = computed(() => [
  { required: true, text: 'Name', value: 'name' },
  {
    required: true,
    text: 'Fund',
    value: 'fund_id',
    items: funds.value,
    itemText: 'name',
    itemValue: 'id',
  },
]);

async function create(newFundingSource) {
  processing.value = true;
  const params = {
    name: newFundingSource.name,
    fund_id: newFundingSource.fund_id,
    owner_id: route.params.grantProgramId,
    owner_type: 'GrantProgram',
  };
  const response = await Api.organization.funding_source.create(params);

  if (response?.data) {
    fundingSources.value.push(response.data);
    newDialog.value.close();
  }

  processing.value = false;
}

function loadFunds() {
  Api.organization.fund.index({}, (response) => {
    funds.value = response.data;
  });
}

async function loadFundingSources() {
  const { data } = await Api.organization.funding_source.index({
    owner_id: route.params.grantProgramId,
  });
  fundingSources.value = data;
}

function loadGrantProgram() {
  Api.organization.grant_program.get(route.params.grantProgramId, (resp) => {
    grantSchema.value = store.state.schemas[resp.data.grant_schema_id];
  });
}

onMounted(() => {
  void loadFundingSources();
  loadFunds();
  loadGrantProgram();
});
</script>
