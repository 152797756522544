<template>
  <v-card
    class="mb-4"
    border
    flat
    tile
  >
    <v-card-title class="tab-title">
      <v-row>
        <v-col
          class="d-flex align-center"
          cols="4"
        >
          <span v-t="title" />
        </v-col>
        <v-col>
          <div class="d-flex justify-end">
            <v-select
              @update:model-value="updateQuery({ subsidyProgramId: $event })"
              :items="subsidyPrograms"
              :model-value="$route.query.subsidyProgramId"
              class="me-3 mxw-350"
              density="compact"
              item-title="name"
              item-value="id"
              variant="filled"
              hide-details
            />
            <v-text-field
              v-model="query"
              :placeholder="$t('Search students')"
              class="mxw-250"
              density="compact"
              prepend-inner-icon="search"
              variant="solo-filled"
              flat
              hide-details
              rounded
            />
          </div>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider />

    <v-card-text v-if="enrollments">
      <v-row class="d-flex align-center mb-2">
        <v-col
          class="d-flex align-center"
          cols="12"
          md="6"
        >
          <template v-if="pages > 1">
            <v-pagination
              v-model="page"
              :length="pages"
              :total-visible="8"
              class="d-inline-block"
            />
          </template>
        </v-col>

        <v-col
          class="d-flex justify-end px-6"
          cols="12"
          md="6"
        >
          <template v-if="filterPrograms.length > 0">
            <FilterMenu
              ref="programFilter"
              :actions="false"
              :active="!!programFilter"
              :title="programFilter || terms.program"
              classes="ms-3"
              color="white"
              elevation="0"
              min-width="400"
              left
              outlined
            >
              <template #card>
                <v-select
                  @update:model-value="updateQuery({ programId: $event })"
                  :items="filterPrograms"
                  :model-value="$route.query.programId"
                  :placeholder="$t('Search by name or address')"
                  item-title="name"
                  item-value="id"
                  variant="filled"
                  auto-select-first
                  clearable
                  left
                />
              </template>
            </FilterMenu>
          </template>
        </v-col>
      </v-row>

      <v-card
        border
        flat
        tile
      >
        <template v-if="$vuetify.display.smAndUp">
          <v-card
            class="bb-1 pa-0"
            flat
            tile
          >
            <v-card-text>
              <v-row>
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="sortDirection"
                  :sort-field="sortField"
                  field="name"
                  md="3"
                  title="Child's name"
                />
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="sortDirection"
                  :sort-field="sortField"
                  field="dob"
                  md="2"
                  title="DOB"
                />
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="sortDirection"
                  :sort-field="sortField"
                  field="address"
                  md="2"
                  title="Home address"
                />
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="sortDirection"
                  :sort-field="sortField"
                  field="provider"
                  md="3"
                  title="Location"
                />
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="sortDirection"
                  :sort-field="sortField"
                  :title="terms.program"
                  field="program"
                  md="2"
                />
              </v-row>
            </v-card-text>
          </v-card>

          <v-divider />
        </template>

        <v-card
          flat
          tile
        >
          <v-card-text class="fs-15">
            <v-row dense>
              <v-col class="d-flex align-center">
                <template v-if="filterSummary">
                  <v-icon start> filter_alt </v-icon>
                  <span
                    v-t="'Filtering by:'"
                    class="me-1"
                  />
                  <span class="fw-600">{{ $t(filterSummary) }}</span>
                  <span>.&nbsp;</span>
                </template>
                <span>
                  {{ $t('Displaying first') }} <strong>{{ enrollments.length }}</strong>
                  {{ $t('results') }}, {{ $t('out of ') }} <strong>{{ total }}</strong>
                  {{ $t('total results') }}.
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider />

        <NullState
          v-if="enrollments.length == 0"
          hide-new-button
        />

        <div
          v-for="(enrollment, index) in enrollments"
          :key="enrollment.id"
        >
          <EnrollmentRow
            @check="enrollment.checked = !enrollment.checked"
            :enrollment="enrollment"
            hide-selector
          />
          <v-divider v-if="index < enrollments.length - 1" />
        </div>

        <template v-if="pages > 1">
          <v-divider />
          <v-pagination
            v-model="page"
            :length="pages"
            :total-visible="8"
            class="my-2"
          />
        </template>
      </v-card>
    </v-card-text>

    <template v-else>
      <v-progress-linear
        class="mt-6"
        indeterminate
      />
    </template>
  </v-card>
</template>

<script>
import { scrollTo } from 'vuetify/lib/composables/goto';
import API from '@/shared/mixins/api';
import EnrollmentRow from '@/specialist/components/enrollments/EnrollmentRow.vue';
import RouterHelper from '@/shared/mixins/RouterHelper';
import SortableTableHeader from '@/shared/components/SortableTableHeader.vue';
import Terms from '@/shared/mixins/terms';
import { ENROLLMENT_STATUSES } from '@/shared/assets/constants';

export default {
  compatConfig: { MODE: 2 },

  components: {
    EnrollmentRow,
    SortableTableHeader,
  },

  mixins: [API, RouterHelper, Terms],

  props: {
    title: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      enrollments: null,
      filterPrograms: [],
      filters: {
        provider_id: this.$route.params.providerId,
      },
      page: 1,
      pages: null,
      processing: false,
      query: null,
      queryCount: 0,
      sortDirection: null,
      sortField: null,
      subsidyPrograms: [],
      total: 0,
    };
  },

  computed: {
    filterSummary() {
      const appliedFilters = [];
      if (this.programFilter) appliedFilters.push(this.programFilter);
      if (this.subsidyProgramFilter) appliedFilters.push(this.subsidyProgramFilter);
      return appliedFilters.length > 0 ? appliedFilters.join(', ') : null;
    },

    programFilter() {
      return this.filterPrograms.find((program) => program.id === this.$route.query.programId)
        ?.name;
    },

    subsidyProgramFilter() {
      return this.subsidyPrograms.find(
        (subsidyProgram) => subsidyProgram.id === this.$route.query.subsidyProgramId,
      )?.name;
    },
  },

  watch: {
    page() {
      this.loadEnrollments();
      scrollTo(0);
    },

    query(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.page = 1;
        this.loadEnrollments();
      }
    },

    '$route.query.programId': {
      immediate: true,
      handler() {
        this.page = 1;
        this.loadEnrollments();
      },
    },

    '$route.query.subsidyProgramId': {
      immediate: true,
      handler() {
        this.page = 1;
        this.loadEnrollments();
      },
    },
  },

  created() {
    this.load();
    this.loadPrograms();
  },

  methods: {
    display(enrollment) {
      this.$refs.enrollDialog.open(enrollment);
    },

    loadEnrollments() {
      const params = {};
      params.status = ENROLLMENT_STATUSES.ENROLLED;
      params.page = this.page;
      params.query = this.query;
      params.provider_id = this.$route.params.providerId;
      params.program_id = this.$route.query.programId;
      params.subsidy_program_id = this.$route.query.subsidyProgramId;
      this.queryCount += 1;
      const currentQueryCount = this.queryCount;

      if (this.sortField) {
        params.sort_field = this.sortField;
        params.sort_dir = this.sortDirection;
        this.$router.push({ query: params });
      }

      this.api.organization.enrollment.index(
        params,
        (resp) => {
          if (this.queryCount !== currentQueryCount) return;

          this.enrollments = resp.data.map((enrollment) => {
            enrollment.checked = false;
            return enrollment;
          });

          this.pages = parseInt(resp.headers['x-page-count'] || 0, 10);
          this.total = parseInt(resp.headers['x-total-count'] || 0, 10);
          this.processing = false;
        },
        (err) => {
          this.$eventBus.$emit('error', err);
        },
      );
    },

    loadPrograms() {
      this.api.public_api.organization.program.index(
        { provider_id: this.$route.params.providerId },
        (resp) => {
          this.filterPrograms = resp.data;
        },
      );
    },

    async load() {
      const resp = await this.api.organization.subsidy_program.index();
      this.subsidyPrograms = resp.data;
      if (this.$route.query.subsidyProgramId) {
        this.loadEnrollments();
      } else {
        await this.updateQuery({ subsidyProgramId: this.subsidyPrograms[0]?.id });
      }
    },

    toggleSort(field) {
      if (this.sortField !== field) {
        this.sortDirection = 'desc';
      } else {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.sortField = field;
      this.loadEnrollments();
    },
  },
};
</script>
