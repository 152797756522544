<template>
  <v-container
    v-if="announcement"
    fluid
  >
    <v-stepper
      v-model="step"
      class="mb-4 bg-transparent"
      flat
      tile
    >
      <v-row>
        <v-col
          class="px-12"
          cols="12"
        >
          <v-stepper-header
            class="elevation-0"
            flat
          >
            <v-stepper-item
              :complete="step > 1"
              :value="1"
            >
              {{ $t('Design') }}
            </v-stepper-item>

            <v-divider />

            <v-stepper-item
              :complete="step > 2"
              :value="2"
            >
              {{ $t('Preview') }}
            </v-stepper-item>

            <v-divider />

            <v-stepper-item
              :complete="step > 3"
              :value="3"
            >
              {{ $t('Send') }}
            </v-stepper-item>

            <v-divider />

            <v-stepper-item
              :complete="step > 4"
              :value="4"
            >
              {{ $t('Results') }}
            </v-stepper-item>
          </v-stepper-header>
        </v-col>
      </v-row>

      <v-stepper-window v-model="step">
        <v-stepper-window-item
          :value="1"
          class="py-0"
        >
          <v-row dense>
            <v-col>
              <v-card
                class="mb-4"
                border
                tile
              >
                <v-card-title>
                  {{ $t('Overview') }}
                </v-card-title>

                <v-divider />

                <v-card-text>
                  <v-row>
                    <LabeledTextfield
                      v-model="announcement.name"
                      @input="handleChange"
                      cols="12"
                      message="Name"
                    />

                    <LabeledAutocomplete
                      v-model="announcement.report_id"
                      @change="handleChange"
                      @update:search="searchReports"
                      :items="reportOptions"
                      :multiple="false"
                      cols="12"
                      item-title="name"
                      item-value="id"
                      message="Recipient list"
                      placeholder="Type a report name..."
                      filled
                    />
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card
                class="mb-4"
                border
                tile
              >
                <v-card-title>
                  {{ $t('Email') }}
                </v-card-title>

                <v-divider />

                <v-card-text>
                  <v-row dense>
                    <LabeledSimpleSelect
                      v-model="emailColumn"
                      @change="handleChange"
                      :items="emailColumns"
                      cols="12"
                      item-title="alias"
                      item-value="key"
                      message="Email column"
                      clearable
                    />
                  </v-row>

                  <template v-if="emailColumn">
                    <v-row>
                      <LabeledTextfield
                        v-model="announcement.reply_to_email"
                        @input="handleChange"
                        cols="6"
                        message="Reply-to"
                      />

                      <LabeledTextfield
                        v-model="announcement.rendered_reply_to_email"
                        @input="handleChange"
                        cols="6"
                        message="Reply-to preview"
                      />

                      <LabeledTextfield
                        v-model="announcement.subject"
                        @input="handleChange"
                        cols="6"
                        message="Subject"
                      />

                      <LabeledTextfield
                        v-model="announcement.rendered_subject"
                        cols="6"
                        message="Subject preview"
                        readonly
                      />
                    </v-row>

                    <v-row
                      class="mt-6"
                      dense
                    >
                      <v-col cols="12">
                        <v-card
                          class="h-500"
                          tile
                        >
                          <v-row
                            class="h-100pc"
                            dense
                          >
                            <v-col cols="6">
                              <div
                                id="editor"
                                class="w-100pc br-0"
                              />
                            </v-col>

                            <v-col
                              class="h-100pc oy-scroll"
                              cols="6"
                            >
                              <MarkdownContent
                                v-if="announcement.rendered_body"
                                :content="announcement.rendered_body"
                                class="fs-16 pa-4"
                              />
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-card-text>
              </v-card>

              <v-card
                border
                tile
              >
                <v-card-title>
                  {{ $t('Text message') }}
                </v-card-title>

                <v-divider />

                <v-card-text>
                  <v-row dense>
                    <LabeledSimpleSelect
                      v-model="phoneColumn"
                      @change="handleChange"
                      :hard-lock="isTextEnabled"
                      :items="phoneColumns"
                      cols="12"
                      item-title="alias"
                      item-value="key"
                      message="Phone column"
                      clearable
                    />

                    <LabeledTextfield
                      v-model="announcement.text"
                      @input="handleChange"
                      :hard-lock="!isTextEnabled"
                      cols="6"
                      message="Text content"
                    />

                    <LabeledTextfield
                      v-model="announcement.rendered_text"
                      :hard-lock="!isTextEnabled"
                      cols="6"
                      message="Text preview"
                      readonly
                    />
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                @click="save()"
                :loading="processing"
                class="me-3"
                color="primary"
                size="x-large"
              >
                {{ $t('Save') }}
              </v-btn>

              <v-btn
                @click="destroy()"
                :loading="processing"
                color="red"
                size="x-large"
                variant="text"
              >
                {{ $t('Delete') }}
              </v-btn>
            </v-col>

            <v-col class="ta-right">
              <v-btn
                @click="stepTo(1)"
                :disabled="step == 3"
                :loading="processing"
                append-icon="east"
                color="primary"
                size="x-large"
              >
                {{ $t('Preview and test') }}
              </v-btn>
            </v-col>
          </v-row>

          <VerticalSpacer :min-height="50" />
        </v-stepper-window-item>

        <v-stepper-window-item
          :value="2"
          class="py-0"
        >
          <v-row>
            <v-col>
              <v-card
                v-if="emailColumn"
                class="mb-4"
                border
                tile
              >
                <v-card-title>
                  {{ $t('Test your email content') }}
                </v-card-title>

                <v-divider />

                <v-card-text>
                  <div class="fs-16 c-light-black mb-3">
                    {{ $t(TEST_EMAIL_INSTRUCTIONS) }}
                  </div>
                  <v-row class="mb-3">
                    <LabeledTextfield
                      v-model="announcement.test_email"
                      message="Send a test email to:"
                    />
                  </v-row>

                  <v-btn
                    @click="sendTestEmail"
                    :loading="processing"
                    color="primary"
                  >
                    {{ $t('Send email') }}
                  </v-btn>
                </v-card-text>
              </v-card>

              <v-card
                v-if="phoneColumn"
                border
                tile
              >
                <v-card-title>
                  {{ $t('Test your text message content') }}
                </v-card-title>

                <v-divider />

                <v-card-text>
                  <div class="fs-16 c-light-black mb-3">
                    {{ $t(TEST_TEXT_INSTRUCTIONS) }}
                  </div>

                  <v-row class="mb-3">
                    <LabeledTextfield
                      v-model="announcement.test_phone"
                      mask="(###) ###-####"
                      message="Send text message to:"
                    />
                  </v-row>

                  <v-btn
                    @click="sendTestText"
                    :loading="processing"
                    color="primary"
                  >
                    {{ $t('Send text message') }}
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                @click="stepTo(-1)"
                :disabled="step == 1"
                :loading="processing"
                class="me-3"
                color="primary"
                size="x-large"
                variant="outlined"
              >
                {{ $t('Back') }}
              </v-btn>
            </v-col>

            <v-col class="ta-right">
              <v-btn
                @click="stepTo(1)"
                :disabled="step == 3"
                :loading="processing"
                append-icon="east"
                color="primary"
                size="x-large"
              >
                {{ $t('Review recipients') }}
              </v-btn>
            </v-col>
          </v-row>

          <VerticalSpacer :min-height="50" />
        </v-stepper-window-item>

        <v-stepper-window-item
          v-if="associatedReport"
          :value="3"
          class="py-0"
        >
          <v-row>
            <v-col>
              <v-card
                class="mb-4"
                tile
              >
                <v-card-title>
                  <v-row class="d-flex align-center">
                    <v-col>
                      {{ $t('Recipients preview') }}
                    </v-col>

                    <v-col class="d-flex justify-end">
                      <span class="fs-12 c-light-black me-2 fw-500">
                        Previewing first {{ associatedReport.preview.length }}
                        <span
                          v-if="
                            !associatedReport.columns.some(
                              (column) => column.count_min || column.count_max,
                            )
                          "
                        >
                          of {{ associatedReport.result_count }}
                        </span>
                        recipients
                      </span>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-divider />

                <v-card-text class="ox-scroll">
                  <table>
                    <thead>
                      <tr>
                        <th
                          v-for="(column, index) in associatedReport.columns"
                          :key="index"
                          :class="
                            column.key == announcement.email_column ||
                            column.key == announcement.phone_column
                              ? 'bg-super-light-grey'
                              : ''
                          "
                          class="fs-14 fw-600 bb-1 bc-extra-light-grey px-1 ta-left br-1"
                        >
                          {{ column.alias }}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(row, rowIndex) in associatedReport.preview"
                        :key="rowIndex"
                      >
                        <td
                          v-for="(column, colIndex) in associatedReport.columns"
                          :key="colIndex"
                          :class="
                            column.key == announcement.email_column ||
                            column.key == announcement.phone_column
                              ? 'bg-super-light-grey'
                              : ''
                          "
                          class="bb-1 br-1 bc-extra-light-grey mnw-200 pa-2 fs-14"
                        >
                          {{ row[column.key] }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-card-text>
              </v-card>

              <v-row>
                <v-col>
                  <v-btn
                    @click="stepTo(-1)"
                    :disabled="step == 1"
                    :loading="processing"
                    class="me-3"
                    color="primary"
                    size="x-large"
                    variant="outlined"
                  >
                    {{ $t('Back') }}
                  </v-btn>
                </v-col>

                <v-col class="ta-right">
                  <v-btn
                    @click="send"
                    :disabled="!announcement.email_column"
                    :loading="processing"
                    class="me-3"
                    color="primary"
                    size="x-large"
                    tile
                  >
                    {{ $t('Send now') }}
                  </v-btn>

                  <v-btn
                    v-if="step == 3"
                    @click="stepTo(1)"
                    :disabled="step == 4"
                    :loading="processing"
                    color="primary"
                    data-cy="next-announce"
                    size="x-large"
                    tile
                  >
                    {{ $t('Next') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <VerticalSpacer :min-height="50" />
        </v-stepper-window-item>

        <v-stepper-window-item
          :value="4"
          data-testid="sent-emails-table"
        >
          <v-row class="mb-1">
            <v-col class="d-flex align-center">
              <v-text-field
                v-model="correspondenceQuery"
                :aria-label="$t('Search sent messages by recipient email address or phone')"
                class="mxw-500"
                density="compact"
                placeholder="Search sent messages by recipient email address or phone"
                prepend-inner-icon="search"
                variant="solo"
                hide-details
                rounded
              />

              <v-btn
                @click="loadCorrespondence"
                :aria-label="$t('Refresh results')"
                :loading="processing"
                icon="refresh"
                variant="flat"
              />
            </v-col>
          </v-row>

          <v-card
            class="bb-1 pa-0"
            tile
          >
            <v-card-text>
              <v-row class="fs-14">
                <SortableTableHeader
                  field="name"
                  md="3"
                  title="Name"
                  dense
                />

                <SortableTableHeader
                  field="to"
                  md="4"
                  title="To"
                  dense
                />

                <SortableTableHeader
                  field="status"
                  md="2"
                  title="Status"
                  dense
                />

                <SortableTableHeader
                  field="Sent"
                  md="3"
                  title="Sent"
                  dense
                />
              </v-row>
            </v-card-text>
          </v-card>

          <v-divider />

          <NullState
            v-if="sentCorrespondence.length == 0"
            data-testid="null-state-sent-emails"
            title="Announcement is in the queue, no emails sent yet!"
            hide-new-button
          />

          <v-card
            v-for="correspondence in sentCorrespondence"
            @click="showBody(correspondence)"
            :key="correspondence.id"
            class="fs-14 fw-500 px-4 py-3"
            data-testid="sentMessage"
            tile
          >
            <v-row class="d-flex align-center">
              <v-col cols="3">
                <span v-text="correspondence.name" />
              </v-col>

              <v-col cols="4">
                <span v-text="correspondence.to" />
              </v-col>

              <v-col cols="2">
                <span
                  v-text="correspondence.status"
                  data-testid="sentMessage.emailStatus"
                />
              </v-col>

              <v-col cols="3">
                <LongDateTime :date="correspondence.created_at" />
              </v-col>
            </v-row>
          </v-card>

          <v-pagination
            v-model="page"
            :length="pages"
            :total-visible="3"
            class="pt-2"
          />

          <v-divider class="my-4" />

          <v-row class="mt-8">
            <v-col>
              <v-btn
                @click="stepTo(-1)"
                :disabled="step == 1"
                :loading="processing"
                class="me-3"
                color="primary"
                size="x-large"
                variant="outlined"
              >
                {{ $t('Back') }}
              </v-btn>
            </v-col>

            <v-col class="text-right">
              <v-btn
                @click="cancelAnnouncement()"
                :disabled="isAnnouncementCancelled()"
                :loading="processing"
                class="me-3"
                color="primary"
                size="x-large"
                variant="outlined"
              >
                {{ $t(getCancelButtonText()) }}
              </v-btn>
            </v-col>
          </v-row>

          <VerticalSpacer :min-height="50" />
        </v-stepper-window-item>
      </v-stepper-window>
    </v-stepper>

    <v-dialog
      v-model="bodyDialogIsVisible"
      data-testid="sentEmailDialog"
      max-width="600"
    >
      <v-card v-if="selectedEmail">
        <v-card-title>
          {{ selectedEmail.subject }}
        </v-card-title>

        <v-card-text>
          <MarkdownContent :content="selectedEmail.body" />

          <div v-text="selectedEmail.text" />
        </v-card-text>

        <v-card-actions>
          <v-btn
            @click="bodyDialogIsVisible = false"
            color="primary"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SaveBar
      @cancel="cancel"
      @save="save"
      :model-value="changed"
      :processing="processing"
    />
  </v-container>
</template>

<script setup>
import '@toast-ui/editor/dist/toastui-editor.css';
import { scrollTo } from 'vuetify/lib/composables/goto';

import api from '@/specialist/services/bright_finder';
import Editor from '@toast-ui/editor';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import NullState from '@/shared/components/NullState.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import SortableTableHeader from '@/shared/components/SortableTableHeader.vue';
import { waitForElement } from '@/shared/services/utils';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';

const TEST_EMAIL_INSTRUCTIONS =
  'Test your email before sending to recipients. If you have designed the ' +
  'template to be pre-populated with content, we’ll prepopulate the email with this content.';

const TEST_TEXT_INSTRUCTIONS =
  'Test your text message content before sending to recipients. If you have designed the ' +
  'template to be pre-populated with content, we’ll prepopulate the text body with this content.';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();

const announcement = reactive({});
const associatedReport = ref(null);
const bodyDialogIsVisible = ref(false);
const changed = ref(false);
const emailColumn = ref(null);
const emailColumns = ref([]);
const page = ref(1);
const pages = ref(1);
const smsPages = ref(1);
const emailPages = ref(1);
const correspondenceQuery = ref(null);
const correspondenceQueryCount = ref(0);
const phoneColumn = ref(null);
const phoneColumns = ref([]);
const processing = ref(false);
const reports = ref([]);
const selectedEmail = ref(null);
const sentCorrespondence = ref([]);
const sentEmails = ref([]);
const sentSms = ref([]);
const smsQueryCount = ref(0);
const step = ref(1);
const basePageSize = 5;

let editor = null;

watch(page, loadCorrespondence);

watch(correspondenceQuery, async () => {
  page.value = 1;
  await loadCorrespondence();
});

watch(
  () => announcement.report_id,
  (newVal) => {
    if (newVal) {
      associatedReport.value = reports.value.find((report) => report.id === newVal);
    } else {
      associatedReport.value = null;
    }
  },
  { immediate: true },
);

const isTextEnabled = computed(() => {
  return !!phoneColumn.value && !!associatedReport.value?.is_textable;
});

const reportOptions = computed(() => {
  if (!associatedReport.value) return reports.value;
  if (reports.value.find((report) => report.id === associatedReport.value.id)) return reports.value;
  return [associatedReport.value, ...reports.value];
});

async function cancel() {
  await loadAnnouncement();
  changed.value = false;
}

async function cancelAnnouncement() {
  const updatedAnnouncement = { ...announcement, cancelled: true };
  await api.organization.announcement.update(announcement.id, updatedAnnouncement);
  await loadAnnouncement();
  changed.value = false;
}

async function destroy() {
  // eslint-disable-next-line no-alert
  if (!confirm('Are you sure you want to delete this announcement?')) return;

  await api.organization.announcement.destroy(route.params.announcementId);

  router.replace({ name: 'AnnouncementIndex' });
}

function getCancelButtonText() {
  return isAnnouncementCancelled() ? 'Cancelled' : 'Cancel';
}

function handleChange() {
  changed.value = true;
}

function isAnnouncementCancelled() {
  return announcement.cancelled === true;
}

async function load() {
  await loadAnnouncement();
  await loadReport();
  await loadCorrespondence();
}

async function loadReport() {
  if (!announcement.report_id) return;

  const response = await api.organization.report.get(announcement.report_id);
  associatedReport.value = response?.data;
  loadEligibleEmailColumns();
  loadEligiblePhoneColumns();
}

function loadEligibleEmailColumns() {
  for (const column of associatedReport.value.columns) {
    if (column.format === 'email') emailColumns.value.push(column);
  }
}

function loadEligiblePhoneColumns() {
  for (const column of associatedReport.value.columns) {
    if (column.format === 'phone') phoneColumns.value.push(column);
  }
}

async function loadAnnouncement() {
  const response = await api.organization.announcement.get(route.params.announcementId);
  if (response?.data.send_at) {
    step.value = 4;
    await loadCorrespondence();
  }

  Object.assign(announcement, response?.data);
  emailColumn.value = announcement.email_column;
  phoneColumn.value = announcement.phone_column;
  openEditor();
}

async function loadCorrespondence() {
  await loadSentEmails();
  await loadSentSms();

  for (const index in sentEmails.value) {
    sentEmails.value[index].status = sentEmails.value[index].email_status;
    if (null === sentEmails.value[index].first_name) sentEmails.value[index].first_name = '';
    if (null === sentEmails.value[index].last_name) sentEmails.value[index].last_name = '';

    sentEmails.value[index].name =
      sentEmails.value[index].first_name + ' ' + sentEmails.value[index].last_name;
  }
  for (const index in sentSms.value) {
    sentSms.value[index].status = sentSms.value[index].sms_status;
    if (null === sentSms.value[index].first_name) sentSms.value[index].first_name = '';
    if (null === sentSms.value[index].last_name) sentSms.value[index].last_name = '';

    sentSms.value[index].name =
      sentSms.value[index].first_name + ' ' + sentSms.value[index].last_name;
  }

  sentCorrespondence.value = sentEmails.value.concat(sentSms.value).sort((a, b) => {
    if (a.member_id < b.member_id) return -1;
    if (a.member_id > b.member_id) return 1;

    return 0;
  });
}

async function loadSentEmails() {
  processing.value = true;
  correspondenceQueryCount.value += 1;
  const currentEmailQueryCount = correspondenceQueryCount.value;
  const response = await api.organization.sent_email.index({
    page: page.value,
    page_size: getPageSize(),
    query: correspondenceQuery.value,
    announcement_id: route.params.announcementId,
  });
  if (correspondenceQueryCount.value === currentEmailQueryCount) {
    setPages(response, 'email');
    processing.value = false;
    sentEmails.value.splice(0);
    sentEmails.value.push(...response.data);
  }
}

async function loadSentSms() {
  processing.value = true;
  smsQueryCount.value += 1;
  const currentSmsQueryCount = smsQueryCount.value;
  const response = await api.organization.sent_sms.index({
    page: page.value,
    page_size: getPageSize(),
    query: correspondenceQuery.value,
    announcement_id: route.params.announcementId,
  });
  if (smsQueryCount.value === currentSmsQueryCount) {
    setPages(response, 'sms');
    processing.value = false;
    sentSms.value.splice(0);
    sentSms.value.push(...response.data);
  }
}

function isSingleCorrespondenceType() {
  return smsPages.value === 0 || emailPages.value == 0;
}

function getPageSize() {
  if (isSingleCorrespondenceType()) return basePageSize * 2;
  return basePageSize;
}

function setPages(response, type) {
  if (smsPages.value >= emailPages.value) {
    pages.value = smsPages.value;
  } else {
    pages.value = emailPages.value;
  }

  const pageCount = parseInt(response.headers['x-page-count'] || 0, 10);
  if (type === 'sms') {
    smsPages.value = pageCount;
  } else if (type === 'email') {
    emailPages.value = pageCount;
  }
}

function openEditor() {
  waitForElement('#editor').then(() => {
    editor = new Editor({
      el: document.querySelector('#editor'),
      events: {
        change() {
          changed.value = true;
        },
      },
      height: '500px',
      initialEditType: 'wysiwyg',
      initialValue: announcement.body || '',
      previewStyle: 'vertical',
      toolbarItems: [
        ['heading', 'bold', 'italic'],
        ['hr', 'quote'],
        ['ul', 'ol'],
        ['image', 'link'],
      ],
    });
  });
}

async function save() {
  processing.value = true;
  if (editor) announcement.body = editor.getMarkdown().replaceAll('\\_', '_');

  announcement.email_column = emailColumn.value;
  announcement.phone_column = phoneColumn.value;
  const response = await api.organization.announcement.update(announcement.id, announcement);
  processing.value = false;
  if (response.status != 200) return false;

  Object.assign(announcement, response.data);

  eventBus.chime('Draft saved');
  changed.value = false;
  return true;
}

async function searchReports(reportQuery) {
  const { data } = await api.organization.report.index({ query: reportQuery });
  reports.value = data;
}

async function send() {
  announcement.test_email_requested = false;
  announcement.test_text_requested = false;
  announcement.send_requested = true;
  if (!(await save())) return;

  eventBus.chime('Announcement queued for sending');
  step.value = 4;
}

async function sendTestEmail() {
  announcement.test_email_requested = true;
  announcement.test_text_requested = false;

  if (await save()) eventBus.chime('Test email sent');
}

async function sendTestText() {
  announcement.test_text_requested = true;
  announcement.test_email_requested = false;

  if (await save()) eventBus.chime('Test text sent');
}

function showBody(email) {
  selectedEmail.value = email;
  bodyDialogIsVisible.value = true;
}

function stepTo(steps) {
  step.value += steps;
  setTimeout(() => {
    scrollTo(0);
  }, 250);
}

onMounted(load);
</script>

<style>
.toastui-editor-defaultUI {
  border-radius: 0px !important;
}
</style>
