<template>
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <v-row dense>
      <v-col
        class="labeled-input"
        cols="12"
      >
        {{ $t(label) }}
      </v-col>
      <v-col>
        <v-select
          @blur="handleBlur"
          @update:model-value="handleUpdateModelValue"
          :append-inner-icon="appendIcon"
          :chips="chips"
          :closable-chips="deletableChips"
          :density="dense || veryDense ? 'compact' : undefined"
          :disabled="locked"
          :item-props="(item) => ({ prependIcon: item.icon, title: $t(item.text) })"
          :items="items"
          :model-value="localValue"
          :prepend-inner-icon="selectedIcon"
          data-cy="labeled-select"
          variant="filled"
          hide-details
          tile
          v-bind="attrsOmitDataPrefix"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import FilteredAttrs from '@/shared/mixins/FilteredAttrs';
import Labeled from '@/shared/mixins/labeled';
import Schematized from '@/shared/mixins/schematized';

export default {
  compatConfig: { MODE: 3 },

  mixins: [Labeled, Schematized, FilteredAttrs],

  props: {
    chips: Boolean,
    deletableChips: Boolean,
    items: Array,
  },
  computed: {
    selectedIcon() {
      if (this.localValue) {
        return this.items.find((item) => item.value === this.localValue)?.icon;
      }
      return null;
    },
  },
};
</script>
