<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle title="Businesses" />

    <v-toolbar class="mb-3 px-4 bb-1">
      <v-row>
        <v-col class="mxw-340">
          <v-text-field
            v-model="query"
            id="search_link"
            :aria-label="$t('Filter results by name')"
            :placeholder="$t('Filter results by name')"
            color="secondary"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
            tracked
          />
        </v-col>

        <v-col class="ta-right">
          <v-btn
            v-if="$store.state.profile.org_business_edit"
            @click="$refs.newBusiness.open()"
            color="primary"
            prepend-icon="add"
            variant="flat"
          >
            <span v-t="'New business'" />
          </v-btn>

          <ResourceDialog
            @save="createBusiness"
            ref="newBusiness"
            :fields="newBusinessValues"
            :processing="processing"
            title="New business"
          />
        </v-col>
      </v-row>
    </v-toolbar>

    <v-container
      class="px-3 py-0"
      fluid
    >
      <v-card
        v-if="businesses.length > 0"
        class="bb-1 bc-extra-light-gray pa-0"
        tile
      >
        <v-card-text>
          <v-row>
            <v-col
              class="fw-600 fs-14 c-black d-none d-md-flex"
              cols="4"
            >
              Name
            </v-col>
            <v-col
              class="fw-600 fs-14 c-black d-none d-md-flex"
              cols="3"
            >
              Address
            </v-col>
            <v-col
              class="fw-600 fs-14 c-black d-none d-md-flex"
              cols="2"
            >
              City
            </v-col>
            <SortableTableHeader
              @toggle="toggleSort($event)"
              :sort-dir="sortDirection"
              :sort-field="sortField"
              field="count_of_staff"
              md="1"
              title="Staff"
              dense
            />
            <SortableTableHeader
              @toggle="toggleSort($event)"
              :sort-dir="sortDirection"
              :sort-field="sortField"
              field="count_of_locations"
              md="2"
              title="Locations"
              dense
            />
          </v-row>
        </v-card-text>
      </v-card>

      <v-card
        v-for="(business, index) in businesses"
        @click="$router.push({ name: 'BusinessShow', params: { businessId: business.id } })"
        :key="index"
        class="bb-1 bc-extra-light-gray pointer pa-0"
        tile
      >
        <v-card-text>
          <v-row class="d-flex align-center">
            <v-col
              class="c-black fs-16 fw-500"
              cols="4"
              >{{ business.name || '-' }}</v-col
            >
            <v-col
              class="c-black fs-16 fw-500"
              cols="3"
              >{{ business.address || '-' }}</v-col
            >
            <v-col
              class="c-black fs-16 fw-500"
              cols="2"
              >{{ business.city || '-' }}</v-col
            >
            <v-col
              class="c-black fs-16 fw-500"
              cols="1"
              >{{ business.count_of_staff }}</v-col
            >
            <v-col
              class="c-black fs-16 fw-500"
              cols="2"
              >{{ business.count_of_locations }}</v-col
            >
          </v-row>
        </v-card-text>
      </v-card>

      <v-row
        v-if="businesses.length == 0"
        class="justify-center px-2"
      >
        <v-col cols="3">
          <v-img :src="$a.url('icons.nullstate')" />
        </v-col>
      </v-row>

      <v-pagination
        v-show="pages > 1"
        v-model="page"
        :length="pages"
        :total-visible="8"
        class="mt-4"
      />

      <VerticalSpacer />
    </v-container>
  </v-container>
</template>
<script>
import Api from '@/specialist/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SortableHeaders from '@/shared/mixins/sortable_headers';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ResourceDialog,
  },

  mixins: [SortableHeaders],

  data() {
    return {
      businesses: [],
      newBusinessValues: [{ text: 'Name', value: 'name' }],
      page: 1,
      pages: 1,
      processing: false,
      query: '',
      queryCount: 0,
      queriedBusinesses: [],
      schemas: Object.values(this.$store.state.schemas).filter(
        (schema) => schema.data_type === 'business',
      ),
      selectedBusiness: null,
      selectedSchemaId: null,
    };
  },

  watch: {
    page() {
      this.load();
    },

    query(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.page = 1;
        this.load();
      }
    },
  },
  created() {
    this.load();
  },

  methods: {
    createBusiness(newBiz) {
      this.processing = true;
      Api.organization.business.create(
        {
          ...newBiz,
          schema_id: Object.values(this.$store.state.schemas).find(
            (schema) => schema.data_type === 'Business',
          ).id,
          confirmed: true,
        },
        (resp) => {
          this.$router.push({ name: 'BusinessShow', params: { businessId: resp.data.id } });
        },
        (err) => {
          this.$eventBus.$emit('error', err);
          this.processing = false;
        },
      );
    },

    load() {
      const params = {};
      params.query = this.query;
      params.page = this.page;
      if (this.sortField) {
        params.sort_field = this.sortField;
        params.sort_dir = this.sortDirection;
      }

      this.queryCount += 1;
      const currentQueryCount = this.queryCount;

      Api.organization.business.index(params, (resp) => {
        if (this.queryCount === currentQueryCount) {
          this.pages = parseInt(resp.headers['x-page-count'] || 0, 10);
          this.businesses = resp.data;
        }
      });
    },
  },
};
</script>
