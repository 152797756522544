<template>
  <v-card
    v-if="loaded"
    :border="border"
    class="c-light-black fs-14 fw-600"
    data-testid="submitted-subsidy-memo"
    flat
    tile
  >
    <v-card-text class="fs-16">
      <p class="my-0">
        <strong>{{ $t('ID') }}:</strong> {{ ownerId.toUpperCase() }}
      </p>

      <template v-if="createRevision">
        <p class="my-0">
          <strong>{{ $t('Created by') }}: </strong>
          <span>{{ createdByResult }}</span>
        </p>
      </template>

      <p class="my-0">
        <strong>{{ $t('Created') }}: </strong>
        <LongDateTime :date="createdAt" />
      </p>

      <template v-if="submitRevision">
        <p class="my-0">
          <strong>{{ $t('Submitted by') }}: </strong>
          <span>{{ submittedBy }}</span>
        </p>

        <p class="my-0">
          <strong>{{ $t('Submitted') }}: </strong>
          <LongDateTime :date="submittedAt" />
        </p>
      </template>
    </v-card-text>
  </v-card>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LongDateTime from '@/shared/components/LongDateTime.vue';

const props = defineProps({
  createdAt: {
    type: String,
    default: null,
  },
  createdBy: {
    type: String,
    default: null,
  },
  ownerId: {
    type: String,
    required: true,
  },
  ownerType: {
    type: String,
    required: true,
  },
  border: {
    type: Boolean,
    default: true,
  },
  submittedAt: {
    type: String,
    default: null,
  },
});

const createRevision = ref(null);
const submitRevision = ref(null);
const loaded = ref(false);

const createdByResult = computed(() => {
  if (props.createdBy) {
    return props.createdBy;
  }

  return [createRevision.value?.author_name, createRevision.value?.author_email]
    .filter(Boolean)
    .join(', ');
});

const submittedBy = computed(() => {
  return [submitRevision.value?.author_name, submitRevision.value?.author_email]
    .filter(Boolean)
    .join(', ');
});

async function load() {
  loaded.value = false;
  const params = {
    owner_id: props.ownerId,
    owner_type: props.ownerType,
  };

  const response = await Api.organization.revision.index(params);
  createRevision.value = response.data[response.data.length - 1];
  [submitRevision.value] = response.data.filter(
    (revision) => revision.data.submitted_at && !revision.data.submitted_at[0],
  );
  loaded.value = true;
}

watch(
  () => props.ownerId,
  async (val) => {
    if (val) {
      await load();
    }
  },
  { immediate: true },
);
</script>
