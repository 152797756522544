<template>
  <v-card
    border
    flat
    tile
  >
    <v-card-title tag="h3">
      {{ $t('Authentication') }}
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row
        class="mb-4"
        dense
      >
        <v-col cols="12">
          <LabeledSwitch
            v-model="draft.enable_magic_link_authentication"
            @input="changed = true"
            subtitle="Allow users to login with a magic link sent to their email."
            title="Enable magic link authentication"
          />
          <LabeledSwitch
            v-model="draft.enable_password_authentication"
            @input="changed = true"
            subtitle="Allow users to login with an email and password."
            title="Enable email/password based authentication and registration"
          />
          <LabeledSwitch
            v-model="draft.enable_mfa_authentication"
            @input="changed = true"
            subtitle="Allow users to setup 2-Factor authentication."
            title="Enable multi-factor authentication"
          />
          <LabeledSwitch
            v-if="draft.enable_mfa_authentication"
            v-model="draft.enable_mfa_requirement"
            @input="changed = true"
            subtitle="Require users setup and use 2-Factor authentication."
            title="Require multi-factor authentication"
          />
          <LabeledSwitch
            v-model="draft.enable_optional_phone"
            @input="changed = true"
            subtitle="Allow users to add a phone number to their account. Note: parent and provider portals only."
            title="Enable optional phone"
          />
          <LabeledSwitch
            v-model="draft.enable_phone_registration"
            @input="changed = true"
            subtitle="Allow users to register with a phone number. Note: parent portal only."
            title="Enable phone based registration"
          />
          <LabeledSwitch
            v-model="draft.enable_phone_authentication"
            @input="changed = true"
            subtitle="Allow users to login with a phone number. Note: parent portal only."
            title="Enable phone based authentication"
          />
          <LabeledSwitch
            v-model="draft.disable_self_registration"
            @input="changed = true"
            subtitle="Prevent users from registering new accounts. Note: parent and provider portals only."
            title="Disable self registration"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <SaveBar
      @cancel="reset"
      @save="save"
      :model-value="changed"
      :processing="processing"
      flat
    />
  </v-card>
</template>

<script setup>
import organizationApi from '@/specialist/services/bright_finder';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const props = defineProps({
  site: {
    default: null,
    type: Object,
  },
});

const emit = defineEmits(['refresh']);

const changed = ref(false);
const draft = reactive({});
const processing = ref(false);

function reset() {
  Object.assign(draft, {
    disable_self_registration: props.site.disable_self_registration,
    enable_magic_link_authentication: props.site.enable_magic_link_authentication,
    enable_optional_phone: props.site.enable_optional_phone,
    enable_password_authentication: props.site.enable_password_authentication,
    enable_phone_authentication: props.site.enable_phone_authentication,
    enable_phone_registration: props.site.enable_phone_registration,
    enable_mfa_authentication: props.site.enable_mfa_authentication,
    enable_mfa_requirement: props.site.enable_mfa_requirement,
  });
  changed.value = false;
}

async function save() {
  processing.value = true;
  const resp = await organizationApi.organization.site.update(props.site.id, draft);
  processing.value = false;
  if (resp?.status !== 200) {
    reset();
    return;
  }

  changed.value = false;
  eventBus.chime('Saved');
  emit('refresh');
}

onMounted(() => {
  reset();
});
</script>
