<template>
  <div>
    <ResourceDialog
      @save="$emit('add', selectedLearners)"
      action="add-learner"
      close-button-text="Cancel"
      save-button-text="Add"
      title="Add learners"
      close-on-save
      closeable
    >
      <template #prepend>
        <LabeledSelect
          v-if="pageOptions.length > 1"
          v-model="selectedPage"
          @change="loadProviderMembers"
          :items="pageOptions"
          cols="12"
          prefix-text="Page"
        />

        <LabeledAutocomplete
          v-model="selectedLearners"
          :items="learnerOptions"
          :mandatory="true"
          :multiple="true"
          item-title="name"
          item-value="id"
          message="Select new learners"
          placeholder="Search by name"
          chips
          closable-chips
          return-object
        />
      </template>
    </ResourceDialog>
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import LabeledSelect from '@/shared/components/form/LabeledSelect.vue';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';

const props = defineProps({
  existingLearners: {
    type: Array,
    default: () => [],
  },
});

defineEmits(['add']);

const pageOptions = ref([]);
const providerMembers = ref([]);
const selectedLearners = ref([]);
const selectedPage = ref(1);

const learnerOptions = computed(() => {
  return providerMembers.value.filter(
    (member) => !props.existingLearners.some((learner) => learner.id === member.id),
  );
});

async function loadProviderMembers() {
  console.log('PAGE NUMBER: ', selectedPage.value);
  const params = {
    is_provider: true,
    page: selectedPage.value,
  };

  const response = await Api.organization.member.index(params);

  providerMembers.value = response?.data || [];

  const numberOfPages = parseInt(response.headers['x-page-count'] || 0, 10);
  pageOptions.value = Array.from({ length: numberOfPages }, (_, i) => i + 1);
}

onMounted(() => {
  selectedLearners.value = [];
  void loadProviderMembers();
});
</script>
