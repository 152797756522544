<template>
  <v-container v-if="schema">
    <FormDialog
      @save="create"
      ref="dialog"
      :schema-id="schema.id"
    />
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import FormDialog from '@/shared/components/form/FormDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    FormDialog,
  },

  mixins: [API],

  data() {
    return {
      schema: null,
    };
  },

  watch: {
    '$route.params.formSchemaId': {
      immediate: true,
      handler() {
        this.load();
      },
    },
  },

  methods: {
    create() {
      window.location.replace(this.schema.meta.submitted_redirect_url);
    },

    async load() {
      await this.loadSchema();
      const memberParams = {
        is_anonymous: true,
        organization_id: window.organization_id,
        is_parent: true,
      };
      this.api.member.create(
        memberParams,
        () => this.$refs.dialog.open(),
        (err) => this.$eventBus.$emit('error', err),
      );
    },

    async loadSchema() {
      const resp = await this.api.public_api.organization.schema
        .get(this.$route.params.formSchemaId)
        .catch(() => this.$eventBus.$emit('longChime', 'Form could not be found'));
      if (!resp) return;

      this.schema = resp.data;
    },
  },
};
</script>
