<template>
  <v-container
    v-if="members"
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle title="Staff" />

    <v-toolbar class="mb-3 px-4 border-b">
      <v-row>
        <v-col>
          <v-text-field
            v-model="staffQuery"
            class="mxw-300"
            density="compact"
            placeholder="Filter by name or email"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
          />
        </v-col>

        <v-col class="ta-right">
          <v-btn
            v-if="$store.state.profile.org_settings_access"
            @click="uploadDialogIsVisible = true"
            color="primary"
            prepend-icon="upload"
            variant="outlined"
          >
            {{ $t('Upload') }}
          </v-btn>

          <v-btn
            v-if="isEditor"
            @click="openNewMemberDialog"
            class="ml-2"
            color="primary"
            data-cy="add-member-button"
            prepend-icon="add"
            variant="flat"
          >
            {{ $t('New') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-container
      class="px-3 py-0"
      fluid
    >
      <v-card
        v-if="members.length > 0"
        class="border pa-0"
        tile
      >
        <v-card-text>
          <v-row>
            <v-col class="fw-600 fs-16 c-black d-none d-md-flex">
              {{ $t('Name') }}
            </v-col>

            <v-col class="fw-600 fs-16 c-black d-none d-md-flex">
              {{ $t('Email') }}
            </v-col>

            <v-col class="fw-600 fs-16 c-black d-none d-md-flex">
              {{ $t('Last login') }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card
        v-for="(member, index) in members"
        :key="index"
        class="bt-0 pointer pa-0"
        border
        tile
      >
        <v-card-text>
          <v-row class="d-flex align-center">
            <v-col class="c-black fs-16 fw-500">
              <router-link :to="{ name: 'StaffShow', params: { memberId: member.id } }">
                <span v-if="member.name && member.name.trim().length > 2">
                  {{ member.name || '-' }}
                </span>

                <span v-else>
                  {{ $t('Unknown name') }}
                </span>

                <v-chip
                  v-if="member.deactivated"
                  class="ml-2"
                  color="red"
                  size="small"
                >
                  {{ $t('Deactivated') }}
                </v-chip>
              </router-link>
            </v-col>

            <v-col class="c-black fs-16 fw-500">
              <span>
                {{ $t(member.email) }}
              </span>
            </v-col>

            <v-col class="c-black fs-16 fw-500">
              <LongDateTime :date="member.last_sign_in_at" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row
        v-if="members.length == 0"
        class="justify-center px-2"
      >
        <v-col cols="3">
          <v-img :src="$a.url('icons.nullstate')" />
        </v-col>
      </v-row>

      <v-pagination
        v-if="pages"
        v-show="pages > 1"
        v-model="page"
        :length="pages"
        :total-visible="8"
        class="mt-4"
      />

      <VerticalSpacer :min-height="60" />

      <v-dialog
        v-model="uploadDialogIsVisible"
        max-width="500"
      >
        <v-card
          border
          tile
        >
          <v-card-title>
            {{ $t('New upload') }}
          </v-card-title>

          <v-divider />

          <v-card-text class="py-4 fs-16">
            <p>
              {{ $t('Upload a CSV file to update or create new members.') }}
            </p>
            <p>{{ $t('Required fields') }}:</p>
            <p>
              <strong>
                {{ $t('email, is_provider (should be true), is_parent (should be false)') }}
              </strong>
            </p>
            <p>{{ $t('Optional fields') }}:</p>
            <p>
              <strong>
                {{
                  $t(
                    'first_name, last_name, phone, text_opted_in (true or false), provider_ids (comma separated), business_id',
                  )
                }}
              </strong>
            </p>

            <v-row>
              <LabeledControl message="Attach CSV file">
                <v-file-input
                  v-model="file"
                  accept=".csv"
                  color="primary"
                  label="Attach file"
                  variant="filled"
                  hide-details
                />
              </LabeledControl>
            </v-row>
          </v-card-text>

          <v-divider class="mb-4" />

          <v-card-actions>
            <v-btn
              @click="createUpload"
              :disabled="!file"
              :loading="processing"
              color="primary"
              block
            >
              {{ $t('Upload') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ResourceDialog
        @save="createStaffMember"
        ref="newStaffMemberDialog"
        :max-width="600"
        :save-button-disabled="
          !newStaffMember.first_name || !newStaffMember.last_name || !newStaffMember.email
        "
        title="Invite member"
      >
        <template #form>
          <LabeledTextfield
            v-model="newStaffMember.first_name"
            data-cy="invite_first_name"
            message="First name"
          />

          <LabeledTextfield
            v-model="newStaffMember.last_name"
            data-cy="invite_last_name"
            message="Last name"
          />

          <LabeledTextfield
            v-model="newStaffMember.email"
            data-cy="invite_email"
            message="Email"
          />
        </template>
      </ResourceDialog>
    </v-container>
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LabeledControl from '@/shared/components/form/LabeledControl.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const store = useStore();

const file = ref(null);
const members = ref(null);
const newStaffMember = ref({});
const newStaffMemberDialog = ref(null);
const page = ref(1);
const pages = ref(null);
const processing = ref(false);
const staffQuery = ref(null);
const staffQueryCount = ref(0);
const uploadDialogIsVisible = ref(false);

const isEditor = computed(() => {
  return store.state.profile.org_business_edit;
});

watch(page, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    page.value = 1;
    load();
  }
});

watch(staffQuery, load);

function createUpload() {
  processing.value = true;

  const reader = new FileReader();
  reader.readAsText(file.value, 'UTF-8');

  reader.onload = function (event) {
    Api.organization.member.upload.create(
      { content: event.target.result },
      () => {
        eventBus.chime('File upload successful.');
        processing.value = false;
        uploadDialogIsVisible.value = false;
        load();
      },
      (error) => {
        eventBus.chime(error.response.data.errors.join('. '));
        processing.value = false;
        load();
      },
    );
  };
}

function load() {
  staffQueryCount.value += 1;
  const currentStaffQueryCount = staffQueryCount.value;
  Api.organization.member.index(
    {
      is_provider: true,
      page: page.value,
      query: staffQuery.value,
    },
    (response) => {
      if (currentStaffQueryCount === staffQueryCount.value) {
        pages.value = parseInt(response.headers['x-page-count'] || 0, 10);
        members.value = response.data;
      }
    },
  );
}

function openNewMemberDialog() {
  newStaffMember.value = {};
  newStaffMemberDialog.value.open();
}

async function createStaffMember() {
  newStaffMember.value.is_provider = true;
  newStaffMember.value.is_parent = false;
  newStaffMember.value.is_specialist = false;
  processing.value = true;

  const resp = await Api.organization.member.create(newStaffMember.value);

  if (resp?.status === 201) {
    eventBus.chime('Staff member added');
    load();
    newStaffMemberDialog.value.close();
  }

  processing.value = false;
}

onMounted(load);
</script>
