<template>
  <v-container
    v-if="collection"
    class="mxw-1200 pa-6"
  >
    <v-row>
      <v-col>
        <h1 class="fw-600 fs-24">
          <span v-t="`${terms.family_subsidy} ${terms.programs.toLowerCase()}`" />
        </h1>
      </v-col>
      <v-col
        v-if="collection.length > 0"
        class="ta-right"
      >
        <v-btn
          @click="$refs.newDialog.open()"
          color="primary"
          variant="flat"
        >
          <span v-t="'New'" />
        </v-btn>
      </v-col>
    </v-row>

    <template v-if="collection.length == 0">
      <NullState @new="$refs.newDialog.open({})" />
    </template>

    <v-row
      v-for="program in collection"
      :key="program.id"
    >
      <v-col cols="12">
        <v-card
          :ripple="false"
          :to="{ name: 'FamilySubsidyProgramShow', params: { id: program.id } }"
          border
          flat
          tile
        >
          <v-card-text>
            <div v-text="program.name" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ResourceDialog
      @save="create"
      ref="newDialog"
      :fields="fields"
      title="New"
    />
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import NullState from '@/shared/components/NullState.vue';
import Terms from '@/shared/mixins/terms';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    NullState,
    ResourceDialog,
  },

  mixins: [API, Terms],

  data() {
    return {
      collection: null,
      familySubsidySchemas: [],
      groupSchemas: [],
      processing: false,
      schemas: [],
    };
  },

  computed: {
    fields() {
      return [
        { text: 'Name', value: 'name' },
        {
          text: 'Group schema',
          value: 'group_schema_id',
          items: this.groupSchemas,
          itemText: 'name',
          itemValue: 'id',
        },
        {
          text: `${this.terms.family_subsidy} schema`,
          value: 'family_subsidy_schema_id',
          items: this.familySubsidySchemas,
          itemText: 'name',
          itemValue: 'id',
        },
      ];
    },
  },

  created() {
    this.load();
    this.loadSchemas();
  },

  methods: {
    async create(newVal) {
      this.processing = true;
      const resp = await this.api.organization.family_subsidy_program
        .create(newVal)
        .catch((error) => this.$eventBus.$emit('error', error));
      this.processing = false;
      if (!resp?.data?.id) return;

      this.$router.push({ name: 'FamilySubsidyProgramShow', params: { id: resp.data.id } });
    },

    async load() {
      const resp = await this.api.organization.family_subsidy_program.index();
      this.collection = resp.data;
    },

    async loadSchemas() {
      const { data } = await this.api.organization.schema.index();
      this.familySubsidySchemas = data.filter((schema) => schema.data_type === 'FamilySubsidy');
      this.groupSchemas = data.filter((schema) => schema.data_type === 'Group');
    },
  },
};
</script>
