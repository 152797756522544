<template>
  <v-dialog
    v-model="isVisible"
    max-width="500"
  >
    <v-card
      class="mx-auto"
      width="500"
      border
      flat
      tile
    >
      <v-card-title>
        {{ $t(`New ${question.is_section ? 'section' : 'question'}`) }}
      </v-card-title>
      <v-divider class="mb-6" />
      <v-card-text>
        <v-row>
          <LabeledTextfield
            v-model="question.title"
            message="Title"
            autofocus
          />
        </v-row>

        <v-row>
          <LabeledCustomSelect
            v-model="questionFormat"
            :items="formatOptions"
            cols="12"
            message="Format"
          />
        </v-row>

        <v-row>
          <LabeledSwitch
            v-model="question.published"
            subtitle="Question will be visible to all users"
            title="Published"
          />
          <LabeledSwitch
            v-model="question.mandatory"
            subtitle="All fields must be filled out to proceed"
            title="Mandatory"
          />
        </v-row>

        <template
          v-if="!question.is_section && (questionFormat === 'text' || questionFormat === 'address')"
        >
          <v-row
            v-if="schemas"
            class="d-flex align-center mb-1"
          >
            <LabeledAutocomplete
              v-model="question.synced_model"
              :items="schemas"
              item-title="name"
              item-value="data_type"
              message="Model"
            />
          </v-row>

          <v-row dense>
            <LabeledControl message="Fields">
              <DataItem
                v-for="(obj, index) in question.synced_attributes"
                @remove="question.synced_attributes.splice(index, 1)"
                :key="index"
                :name="obj.name"
                dense
              />
            </LabeledControl>
            <v-col>
              <v-btn
                @click="$refs.addAttributeDialog.open()"
                color="primary"
                size="small"
                variant="flat"
              >
                <v-icon
                  class="me-1"
                  size="16"
                >
                  add
                </v-icon>
                <span>Add</span>
              </v-btn>
            </v-col>
          </v-row>

          <template v-if="!question.mandatory">
            <v-row dense>
              <LabeledControl message="Mandatory fields">
                <DataItem
                  v-for="(obj, index) in question.mandatory_attributes"
                  @remove="question.mandatory_attributes.splice(index, 1)"
                  :key="index"
                  :name="obj.name"
                  dense
                />
              </LabeledControl>
              <v-col>
                <v-btn
                  @click="$refs.addMandatoryAttributeDialog.open()"
                  color="primary"
                  size="small"
                  variant="flat"
                >
                  <v-icon
                    class="me-1"
                    size="16"
                  >
                    add
                  </v-icon>
                  <span>{{ $t('Add') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </template>
      </v-card-text>
      <v-divider class="mt-4 mb-6" />
      <v-card-actions>
        <v-btn
          @click="isVisible = false"
          :loading="processing"
          size="x-large"
          variant="text"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          @click="create"
          :disabled="createDisabled"
          :loading="processing"
          color="primary"
          size="x-large"
        >
          {{ $t('Create') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <ResourceDialog
      @save="question.synced_attributes.push($event)"
      ref="addAttributeDialog"
      :fields="[{ text: 'Name', value: 'name', items: fields }]"
      save-button-text="Add"
      title="Add field"
      close-on-save
    />

    <ResourceDialog
      @save="question.mandatory_attributes.push($event)"
      ref="addMandatoryAttributeDialog"
      :fields="[{ text: 'Name', value: 'name', items: fields }]"
      save-button-text="Add"
      title="Add mandatory field"
      close-on-save
    />
  </v-dialog>
</template>

<script setup>
import Api from '@/admin/services/bright_finder';
import DataItem from '@/shared/components/form/DataItem.vue';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import LabeledControl from '@/shared/components/form/LabeledControl.vue';
import LabeledCustomSelect from '@/shared/components/form/LabeledCustomSelect.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import Schema from '@/shared/services/schema';

const eventBus = useEventBus();

const props = defineProps({
  ownerId: {
    type: String,
    default: null,
  },
  ownerType: {
    type: String,
    default: null,
  },
  schema: {
    type: Object,
    default: null,
  },
  schemas: {
    type: Array,
    default: null,
  },
});

const emit = defineEmits(['created']);
defineExpose({ open });

const questionFormat = ref('text');
const isVisible = ref(false);
const processing = ref(false);
const question = ref({ format: 'text' });

const formatOptions = computed(() => {
  const options = [
    { text: 'Text', value: 'text', icon: 'notes' },
    { text: 'Upload', value: 'document', icon: 'upload' },
  ];

  if (props.ownerType === 'FamilySubsidyProgram') {
    options.push({ text: 'Address', value: 'address', icon: 'location_on' });
  }

  return options;
});

const createDisabled = computed(() => {
  return (
    !question.value.title ||
    (!question.value.is_section &&
      questionFormat.value === 'text' &&
      !question.value.synced_attributes.length > 0)
  );
});

const fields = computed(() => {
  let fields = [];
  if (question.value.synced_model) {
    if (props.schemas) {
      const syncedSchema = props.schemas.find(
        (schema) => schema.data_type === question.value.synced_model,
      );

      if (syncedSchema) {
        fields = Schema.fields(syncedSchema.definition);
      } else {
        eventBus.error(`Cannot find synced schema for: ${question.value.synced_model}`);
      }
    } else {
      fields = Schema.fields(props.schema.definition);
    }
  } else {
    return [];
  }

  return fields;
});

function create() {
  question.value.format = questionFormat.value;
  processing.value = true;

  Api.organization.question.create(
    question.value,
    () => {
      emit('created');
      isVisible.value = false;
      processing.value = false;
    },
    (err) => {
      processing.value = false;
      eventBus.chime(err.response.data.errors[0]);
    },
  );
}

function open(
  syncedModel,
  leadingQuestion,
  eligibility,
  isSection = false,
  postPreferenceSelection = false,
) {
  question.value = {
    eligibility,
    post_preference_selection: postPreferenceSelection,
    is_section: isSection,
    leading_question_id: leadingQuestion ? leadingQuestion.id : null,
    mandatory_attributes: [],
    synced_attributes: [],
    synced_model: syncedModel,
    owner_id: props.ownerId,
    owner_type: props.ownerType,
  };
  isVisible.value = true;
}
</script>
