<template>
  <div
    v-if="dataSchema && form && provider && schema"
    class="d-flex w-100pc justify-center"
  >
    <v-container>
      <v-toolbar
        class="px-3"
        color="white"
        elevation="0"
      >
        <v-btn
          :to="backRoute"
          variant="text"
          icon
        >
          <v-icon
            class="c-black me-3"
            icon="west"
            size="28"
          />
        </v-btn>

        <div
          v-text="form.name"
          class="c-black d-inline-block fs-22 fw-600"
        ></div>

        <v-spacer></v-spacer>

        <v-tooltip location="bottom">
          <template #activator="{ props }">
            <v-btn
              @click="toggleLock"
              v-bind="props"
              :disabled="isReadonly"
              :icon="form.locked ? 'lock' : 'lock_open'"
              class="ms-3 me-2"
              color="primary"
              data-cy="lock-button"
              size="small"
              variant="text"
            />
          </template>
          {{ $t(form.locked ? 'Unlock form' : 'Lock form') }}
        </v-tooltip>

        <ActionMenu
          @click:action:status="setStatus"
          :button-color="buttonColor"
          :button-title="form.status || 'Draft'"
          :dark="reviewed || null"
          :items="statusActions"
          button-icon-side="right"
          button-outlined
          left
        />

        <ActionMenu
          @click:action:delete="deleteForm()"
          :items="actionItems"
          button-icon="more_vert"
        />
      </v-toolbar>

      <v-card
        flat
        tile
      >
        <QuestionSet
          v-model="dataModel"
          @change="saveForm($event)"
          @change:attachments="loadAttachments()"
          :attachment-group-id="form.group_id"
          :attachment-owner-id="form?.id"
          :attachment-owner-type="'Form'"
          :attachment-readonly="isReadonly || null"
          :attachments="attachments"
          :processing="processing"
          :questions="validQuestions"
          :readonly="isReadonly"
          :schema="dataSchema.definition"
          color="white"
          divided
          expanded
          hide-actions
          landscape
          very-dense
        />

        <div
          v-for="question in validVerificationQuestions"
          :key="question.id"
        >
          <v-divider class="my-2" />

          <FormQuestion
            :subtitle="question.verification_subtitle"
            :title="question.verification_title"
            color="white"
            hide-actions
            landscape
            very-dense
          >
            <AttachmentUploader
              v-show="!isReadonly"
              @uploaded="attachments.push($event)"
              :ref="['uploader', question.id].join('')"
              :owner="{ type: 'Form', id: form.id, tag: question.id, tags: [form.id, question.id] }"
              class="mb-4"
            />
            <AttachmentList
              @delete="loadAttachments()"
              :attachments="attachments.filter((attachment) => attachment.tag == question.id)"
              :hide-remove="isReadonly"
              class="mb-6"
            />
          </FormQuestion>
        </div>
      </v-card>

      <v-row v-if="displaySubmitButton">
        <v-col>
          <v-btn
            @click="submitForm()"
            :disabled="questionsIncomplete"
            class="mt-2"
            color="primary"
            block
          >
            {{ $t('Submit') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import useQuestionable from '@/shared/composables/useQuestionable';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const UPPY_CLASS_NAME = 'uppy-Dashboard-input';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();

const attachments = ref([]);
const confirmDialog = ref(null);
const form = ref(null);
const processing = ref(false);
const provider = ref(null);
const schema = ref(null);

const schemaId = computed(() => schema.value?.id);

const {
  questionsIncomplete,
  questionsLoaded,
  validQuestions,
  validVerificationQuestions,
  validateAnswers,
} = useQuestionable({
  ownerDataType: ref('Schema'),
  ownerId: schemaId,
  syncedObject: form,
});

const actionItems = computed(() => {
  if (isReadonlyEnrollmentForm.value) return [];

  if (store.state.profile.org_forms_admin || store.state.profile.org_forms_delete) {
    return [
      {
        event: 'delete',
        avatar: 'delete',
        title: 'Delete',
      },
    ];
  }

  return [];
});

const backRoute = computed(() => {
  if (route.query?.back === 'subsidy') {
    return { name: 'SubsidyShow', params: { id: form.value?.subsidy_id } };
  } else if (route.query?.back === 'provider') {
    return { name: 'ProviderShow', params: { providerId: form.value?.provider_id } };
  } else {
    return { name: 'FormIndex' };
  }
});

const buttonColor = computed(() => {
  if (form.value.status === 'Approved') return 'green';
  if (form.value.status === 'Denied') return 'red';
  return 'primary';
});

const isReadonlyEnrollmentForm = computed(() => {
  if (!form.value?.enrollment_id) return false;
  return store.state.profile.org_enrollments_agent;
});

const isReadonly = computed(() => {
  if (isReadonlyEnrollmentForm.value) return true;

  return !(store.state.profile.org_forms_edit || store.state.profile.org_forms_admin);
});

const displaySubmitButton = computed(() => {
  if (form.value.submitted_at) return false;
  return store.state.profile.org_forms_edit || store.state.profile.org_forms_admin;
});

const dataSchema = computed(() => {
  if (provider.value && questionsLoaded.value) {
    if (validQuestions.value[0]?.synced_model === 'Provider') {
      return store.state.schemas[provider.value.schema_id];
    }
    if (schema.value) return schema.value;
  }
  return null;
});

const dataModel = computed(() => {
  if (dataSchema.value) {
    if (dataSchema.value.data_type === 'Provider') {
      return provider.value;
    }
    return form.value;
  }
  return null;
});

const statusActions = computed(() => {
  if (isReadonly.value) return [];

  return [
    {
      event: 'status',
      avatar: 'done',
      title: form.value.status === 'Approved' ? 'Approved' : 'Approve',
    },
    {
      event: 'status',
      avatar: 'remove_done',
      title: form.value.status === 'Denied' ? 'Denied' : 'Deny',
    },
  ];
});

const reviewed = computed(() => ['Approved', 'Denied'].includes(form.value.status));

watch(
  () => route.params.formId,
  () => void load(),
  { immediate: true },
);

async function deleteForm() {
  const confirm = await confirmDialog.value.confirmWithText(
    `Are you sure you want to delete this Form? This cannot be undone`,
  );

  if (!confirm) return;

  const result = await Api.organization.form.destroy(form.value.id);

  if (result.status === 200) eventBus.chime('Deleted successfully');
  else eventBus.chime(result.error);

  await router.replace(backRoute.value);
}

async function load() {
  const { data } = await Api.organization.form.get(route.params.formId);

  form.value = data;
  schema.value = store.state.schemas[form.value.schema_id];
  void loadAttachments();
  loadProvider();
}

async function loadAttachments() {
  const params = {
    owner_type: 'Form',
    owner_id: route.params.formId,
  };

  const resp = await Api.organization.attachment.index(params);
  if (resp?.status !== 200) return;

  attachments.value = resp.data;
}

function loadProvider() {
  if (form.value.provider_id) {
    Api.organization.provider.get(form.value.provider_id, (resp) => {
      provider.value = resp.data;
    });
  } else {
    provider.value = {};
  }
}

async function saveForm(event) {
  const isUppyTriggeredEvent = event.target?.className === UPPY_CLASS_NAME;

  if (!isReadonly.value && !isUppyTriggeredEvent) {
    processing.value = true;
    if (form.value.provider_id) {
      Api.organization.provider.update(
        provider.value.id,
        provider.value,
        () => {
          void updateForm();
        },
        (err) => {
          processing.value = false;
          eventBus.chime(err.response.data.errors[0]);
        },
      );
    } else {
      void updateForm();
    }
    validateAnswers();
  }
}

async function setStatus(newVal) {
  let status;
  if (newVal.title === 'Approve') {
    status = 'Approved';
  } else if (newVal.title === 'Deny') {
    status = 'Denied';
  }

  const params = { status };
  await Api.organization.form.update(route.params.formId, params);
  void load();
}

async function submitForm() {
  const params = { submitted: true };
  await Api.organization.form.update(route.params.formId, params);
  eventBus.chime('Form submitted');
  void load();
}

async function toggleLock() {
  const params = { locked: !form.value.locked };
  await Api.organization.form.update(route.params.formId, params);
  if (params.locked) eventBus.chime('Form locked');
  else eventBus.chime('Form unlocked');
  void load();
}

async function updateForm() {
  await nextTick();
  Api.organization.form.update(
    form.value.id,
    form.value,
    (resp) => {
      processing.value = false;
      form.value = resp.data;
      eventBus.chime('Form saved');
    },
    (err) => {
      processing.value = false;
      eventBus.chime(err.response.data.errors[0]);
    },
  );
}
</script>
