<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle title="Data Dashboard" />

    <v-tabs
      v-model="tab"
      @update:model-value="tabChange"
      bg-color="surface"
      grow
    >
      <v-tab value="overview">
        <v-icon
          class="material-icons-outlined"
          icon="stacked_bar_chart"
          start
        />

        {{ $t('Overview') }}
      </v-tab>

      <v-tab value="availability">
        <v-icon
          icon="chair_alt"
          start
        />

        {{ $t('Availability') }}
      </v-tab>

      <v-tab value="demand">
        <v-icon
          icon="child_care"
          start
        />

        {{ $t('Demand') }}
      </v-tab>

      <v-tab value="supply">
        <v-icon
          class="material-icons-outlined"
          icon="other_houses"
          start
        />

        {{ $t('Supply') }}
      </v-tab>
    </v-tabs>

    <v-window
      v-model="tab"
      class="border-t"
    >
      <v-window-item value="overview">
        <DataOverview />
      </v-window-item>

      <v-window-item value="availability">
        <DataAvailability />
      </v-window-item>

      <v-window-item value="demand">
        <DataDemand />
      </v-window-item>

      <v-window-item value="supply">
        <DataSupply />
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script setup>
import DataAvailability from '@/specialist/views/data/DataAvailability.vue';
import DataDemand from '@/specialist/views/data/DataDemand.vue';
import DataOverview from '@/specialist/views/data/DataOverview.vue';
import DataSupply from '@/specialist/views/data/DataSupply.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const tab = ref('overview');

function load() {
  if (!route.query.tab) return;

  const tabNames = ['overview', 'availability', 'demand', 'supply'];
  if (tabNames.includes(route.query.tab)) tabChange(route.query.tab);
}

function tabChange(newValue) {
  tab.value = newValue;
  router.push({
    query: { tab: tab.value },
  });
}

onMounted(load);
</script>
