<template>
  <v-card
    class="my-2"
    border
    tile
  >
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col>
          <span>
            {{ $t(course.name) }}
          </span>

          <v-chip
            :color="course.restricted ? 'amber-lighten-3' : 'primary-lighten-3'"
            class="ml-3"
          >
            {{ $t(course.restricted ? 'Cohort' : 'Public') }}
          </v-chip>
        </v-col>

        <v-col class="ta-right">
          <template v-if="showDownloadCertificate">
            <v-btn
              :href="downloadLink"
              :ripple="false"
              color="primary"
              target="_blank"
            >
              <span>
                {{ $t('Download certificate') }}
              </span>
            </v-btn>
          </template>

          <template v-else-if="courseCompletion">
            <v-chip
              class="fs-14"
              color="light-green-lighten-3"
            >
              {{ $t('Active') }}
            </v-chip>
          </template>

          <template v-else>
            <v-chip
              class="fs-14"
              color="light-grey"
            >
              {{ $t('Not started') }}
            </v-chip>
          </template>

          <v-btn
            @click="destroy"
            :disabled="!courseCompletion"
            :loading="processing"
            class="ms-3"
            color="red"
            variant="text"
            icon
          >
            <v-icon icon="close" />
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <ConfirmDialog ref="confirmDialog" />
  </v-card>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';

const props = defineProps({
  cohort: {
    type: Object,
    default: null,
  },
  cohortCourseCompletion: {
    type: Object,
    default: null,
  },
  course: {
    type: Object,
    default: null,
  },
  courseCompletion: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['destroy']);

const confirmDialog = ref(null);
const processing = ref(false);

const downloadLink = computed(() => {
  return Api.organization.course_completion.downloadUrl(
    props.courseCompletion.id,
    props.course.name.replaceAll(' ', '-'),
  );
});

const showDownloadCertificate = computed(() => {
  if (props.cohortCourseCompletion) return props.cohortCourseCompletion?.attributes?.awarded;

  return !!props.courseCompletion?.completed_at;
});

async function destroy() {
  const confirm = await confirmDialog.value.confirmWithText(
    'Are you sure you want to remove this completed course work?',
  );
  if (!confirm) return;

  processing.value = true;

  Api.organization.course_completion.destroy(props.courseCompletion.id, () => {
    emit('destroy');
    processing.value = false;
  });
}
</script>
