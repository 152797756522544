<template>
  <v-container
    v-if="familySubsidyProgram"
    class="mxw-1200"
  >
    <TitleBar
      :back-name="`${terms.family_subsidy} programs`"
      :back-route="{ name: 'FamilySubsidyProgramIndex' }"
      :name="familySubsidyProgram.name"
    />

    <SectionHeader title="Overview" />

    <v-card
      class="mb-8"
      border
      tile
    >
      <v-card-text>
        <v-row>
          <LabeledTextfield
            v-model="familySubsidyProgram.name"
            @input="changed = true"
            :message="$t('Name')"
          />
          <LabeledTextarea
            v-model="familySubsidyProgram.description"
            @input="changed = true"
            :message="$t('Description')"
          />
          <LabeledSimpleSelect
            v-model="familySubsidyProgram.state_options"
            @input="changed = true"
            :items="$a.assets.states"
            :message="$t('Allowed states')"
            chips
            deletable-chips
            multiple
          />
          <LabeledCombobox
            v-model="familySubsidyProgram.zip_options"
            @input="changed = true"
            :message="$t('Allowed Zips')"
            chips
            deletable-chips
            multiple
          />
          <LabeledCombobox
            v-model="familySubsidyProgram.city_options"
            @input="changed = true"
            :message="$t('Allowed Cities')"
            chips
            deletable-chips
            multiple
          />
          <LabeledSwitch
            v-model="familySubsidyProgram.enable_lock_on_submit"
            @input="changed = true"
            :subtitle="$t('Family cannot submit or update application when application is locked')"
            :title="$t('Enable lock on submit')"
          />
          <LabeledSwitch
            v-model="familySubsidyProgram.enable_multiple_applications"
            @input="changed = true"
            :subtitle="
              $t('Families can apply multiple times to the same program for the same child.')
            "
            :title="$t('Enable multiple applications')"
          />
          <LabeledSwitch
            v-model="familySubsidyProgram.enable_claims"
            @input="changed = true"
            :subtitle="$t('Families can submit claims for an approved funding amount.')"
            :title="$t('Enable claims')"
          />
          <LabeledSwitch
            v-model="familySubsidyProgram.enable_household_members"
            @input="changed = true"
            :subtitle="$t('Household member information should be included in applications.')"
            :title="$t('Enable household members')"
          />
          <LabeledSwitch
            v-model="familySubsidyProgram.enable_require_eligible_dependent"
            @input="changed = true"
            :subtitle="
              $t(
                'When this requirement is enabled, the application will only be accepted if it includes a dependent under 13 who needs care, or a dependent aged 13-19 who requires care and has a disability.',
              )
            "
            :title="$t('Eligible dependent required')"
          />
          <LabeledSwitch
            v-model="familySubsidyProgram.enable_require_funding_source_eligibility"
            @input="changed = true"
            :subtitle="
              $t(
                'When disabled, an application does not have to meet any funding eligibility checks.',
              )
            "
            :title="$t('Enable requirement for funding eligibility checks')"
          />
          <LabeledSwitch
            v-model="familySubsidyProgram.enable_invite_only"
            @input="changed = true"
            :subtitle="$t('Families cannot view this program unless invited.')"
            :title="$t('Enable invite only')"
          />
          <LabeledSwitch
            v-model="familySubsidyProgram.enable_third_party_applications"
            @input="changed = true"
            :subtitle="
              $t(
                'When this is enabled, users can indicate they are applying on behalf of someone else and are prompted to respond to a different attestation',
              )
            "
            :title="$t('Enable third-party applications')"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Data mappings" />
    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <LabeledSimpleSelect
            v-model="familySubsidyProgram.group_schema_id"
            @change="changed = true"
            :items="groupSchemas"
            :message="$t('Group schema')"
            cols="4"
            item-title="name"
            item-value="id"
            chips
          />
          <LabeledSimpleSelect
            v-model="familySubsidyProgram.family_subsidy_schema_id"
            @change="changed = true"
            :items="familySubsidySchemas"
            :message="$t(`${terms.family_subsidy} schema`)"
            cols="4"
            item-title="name"
            item-value="id"
            chips
          />
          <LabeledSimpleSelect
            v-model="familySubsidyProgram.claim_schema_id"
            @change="changed = true"
            :items="claimSchemas"
            :message="$t('Claim schema')"
            cols="4"
            item-title="name"
            item-value="id"
            chips
          />
          <LabeledSimpleSelect
            v-model="familySubsidyProgram.associated_schema_ids"
            @change="changed = true"
            :items="otherSchemas"
            :message="$t('Other associated schemas (reviews, agreements, etc)')"
            cols="12"
            item-title="name"
            item-value="id"
            chips
            multiple
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Content" />
    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="contentCategory"
              :items="contentCategories"
              variant="outlined"
              hide-details
            />
          </v-col>

          <template v-if="contentCategory == 'Eligibility'">
            <LabeledTextfield
              v-model="familySubsidyProgram.eligibility_title"
              @input="changed = true"
              :message="$t('Title')"
            />
            <LabeledTextarea
              v-model="familySubsidyProgram.eligibility_subtitle"
              @input="changed = true"
              :message="$t('Subtitle')"
            />
            <LabeledTextarea
              v-model="familySubsidyProgram.eligibility_failure_alert"
              @input="changed = true"
              :message="$t('Failure alert')"
            />
          </template>

          <template v-if="contentCategory == 'Household members'">
            <LabeledTextfield
              v-model="familySubsidyProgram.household_members_title"
              @input="changed = true"
              :message="$t('Title')"
            />
            <LabeledTextarea
              v-model="familySubsidyProgram.household_members_subtitle"
              @input="changed = true"
              :message="$t('Subtitle')"
            />
          </template>
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Availability" />
    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row class="mb-3">
          <LabeledDateTimeZonePicker
            v-model="familySubsidyProgram.opens_at"
            @change="changed = true"
            :message="$t('Open date')"
            cols="6"
          />
          <LabeledDateTimeZonePicker
            v-model="familySubsidyProgram.closes_at"
            @change="changed = true"
            :message="$t('Close date')"
            cols="6"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Labels" />
    <v-card
      v-if="labels.length == 0"
      class="pa-4 mb-4"
      border
      flat
    >
      <div v-t="'You have not created any labels yet.'" />
    </v-card>
    <v-card
      v-for="label in labels"
      :key="label.id"
      class="pa-4 mb-4"
      border
      flat
      tile
    >
      <v-row>
        <v-col>
          <v-menu
            location="left"
            max-height="300"
          >
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                class="me-3"
                size="small"
                variant="text"
                icon
              >
                <v-avatar
                  :color="label.color"
                  size="20"
                />
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="color in colorOptions"
                @click="changeColor(label, color)"
                :key="color"
              >
                <template #prepend>
                  <v-avatar
                    :color="color"
                    size="20"
                  />
                </template>
              </v-list-item>
            </v-list>
          </v-menu>
          <span v-text="label.name" />
        </v-col>

        <v-col class="ta-right">
          <ActionMenu
            @click:action:delete="deleteLabel(label)"
            @click:action:edit="$refs.addEditLabelDialog.open(label)"
            :items="[
              { avatar: 'edit', title: 'Edit', event: 'edit' },
              { avatar: 'delete', title: 'Delete', event: 'delete' },
            ]"
            button-icon="more_vert"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-btn
      @click="$refs.addEditLabelDialog.open({})"
      :disabled="processing"
      color="primary"
    >
      {{ $t('Add label') }}
    </v-btn>

    <v-divider class="my-8" />

    <SectionHeader title="Attestation" />
    <v-card
      v-if="organization"
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row class="mb-3">
          <v-col>
            <v-select
              v-model="selectedLanguage"
              :items="organization.languages"
              variant="outlined"
              hide-details
            />
          </v-col>
        </v-row>
        <div class="fs-20 mb-2">Family</div>
        <v-row>
          <LabeledTextarea
            v-model="familySubsidyProgram.attestation_text[selectedLanguage]"
            @input="changed = true"
            :message="$t('Attestation text')"
            :placeholder="$t('Ex “I agree the information is correct and may be shared with XYZ.”')"
            class="mb-4"
          />
        </v-row>
        <v-divider class="my-6" />
        <div class="fs-20 mb-2">Admin</div>
        <v-row>
          <LabeledTextarea
            v-model="familySubsidyProgram.admin_attestation_text[selectedLanguage]"
            @input="changed = true"
            :message="$t('Attestation text')"
            :placeholder="
              $t(
                'Ex “I agree the information is correct and the family has authorized it to be shared with XYZ.”',
              )
            "
            class="mb-4"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <DangerBar
      @destroy="destroy"
      @publish="publish"
      @unpublish="unpublish"
      :processing="processing"
      :published="!!familySubsidyProgram.published_at"
      publishable
    />

    <SaveBar
      @cancel="load"
      @save="save"
      :model-value="changed"
      :processing="processing"
    />

    <ResourceDialog
      @save="addEditLabel"
      ref="addEditLabelDialog"
      :fields="[
        { text: 'Name', value: 'name', required: true },
        { text: 'Color', value: 'color', required: true, type: 'color-hex' },
      ]"
      :processing="processing"
      :title="$t('Add label')"
    />
  </v-container>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Api from '@/specialist/services/bright_finder';
import colors from 'vuetify/util/colors';
import DangerBar from '@/shared/components/DangerBar.vue';
import LabeledCombobox from '@/shared/components/form/LabeledCombobox.vue';
import LabeledDateTimeZonePicker from '@/shared/components/form/LabeledDateTimeZonePicker.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import SectionHeader from '@/shared/components/content/SectionHeader.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import useTerms from '@/shared/composables/useTerms';
import TitleBar from '@/shared/components/TitleBar.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';

defineEmits(['error']);
const router = useRouter();
const route = useRoute();

const addEditLabelDialog = ref(null);
const contentCategories = ['Eligibility', 'Household members'];
const contentCategory = ref('Eligibility');
const colorOptions = Object.values(colors).map((value) => value.lighten2);
const changed = ref(false);
const claimSchemas = ref([]);
const eventBus = useEventBus();
const familySubsidyProgram = ref(null);
const familySubsidySchemas = ref([]);
const groupSchemas = ref([]);
const labels = ref([]);
const organization = ref(null);
const otherSchemas = ref([]);
const paramsId = ref('route.params.id');
const processing = ref(false);
const selectedLanguage = ref(null);
const { terms } = useTerms();

watch(paramsId, load, { immediate: true });

async function destroy() {
  // eslint-disable-next-line no-alert
  if (!confirm('Are you sure you want to destroy this program?')) return;

  processing.value = true;
  const resp = await Api.organization.family_subsidy_program
    .destroy(familySubsidyProgram.value)
    .catch((error) => eventBus.error(error));

  if (resp) router.push({ name: 'FamilySubsidyProgramIndex' });
}

async function load() {
  changed.value = false;
  loadOrganization();
  await loadSchemas();
  await loadFamilySubsidyProgram();
}

async function loadSchemas() {
  const resp = await Api.organization.schema.index();
  groupSchemas.value = resp.data.filter((schema) => schema.data_type === 'Group');
  otherSchemas.value = resp.data.filter((schema) =>
    ['Agreement', 'Form', 'Review'].includes(schema.data_type),
  );
  familySubsidySchemas.value = resp.data.filter((schema) => schema.data_type === 'FamilySubsidy');
  claimSchemas.value = resp.data.filter((schema) => schema.data_type === 'Claim');
}

async function loadFamilySubsidyProgram() {
  const resp = await Api.organization.family_subsidy_program.get(route.params.id);
  familySubsidyProgram.value = resp.data;
  await loadLabels();
}

function loadOrganization() {
  Api.organization.get((resp) => {
    organization.value = resp.data;
    selectedLanguage.value = organization.value.languages[0].value;
  });
}

async function publish() {
  await save({
    id: familySubsidyProgram.value.id,
    published_at: new Date().toISOString(),
  });
}

async function save(newVal) {
  processing.value = true;
  const resp = await Api.organization.family_subsidy_program
    .update(newVal || familySubsidyProgram.value)
    .catch((error) => eventBus.error(error));
  processing.value = false;

  if (!resp?.data) return;
  familySubsidyProgram.value = resp.data;
  changed.value = false;
}

async function unpublish() {
  await save({
    id: familySubsidyProgram.value.id,
    published_at: null,
  });
}

async function loadLabels() {
  const resp = await Api.organization.label.index({
    family_subsidy_program_id: familySubsidyProgram.value.id,
  });
  labels.value = resp.data;
  processing.value = false;
}

async function addEditLabel(label) {
  processing.value = true;
  if (label.id) {
    await Api.organization.label.update(label.id, label);
  } else {
    await Api.organization.label.create({
      family_subsidy_program_id: familySubsidyProgram.value.id,
      label,
    });
  }
  await loadLabels();
  addEditLabelDialog.value.close();
}

async function changeColor(label, color) {
  // eslint-disable-next-line no-param-reassign
  label.color = color;
  await addEditLabel(label);
}

async function deleteLabel(label) {
  if (
    // eslint-disable-next-line no-alert
    confirm(
      'Are you sure you want to remove this label? Applications that have been assigned this label will retain it until removed.',
    )
  ) {
    processing.value = true;
    await Api.organization.label.destroy(label.id);
    await loadLabels();
  }
}
</script>
