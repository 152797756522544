<template>
  <div v-if="survey && schema">
    <v-toolbar
      class="mb-3"
      height="60"
      border
      flat
    >
      <v-row class="d-flex align-center px-4">
        <v-col
          class="d-flex align-center"
          cols="8"
        >
          <v-btn
            :aria-label="$t('Back to all surveys')"
            :to="{ name: 'SurveyIndex' }"
            class="me-3"
            variant="text"
            icon
          >
            <v-icon
              class="c-black"
              size="28"
            >
              west
            </v-icon>
          </v-btn>

          <div
            v-text="survey.id"
            class="c-black d-inline-block fs-22 fw-600"
          />
        </v-col>

        <v-col
          class="d-flex justify-end"
          cols="4"
        >
          <ActionMenu
            @click:action:destroy="destroy"
            :items="[{ event: 'destroy', avatar: 'delete', title: 'Delete' }]"
            button-icon="more_vert"
          />
        </v-col>
      </v-row>
    </v-toolbar>

    <v-container>
      <QuestionSet
        v-model="survey"
        :color="null"
        :elevation="0"
        :questions="validQuestions"
        :schema="schema.definition"
        condensed
        dense
        expanded
        hide-actions
        outlined
        readonly
        tile
      />
    </v-container>
  </div>
</template>

<script>
import API from '@/shared/mixins/api';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import Questionable from '@/shared/mixins/questionable';

export default {
  compatConfig: { MODE: 3 },

  components: {
    QuestionSet,
  },

  mixins: [API, Questionable],

  data() {
    return {
      processing: false,
      questions: [],
      schema: null,
      survey: [],
    };
  },

  watch: {
    '$route.params.surveyId': {
      immediate: true,
      handler() {
        this.load();
      },
    },
  },

  methods: {
    async destroy() {
      // eslint-disable-next-line no-alert
      if (!confirm('Are you sure you want to delete this survey?')) return;

      await this.api.organization.survey.destroy(this.$route.params.surveyId);
      this.$router.replace({ name: 'SurveyIndex' });
    },

    // TODO: When converting to Vue3, consider the use of useQuestionable
    async loadQuestions() {
      const resp = await this.api.public_api.organization.question.index({
        owner_id: this.schema.id,
        owner_type: 'Schema',
      });
      this.questions = resp.data;
      this.validate();
    },

    async load() {
      const resp = await this.api.organization.survey.get(this.$route.params.surveyId);
      this.survey = resp.data;
      this.schema = this.$store.state.schemas[this.survey.schema_id];
      await this.loadQuestions();
    },
  },
};
</script>
