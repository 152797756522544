<template>
  <v-card
    v-if="draft"
    class="px-0"
    border
    tile
  >
    <v-card-text>
      <LabeledTextfield
        v-model="draft.name"
        @input="changed = true"
        :message="$t('What is the name of this site?')"
        class="mb-2"
      />

      <LabeledSimpleSelect
        v-model="draft.brand_id"
        @change="changed = true"
        :items="brands"
        :message="$t('What brand would you like to apply to this site?')"
        class="mb-2"
        item-title="name"
        item-value="id"
      />

      <LabeledSimpleSelect
        v-model="draft.site_template_id"
        @change="changed = true"
        :items="siteTemplates"
        :message="$t('Which template do you want to use for your new site?')"
        class="mb-2"
        item-title="name"
        item-value="id"
      />

      <LabeledSimpleSelect
        v-model="draft.webpage_id"
        @change="changed = true"
        :items="webpages"
        :message="$t('Does this site use a custom landing page?')"
        class="mb-2"
        data-testid="webpages-select"
        item-title="name"
        item-value="id"
        chips
        deletable-chips
      />

      <LabeledSimpleSelect
        v-model="draft.terms_agreement_id"
        @change="changed = true"
        :items="termsAgreements"
        :message="$t('Does this site use a terms of service agreement?')"
        class="mb-2"
        item-title="name"
        item-value="id"
        chips
        deletable-chips
      />

      <LabeledNumber
        v-model="draft.user_session_timeout_minutes"
        @change="changed = true"
        @input="changed = true"
        :max="59"
        :message="$t('Inactive user timeout:')"
        class="mb-2"
        data-cy="site_session_timeout"
        title="Minutes"
      />

      <template v-if="draft.id">
        <SaveBar
          @cancel="reset"
          @save="submit"
          :model-value="changed"
          :processing="processing"
          flat
        />
      </template>

      <v-col
        v-else
        cols="12"
      >
        <v-btn
          @click="submit()"
          :loading="processing"
          color="primary"
        >
          {{ $t('Create') }}
        </v-btn>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script setup>
import _ from 'lodash';
import AdminApi from '@/admin/services/bright_finder';
import SpecialistApi from '@/specialist/services/bright_finder';
import LabeledNumber from '@/shared/components/form/LabeledNumber.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';

const eventBus = useEventBus();
const router = useRouter();

const props = defineProps({
  site: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['refresh']);

const brands = ref([]);
const changed = ref(false);
const draft = ref(null);
const messageTemplates = ref([]);
const processing = ref(false);
const siteTemplates = ref([]);
const termsAgreements = ref([]);
const webpages = ref([]);

async function load() {
  draft.value = JSON.parse(JSON.stringify(props.site || {}));

  await loadWebpages();
  await loadBrands();
  await loadMessageTemplates();
  await loadSiteTemplates();
  await loadTermsAgreements();
}

async function loadBrands() {
  const response = await AdminApi.organization.brand.index();
  brands.value = response?.data;
}

async function loadMessageTemplates() {
  const response = SpecialistApi.organization.messageTemplate.index();
  messageTemplates.value = response?.data;
}

async function loadSiteTemplates() {
  const response = await SpecialistApi.organization.siteTemplate.index();
  siteTemplates.value = response?.data;
}

async function loadTermsAgreements() {
  const response = await SpecialistApi.organization.termsAgreement.index();
  termsAgreements.value = response?.data;
}

async function loadWebpages() {
  const response = await SpecialistApi.organization.webpage.index();
  webpages.value = response?.data;
}

function reset() {
  draft.value = JSON.parse(JSON.stringify(props.site || {}));
  changed.value = false;
}

function submit() {
  if (!validate()) return;

  processing.value = true;
  SpecialistApi.organization.site.updateOrCreate(
    draft.value,
    (response) => {
      if (draft.value.id) {
        eventBus.chime('Saved');
        emit('refresh');
        processing.value = false;
        changed.value = false;
      } else {
        router.push({
          name: 'SiteShow',
          params: {
            siteId: response.data.id,
          },
        });
        processing.value = false;
      }
    },
    (error) => {
      eventBus.chime(error.response.data.errors[0]);
      processing.value = false;
    },
  );
}

function validate() {
  // allow user to set blank value
  if (_.isEmpty(draft.value.user_session_timeout_minutes)) return true;

  // if not blank, must be greater than 15
  if (draft.value.user_session_timeout_minutes < 15) {
    eventBus.chime('Value must be 15 or greater');
    return false;
  }

  return true;
}

onMounted(load);
</script>
