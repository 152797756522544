<template>
  <ProgramMessaging
    @change="changed = $event"
    @load="load"
    @newMessageTemplate="addMessageTemplate"
    @save="save"
    :back-route="{ name: 'FamilySubsidyProgramShow' }"
    :changed="changed"
    :event-names="eventNames"
    :owner-type="'FamilySubsidyProgram'"
    :processing="processing"
    :program="familySubsidyProgram"
  />
</template>

<script>
import API from '@/shared/mixins/api';
import ProgramMessaging from '@/admin/components/ProgramMessaging.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ProgramMessaging,
  },

  mixins: [API],

  data() {
    return {
      changed: true,
      eventNames: null,
      familySubsidyProgram: {},
      familySubsidyProgramId: null,
      processing: false,
    };
  },

  created() {
    this.processing = true;
    this.familySubsidyProgramId = this.$route.params.id;
    this.load();
  },

  methods: {
    addMessageTemplate(messageTemplate) {
      this.familySubsidyProgram.message_templates.push(messageTemplate);
      this.changed = true;
    },

    async load() {
      this.changed = false;

      await this.loadFamilySubsidyProgram();
    },

    async loadFamilySubsidyProgram() {
      const response = await this.api.organization.family_subsidy_program.get(
        this.familySubsidyProgramId,
      );
      this.familySubsidyProgram = response.data;
      this.processing = false;
      this.eventNames = this.familySubsidyProgram.event_names;
    },

    async save() {
      this.processing = true;
      const params = {
        id: this.$route.params.id,
        enable_parent_subsidy_submitted_alert:
          this.familySubsidyProgram.enable_parent_subsidy_submitted_alert,
        parent_subsidy_submitted_alert_subject_template:
          this.familySubsidyProgram.parent_subsidy_submitted_alert_subject_template,
        parent_subsidy_submitted_alert_template:
          this.familySubsidyProgram.parent_subsidy_submitted_alert_template,
        message_templates: this.familySubsidyProgram.message_templates,
      };

      const response = await this.api.organization.family_subsidy_program
        .update(params)
        .catch((error) => this.$eventBus.$emit('chime', error.response.data.errors[0]));
      this.processing = false;
      if (!response?.data) {
        this.load();
        return;
      }

      this.familySubsidyProgram = response.data;
      this.changed = false;
      this.$eventBus.$emit('chime', 'Saved');
    },
  },
};
</script>
