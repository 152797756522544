<template>
  <v-card
    class="mb-4"
    border
    tile
  >
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col>
          <div
            v-t="subsidyProgram.name"
            class="fs-20 mb-2 fw-500"
          />
          <template v-if="subsidy">
            <div>
              <template v-if="subsidy.submitted_at">
                <span
                  v-t="'Submitted at'"
                  class="me-2 fs-14"
                />
                <LongDateTime
                  :date="subsidy.submitted_at"
                  class="fs-14"
                />
              </template>
              <template v-else>
                <span
                  v-t="'Started on'"
                  class="me-2 fs-14"
                />
                <LongDateTime
                  :date="subsidy.created_at"
                  class="fs-14"
                />
              </template>
            </div>
            <div>
              <span class="fs-12 c-light-black">ID# {{ subsidy.id }}</span>
            </div>
          </template>
          <div v-else>
            <span
              v-t="'Not started'"
              class="fs-14"
            />
          </div>
        </v-col>

        <v-col class="ta-right">
          <template v-if="subsidy">
            <template v-if="subsidy.submitted_at">
              <v-btn
                :to="{ name: 'SubsidyShow', params: { id: subsidy.id } }"
                class="focus-very-visible"
                color="primary"
                data-cy="review_application"
                tile
              >
                <v-icon start> check_circle </v-icon>
                <span v-t="'Review'" />
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                :disabled="programClosed()"
                :to="{ name: 'SubsidyShow', params: { id: subsidy.id } }"
                color="primary"
                tile
              >
                <v-icon start> check_circle </v-icon>
                <span v-t="'In progress'" />
              </v-btn>
            </template>
          </template>

          <v-btn
            v-else
            @click="createSubsidy(subsidyProgram)"
            :disabled="programClosed()"
            :loading="processing"
            color="primary"
          >
            {{ $t('Start') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import API from '@/shared/mixins/api';
import LongDateTime from '@/shared/components/LongDateTime.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LongDateTime,
  },

  mixins: [API],

  props: {
    child: {
      type: Object,
      default: null,
    },
    subsidy: {
      type: Object,
      default: null,
    },
    subsidyProgram: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      processing: false,
    };
  },

  methods: {
    async createSubsidy() {
      this.processing = true;

      const params = {
        child_id: this.child.id,
        subsidy_program_id: this.subsidyProgram.id,
      };
      const resp = await this.api.organization.subsidy.create(params);
      this.$router.push({ name: 'SubsidyShow', params: { id: resp.data.id } });
    },

    programClosed() {
      if (
        this.$store.state.profile?.org_subsidies_admin === true &&
        this.$store.state.profile?.org_subsidies_edit === true
      ) {
        return false;
      }

      return (
        !!this.subsidyProgram.closes_at && new Date() >= new Date(this.subsidyProgram.closes_at)
      );
    },
  },
};
</script>
