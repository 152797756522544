<template>
  <v-container
    v-if="specialist"
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      :back-route="{ name: 'SpecialistIndex' }"
      :title="specialist.name + (specialist.deactivated ? ` (${$t('Deactivated')})` : '')"
      back-name="Members"
      flat
      outlined
    />

    <v-container
      class="pa-8 ma-0"
      fluid
    >
      <v-card
        class="mb-8"
        border
        tile
      >
        <v-card-title>
          {{ $t('Profile') }}
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-row dense>
            <LabeledTextfield
              v-model="specialist.first_name"
              @input="changed = true"
              id="specialist_first_name"
              :readonly="specialist.deactivated"
              cols="6"
              message="First name"
            />
            <LabeledTextfield
              v-model="specialist.last_name"
              @input="changed = true"
              id="specialist_last_name"
              :readonly="specialist.deactivated"
              cols="6"
              message="Last name"
            />
            <LabeledTextfield
              v-model="specialist.email"
              @input="changed = true"
              id="specialist_email"
              :readonly="specialist.deactivated"
              cols="6"
              message="Email"
            />
          </v-row>

          <v-divider class="my-6" />

          <v-row dense>
            <LabeledSwitch
              v-model="specialist.require_sso"
              @input="changed = true"
              :readonly="specialist.deactivated"
              title="Require Single Sign-On (SSO)"
            />
          </v-row>
        </v-card-text>
      </v-card>

      <template v-if="!specialist.deactivated">
        <PermissionBlock
          v-model="specialist"
          @change:permission="changed = true"
          :permissions="specialistPermissions.getProviderContents()"
          title="Providers"
        />

        <PermissionBlock
          v-model="specialist"
          @change:permission="changed = true"
          :permissions="specialistPermissions.getGroupContents()"
          title="Families"
        />

        <PermissionBlock
          v-model="specialist"
          @change:permission="changed = true"
          :permissions="specialistPermissions.getChildSubsidyContents()"
          title="Child subsidies"
        />

        <PermissionBlock
          v-model="specialist"
          @change:permission="changed = true"
          :permissions="specialistPermissions.getEnrollmentContents()"
          title="Enrollments"
        />

        <PermissionBlock
          v-model="specialist"
          @change:permission="changed = true"
          :permissions="specialistPermissions.getReportingContents()"
          title="Reports"
        />

        <PermissionBlock
          v-model="specialist"
          @change:permission="changed = true"
          :permissions="specialistPermissions.getFormContents()"
          title="Forms"
        />

        <PermissionBlock
          v-model="specialist"
          @change:permission="changed = true"
          :permissions="specialistPermissions.getLearningContents()"
          title="Learning"
        />

        <PermissionBlock
          v-model="specialist"
          @change:permission="changed = true"
          :permissions="specialistPermissions.getFamilySubsidyContents()"
          :title="terms.family_subsidies"
        />

        <PermissionBlock
          v-if="pages.Grants.enabled"
          v-model="specialist"
          @change:permission="changed = true"
          :permissions="specialistPermissions.getGrantContents()"
          title="Grants"
        />

        <PermissionBlock
          v-if="pages.Messaging.enabled"
          v-model="specialist"
          @change:permission="changed = true"
          :permissions="specialistPermissions.getMessagingContents()"
          title="Messaging"
        />

        <PermissionBlock
          v-model="specialist"
          @change:permission="changed = true"
          :permissions="specialistPermissions.getOtherPermissionContents(pages)"
          title="Other permissions"
        />

        <PermissionBlock
          v-model="specialist"
          @change:permission="changed = true"
          :permissions="specialistPermissions.getLabContents()"
          subtitle="These are features we are working on that you can opt into. Please note that since these features are in work, they may change."
          title="Labs"
        />
      </template>

      <AccountSettings :member="specialist" />
    </v-container>

    <SaveBar
      @cancel="cancel"
      @save="save"
      :model-value="changed"
      :processing="processing"
    />
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import AccountSettings from '@/shared/components/AccountSettings.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import PermissionBlock from '@/specialist/components/PermissionBlock.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import useEventBus from '@/shared/composables/useEventBus';
import useTerms from '@/shared/composables/useTerms';
import { specialistPermissions } from '@/specialist/services/specialist-permissions';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const { terms } = useTerms();

const eventBus = useEventBus();
const route = useRoute();
const store = useStore();

const changed = ref(true);
const processing = ref(true);
const specialist = ref(null);

const pages = computed(() => {
  return store.state.pages;
});

async function cancel() {
  processing.value = true;
  await load();
}

async function load() {
  const response = await Api.organization.member.get(route.params.id);
  specialist.value = response?.data;

  processing.value = false;
  changed.value = false;
}

async function save() {
  processing.value = true;

  const response = await Api.admin.member
    .update(route.params.id, specialist.value)
    .catch((error) => eventBus.error(error));

  processing.value = false;
  if (!response?.data) return;

  specialist.value = response.data;
  changed.value = false;

  eventBus.chime('Saved');
}

onMounted(load);
</script>
