<template>
  <v-container fluid>
    <BreadcrumbTitle
      :back-route="{ name: 'SiteIndex' }"
      back-name="Sites"
      name="New Site"
    />

    <SiteForm :site="siteTemplate" />
  </v-container>
</template>

<script setup>
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import SiteForm from '@/admin/components/sites/SiteForm.vue';

const siteTemplate = ref({
  site_type: null,
  name: null,
  domains: null,
  site_template_id: null,
});
</script>
