export default [
  {
    description: 'Last day care was provided for the child under the terms of this award.',
    required: true,
    text: 'Finish date',
    value: 'finish_date',
    type: 'date',
  },
  {
    description: 'All payments after this date will be canceled if any remain.',
    required: true,
    text: 'Last payment date',
    value: 'last_payment_date',
    type: 'date',
  },
  {
    description: 'Amount that was overpaid due to pre-payment or early cancelation of the award.',
    text: 'Refund amount',
    value: 'refund_amount',
    type: 'currency',
  },
  {
    description: 'Date refund was or should be scheduled for payment.',
    text: 'Refund date',
    value: 'refund_date',
    type: 'date',
  },
];
