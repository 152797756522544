<template>
  <ResourceDialog
    @save="$emit('save', $event)"
    ref="dialog"
    :processing="processing"
    title="Edit assignment"
  >
    <template #form="{ localValue }">
      <v-row dense>
        <v-col cols="12">
          <v-autocomplete
            v-model="localValue.value"
            :disabled="disabled"
            :items="reviewers"
            item-title="name"
            item-value="id"
            label="Assign to"
            placeholder="Assign to"
            variant="filled"
            hide-details
          />
        </v-col>
      </v-row>
      <v-checkbox
        v-model="disabled"
        @update:model-value="localValue.value = null"
        class="mt-0"
        label="Unassign reviewer"
        hide-details
        inset
        tile
      />
    </template>
  </ResourceDialog>
</template>

<script>
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ResourceDialog,
  },

  data() {
    return {
      disabled: false,
    };
  },

  props: {
    processing: Boolean,
    reviewers: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['save'],

  methods: {
    close() {
      if (this.disabled) {
        this.disabled = false;
      }
      this.$refs.dialog.close();
    },

    open() {
      this.$refs.dialog.open({ value: null });
    },
  },
};
</script>
