<template>
  <div v-if="site && config">
    <SchemaForm
      v-model="config"
      @update:model-value="changed = true"
      :schema="site.meta.schema"
      class="mb-8"
      title="Template settings"
      flat
      outlined
    />

    <v-card
      class="mb-8"
      border
      tile
    >
      <v-card-title
        class="fs-24"
        tag="h3"
      >
        {{ $t('Global settings') }}
      </v-card-title>

      <v-divider />

      <v-card-text class="px-4">
        <v-row>
          <LabeledTextfield
            v-model="feedbackUrl"
            @update:model-value="changed = true"
            message="Feedback URL or email"
          />
          <LabeledSwitch
            v-model="enableSearch"
            @update:model-value="changed = true"
            subtitle="Disabling global search will hide the search bar from the header."
            title="Enable global search"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      v-if="site.site_type == 'manager'"
      class="mb-8"
      border
      tile
    >
      <v-card-title
        class="fs-24"
        tag="h3"
      >
        {{ $t('Provider settings') }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <LabeledSwitch
            v-model="enableProviderAccessRequest"
            @update:model-value="changed = true"
            subtitle="Disabling provider access request will require providers to be added manually."
            title="Enable provider access request"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      v-if="site.site_type == 'parent'"
      class="mb-8"
      border
      tile
    >
      <v-card-title
        class="fs-24"
        tag="h3"
      >
        {{ $t('Parent settings') }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <LabeledTextarea
            v-model="parentPreEligibilityInstructionText"
            @input="changed = true"
            message="Parent pre-eligibility instruction text"
          />

          <LabeledTextarea
            v-model="parentPreEligibilityFailureText"
            @input="changed = true"
            message="Parent pre-eligibility failure text"
          />

          <LabeledSwitch
            v-model="enableParentDashboardV2"
            @update:model-value="changed = true"
            subtitle="This enables the beta version of the parent dashboard."
            title="Enable Dashboard v2"
          />

          <LabeledSimpleSelect
            v-if="enableParentDashboardV2"
            v-model="defaultScreenerProgramId"
            @change="changed = true"
            :items="screenerPrograms"
            class="mb-2"
            item-title="name"
            item-value="id"
            subtitle="When this is set, the 'Retake eligibility quiz' or 'Take eligibility quiz' button on the dashboard will use this Screener Program when clicked."
            title="Retake Eligibility Quiz (Dashboard)"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <SaveBar
      @cancel="reset"
      @save="save"
      :model-value="changed"
      :processing="processing"
      flat
    />
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import organizationApi from '@/specialist/services/bright_finder';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import SchemaForm from '@/admin/components/schemas/SchemaForm.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const props = defineProps({
  site: {
    type: Object,
    required: true,
  },
});

const changed = ref(false);
const config = ref(null);
const defaultScreenerProgramId = ref(null);
const enableParentDashboardV2 = ref(null);
const enableProviderAccessRequest = ref(null);
const enableSearch = ref(null);
const feedbackUrl = ref(null);
const parentPreEligibilityFailureText = ref(null);
const parentPreEligibilityInstructionText = ref(null);
const processing = ref(true);
const screenerPrograms = ref([]);

async function reset() {
  processing.value = true;

  const response = await Api.organization.screenerProgram.index();
  screenerPrograms.value = response.data;

  changed.value = false;
  processing.value = false;

  config.value = JSON.parse(JSON.stringify(props.site.config));
  feedbackUrl.value = props.site.feedback_url;

  enableParentDashboardV2.value = props.site.enable_parent_dashboard_v2;
  enableProviderAccessRequest.value = props.site.enable_provider_access_request;
  enableSearch.value = props.site.enable_search;

  parentPreEligibilityFailureText.value = props.site.parent_pre_eligibility_failure_text;
  parentPreEligibilityInstructionText.value = props.site.parent_pre_eligibility_instruction_text;

  defaultScreenerProgramId.value = props.site.screener_program_id;
}

async function save() {
  processing.value = true;
  const resp = await organizationApi.organization.site.update(props.site.id, {
    config: config.value,
    feedback_url: feedbackUrl.value,
    enable_parent_dashboard_v2: enableParentDashboardV2.value,
    enable_provider_access_request: enableProviderAccessRequest.value,
    enable_search: enableSearch.value,
    parent_pre_eligibility_failure_text: parentPreEligibilityFailureText.value,
    parent_pre_eligibility_instruction_text: parentPreEligibilityInstructionText.value,
    screener_program_id: defaultScreenerProgramId.value,
  });
  processing.value = false;
  if (resp?.status !== 200) return;

  changed.value = false;
  eventBus.chime('Configuration saved');
}

onMounted(reset);
</script>
