<template>
  <v-container
    v-if="collection"
    class="mxw-1200 pa-6"
  >
    <v-row>
      <v-col>
        <div class="fw-600 fs-24">
          {{ $t('Child subsidy programs') }}
        </div>
      </v-col>

      <v-col
        v-if="collection.length > 0"
        class="ta-right"
      >
        <v-btn
          @click="newDialogIsVisible = true"
          color="primary"
          variant="flat"
        >
          {{ $t('New') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row
      v-if="collection.length == 0"
      class="d-flex justify-center"
      style="margin-top: 15%"
    >
      <v-col class="ta-center">
        <div class="fw-600 fs-20 mb-4">
          {{ $t('You do not have any subsidy programs') }}
        </div>

        <v-btn
          @click="newDialogIsVisible = true"
          color="primary"
          size="x-large"
          variant="flat"
          rounded
        >
          {{ $t('New') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="subsidyProgram in collection"
        :key="subsidyProgram.id"
        cols="12"
      >
        <v-card
          @click="
            $router.push({
              name: 'ChildSubsidyProgramShow',
              params: { subsidyProgramId: subsidyProgram.id },
            })
          "
          :ripple="false"
          border
          flat
          tile
        >
          <v-card-title class="fs-18 fw-600 constrained">
            {{ subsidyProgram.name }}
          </v-card-title>

          <v-card-text>
            <div
              v-text="subsidyProgram.description || '...'"
              class="c-black fs-16 fw-500 constrained"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="newDialogIsVisible">
      <v-card
        class="mx-auto"
        max-width="500"
        flat
        tile
      >
        <v-card-title class="fs-24 fw-600 mb-4">
          {{ $t('New subsidy program') }}
        </v-card-title>

        <v-card-text>
          <v-row>
            <LabeledTextfield
              v-model="newName"
              message="Name"
            />

            <LabeledSimpleSelect
              v-model="newGroupSchema"
              :items="groupSchemas"
              item-title="name"
              item-value="id"
              message="Group type"
            />

            <LabeledSimpleSelect
              v-model="newChildSchema"
              :items="childSchemas"
              item-title="name"
              item-value="id"
              message="Child type"
            />
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            @click="newDialogIsVisible = false"
            size="x-large"
            variant="text"
          >
            {{ $t('Cancel') }}
          </v-btn>

          <v-spacer />

          <v-btn
            @click="create"
            :disabled="!newName || !newChildSchema || !newGroupSchema"
            :loading="processing"
            color="primary"
            size="x-large"
          >
            {{ $t('Create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';

const eventBus = useEventBus();
const router = useRouter();

const childSchemas = ref([]);
const collection = ref(null);
const groupSchemas = ref([]);
const newDialogIsVisible = ref(false);
const newChildSchema = ref(null);
const newGroupSchema = ref(null);
const newName = ref(null);
const processing = ref(false);

onMounted(async () => {
  load();
  await loadSchemas();
});

function create() {
  processing.value = true;
  Api.organization.subsidy_program.create(
    {
      name: newName.value,
      child_schema_id: newChildSchema.value,
      group_schema_id: newGroupSchema.value,
    },
    (resp) => {
      processing.value = false;
      router.push({
        name: 'ChildSubsidyProgramShow',
        params: { subsidyProgramId: resp.data.id },
      });
    },
    (err) => {
      eventBus.chime(err.response.data.errors[0]);
    },
  );
}

function load() {
  Api.organization.subsidy_program.index((resp) => {
    collection.value = resp.data;
  });
}

async function loadSchemas() {
  const { data } = await Api.organization.schema.index();
  groupSchemas.value = data.filter((schema) => schema.data_type === 'Group');
  childSchemas.value = data.filter((schema) => schema.data_type === 'Child');
}
</script>
