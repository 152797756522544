<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle title="Survey" />

    <v-toolbar
      class="mb-3 px-4 bb-1"
      flat
    >
      <v-row>
        <v-col class="mxw-400">
          <v-select
            v-model="filters.schema_id"
            @update:model-value="updateFilters"
            :items="schemas"
            density="compact"
            item-title="name"
            item-value="id"
            variant="filled"
            hide-details
          />
        </v-col>
      </v-row>
    </v-toolbar>

    <v-container
      class="px-3 py-0"
      fluid
    >
      <v-card
        class="bb-1 pa-0"
        border
        flat
        tile
      >
        <v-card-text>
          <v-card
            class="bb-1 bc-extra-light-gray mb-4"
            flat
            tile
          >
            <v-card-text class="pt-0 px-0 pb-3 fs-15">
              <v-row dense>
                <v-col class="d-flex align-center">
                  <template v-if="appliedFiltersToDisplay.length > 0">
                    <v-icon start> filter_alt </v-icon>
                    <span
                      v-t="'Filtering by:'"
                      class="me-1"
                    />
                    <span class="fw-600">{{ appliedFiltersToDisplay.join(', ') }}</span>
                    <span>.&nbsp;</span>
                  </template>
                  <span
                    >{{ $t('Displaying first') }} <strong>{{ surveys.length }}</strong>
                    {{ $t('results') }}, {{ $t('out of') }} <strong>{{ total }}</strong>
                    {{ $t('total results') }}.</span
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-row role="row">
            <TableHeader
              md="6"
              title="ID"
            />

            <SortableTableHeader
              @toggle="toggleSort($event)"
              :sort-dir="$route.query.sort_dir"
              :sort-field="$route.query.sort_field"
              field="created_at"
              md="6"
              title="Created"
              justify-end
            />
          </v-row>
        </v-card-text>
      </v-card>

      <div
        v-show="processing"
        class="mxw-800 mx-auto py-12 ta-center"
      >
        <v-progress-circular
          color="primary"
          size="75"
          indeterminate
        />
      </div>

      <div v-show="!processing">
        <v-card
          v-for="survey in surveys"
          :key="survey.id"
          border
          flat
          tile
        >
          <v-card-text>
            <v-row
              :key="survey.id"
              role="row"
            >
              <TableCell
                :content="survey.id"
                :to="{ name: 'SurveyShow', params: { surveyId: survey.id } }"
                cols="6"
                target="_self"
              />

              <TableCell
                :content="survey.created_at"
                classes="justify-end"
                cols="6"
                transform="date-time"
              />
            </v-row>
          </v-card-text>
        </v-card>

        <NullState
          v-if="surveys.length == 0"
          title="No surveys found"
          hide-new-button
        />

        <v-pagination
          v-show="pages > 1"
          v-model="filters.page"
          @update:model-value="updateFilters(true)"
          :length="pages"
          :total-visible="8"
          class="mt-4"
        />
      </div>

      <VerticalSpacer :min-height="50" />
    </v-container>
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import NullState from '@/shared/components/NullState.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import RouterHelper from '@/shared/mixins/RouterHelper';
import SortableTableHeader from '@/shared/components/SortableTableHeader.vue';
import TableHeader from '@/shared/components/TableHeader.vue';
import TableCell from '@/shared/components/TableCell.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    NullState,
    PageTitle,
    SortableTableHeader,
    TableHeader,
    TableCell,
    VerticalSpacer,
  },
  mixins: [API, RouterHelper],

  data() {
    return {
      filters: this.getFiltersFromQuery({ paged: true }),
      pages: 0,
      processing: false,
      subsidyPrograms: [],
      schemas: Object.values(this.$store.state.schemas).filter(
        (schema) => schema.data_type === 'Survey',
      ),
      surveys: [],
      total: 0,
    };
  },

  computed: {
    appliedFiltersToDisplay() {
      return [];
    },
  },

  created() {
    if (this.schemas.length > 0) this.filters.schema_id = this.schemas[0].id;
    this.updateFilters();

    this.loadSurveys();
  },

  methods: {
    async loadSurveys() {
      this.processing = true;
      const params = this.getFiltersFromQuery({ paged: true });

      const resp = await this.api.organization.survey.index(params);

      this.surveys = resp.data;
      this.pages = parseInt(resp.headers['x-page-count'] || 0, 10);
      this.total = parseInt(resp.headers['x-total-count'] || 0, 10);
      this.processing = false;
    },

    async toggleSort(field) {
      let newSortDir;
      if (this.$route.query.sort_field !== field) {
        newSortDir = 'desc';
      } else if (this.$route.query.sort_dir === 'desc') {
        newSortDir = 'asc';
      } else {
        newSortDir = 'desc';
      }
      await this.updateQuery({ sort_field: field, sort_dir: newSortDir });
      this.loadSurveys();
    },

    async updateFilters(paged = false) {
      if (!paged) {
        this.filters.page = 1;
      }
      await this.updateQuery({ ...this.filters });
      this.loadSurveys();
    },
  },
};
</script>
