<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle :title="terms.family_subsidies" />

    <v-toolbar
      class="mb-3 bb-1"
      extension-height="60"
      extended
    >
      <v-row class="d-flex align-center px-4">
        <v-col class="mxw-400">
          <v-select
            v-model="filters.family_subsidy_program_id"
            @update:model-value="load"
            :aria-label="familySubsidyProgram?.name"
            :items="familySubsidyPrograms"
            density="compact"
            item-title="name"
            item-value="id"
            variant="filled"
            hide-details
          />
        </v-col>

        <v-col class="mxw-340">
          <v-text-field
            v-model="filters.query"
            id="search_link"
            :aria-label="$t('Filter results by name')"
            :placeholder="$t('Filter results by name')"
            color="primary"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
            tracked
          />
        </v-col>
      </v-row>

      <template
        v-if="familySubsidyProgram"
        #extension
      >
        <v-row
          class="px-4"
          dense
        >
          <v-col>
            <FilterMenu
              :active="!!filters.status"
              classes="d-none d-md-inline-flex"
              title="Status"
              paddingless
            >
              <template #card>
                <v-list
                  v-model:selected="filters.status"
                  @update:selected="handleFilterChange()"
                >
                  <v-list-item
                    v-for="option in statusOptions"
                    :key="option"
                    :title="option"
                    :value="option"
                  >
                    <template #prepend="{ isSelected }">
                      <v-checkbox-btn
                        :modelValue="isSelected"
                        tabindex="-1"
                      />
                    </template>
                  </v-list-item>
                </v-list>
              </template>
            </FilterMenu>

            <FilterMenu
              v-if="familySubsidyProgram.enable_stages && familySubsidyProgram.stages.length > 0"
              :active="!!filters.stage_id"
              classes="d-none d-md-inline-flex"
              title="Stage"
              paddingless
            >
              <template #card>
                <v-list
                  v-model:selected="filters.stage_id"
                  @update:selected="handleFilterChange()"
                >
                  <v-list-item
                    v-for="stage in familySubsidyProgram.stages"
                    :key="stage.id"
                    :title="stage.title"
                    :value="stage.id"
                  >
                    <template #prepend="{ isSelected }">
                      <v-checkbox-btn
                        :modelValue="isSelected"
                        false-icon="check_box_outline_blank"
                        tabindex="-1"
                        true-icon="check_box"
                      />
                    </template>
                  </v-list-item>
                </v-list>
              </template>
            </FilterMenu>

            <TeamFilter
              v-if="teams.length > 0"
              v-model="filters"
              :handleFilterChange="handleFilterChange"
              :processing="processing"
              :teams="teams"
            />

            <FilterMenu
              v-if="familySubsidyProgramLabels.length > 0"
              :active="filters.label_ids?.length > 0"
              classes="d-none d-md-inline-flex"
              title="Label"
              paddingless
            >
              <template #card>
                <v-autocomplete
                  v-model="filters.label_ids"
                  @update:model-value="handleFilterChange()"
                  :aria-label="$t('Filter by label name')"
                  :closable-chips="true"
                  :disabled="processing"
                  :items="familySubsidyProgramLabels"
                  :menu="true"
                  :placeholder="$t('Filter by label name')"
                  density="compact"
                  item-title="name"
                  item-value="id"
                  prepend-inner-icon="search"
                  variant="filled"
                  autofocus
                  chips
                  clearable
                  multiple
                >
                  <template #chip="{ item, props }">
                    <v-chip v-bind="props">
                      <template #prepend>
                        <v-avatar
                          :color="item.raw.color"
                          start
                        />
                      </template>
                      {{ item.raw.name }}
                    </v-chip>
                  </template>

                  <template #item="{ item, props }">
                    <v-list-item v-bind="props">
                      <template #prepend="{ isSelected }">
                        <v-checkbox-btn
                          :key="item.value"
                          :modelValue="isSelected"
                          :ripple="false"
                          tabindex="-1"
                        />
                      </template>

                      <template #append>
                        <v-avatar
                          :color="item.raw.color"
                          size="20"
                        />
                      </template>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </template>
            </FilterMenu>
          </v-col>

          <v-col class="d-flex justify-end">
            <template v-if="familySubsidyProgram?.stages?.length > 0">
              <v-btn
                v-show="selectedAnyItems"
                @click="openStageSelectionDialog()"
                class="me-3"
                color="primary"
              >
                <span v-t="'Update stage'" />
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </template>
    </v-toolbar>

    <v-container
      class="px-3 py-0"
      fluid
    >
      <v-card
        class="pa-0"
        border
        flat
        tile
      >
        <v-card-text class="pa-3">
          <v-card
            class="bb-1 bc-extra-light-gray mb-4"
            flat
            tile
          >
            <v-card-text class="pt-0 px-0 pb-3 fs-15">
              <v-row dense>
                <v-col class="d-flex align-center">
                  <template v-if="filterSummary">
                    <v-icon
                      icon="filter_alt"
                      start
                    />

                    <span
                      v-t="'Filtering by:'"
                      class="me-1"
                    />

                    <span class="fw-600">
                      {{ $t(filterSummary) }}
                    </span>

                    <span>.&nbsp;</span>
                  </template>

                  <span>
                    {{ $t('Displaying first') }} <strong>{{ collection.length }}</strong>
                    {{ $t('results') }}, {{ $t('out of') }} <strong>{{ total }}</strong>
                    {{ $t('total results') }}.
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-row
            v-if="familySubsidyProgram"
            dense
          >
            <v-col class="fw-600 fs-14 c-black">
              <div class="d-flex align-center fw-600 fs-14 c-black">
                <v-checkbox-btn
                  v-model="selectedAll"
                  aria-label="Select all applications"
                  class="d-inline-flex flex-none pa-0 ma-0 va-middle"
                  density="compact"
                  false-icon="check_box_outline_blank"
                  true-icon="check_box"
                />

                <v-btn
                  @click="toggleSort('groups.last_name')"
                  class="h-inherit pa-0 ma-0"
                  variant="text"
                >
                  <span
                    v-t="'Applicant'"
                    class="fs-14 fw-600 c-black"
                  />

                  <v-icon class="c-black">
                    {{
                      sortDirection['groups.last_name'] == 'desc'
                        ? 'arrow_drop_down'
                        : 'arrow_drop_up'
                    }}
                  </v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col class="ta-center">
              <v-btn
                @click="toggleSort('submitted_at')"
                class="h-inherit pa-0 ma-0"
                variant="text"
              >
                <span
                  v-t="'Date'"
                  class="fs-14 fw-600 c-black"
                />

                <v-icon class="c-black">
                  {{
                    sortDirection['submitted_at'] == 'desc' ? 'arrow_drop_down' : 'arrow_drop_up'
                  }}
                </v-icon>
              </v-btn>
            </v-col>

            <v-col class="ta-center">
              <span
                v-t="'Team'"
                class="fs-14 fw-600 c-black"
              />
            </v-col>

            <v-col class="ta-center">
              <v-btn
                @click="toggleSort('status')"
                class="h-inherit"
                variant="text"
              >
                <span
                  v-t="'Status'"
                  class="fs-14 fw-600 c-black"
                />

                <v-icon class="c-black">
                  {{ sortDirection['status'] == 'desc' ? 'arrow_drop_down' : 'arrow_drop_up' }}
                </v-icon>
              </v-btn>
            </v-col>

            <v-col
              v-if="familySubsidyProgram.enable_stages && familySubsidyProgram.stages.length > 0"
              class="ta-center"
            >
              <span
                v-t="'Stage'"
                class="fs-14 fw-600 c-black"
              />
            </v-col>

            <v-col
              v-if="familySubsidyProgramLabels.length > 0"
              class="ta-center"
            >
              <span
                v-t="'Labels'"
                class="fs-14 fw-600 c-black"
              />
            </v-col>

            <v-col class="fw-600 fs-14 c-black ta-center">
              {{ $t('Family ID') }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card
        v-for="(subsidy, index) in collection"
        :key="index"
        class="pointer bt-0 pa-0"
        border
        flat
        tile
      >
        <v-card-text class="pa-3">
          <v-row
            class="d-flex align-center"
            dense
          >
            <v-col class="c-black fs-14 fw-500">
              <div class="d-flex align-center fw-500 fs-14 c-black">
                <v-checkbox-btn
                  v-model="selectedItems[subsidy.id]"
                  :aria-label="$t('Select') + ' ' + subsidy.group_name"
                  class="d-inline-flex flex-none pa-0 ma-0 va-middle"
                  density="compact"
                  false-icon="check_box_outline_blank"
                  true-icon="check_box"
                />

                <router-link
                  :to="{ name: 'FamilySubsidyShow', params: { id: subsidy.id } }"
                  data-cy="subsidy-name"
                >
                  <span v-text="subsidy.group_name" />
                </router-link>
              </div>
            </v-col>

            <v-col class="c-black fs-14 fw-500 ta-center">
              <template v-if="subsidy.submitted_at">
                <LongDate
                  :date="subsidy.submitted_at"
                  short-month
                />
              </template>

              <template v-else>
                <span v-text="$t('Draft')" />
              </template>
            </v-col>

            <v-col class="c-black fs-14 fw-500 ta-center">
              <span v-text="teamName(subsidy)" />
            </v-col>

            <v-col class="c-black fs-14 fw-500 ta-center">
              <span v-text="subsidy.status" />
            </v-col>

            <v-col
              v-if="familySubsidyProgram.enable_stages && familySubsidyProgram.stages.length > 0"
              class="c-black fs-14 fw-500 ta-center"
            >
              <template v-if="subsidy.stage_id">
                <span v-text="stages[subsidy.stage_id]?.title" />
              </template>
            </v-col>

            <v-col
              v-if="familySubsidyProgramLabels.length > 0"
              class="c-black fs-14 fw-500 ta-center"
            >
              <LabelValue
                @edit="$refs.familySubsidyLabelDialog.open(subsidy)"
                :applied-labels="subsidy.relationships.labels.data"
                :disabled="processing"
              />
            </v-col>

            <v-col class="c-black fs-14 fw-500 ta-center">
              <span v-text="subsidy.group_external_id" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <template v-if="processing">
        <div class="mxw-800 mx-auto py-12 ta-center">
          <v-progress-circular
            color="primary"
            size="75"
            indeterminate
          />
        </div>
      </template>

      <template v-if="!processing && collection.length == 0">
        <NullState hide-new-button />
      </template>

      <v-pagination
        v-show="pages > 1"
        v-model="filters.page"
        :length="pages"
        :total-visible="8"
        class="mt-4"
      />

      <VerticalSpacer :min-height="60" />
    </v-container>

    <v-dialog
      v-model="updateStageDialogIsVisible"
      max-width="400"
      scrim="transparent"
    >
      <v-card
        v-if="updateStageDialogIsVisible"
        border
        flat
        tile
      >
        <v-card-title>
          {{ $t('Set stage') }}
        </v-card-title>

        <v-divider class="my-2" />

        <v-card-text class="pa-0">
          <v-list class="py-0">
            <v-list-item
              v-for="stage in familySubsidyProgram.stages"
              :key="stage.id"
              lines="two"
            >
              <v-list-item-title>
                {{ $t(stage.title) }}
              </v-list-item-title>

              <template #append>
                <v-hover v-slot="{ hover }">
                  <v-btn
                    @click="selectedStageId = stage.id"
                    :loading="processing"
                    color="grey-lighten-3"
                    data-cy="set-stage-button"
                    variant="outlined"
                    icon
                  >
                    <v-icon
                      v-if="hover || selectedStageId == stage.id"
                      color="primary-lighten-2"
                      icon="check"
                    />
                  </v-btn>
                </v-hover>
              </template>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider class="my-2" />

        <v-card-actions>
          <v-btn
            @click="updateStageDialogIsVisible = false"
            variant="text"
          >
            {{ $t('Cancel') }}
          </v-btn>

          <v-spacer />

          <v-btn
            @click="setSubsidyStage()"
            :disabled="!selectedStageId || processing"
            color="primary"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <LabelDialog
      @change="updateEditedSubsidyLabels"
      @close="loadFamilySubsidies()"
      ref="familySubsidyLabelDialog"
      :program-labels="familySubsidyProgramLabels"
      label-owner-type="FamilySubsidy"
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LabelDialog from '@/specialist/components/LabelDialog.vue';
import LabelValue from '@/specialist/components/LabelValue.vue';
import LongDate from '@/shared/components/LongDate.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import TeamFilter from '@/specialist/components/family_subsidy/TeamFilter.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import useTerms from '@/shared/composables/useTerms';
import NullState from '@/shared/components/NullState.vue';
import { computed, watch } from 'vue';
import { useStore } from 'vuex';

const routerHelper = useRouterHelper();
const store = useStore();
const terms = useTerms();

const filters = ref(defaultFilters());
const searchCounter = ref(0);
const selectedAll = ref(false);
const selectedItems = ref({});
const sort = ref(null);
const sortDirection = ref({
  'group.last_name': 'asc',
  submitted_at: 'desc',
  updated_at: 'desc',
  status: 'desc',
  priority: 'desc',
});
const statusOptions = ref([
  'In progress',
  'Submitted',
  'Reviewed',
  'Awaiting documents',
  'Withdrawn',
  'Approved',
  'Denied',
]);
const collection = ref([]);
const pages = ref(1);
const processing = ref(false);
const selectedStageId = ref(null);
const familySubsidyProgram = ref(null);
const familySubsidyPrograms = ref([]);
const familySubsidyProgramLabels = ref([]);
const total = ref(0);
const updateStageDialogIsVisible = ref(false);
const teams = ref([]);

const filterSummary = computed(() => {
  const appliedFilters = [];
  if (filters.value.status) appliedFilters.push(filters.value.status);
  if (filters.value.stage_id && familySubsidyProgram.value?.stages)
    appliedFilters.push(
      familySubsidyProgram.value.stages.find((stage) => stage.id === filters.value.stage_id)?.title,
    );
  if (filters.value.label_ids?.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const id of filters.value.label_ids) {
      appliedFilters.push(familySubsidyProgramLabels.value.find((label) => label.id === id).name);
    }
  }
  return appliedFilters.length > 0 ? appliedFilters.join(', ') : null;
});

const selectedAnyItems = computed(() => {
  return Object.values(selectedItems.value).some((item) => item === true);
});

const stages = computed(() => {
  if (familySubsidyProgram.value) {
    const hsh = {};
    // eslint-disable-next-line no-return-assign
    familySubsidyProgram.value.stages.forEach((stage) => (hsh[stage.id] = stage));
    return hsh;
  }
  return {};
});

watch(
  () => filters.value.page,
  async () => {
    await load();
  },
);

watch(
  () => filters.value.query,
  async (newVal, oldVal) => {
    if (newVal !== oldVal) {
      filters.value.page = 1;
      await loadFamilySubsidies();
    }
  },
);

watch(
  () => filters.value.team_ids,
  async (newVal, oldVal) => {
    if (newVal !== oldVal) {
      filters.value.page = 1;
      await loadFamilySubsidies();
    }
  },
);

watch(
  () => filters.value.label_ids,
  async (newVal, oldVal) => {
    if (newVal !== oldVal) {
      filters.value.page = 1;
      await loadFamilySubsidies();
    }
  },
);

watch(
  () => selectedAll.value,
  (newVal) => {
    setAll(newVal);
  },
);

onMounted(async () => {
  await load();
});

function defaultFilters() {
  if (store.state.filters.family_subsidy) {
    return store.state.filters.family_subsidy;
  }
  return {
    status: null,
    family_subsidy_program_id: null,
    filter: null,
    team_ids: null,
    page: 1,
    query: null,
    stage_id: null,
  };
}

async function handleFilterChange() {
  filters.value.page = 1;
  await loadFamilySubsidies();
}

async function load() {
  collection.value.splice(0);
  processing.value = true;

  const resp = await Api.organization.family_subsidy_program.index();
  familySubsidyPrograms.value = resp.data;

  if (filters.value.family_subsidy_program_id) {
    familySubsidyProgram.value = familySubsidyPrograms.value.find(
      (sp) => sp.id === filters.value.family_subsidy_program_id,
    );
  } else {
    familySubsidyProgram.value =
      familySubsidyPrograms.value[familySubsidyPrograms.value.length - 1];
    filters.value.family_subsidy_program_id = familySubsidyProgram.value.id;
  }

  await loadTeams();
  await loadFamilySubsidyProgramLabels();
  await loadFamilySubsidies();
}

async function loadFamilySubsidies() {
  filters.value.family_subsidy_program_id = familySubsidyProgram.value.id;
  filters.value.sort = sort.value;
  filters.value.sort_dir = sortDirection.value[sort.value];
  store.commit('setFilters', ['familySubsidy', filters.value]);
  await routerHelper.updateQuery({ ...filters.value });
  searchCounter.value += 1;
  const currentSearchCounter = searchCounter.value;

  const resp = await Api.organization.family_subsidy.index(filters.value);
  if (!searchCounter.value === currentSearchCounter) return;

  pages.value = parseInt(resp.headers['x-page-count'] || 0, 10);
  total.value = parseInt(resp.headers['x-total-count'] || 0, 10);
  collection.value = resp.data;
  selectedItems.value = {};
  processing.value = false;
}

async function loadFamilySubsidyProgramLabels() {
  const resp = await Api.organization.label.index({
    family_subsidy_program_id: familySubsidyProgram.value.id,
  });
  familySubsidyProgramLabels.value = resp.data;
}

async function loadTeams() {
  const resp = await Api.organization.team.index();
  teams.value = resp.data;
}

function openStageSelectionDialog() {
  selectedStageId.value = null;
  updateStageDialogIsVisible.value = true;
}

function setAll(val) {
  collection.value.forEach((subsidy) => {
    selectedItems.value[subsidy.id] = val;
  }, this);
}

async function setSubsidyStage() {
  processing.value = true;
  let resp;
  // eslint-disable-next-line no-restricted-syntax
  for (const id in selectedItems.value) {
    if (selectedItems.value[id]) {
      // eslint-disable-next-line no-await-in-loop
      resp = await Api.organization.family_subsidy.update({
        id,
        stage_id: selectedStageId.value,
      });
      const index = collection.value.indexOf(collection.value.find((item) => item.id === id));
      collection.value[index] = resp.data;
    }
  }
  updateStageDialogIsVisible.value = false;
  processing.value = false;
  selectedStageId.value = null;
  selectedAll.value = false;
  setAll(false);
}

function teamName(subsidy) {
  const team = teams.value.find((team) => team.id === subsidy.team_id)?.name;

  if (team) return team;

  return 'N/A';
}

async function toggleSort(field) {
  sort.value = field;
  Object.keys(sortDirection.value).forEach((key) => {
    if (field !== key) {
      sortDirection.value[key] = 'desc';
    }
  });
  if (sortDirection.value[field] === 'desc') {
    sortDirection.value[field] = 'asc';
  } else {
    sortDirection.value[field] = 'desc';
  }

  await load();
}

function updateEditedSubsidyLabels(subsidy) {
  const index = collection.value.findIndex((item) => item.id === subsidy.id);
  collection.value[index] = subsidy;
}
</script>
