<template>
  <div class="px-5 py-5">
    <!-- These options don't make sense to use right now -->
    <!-- There's no such thing as calendar.prev() or calendar.next() -->
    <!-- We can only slot #events in the month view, so changing the mode doesn't have much value -->
    <!-- Changing the weekdays doesn't make much sense since it makes the day numbers inaccurate -->
    <!-- <v-sheet
      class="d-flex"
      border
      rounded
      tile
    >
      <v-btn
        @click="$refs.calendar.prev()"
        class="ma-2"
        variant="text"
        icon
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-select
        v-model="type"
        :items="types"
        class="ma-2"
        density="compact"
        label="type"
        variant="outlined"
        hide-details
      />
      <v-select
        v-model="mode"
        :items="modes"
        class="ma-2"
        density="compact"
        label="event-overlap-mode"
        variant="outlined"
        hide-details
      />
      <v-select
        v-model="weekday"
        :items="weekdays"
        class="ma-2"
        density="compact"
        label="weekdays"
        variant="outlined"
        hide-details
      />
      <v-spacer />
      <v-btn
        @click="$refs.calendar.next()"
        class="ma-2"
        variant="text"
        icon
      >
        <v-icon>chevron_right</v-icon>
      </v-btn>
    </v-sheet> -->

    <v-sheet
      class="bg-super-light-blue"
      height="900"
    >
      <v-calendar
        v-model="value"
        @change="getEvents"
        @click:event="handleEventClick"
        ref="calendar"
        :event-color="getEventColor"
        :event-overlap-threshold="30"
        :events="events"
        :view-mode="type"
        :weekdays="weekday"
        class=""
      >
        <template #event="{ event }">
          <v-chip
            @click="handleEventClick(event)"
            color="grey-lighten-2"
            density="comfortable"
            width="100%"
            label
          >
            <v-badge
              :color="event.color"
              dot
              inline
            />

            {{ event.title }}
          </v-chip>
        </template>
      </v-calendar>
    </v-sheet>
  </div>
</template>

<script setup>
import { iso8601DateStringToLocalDateTime } from '@/shared/services/date-helper';
import { VCalendar } from 'vuetify/labs/VCalendar';

const props = defineProps({
  selected: {
    type: Array,
    default: () => [],
  },
  tasks: {
    type: Array,
    default: () => [],
  },
});

const colors = ref(['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1']);
// const mode = ref('stack');
// const modes = ref(['stack', 'column']);
const type = ref('month');
// const types = ref(['month', 'week', 'day', '4day'],);
const value = ref([new Date()]);
const weekday = ref([0, 1, 2, 3, 4, 5, 6]);
// const weekdays = ref([
//   { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
//   { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
//   { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
//   { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
// ]);

const emit = defineEmits(['open']);

const events = computed(() => {
  const taskEvents = [];

  if (!props.tasks?.length) return;

  props.tasks.forEach((task) => {
    if (task.due_date === null) return;
    const eventDate = iso8601DateStringToLocalDateTime(task.due_date);

    let eventLink = null;
    if (task.group_id) {
      eventLink = {
        name: 'GroupShow',
        params: { groupId: task.group_id },
      };
    } else if (task.provider_id) {
      eventLink = {
        name: 'ProviderShow',
        params: { providerId: task.provider_id },
      };
    }

    taskEvents.push({
      ...task,
      name: task.provider_name ? `${task.provider_name}: ${task.description}` : task.description,
      start: eventDate,
      end: eventDate,
      color: getColor(),
      timed: false,
      eventLink,
    });
  });

  return taskEvents;
});

function getEventColor(event) {
  return event.color;
}

// TODO: Use colors for something meaningful;
// For now showing random colors
function getColor() {
  return colors.value[Math.floor(colors.value.length * Math.random())];
}

function handleEventClick(event) {
  emit('open', event);
}
</script>

<style>
.v-calendar {
  background-color: transparent;
}
.v-calendar__container {
  background-color: white;
}
</style>
