<template>
  <span v-text="initials" />
</template>

<script>
import { toAcronym } from '@/shared/services/string_utils';

export default {
  compatConfig: { MODE: 2 },

  props: {
    value: {
      type: String,
      default: null,
    },
  },

  computed: {
    initials() {
      if (!this.value) return null;

      return toAcronym(this.value);
    },
  },
};
</script>
