<template>
  <v-card
    :border="outlined"
    :color="color"
    flat
    tile
  >
    <template v-if="title">
      <v-card-title
        class="fs-24"
        tag="h3"
      >
        {{ $t(title) }}
      </v-card-title>
      <v-divider />
    </template>

    <v-card-text class="pa-0">
      <v-card
        v-for="entry in entries"
        :key="entry[0]"
        class="mb-0 px-0"
        flat
        tile
      >
        <v-card-title
          v-if="entry[1].type == 'object'"
          class="fs-18 fw-500"
        >
          <span v-text="entry[1].title"></span>
        </v-card-title>
        <v-card-text v-if="entry[1].type == 'object'">
          <div
            v-for="(subentry, index) in Object.entries(entry[1].properties)"
            :key="index"
          >
            <SchemaControl
              v-model="modelValue[entry[0]][subentry[0]]"
              @update:model-value="handleModelUpdated"
              :depth="1"
              :field="subentry[0]"
              :schema="subentry[1]"
            />
            <v-divider
              v-if="index < Object.entries(entry[1].properties).length - 1"
              class="my-4"
            />
          </div>
        </v-card-text>
        <v-card-text v-else>
          <SchemaControl
            v-model="modelValue[entry[0]]"
            @update:model-value="handleModelUpdated"
            :depth="0"
            :field="entry[0]"
            :schema="entry[1]"
          />
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script setup>
import SchemaControl from '@/admin/components/schemas/SchemaControl.vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  color: {
    default: null,
    type: String,
  },
  controls: {
    default: false,
    type: Boolean,
  },
  flat: {
    default: false,
    type: Boolean,
  },
  outlined: {
    type: Boolean,
    default: false,
  },
  schema: {
    type: Object,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
});

const modelValue = defineModel();
const entries = Object.entries(props.schema.properties);

function handleModelUpdated() {
  emit('update:modelValue', modelValue.value);
}
</script>
