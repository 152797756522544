<template>
  <v-card
    class="fill-height"
    data-testid="assessment-messages"
    elevation="2"
    tile
  >
    <v-card-title class="bb-1 bc-very-light-grey fs-16 fw-600 pa-5 d-flex">
      <span
        v-t="'Messages'"
        tabindex="0"
      />
      <v-spacer />
      <v-btn
        @click="$emit('close')"
        aria-label="Close Messages"
        class="focus-visible"
        icon="close"
        variant="text"
      />
    </v-card-title>
    <v-card-text class="pt-4">
      <p
        v-if="messages.length == 0"
        v-t="'No messages sent yet.'"
        class="c-light-black fs-14"
        tabindex="0"
      />
      <AssessmentMessage
        v-for="message in messages"
        :key="message.id"
        :message="message"
        class="mb-3"
        tabindex="0"
      />

      <v-divider class="my-6" />

      <div
        class="w-100pc br-0 mb-3"
        style="height: 242px"
      >
        <div class="pa-2">
          <v-textarea
            v-model="newMessageText"
            aria-label="Type message"
            class="mb-2"
            data-cy="message-textarea"
            rows="4"
            variant="filled"
            hide-details
          />
          <v-btn
            @click="createMessage"
            :disabled="!newMessageText"
            :loading="processing"
            color="primary"
            block
          >
            Send message
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import API from '@/shared/mixins/api';
import AssessmentMessage from './AssessmentMessage.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    AssessmentMessage,
  },

  mixins: [API],

  props: {
    assessment: {
      type: Object,
      default: null,
    },
    businessId: {
      type: String,
      default: '',
    },
  },

  emits: ['close'],

  data() {
    return {
      messages: [],
      unreadMessages: [],
      newMessageText: null,
      processing: null,
      uploadedAttachments: [],
    };
  },

  async created() {
    await this.loadMessages();
  },

  methods: {
    async createMessage() {
      this.processing = true;

      const resp = await this.api.organization.message
        .create({
          business_id: this.businessId,
          assessment_id: this.assessment.id,
          text: this.newMessageText,
        })
        .catch((error) => this.$eventBus.$emit('error', error));

      this.processing = false;
      if (!resp?.data) return;

      this.newMessageText = null;
      this.messages.push(resp.data);
    },

    async loadMessages() {
      const resp = await this.api.organization.message.index({
        days: 10000,
        business_id: this.businessId,
        assessment_id: this.assessment.id,
      });
      this.messages = resp.data.reverse();
      const unreadMessagesFromOthers = this.messages.filter(
        (message) => !!message.read_at && message.member_id !== this.$store.state.profile.id,
      );
      this.unreadMessages = unreadMessagesFromOthers.filter(
        (message) => !message.meta.member_is_specialist,
      );
    },
  },
};
</script>
