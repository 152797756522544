<template>
  <v-container
    v-if="translations && organization"
    class="mxw-1200 pa-6"
  >
    <v-row>
      <v-col>
        <div class="fw-600 bc-h5">Translations</div>
      </v-col>
    </v-row>

    <v-row class="mb-3">
      <v-col>
        <div class="c-light-black fs-16 fw-500">
          This is a comprehensive list of all content shown on your sites. Updating content from
          this list, will update all sites. If translations are left blank, content will default to
          the primary language.
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-autocomplete
          v-model="organization.languages"
          @update:model-value="saveLanguages"
          :items="languages"
          item-title="text"
          variant="solo"
          chips
          deleteable-chips
          hide-details
          multiple
          return-object
        />
      </v-col>
    </v-row>

    <v-row class="d-flex align-center mb-3">
      <v-col>
        <v-text-field
          v-model="keyQuery"
          class="mxw-250"
          density="compact"
          prepend-inner-icon="search"
          variant="solo"
          hide-details
          rounded
        />
      </v-col>
      <v-col class="ta-right">
        <v-btn
          :href="downloadTranslations()"
          class="me-3"
          variant="text"
        >
          {{ $t('Download All Translations') }}
        </v-btn>
        <v-btn
          @click="uploadDialogIsVisible = true"
          class="me-3"
          variant="text"
        >
          {{ $t('Upload') }}
        </v-btn>
        <v-btn
          @click="translations.push({ language: selectedLanguage.split('-')[0] })"
          color="primary"
          size="large"
        >
          Add
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="d-flex align-center">
      <v-col
        class="mx-0 fw-600"
        cols="6"
      >
        English
      </v-col>
      <v-col
        class="mx-0 fw-600"
        cols="6"
      >
        <v-select
          v-model="selectedLanguage"
          :items="organization.languages"
          variant="underlined"
        />
      </v-col>
    </v-row>

    <TranslationForm
      v-for="translation in translations"
      :key="translation.id"
      :translation="translation"
    />
    <v-pagination
      v-if="pages > 1"
      v-model="page"
      :disabled="processing"
      :length="pages"
      :total-visible="8"
      rounded
    >
    </v-pagination>

    <v-btn
      v-show="translations.length > 0"
      @click="translations.push({ language: selectedLanguage.split('-')[0] })"
      class="mt-4"
      color="primary"
      size="large"
    >
      Add
    </v-btn>

    <v-dialog
      v-model="uploadDialogIsVisible"
      max-width="500"
    >
      <v-card>
        <v-card-title>Upload translations</v-card-title>
        <v-card-text>
          <p class="fs-16 c-black fw-400">
            Select a language below for your file. Your file should not contain any headers, and
            only two columns in CSV format. First column should be your English content, second
            should be your translated content.
          </p>

          <v-row>
            <v-col>
              <v-file-input
                v-model="file"
                accept=".csv"
                color="primary"
                label="Attach file"
                variant="filled"
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="createUpload()"
            :disabled="!file"
            :loading="processing"
            color="primary"
            block
          >
            {{ $t('Upload') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash';
import Api from '@/specialist/services/bright_finder';
import TranslationForm from '@/admin/components/TranslationForm.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    TranslationForm,
  },

  data() {
    return {
      file: null,
      keyQuery: null,
      languages: this.$a.assets.locales.map((locale) => ({
        text: locale.text,
        value: locale.value,
      })),
      organization: null,
      page: 1,
      pages: 1,
      processing: false,
      selectedLanguage: null,
      translations: null,
      uploadLanguage: null,
      uploadDialogIsVisible: false,
    };
  },

  created() {
    this.loadTranslations();
    this.loadOrganization();
  },

  computed: {
    selectedLanguageCode() {
      return this.selectedLanguage ? this.selectedLanguage.split('-')[0] : null;
    },
  },

  watch: {
    page() {
      this.loadTranslations();
    },

    keyQuery: _.debounce(function () {
      this.page = 1;
      this.loadTranslations();
    }, 300),

    selectedLanguage() {
      this.page = 1;
      this.loadTranslations();
    },
  },

  methods: {
    createUpload() {
      this.processing = true;
      const reader = new FileReader();
      reader.readAsText(this.file, 'UTF-8');
      // eslint-disable-next-line func-names
      reader.onload = (evt) => {
        Api.organization.translation.upload.create(
          { language: this.selectedLanguageCode, content: evt.target.result },
          () => {
            this.$eventBus.$emit('chime', 'File upload successful.');
            this.processing = false;
            this.uploadDialogIsVisible = false;
            this.loadTranslations();
          },
          (error) => {
            this.$eventBus.$emit('chime', error.response.data.errors.join('. '));
          },
        );
      };
    },

    downloadTranslations() {
      return Api.organization.translation.downloadUrl('All Translations.csv');
    },

    loadTranslations() {
      const params = {
        page: this.page,
        page_size: 100,
        language: this.selectedLanguage ? this.selectedLanguage.split('-')[0] : null,
        key: this.keyQuery === '' ? null : this.keyQuery, // case where you delete the "key" ends in empty String
      };

      this.processing = true;
      Api.organization.translation.index(params, (resp) => {
        this.translations = resp.data;
        this.pages = parseInt(resp.headers['x-page-count'] || 0, 10);
        this.total = parseInt(resp.headers['x-total-count'] || 0, 10);
        this.pageSize = parseInt(resp.headers['x-page-size'] || 0, 10);
        this.processing = false;
      });
    },

    loadOrganization() {
      Api.organization.get((resp) => {
        this.organization = resp.data;
        this.selectedLanguage = this.organization.languages[1].value;
      });
    },

    saveLanguages() {
      this.organization.languages = this.organization.languages.filter((ssl) =>
        this.languages.find((csl) => ssl.text === csl.text && ssl.value === csl.value),
      );
      Api.organization.update({ languages: this.organization.languages }, () => {
        this.$eventBus.$emit('chime', 'Saved');
      });
    },
  },
};
</script>
