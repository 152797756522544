<template>
  <div v-if="loaded">
    <BreadcrumbTitle
      :back-name="schemaName"
      name="Functions"
    />

    <NullState
      v-if="schemaFunctions.length == 0"
      @new="createDialog.open()"
      new-button-title="Add function"
    />

    <ResourceItem
      v-for="schemaFunction in schemaFunctions"
      :key="schemaFunction.id"
      :title="schemaFunction.name"
      :to="{ name: 'SchemaFunctionShow', params: { schemaFunctionId: schemaFunction.id } }"
      data-testid="schema-function"
    />

    <v-btn
      v-if="schemaFunctions.length > 0"
      @click="createDialog.open()"
      class="mt-4"
      color="primary"
    >
      {{ $t('Add function') }}
    </v-btn>

    <ResourceDialog
      @save="create"
      ref="createDialog"
      :fields="createFields"
      :processing="processing"
      title="New function"
    />
  </div>
</template>

<script setup>
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import ResourceItem from '@/shared/components/ResourceItem.vue';
import organizationApi from '@/specialist/services/bright_finder';
import { useRoute } from 'vue-router';

const route = useRoute();

const processing = ref(false);
const loaded = ref(false);
const schemaId = ref(null);
const schemaName = ref(null);
const schemaFunctions = ref([]);
const createDialog = ref(null);
const createFields = [{ value: 'name', text: 'Name' }];

onMounted(async () => {
  await load();
});

async function create(newValues) {
  processing.value = true;
  const resp = await organizationApi.organization.schema_function.create(
    schemaId.value,
    newValues.name,
  );
  processing.value = false;
  if (resp?.status != 201) return;

  await loadSchemaFunctions();
  createDialog.value.close();
}

async function load() {
  loaded.value = false;
  schemaId.value = route.params.schemaId;
  await loadSchema();
  await loadSchemaFunctions();
  loaded.value = true;
}

async function loadSchemaFunctions() {
  const { data } = await organizationApi.organization.schema_function.index(schemaId.value);
  schemaFunctions.value = data;
}

async function loadSchema() {
  const { data } = await organizationApi.organization.schema.get(schemaId.value);
  schemaName.value = data.name;
}
</script>
