<template>
  <v-container class="mxw-1200 pa-6">
    <TitleBar
      @create="newMessageTemplateDialog.open()"
      create-text="New template"
      name="Message templates"
      createable
    />

    <v-row>
      <v-col
        v-for="messageTemplate in messageTemplates"
        :key="messageTemplate.id"
        cols="12"
      >
        <v-card
          :to="{
            name: 'MessageTemplateShow',
            params: {
              messageTemplateId: messageTemplate.id,
            },
          }"
          border
        >
          <v-card-text>
            <v-row>
              <v-col class="fs-18 fw-600">
                {{ messageTemplate.name }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <VerticalSpacer />

    <ResourceDialog
      @save="create"
      ref="newMessageTemplateDialog"
      :fields="[{ text: 'Name', value: 'name' }]"
      title="Create new message template"
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import TitleBar from '@/shared/components/TitleBar.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';

const eventBus = useEventBus();
const router = useRouter();

const messageTemplates = ref(null);
const newMessageTemplateDialog = ref(false);

function create(messageTemplate) {
  Api.organization.messageTemplate.create(
    messageTemplate,
    (response) => {
      router.push({
        name: 'MessageTemplateShow',
        params: { messageTemplateId: response.data.id },
      });
    },
    (err) => {
      eventBus.chime(err.response.data.errors[0]);
    },
  );
}

function load() {
  Api.organization.messageTemplate.index({}, (response) => {
    messageTemplates.value = response.data;
  });
}

onMounted(load);
</script>
