export default {
  properties: {
    attendance: {
      title: 'Attendance',
      type: 'string',
    },
    family_subsidy: {
      title: 'Family subsidy',
      type: 'string',
    },
    family_subsidies: {
      title: 'Family subsidies',
      type: 'string',
    },
    form: {
      title: 'Form',
      type: 'string',
    },
    forms: {
      title: 'Forms',
      type: 'string',
    },
    program: {
      title: 'Program',
      type: 'string',
    },
    programs: {
      title: 'Programs',
      type: 'string',
    },
  },
};
