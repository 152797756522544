<template>
  <v-container v-if="summaryConfiguration">
    <TitleBar
      :back-route="{ name: 'SummaryConfigurationIndex' }"
      :name="summaryConfiguration.name"
      back-name="Summaries"
      flat
    />

    <StandardCard
      @click:action:save="saveFormula"
      @click:action:save-preview="saveFormula(true)"
      :actions="[
        { event: 'save', outlined: true, title: 'Save draft' },
        { event: 'save-preview', title: 'Save and preview' },
      ]"
      :processing="processing"
      title="Formula"
    >
      <v-row>
        <LabeledFormulaField v-model="draftFormula" />
      </v-row>
    </StandardCard>

    <StandardCard
      @click:action:preview="getPreview"
      :actions="[{ event: 'preview', title: 'Refresh preview' }]"
      :processing="processing"
      class="mt-4"
      title="Preview"
    >
      <v-row>
        <v-col>
          <template v-if="preview">
            <v-data-table
              :headers="headers"
              :items="preview"
              item-key="itemKey"
            />
          </template>

          <template v-else-if="error">
            <div class="px-4 py-4 bg-red c-white fs-16">
              {{ error }}
            </div>
          </template>
        </v-col>
      </v-row>
    </StandardCard>

    <DangerBar
      @destroy="destroy"
      @run="run"
      :other-actions="[{ text: 'Run', value: 'run' }]"
      :processing="processing"
      class="mt-4"
    />
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import DangerBar from '@/shared/components/DangerBar.vue';
import LabeledFormulaField from '@/shared/components/form/LabeledFormulaField.vue';
import StandardCard from '@/shared/components/StandardCard.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    DangerBar,
    LabeledFormulaField,
    StandardCard,
  },

  mixins: [API],

  data() {
    return {
      draftFormula: null,
      error: null,
      headers: [],
      preview: null,
      processing: false,
      summaryConfiguration: null,
    };
  },

  created() {
    this.load();
  },

  methods: {
    async destroy() {
      await this.api.organization.summary_configuration.destroy(this.summaryConfiguration);
      this.$router.replace({ name: 'SummaryConfigurationIndex' });
    },

    async getPreview() {
      this.preview = null;
      this.error = null;
      const resp = await this.api.organization.summary_configuration
        .preview(this.summaryConfiguration.id)
        .catch((error) => {
          this.error = error.response.data.errors[0];
        });
      if (resp?.status !== 200) return;

      this.headers = [
        {
          text: 'Row',
          value: 'itemKey',
          title: 'Key',
          align: 'start',
          key: 'itemKey',
        },
      ];
      this.headers = this.headers.concat(
        Object.keys(resp.data[0]).map((header) => ({
          text: header,
          title: header,
          align: 'start',
          sortable: true,
          key: header,
          value: header,
        })),
      );
      this.preview = resp.data.map((item, index) => ({ ...item, itemKey: index }));
    },

    async load() {
      this.summaryConfiguration = (
        await this.api.organization.summary_configuration.get(this.$route.params.id)
      ).data;
      this.draftFormula = this.summaryConfiguration.formula;
      this.getPreview();
    },

    async run() {
      const resp = await this.api.organization.summary_configuration.run(
        this.summaryConfiguration.id,
      );
      if (resp.status === 200) this.$eventBus.$emit('chime', 'Run started');
    },

    async saveFormula(refreshPreview) {
      this.processing = true;
      const resp = await this.api.organization.summary_configuration.update({
        id: this.summaryConfiguration.id,
        formula: this.draftFormula,
      });
      if (resp.status === 200) this.$eventBus.$emit('chime', 'Saved');
      if (refreshPreview) this.getPreview();
      this.processing = false;
    },
  },
};
</script>
