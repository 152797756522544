<template>
  <v-card
    class="fill-height"
    elevation="2"
    tile
  >
    <v-card-title class="bb-1 bc-very-light-grey fs-16 fw-600 pa-5 d-flex">
      <span>Notes</span>
      <v-spacer />
      <v-btn
        @click="$emit('close')"
        icon="close"
        variant="text"
      />
    </v-card-title>
    <v-card-text class="pt-4">
      <div
        v-for="note in notes"
        :key="note.id"
        class="mb-3"
      >
        <div class="bg-super-light-blue pa-3 fs-16 fw-500">
          <div v-text="note.text" />
        </div>
        <div
          v-text="[note.meta.member.first_name, note.meta.member.last_name].join(' ')"
          class="fs-12 c-light-black"
        />
      </div>

      <v-divider class="my-6" />

      <v-textarea
        v-model="newNoteText"
        variant="filled"
      />
      <v-btn
        @click="createNote"
        :disabled="processing"
        color="primary"
        block
      >
        Add note
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import Api from '@/specialist/services/bright_finder';

export default {
  compatConfig: { MODE: 3 },

  props: {
    grant: {
      type: Object,
      default: null,
    },
  },

  emits: ['close'],

  data() {
    return {
      newNoteText: null,
      notes: [],
      processing: null,
    };
  },
  created() {
    this.loadNotes();
  },

  methods: {
    createNote() {
      this.processing = true;
      Api.organization.note.create(
        { owner_id: this.grant.id, owner_type: 'Grant', text: this.newNoteText },
        (resp) => {
          this.newNoteText = null;
          this.notes.push(resp.data);
          this.processing = false;
        },
      );
    },

    loadNotes() {
      Api.organization.note.index({ owner_id: this.$route.params.id }, (resp) => {
        this.notes = resp.data;
      });
    },
  },
};
</script>
