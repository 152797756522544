<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card class="pa-6">
    <v-row dense>
      <v-col cols="12">
        <div class="fs-28 fw-600 c-black mb-3">Configurations</div>
      </v-col>
      <v-col
        class="mb-3"
        cols="12"
      >
        <div class="fs-18 fw-600 c-black">Edit your advanced configuration file.</div>
      </v-col>
      <v-col
        class="mb-3"
        cols="12"
      >
        <v-card>
          <prism-editor
            v-model="page_template.config"
            :highlight="highlighter"
            class="my-editor"
          />
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-btn
          @click="save()"
          :disabled="!valid"
          color="primary"
          size="x-large"
          rounded
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Api from '@/admin/services/bright_finder';
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere
// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

export default {
  compatConfig: { MODE: 2 },

  components: {
    PrismEditor,
  },

  props: {
    // eslint-disable-next-line vue/prop-name-casing
    site_template: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    page_template: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      valid: false,
    };
  },

  watch: {
    // eslint-disable-next-line func-names
    'page_template.config': function (newVal) {
      try {
        JSON.parse(newVal);
        this.valid = true;
      } catch (_error) {
        this.valid = false;
      }
    },
  },

  methods: {
    highlighter(code) {
      return highlight(code, languages.json); // languages.<insert language> to return html with markup
    },

    save() {
      const vm = this;
      vm.processing = true;
      Api.organization.siteTemplate.pageTemplate.update(
        vm.site_template.id,
        vm.page_template.id,
        { config: JSON.parse(vm.page_template.config), name: vm.page_template.name },
        () => {
          vm.$eventBus.$emit('chime', 'Configuration saved.');
        },
        (err) => {
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },
  },
};
</script>

<style>
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #fff;
  color: #38beb5;

  /* you must provide font-family font-size line-height. Example: */
  font-family:
    Fira code,
    Fira Mono,
    Consolas,
    Menlo,
    Courier,
    monospace;
  font-size: 12px;
  line-height: 1.5;
  padding: 10px;
}

.token.punctuation {
  color: #38beb5;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
