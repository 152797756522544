<template>
  <v-container v-if="collection">
    <TitleBar
      @create="$refs.newDialog.open({})"
      class="mb-4"
      name="API Keys"
      createable
    />

    <ApiKeyRow
      v-for="apiKey in collection"
      @destroy="load"
      @edit="$refs.newDialog.open(apiKey)"
      :key="apiKey.id"
      :api-key="apiKey"
      class="mb-2"
    />

    <NullState
      v-if="collection.length == 0"
      @new="$refs.newDialog.open({})"
      new-button-title="New API Key"
    />

    <NewButton
      v-if="collection.length > 0"
      @click="$refs.newDialog.open({})"
      class="mt-4"
      text="New API Key"
    />

    <ResourceDialog
      @save="save"
      ref="newDialog"
      :fields="fields"
      :processing="processing"
      title="New API Key"
      close-on-save
    />

    <v-pagination
      v-show="pages > 1"
      v-model="page"
      :length="pages"
      :total-visible="8"
      class="mt-4"
    />
  </v-container>
</template>

<script setup>
import Api from '@/admin/services/bright_finder';
import ApiKeyRow from '@/admin/components/ApiKeyRow.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { scrollTo } from 'vuetify/lib/composables/goto';

const collection = ref(null);
const eventBus = useEventBus();
const newDialog = ref(null);
const page = ref(1);
const pages = ref(null);
const permissions = ref([]);
const processing = ref(false);

const fields = computed(() => {
  return [
    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'Permissions',
      value: 'permissions',
      items: permissions.value,
      multiple: true,
    },
  ];
});

watch(page.value, () => {
  load();
  scrollTo(0);
});

onMounted(load);

function create(apiKey) {
  Api.admin.api_key.create(apiKey, () => {
    newDialog.value.close();
    load();
    processing.value = false;
  });
}
function load() {
  Api.admin.api_key.index({ page: page.value }, (resp) => {
    collection.value = resp.data;
    pages.value = parseInt(resp.headers['x-page-count'] || 0, 10);
  });

  Api.admin.api_permission.index((resp) => {
    permissions.value = resp.data;
  });
}
function save(apiKey) {
  processing.value = true;
  if (apiKey.id) {
    Api.admin.api_key.update(apiKey.id, apiKey, () => {
      load();
      eventBus.chime('Saved');
      processing.value = false;
    });
  } else {
    create(apiKey);
  }
}
</script>
