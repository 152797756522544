<template>
  <div
    :style="style"
    class="ms-2 me-2"
    data-testid="vertical-divider"
  />
</template>

<script setup>
const props = defineProps({
  color: { type: String, default: 'black' },
  height: { type: Number, default: 40 },
  opacity: { type: Number, default: 0.1 },
  width: { type: Number, default: 1 },
});

const style = computed(() => {
  return {
    width: `${props.width}px`,
    height: `${props.height}px`,
    backgroundColor: props.color,
    opacity: props.opacity,
  };
});
</script>
