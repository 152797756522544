export default {
  properties: {
    accept: {
      title: 'Accept',
      type: 'string',
    },
    accepted: {
      title: 'Accepted',
      type: 'string',
    },
    decline: {
      title: 'Decline',
      type: 'string',
    },
    declined: {
      title: 'Declined',
      type: 'string',
    },
    enroll: {
      title: 'Enroll',
      type: 'string',
    },
    enrolled: {
      title: 'Enrolled',
      type: 'string',
    },
    graduate: {
      title: 'Graduate',
      type: 'string',
    },
    graduated: {
      title: 'Graduated',
      type: 'string',
    },
    offer: {
      title: 'Offer',
      type: 'string',
    },
    offered: {
      title: 'Offered',
      type: 'string',
    },
    place: {
      title: 'Place',
      type: 'string',
    },
    placed: {
      title: 'Placed',
      type: 'string',
    },
    propose: {
      title: 'Propose',
      type: 'string',
    },
    proposed: {
      title: 'Proposed',
      type: 'string',
    },
    select: {
      title: 'Select',
      type: 'string',
    },
    selected: {
      title: 'Selected',
      type: 'string',
    },
    standby: {
      title: 'Standby',
      type: 'string',
    },
    unenroll: {
      title: 'Unenroll',
      type: 'string',
    },
    unenrolled: {
      title: 'Unenrolled',
      type: 'string',
    },
    waitlist: {
      title: 'Waitlist',
      type: 'string',
    },
    waitlisted: {
      title: 'Waitlisted',
      type: 'string',
    },
    withdraw: {
      title: 'Withdraw',
      type: 'string',
    },
    withdrawn: {
      title: 'Withdrawn',
      type: 'string',
    },
  },
  title: 'Terms Form Schema',
  type: 'object',
};
