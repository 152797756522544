<template>
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
    :sm="sm"
  >
    <v-row dense>
      <v-col
        v-if="label"
        :class="labelClass"
        cols="12"
      >
        {{ $t(label) }}
        <RequiredIndicator v-if="mandatory" />
      </v-col>

      <v-col
        v-if="description"
        class="fs-16 c-light-black my-1"
        cols="12"
      >
        <span>
          {{ $t(description) }}
        </span>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-text-field
          v-model="localValue"
          @update:model-value="handleUpdateModelValue"
          :append-inner-icon="appendIcon"
          :aria-label="ariaLabel"
          :autofocus="autofocus"
          :class="inputClass"
          :density="dense ? 'compact' : undefined"
          :disabled="locked || undefined"
          :label="$t(title)"
          :max="max"
          :message="$t(message)"
          :min="min"
          :readonly="readonly || undefined"
          data-cy="labeled-number-field"
          data-testid="labeled-number-field"
          type="number"
          variant="filled"
          hide-details
          round
          tile
        />
      </v-col>
    </v-row>

    <v-divider
      v-if="dividedBottom"
      class="mt-4 mb-2"
    />
  </v-col>
</template>

<script setup>
import propsToRefs from '@/shared/utils/propsToRefs';
import RequiredIndicator from '@/shared/components/RequiredIndicator.vue';
import useLabeledField from '@/shared/composables/useLabeledField';
import useSchematizedField from '@/shared/composables/useSchematizedField';

const attrs = useAttrs();
const emit = defineEmits(useLabeledField.emits);
defineExpose({ attrs, emit });

const localValue = defineModel({ type: undefined });

const props = defineProps({
  dividedBottom: {
    type: Boolean,
    default: false,
  },
  max: {
    type: Number,
    default: null,
  },
  min: {
    type: Number,
    default: 0,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: null,
  },
  ...useLabeledField.props,
  ...useSchematizedField.props,
});

const { appendIcon, ariaLabel, inputClass, labelClass, handleUpdateModelValue } = useLabeledField(
  ...propsToRefs(props, useLabeledField.paramKeys),
);

const { enabled, label, locked } = useSchematizedField(
  ...propsToRefs(props, useSchematizedField.paramKeys),
);
</script>
