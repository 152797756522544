<template>
  <v-dialog
    v-model="isVisible"
    fullscreen
  >
    <v-card>
      <v-toolbar
        border
        flat
      >
        <v-btn
          @click="isVisible = false"
          :aria-label="$t('Close')"
          variant="text"
          icon
        >
          <v-icon>close</v-icon>
        </v-btn>

        <v-toolbar-title> {{ $t('Assignment') }}: {{ assignment.name }} </v-toolbar-title>

        <v-spacer />

        <v-btn
          @click="destroy()"
          :loading="processing"
          class="me-6"
          color="red"
          variant="text"
        >
          {{ $t('Delete') }}
        </v-btn>

        <v-btn
          @click="save(true)"
          :loading="processing"
          color="primary"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-6">
        <v-row>
          <v-col cols="6">
            <v-row class="mb-4">
              <LabeledTextfield
                v-model="assignment.name"
                message="Name"
              />

              <LabeledTextarea
                v-model="assignment.text[lang]"
                message="Instructions"
                auto-grow
                filled
              />
            </v-row>
            <v-row dense>
              <LabeledSimpleSelect
                v-model="lang"
                :items="[
                  { text: 'English', value: 'en' },
                  { text: 'Spanish', value: 'es' },
                ]"
                class="mb-3"
                message="Language"
                filled
              />

              <LabeledTextfield
                v-model="assignment.assets[lang]"
                @click="$refs.assetFinder.open({})"
                class="mb-3"
                message="Content asset"
                clearable
                readonly
              />

              <AssetFinder
                @save="saveContentAsset($event)"
                ref="assetFinder"
                :asset-url="assignment.assets[lang]"
                :is-valid-selection="isAssetValid"
              />
            </v-row>
          </v-col>

          <v-col cols="6">
            <ContentPlayer
              v-if="assignment.assets[lang]"
              @update-video-progress="videoProgress = $event"
              :url="assignment.assets[lang]"
              :watched-video-progress="videoProgress"
            />

            <MarkdownContent
              :content="assignment.text[lang]"
              class="no-drag pa-6 fs-16 mnh-100"
            />
          </v-col>
        </v-row>

        <v-divider class="my-6" />

        <template v-if="course.restricted">
          <v-row>
            <div class="text-h5 mt-6 ml-3">
              {{ $t('Activities') }}
            </div>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-row>
                <LabeledTextfield
                  v-model="assignment.assets[`${lang}_homework`]"
                  @click="$refs.homeworkAssetFinder.open({})"
                  class="mb-3"
                  message="Homework asset"
                  clearable
                  readonly
                />

                <AssetFinder
                  @save="saveHomeworkAsset($event)"
                  ref="homeworkAssetFinder"
                  :asset-url="assignment.assets[`${lang}_homework`]"
                />
              </v-row>

              <v-row>
                <v-col>
                  <LabeledSwitch
                    v-model="assignment.required_homework_file_upload"
                    title="Required homework file upload"
                  />
                </v-col>

                <v-col>
                  <LabeledSwitch
                    v-model="assignment.enable_comments"
                    title="Enable comments"
                  />
                </v-col>
              </v-row>

              <v-row v-show="assignment.enable_comments">
                <v-col>
                  <LabeledNumber
                    v-model="assignment.required_comment_count"
                    message="Comments required"
                  />
                </v-col>

                <v-col>
                  <LabeledNumber
                    v-model="assignment.required_comment_reply_count"
                    message="Replies required"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="6">
              <ContentPlayer
                v-if="assignment.assets[`${lang}_homework`]"
                @update-video-progress="videoProgress = $event"
                :url="assignment.assets[`${lang}_homework`]"
                :watched-video-progress="videoProgress"
              />

              <MarkdownContent
                :content="assignment.text[`${lang}_homework`]"
                class="no-drag pa-6 fs-16 mnh-100"
              />
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder.js';
import AssetFinder from '@/specialist/components/AssetFinder.vue';
import ContentPlayer from '@/shared/components/learning/ContentPlayer.vue';
import LabeledNumber from '@/shared/components/form/LabeledNumber.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import {
  ALL_SUPPORTED_EXTENSIONS_SET,
  UNSUPPORTED_LMS_EXTENSIONS,
} from '@/shared/assets/constants';
import useEventBus from '@/shared/composables/useEventBus';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const lang = 'en';

const eventBus = useEventBus();
const route = useRoute();
const { t } = useI18n();

defineProps({
  course: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['destroy']);

defineExpose({ open });

const assignment = ref({ assets: {}, text: {} });
const isVisible = ref(false);
const processing = ref(false);
const videoProgress = ref(0);

function destroy() {
  if (confirm('Are you sure you want to delete this assignment?')) {
    processing.value = true;
    Api.organization.assignment.destroy(
      route.params.lessonId,
      assignment.value.id,
      () => {
        emit('destroy');
        isVisible.value = false;
        processing.value = false;
      },
      (error) => {
        eventBus.chime(error.response.data.errors[0]);
      },
    );
  }
}

function open(assignmentToEdit) {
  assignment.value = assignmentToEdit;
  isVisible.value = true;
}

async function save(close = false) {
  processing.value = true;
  await Api.organization.assignment.update(
    route.params.lessonId,
    assignment.value.id,
    assignment.value,
    (response) => {
      processing.value = false;
      if (close) isVisible.value = false;

      assignment.value = response.data;
    },
    (error) => {
      processing.value = false;
      eventBus.chime(error.response.data.errors[0]);
    },
  );
}

function isAssetValid(asset) {
  const mimeType = asset?.file?.metadata?.mime_type;

  const errorMessage = 'This asset cannot be used in Assignments.';

  if (!mimeType) return t(errorMessage);

  const extension = mimeType.split('/').pop();

  if (!ALL_SUPPORTED_EXTENSIONS_SET.has(extension) || UNSUPPORTED_LMS_EXTENSIONS.has(extension)) {
    return t(errorMessage);
  }

  return true;
}

async function saveContentAsset(asset) {
  assignment.value.assets[lang] = asset;
  await save();
}

async function saveHomeworkAsset(asset) {
  assignment.value.assets[`${lang}_homework`] = asset;
  await save();
}
</script>
