<template>
  <v-container
    v-if="site && page"
    class="mxw-1200"
  >
    <v-row class="mb-1">
      <v-col>
        <div
          @click="$router.push({ name: 'SiteIndex' })"
          class="fw-600 bc-h5 d-inline-block me-2 bc-link-hover"
        >
          {{ $t('Sites') }}
        </div>

        <div class="d-inline-block me-2">
          <v-icon
            class="fs-26 c-black va-sub"
            icon="chevron_right"
          />
        </div>

        <div
          @click="
            $router.push({
              name: 'SiteShow',
              params: {
                siteId: site.id,
              },
            })
          "
          v-text="site.name"
          class="fw-600 bc-h5 bc-link-hover d-inline-block me-2"
        ></div>

        <div class="fw-600 bc-h5 d-inline-block me-2">
          <v-icon
            class="c-black"
            icon="chevron_right"
          />
        </div>

        <div
          v-text="page.name"
          class="fw-600 bc-h5 d-inline-block me-2"
        ></div>
      </v-col>
    </v-row>

    <v-card
      color="transparent"
      flat
    >
      <v-card-text class="px-0">
        <SchemaForm
          v-if="page"
          v-model="page.config"
          @update:model-value="changed = true"
          :schema="page.meta.schema"
          class="mb-4"
          title="Configurations"
          flat
          outlined
        />
      </v-card-text>
    </v-card>

    <SaveBar
      @cancel="load"
      @save="save"
      :model-value="changed"
      :processing="processing"
      flat
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import SchemaForm from '@/admin/components/schemas/SchemaForm.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute } from 'vue-router';

const props = defineProps({
  site: {
    type: Object,
    default: null,
  },
});

const eventBus = useEventBus();
const route = useRoute();

const changed = ref(false);
const page = ref(null);
const processing = ref(false);

watch(
  () => route.params?.pageId,
  () => load(),
);

onMounted(() => load());

function load() {
  if (route.params.siteId && route.params.pageId) {
    page.value = null;
    changed.value = false;
    Api.organization.site.page.get(route.params.siteId, route.params.pageId, (response) => {
      page.value = response.data;
    });
  }
}

function save() {
  processing.value = true;
  Api.organization.site.page.update(
    props.site.id,
    page.value.id,
    {
      config: page.value.config,
      name: page.value.name,
    },
    () => {
      changed.value = false;
      processing.value = false;
      eventBus.chime('Configuration saved');
    },
    (error) => {
      processing.value = false;
      eventBus.chime(error);
    },
  );
}
</script>
