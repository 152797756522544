<template>
  <ResourceDialog
    @save="save"
    ref="dialog"
    :max-width="600"
    :save-button-disabled="!validList"
    title="Add list"
  >
    <template #form>
      <LabeledControl :message="$t('New list')">
        <div>
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                v-model="draft.dataType"
                :items="dataTypes"
                item-title="data_type"
                item-value="data_type"
                prefix="Table:"
                variant="filled"
                chips
                hide-details
              />
            </v-col>

            <template v-if="draft.dataType">
              <v-col cols="12">
                <v-autocomplete
                  v-model="draft.field.value"
                  :items="fields[draft.dataType]"
                  prefix="Field:"
                  variant="filled"
                  chips
                  hide-details
                />
              </v-col>
            </template>
          </v-row>
        </div>
      </LabeledControl>
    </template>
  </ResourceDialog>
</template>

<script setup>
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import LabeledControl from '@/shared/components/form/LabeledControl.vue';

defineProps({
  dataTypes: {
    type: Array,
    default: null,
  },
  fields: {
    type: Object,
    default: null,
  },
});

defineExpose({
  open,
});

const emit = defineEmits(['column']);

const draft = ref({
  field: { value: null },
  list: true,
  dataType: null,
  newVal: false,
});

const dialog = ref(null);

const validList = computed(() => {
  return draft.value && draft.value.dataType && draft.value.field.value;
});

function close() {
  dialog.value.close();
}

function getDefaultColumn() {
  return blankColumn();
}

function blankColumn() {
  return {
    field: { value: null },
    list: true,
    dataType: null,
    newVal: false,
  };
}

function open() {
  draft.value = getDefaultColumn();
  dialog.value.open();
}

function save() {
  draft.value.newVal = true;
  emit('column', draft.value);
  close();
}
</script>
