<template>
  <v-card
    class="py-3 text-body-2"
    color="grey-lighten-2"
    variant="outlined"
  >
    <div class="px-3 pb-2 d-flex justify-end align-center">
      <v-btn
        v-if="emailCommunication"
        @click="handlePreviewClick(emailCommunication)"
        aria-label="Preview Email"
        color="black"
        size="x-small"
        variant="text"
      >
        <v-icon class="material-icons-outlined">mail</v-icon>
      </v-btn>
      <v-btn
        v-if="smsCommunication"
        @click="handlePreviewClick(smsCommunication)"
        aria-label="Preview SMS"
        color="black"
        size="x-small"
        variant="text"
      >
        <v-icon class="material-icons-outlined">sms</v-icon>
      </v-btn>
    </div>
    <v-table
      class="table"
      density="compact"
    >
      <tbody>
        <tr
          v-for="row in rowData"
          :key="row.label"
          class="h-30"
        >
          <td class="align-content-start border-0 px-4 font-weight-bold">{{ row.label }}</td>
          <td class="align-content-start border-0 px-4">{{ row.value }}</td>
        </tr>
      </tbody>
    </v-table>
  </v-card>
</template>

<script setup>
import useSegment from '@/shared/composables/analytics/useSegment';
import { computed } from 'vue';

const segment = useSegment();

const emit = defineEmits(['preview-email', 'preview-sms']);

const props = defineProps({
  communication: {
    type: Object,
    required: true,
  },
});

const rowData = computed(() => {
  const locale = navigator.languages?.length ? navigator.languages[0] : 'en-US';

  return [
    {
      label: 'Sent at',
      value: new Date(props.communication.sent_at).toLocaleString(locale, {
        dateStyle: 'long',
        timeStyle: 'short',
      }),
    },
    props.communication.context.title
      ? {
          label: 'Title',
          value: props.communication.context.title,
        }
      : null,
    emailCommunication.value
      ? {
          label: 'Subject',
          value: emailCommunication.value.subject,
        }
      : null,
    props.communication.context.event_trigger
      ? {
          label: 'Event trigger',
          value: props.communication.context.event_trigger,
        }
      : null,
    props.communication.context.event_value
      ? {
          label: 'Event value',
          value: props.communication.context.event_value,
        }
      : null,
  ].filter(Boolean);
});

const emailCommunication = computed(() => findCommunicationType('SentEmail'));
const smsCommunication = computed(() => findCommunicationType('SentSMS'));

function findCommunicationType(communicationType) {
  return props.communication.communications.find(
    (communication) => communication.communication_type === communicationType,
  );
}

async function handlePreviewClick(communication) {
  const emitEvent =
    communication.communication_type === 'SentEmail' ? 'preview-email' : 'preview-sms';

  emit(emitEvent, communication.communication_id);

  await segment.track('Subsidy Communication Previewed', {
    communicationId: communication.communication_id,
    communicationType: communication.communication_type,
    contextId: props.communication.context.context_id,
    contextType: props.communication.context.context_type,
  });
}
</script>

<style scoped>
.table {
  font-size: 16px; /* TODO: Address in https://bridgecare.atlassian.net/browse/CARE-940 */
}
</style>
