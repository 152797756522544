<template>
  <v-card
    class="mb-4"
    border
    tile
  >
    <v-card-title>
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col class="fs-22 fw-600">
          {{ $t('Funding') }}
        </v-col>
        <v-col class="ta-right">
          <v-btn
            @click="createGrantAward"
            :loading="processing"
            color="primary"
            prepend-icon="add"
          >
            {{ $t('Draft award') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider class="mb-0" />

    <NullState
      v-if="grantAwards.length == 0"
      @new="createGrantAward"
      :processing="processing"
      class="mt-6"
      new-button-title="Draft award"
    />

    <v-card-text>
      <GrantAwardCard
        v-for="(grantAward, index) in grantAwards"
        @destroy="load()"
        :key="index"
        :grant-award="grantAward"
        :grant-program="grantProgram"
        class="mb-4"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import GrantAwardCard from '@/specialist/components/grant/GrantAwardCard.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const props = defineProps({
  grant: {
    type: Object,
    default: null,
  },
  grantProgram: {
    type: Object,
    default: null,
  },
});

const processing = ref(false);
const grantAwards = ref([]);

onMounted(() => load());

function createGrantAward() {
  processing.value = true;
  Api.organization.grant_award.create(
    { grant_id: props.grant.id },
    (resp) => {
      processing.value = false;
      grantAwards.value.push(resp.data);
    },
    (err) => {
      processing.value = false;
      eventBus.error(err);
    },
  );
}

function load() {
  Api.organization.grant_award.index({ grant_id: props.grant.id }, (resp) => {
    grantAwards.value = resp.data;
  });
}
</script>
