<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card
    class="px-2 py-4"
    data-cy="row"
    flat
    tile
  >
    <v-row class="d-flex align-center">
      <v-col
        :md="waitlist ? 2 : 4"
        class="d-flex align-center"
        cols="12"
      >
        <div class="me-1 px-1">
          <v-checkbox
            v-if="!hideSelector"
            @click.stop="$emit('check')"
            :value="er.checked"
            class="mt-0 pt-0"
            data-cy="checkbox"
            hide-details
          />
        </div>
        <span
          v-text="er.meta.child.name"
          class="fs-16 fw-500"
        />
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <span v-text="er.meta.child.dob" />
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <span v-text="[er.meta.group.home_address, er.meta.group.home_city].join(' ')" />
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <span>
          {{ $t(getStatusText(er.status)) }}
        </span>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <span v-text="americanDate(er.status_updated_at)" />
      </v-col>
      <v-col
        v-if="waitlist"
        cols="12"
        md="2"
      >
        <template v-if="er.status == 'Waitlisted'">
          <v-select
            v-model="er.waitlist_number"
            @click.stop=""
            @update:model-value="$emit('waitlist', $event)"
            :items="waitlistNumbers"
            class="mxw-125"
            density="compact"
            variant="filled"
            hide-details
            readonly
            rounded
          />
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Statuses from '@/shared/mixins/statuses';

export default {
  compatConfig: { MODE: 2 },

  mixins: [Statuses],

  props: {
    er: {
      type: Object,
      default: null,
    },
    hideSelector: {
      type: Boolean,
      default: false,
    },
    waitlist: {
      default: true,
      type: Boolean,
    },
  },

  emits: ['check', 'waitlist'],

  data() {
    return {
      waitlistNumbers: [...Array(200).keys()].map((index) => index + 1),
    };
  },

  methods: {
    americanDate(dateString) {
      if (dateString === null) {
        return '';
      }

      const date = new Date(dateString);

      const MM = date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
      const dd = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      const yyyy = date.getFullYear();

      return `${MM}/${dd}/${yyyy}`;
    },
  },
};
</script>
