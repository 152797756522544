<template>
  <div>
    <ResourceDialog
      @save="$emit('save', currentSubsidy)"
      ref="editorDialog"
      :max-width="600"
      :processing="processing"
      cancel-button-text="Ignore"
      save-button-color="red"
      save-button-text="Remove alert"
      title="Warning"
      close-on-save
    >
      <template #form>
        <div>
          <v-row class="d-flex mb-4">
            <v-col cols="1">
              <router-link
                :to="{ name: 'SubsidyShow', params: { id: currentSubsidy.id } }"
                target="_blank"
                tracked
              >
                <v-icon
                  class="fs-24"
                  color="primary"
                >
                  open_in_new
                </v-icon>
              </router-link>
            </v-col>

            <v-col class="d-flex align-center">
              <p class="mb-0">
                <router-link
                  :to="{ name: 'SubsidyShow', params: { id: currentSubsidy.id } }"
                  target="_blank"
                  tracked
                >
                  <span v-t="'This application'" />
                </router-link>
                has {{ duplicateSubsidies.length }} likely duplicate{{
                  duplicateSubsidies.length === 1 ? '' : 's'
                }}.
              </p>
            </v-col>
          </v-row>

          <div
            v-for="(subsidy, index) in duplicateSubsidies"
            :key="index"
          >
            <v-divider />

            <v-row class="d-flex my-2">
              <v-col>
                <div>
                  <p
                    v-if="subsidy.created_by"
                    class="fs-16 mb-0"
                  >
                    {{ $t('Created by') + ' ' + subsidy.created_by }}
                  </p>
                  <p class="fs-16 mb-3">
                    {{ $t('Created on') }} <LongDateTime :date="subsidy.created_at" />
                  </p>
                </div>

                <div v-if="subsidy.submitted_at">
                  <p
                    v-if="subsidy.submitted_by"
                    class="fs-16 mb-0"
                  >
                    {{ $t('Submitted by') + ' ' + subsidy.submitted_by }}
                  </p>
                  <p class="fs-16 mb-0">
                    {{ $t('Submitted on') }} <LongDateTime :date="subsidy.submitted_at" />
                  </p>
                </div>
                <div v-else>
                  <p
                    v-if="subsidy.status"
                    class="fs-16 mb-0"
                  >
                    {{ $t('Status') + ': ' + subsidy.status }}
                  </p>
                  <p class="fs-16 mb-0">
                    {{ $t('Last updated on') }} <LongDateTime :date="subsidy.updated_at" />
                  </p>
                </div>
              </v-col>

              <v-col
                class="d-flex align-center"
                cols="1"
              >
                <router-link
                  :to="{ name: 'SubsidyShow', params: { id: subsidy.id } }"
                  target="_blank"
                  tracked
                >
                  <v-icon
                    class="fs-24"
                    color="primary"
                  >
                    open_in_new
                  </v-icon>
                </router-link>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>
    </ResourceDialog>
  </div>
</template>

<script>
import LongDateTime from '@/shared/components/LongDateTime.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LongDateTime,
    ResourceDialog,
  },

  props: {
    processing: Boolean,
  },

  emits: ['save'],

  data() {
    return {
      currentSubsidy: {},
      duplicateSubsidies: [],
    };
  },

  methods: {
    close() {
      this.$refs.editorDialog.close();
    },

    open(currentSubsidy, duplicateSubsidies) {
      this.currentSubsidy = currentSubsidy;
      this.duplicateSubsidies = duplicateSubsidies;

      this.$refs.editorDialog.open();
    },
  },
};
</script>
