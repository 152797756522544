<template>
  <div class="mx-3">
    <v-row>
      <v-col class="d-flex justify-end">
        <v-btn
          @click="$refs.lessonDialog.open({})"
          color="primary"
          prepend-icon="add"
        >
          {{ $t('New lesson') }}
        </v-btn>
      </v-col>
    </v-row>

    <NullState
      v-if="lessons.length === 0"
      title="No lessons"
    />

    <v-row
      v-for="(lesson, index) in lessons"
      :key="lesson.id"
      class="mt-0"
    >
      <v-col>
        <v-card
          :ripple="false"
          :to="{
            name: 'LessonShow',
            params: {
              courseId: $route.params.courseId,
              lessonId: lesson.id,
            },
          }"
          class="pa-5"
          border
          flat
          tile
        >
          <v-row
            class="d-flex align-center"
            dense
          >
            <v-col cols="8">
              <div
                v-text="[index + 1, lesson.name].join('. ')"
                :id="`lesson-${lesson.id}`"
                class="fs-18 fw-600 constrained"
                data-testid="lesson-name"
              />
            </v-col>

            <v-col
              class="ta-right"
              cols="4"
            >
              <v-btn
                @click.stop.prevent="reorder(index, index + -1)"
                :aria-describedby="`lesson-${lesson.id}`"
                :disabled="!(lessons.length > 0) || index === 0"
                :loading="processing"
                aria-label="Order lesson earlier"
                class="me-3 text-decoration-none"
                icon="expand_less"
                variant="outlined"
              />

              <v-btn
                @click.stop.prevent="reorder(index, index + 1)"
                :aria-describedby="`lesson-${lesson.id}`"
                :disabled="!(lessons.length > index + 1)"
                :loading="processing"
                aria-label="Order lesson later"
                class="text-decoration-none"
                icon="expand_more"
                variant="outlined"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <ResourceDialog
      @save="createLesson"
      ref="lessonDialog"
      :fields="[
        {
          text: 'Name',
          value: 'name',
          required: true,
        },
      ]"
      title="Create new lesson"
    />
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';

const eventBus = useEventBus();
const router = useRouter();
const route = useRoute();

const props = defineProps({
  initialCourse: {
    type: Object,
    default: null,
  },
});

const course = ref(null);
const lessons = ref([]);
const processing = ref(false);

function createLesson(newLesson) {
  processing.value = true;
  Api.organization.lesson.create(
    route.params.courseId,
    { name: newLesson.name },
    (response) => {
      router.push({
        name: 'LessonShow',
        params: {
          courseId: route.params.courseId,
          lessonId: response.data.id,
        },
      });
    },
    (error) => {
      eventBus.chime(error.response.data.errors[0]);
      processing.value = false;
    },
  );
}

function load() {
  course.value = props.initialCourse;
  loadLessons();
}

function loadLessons() {
  processing.value = true;

  Api.organization.lesson.index(route.params.courseId, (response) => {
    lessons.value = response?.data;
    processing.value = false;
  });
}

function reorder(fromIndex, toIndex) {
  const element = course.value.lesson_sequence[fromIndex];
  course.value.lesson_sequence.splice(fromIndex, 1);
  course.value.lesson_sequence.splice(toIndex, 0, element);

  save();
}

function save() {
  processing.value = true;

  Api.organization.course.update(
    route.params.courseId,
    course.value,
    (response) => {
      course.value = response.data;
      loadLessons();
    },
    (error) => {
      eventBus.chime(error.response.data.errors[0]);
      processing.value = false;
    },
  );
}

onMounted(load);
</script>
