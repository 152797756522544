<template>
  <v-card
    data-testid="notes"
    border
    flat
    tile
  >
    <v-card-title>{{ $t('Notes') }}</v-card-title>
    <v-divider />
    <v-card-text>
      <v-row class="fs-16 mb-4">
        <LabeledTextarea
          v-model="groupNotes"
          @input="changed = true"
          :readonly="!canEdit"
          :schema-id="group.schema_id"
          field="notes"
        />
      </v-row>
    </v-card-text>

    <SaveBar
      @cancel="cancel()"
      @save="save()"
      :model-value="changed"
      :processing="processing"
    />
  </v-card>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const store = useStore();

const props = defineProps({
  group: {
    type: Object,
    default: null,
  },
});

const changed = ref(false);
const groupNotes = ref(props.group.notes);
const processing = ref(false);

const canEdit = computed(() => {
  return store.state.profile.org_groups_edit;
});

function cancel() {
  groupNotes.value = props.group.notes;
  changed.value = false;
}

function save() {
  processing.value = true;
  api.organization.group.update(
    props.group.id,
    { notes: groupNotes.value },
    () => {
      changed.value = false;
      processing.value = false;
      eventBus.chime('Notes saved');
    },
    (err) => {
      processing.value = false;
      eventBus.error(err);
    },
  );
}
</script>
