<template>
  <v-card-text class="pt-0">
    <div
      v-for="(source, index) in displayedFundingSources"
      :key="index"
      :data-testid="`funding-source-${source.name}`"
      class="mt-2"
    >
      <div class="d-flex align-start mb-0">
        <v-icon
          v-if="hasCriteriaResults(source)"
          :color="getSourceIconColor(source)"
          :data-icon="getSourceIconName(source)"
          class="me-2"
          data-testid="eligibility-icon"
          size="20"
        >
          {{ getSourceIconName(source) }}
        </v-icon>
        <div>
          <div
            v-t="source.name"
            :aria-label="[$t('Funding source'), source.name].join(': ')"
            class="fs-18 fw-600 mb-0"
            tabindex="0"
          />
        </div>
      </div>

      <div
        v-for="result in source.criteria_results"
        :key="result.message"
        :aria-label="
          [$t('Criteria'), $t(result.passes ? 'passing' : 'failing'), result.message].join(', ')
        "
        class="d-flex align-center mb-1 ms-4 pl-3"
        tabindex="0"
      >
        <v-icon
          v-if="hasCriteriaResults(source) && !allResultsSame(source)"
          :color="result.passes ? 'green-500' : 'red'"
          :data-icon="result.passes ? 'check_circle' : 'cancel'"
          class="me-2"
          data-testid="criteria-icon"
          size="20"
        >
          {{ result.passes ? 'check_circle' : 'cancel' }}
        </v-icon>
        <span
          v-t="result.message"
          class="fs-16"
        />
      </div>

      <template v-if="index + 1 < displayedFundingSources.length">
        <div class="px-2">
          <v-divider class="mt-4 mb-3" />
        </div>
      </template>
    </div>
  </v-card-text>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    fundingSources: {
      default: () => [],
      type: Array,
    },
    name: {
      default: null,
      type: String,
    },
    projectedEligibility: {
      type: Boolean,
      default: null,
    },
  },

  computed: {
    displayedFundingSources() {
      if (this.projectedEligibility) {
        return this.fundingSources.filter((fs) => fs.criteria_results.every((cr) => cr.passes));
      }

      return this.fundingSources;
    },
  },
  methods: {
    hasCriteriaResults(source) {
      return source.criteria_results && source.criteria_results.length > 0;
    },
    allResultsSame(source) {
      if (!this.hasCriteriaResults(source)) return true;
      return source.criteria_results.every(
        (result) => result.passes === source.criteria_results[0].passes,
      );
    },
    getSourceIconColor(source) {
      if (!this.hasCriteriaResults(source)) return 'grey';
      if (this.allResultsSame(source)) {
        return source.criteria_results[0].passes ? 'green-500' : 'red';
      }
      return 'red'; // If mixed results, show as failing
    },
    getSourceIconName(source) {
      if (!this.hasCriteriaResults(source)) return 'help';
      if (this.allResultsSame(source)) {
        return source.criteria_results[0].passes ? 'check_circle' : 'cancel';
      }
      return 'cancel'; // If mixed results, show as failing
    },
  },
};
</script>
