<template>
  <div class="fill-height">
    <template v-if="loaded">
      <v-toolbar
        class="bc-outlined-gray bb-1"
        elevation="0"
        style="z-index: 1; left: var(--v-layout-left); right: 0; width: auto"
        absolute
      >
        <v-row class="d-flex align-center px-4">
          <v-col
            class="d-flex align-center"
            cols="8"
            md="8"
          >
            <v-btn
              v-if="backRoute"
              :to="backRoute"
              class="me-3"
              variant="text"
              icon
            >
              <v-icon
                class="c-black"
                icon="west"
                size="28"
              />
            </v-btn>

            <div
              data-cy="page-title"
              data-testid="page-title"
            >
              <span class="d-inline-block fs-22 fw-600">
                {{ caseRecord.name }}
              </span>
            </div>

            <v-chip
              class="ms-2"
              color="primary"
              size="large"
              variant="flat"
            >
              {{ caseRecord.status }}
            </v-chip>

            <div
              v-if="labels.length > 0"
              class="ms-3"
            >
              <LabelValue
                @edit="$refs.labelDialog.open(caseRecord)"
                :applied-labels="caseRecord.relationships.labels.data"
                :disabled="processing"
              />
            </div>

            <v-tooltip
              v-if="caseType == 'Form'"
              location="bottom"
            >
              <template #activator="{ props }">
                <v-btn
                  @click="toggleLock"
                  v-bind="props"
                  class="ms-2"
                  color="primary"
                  data-cy="lock-button"
                  size="small"
                  variant="text"
                  icon
                >
                  <v-icon>{{ caseRecord.locked ? 'lock' : 'lock_open' }}</v-icon>
                </v-btn>
              </template>
              <span>
                {{
                  caseRecord.locked
                    ? $t(`The ${props.caseType} is locked. The applicant cannot make changes.`)
                    : $t('The application is unlocked. The applicant can make changes.')
                }}
              </span>
            </v-tooltip>
          </v-col>

          <v-col
            class="d-flex align-center justify-end"
            cols="4"
            md="4"
          >
            <v-btn-toggle
              v-model="rightBarTab"
              class="me-3"
              selected-class="bg-primary c-white"
              divided
              tile
            >
              <v-btn
                aria-label="Reviews"
                value="reviews"
                variant="outlined"
                icon
              >
                <v-icon
                  :color="rightBarTab == 'reviews' ? 'white' : 'primary'"
                  class="material-icons-outlined"
                  icon="reviews"
                />
              </v-btn>
              <v-btn
                aria-label="Notes"
                value="notes"
                variant="outlined"
                icon
              >
                <v-icon
                  :color="rightBarTab == 'notes' ? 'white' : 'primary'"
                  class="material-icons-outlined"
                  icon="assignment"
                />
              </v-btn>
            </v-btn-toggle>

            <template v-if="schema.enable_stages && schema.stages.length > 0">
              <v-menu
                v-model="openStageDialog"
                :min-width="350"
                max-width="500"
                offset="5"
              >
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    class="dropdown-btn"
                    color="primary"
                    data-cy="stage-selector"
                    size="large"
                    variant="outlined"
                    tile
                  >
                    {{ $t(currentStage.title) }}
                    <v-icon
                      icon="keyboard_arrow_down"
                      end
                    />
                  </v-btn>
                </template>

                <v-list
                  v-model:selected="currentStageId"
                  class="bc-light-gray b-1 py-0"
                  tile
                >
                  <template
                    v-for="(stage, index) in schema.stages"
                    :key="stage.id"
                  >
                    <v-list-item
                      :class="
                        caseRecord.stage_id == stage.id || currentStageIndex == index
                          ? 'bg-primary-extra-light'
                          : ''
                      "
                      :value="stage.id"
                      lines="two"
                    >
                      <template #title>
                        <div
                          class="mr-3"
                          style="max-width: 350px; white-space: normal"
                        >
                          {{ $t(stage.title) }}
                        </div>
                      </template>
                      <template #append>
                        <template v-if="index < currentStageIndex">
                          <v-hover v-slot="{ isHovering, props }">
                            <v-btn
                              @click="setStage(index)"
                              v-bind="props"
                              :loading="processing"
                              :variant="isHovering ? 'outlined' : undefined"
                              color="primary"
                              data-cy="set-stage-button"
                              icon="check"
                            />
                          </v-hover>
                        </template>
                        <template
                          v-else-if="caseRecord.stage_id == stage.id || currentStageIndex == index"
                        >
                          <v-btn
                            @click="completeStage()"
                            :loading="processing"
                            color="primary"
                            data-cy="complete-stage-button"
                          >
                            {{ $t('Complete') }}
                          </v-btn>
                        </template>
                        <template v-else>
                          <v-hover v-slot="{ isHovering, props }">
                            <v-btn
                              @click="setStage(index)"
                              v-bind="props"
                              :loading="processing"
                              color="grey-lighten-3"
                              data-cy="set-stage-button"
                              variant="outlined"
                              icon
                            >
                              <v-icon
                                v-if="isHovering"
                                color="primary-lighten-2"
                                icon="check"
                              />
                            </v-btn>
                          </v-hover>
                        </template>
                      </template>
                    </v-list-item>
                    <v-divider
                      v-if="index < schema.stages.length - 1"
                      :key="index"
                    />
                  </template>
                </v-list>
              </v-menu>
            </template>

            <ActionMenu
              @click:action:delete="destroy"
              :items="actionItems()"
              button-icon="more_vert"
              x-small
            />
          </v-col>
        </v-row>
      </v-toolbar>

      <div class="bg-super-light-blue fill-height d-flex pt-1">
        <div
          :style="rightBarTab ? 'width: 65%;' : 'width: 100%'"
          class="fill-height px-3"
          style="overflow-y: auto; padding-top: 70px"
        >
          <v-card
            border
            flat
            tile
          >
            <v-card-title class="fs-22 fw-600">
              <v-row
                class="d-flex align-center"
                dense
              >
                <v-col
                  cols="12"
                  role="heading"
                  sm="6"
                >
                  {{ $t('Details') }}
                </v-col>

                <v-col class="d-flex justify-end">
                  <v-btn
                    @click="formVisibility = !formVisibility"
                    :aria-label="$t('Toggle visibility')"
                    class="ms-0"
                    color="primary"
                    size="small"
                    variant="text"
                    icon
                    rounded
                  >
                    <v-icon
                      class="material-icons-outlined"
                      color="navy-blue"
                    >
                      {{ formVisibility ? 'visibility_off' : 'visibility' }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>

            <template v-if="formVisibility">
              <v-divider class="mb-3" />

              <v-card-text>
                <QuestionSet
                  v-model="caseRecord"
                  @change="inputEventHandler('change', $event)"
                  @change:attachments="loadAttachments()"
                  @change:input="inputEventHandler('change:input', $event)"
                  :attachment-owner-id="caseId"
                  :attachment-owner-type="caseType"
                  :attachments="findAttachmentsByQuestions(validQuestions)"
                  :color="null"
                  :elevation="0"
                  :processing="processing"
                  :questions="validQuestions"
                  :readonly="readonly"
                  :schema="schema.definition"
                  condensed
                  divided
                  expanded
                  hide-actions
                  tile
                  very-dense
                />

                <div
                  v-for="question in validVerificationQuestions"
                  :key="question.id"
                  class="mb-6"
                >
                  <FormQuestion
                    :color="null"
                    :elevation="0"
                    :subtitle="question.verification_subtitle"
                    :title="question.verification_title"
                    condensed
                    dense
                    divided
                    hide-actions
                    tile
                  >
                    <AttachmentUploader
                      @uploaded="attachments.push($event)"
                      :ref="['uploader', question.id].join('')"
                      :owner="{
                        type: 'Form',
                        id: schema.id,
                        tag: question.id,
                        tags: [caseRecord.id, question.id],
                      }"
                      class="mb-4"
                    />

                    <AttachmentList
                      @change="loadAttachments()"
                      @delete="loadAttachments"
                      :attachments="
                        attachments.filter((attachment) => attachment.tag == question.id)
                      "
                      class="mb-6"
                    />
                  </FormQuestion>
                </div>

                <SubmittedSubsidyMemo
                  :border="false"
                  :created-at="caseRecord.created_at"
                  :owner-id="caseRecord.id"
                  :owner-type="caseType"
                  :submitted-at="caseRecord.submitted_at"
                />

                <v-row v-if="displaySubmitButton">
                  <v-col>
                    <v-btn
                      @click="submitCase()"
                      :disabled="questionsIncomplete"
                      class="mt-2"
                      color="primary"
                      block
                    >
                      {{ $t('Submit') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </template>
          </v-card>

          <LinkedRecords
            v-if="schema.meta.enable_linked_records"
            :case-id="caseId"
            :case-type="caseType"
            :schema="schema"
          />

          <VerticalSpacer :min-height="60" />
        </div>

        <v-card
          :style="rightBarTab ? 'width: 35%;' : 'display: none;'"
          class="fill-height"
          style="overflow-y: auto; padding-top: 70px"
          border
        >
          <v-window
            v-model="rightBarTab"
            :mandatory="false"
          >
            <v-window-item
              v-if="caseRecord"
              key="notes"
              value="notes"
            >
              <FormNotes
                @close="rightBarTab = null"
                :owner="caseRecord"
                owner-type="Form"
              />
            </v-window-item>

            <v-window-item
              key="reviews"
              value="reviews"
            >
              <ReviewsList
                @close="rightBarTab = null"
                @published="load"
                :associated-schema-ids="schema.associated_schema_ids"
                :owner="caseRecord"
                :owner-type="caseType"
                :statuses="FORM_REVIEW_STATUSES"
              />
            </v-window-item>
          </v-window>
        </v-card>
      </div>

      <LabelDialog
        @change="updateLabels($event)"
        ref="labelDialog"
        :program-labels="labels"
        label-owner-type="Form"
      />
    </template>

    <template v-else>
      <v-progress-linear indeterminate />
    </template>

    <SaveBar
      @cancel="reset"
      @save="save"
      :model-value="changed"
      :processing="processing"
      flat
    />
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import LabelDialog from '@/specialist/components/LabelDialog.vue';
import LabelValue from '@/specialist/components/LabelValue.vue';
import LinkedRecords from '@/specialist/components/LinkedRecords.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import useQuestionable from '@/shared/composables/useQuestionable';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import SubmittedSubsidyMemo from '@/specialist/components/subsidy/SubmittedSubsidyMemo.vue';
import FormNotes from '@/specialist/components/forms/FormNotes.vue';
import ReviewsList from '@/specialist/components/ReviewsList.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { FORM_REVIEW_STATUSES } from '@/specialist/services/statuses';

const UPPY_CLASS_NAME = 'uppy-Dashboard-input';

const props = defineProps({
  backTo: {
    type: String,
    default: null,
  },
  canDelete: {
    type: Boolean,
    default: false,
  },
  caseId: {
    type: String,
    required: true,
  },
  caseRepository: {
    type: Object,
    required: true,
  },
  caseType: {
    type: String,
    required: true,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const attachments = ref([]);
const changed = ref(false);
const currentStageId = ref(null);
const caseRecord = ref(null);
const formVisibility = ref(true);
const labels = ref([]);
const loading = ref(true);
const openStageDialog = ref(false);
const ownerDataType = ref(null);
const ownerId = ref(null);
const processing = ref(false);
const rightBarTab = ref(null);
const schema = ref(null);
const loaded = ref(false);

const eventBus = useEventBus();
const { questionsIncomplete, validateAnswers, validQuestions, validVerificationQuestions } =
  useQuestionable({
    ownerId,
    ownerDataType,
    syncedObject: caseRecord,
  });
const router = useRouter();
const store = useStore();

const currentStageIndex = computed(() => {
  if (schema.value && caseRecord.value) {
    const index = schema.value?.stages.findIndex((stage) => stage.id === caseRecord.value.stage_id);
    return !index || index === -1 ? 0 : index;
  }

  return 0;
});

const backRoute = computed(() => {
  if (props.backTo === 'SubsidyShow') {
    if (caseRecord.value?.subsidy_id)
      return { name: props.backTo, params: { id: caseRecord.value.subsidy_id } };
  } else if (props.backTo === 'ProviderShow') {
    if (caseRecord.value?.provider_id)
      return { name: props.backTo, params: { providerId: caseRecord.value?.provider_id } };
  } else {
    return { name: props.backTo };
  }
  return null;
});

const currentStage = computed(() => {
  return schema.value?.stages[currentStageIndex.value] || {};
});

const displaySubmitButton = computed(() => {
  if (props.caseType !== 'Form') return false;
  if (caseRecord.value.submitted_at) return false;
  return !props.readonly;
});

async function save() {
  processing.value = true;

  const response = await props.caseRepository
    .update(props.caseId, caseRecord.value)
    .catch((error) => eventBus.error(error));

  processing.value = false;

  if (!response?.data) return;

  caseRecord.value = response.data;
  eventBus.chime('Saved');
  changed.value = false;
  await validateAnswers();
}

function completeStage() {
  caseRecord.value = {
    ...caseRecord.value,
    stage_id: schema.value?.stages[currentStageIndex.value + 1]?.id,
  };
  void save();
}

function findAttachmentsByQuestions(questions) {
  const questionIds = Object.values(questions).map((question) => question.id);

  return attachments.value.filter((attachment) => {
    if (attachment?.tag) return questionIds.includes(attachment.tag);
    return questionIds.some((questionId) => attachment?.tags.includes(questionId));
  });
}

function actionItems() {
  const actions = [];

  if (props.canDelete) {
    actions.push({
      event: 'delete',
      avatar: 'delete',
      title: 'Delete',
    });
  }

  return actions;
}

async function destroy() {
  if (!confirm(`Are you sure you want to delete this ${props.caseType}? This cannot be undone.`))
    return;

  const result = await props.caseRepository.destroy(caseRecord.value.id);

  if (result.status === 200) {
    eventBus.chime('Deleted successfully');
  } else {
    eventBus.chime(result.error);
  }

  await router.replace(backRoute.value || { path: '/' });
}

async function inputEventHandler(origin, event) {
  if (props.caseType === 'Form') {
    // We only want to trigger an auto save on 'change' and if not an uppy trigger
    const validUpdateTrigger = origin === 'change' && event.target?.className !== UPPY_CLASS_NAME;
    if (validUpdateTrigger) {
      await nextTick();
      void update(caseRecord.value, 'Form saved');
    }
  } else {
    changed.value = true;
  }
}

async function loadAttachments() {
  const params = {
    owner_id: props.caseId,
    owner_type: props.caseType,
  };

  const response = await Api.organization.attachment.index(params);
  attachments.value = response.data;
}

async function loadCase() {
  const response = await props.caseRepository.get(props.caseId);
  caseRecord.value = response.data;
  currentStageId.value = caseRecord.value.stage_id;
}

async function loadLabels() {
  if (store.state.profile.org_settings_access) {
    const response = await Api.organization.label.index({ schema_id: schema.value.id });
    labels.value = response.data;
  }
}

async function loadSchema() {
  const response = await Api.organization.schema.get(caseRecord.value.schema_id);
  schema.value = response.data;
  ownerId.value = response.data.id;
  ownerDataType.value = 'Schema';
  await loadLabels();
}

async function load() {
  await loadCase();
  await loadSchema();
  await validateAnswers();
  await loadAttachments();
  loaded.value = true;
}

function reset() {
  changed.value = false;
  void load();
}

function setStage(index) {
  if (index < currentStageIndex.value) {
    if (confirm('Are you sure you want to move back to a previous stage?')) {
      caseRecord.value.stage_id = schema.value.stages[index].id;
      void save();
    }
  } else if (index === currentStageIndex.value + 1) {
    completeStage();
  } else if (confirm('Are you sure you want to skip stages?')) {
    caseRecord.value.stage_id = schema.value.stages[index].id;
    void save();
  }
}

function submitCase() {
  void update({ submitted: true }, 'Submitted');
}

async function update(newVal, message) {
  processing.value = true;

  const response = await props.caseRepository
    .update(caseRecord.value.id, newVal)
    .catch((error) => eventBus.error(error));

  processing.value = false;

  if (!response?.data) return;

  caseRecord.value = response.data;
  if (message) eventBus.chime(message);
}

function updateLabels(updatedForm) {
  caseRecord.value.relationships.labels.data = updatedForm.relationships.labels.data;
}

function toggleLock() {
  void update(
    { locked: !caseRecord.value.locked },
    caseRecord.value.locked ? 'Unlocked' : 'Locked',
  );
}

watch(
  () => props.caseId,
  (val) => {
    if (val) {
      loading.value = true;
      void load();
    }
  },
  { immediate: true },
);
</script>

<style>
.dropdown-btn {
  max-width: 465px;

  @media (min-width: 800px) {
    max-width: 313px;
  }

  @media (min-width: 1490px) {
    max-width: 400px;
  }

  @media (min-width: 1600px) {
    max-width: 465px;
  }
}
</style>
