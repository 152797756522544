export default {
  equal: {
    text: 'equals',
    value: 'equal',
    type: 'string',
    compareable: true,
  },
  not_equal: {
    text: 'not equals',
    value: 'not_equal',
    type: 'string',
    compareable: true,
  },
  contains: {
    text: 'contains',
    value: 'contains',
    type: 'string',
    compareable: true,
  },
  not_contains: {
    text: 'does not contain',
    value: 'not_contains',
    type: 'string',
    compareable: true,
  },
  earlier_than: {
    text: 'earlier than',
    value: 'earlier_than',
    compareable: true,
    type: 'string',
    format: 'date',
  },
  later_than: {
    text: 'later than',
    value: 'later_than',
    compareable: true,
    type: 'string',
    format: 'date',
  },
  not_later_than: {
    text: 'not later than',
    value: 'not_later_than',
    compareable: true,
    type: 'string',
    format: 'date',
  },
  greater: {
    text: '>',
    value: 'greater',
    compareable: true,
    type: 'number',
  },
  greater_equal: {
    text: '>=',
    value: 'greater_equal',
    compareable: true,
    type: 'number',
  },
  less: {
    text: '<',
    value: 'less',
    compareable: true,
    type: 'number',
  },
  less_equal: {
    text: '<=',
    value: 'less_equal',
    compareable: true,
    type: 'number',
  },
  blank: { text: 'is blank', value: 'blank', compareable: false },
  present: { text: 'is not blank', value: 'present', compareable: false },
  in: {
    text: 'in',
    value: 'in',
    compareable: true,
    type: 'string',
  },
  not_in: {
    text: 'not in',
    value: 'not_in',
    compareable: true,
    type: 'string',
  },
};
