<template>
  <v-container v-if="business">
    <TitleBar
      :back-route="{ name: 'BusinessIndex' }"
      :name="business.name"
      back-name="Businesses"
      style="padding-inline: 0 !important"
    >
      <template #actions>
        <TitleBarToggleButton
          @toggle="toggleConfirmed"
          :enabled="business.confirmed"
          :processing="processing"
          data-cy="confirm-button"
          disabled-text="Unconfirmed"
          enabled-text="Confirmed"
        />
      </template>
    </TitleBar>

    <div class="mb-8">
      <SectionHeader
        v-if="accessRequests.length > 0"
        title="Access Requests"
      />

      <AccessRequestCard
        v-for="request in accessRequests.filter((accessRequest) => !accessRequest.approved)"
        v-show="isEditor"
        @approved="load"
        @destroy="load"
        :key="request.id"
        :request="request"
        class="mb-4"
      />

      <template v-if="accessRequests.filter((accessRequest) => accessRequest.approved).length > 0">
        <div v-if="accessRequests.filter((accessRequest) => !accessRequest.approved) == 0">
          {{ $t('No new access requests.') }}
        </div>
        <v-btn
          @click="showApprovedAccessRequests = !showApprovedAccessRequests"
          class="mt-3"
          color="primary"
          size="small"
          variant="outlined"
        >
          {{ $t(`${showApprovedAccessRequests ? 'Hide' : 'Show'} approved access requests`) }}
        </v-btn>
      </template>

      <div
        v-show="showApprovedAccessRequests"
        class="mt-3"
      >
        <AccessRequestCard
          v-for="request in accessRequests.filter((accessRequest) => accessRequest.approved)"
          :key="request.id"
          :request="request"
          class="mb-4"
        />
      </div>

      <v-divider class="my-8" />

      <SectionHeader title="Locations">
        <template #actions>
          <v-btn
            v-if="isEditor"
            @click="draftOwnershipRequest"
            class="me-3"
            color="primary"
            data-cy="transfer-location-button"
          >
            <v-icon
              icon="input"
              start
            />
            {{ $t('Add') }}
          </v-btn>

          <v-btn
            v-if="isEditor"
            @click="$refs.newProviderDialog.open(business.id)"
            color="primary"
            data-cy="add-location-button"
          >
            <v-icon
              icon="add"
              start
            />
            {{ $t('New') }}
          </v-btn>

          <NewProviderDialog
            @create="addProvider"
            ref="newProviderDialog"
          />
        </template>
      </SectionHeader>

      <OwnershipRequestCard
        v-for="request in ownershipRequests.filter(
          (ownershipRequest) => !ownershipRequest.approved,
        )"
        v-show="isEditor"
        @approved="load"
        @destroy="load"
        :key="request.id"
        :request="request"
        class="mb-4"
      />

      <v-card
        v-for="provider in providers"
        :key="provider.id"
        :to="{ name: 'ProviderShow', params: { providerId: provider.id } }"
        border
        flat
        tile
      >
        <v-card-text>
          <v-row class="d-flex align-center">
            <v-col class="d-flex align-center">
              <v-icon
                class="me-2"
                color="primary"
                icon="storefront"
              />
              <div
                v-text="provider.name"
                class="fs-16 fw-500"
                data-cy="provider-name"
              />
            </v-col>
            <v-col class="ta-right">
              <div
                v-text="[provider.address1, provider.city].join(', ')"
                class="fs-16 fw-500 c-light-black"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-pagination
        v-if="providersPageCount > 1"
        v-model="providersPage"
        :length="providersPageCount"
        :total-visible="8"
        class="mt-4"
      />

      <div
        v-if="providers.length == 0"
        class="fs-16 fw-500"
      >
        {{ $t('There are no provider locations registered to this business.') }}
      </div>

      <v-btn
        v-if="ownershipRequests.filter((ownershipRequest) => ownershipRequest.approved).length > 0"
        @click="showApprovedOwnershipRequests = !showApprovedOwnershipRequests"
        class="mt-3"
        color="primary"
        size="small"
        variant="outlined"
      >
        {{ $t(`${showApprovedOwnershipRequests ? 'Hide' : 'Show'} approved ownership requests`) }}
      </v-btn>

      <div
        v-show="showApprovedOwnershipRequests"
        class="mt-3"
      >
        <OwnershipRequestCard
          v-for="request in ownershipRequests.filter(
            (ownershipRequest) => ownershipRequest.approved,
          )"
          :key="request.id"
          :request="request"
          class="mb-4"
        />
      </div>
    </div>

    <v-divider class="my-8" />

    <SectionHeader title="Staff">
      <template #actions>
        <v-btn
          v-if="isEditor"
          @click="newMember"
          color="primary"
          data-cy="add-member-button"
          variant="flat"
        >
          <v-icon
            icon="add"
            start
          />
          {{ $t('New') }}
        </v-btn>
      </template>
    </SectionHeader>

    <div
      v-if="members.length == 0"
      class="fs-16 fw-500"
    >
      {{ $t('There are no staff registered to this business.') }}
    </div>

    <v-card
      v-for="staffMember in members"
      :key="staffMember.id"
      :to="{
        name: 'StaffShow',
        params: {
          memberId: staffMember.id,
        },
        query: {
          businessId: business.id,
        },
      }"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row class="d-flex align-center">
          <v-col
            class="d-flex align-center"
            cols="8"
          >
            <v-avatar
              class="fs-18 c-white me-4"
              color="primary"
              size="40"
            >
              <template v-if="staffMemberAvatarText(staffMember)">
                {{ staffMemberAvatarText(staffMember) }}
              </template>
              <template v-else>
                <v-icon
                  color="white"
                  icon="person"
                />
              </template>
            </v-avatar>

            <div>
              <div class="mb-1">
                <span class="fs-18 fw-600 mb-0 me-3">
                  {{ staffMember.name || $t('Unknown name') }}
                </span>

                <v-chip
                  v-if="staffMember.deactivated"
                  color="red"
                  size="small"
                >
                  {{ $t('Deactivated') }}
                </v-chip>

                <v-chip
                  v-else-if="staffMember.provider_ids.length > 0"
                  size="small"
                >
                  {{ $t('Limited access') }}
                </v-chip>
              </div>

              <span
                v-text="staffMember.email"
                class="fs-16 c-light-black"
              />
            </div>
          </v-col>

          <v-col
            v-if="isEditor && !staffMember.deactivated"
            class="ta-right"
            cols="4"
          >
            <v-btn
              @click.stop.prevent="deactivateMember(staffMember)"
              :aria-label="$t('Deactivate user')"
              class="text-decoration-none"
              color="red"
              variant="text"
              icon
            >
              <v-icon icon="close" />
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Settings" />

    <v-card
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <LabeledTextfield
            v-model="business.name"
            :readonly="!isEditor"
            cols="6"
            message="Business name"
          />
          <LabeledTextfield
            v-model="business.ein"
            :readonly="!isEditor"
            cols="6"
            md="6"
            message="EIN"
          />
          <LabeledTextfield
            v-model="business.address"
            :readonly="!isEditor"
            cols="12"
            md="3"
            message="Address"
          />
          <LabeledTextfield
            v-model="business.city"
            :readonly="!isEditor"
            cols="12"
            md="3"
            message="City"
          />
          <LabeledTextfield
            v-model="business.state"
            :readonly="!isEditor"
            cols="12"
            md="2"
            message="State"
          />
          <LabeledTextfield
            v-model="business.zip"
            :readonly="!isEditor"
            cols="12"
            md="2"
            message="Zip"
          />
          <LabeledTextfield
            v-model="business.county"
            :readonly="!isEditor"
            cols="12"
            md="2"
            message="County"
          />
          <LabeledTextfield
            v-model="business.email"
            :readonly="!isEditor"
            cols="12"
            md="6"
            message="Email"
          />
          <LabeledTextfield
            v-model="business.phone"
            :readonly="!isEditor"
            cols="12"
            md="6"
            message="Phone"
          />

          <CustomField
            v-for="(field, index) in customFields"
            :key="index"
            :field="field"
            :model-value="business"
            :readonly="!isEditor"
            :schema-id="business.schema_id"
            :show-title="true"
            class="fs-16"
            mode="edit"
          />

          <LabeledSwitch
            v-if="$store.state.features.enable_payments"
            v-model="business.enable_payments"
            subtitle="If enabled, providers will be able to scheduled and completed subsidy payments from their dashboard."
            title="Enable payments"
          />
        </v-row>
      </v-card-text>
      <v-card-actions
        v-if="isEditor"
        class="pt-0"
      >
        <v-btn
          @click="saveBusiness"
          :loading="processing"
          color="primary"
          variant="flat"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="memberDialogIsVisible"
      max-width="600px"
    >
      <v-card v-if="member">
        <v-card-title>
          {{ $t(member.id ? 'Update member' : 'Invite member') }}
        </v-card-title>
        <v-card-text class="pt-4 pb-8">
          <v-row>
            <LabeledTextfield
              v-model="member.first_name"
              data-cy="invite_first_name"
              message="First name"
            />
            <LabeledTextfield
              v-model="member.last_name"
              data-cy="invite_last_name"
              message="Last name"
            />
            <LabeledTextfield
              v-model="member.email"
              data-cy="invite_email"
              message="Email"
            />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="memberDialogIsVisible = false"
            :loading="processing"
            color="primary"
            size="x-large"
            variant="text"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="createMember"
            :disabled="!member.first_name || !member.last_name || !member.email"
            :loading="processing"
            :ripple="false"
            color="primary"
            data-cy="invite_submit"
            size="x-large"
            variant="flat"
          >
            {{ $t('Submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="transferMemberDialogVisible"
      max-width="600px"
      scrollable
    >
      <v-card>
        <v-card-title data-cy="staff_exists_dialog">
          {{ $t('This staff member already exists') }}
        </v-card-title>
        <v-divider class="mb-4" />
        <v-card-text>
          <div
            v-if="otherBusiness"
            class="fs-16 fw-500"
          >
            {{ $t('This staff member is already associated with') }}
            <router-link
              :to="{ name: 'BusinessShow', params: { businessId: otherBusiness.id } }"
              target="_blank"
            >
              {{ $t(otherBusiness.name || 'Unknown name') }}
            </router-link>
            {{ $t('Would you like to transfer this staff member to this business instead?') }}
          </div>
          <div
            v-else
            class="fs-16 fw-500"
            data-cy="staff_exists_dialog"
          >
            {{
              $t(
                'This staff member is not assigned to a business. Would you like to add them to this business?',
              )
            }}
          </div>
        </v-card-text>
        <v-divider class="mb-4" />
        <v-card-actions>
          <v-btn
            @click="processAccessRequest"
            data-cy="transfer_staff_member"
            size="large"
            variant="flat"
          >
            {{ otherBusiness ? $t('Yes, transfer staff member') : $t('Yes, add staff member') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="transferMemberDialogVisible = false"
            :ripple="false"
            color="primary"
            size="large"
            variant="flat"
          >
            {{ $t('No, leave as is') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="newOwnershipRequestDialogIsVisible"
      max-width="600px"
      scrollable
    >
      <v-card>
        <v-card-title>
          {{ $t('Add location') }}
        </v-card-title>
        <v-card-text>
          <p class="fs-16 fw-500 mb-4">
            {{
              $t(
                'If this provider already belongs to an existing business, ownership will be transfered to this business.',
              )
            }}
          </p>
          <v-autocomplete
            v-model="selectedProvider"
            v-model:search="providerQuery"
            :aria-label="$t('Search by location name')"
            :items="searchedProviders"
            :placeholder="$t('Search')"
            class="mb-4"
            color="secondary"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="search"
            variant="filled"
            auto-select-first
            hide-details
            hide-no-data
            no-filter
            return-object
            rounded
          >
            <template #item="{ item, props }">
              <v-list-item
                v-bind="props"
                :subtitle="item.raw.location"
                :title="item.raw.name"
              />
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="newOwnershipRequestDialogIsVisible = false"
            :loading="processing"
            variant="text"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="createOwnershipRequest"
            :disabled="!selectedProvider"
            :loading="processing"
            color="primary"
            variant="flat"
          >
            {{ $t('Add location') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-divider class="my-8" />

    <DangerBar
      v-if="isEditor"
      @destroy="destroy"
      :processing="processing"
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import AccessRequestCard from '@/specialist/components/provider/AccessRequestCard.vue';
import CustomField from '@/shared/components/CustomField.vue';
import DangerBar from '@/shared/components/DangerBar.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import NewProviderDialog from '@/specialist/components/provider/NewProviderDialog.vue';
import OwnershipRequestCard from '@/specialist/components/provider/OwnershipRequestCard.vue';
import SectionHeader from '@/shared/components/content/SectionHeader.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import TitleBarToggleButton from '@/specialist/components/TitleBarToggleButton.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();

const accessRequests = ref([]);
const business = ref(null);
const customFields = ref([]);
const existingMember = ref(null);
const member = ref(null);
const memberDialogIsVisible = ref(false);
const members = ref([]);
const newOwnershipRequestDialogIsVisible = ref(false);
const otherBusiness = ref(null);
const ownershipRequests = ref([]);
const processing = ref(false);
const providerQuery = ref(null);
const providers = ref([]);
const providersPage = ref(1);
const providersPageCount = ref(0);
const searchedProviders = ref([]);
const selectedProvider = ref(null);
const showApprovedAccessRequests = ref(false);
const showApprovedOwnershipRequests = ref(false);
const transferMemberDialogVisible = ref(false);

const isEditor = computed(() => store.state.profile.org_business_edit);

watch(
  () => providerQuery.value,
  (newVal) => {
    if (newVal) searchProviders();
  },
);

watch(
  () => providersPage.value,
  (newVal) => {
    if (newVal) void loadProviders();
  },
);

watch(
  () => route.params.businessId,
  (newVal) => {
    if (newVal) load();
  },
  { immediate: true },
);

function addProvider(provider) {
  providers.value.push(provider);
}

async function createMember() {
  member.value.business_id = business.value.id;
  member.value.is_provider = true;
  member.value.is_parent = false;
  member.value.is_specialist = false;
  processing.value = true;

  Api.organization.member.create(
    member.value,
    () => {
      eventBus.chime('Member updated');
      void loadMembers();
      memberDialogIsVisible.value = false;
      processing.value = false;
    },
    async (err) => {
      if (err.response.data.errors[0].includes('already taken')) {
        await handleExistingStaffMember();
      } else {
        eventBus.chime(err.response.data.errors[0]);
      }
      processing.value = false;
    },
  );
}

function createOwnershipRequest() {
  processing.value = true;
  Api.organization.ownership_request.create(
    { business_id: business.value.id, provider_id: selectedProvider.value.id },
    () => {
      processing.value = false;
      newOwnershipRequestDialogIsVisible.value = false;
      load();
      eventBus.chime('Request sent');
    },
  );
}

async function deactivateMember(memberToDeactivate) {
  const updatedMember = {
    ...memberToDeactivate,
    deactivated: true,
  };

  // TODO: Replace w/ confirm dialog
  if (confirm('Are you sure you want to deactivate this user?')) {
    processing.value = true;
    const response = await Api.organization.member.update(updatedMember);

    if (response?.status !== 200) {
      eventBus.chime(response.data.errors[0]);
    } else {
      eventBus.chime('User deactivated');
      load();
    }
  }
}

function destroy() {
  if (members.value.length > 0) {
    alert(
      'You cannot remove a business that still has staff members. Remove all staff to remove this business.',
    );
  } else if (confirm('Are you sure you want to remove this business?')) {
    Api.organization.business.destroy(business.value.id, () =>
      router.replace({ name: 'BusinessIndex' }),
    );
  }
}

function draftOwnershipRequest() {
  searchedProviders.value = [];
  providerQuery.value = null;
  selectedProvider.value = null;
  newOwnershipRequestDialogIsVisible.value = true;
}

function getCustomFields() {
  return Object.keys(
    store.state.schemas[business.value.schema_id]?.definition.properties.custom.properties || {},
  );
}

async function handleExistingStaffMember() {
  existingMember.value = (
    await Api.organization.member.index({ query: member.value.email })
  ).data.shift();

  if (existingMember.value && existingMember.value.business_id) {
    otherBusiness.value = (
      await Api.organization.business.get(existingMember.value.business_id)
    ).data;
  }
  if (existingMember.value) {
    transferMemberDialogVisible.value = true;
  }
}

function load() {
  business.value = null;

  void loadBusiness();
  void loadMembers();
  void loadProviders();

  Api.organization.access_request.index({ business_id: route.params.businessId }, (resp) => {
    accessRequests.value = resp.data;
  });
  Api.organization.ownership_request.index({ business_id: route.params.businessId }, (resp) => {
    ownershipRequests.value = resp.data;
  });
}

async function loadBusiness() {
  const { data } = await Api.organization.business.get(route.params.businessId);
  business.value = data;
  customFields.value = getCustomFields();
}

async function loadMembers() {
  const params = { business_id: route.params.businessId };
  const { data } = await Api.organization.member.index(params);
  members.value = data;
}

async function loadProviders() {
  const params = {
    business_id: route.params.businessId,
    page: providersPage.value,
  };
  const resp = await Api.organization.provider.index(params);
  providersPageCount.value = parseInt(resp.headers['x-page-count'] || 0, 10);
  providers.value = resp.data;
}

function newMember() {
  member.value = {};
  memberDialogIsVisible.value = true;
}

function processAccessRequest() {
  // Create an approved access request to move an existing member to this business
  processing.value = true;
  Api.organization.access_request.create(
    { business_id: business.value.id, member_id: existingMember.value.id, approved: true },
    () => {
      processing.value = false;
      memberDialogIsVisible.value = false;
      transferMemberDialogVisible.value = false;
      load();
      eventBus.chime('Moving user to this business...');
    },
  );
}

function saveBusiness() {
  if (!isEditor.value) return;

  processing.value = true;
  Api.organization.business.update(
    route.params.businessId,
    business.value,
    (resp) => {
      business.value = resp.data;
      processing.value = false;
      eventBus.chime('Saved');
    },
    (err) => {
      eventBus.chime(err.response.data.errors[0]);
      processing.value = false;
    },
  );
}

function searchProviders() {
  Api.public_api.provider.index({ query: providerQuery.value }, (resp) => {
    searchedProviders.value = resp.data;
  });
}

function staffMemberAvatarText(staffMember) {
  if (!staffMember.first_name && !staffMember.last_name) return false;

  let avatarLetters = [];
  if (staffMember.first_name) avatarLetters.push(staffMember.first_name[0]);
  if (staffMember.last_name) avatarLetters.push(staffMember.last_name[0]);
  avatarLetters = avatarLetters.filter((character) => character.trim() !== '');

  if (avatarLetters.length === 0) return false;

  return avatarLetters.join('').toUpperCase();
}

function toggleConfirmed() {
  processing.value = true;

  Api.organization.business.update(
    business.value.id,
    { confirmed: !business.value.confirmed },
    (resp) => {
      processing.value = false;
      business.value = resp.data;
    },
    (err) => {
      processing.value = false;
      eventBus.chime(err.response.data.errors[0]);
    },
  );
}
</script>

<style>
.text-decoration-none span:hover {
  text-decoration: none !important;
}
</style>
