<!-- eslint-disable vue/valid-v-for -->
<template>
  <div v-if="localValue">
    <template v-if="selected">
      <v-card
        class="bc-primary border-md border-dashed mb-8"
        tile
      >
        <v-card-title>
          <v-row class="d-flex align-center">
            <v-col> {{ $t('Question') }} #{{ index + 1 }} </v-col>

            <v-col
              class="ta-right"
              cols="4"
            >
              <QuestionIndicators
                :conditional="conditional"
                :mandatory="localValue.mandatory"
                :published="localValue.published"
                :verification="localValue.verification"
                class="d-inline"
              />
              <v-btn
                @click="$emit('cancel')"
                :aria-label="$t('Close')"
                class="ml-3"
                variant="text"
                icon
              >
                <v-icon icon="close" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-row>
            <LabeledTextfield
              v-model="localValue.title"
              @input="changed = true"
              message="Title"
              hide-details
            />

            <LabeledTextarea
              v-model="localValue.subtitle"
              @input="changed = true"
              message="Subtitle"
              rows="2"
              hide-details
            />

            <LabeledSimpleSelect
              v-model="localValue.format"
              :items="['text', 'address', 'document']"
              message="Format"
              hard-lock
            />
          </v-row>

          <v-row
            v-if="isDocumentQuestion"
            class="mb-3"
          >
            <LabeledTextarea
              v-model="localValue.attachment_description"
              @input="changed = true"
              message="Attachment description"
              rows="2"
              hide-details
            />
          </v-row>

          <template v-if="!localValue.is_section">
            <v-divider
              v-if="hasSyncedAttributes"
              class="my-4"
            />

            <v-row
              v-if="hasSyncedAttributes"
              class="d-flex align-center mb-1"
            >
              <LabeledSimpleSelect
                v-model="localValue.synced_model"
                :items="schemas"
                item-title="name"
                item-value="data_type"
                message="Model"
              />
            </v-row>

            <v-row
              v-if="hasSyncedAttributes"
              class="mb-3"
              dense
            >
              <LabeledControl message="Attributes">
                <DataItem
                  v-for="(obj, sa_index) in localValue.synced_attributes"
                  @remove="
                    localValue.synced_attributes.splice(sa_index, 1);
                    updateBooleansAsCheckboxesToggle();
                    changed = true;
                  "
                  :key="sa_index"
                  :name="obj.name"
                  data-testid="synced-attribute"
                  dense
                />
              </LabeledControl>

              <v-col>
                <v-btn
                  @click="$refs.addAttributeDialog.open()"
                  color="primary"
                  data-testid="add-attribute-button"
                  size="small"
                  variant="flat"
                >
                  <v-icon
                    class="me-1"
                    icon="add"
                    size="16"
                  />

                  <span>
                    {{ $t('Add') }}
                  </span>
                </v-btn>
              </v-col>

              <ResourceDialog
                @save="
                  localValue.synced_attributes.push($event);
                  updateBooleansAsCheckboxesToggle();
                  changed = true;
                "
                ref="addAttributeDialog"
                :fields="[{ text: 'Name', value: 'name', items: fields }]"
                save-button-text="Add"
                title="Add synced attribute"
                close-on-save
              />
            </v-row>

            <template v-if="someAttributesAreBoolean">
              <v-row
                class="mb-3"
                dense
              >
                <LabeledTextfield
                  v-model="localValue.true_label"
                  message="True text"
                />

                <LabeledTextfield
                  v-model="localValue.false_label"
                  message="False text"
                />
              </v-row>
            </template>

            <template v-if="!localValue.mandatory">
              <v-row
                class="mb-3"
                dense
              >
                <LabeledControl message="Mandatory fields">
                  <DataItem
                    v-for="(obj, ma_index) in localValue.mandatory_attributes"
                    @remove="
                      localValue.mandatory_attributes.splice(ma_index, 1);
                      changed = true;
                    "
                    :key="ma_index"
                    :name="obj.name"
                    data-testid="mandatory-field"
                    dense
                  />
                </LabeledControl>

                <v-col>
                  <v-btn
                    @click="$refs.addMandatoryAttributeDialog.open()"
                    color="primary"
                    data-testid="add-mandatory-field-button"
                    size="small"
                    variant="flat"
                  >
                    <v-icon
                      class="me-1"
                      icon="add"
                      size="16"
                    />

                    <span>
                      {{ $t('Add') }}
                    </span>
                  </v-btn>
                </v-col>

                <ResourceDialog
                  @save="
                    localValue.mandatory_attributes.push($event);
                    changed = true;
                  "
                  ref="addMandatoryAttributeDialog"
                  :fields="[{ text: 'Name', value: 'name', items: fields }]"
                  save-button-text="Add"
                  title="Add mandatory field"
                  close-on-save
                />
              </v-row>
            </template>

            <template v-if="answerable">
              <v-divider class="my-3" />

              <div
                v-t="'Score options'"
                class="fs-16 fw-500 mb-2"
              />

              <template v-if="localValue.answer_key?.length == 0">
                <div
                  v-t="'No score options'"
                  class="fs-14"
                />
              </template>

              <v-card
                v-for="(option, ak_index) in localValue?.answer_key"
                :key="ak_index"
                class="mb-2"
                border
                tile
              >
                <v-card-title class="fs-16 fw-500">
                  <v-row>
                    <v-col> {{ $t('Answer') }} {{ ak_index + 1 }} </v-col>

                    <v-col class="d-flex justify-end">
                      <v-btn
                        @click="removeAnswerKey(ak_index)"
                        color="red"
                        size="small"
                      >
                        {{ $t('Remove') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-divider />

                <v-card-text>
                  <v-row>
                    <LabeledSimpleSelect
                      v-model="option.synced_attribute"
                      :items="localValue.synced_attributes"
                      cols="4"
                      item-title="name"
                      item-value="name"
                      message="Attribute"
                      hide-details
                    />

                    <LabeledTextfield
                      v-model="option.text"
                      cols="4"
                      message="Value"
                      hide-details
                    />

                    <LabeledTextfield
                      v-model.number="option.value"
                      cols="4"
                      message="Score"
                      hide-details
                    />
                  </v-row>
                </v-card-text>
              </v-card>

              <v-row class="mt-2">
                <v-col>
                  <v-btn
                    @click="localValue.answer_key.push({})"
                    class="focus-very-visible"
                    color="primary"
                    size="small"
                  >
                    {{ $t('Add') }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>

            <template v-if="eligibility">
              <v-divider class="my-4" />
              <v-row>
                <v-col class="fw-500 fs-16">
                  {{ $t('Determines eligibility') }}
                </v-col>

                <v-col class="ta-right d-flex justify-end">
                  <v-switch
                    v-model="localValue.eligibility"
                    @update:model-value="
                      changed = true;
                      updatePostPreferenceSelectionToggle();
                    "
                    :label="localValue.eligibility ? 'Yes' : 'No'"
                    class="mt-0"
                    density="compact"
                    hide-details
                    inset
                  />
                </v-col>
              </v-row>
            </template>

            <v-divider class="my-4" />

            <v-row class="d-flex align-center">
              <v-col class="fw-500 fs-16">
                {{ $t('Mandatory') }}
              </v-col>

              <v-col class="ta-right d-flex justify-end">
                <v-switch
                  v-model="localValue.mandatory"
                  @update:model-value="changed = true"
                  :label="localValue.mandatory ? 'Yes' : 'No'"
                  class="mt-0"
                  density="compact"
                  hide-details
                  inset
                />
              </v-col>
            </v-row>

            <v-divider class="my-4" />

            <v-row class="d-flex align-center">
              <v-col class="fw-500 fs-16">
                {{ $t('Post preference selection') }}
              </v-col>

              <v-col class="ta-right d-flex justify-end">
                <v-switch
                  v-model="localValue.post_preference_selection"
                  @update:model-value="
                    changed = true;
                    updateEligibilityToggle();
                  "
                  :label="localValue.post_preference_selection ? 'Yes' : 'No'"
                  class="mt-0"
                  density="compact"
                  hide-details
                  inset
                />
              </v-col>
            </v-row>

            <v-divider class="my-4" />

            <v-row class="d-flex align-center">
              <v-col class="fw-500 fs-16">
                {{ $t('Bold') }}
              </v-col>

              <v-col class="ta-right d-flex justify-end">
                <v-switch
                  v-model="localValue.is_bold"
                  :label="localValue.is_bold ? 'Yes' : 'No'"
                  class="mt-0"
                  density="compact"
                  hide-details
                  inset
                />
              </v-col>
            </v-row>

            <v-divider class="my-4" />

            <v-row class="d-flex align-center">
              <v-col class="fw-500 fs-16">
                {{ $t('Show only when conditions are met') }}
              </v-col>

              <v-col class="ta-right d-flex justify-end">
                <v-switch
                  @update:model-value="toggleConditions($event)"
                  :false-value="false"
                  :label="localValue.conditions.length > 0 ? 'Yes' : 'No'"
                  :model-value="conditional"
                  :true-value="true"
                  class="mt-0"
                  density="compact"
                  hide-details
                  inset
                />
              </v-col>
            </v-row>

            <CriteriaEditor
              v-show="localValue.conditions.length > 0"
              v-model="localValue.conditions"
              @change="changed = true"
              :schemas="[schema]"
              class="mt-4"
            />

            <v-divider class="my-4" />

            <v-row
              v-if="hasSyncedAttributes"
              class="d-flex align-center"
            >
              <v-col class="fw-500 fs-16">
                {{ $t('Requires verification') }}
              </v-col>

              <v-col class="ta-right d-flex justify-end">
                <v-switch
                  v-model="localValue.verification"
                  @update:model-value="changed = true"
                  :label="localValue.verification ? 'Yes' : 'No'"
                  class="mt-0"
                  hide-details
                  inset
                />
              </v-col>
            </v-row>

            <template v-if="localValue.verification">
              <v-row class="d-flex align-center">
                <v-col class="fw-500 fs-16">
                  {{ $t('Verification evidence is mandatory') }}
                </v-col>

                <v-col class="ta-right d-flex justify-end">
                  <v-switch
                    v-model="localValue.verification_mandatory"
                    @update:model-value="changed = true"
                    :label="localValue.verification_mandatory ? 'Yes' : 'No'"
                    class="mt-0"
                    density="compact"
                    hide-details
                    inset
                  />
                </v-col>
              </v-row>

              <v-row>
                <LabeledTextfield
                  v-model="localValue.verification_title"
                  @input="changed = true"
                  message="Verification title"
                />

                <LabeledTextarea
                  v-model="localValue.verification_subtitle"
                  @input="changed = true"
                  message="Verification subtitle"
                  rows="2"
                />
              </v-row>
            </template>

            <v-divider class="my-4" />

            <v-row class="d-flex align-center">
              <v-col class="fw-500 fs-16">
                {{ $t('Display all boolean fields as checkboxes') }}
              </v-col>

              <v-col class="ta-right d-flex justify-end">
                <v-switch
                  v-model="localValue.enable_display_booleans_as_checkboxes"
                  @update:model-value="changed = true"
                  :disabled="!allAttributesAreBoolean"
                  :label="localValue.enable_display_booleans_as_checkboxes ? 'Yes' : 'No'"
                  class="mt-0"
                  data-testid="display-booleans-as-checkboxes-toggle"
                  density="compact"
                  hide-details
                  inset
                />
              </v-col>
            </v-row>

            <v-divider class="my-4" />

            <v-row class="d-flex align-center">
              <v-col class="fw-500 fs-16">
                {{ $t('Danger!') }}
              </v-col>

              <v-col class="ta-right d-flex justify-end">
                <v-btn
                  @click="destroy"
                  :loading="processing || saving"
                  color="red"
                  variant="outlined"
                >
                  {{ $t('Delete') }}
                </v-btn>
              </v-col>
            </v-row>

            <v-divider class="my-4" />

            <v-row class="d-flex align-center">
              <v-col class="fw-500 fs-16">
                {{ $t('Publish') }}
              </v-col>

              <v-col class="ta-right d-flex justify-end">
                <v-btn
                  v-if="localValue.published"
                  @click="publish(false)"
                  :loading="processing || saving"
                  color="green"
                  variant="outlined"
                >
                  {{ $t('Unpublish') }}
                </v-btn>

                <v-btn
                  v-else
                  @click="publish(true)"
                  :loading="processing || saving"
                  color="green"
                >
                  {{ $t('Publish') }}
                </v-btn>
              </v-col>
            </v-row>

            <v-divider class="my-4" />

            <v-row class="my-4">
              <v-col class="fs-12 c-light-black">
                {{ $t('Question ID') }}: {{ localValue.id }}
              </v-col>
            </v-row>
          </template>
        </v-card-text>

        <v-card-actions>
          <v-btn
            @click="$emit('cancel')"
            :loading="processing || saving"
            variant="outlined"
          >
            {{ $t(changed ? 'Cancel' : 'Close') }}
          </v-btn>

          <v-spacer />

          <v-btn
            @click="save"
            :disabled="!changed"
            :loading="processing || saving"
            class="ms-3"
            color="primary"
            data-testid="question-form-save-button"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>

    <template v-else>
      <v-card
        :class="localValue.is_section ? 'bg-white-smoke' : ''"
        class="mx-auto"
        border
        tile
      >
        <v-card-title>
          <v-row
            class="d-flex align-center pb-0 flex-wrap"
            dense
          >
            <v-col cols="1">
              <v-btn
                @click.stop.prevent="$emit('move', -1)"
                :aria-label="$t('Move question up')"
                :loading="processing || saving"
                class="ps-0 me-3"
                size="small"
                variant="text"
                icon
              >
                <v-icon icon="expand_less" />
              </v-btn>

              <v-btn
                @click.stop.prevent="$emit('move', 1)"
                :aria-label="$t('Move question down')"
                :loading="processing || saving"
                size="small"
                variant="text"
                icon
              >
                <v-icon icon="expand_more" />
              </v-btn>
            </v-col>

            <v-col
              class="d-flex justify-end align-center flex-wrap"
              cols="9"
            >
              <v-chip
                v-for="(a, a_index) in localValue.synced_attributes"
                :key="a_index"
                class="mr-2"
                color="secondary"
                prepend-icon="sync"
                variant="outlined"
              >
                {{ a.name }}

                <v-tooltip
                  activator="parent"
                  location="start"
                >
                  {{ $t('Synced Attribute') }}
                </v-tooltip>
              </v-chip>

              <v-chip
                v-for="(c, c_index) in localValue.conditions"
                :key="c_index"
                class="mr-2"
                color="secondary"
                prepend-icon="dynamic_form"
                variant="outlined"
              >
                {{ c.field }}:{{ c.comparator }}{{ c.value ? `:${c.value}` : '' }}
                <v-tooltip
                  activator="parent"
                  location="start"
                >
                  {{ $t('Conditional Attribute') }}
                </v-tooltip>
              </v-chip>
            </v-col>

            <v-col
              class="d-flex justify-end align-center flex-wrap"
              cols="2"
            >
              <QuestionIndicators
                :conditional="conditional"
                :mandatory="localValue.mandatory"
                :published="localValue.published"
                :verification="localValue.verification"
                class="d-flex align-center"
              />

              <v-menu location="bottom left">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    aria-label="Actions"
                    class="ml-3"
                    color="black"
                    icon="more_vert"
                    size="small"
                    variant="text"
                  />
                </template>

                <v-list
                  class="py-0"
                  density="compact"
                  border
                  tile
                >
                  <v-list-item @click="$emit('edit')">
                    <template #prepend>
                      <v-icon
                        color="black"
                        icon="edit"
                      />
                    </template>

                    <v-list-item-title class="fs-14">
                      {{ $t('Edit question') }}
                    </v-list-item-title>
                  </v-list-item>

                  <v-divider />

                  <v-list-item @click="destroy">
                    <template #prepend>
                      <v-icon
                        color="black"
                        icon="delete"
                      />
                    </template>

                    <v-list-item-title class="fs-14">
                      {{ $t('Remove question') }}
                    </v-list-item-title>
                  </v-list-item>

                  <v-divider />

                  <v-list-item @click="duplicate">
                    <template #prepend>
                      <v-icon
                        color="black"
                        icon="content_copy"
                      />
                    </template>

                    <v-list-item-title class="fs-14">
                      {{ $t('Duplicate question') }}
                    </v-list-item-title>
                  </v-list-item>

                  <v-divider />

                  <v-list-item
                    v-if="!localValue.published"
                    @click="publish(true)"
                  >
                    <template #prepend>
                      <v-icon
                        color="black"
                        icon="circle"
                      />
                    </template>

                    <v-list-item-title class="fs-14">
                      {{ $t('Publish question') }}
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="localValue.published"
                    @click="publish(false)"
                  >
                    <template #prepend>
                      <v-icon
                        color="black"
                        icon="hide_source"
                      />
                    </template>

                    <v-list-item-title class="fs-14">
                      {{ $t('Unpublish question') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-title>

        <v-divider />

        <template v-if="!localValue.is_section">
          <v-card-text>
            <template v-if="isAddressQuestion">
              <AddressQuestion
                v-model="testData"
                :question="localValue"
                :schema="schema.definition"
                hide-actions
              />
            </template>

            <template v-if="isTextQuestion">
              <QuestionWithField
                v-model="testData"
                :liquidRenderingEnabled="liquidRenderingEnabled"
                :question="localValue"
                :schema="schema.definition"
                hide-actions
              />
            </template>

            <template v-if="isDocumentQuestion">
              <DocumentQuestion
                v-model="testData"
                :attachments="[]"
                :question="localValue"
                :schema="schema.definition"
                hide-actions
              />
            </template>
          </v-card-text>

          <template v-if="localValue?.answer_key?.length > 0">
            <v-card flat>
              <v-card-text>
                <v-card
                  v-for="(option, ak_index) in localValue?.answer_key"
                  :key="ak_index"
                  class="mb-2"
                  border
                  tile
                >
                  <v-card-title class="fs-16 fw-500">
                    <v-row>
                      <v-col> {{ $t('Answer') }} {{ ak_index + 1 }} </v-col>
                    </v-row>
                  </v-card-title>

                  <v-divider />

                  <v-card-text>
                    <v-row>
                      <LabeledSimpleSelect
                        v-model="option.synced_attribute"
                        :items="localValue.synced_attributes"
                        cols="4"
                        item-title="name"
                        item-value="name"
                        message="Attribute"
                        hide-details
                        readonly
                      />

                      <LabeledTextfield
                        v-model="option.text"
                        cols="4"
                        message="Value"
                        hide-details
                        readonly
                      />

                      <LabeledTextfield
                        v-model.number="option.value"
                        cols="4"
                        message="Score"
                        hide-details
                        readonly
                      />
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </template>
        </template>
      </v-card>
    </template>
  </div>
</template>

<script setup>
import Api from '@/admin/services/bright_finder';
import AddressQuestion from '@/shared/components/form/AddressQuestion.vue';
import CriteriaEditor from '@/shared/components/form/CriteriaEditor.vue';
import DataItem from '@/shared/components/form/DataItem.vue';
import DocumentQuestion from '@/shared/components/form/DocumentQuestion.vue';
import LabeledControl from '@/shared/components/form/LabeledControl.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import QuestionIndicators from '@/shared/components/form/QuestionIndicators.vue';
import QuestionWithField from '@/shared/components/form/QuestionWithField.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import Schema from '@/shared/services/schema';
import useEventBus from '@/shared/composables/useEventBus';
import { ref } from 'vue';

const emit = defineEmits(['cancel', 'change', 'edit', 'move']);
const eventBus = useEventBus();

const props = defineProps({
  answerable: {
    type: Boolean,
    default: false,
  },
  eligibility: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    default: null,
  },
  listLength: {
    type: Number,
    default: null,
  },
  liquidRenderingEnabled: {
    type: Boolean,
    default: true,
  },
  processing: {
    type: Boolean,
    default: false,
  },
  question: {
    type: Object,
    default: null,
  },
  schemas: {
    type: Array,
    default: () => [],
  },
  selected: {
    type: Boolean,
    default: false,
  },
  verification: {
    type: Boolean,
    default: true,
  },
});

const changed = ref(false);
const localValue = ref(JSON.parse(JSON.stringify(props.question)));
const saving = ref(false);
const testData = ref({ custom: {} });

const allAttributesAreBoolean = computed(() => {
  return (
    localValue.value.synced_attributes.length > 1 &&
    localValue.value.synced_attributes.every((field) => {
      return Schema.fieldToProperty(field.name, getSchemaDefinition()).type === 'boolean';
    })
  );
});

const conditional = computed(() => {
  return localValue.value.conditions.length > 0;
});

const isAddressQuestion = computed(() => {
  return localValue.value.format === 'address';
});

const isDocumentQuestion = computed(() => {
  return localValue.value.format === 'document';
});

const isTextQuestion = computed(() => {
  return localValue.value.format === 'text';
});

const fields = computed(() => {
  if (!localValue.value.synced_model) return [];

  return Schema.fields(getSchemaDefinition());
});

const hasSyncedAttributes = computed(() => {
  return isAddressQuestion.value || isTextQuestion.value;
});

const schema = computed(() => {
  return props.schemas.find((schema) => schema.data_type === localValue.value.synced_model);
});

const someAttributesAreBoolean = computed(() => {
  return localValue.value.synced_attributes.some(
    (field) => Schema.fieldToProperty(field.name, getSchemaDefinition()).type === 'boolean',
  );
});

function destroy() {
  // eslint-disable-next-line no-alert
  if (confirm('Are you sure you want to delete this question?')) {
    saving.value = true;

    Api.organization.question.destroy(localValue.value.id, () => {
      emit('change');
      localValue.value = null;
    });
  }
}

function duplicate() {
  saving.value = true;

  const copy = JSON.parse(JSON.stringify(localValue.value));
  copy.leading_question_id = copy.id;
  copy.id = null;
  copy.title = `Copy of ${copy.title}`;

  Api.organization.question.create(copy, () => {
    saving.value = false;
    emit('change');
  });
}

function getSchemaDefinition() {
  if (props.schemas) {
    return props.schemas.find((schema) => schema.data_type === localValue.value.synced_model)
      .definition;
  }
  return schema.value.definition;
}

function publish(newVal) {
  localValue.value.published = newVal;
  save();
}

function removeAnswerKey(index) {
  localValue.value.answer_key.splice(index, 1);
  changed.value = true;
}

function save() {
  saving.value = true;

  Api.organization.question.update(
    localValue.value.id,
    localValue.value,
    (resp) => {
      changed.value = false;
      localValue.value = resp.data;
      saving.value = false;
      emit('cancel');
    },
    (err) => {
      changed.value = false;
      eventBus.chime(err.response.data.errors[0]);
      saving.value = false;
    },
  );
}

function toggleConditions(newVal) {
  if (newVal && localValue.value.conditions.length === 0) {
    localValue.value.conditions.push({});
  } else {
    localValue.value.conditions.splice(0);
  }
}

function updateBooleansAsCheckboxesToggle() {
  localValue.value.enable_display_booleans_as_checkboxes =
    localValue.value.enable_display_booleans_as_checkboxes && allAttributesAreBoolean.value;
}

function updateEligibilityToggle() {
  if (localValue.value.post_preference_selection) {
    localValue.value.eligibility = false;
  }
}

function updatePostPreferenceSelectionToggle() {
  if (localValue.value.eligibility) {
    localValue.value.post_preference_selection = false;
  }
}
</script>
