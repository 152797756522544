<template>
  <v-card
    border
    tile
  >
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col cols="8">
          <div
            @click="$emit('open')"
            class="fs-18 fw-500 underlined c-primary pointer"
            data-cy="agreement-name"
          >
            {{ agreement.name }}
          </div>
          <div
            class="fs-16"
            data-cy="agreement-signer"
          >
            Signer: {{ memberName }}
          </div>
          <div class="fs-12 c-light-black">ID #{{ agreement.id }}</div>
        </v-col>
        <v-col
          class="d-flex align-center justify-end"
          cols="4"
        >
          <div
            class="me-4"
            data-cy="agreement-status"
          >
            <span class="fs-16">
              <v-icon
                color="green"
                start
                >{{ statusMesage[0] }}</v-icon
              >
              <span v-t="statusMesage[1]" />
            </span>
          </div>

          <v-btn
            v-if="!agreement.sent_at"
            @click="sendAgreement"
            :loading="processing"
            class="me-3"
            color="primary"
            data-cy="agreement-send-button"
            variant="flat"
          >
            <span v-t="'Send'" />
          </v-btn>

          <ActionMenu
            @click:action:accept="accept"
            @click:action:delete="destroy"
            @click:action:download="download"
            @click:action:preview="$emit('open')"
            :items="actionItems"
            button-data-cy="agreement-action-button"
            button-icon="more_vert"
            left
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ActionMenu,
  },

  props: {
    agreement: {
      type: Object,
      default: null,
    },
    locked: Boolean,
    members: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['change', 'destroy', 'open'],

  data() {
    return {
      processing: false,
      schema: this.$store.state.schemas[this.agreement.schema_id],
    };
  },

  computed: {
    actionItems() {
      const actions = [];
      if (this.agreement.submitted_at) {
        if (this.agreement.status !== 'Accepted') {
          actions.push({ event: 'accept', title: 'Accept', avatar: 'check' });
        }
        if (!this.schema.meta.signature_template_id) {
          actions.push({ event: 'preview', title: 'View', avatar: 'file_open' });
        }
        actions.push({ event: 'download', title: 'Download', avatar: 'file_download' });
      } else {
        actions.push({ event: 'delete', title: 'Delete', avatar: 'delete' });
        actions.push({ event: 'preview', title: 'Preview', avatar: 'file_open' });
      }
      return actions;
    },

    link() {
      return Api.organization.agreement.downloadUrl(
        this.agreement.id,
        this.agreement.name.replaceAll(' ', '-'),
      );
    },

    memberName() {
      if (this.members && this.members.length > 0) {
        const member = this.members.find(
          (memberObject) => memberObject.id === this.agreement.member_id,
        );
        if (member) return member.name || member.email;

        return 'Unknown signer';
      }
      return null;
    },

    statusMesage() {
      if (this.agreement.submitted_at) {
        if (this.agreement.status === 'Accepted') {
          return ['check_circle', 'Signed and accepted'];
        }
        return ['check', 'Signed'];
      }
      if (!this.agreement.submitted_at && this.agreement.viewed_at) {
        return ['visibility', 'Viewed'];
      }
      if (this.agreement.sent_at && !this.agreement.submitted_at && !this.agreement.viewed_at) {
        return ['email', 'Sent'];
      }
      if (!this.agreement.sent_at) {
        return ['drafts', 'Not sent'];
      }
      return [];
    },
  },

  methods: {
    accept() {
      Api.organization.agreement.update(
        this.agreement.id,
        { status: 'Accepted' },
        () => {
          this.$emit('change');
          this.$eventBus.$emit('chime', 'Agreement acepted');
          this.processing = false;
        },
        (err) => {
          this.$eventBus.$emit('error', err);
          this.processing = false;
        },
      );
    },

    destroy() {
      // eslint-disable-next-line no-alert
      if (!confirm('Are you sure you want to remove this agreement?')) return;

      this.processing = true;
      Api.organization.agreement.destroy(this.agreement.id, () => {
        this.$emit('destroy');
      });
    },

    download() {
      window.open(this.link);
    },

    sendAgreement() {
      this.processing = true;
      Api.organization.agreement.update(
        this.agreement.id,
        { send_requested: true },
        () => {
          this.$emit('change');
          this.$eventBus.$emit('chime', 'Sent');
          this.processing = false;
        },
        (err) => {
          this.$eventBus.$emit('error', err);
          this.processing = false;
        },
      );
    },
  },
};
</script>
