<template>
  <div tabindex="0">
    <div
      :class="isSelf ? 'c-white bg-dark-blue' : 'c-black bg-super-light-blue'"
      class="pa-3 w-90pc b-radius-8"
    >
      <MarkdownContent
        :content="message.text"
        class="fs-16 fw-500 message-container"
      />
    </div>
    <LongDateTime
      :date="message.created_at"
      :prefix="message.meta.member_name + ' - '"
      class="fs-12"
    />
  </div>
</template>

<script>
import LongDateTime from '@/shared/components/LongDateTime.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LongDateTime,
    MarkdownContent,
  },

  props: {
    message: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isSelf() {
      return this.message.member_id === this.$store.state.profile.id;
    },
  },
};
</script>
