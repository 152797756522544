<template>
  <v-container
    v-if="jobConfigs"
    class="mxw-1200 pa-6"
  >
    <v-row>
      <v-col>
        <div class="fw-600 bc-h5">Job Config</div>
      </v-col>
      <v-col
        v-if="jobConfigs.length > 0"
        class="ta-right"
      >
        <v-btn
          @click="$refs.createDialog.open()"
          color="primary"
        >
          Create job config
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="jobConfigs.length == 0"
      class="d-flex justify-center"
      style="margin-top: 15%"
    >
      <v-col class="ta-center">
        <div class="fw-600 fs-20 mb-4">You do not have any job configs.</div>
        <v-btn
          @click="$refs.createDialog.open()"
          color="primary"
          size="x-large"
          rounded
        >
          Create job config
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-for="(jc, index) in jobConfigs"
      :key="index"
    >
      <v-col>
        <v-card
          border
          flat
          tile
        >
          <v-card-text>
            <v-row
              @click="handleNavigationToJobConfig(jc)"
              class="d-flex align-center"
            >
              <v-col>
                <span v-text="jc.attributes.name" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ResourceDialog
      @save="create"
      ref="createDialog"
      :fields="[
        { text: 'Name', value: 'name' },
        {
          text: 'Type',
          value: 'job_type',
          items: jobTypes,
          itemText: 'name',
          itemValue: 'value',
        },
        {
          text: 'Content template',
          value: 'content_template_id',
          items: contentTemplates,
          itemText: 'attributes.name',
          itemValue: 'id',
        },
        {
          text: 'Site',
          value: 'site_id',
          items: sites,
          itemText: 'name',
          itemValue: 'id',
        },
      ]"
      title="New job config"
    />
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ResourceDialog,
  },

  mixins: [API],

  data() {
    return {
      contentTemplates: [],
      jobConfigs: [],
      jobTypes: [
        { name: 'Attendance log reminder', value: 'attendance_log_reminders' },
        { name: 'Provider availability reminder', value: 'provider_availability_reminders' },
      ],
      sites: [],
    };
  },

  created() {
    this.load();
  },

  methods: {
    handleNavigationToJobConfig(jobConfig) {
      this.$router.push({ name: 'JobConfigShow', params: { jobConfigId: jobConfig.id } });
    },

    async create(resourceAttrs) {
      // Strip and restructure attributes per endpoint requirements
      const {
        name,
        job_type,
        content_template_id: contentTemplateId,
        site_id: siteId,
      } = resourceAttrs;
      const attributes = { name, job_type };

      try {
        const res = await this.api.organization.job_config.create(
          attributes,
          contentTemplateId,
          siteId,
        );
        const { data } = res.data;

        this.jobConfigs.push(data);
      } finally {
        this.$refs.createDialog.close();
      }
    },

    async loadContentTemplates() {
      const res = await this.api.organization.content_template.index();
      const { data } = res.data;

      this.contentTemplates = data || [];
    },

    async loadSites() {
      // Site endpoint still uses callback signature

      await this.api.organization.site.index({}, (resp) => {
        this.sites = resp.data;
      });
    },

    async load() {
      const res = await this.api.organization.job_config.index();
      const { data } = res.data;

      this.loadContentTemplates();
      this.loadSites();

      this.jobConfigs = data || [];
    },
  },
};
</script>
