export default {
  data() {
    return {
      sortDirection: null,
      sortField: null,
    };
  },

  methods: {
    toggleSort(field) {
      if (this.sortField != field) {
        this.sortDirection = 'desc';
      } else {
        this.sortDirection = this.sortDirection == 'asc' ? 'desc' : 'asc';
      }
      this.sortField = field;
      this.page = 1;
      this.load();
    },
  },
};
