<template>
  <v-container
    v-if="lesson && course"
    class="mxw-1200"
  >
    <TitleBar
      :back-back-route="{
        name: 'LearningIndex',
        query: {
          tab: 'courses',
        },
      }"
      :back-name="course.name"
      :back-route="{
        name: 'CourseShow',
        params: {
          courseId: course.id,
        },
        query: {
          tab: 'lessons',
        },
      }"
      :name="lesson.name"
      back-back-name="Courses"
    />

    <v-card
      class="mb-4"
      border
      flat
      tile
    >
      <v-card-title>
        {{ $t('Settings') }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <LabeledTextfield
            v-model="lesson.name"
            @input="changed = true"
            message="Name"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      class="mb-4"
      border
      flat
      tile
    >
      <v-card-title>
        {{ $t('Assignments') }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <NullState
          v-if="assignments.length == 0"
          @new="$refs.assignmentDialog.open({})"
          new-button-title="New assignment"
          title="No assignments"
        />

        <v-card
          v-for="(assignment, index) in assignments"
          @click="$refs.assignmentCard.open(assignment)"
          :key="assignment.id"
          class="mb-2"
          border
          flat
          tile
        >
          <v-card-text>
            <v-row
              class="d-flex align-center"
              dense
            >
              <v-col cols="8">
                <div v-text="[index + 1, assignment.name].join('. ')" />
              </v-col>

              <v-col
                class="ta-right"
                cols="4"
              >
                <v-btn
                  @click.stop.prevent="reorder(index, index - 1)"
                  :aria-label="$t('Move up')"
                  :disabled="!(assignments.length > 0) || index == 0"
                  :loading="processing"
                  class="me-3"
                  icon="expand_less"
                  variant="outlined"
                />

                <v-btn
                  @click.stop.prevent="reorder(index, index + 1)"
                  :aria-label="$t('Move down')"
                  :disabled="!(assignments.length > index + 1)"
                  :loading="processing"
                  icon="expand_more"
                  variant="outlined"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-actions>
        <AssignmentCard
          @destroy="load()"
          ref="assignmentCard"
          :course="course"
          class="mb-2"
        />

        <template v-if="assignments.length > 0">
          <NewButton
            @click="$refs.assignmentDialog.open({})"
            :processing="processing"
            class="mt-4"
            text="New assignment"
          />
        </template>
      </v-card-actions>
    </v-card>

    <ResourceDialog
      @save="createAssignment"
      ref="assignmentDialog"
      :fields="[{ text: 'Name', value: 'name' }]"
      :processing="processing"
      title="New assignment"
      close-on-save
    />

    <v-card
      class="mb-4"
      border
      flat
      tile
    >
      <v-card-title>
        {{ $t('Quiz') }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <LabeledSwitch
            v-model="quizEnabled"
            @input="changed = true"
            subtitle="Student will be required to pass a quiz before completing the lesson."
            title="Enable quiz"
          />
        </v-row>

        <template v-if="quizEnabled">
          <v-row>
            <LabeledSimpleSelect
              v-model="lesson.quiz_schema_id"
              @update:model-value="changed = true"
              :items="quizSchemas"
              item-title="name"
              item-value="id"
              message="Quiz"
            />
          </v-row>

          <v-row>
            <v-col>
              <h5 class="labeled-input mb-2">
                {{ $t('Grade scale') }}
              </h5>
            </v-col>
          </v-row>

          <v-card
            v-for="(scale, index) in lesson.grade_scale"
            :key="index"
            class="mb-2"
            border
            flat
            tile
          >
            <v-card-text>
              <v-row>
                <LabeledTextfield
                  v-model.number="scale.min_score"
                  @input="changed = true"
                  cols="12"
                  maxlength="2"
                  message="Minimum score"
                  type="number"
                />

                <LabeledTextarea
                  v-model="scale.display_text"
                  @input="changed = true"
                  cols="12"
                  message="Display text"
                />

                <LabeledSwitch
                  v-model="scale.passing"
                  @input="changed = true"
                  subtitle="Scores equal to and above this minimum score will pass."
                  title="Passing"
                />
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-btn
                @click="removeGradeScale(index)"
                color="red"
                size="small"
              >
                {{ $t('Remove') }}
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-row class="mt-2">
            <v-col>
              <v-btn
                @click="addGradeScale"
                color="primary"
              >
                {{ $t('Add') }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>

    <DangerBar
      @destroy="destroy"
      :processing="processing"
    />

    <SaveBar
      @cancel="load"
      @save="save"
      :model-value="changed"
      :processing="processing"
    />
  </v-container>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import AssignmentCard from '@/specialist/components/AssignmentCard.vue';
import DangerBar from '@/shared/components/DangerBar.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import NewButton from '@/shared/components/NewButton.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';

const assignments = ref([]);
const changed = ref(false);
const course = ref(null);
const eventBus = useEventBus();
const lesson = ref(null);
const processing = ref(false);
const route = useRoute();
const router = useRouter();
const quizEnabled = ref(false);
const quizSchemas = ref([]);

watch(
  () => route.params.lessonId,
  () => {
    load();
  },
  { immediate: true },
);

onMounted(() => {
  loadQuizSchemas();
});

function addGradeScale() {
  lesson.value.grade_scale.push({
    display_text: null,
    min_score: null,
    passing: false,
  });
}

function createAssignment(newAssignment) {
  processing.value = true;
  api.organization.assignment.create(
    route.params.lessonId,
    { name: newAssignment.name },
    (response) => {
      processing.value = false;
      assignments.value.push(response.data);
      lesson.value.assignment_sequence.push(response.data.id);
    },
    (error) => {
      processing.value = false;
      eventBus.chime(error.response.data.errors[0]);
    },
  );
}

function destroy() {
  processing.value = true;
  api.organization.lesson.destroy(
    route.params.courseId,
    route.params.lessonId,
    () => {
      router.push({ name: 'CourseShow', params: { courseId: route.params.courseId } });
    },
    (error) => {
      eventBus.chime(error.response.data.errors[0]);
    },
  );
}

function load() {
  api.public_api.organization.course.get(route.params.courseId, (response) => {
    course.value = response.data;

    api.organization.lesson.get(route.params.courseId, route.params.lessonId, (lessonResp) => {
      lesson.value = lessonResp.data;
      quizEnabled.value = !!lessonResp.data.quiz_schema_id;

      api.organization.assignment.index(route.params.lessonId, (assignmentResp) => {
        assignments.value = assignmentResp.data;
        changed.value = false;
        processing.value = false;
      });
    });
  });
}

function loadQuizSchemas() {
  api.organization.schema.index({ data_type: 'Quiz' }, (response) => {
    quizSchemas.value = response.data;
  });
}

function removeGradeScale(index) {
  lesson.value.grade_scale.splice(index, 1);
  changed.value = true;
}

function reorder(fromIndex, toIndex) {
  const elementId = lesson.value.assignment_sequence[fromIndex];
  lesson.value.assignment_sequence.splice(fromIndex, 1);
  lesson.value.assignment_sequence.splice(toIndex, 0, elementId);
  save();
}

function save() {
  if (!validate()) return;

  if (!quizEnabled.value) {
    lesson.value.quiz_schema_id = null;
    lesson.value.grade_scale = [];
  }

  processing.value = true;
  api.organization.lesson.update(
    route.params.courseId,
    route.params.lessonId,
    lesson.value,
    (response) => {
      lesson.value = response.data;
      load();
    },
    (error) => {
      processing.value = false;
      eventBus.chime(error.response.data.errors[0]);
    },
  );
}

function validate() {
  if (!quizEnabled.value) {
    // eslint-disable-next-line no-alert
    if (lesson.value.quiz_schema_id)
      return confirm('Are you sure that you want to remove the quiz from this lesson?');
    return true;
  }

  const errors = [];
  if (!lesson.value.quiz_schema_id) {
    errors.push('You must specify a Quiz');
  }
  if (!lesson.value.grade_scale.some((scale) => scale.passing)) {
    errors.push('You need at least 1 passing score');
  }
  if (lesson.value.grade_scale.some((scale) => !scale.display_text || scale.min_score == null)) {
    errors.push('Scores require a minimum score and display text');
  }

  if (errors.length > 0) {
    changed.value = false;
    eventBus.error(errors);
    return false;
  }

  return true;
}
</script>
