<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle title="Locations" />

    <v-toolbar
      class="mb-3 px-4 bb-1"
      extension-height="60"
    >
      <v-row>
        <v-col class="mxw-400">
          <v-select
            v-model="filters.schema_id"
            @update:model-value="updateFilters"
            :aria-label="schema?.name"
            :items="schemas"
            density="compact"
            item-title="name"
            item-value="id"
            variant="filled"
            hide-details
          />
        </v-col>
        <v-col class="mxw-340">
          <v-text-field
            v-model="filters.query"
            @update:model-value="updateFilters"
            id="search_link"
            :aria-label="$t('Filter results by name')"
            :placeholder="$t('Filter results by name')"
            color="secondary"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
            tracked
          />
        </v-col>
      </v-row>

      <template
        v-if="$route.query.schema_id"
        #extension
      >
        <v-row
          class="d-flex align-center"
          dense
        >
          <v-col>
            <FilterMenu
              :active="!!filters.team_id"
              classes="d-none d-md-inline-flex"
              title="Team"
            >
              <template #card>
                <v-autocomplete
                  v-model="filters.team_id"
                  @update:model-value="updateFilters"
                  :items="teams"
                  item-title="name"
                  item-value="id"
                  variant="filled"
                  clearable
                  hide-details
                  hide-no-data
                />
              </template>
            </FilterMenu>

            <FilterMenu
              :active="!!filters.county"
              classes="d-none d-md-inline-flex"
              title="County"
            >
              <template #card>
                <v-autocomplete
                  v-model="filters.county"
                  @update:model-value="updateFilters"
                  :items="counties"
                  variant="filled"
                  clearable
                  hide-details
                  hide-no-data
                />
              </template>
            </FilterMenu>
          </v-col>

          <v-col class="d-flex align-center justify-end">
            <template v-if="$store.state.profile.org_providers_admin">
              <v-btn
                @click="$refs.teamDialog.open()"
                :disabled="!(selectAll || selected.length > 0)"
                color="primary"
                variant="flat"
              >
                <span v-t="'Assign'" />
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </template>
    </v-toolbar>

    <v-container
      class="px-3 py-0"
      fluid
    >
      <v-card
        class="bb-1 pa-0"
        border
        flat
        tile
      >
        <v-card-text>
          <v-row role="row">
            <SortableTableHeader
              @check="selectAll = !selectAll"
              @toggle="toggleSort($event)"
              :check-value="selectAll"
              :checkable="isAdmin"
              :sort-dir="$route.query.sort_dir"
              :sort-field="$route.query.sort_field"
              cols="3"
              field="name"
              title="Name"
              check-locked
            />

            <SortableTableHeader
              @toggle="toggleSort($event)"
              :sort-dir="$route.query.sort_dir"
              :sort-field="$route.query.sort_field"
              cols="2"
              field="state_provider_id"
              title="License #"
            />

            <SortableTableHeader
              @toggle="toggleSort($event)"
              :sort-dir="$route.query.sort_dir"
              :sort-field="$route.query.sort_field"
              cols="2"
              field="address1"
              title="Address"
            />

            <SortableTableHeader
              @toggle="toggleSort($event)"
              :sort-dir="$route.query.sort_dir"
              :sort-field="$route.query.sort_field"
              cols="2"
              field="county"
              title="County"
            />

            <SortableTableHeader
              @toggle="toggleSort($event)"
              :sort-dir="$route.query.sort_dir"
              :sort-field="$route.query.sort_field"
              field="business_id"
              md="2"
              title="Business"
            />

            <SortableTableHeader
              @toggle="toggleSort($event)"
              :sort-dir="$route.query.sort_dir"
              :sort-field="$route.query.sort_field"
              cols="1"
              field="team_id"
              title="Team"
            />
          </v-row>
        </v-card-text>
      </v-card>

      <div
        v-show="processing"
        class="mxw-800 mx-auto py-12 ta-center"
      >
        <v-progress-circular
          color="primary"
          size="75"
          indeterminate
        />
      </div>

      <div v-show="!processing">
        <v-card
          v-for="provider in providers"
          :key="provider.id"
          border
          flat
          tile
        >
          <v-card-text>
            <v-row
              :key="provider.id"
              role="row"
            >
              <TableCell
                @check="select(provider.id)"
                :check-value="selectAll || selected.includes(provider.id)"
                :checkable="isAdmin"
                :content="provider.name"
                :to="{
                  name: 'ProviderShow',
                  params: {
                    providerId: provider.id,
                  },
                  query: {
                    tab: 'profile',
                  },
                }"
                cols="3"
                target="_self"
                check-locked
              />
              <TableCell
                :content="provider.state_provider_id"
                cols="2"
              />
              <TableCell
                :content="provider.address1"
                cols="2"
              />
              <TableCell
                :content="provider.county"
                cols="2"
              />
              <TableCell
                :content="provider.business_name"
                cols="2"
              />
              <TableCell cols="1">
                <v-tooltip location="bottom">
                  <template #activator="{ props }">
                    <span
                      v-text="teamName(provider.team_name)"
                      v-bind="props"
                    />
                  </template>
                  <span v-text="provider.team_name" />
                </v-tooltip>
              </TableCell>
            </v-row>
          </v-card-text>
        </v-card>

        <NullState
          v-if="providers.length == 0"
          title="No providers found"
          hide-new-button
        />

        <v-pagination
          v-show="pages > 1"
          v-model="filters.page"
          @update:model-value="updateFilters(true)"
          :length="pages"
          :total-visible="8"
          class="mt-4"
        />
      </div>

      <ResourceDialog
        @save="assign"
        ref="teamDialog"
        :fields="[
          { text: 'Team', value: 'team_id', items: teams, itemText: 'name', itemValue: 'id' },
        ]"
        :processing="processing"
        title="Assign"
      />

      <VerticalSpacer :min-height="50" />
    </v-container>
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import NullState from '@/shared/components/NullState.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import RouterHelper from '@/shared/mixins/RouterHelper';
import SortableTableHeader from '@/shared/components/SortableTableHeader.vue';
import TableCell from '@/shared/components/TableCell.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { toAcronym } from '@/shared/services/string_utils';

export default {
  compatConfig: { MODE: 3 },

  components: {
    FilterMenu,
    NullState,
    PageTitle,
    ResourceDialog,
    SortableTableHeader,
    TableCell,
    VerticalSpacer,
  },

  mixins: [API, RouterHelper],

  data() {
    return {
      counties: [],
      isAdmin: false,
      processing: false,
      providers: [],
      selectAll: false,
      selected: [],
      filters: this.getFiltersFromQuery({ paged: true }),
      pages: 0,
      providerQueryCount: 0,
      schemas: [],
      schema: null,
      teamDialogIsVisible: false,
      teams: [],
    };
  },

  created() {
    this.isAdmin = this.$store.state.profile.org_providers_admin;
    this.load();
  },

  methods: {
    async assign(newVal) {
      this.processing = true;
      const ids = this.selectAll ? this.providers.map((provider) => provider.id) : this.selected;
      await Promise.all(
        ids.map(async (id) => {
          await this.api.organization.provider.update(id, { team_id: newVal.team_id });
        }),
      );
      this.$refs.teamDialog.close();
      this.loadProviders();
    },

    load() {
      this.loadSchemas();
      this.loadTeams();
      if (this.$route.query.schema_id) {
        this.updateFilters();
      }
    },

    loadProviders() {
      this.processing = true;
      this.selected.splice(0);
      this.selectAll = false;
      const currentProviderQueryCount = this.providerQueryCount;
      this.api.organization.provider.index({ page_size: 200, ...this.$route.query }, (resp) => {
        if (currentProviderQueryCount === this.providerQueryCount) {
          this.providers = resp.data;
          this.processing = false;
          this.pages = parseInt(resp.headers['x-page-count'] || 0, 10);
        }
      });
    },

    loadSchemas() {
      this.processing = true;
      this.api.organization.schema.index({}, (resp) => {
        this.schemas = resp.data.filter((schema) => schema.data_type === 'Provider');
        if (!this.filters.schema_id) {
          this.filters.schema_id = this.schemas[0].id;
          this.updateFilters();
        }
      });
    },

    loadTeams() {
      this.api.organization.team.index({}, (resp) => {
        this.teams = resp.data;
      });
    },

    select(id) {
      if (this.selected.includes(id)) {
        this.selected.splice(this.selected.indexOf(id), 1);
      } else {
        this.selected.unshift(id);
      }
    },

    teamName(name) {
      return toAcronym(name);
    },

    async toggleSort(field) {
      let newSortDir;
      if (this.$route.query.sort_field !== field) {
        newSortDir = 'desc';
      } else if (this.$route.query.sort_dir === 'desc') {
        newSortDir = 'asc';
      } else {
        newSortDir = 'desc';
      }
      await this.updateQuery({ sort_field: field, sort_dir: newSortDir });
      this.loadProviders();
    },

    async updateFilters(paged = false) {
      if (!paged) {
        this.filters.page = 1;
      }

      this.schema = this.$store.state.schemas[this.filters.schema_id];
      if (this.schema) {
        this.counties = this.schema.definition.properties.county.enum;
      }

      await this.updateQuery({ ...this.filters });
      this.loadProviders();
    },
  },
};
</script>
