<template>
  <v-container
    v-if="brand"
    class="mxw-1200 pa-6"
  >
    <TitleBar
      :back-route="{ name: 'BrandIndex' }"
      :name="brand.name"
      back-name="Brands"
    />

    <v-card
      v-if="brand"
      class="pa-6"
      border
      flat
      tile
    >
      <BrandForm :brand="brand" />
    </v-card>
  </v-container>
</template>

<script setup>
import Api from '@/admin/services/bright_finder';
import BrandForm from '@/admin/views/brands/BrandForm.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import { useRoute } from 'vue-router';

const brand = ref(null);
const route = useRoute();

onMounted(() => {
  void loadBrand();
});

async function loadBrand() {
  const resp = await Api.organization.brand.get(route.params.brandId);
  brand.value = resp.data;
}
</script>
