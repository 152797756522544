<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div
          @click="$router.push({ name: 'SiteTemplateIndex' })"
          class="fw-600 bc-h5 d-inline-block me-2 bc-link-hover"
        >
          Templates
        </div>
        <div class="d-inline-block me-2">
          <v-icon class="fs-26 c-black va-sub"> chevron_right </v-icon>
        </div>
        <div
          v-text="site_template.name"
          class="fw-600 bc-h5 d-inline-block me-2"
        />
        <div class="fw-600 bc-h5 d-inline-block me-2">
          <v-icon class="c-black"> chevron_right </v-icon>
        </div>
        <div class="fw-600 bc-h5 d-inline-block me-2">New Page</div>
        <div class="fw-600 bc-h5 d-inline-block me-2">Page</div>
      </v-col>
    </v-row>
    <PageTemplateForm
      @save="$emit('refresh')"
      :page_template="blank_template"
      :site_template="site_template"
    />
  </v-container>
</template>

<script>
import PageTemplateForm from '@/admin/components/page-templates/PageTemplateForm.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    PageTemplateForm,
  },

  props: {
    // eslint-disable-next-line vue/prop-name-casing
    site_template: {
      type: Object,
      default: () => {},
    },
  },

  emits: ['refresh'],

  data() {
    return {
      blank_template: { name: null },
    };
  },
};
</script>
