<template>
  <v-container>
    <TitleBar
      :back-route="{ name: 'SchemaUploads', params: { schemaId: $route.query.schemaId } }"
      back-name="Syncs"
      class="mb-4"
      name="New sync"
    />

    <v-card
      border
      flat
      tile
    >
      <v-card-text class="mb-4">
        <v-row>
          <LabeledTextfield
            v-model="name"
            message="Name"
          />

          <LabeledSimpleSelect
            v-model="syncType"
            :items="syncTypes"
            message="Select a sync type"
          />

          <template v-if="syncType == 'upload'">
            <LabeledSimpleSelect
              v-model="fileType"
              :items="fileTypes"
              message="Select a file type"
            />

            <LabeledControl message="Attach file">
              <v-file-input
                v-model="file"
                color="primary"
                label="Attach file"
                variant="filled"
                hide-details
              />
            </LabeledControl>
          </template>

          <template v-if="syncType == 'sftp'">
            <LabeledTextfield
              v-model="fileName"
              message="Filename"
            />
            <LabeledTextfield
              v-model="host"
              message="Host"
            />
            <LabeledTextfield
              v-model="username"
              message="Username"
            />
            <LabeledTextfield
              v-model="password"
              message="Password"
            />
          </template>

          <template v-if="syncType == 'remoteUrl'">
            <LabeledSimpleSelect
              v-model="fileType"
              :items="fileTypes"
              message="Select a file type"
            />
            <LabeledTextfield
              v-model="remoteFileUrl"
              message="Remote file url"
            />
          </template>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="submit()"
          :disabled="!syncType"
          :loading="processing"
          color="primary"
          block
        >
          {{ $t('Upload') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';

export default {
  compatConfig: { MODE: 2 },

  mixins: [API],

  data() {
    return {
      data_type: this.$route.query.data_type,
      processing: false,
      file: null,
      fileName: null,
      fileType: null,
      fileTypes: [
        { text: 'Comma Separated Values (CSV)', value: 'csv' },
        { text: 'Pipe Delimited Values', value: 'pdv' },
      ],
      host: null,
      name: null,
      password: null,
      remoteFileUrl: null,
      syncType: null,
      syncTypes: [
        { text: 'File upload', value: 'upload' },
        { text: 'Remote file URL', value: 'remoteUrl' },
        { text: 'Retrieve from SFTP', value: 'sftp' },
      ],
      url: null,
      username: null,
    };
  },

  methods: {
    createUploadParams(fileUploadEvent) {
      const params = {
        name: this.name,
        schema_id: this.$route.query.schemaId,
        file_type: this.fileType,
      };

      if (this.syncType === 'upload' && fileUploadEvent) {
        params.content = fileUploadEvent.target.result;
        return params;
      }

      if (this.syncType === 'remoteUrl') {
        if (!this.remoteFileUrl || !this.fileType) {
          return this.$eventBus.$emit('chime', 'Missing required parameters');
        }

        params.remote_file_url = this.remoteFileUrl;
        return params;
      }

      if (this.syncType === 'sftp') {
        if (!this.fileName || !this.username || !this.password || !this.host) {
          return this.$eventBus.$emit('chime', 'Missing required SFTP parameters');
        }

        params.sftp_file_name = this.fileName;
        params.sftp_username = this.username;
        params.sftp_password = this.password;
        params.sftp_host = this.host;
        return params;
      }

      return this.$eventBus.$emit('chime', 'Cannot process sync of unknown content or type');
    },

    submit() {
      if (this.syncType === 'upload') {
        this.processing = true;
        const reader = new FileReader();
        reader.readAsText(this.file, 'UTF-8');
        reader.onload = this.save;
      } else {
        this.save();
      }
    },

    save(fileUploadEvent) {
      const params = this.createUploadParams(fileUploadEvent);
      if (!params) return;

      this.processing = true;
      this.api.organization.upload.create(
        params,
        (resp) => {
          this.$eventBus.$emit('chime', 'File upload currently processing.');
          this.processing = false;
          this.$router.push({ name: 'UploadShow', params: { uploadId: resp.data.id } });
        },
        (error) => {
          this.$eventBus.$emit('error', error);
          this.processing = false;
        },
      );
    },
  },
};
</script>
