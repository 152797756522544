<template>
  <v-card class="pa-6">
    <v-row dense>
      <v-col cols="12">
        <div class="fs-28 fw-600 c-black mb-3">Danger</div>
      </v-col>
      <v-col
        class="mb-3"
        cols="12"
      >
        <div class="fs-18 fw-600 c-black">Be careful! This cannot be undone.</div>
      </v-col>
      <v-col cols="12">
        <v-btn
          @click="destroy()"
          :disabled="processing"
          color="red"
          rounded
        >
          Delete site template
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import Api from '@/admin/services/bright_finder';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();
const processing = ref(false);

const props = defineProps({
  // eslint-disable-next-line vue/prop-name-casing
  site_template: {
    type: Object,
    required: true,
  },
});

function destroy() {
  processing.value = true;
  Api.organization.siteTemplate.destroy(
    props.site_template.id,
    () => {
      props.$router.push({ name: 'SiteTemplateIndex' });
    },
    (err) => {
      eventBus.chime(err.response.data.errors[0]);
    },
  );
}
</script>
