export default [
  {
    title: 'Settings',
    route: 'ScreenerProgramShow',
  },
  {
    title: 'Questions',
    route: 'ScreenerProgramQuestions',
  },
];
