<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card class="px-6 pt-0 pb-6">
    <v-row dense>
      <v-col cols="12">
        <div class="fs-28 fw-600 c-black mb-3" />
      </v-col>
      <v-col cols="12">
        <div class="fs-18 fw-600 c-black">What is the name of this template?</div>
      </v-col>
      <v-col
        class="mb-4"
        cols="12"
      >
        <v-text-field
          v-model="page_template.name"
          placeholder="Input text"
          variant="solo"
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <div class="fs-18 fw-600 c-black">What does this page do?</div>
      </v-col>
      <v-col
        class="mb-4"
        cols="12"
      >
        <v-textarea
          v-model="page_template.description"
          placeholder="Description"
          variant="solo"
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-btn
          @click="submit()"
          :disabled="processing"
          color="primary"
          rounded
        >
          {{ page_template.id ? 'Save' : 'Create' }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Api from '@/admin/services/bright_finder';

export default {
  compatConfig: { MODE: 2 },

  props: {
    // eslint-disable-next-line vue/prop-name-casing
    site_template: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    page_template: {
      type: Object,
      required: true,
    },
  },

  emits: ['save'],

  data() {
    return {
      name: null,
      processing: false,
    };
  },

  methods: {
    submit() {
      this.processing = true;
      Api.organization.siteTemplate.pageTemplate.updateOrCreate(
        this.site_template.id,
        {
          description: this.page_template.description,
          id: this.page_template.id,
          name: this.page_template.name,
        },
        (resp) => {
          this.$emit('save');
          if (this.page_template.id) {
            this.$eventBus.$emit('chime', 'Page saved.');
            this.processing = false;
          } else {
            this.$router.push({
              name: 'PageTemplateShow',
              params: { siteTemplateId: this.site_template.id, pageTemplateId: resp.data.id },
            });
            this.processing = false;
          }
        },
        (err) => {
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
          this.processing = false;
        },
      );
    },
  },
};
</script>
