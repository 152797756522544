<template>
  <div v-if="familySubsidyProgram && assignmentConfigurations">
    <BreadcrumbTitle
      :back-name="familySubsidyProgram.name"
      name="Assignment configurations"
    />

    <v-divider class="my-8" />

    <v-card
      v-for="assignmentConfiguration in assignmentConfigurations"
      :key="assignmentConfiguration.id"
      border
      tile
    >
      <v-card-text>
        <v-row class="d-flex align-center">
          <v-col>
            <span>
              {{ $t(assignmentConfiguration.name) }}
            </span>
          </v-col>

          <v-col class="d-flex justify-end">
            <v-btn
              @click="edit(assignmentConfiguration)"
              class="me-3"
              color="primary"
              variant="flat"
            >
              {{ $t('Edit') }}
            </v-btn>

            <v-btn
              @click="remove(assignmentConfiguration)"
              color="red"
              variant="outlined"
            >
              {{ $t('Delete') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <template v-if="assignmentConfigurations.length == 0">
      <NullState @new="draft" />
    </template>

    <template v-else>
      <v-btn
        @click="draft"
        class="mt-4"
        color="primary"
      >
        <span>
          {{ $t('New') }}
        </span>
      </v-btn>
    </template>

    <ResourceDialog
      @save="save"
      ref="dialog"
      :fields="fields"
      :processing="processing"
      save-button-text="Save"
      title="Assignment configuration"
    >
      <template #append="{ localValue }">
        <v-divider class="my-3" />

        <h3 v-t="'Conditions'" />

        <v-row
          v-for="(condition, index) in localValue.conditions"
          :key="index + condition.title"
          class="mt-5"
        >
          <LabeledTextfield
            v-model="condition.title"
            message="Field"
            readonly
          />

          <LabeledAutocomplete
            v-if="condition.boolean"
            v-model="condition.value"
            :items="[
              { text: 'true', value: true },
              { text: 'false', value: false },
            ]"
            message="Value"
            clearable
            filled
          />

          <LabeledTextfield
            v-else
            v-model="condition.value"
            message="Value"
          />
        </v-row>
      </template>
    </ResourceDialog>
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder.js';
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { useRoute } from 'vue-router';
import _ from 'lodash';

const route = useRoute();
const conditionDefaults = [
  {
    boolean: false,
    title: 'home_county',
    field: ['home_county'],
    value: null,
  },
];
const processing = ref(false);
const fields = ref([]);
const assignmentConfigurations = ref(null);
const familySubsidyProgram = ref(null);
const teams = ref([]);
const dialog = ref(null);

onMounted(async () => {
  await loadFamilySubsidyProgram();
  await loadTeams();
  await loadAssignmentConfigurations();
});

async function draft() {
  await loadTeams();
  fields.value = getFields();
  dialog.value.open({
    conditions: conditionDefaults,
    owner_id: route.params.id,
    owner_type: 'FamilySubsidyProgram',
    target_type: 'Team',
  });
}

function edit(assignmentConfiguration) {
  fields.value = getFields();

  const mergedConditions = conditionDefaults.map((conditionDefault) => {
    const existingCondition = assignmentConfiguration?.conditions?.find(
      (condition) => condition.field.join(',') === conditionDefault.field.join(','),
    );

    if (existingCondition && existingCondition.value !== '') {
      return {
        ...conditionDefault,
        value: existingCondition.value,
      };
    }

    return conditionDefault;
  });

  assignmentConfiguration.conditions = mergedConditions;

  dialog.value.open(assignmentConfiguration);
}

function getFields() {
  return [
    {
      text: 'Name',
      value: 'name',
      required: true,
    },
    {
      text: 'Team',
      value: 'target_id',
      items: teams.value,
      itemText: 'name',
      itemValue: 'id',
      required: true,
    },
  ];
}

async function loadAssignmentConfigurations() {
  const response = await Api.organization.assignment_configuration.index({
    owner_id: route.params.id,
  });
  assignmentConfigurations.value = response.data;
}

async function loadFamilySubsidyProgram() {
  const response = await Api.organization.family_subsidy_program.get(route.params.id);
  familySubsidyProgram.value = response.data;
}

async function loadTeams() {
  const response = await Api.organization.team.index();
  teams.value = response.data;
}

async function remove(assignmentConfiguration) {
  if (!confirm('Are you sure you want to remove this assignment configuration type?')) return;
  processing.value = true;

  await Api.organization.assignment_configuration.destroy(assignmentConfiguration);
  await loadAssignmentConfigurations();

  processing.value = false;
}

async function save(newValue) {
  processing.value = true;

  const params = newValue;
  params.conditions = _.reject(params.conditions, ['value', null]);
  if (params.id) {
    await Api.organization.assignment_configuration.update(params);
  } else {
    await Api.organization.assignment_configuration.create(params);
  }
  dialog.value?.close();
  await loadAssignmentConfigurations();

  processing.value = false;
}
</script>
