<template>
  <div v-if="loaded">
    <BreadcrumbTitle
      :back-name="'Functions'"
      :back-route="{ name: 'SchemaFunctionIndex', params: { schemaId: schemaId } }"
      :name="schemaFunctionName"
    >
      <template #actions>
        <v-btn
          @click="createSchemaFunctionTestSuiteRun"
          :disabled="changed"
          :loading="processing"
          color="primary"
        >
          {{ $t('Run tests') }}
        </v-btn>

        <ActionMenu
          @click:action:edit-name="handleEditName"
          :items="[{ event: 'edit-name', title: 'Edit name' }]"
          button-icon="more_vert"
        />
      </template>
    </BreadcrumbTitle>

    <StandardCard
      @click:action:save="saveCode"
      :actions="schemaFunctionActions"
      :processing="processing"
      class="mb-4"
      title="Code"
    >
      <v-row>
        <LabeledFormulaField
          v-model="schemaFunctionCode"
          @update:model-value="handleCodeChange"
        />
      </v-row>
    </StandardCard>

    <SchemaFunctionTest
      v-for="schemaFunctionTest in schemaFunctionTests"
      @destroy="loadSchemaFunctionTests"
      :key="schemaFunctionTest.id"
      :value="schemaFunctionTest"
      class="mb-4"
      data-testid="schema-function-test"
    />

    <NullState
      v-if="schemaFunctionTests.length == 0"
      @new="createSchemaFunctionTestDialog.open()"
      new-button-title="Add test"
    />

    <v-btn
      v-if="schemaFunctionTests.length > 0"
      @click="createSchemaFunctionTestDialog.open()"
      color="primary"
    >
      {{ $t('Add test') }}
    </v-btn>

    <ResourceDialog
      @save="createSchemaFunctionTest"
      ref="createSchemaFunctionTestDialog"
      :fields="createSchemaFunctionTestFields"
      :processing="processing"
      title="New test"
    />

    <ResourceDialog
      @save="saveName"
      ref="editNameDialog"
      :fields="[{ value: 'name', text: 'Name', required: true }]"
      :processing="processing"
      title="Edit name"
    />
  </div>
</template>

<script setup>
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import LabeledFormulaField from '@/shared/components/form/LabeledFormulaField.vue';
import NullState from '@/shared/components/NullState.vue';
import organizationApi from '@/specialist/services/bright_finder';
import { useRoute } from 'vue-router';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SchemaFunctionTest from '@/specialist/components/SchemaFunctionTest.vue';
import StandardCard from '@/shared/components/StandardCard.vue';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const route = useRoute();

const changed = ref(false);
const createSchemaFunctionTestDialog = ref(null);
const editNameDialog = ref(null);
const loaded = ref(false);
const processing = ref(false);
const schemaId = ref(null);
const schemaFunctionId = ref(null);
const schemaFunctionName = ref(null);
const schemaFunctionCode = ref(null);
const schemaFunctionTests = ref([]);
const createSchemaFunctionTestFields = [{ value: 'name', text: 'Name' }];

const schemaFunctionActions = computed(() => {
  if (!changed.value) return [];

  return [{ event: 'save', outlined: true, title: 'Save' }];
});

onMounted(async () => {
  await load();
});

async function createSchemaFunctionTest(newValues) {
  processing.value = true;
  const resp = await organizationApi.organization.schema_function_test.create(
    schemaFunctionId.value,
    newValues.name,
  );
  processing.value = false;
  if (resp?.status != 201) return;

  eventBus.chime('Created');
  createSchemaFunctionTestDialog.value.close();
  await loadSchemaFunctionTests();
}

async function createSchemaFunctionTestSuiteRun() {
  processing.value = true;
  const resp = await organizationApi.organization.schema_function_test_suite_run.create(
    schemaFunctionId.value,
  );
  if (resp?.status != 201) return;

  eventBus.chime('Running tests');
  setTimeout(async () => {
    await loadSchemaFunctionTests();
    processing.value = false;
  }, 4000);
}

function handleCodeChange() {
  changed.value = true;
}

function handleEditName() {
  editNameDialog.value.open({ name: schemaFunctionName.value });
}

async function load() {
  loaded.value = false;
  schemaId.value = route.params.schemaId;
  schemaFunctionId.value = route.params.schemaFunctionId;
  await loadSchemaFunction();
  await loadSchemaFunctionTests();
  loaded.value = true;
}

async function loadSchemaFunction() {
  const { data } = await organizationApi.organization.schema_function.get(schemaFunctionId.value);
  schemaFunctionName.value = data.name;
  schemaFunctionCode.value = data.code;
}

async function loadSchemaFunctionTests() {
  const { data } = await organizationApi.organization.schema_function_test.index(
    schemaFunctionId.value,
  );
  schemaFunctionTests.value = data.map((test) => ({
    ...test,
    expected_value: JSON.stringify(test.expected_value),
    test_data: JSON.stringify(test.test_data),
    test_mocks: JSON.stringify(test.test_mocks),
    last_actual_value: JSON.stringify(test.last_actual_value),
  }));
}

async function saveCode() {
  processing.value = true;
  const resp = await organizationApi.organization.schema_function.update(schemaFunctionId.value, {
    code: schemaFunctionCode.value,
  });
  processing.value = false;
  if (resp?.status != 200) return;

  eventBus.chime('Saved');
  changed.value = false;
}

async function saveName(newVal) {
  const resp = await organizationApi.organization.schema_function.update(schemaFunctionId.value, {
    name: newVal.name,
  });
  if (resp?.status != 200) return;

  schemaFunctionName.value = newVal.name;
  eventBus.chime('Saved');
  editNameDialog.value.close();
}
</script>
