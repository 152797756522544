<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      title="Payments"
      flat
    />

    <div class="bg-white bb-1 bc-outlined-grey px-3 pt-0 pb-3 mb-3">
      <v-row
        class="my-0"
        dense
      >
        <v-col cols="9">
          <FilterMenu
            :active="filters.application_program_id.length > 0"
            :title="programFilterLabel"
            classes="d-none d-md-inline-flex"
          >
            <template #card>
              <v-autocomplete
                v-model="filters.application_program_id"
                @update:model-value="loadPayments()"
                :items="programs"
                item-title="name"
                item-value="id"
                variant="filled"
                chips
                closable-chips
                hide-details
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            :active="!!filters.business_id"
            :title="businessFilterLabel"
            classes="d-none d-md-inline-flex"
          >
            <template #card>
              <v-autocomplete
                v-model="filters.business_id"
                v-model:search="businessQuery"
                @update:model-value="loadPayments()"
                :items="businesses"
                item-title="name"
                item-value="id"
                variant="filled"
                clearable
                hide-details
                hide-no-data
              />
            </template>
          </FilterMenu>

          <FilterMenu
            :active="!!filters.start_date"
            classes="d-none d-md-inline-flex"
          >
            <template #card>
              <v-row dense>
                <LabeledDatePicker
                  v-model="filters.start_date"
                  @change="loadPayments"
                />
              </v-row>
            </template>
            <template #title>
              <LongDate
                :date="filters.start_date"
                nulltext="Start date"
                prefix="From"
              />
            </template>
          </FilterMenu>

          <FilterMenu
            :active="!!filters.end_date"
            classes="d-none d-md-inline-flex"
          >
            <template #card>
              <v-row dense>
                <LabeledDatePicker
                  v-model="filters.end_date"
                  @change="loadPayments"
                />
              </v-row>
            </template>
            <template #title>
              <LongDate
                :date="filters.end_date"
                nulltext="End date"
                prefix="To"
              />
            </template>
          </FilterMenu>

          <FilterMenu
            :active="!!filters.status"
            :title="statusFilterLabel"
            classes="d-none d-md-inline-flex"
          >
            <template #card>
              <v-select
                v-model="filters.status"
                @update:model-value="loadPayments()"
                :items="statuses"
                variant="filled"
                clearable
                hide-details
                hide-no-data
              />
            </template>
          </FilterMenu>
        </v-col>

        <v-col class="ta-right">
          <template v-if="filters.ids.length > 0 || allChecked">
            <v-btn
              :href="link"
              color="primary"
              target="_blank"
            >
              {{ $t('Download payments') }} ({{
                allChecked ? collection.length : filters.ids.length
              }})
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </div>

    <v-container
      class="px-3 py-0"
      fluid
    >
      <PaymentTable
        @check="toggleCheck"
        @check:all="toggleCheckAll"
        @open="$refs.paymentEditor.open($event)"
        @toggle:sort="toggleSort"
        :collection="collection"
        :filters="filters"
        :processing="processing"
      />
    </v-container>

    <v-pagination
      v-model="filters.page"
      :length="pages"
      :total-visible="8"
      class="mt-4"
    />

    <PaymentEditor
      @change="loadPayments"
      ref="paymentEditor"
    />

    <VerticalSpacer :min-height="60" />
  </v-container>
</template>
<script>
import API from '@/shared/mixins/api';
import PaymentEditor from '@/specialist/components/PaymentEditor.vue';
import PaymentTable from '@/specialist/components/PaymentTable.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    PaymentEditor,
    PaymentTable,
    VerticalSpacer,
  },

  mixins: [API],

  data() {
    return {
      allChecked: false,
      businesses: [],
      businessQuery: null,
      businessQueryCount: 0,
      collection: [],
      filters: this.defaultFilters(),
      programs: [],
      searchCounter: 0,
      selectedItems: {},
      selectedSchemaId: null,
      status: null,
      statuses: [
        { text: 'Draft', value: 'draft' },
        { text: 'Approved', value: 'approved' },
        { text: 'Canceled', value: 'canceled' },
        { text: 'Processing', value: 'processing' },
        { text: 'Processed', value: 'processed' },
      ],
      pages: 1,
      processing: false,
    };
  },

  computed: {
    businessFilterLabel() {
      if (this.filters.business_id) {
        return this.businesses.find((business) => business.id === this.filters.business_id)?.name;
      }
      return 'Business';
    },

    programFilterLabel() {
      if (this.filters.application_program_id && this.filters.application_program_id.length > 0) {
        return `(${this.filters.application_program_id.length}) ${this.$t('programs')}`;
      }
      return 'Program';
    },

    link() {
      return this.api.organization.payment.downloadUrl(this.filters);
    },

    selectedAnyItems() {
      return Object.values(this.selectedItems).some((item) => item === true);
    },

    statusFilterLabel() {
      if (this.filters.status) {
        return this.statuses.find((status) => status.value === this.filters.status).text;
      }
      return 'Status';
    },
  },

  watch: {
    businessQuery(newVal) {
      if (newVal) {
        this.loadBusinesses(newVal);
      } else {
        this.businesses = [];
      }
    },

    // eslint-disable-next-line func-names
    'filters.page': function () {
      this.loadPayments();
    },
  },

  created() {
    this.loadBusinesses();
    this.loadPrograms();
    this.loadPayments();
  },

  methods: {
    defaultFilters() {
      if (this.$store.state.filters.payment) {
        return this.$store.state.filters.payment;
      }
      return {
        business_id: null,
        end_date: null,
        application_program_id: [],
        ids: [],
        page: 1,
        sort_dir: null,
        sort_field: null,
        start_date: null,
      };
    },

    async loadPayments() {
      this.filters.ids.splice(0);
      this.$store.commit('setFilters', ['payment', this.filters]);
      const resp = await this.api.organization.payment.index(this.filters);
      this.pages = parseInt(resp.headers['x-page-count'] || 0, 10);
      this.collection = resp.data;
      this.selectedItems = {};
      this.processing = false;
    },

    loadBusinesses(query) {
      this.businessQueryCount += 1;
      const currentBusinessQueryCount = this.businessQueryCount;
      this.api.organization.business.index({ query }, (resp) => {
        if (this.businessQueryCount === currentBusinessQueryCount) {
          this.businesses = resp.data;
        }
      });
    },

    loadPrograms() {
      this.programs = [];

      this.api.organization.grant_program.index((resp) => {
        this.programs = this.programs.concat(resp.data);
      });

      this.api.organization.subsidy_program.index((resp) => {
        this.programs = this.programs.concat(resp.data);
      });
    },

    toggleCheck(paymentId) {
      if (this.allChecked) {
        this.filters.ids = this.collection.map((item) => item.id);
        this.allChecked = false;
        this.$refs.checkAll.check(false);
      }

      const index = this.filters.ids.indexOf(paymentId);
      if (index >= 0) {
        this.filters.ids.splice(index, 1);
      } else {
        this.filters.ids.push(paymentId);
      }
    },

    toggleCheckAll() {
      this.allChecked = !this.allChecked;
      if (this.allChecked) {
        this.$refs.paymentRow.forEach((item) => item.check(true));
        this.filters.ids = this.collection.map((item) => item.id);
      } else {
        this.filters.ids.splice(0);
        this.$refs.paymentRow.forEach((item) => item.check(false));
      }
    },

    toggleSort(field) {
      if (this.filters.sort_field !== field) {
        this.filters.sort_dir = 'desc';
      } else {
        this.filters.sort_dir = this.filters.sort_dir === 'asc' ? 'desc' : 'asc';
      }
      this.filters.sort_field = field;
      this.loadPayments();
    },
  },
};
</script>
