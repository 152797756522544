<template>
  <div v-if="subsidyProgram && seatTypes">
    <BreadcrumbTitle
      :back-name="subsidyProgram.name"
      name="Seat types"
    />

    <v-divider class="my-8" />

    <v-card
      v-for="seatType in seatTypes"
      :key="seatType.id"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row class="d-flex align-center">
          <v-col>
            <v-row dense>
              <v-col>{{ seatType.name }}</v-col>
            </v-row>
            <v-row
              class="fs-12 c-light-black ml-2"
              dense
            >
              ID: {{ seatType.id }}
            </v-row>
            <v-row
              v-for="(attribute, index) in seatTypeMetadata(seatType)"
              :key="index"
              class="fs-12 c-light-black ml-2"
              dense
            >
              {{ attribute }}
            </v-row>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn
              @click="edit(seatType)"
              class="me-3"
              color="primary"
              variant="flat"
            >
              Edit
            </v-btn>
            <v-btn
              @click="remove(seatType)"
              color="red"
              variant="outlined"
            >
              Delete
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <template v-if="seatTypes.length == 0">
      <NullState @new="draft" />
    </template>

    <template v-else>
      <v-btn
        @click="draft"
        class="mt-4"
        color="primary"
      >
        <span v-t="'New'" />
      </v-btn>
    </template>

    <ResourceDialog
      @save="create"
      ref="newSeatTypeDialog"
      :fields="seatFields"
      :processing="processing"
      save-button-text="Create"
      title="New seat type"
    />

    <ResourceDialog
      @save="update"
      ref="editSeatTypeDialog"
      :fields="seatFields"
      :processing="processing"
      save-button-text="Save"
      title="Edit seat type"
    />
  </div>
</template>

<script>
import API from '@/shared/mixins/api';
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    BreadcrumbTitle,
    NullState,
    ResourceDialog,
  },

  mixins: [API],

  data() {
    return {
      changed: false,
      processing: false,
      seatFields: [
        { text: 'Name', value: 'name' },
        { text: 'Reserved for COC', value: 'reserved_for_continuity_of_care', type: 'boolean' },
        { text: 'Visible to families', value: 'visible_to_families', type: 'boolean' },
        { text: 'Providers can manage', value: 'providers_can_manage', type: 'boolean' },
      ],
      seatTypes: null,
      subsidyProgram: null,
    };
  },

  created() {
    this.loadSubsidyProgram();
    this.loadSeatTypes();
  },

  methods: {
    async create(newVal) {
      this.processing = true;
      await this.api.organization.subsidy_program.seat_type.create(newVal);
      this.$refs.newSeatTypeDialog.close();
      await this.loadSeatTypes();
      this.processing = false;
    },

    draft() {
      this.$refs.newSeatTypeDialog.open({
        subsidy_program_id: this.$route.params.subsidyProgramId,
      });
    },

    edit(seatType) {
      this.$refs.editSeatTypeDialog.open(seatType);
    },

    async loadSubsidyProgram() {
      const resp = await this.api.organization.subsidy_program.get(
        this.$route.params.subsidyProgramId,
      );
      this.subsidyProgram = resp.data;
    },

    async loadSeatTypes() {
      const resp = await this.api.organization.subsidy_program.seat_type.index(
        this.$route.params.subsidyProgramId,
      );
      this.seatTypes = resp.data;
    },

    async remove(seatType) {
      // eslint-disable-next-line no-alert
      if (!confirm('Are you sure you want to remove this seat type?')) return;
      this.processing = true;
      await this.api.organization.subsidy_program.seat_type.destroy(seatType);
      await this.loadSeatTypes();
      this.processing = false;
    },

    seatTypeMetadata(seatType) {
      const metadata = [];

      if (seatType.reserved_for_continuity_of_care)
        metadata.push(`Reserved for COC: ${seatType.reserved_for_continuity_of_care}`);
      if (seatType.visible_to_families)
        metadata.push(`Visible to families: ${seatType.visible_to_families}`);
      if (seatType.providers_can_manage)
        metadata.push(`Providers can manage: ${seatType.providers_can_manage}`);
      if (seatType.iep_direct_placement)
        metadata.push(`IEP Direct Placement: ${seatType.iep_direct_placement}`);
      if (seatType.dob_start_date) metadata.push(`DOB start date: ${seatType.dob_start_date}`);
      if (seatType.dob_end_date) metadata.push(`DOB end date: ${seatType.dob_end_date}`);
      if (seatType.min_score) metadata.push(`Minimum score: ${seatType.min_score}`);
      if (seatType.max_score) metadata.push(`Maximum score: ${seatType.max_score}`);
      if (seatType.label_operator) metadata.push(`Label operator: ${seatType.label_operator}`);

      return metadata;
    },

    async update(newVal) {
      this.processing = true;
      await this.api.organization.subsidy_program.seat_type.update(newVal);
      this.$refs.editSeatTypeDialog.close();
      await this.loadSeatTypes();
      this.processing = false;
    },
  },
};
</script>
