<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      @create="updateQuery({ action: 'create' })"
      :createable="canCreate"
      :title="terms.subsidies"
      create-text="New application"
    />

    <CustomSubsidyIndex v-if="$store.state.profile.enable_labs_feature_custom_views" />
    <DefaultSubsidyIndex
      v-else
      :subsidy-term="terms.subsidies"
    />
  </v-container>
</template>

<script setup>
import CustomSubsidyIndex from '@/specialist/views/CustomSubsidyIndex.vue';
import DefaultSubsidyIndex from '@/specialist/views/DefaultSubsidyIndex.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import useTerms from '@/shared/composables/useTerms';
import { useStore } from 'vuex';

const store = useStore();
const { terms } = useTerms();
const { updateQuery } = useRouterHelper();

const canCreate = computed(() => {
  if (store.state.profile.enable_labs_feature_custom_views) {
    return store.state.profile.org_subsidies_edit || store.state.profile.org_subsidies_agent;
  }
  return false;
});
</script>
