<template>
  <div v-if="grantProgram && grantSchema">
    <v-expansion-panels
      v-model="open"
      class="mb-12"
      flat
      multiple
      tile
    >
      <QuestionList
        @cancel="load"
        @change="load"
        @edit="edit($event)"
        @new="$refs.newQuestionDialog.open('Grant', $event, true)"
        @settings="settings($event)"
        :processing="processing"
        :questions="validEligibilityQuestions"
        :schemas="[grantSchema]"
        :sections="false"
        :selected-question="selectedQuestion"
        title="Eligibility questions"
        eligibility
      />
      <QuestionList
        @cancel="load"
        @change="load"
        @edit="edit($event)"
        @new="$refs.newQuestionDialog.open('Grant', $event)"
        @settings="settings($event)"
        :processing="processing"
        :questions="validQuestions"
        :schemas="[grantSchema]"
        :sections="false"
        :selected-question="selectedQuestion"
        title="Other questions"
      />
    </v-expansion-panels>

    <NewQuestionDialog
      @created="load"
      ref="newQuestionDialog"
      :owner-id="grantProgram.id"
      :schemas="[grantSchema]"
      owner-type="GrantProgram"
    />

    <ProcessingOverlay :processing="processing" />
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import NewQuestionDialog from '@/shared/components/form/NewQuestionDialog.vue';
import ProcessingOverlay from '@/shared/components/ProcessingOverlay.vue';
import QuestionList from '@/admin/components/QuestionList.vue';
import useGrant from '@/shared/composables/useGrant';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const open = ref([0, 1, 2, 3]);

const changed = ref(false);
const detailsDrawer = ref(null);
const grantProgram = ref(null);
const grantSchema = ref(null);
const selectedQuestion = ref(null);

const grantProgramId = computed(() => route.params.grantProgramId);

const { loadQuestions, questionsLoaded, validEligibilityQuestions, validQuestions } = useGrant({
  grantProgramId,
  displayAll: true,
});

const processing = computed(() => !questionsLoaded.value);

onMounted(() => {
  void load();
});

async function load() {
  changed.value = false;
  selectedQuestion.value = null;

  loadGrantProgram();
  loadQuestions();
}

function loadGrantProgram() {
  Api.organization.grant_program.get(grantProgramId.value, (resp) => {
    grantProgram.value = resp.data;
    grantSchema.value = store.state.schemas[grantProgram.value.grant_schema_id];
  });
}

function edit(question) {
  selectedQuestion.value = question;
}

function settings(question) {
  selectedQuestion.value = question;
  detailsDrawer.value.open();
}
</script>
