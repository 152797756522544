<template>
  <v-container class="mxw-1200">
    <router-view />
  </v-container>
</template>

<script setup>
import organizationApi from '@/specialist/services/bright_finder';
import { getSchemaNavigation } from '@/admin/services/admin-console-navigation';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const schemaId = route.params.schemaId;

onBeforeUnmount(() => {
  store.commit('setNavigation', null);
});

onMounted(loadSchema);

async function loadSchema() {
  const { data } = await organizationApi.organization.schema.get(schemaId);

  store.commit('setNavigation', getSchemaNavigation(data.data_type, schemaId));
}
</script>
