<template>
  <router-view
    v-if="site"
    @refresh="load()"
    :site="site"
  />
</template>

<script setup>
import organizationApi from '@/specialist/services/bright_finder';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const site = ref(null);

watch(
  route,
  async (to, from) => {
    if (to?.params?.siteId !== from?.params?.siteId) await load();
  },
  { flush: 'pre', immediate: true, deep: true },
);

onBeforeUnmount(() => {
  store.commit('setNavigation', null);
});

async function load() {
  store.commit('setNavigation', null);
  const { data: siteData } = await organizationApi.organization.site.get(route.params.siteId);
  site.value = siteData;
  await setNavigation();
}

async function setNavigation() {
  store.commit('setNavigation', [
    {
      route: 'SiteIndex',
      icon: 'chevron_left',
      type: 'back',
      title: 'Back',
    },
    {
      type: 'section',
      items: [
        {
          title: 'Settings',
          route: 'SiteShow',
          params: {
            siteId: route.params.siteId,
          },
        },
      ],
    },
    {
      type: 'section',
      header: 'Pages',
      items: site.value.pages.map(
        (page) => ({
          title: page.name,
          route: 'PageShow',
          params: {
            siteId: route.params.siteId,
            pageId: page.id,
          },
        }),
        this,
      ),
    },
  ]);
}
</script>
