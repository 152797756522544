<template>
  <div>
    <div
      v-for="(question, index) in questions"
      :key="question.id"
      class="mb-4"
    >
      <QuestionForm
        @cancel="$emit('cancel')"
        @change="$emit('change')"
        @edit="$emit('edit', question)"
        @move="$emit('move', [index, $event])"
        @settings="$emit('settings', question)"
        :index="index"
        :list-length="questions.length"
        :processing="processing || moving"
        :question="question"
        :schemas="schemas"
        :selected="selectedQuestion == question"
      />
    </div>
  </div>
</template>

<script setup>
import QuestionForm from '@/admin/components/QuestionForm.vue';

defineEmits(['cancel', 'change', 'edit', 'move', 'settings']);
const moving = ref(false);

defineProps({
  processing: Boolean,
  questions: {
    type: Array,
    default: null,
  },
  schemas: {
    type: Array,
    default: null,
  },
  selectedQuestion: {
    type: Object,
    default: null,
  },
});
</script>
