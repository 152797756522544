<template>
  <div v-if="schema">
    <v-container
      v-if="schemas.length > 0"
      class="mxw-1200"
    >
      <BreadcrumbTitle
        :back-name="schema.name"
        name="Questions"
      />

      <v-expansion-panels
        v-model="open"
        class="mb-12"
        flat
        mandatory
        multiple
        tile
      >
        <QuestionList
          @cancel="load"
          @change="load"
          @edit="edit($event)"
          @new="$refs.newQuestionDialog.open(schema.data_type, $event)"
          @new-section="$refs.newQuestionDialog.open(schema.data_type, null, false, true)"
          @settings="settings($event)"
          :answerable="answerable"
          :processing="processing"
          :questions="sortedQuestions"
          :schemas="schemas"
          :selected-question="selectedQuestion"
          :verification="false"
          title="Questions"
        />
      </v-expansion-panels>

      <NewQuestionDialog
        @created="load"
        ref="newQuestionDialog"
        :owner-id="schema.id"
        :schema="schema"
        :schemas="schemas"
        owner-type="Schema"
      />

      <ProcessingOverlay :processing="processing" />
    </v-container>
  </div>
</template>

<script>
import API from '@/shared/mixins/api';
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import LinkedList from '@/shared/services/linked-list';
import NewQuestionDialog from '@/shared/components/form/NewQuestionDialog.vue';
import QuestionList from '@/admin/components/QuestionList.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    BreadcrumbTitle,
    NewQuestionDialog,
    QuestionList,
  },
  mixins: [API],

  data() {
    return {
      open: [0],
      processing: false,
      questions: [],
      schema: null,
      schemas: [],
      selectedQuestion: null,
    };
  },

  computed: {
    answerable() {
      if (!this.schema) return null;

      return ['Quiz'].includes(this.schema.data_type);
    },

    sortedQuestions() {
      return LinkedList.sort(this.questions);
    },
  },

  watch: {
    '$route.params.schemaId': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.schema = null;
          this.load();
        }
      },
    },
  },

  methods: {
    async load() {
      this.processing = true;
      const resp = await this.api.organization.schema.get(this.$route.params.schemaId);
      this.schema = resp.data;
      this.changed = false;
      this.selectedQuestion = null;
      this.loadSchemas();
      this.loadQuestions();
    },

    async loadQuestions() {
      const resp = await this.api.public_api.organization.question.index({
        owner_id: this.schema.id,
        owner_type: 'Schema',
      });
      this.questions = resp.data;
      this.processing = false;
    },

    async loadSchemas() {
      const resp = await this.api.public_api.organization.schema.index();
      this.schemas = resp.data
        .filter((schema) => this.schema.associated_schema_ids.includes(schema.id))
        .concat([this.schema]);
    },

    edit(question) {
      this.selectedQuestion = question;
    },

    settings(question) {
      this.selectedQuestion = question;
      this.$refs.detailsDrawer.open();
    },
  },
};
</script>
