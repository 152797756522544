<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      title="Funding"
      outlined
    />

    <v-container
      class="px-3 py-0"
      fluid
    >
      <v-row>
        <v-col>
          <v-card
            class="bg-transparent"
            flat
          >
            <v-card-text>
              <v-card
                class="b-1 bc-outlined-gray"
                elevation="0"
              >
                <v-card-text>
                  <v-row class="d-flex align-center">
                    <v-col cols="6">
                      <div
                        v-t="'Customer ID'"
                        class="fs-14 c-light-black"
                      />
                      <div
                        v-text="bankAccount?.dwolla_customer_id || '-'"
                        class="fs-15"
                      />
                    </v-col>
                    <v-col cols="6">
                      <div
                        v-t="'Funding Source ID'"
                        class="fs-14 c-light-black"
                      />
                      <div
                        v-text="bankAccount?.dwolla_funding_source_id || '-'"
                        class="fs-15"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>

            <v-card-text>
              <v-card
                class="b-1 bc-outlined-gray"
                elevation="0"
              >
                <v-card-title>
                  <h3
                    v-t="'Bank account'"
                    class="fs-22 fw-500"
                  />
                </v-card-title>

                <v-card-text>
                  <template v-if="exists">
                    <v-row class="d-flex align-center">
                      <v-col>
                        <div
                          v-t="'Name'"
                          class="fs-14 c-light-black"
                        />
                        <div
                          v-text="bankAccount?.bank_account_name || '-'"
                          class="fs-15"
                        />
                      </v-col>
                      <v-col>
                        <div
                          v-t="'Type'"
                          class="fs-14 c-light-black"
                        />
                        <div
                          v-text="bankAccount?.bank_account_type || '-'"
                          class="fs-15"
                        />
                      </v-col>
                      <v-col>
                        <div
                          v-t="'Routing number'"
                          class="fs-14 c-light-black"
                        />
                        <div
                          v-text="bankAccount?.bank_routing_number || '-'"
                          class="fs-15"
                        />
                      </v-col>
                      <v-col>
                        <div
                          v-t="'Account number'"
                          class="fs-14 c-light-black"
                        />
                        <div
                          v-text="bankAccount?.bank_account_number?.padStart(10, '•') || '-'"
                          class="fs-15"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <div
                    v-else
                    class="fs-16"
                  >
                    No bank account present.
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                v-if="exists"
                @click="destroy"
                class="me-6"
                color="red"
                size="x-large"
                variant="text"
              >
                <span v-t="'Remove'" />
              </v-btn>
              <v-btn
                @click="draft"
                @save="update"
                :loading="processing"
                color="primary"
                size="x-large"
              >
                <span v-t="exists ? 'Update' : 'Add'" />
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <ResourceDialog
        @save="update"
        ref="bankAccountDialog"
        :consent-label="onDemandAuth.buttonText"
        :consent-text="onDemandAuth.bodyText"
        :fields="bankAccountFields"
        :processing="processing"
        :title="exists ? 'Update bank account' : 'Add bank account'"
        fullscreen
      />
    </v-container>
  </v-container>
</template>

<script>
import Api from '@/shared/services/all_bright_finder';
import PageTitle from '@/shared/components/PageTitle.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    PageTitle,
    ResourceDialog,
  },

  data() {
    return {
      bankAccount: null,
      bankAccountFields: [
        { text: 'Account name', value: 'bank_account_name' },
        { text: 'Account number', value: 'bank_account_number' },
        { text: 'Routing number', value: 'bank_routing_number' },
        {
          text: 'Account type',
          value: 'bank_account_type',
          items: ['Checking', 'Savings'],
          placeholder: 'Checking or Savings',
        },
      ],
      onDemandAuth: {},
      processing: false,
    };
  },

  computed: {
    exists() {
      return (
        this.bankAccount &&
        this.bankAccount?.dwolla_customer_id &&
        (this.bankAccount.bank_account_name ||
          this.bankAccount.bank_account_number ||
          this.bankAccount.bank_routing_number ||
          this.bankAccount.bank_account_type)
      );
    },
  },

  created() {
    if (this.$store.state.profile.org_billing_edit) this.load();
  },

  methods: {
    async load() {
      this.processing = true;
      await this.loadBankAccount();
      this.processing = false;
    },

    async loadBankAccount() {
      const response = await Api.organization.bankAccount.get();
      this.bankAccount = response.data;
    },

    async draft() {
      this.processing = true;
      const response = await Api.organization.dwolla.onDemandAuthorization.create();
      this.onDemandAuth = response.data;
      this.processing = false;
      this.$refs.bankAccountDialog.open(this.bankAccount);
    },

    async update(bankAccount) {
      this.processing = true;
      // eslint-disable-next-line no-underscore-dangle
      if (this.onDemandAuth._links) {
        // eslint-disable-next-line no-param-reassign, no-underscore-dangle
        bankAccount.on_demand_authorization_link = this.onDemandAuth._links.self.href;
      }
      const response = await Api.organization.bankAccount.update(bankAccount);
      this.bankAccount = response.data;
      this.processing = false;
      this.$eventBus.$emit('chime', 'Bank account updated successfully');
      this.$refs.bankAccountDialog.close();
    },

    destroy() {
      // eslint-disable-next-line no-alert
      if (confirm('Are you sure you want to disconnect this bank account?')) {
        this.update({
          bank_account_name: null,
          bank_account_number: null,
          bank_routing_number: null,
          bank_account_type: null,
        });
      }
    },
  },
};
</script>
