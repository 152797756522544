import Api from '@/shared/services/bright_finder';
import LinkedList from '@/shared/services/linked-list';
import ValueExtractor from '@/shared/services/ValueExtractor';

/**
 * @deprecated
 * @example ```vue
 * <script setup>
 *   import useMyFamilySubsidyUtils from '@/shared/composables/useMyFamilySubsidyUtils';
 *
 *   const {
 *     loadQuestions,
 *     validEligibilityQuestions,
 *     validOtherQuestions,
 *     validVerificationQuestions,
 *   } = useMyFamilySubsidyUtils();
 * </script>
 * ```
 */
export default {
  data() {
    return {
      displayAll: false,
      questions: [],
      householdMemberQuestions: [],
      validVerificationQuestions: [],
      validEligibilityQuestions: [],
      validOtherQuestions: [],
    };
  },

  methods: {
    async validate() {
      this.familySubsidyQuestions = this.filterQuestionsForModel(this.questions, 'FamilySubsidy');
      const familySubsidyAssertions = this.familySubsidyQuestions
        .filter((question) => question.conditions?.length > 0)
        .map((question) => ({
          assertion: {
            conditions: question.conditions,
            labels: [question.id],
            value: ValueExtractor.extract(
              this.subsidy,
              question.conditions.map((condition) => condition.field),
            ),
          },
        }));
      this.householdMemberQuestions = LinkedList.sort(
        this.filterQuestionsForModel(this.questions, 'HouseholdMember'),
      );
      this.householdMembers.forEach((householdMember) => {
        this.householdMemberQuestionRegister[householdMember.id] = JSON.parse(
          JSON.stringify(this.householdMemberQuestions),
        );
      });

      const householdMemberAssertions = this.householdMembers
        .map((householdMember) =>
          this.householdMemberQuestions
            .filter((question) => question.conditions?.length > 0)
            .map((question) => ({
              assertion: {
                conditions: question.conditions,
                labels: [question.id, householdMember.id],
                value: ValueExtractor.extract(
                  householdMember,
                  question.conditions.map((condition) => condition.field),
                ),
              },
            })),
        )
        .flat();

      const assertions = familySubsidyAssertions.concat(householdMemberAssertions);
      const { data } = await Api.public_api.assertion.promiseBulkCreate(assertions);
      data.results.forEach((result) => {
        const familySubsidyQuestion = this.familySubsidyQuestions.find((question) =>
          result.labels.includes(question.id),
        );
        if (familySubsidyQuestion) return (familySubsidyQuestion.valid = result.result);

        this.mapValidQuestions(this.householdMemberQuestionRegister, result);
      });

      this.validEligibilityQuestions = this.sortQuestions(
        this.familySubsidyQuestions,
        'FamilySubsidy',
        true,
      ).filter((question) => question.valid);
      this.validOtherQuestions = this.sortQuestions(
        this.familySubsidyQuestions,
        'FamilySubsidy',
        false,
      ).filter((question) => question.valid);
      this.validVerificationQuestions = this.familySubsidyQuestions
        .filter((question) => question.verification)
        .filter((question) => this.displayAll || (question.published && question.valid));
      this.validHouseholdMemberQuestionsLength = this.lengthOfValidQuestions(
        this.householdMemberQuestionRegister,
      );
    },

    lengthOfValidQuestions(questionRegister) {
      const lengths = Object.values(questionRegister).map(
        (questionRegisterObject) =>
          questionRegisterObject.filter((question) => question.valid).length,
      );
      return lengths.reduce((total, length) => total + length, 0);
    },

    async loadQuestions() {
      const params = {
        owner_id: this.familySubsidyProgram.id,
        owner_type: 'FamilySubsidyProgram',
      };
      const resp = await this.api.public_api.organization.question.index(params);
      this.questions = resp.data;
      this.validate();
    },

    filterQuestionsForModel(questions, syncedModel) {
      return questions.filter((question) => question.synced_model == syncedModel);
    },

    mapValidQuestions(col, r) {
      const id = Object.keys(col).find((householdMemberId) => r.labels.includes(householdMemberId));
      if (id) {
        const question = col[id].find((question) => r.labels.includes(question.id));
        if (question) {
          question.valid = r.result;
          return true;
        }
      }
    },

    sortQuestions(questions, syncedModel, eligibility) {
      return LinkedList.sort(
        this.filterQuestionsForModel(questions, syncedModel).filter(
          (question) => question.eligibility == eligibility,
        ),
      );
    },

    sortValidQuestions(questions, syncedModel, eligibility) {
      return this.sortQuestions(questions, syncedModel, eligibility).filter(
        (question) => question.valid,
      );
    },
  },
};
