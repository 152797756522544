const APPLICATION_TAB = 'Application';
const ENROLLMENT_TAB = 'Enrollment';
const HOUSEHOLD_TAB = 'Household';
const FUNDING_TAB = 'Funding';
const CLAIMS_TAB = 'Claims';

export function getTabsForSubsidyStage(subsidyProgram, currentStage, { profile = null } = {}) {
  const ENROLLMENT_TAB_PERMISSION =
    profile?.org_enrollments_access || profile?.org_enrollments_agent;
  const FUNDING_TAB_PERMISSION = profile?.org_enrollments_access;
  const tabArray = [];

  if (subsidyProgram) {
    if (subsidyProgram.enable_stages) {
      if (currentStage) {
        if (currentStage.enable_application) tabArray.push(APPLICATION_TAB);
        if (currentStage.enable_enrollment && ENROLLMENT_TAB_PERMISSION)
          tabArray.push(ENROLLMENT_TAB);
        if (currentStage.enable_funding && FUNDING_TAB_PERMISSION) tabArray.push(FUNDING_TAB);
      }
    } else {
      tabArray.push(APPLICATION_TAB);
      if (ENROLLMENT_TAB_PERMISSION) tabArray.push(ENROLLMENT_TAB);
      if (subsidyProgram.enable_awards && FUNDING_TAB_PERMISSION) tabArray.push(FUNDING_TAB);
    }
  }
  return tabArray;
}

export function getTabsForFamilySubsidyStage(familySubsidyProgram, familySubsidy, currentStage) {
  const tabArray = [];

  if (familySubsidyProgram) {
    if (familySubsidyProgram.enable_stages) {
      if (currentStage) {
        if (currentStage.enable_application) tabArray.push(APPLICATION_TAB);
        // TO-DO : ONCE STATUS IS BEING PASSED TO PARENTS TO CHECK FOR APPROVED ADD "&& familySubsidy.status == 'Approved'"
        if (!!familySubsidy.submitted_at && currentStage.enable_claims) {
          tabArray.push(CLAIMS_TAB);
        }
      }
    } else {
      tabArray.push(APPLICATION_TAB);
      if (
        !!familySubsidy.submitted_at &&
        familySubsidy.status === 'Approved' &&
        familySubsidyProgram.enable_claims
      ) {
        tabArray.push(CLAIMS_TAB);
      }
    }
    if (familySubsidyProgram.enable_household_members) {
      tabArray.push(HOUSEHOLD_TAB);
    }
  }
  return tabArray;
}
