<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      @create="showDialog"
      create-text="New program"
      title="Screener programs"
      createable
      flat
      outlined
    />

    <v-container
      class="mt-3 px-3 py-0"
      fluid
    >
      <template v-if="screenerPrograms.length">
        <v-data-table
          @click:row="handleRowClick"
          :headers="getHeaders()"
          :items="screenerPrograms"
          class="border"
          hover
        >
          <template #item.created_at="{ item }">
            <LongDate
              :date="item.created_at"
              short-month
            />
          </template>

          <template #item.updated_at="{ item }">
            <LongDateTime
              :date="item.updated_at"
              short-month
            />
          </template>
        </v-data-table>
      </template>

      <template v-else>
        <NullState hide-new-button />
      </template>
    </v-container>

    <ResourceDialog
      @save="create"
      ref="dialog"
      :fields="getFields()"
      :processing="processing"
      save-button-text="Create"
      title="New screener program"
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LongDate from '@/shared/components/LongDate.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import NullState from '@/shared/components/NullState.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';

const eventBus = useEventBus();
const router = useRouter();

const screenerPrograms = ref([]);
const dialog = ref(null);
const processing = ref(false);

async function create(data) {
  processing.value = true;

  const response = await Api.organization.screenerProgram.create(data);
  processing.value = false;
  if (response?.status !== 201) return;

  screenerPrograms.value.push(response.data);
  dialog.value.close();
  eventBus.chime('Created');
}

function getFields() {
  return [
    {
      text: 'Name',
      value: 'name',
      required: true,
    },
  ];
}

function getHeaders() {
  return [
    { title: 'Name', value: 'name' },
    { title: 'Created', value: 'created_at' },
    { title: 'Updated', value: 'updated_at' },
  ];
}

function handleRowClick(event, { item }) {
  router.push({
    name: 'ScreenerProgramShow',
    params: {
      id: item.id,
    },
  });
}

async function load() {
  processing.value = true;

  const response = await Api.organization.screenerProgram.index();
  screenerPrograms.value = response.data;

  processing.value = false;
}

async function showDialog() {
  dialog.value.open({});
}

onMounted(load);
</script>
