<template>
  <div v-if="eligibilityProgram">
    <BreadcrumbTitle
      :back-name="eligibilityProgram.name"
      :back-route="{ name: 'EligibilityProgramShow' }"
      name="Eligibility"
    >
      <template #actions>
        <v-btn
          @click="draftFundingSource"
          color="primary"
          prepend-icon="add"
        >
          {{ $t('New funding source') }}
        </v-btn>
      </template>
    </BreadcrumbTitle>

    <div
      v-for="source in fundingSources"
      :key="source.id"
      class="mb-8"
    >
      <FundingSourceCard
        @destroy="loadFundingSources"
        @move="reorder(source, $event)"
        :display-index="getDisplayPositionIndex(source)"
        :schemas="schemas"
        :show-eligibility-required="false"
        :show-enrollment-assignable="false"
        :show-publicly-listed="false"
        :source="source"
        :total-count="fundingSources.length"
        ordered
      />
    </div>

    <ResourceDialog
      @save="createFundingSource"
      :fields="fundingSourceFields"
      :processing="processing"
      action="new"
      save-button-text="Create"
      title="Create funding source"
    />

    <SaveBar
      @cancel="loadEligibilityProgram"
      @save="save"
      :model-value="changed"
      :processing="processing"
    />
  </div>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import FundingSourceCard from '@/admin/components/FundingSourceCard.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import RouterHelper from '@/shared/mixins/RouterHelper';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import Subsidy from '@/shared/mixins/subsidy';

export default {
  compatConfig: { MODE: 2 },

  components: {
    BreadcrumbTitle,
    FundingSourceCard,
    ResourceDialog,
    SaveBar,
  },

  mixins: [RouterHelper, Subsidy],

  data() {
    return {
      changed: false,
      funds: [],
      fundingSources: [],
      newName: null,
      processing: false,
      publicSchemas: [],
      eligibilityProgram: null,
    };
  },

  computed: {
    fundingSourceFields() {
      return [
        {
          required: true,
          text: 'Name',
          value: 'name',
        },
        {
          required: true,
          text: 'Fund',
          value: 'fund_id',
          items: this.funds,
          itemText: 'name',
          itemValue: 'id',
        },
      ];
    },

    schemas() {
      if (!this.eligibilityProgram?.eligibilityScreenerSchema?.id) return [];

      const eligibilityScreenerSchema =
        this.publicSchemas.find(
          (schema) => schema.id == this.eligibilityProgram.eligibilityScreenerSchema.id,
        ) || null;

      return eligibilityScreenerSchema ? [eligibilityScreenerSchema] : [];
    },
  },

  created() {
    this.id = this.$route.params.id;

    this.load();
  },

  methods: {
    async createFundingSource(newValue) {
      this.processing = true;
      const response = await Api.organization.funding_source.create({
        ...newValue,
        owner_id: this.id,
        owner_type: 'EligibilityProgram',
      });

      this.processing = false;
      await this.updateQuery({ action: null });
      if (response?.data) this.fundingSources.push(response.data);
    },

    async draftFundingSource() {
      await this.updateQuery({ action: 'new' });
    },

    getDisplayPositionIndex(source) {
      if (source.display_order !== null) return source.display_order - 1;

      return this.fundingSources.indexOf(source);
    },

    async load() {
      this.loadFundingSources();
      this.loadFunds();
      await this.loadEligibilityProgram();
      this.loadPublicSchemas();
    },

    async loadFunds() {
      const response = await Api.organization.fund.index({});
      this.funds = response?.data;
    },

    async loadFundingSources() {
      const params = { owner_id: this.id };
      const response = await Api.organization.funding_source.index(params);
      this.fundingSources = response?.data;
    },

    loadPublicSchemas() {
      Api.public_api.organization.schema.index((response) => {
        this.publicSchemas = response?.data;
      });
    },

    async loadEligibilityProgram() {
      const response = await Api.organization.eligibilityProgram.get(this.id);
      this.eligibilityProgram = response?.data;
    },

    async reorder(source, displayOrderChange) {
      const params = {
        display_order_was: source.display_order,
        display_order: this.getDisplayPositionIndex(source) + 1 + displayOrderChange,
      };

      await Api.organization.funding_source.reorder(source.id, params);
      this.loadFundingSources();
    },

    save() {
      this.processing = true;
      Api.organization.eligibilityProgram.update(
        this.$route.params.subsidyProgramId,
        this.eligibilityProgram,
        (response) => {
          this.processing = false;
          this.eligibilityProgram = response.data;
          this.changed = false;
        },
        (error) => {
          this.processing = false;
          this.$eventBus.$emit('chime', error.response.data.errors[0]);
        },
      );
    },
  },
};
</script>
