<template>
  <v-list-group
    v-if="items.length > 0"
    :class="{ rail: rail }"
    dense
  >
    <template #activator="{ props }">
      <v-list-item
        v-bind="props"
        :class="{ rail: rail }"
      >
        <v-list-item-title class="d-flex align-center">
          <v-icon
            v-if="icon"
            :class="{ 'me-3': !rail }"
            :icon="icon"
            active-class="c-primary"
            class="material-icons-round"
            size="22"
          />

          <span
            v-if="!rail"
            class="fs-16 fw-500"
          >
            {{ $t(title) }}
          </span>
        </v-list-item-title>
      </v-list-item>
    </template>

    <v-divider class="my-2" />

    <NavItem
      v-for="(item, index) in items"
      :key="index"
      :icon="item.icon"
      :params="item.params"
      :query="item.query"
      :route="item.route"
      :title="item.title"
      exact
    />

    <v-divider
      v-if="bottomDivider"
      class="my-2"
    />
  </v-list-group>
</template>

<script setup>
import NavItem from './NavItem.vue';
import vuetify from '@/plugins/vuetify';
import { useStore } from 'vuex';

const store = useStore();

defineProps({
  bottomDivider: {
    type: Boolean,
    default: true,
  },
  icon: {
    type: String,
    default: null,
  },
  items: {
    type: Array,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
});

const rail = computed(() => {
  return store.state.miniNav && !vuetify.display.mobile.value;
});
</script>

<style>
.v-list-group {
  --prepend-width: 0px;
}
.rail.v-list-group {
  --list-indent-size: 0px;
}
.rail.v-list-group .v-list-item__append {
  display: none !important;
}
</style>
