<template>
  <v-card
    class="px-2 py-4"
    data-cy="row"
    flat
    tile
  >
    <v-row class="d-flex align-center">
      <v-col
        class="d-flex align-center"
        cols="12"
        md="3"
      >
        <div class="me-1 px-1">
          <v-checkbox
            v-if="!hideSelector"
            @click.stop="$emit('check')"
            :value="enrollment.checked"
            class="mt-0 p-0"
            data-cy="enrollment-select"
            hide-details
          />
        </div>
        <span
          v-text="enrollment.name"
          class="fs-16 fw-500"
        />
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <span v-text="enrollment.meta.child.dob" />
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <span
          v-text="[enrollment.meta.group.home_address, enrollment.meta.group.home_city].join(' ')"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <span v-text="enrollment.meta.provider.name" />
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <span
          v-if="enrollment.meta.program"
          v-text="enrollment.meta.program.name"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    enrollment: {
      type: Object,
      default: null,
    },
    hideSelector: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['check'],
};
</script>
