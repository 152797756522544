<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-row class="fs-16">
      <LabeledTextfield
        v-model="child.first_name"
        @change="update()"
        :readonly="!canEdit"
        cols="6"
        data-cy="child_first_name"
        message="First name"
      />
      <LabeledTextfield
        v-model="child.last_name"
        @change="update()"
        :readonly="!canEdit"
        cols="6"
        data-cy="child_last_name"
        message="Last name"
      />
    </v-row>
    <v-row class="fs-16">
      <LabeledDateOfBirth
        v-model="child.dob"
        @change="update()"
        :readonly="!canEdit"
        cols="8"
        data-cy="child_dob"
        md="9"
        message="Date of birth"
      />
      <LabeledTextfield
        v-model="childAgeGroup"
        :disabled="!child.dob"
        :placeholder="$t('Enter birth date to calculate')"
        append-inner-icon="sync"
        cols="12"
        md="3"
        message="Age group"
        readonly
      />
    </v-row>
    <v-row class="fs-16">
      <LabeledDatePicker
        v-model="child.care_needed_date"
        @input="update"
        :disabled="!child.dob"
        :disallow-before-date="child.dob"
        :readonly="!canEdit"
        cols="12"
        disabled-message="Enter birth date"
        md="6"
        message="Date care needed"
      />
      <LabeledTextfield
        v-model="ageGroupWhenCareNeeded"
        :disabled="!child.care_needed_date"
        :placeholder="$t('Enter care needed date to calculate')"
        append-inner-icon="sync"
        cols="12"
        md="6"
        message="Age group at time of care"
        readonly
      />
    </v-row>

    <v-divider class="my-4" />

    <SubsidyCard
      v-for="subsidyProgram in subsidyPrograms"
      :key="subsidyProgram.id"
      :child="child"
      :subsidy="subsidies.find((subsidy) => subsidy.subsidy_program_id == subsidyProgram.id)"
      :subsidy-program="subsidyProgram"
    />

    <v-row>
      <v-col>
        <span class="fs-12 c-light-black">ID #{{ child.id }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import LabeledDateOfBirth from '@/shared/components/form/LabeledDateOfBirth.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import SubsidyCard from '@/specialist/components/group/SubsidyCard.vue';
import useAgeCalculations from '@/shared/composables/useAgeCalculations';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';

const { ageGroupFromDate } = useAgeCalculations();
const eventBus = useEventBus();
const store = useStore();

const props = defineProps({
  groupId: {
    type: String,
    default: null,
  },
  initialChild: {
    type: Object,
    default: null,
  },
  subsidies: {
    type: Array,
    default: null,
  },
  subsidyPrograms: {
    type: Array,
    default: null,
  },
});

const child = ref(props.initialChild);

const childAgeGroup = computed(() => {
  const noneFoundText = 'No age group found';
  if (child.value.dob) {
    const dob = new Date(child.value.dob);
    const ageGroup = ageGroupFromDate(dob);
    return ageGroup?.name || noneFoundText;
  }
  return null;
});

const ageGroupWhenCareNeeded = computed(() => {
  const noneFoundText = 'No age group found';
  if (child.value.dob && child.value.care_needed_date) {
    const careNeededDate = new Date(child.value.care_needed_date);
    const dob = new Date(child.value.dob);
    const ageGroup = ageGroupFromDate(dob, careNeededDate);
    return ageGroup?.name || noneFoundText;
  }
  return null;
});

const canEdit = computed(() => {
  return store.state.profile.org_groups_edit || store.state.profile.org_subsidies_agent;
});

async function update() {
  const resp = await api.organization.group.child.update(
    props.groupId,
    child.value.id,
    child.value,
  );
  if (resp?.status === 200) eventBus.chime('Child updated');
}
</script>
