<template>
  <v-navigation-drawer
    v-model="isVisible"
    :scrim="false"
    location="right"
    width="400"
    temporary
  >
    <v-card
      class="d-flex flex-column"
      style="height: 100%; max-height: 100vh"
    >
      <v-card-title>
        <v-row class="d-flex align-center">
          <v-col
            class="py-0"
            cols="9"
          >
            {{ $t('Task') }}
          </v-col>

          <v-col
            class="py-0 ta-right"
            cols="3"
          >
            <v-btn
              @click="close"
              icon="close"
              variant="button"
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="flex-grow-1 overflow-auto">
        <v-row>
          <v-col cols="12">
            <v-row dense>
              <v-col
                class="labeled-input"
                cols="12"
              >
                {{ $t('Name') }}
              </v-col>

              <v-col class="fs-16 c-light-black">
                <span>{{ $t(task.title) }}</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            v-if="draft.group"
            cols="12"
          >
            <v-row dense>
              <v-col
                class="labeled-input"
                cols="12"
              >
                {{ $t(`Related ${terms.group.toLowerCase()}`) }}
              </v-col>

              <v-col class="fs-16 c-light-black">
                <router-link
                  :to="{
                    name: 'GroupShow',
                    params: { groupId: draft.group.id },
                  }"
                >
                  {{ draft.group.name }}
                </router-link>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            v-if="draft.provider"
            cols="12"
          >
            <v-row dense>
              <v-col
                class="labeled-input"
                cols="12"
              >
                {{ $t('Related provider') }}
              </v-col>

              <v-col class="fs-16 c-light-black">
                <router-link
                  :to="{
                    name: 'ProviderShow',
                    params: { providerId: draft.provider.id },
                  }"
                >
                  {{ draft.provider.name }}
                </router-link>
              </v-col>
            </v-row>
          </v-col>

          <LabeledAutocomplete
            v-model="draft.owner_id"
            @update:model-value="changed = true"
            :aria-label="$t(ariaLabel)"
            :items="specialists"
            item-title="name"
            item-value="id"
            message="Assigned to"
          />

          <LabeledDatePicker
            v-model="draft.due_date"
            @update:model-value="changed = true"
            :min="minimumDueDate"
            message="Due date"
            placeholder-text="Due date (if applicable)"
          />

          <LabeledTextarea
            v-model="draft.description"
            @update:model-value="changed = true"
            message="Notes"
            placeholder-text="No notes provided"
          />

          <v-col class="v-col v-col-12 labeled-input pb-0">
            <span>{{ $t('Status') }}</span>
          </v-col>

          <v-col>
            <v-btn-toggle
              v-model="draft.status"
              @update:model-value="changed = true"
              selected-class="bg-primary c-white"
              style="width: 100%"
              variant="outlined"
              divided
              mandatory
              tile
            >
              <v-btn
                data-cy="task_mark_incomplete"
                value="open"
                variant="outlined"
                width="50%"
              >
                <v-icon
                  class="material-icons-outlined"
                  icon="circle"
                  start
                />

                {{ $t('Open') }}
              </v-btn>

              <v-btn
                data-cy="task_mark_incomplete"
                value="complete"
                variant="outlined"
                width="50%"
              >
                <v-icon
                  class="material-icons-outlined"
                  icon="check_circle"
                  start
                />

                {{ $t('Complete') }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions
        v-if="changed"
        class="d-flex flex-row justify-center"
      >
        <v-btn
          @click="cancel"
          :class="[
            display.smAndDown ? 'btn-small' : 'btn-large',
            'me-3',
            'focus-very-visible',
            'd-flex',
          ]"
          :loading="processing"
          class="focus-very-visible"
          color="primary"
          variant="outlined"
        >
          {{ $t('Cancel') }}
        </v-btn>

        <v-spacer />

        <v-btn
          @click="save"
          :class="[
            display.smAndDown ? 'btn-small' : 'btn-large',
            'me-2',
            'focus-very-visible',
            'd-flex',
          ]"
          :loading="processing"
          :ripple="false"
          class="focus-very-visible"
          color="primary"
          data-cy="save-button"
          data-testid="save-button"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import useTerms from '@/shared/composables/useTerms';
import useEventBus from '@/shared/composables/useEventBus';
import { useDisplay } from 'vuetify';

const display = useDisplay();
const eventBus = useEventBus();
const { terms } = useTerms();

defineProps({
  processing: {
    type: Boolean,
    default: false,
  },
  specialists: {
    type: Array,
    default: () => [],
  },
});

const changed = ref(false);
const draft = ref({});
const isVisible = ref(false);
const task = ref({});

const emit = defineEmits(['save']);

defineExpose({ open });

const ariaLabel = computed(() => {
  if (!draft.value.owner_id) return 'Assign to (choose owner)';

  return `Assign to ${draft.value.owner_name}`;
});

function cancel() {
  draft.value = JSON.parse(JSON.stringify(task.value || {}));
  changed.value = false;
}

function close() {
  if (!isVisible.value) return;

  isVisible.value = false;
}

async function open(opened) {
  await Api.organization.task.update(opened.id, { read: true });
  opened.read = true;

  if (opened.group_id) {
    const response = await Api.organization.group.get(opened.group_id);
    opened.group = response?.data;
  }

  if (opened.provider_id) {
    const response = await Api.provider.get(opened.provider_id);
    opened.provider = response?.data;
  }

  changed.value = false;
  task.value = opened;
  draft.value = JSON.parse(JSON.stringify(opened || {}));

  isVisible.value = true;
}

async function save() {
  await Api.organization.task.update(task.value.id, draft.value);
  emit('save');

  changed.value = false;

  eventBus.chime('Saved');
}
</script>
