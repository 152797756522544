<template>
  <div>
    <v-card
      id="programs"
      class="mb-4"
      data-testid="programs"
      border
      flat
      tile
    >
      <v-card-title class="tab-title d-flex">
        <span v-t="terms.programs" />

        <v-spacer />
        <v-select
          @update:model-value="updateQuery({ subsidyProgramId: $event })"
          :aria-label="$t('Subsidy program')"
          :items="subsidyPrograms"
          :model-value="$route.query.subsidyProgramId"
          class="me-3 mxw-350"
          density="compact"
          item-title="name"
          item-value="id"
          variant="filled"
          hide-details
        />

        <template v-if="!$store.state.pages.Provider.features.enable_program_lock">
          <v-btn
            @click="openDraft()"
            color="primary"
            data-cy="new-program-button"
          >
            <v-icon class="fs-24"> add </v-icon>
            <span v-t="`Create new ${terms.program.toLowerCase()}`" />
          </v-btn>
        </template>
      </v-card-title>
      <v-divider />
      <v-card-text class="my-4">
        <v-row class="mb-2">
          <v-col class="fs-18">
            <span
              v-t="
                description ||
                'Add classrooms, before or after school programs, summer programs etc.'
              "
            />
          </v-col>
        </v-row>

        <template v-if="programs.length > 0">
          <v-row>
            <v-col
              v-for="(program, index) in programs"
              :key="index"
              class="mb-4"
              cols="12"
              lg="4"
              md="6"
            >
              <v-card
                :data-testid="`program-${program.id}`"
                role="listitem"
                border
                flat
                tile
              >
                <v-card-title>
                  <v-row>
                    <v-col cols="10">
                      <span
                        v-text="$t(program.name)"
                        class="fs-22"
                        data-cy="program-name"
                      />
                    </v-col>
                    <v-col
                      class="d-flex justify-end"
                      cols="2"
                    >
                      <ActionMenu
                        @click:action:delete="destroy(program)"
                        @click:action:duplicate="duplicate(program)"
                        @click:action:edit="editProgram(program)"
                        @click:action:seats="$refs.editSeatsDialog.open(program)"
                        :button-icon-size="20"
                        :items="actionItems"
                        button-data-cy="edit-program-button"
                        button-icon="more_vert"
                        left
                        x-small
                      />
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-card-text>
                  <ProviderProgram
                    :dashboard="dashboard"
                    :program="program"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>

    <ProgramEditor
      @save="save"
      ref="programEditor"
      :age-groups="ageGroups"
      :processing="processing"
      :subsidy-programs="subsidyPrograms"
    />

    <SeatsEditor
      @change="loadDashboard"
      ref="editSeatsDialog"
      :subsidy-program="subsidyProgram"
    />
  </div>
</template>

<script>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import API from '@/shared/mixins/api';
import { handleError } from '@/shared/services/api';
import ProgramEditor from '@/shared/components/provider/ProgramEditor.vue';
import { ProgramParams } from '@/shared/services/program_params';
import ProviderProgram from '@/shared/components/provider/ProviderProgram.vue';
import SeatsEditor from '@/specialist/components/provider/SeatsEditor.vue';
import RouterHelper from '@/shared/mixins/RouterHelper';
import Terms from '@/shared/mixins/terms';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ActionMenu,
    ProgramEditor,
    SeatsEditor,
    ProviderProgram,
  },

  mixins: [API, RouterHelper, Terms],

  props: {
    ageGroups: {
      type: Array,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    providerId: {
      type: String,
      default: null,
    },
    regularHours: {
      type: Array,
      default: null,
    },
  },

  emits: ['progress', 'refresh'],

  data() {
    return {
      hours: [
        {
          day: 0,
          open: 700,
          close: 1800,
          included: true,
        },
        {
          day: 1,
          open: 700,
          close: 1800,
          included: true,
        },
        {
          day: 2,
          open: 700,
          close: 1800,
          included: true,
        },
        {
          day: 3,
          open: 700,
          close: 1800,
          included: true,
        },
        {
          day: 4,
          open: 700,
          close: 1800,
          included: true,
        },
        {
          day: 5,
          open: 700,
          close: 1800,
          included: false,
        },
        {
          day: 6,
          open: 700,
          close: 1800,
          included: false,
        },
      ],
      dashboard: null,
      processing: false,
      programs: [],
      schema: null,
      subsidyPrograms: [],
    };
  },

  computed: {
    actionItems() {
      const items = [{ avatar: 'edit', event: 'edit', title: 'Edit' }];

      if (
        this.subsidyProgram?.enable_deferred_acceptance &&
        this.$store.state.profile.org_enrollments_admin
      ) {
        items.push({ event: 'seats', title: 'Edit seats', avatar: 'chair_alt' });
      }

      if (this.$store.state.pages.Provider.features.enable_program_lock) return items;

      items.push({ event: 'duplicate', title: 'Duplicate', avatar: 'content_copy' });
      items.push({ event: 'delete', title: 'Delete', avatar: 'delete' });
      return items;
    },

    subsidyProgram() {
      if (this.$route.query.subsidyProgramId && this.subsidyPrograms.length > 0) {
        return this.subsidyPrograms.find((sp) => sp.id === this.$route.query.subsidyProgramId);
      }
      return null;
    },
  },

  watch: {
    providerId: {
      immediate: true,
      handler() {
        this.load();
      },
    },

    '$route.query.tab': {
      handler(newVal) {
        if (newVal === 'programs') this.load();
      },
    },

    '$route.query.subsidyProgramId': {
      immediate: true,
      handler() {
        this.loadDashboard();
        this.loadPrograms();
      },
    },
  },

  methods: {
    destroy(program) {
      // eslint-disable-next-line no-alert
      if (!confirm(`Are you sure you want to remove this ${this.terms.program.toLowerCase()}?`))
        return;

      this.processing = true;
      this.api.program.destroy(
        this.providerId,
        program.id,
        () => {
          this.processing = false;
          this.load();
          this.$emit('refresh');
        },
        handleError,
      );
    },

    duplicate(program) {
      const draft = JSON.parse(JSON.stringify(program));
      draft.id = null;
      if (!draft.hours) {
        draft.regular_hours = true;
        draft.hours = this.getDefaultHours();
      }
      this.$refs.programEditor.open(draft);
    },

    editProgram(program) {
      const draft = JSON.parse(JSON.stringify(program));
      if (!draft.hours) {
        draft.regular_hours = true;
        draft.hours = this.getDefaultHours();
      }
      this.$refs.programEditor.open(draft);
    },

    getBlankProgram() {
      return {
        age_group_ids: [],
        age_max_months: true,
        age_min_months: true,
        custom: {},
        hours: this.getDefaultHours(),
        is_free: false,
        is_donation_based: false,
        regular_hours: true,
        season: 'Year round',
        schema_id: Object.values(this.$store.state.schemas).filter(
          (schema) => schema.data_type === 'Program',
        )[0].id,
      };
    },

    getDefaultHours() {
      if (!this.regularHours) return this.hours;

      // eslint-disable-next-line func-names
      return this.hours.map(function (new_day) {
        // eslint-disable-next-line no-shadow
        const old_day = this.regularHours.find((old_day) => old_day.day === new_day.day);
        if (old_day) {
          // eslint-disable-next-line no-param-reassign
          new_day.close = old_day.close;
          // eslint-disable-next-line no-param-reassign
          new_day.included = true;
          // eslint-disable-next-line no-param-reassign
          new_day.open = old_day.open;
          return new_day;
        }
        // eslint-disable-next-line no-param-reassign
        new_day.included = false;
        return new_day;
      }, this);
    },

    async load() {
      this.processing = true;

      this.loadPrograms();
      await this.loadSubsidyPrograms();

      if (!this.$route.query.subsidyProgramId) {
        await this.updateQuery({ subsidyProgramId: this.subsidyPrograms[0]?.id });
      }
    },

    async loadDashboard() {
      this.dashboard = null;
      if (!this.$route.query.subsidyProgramId) return;

      const filters = { subsidy_program_id: this.$route.query.subsidyProgramId };
      const resp = await this.api.organization.provider.dashboard.get(this.providerId, filters);
      this.dashboard = resp.data;
    },

    async loadPrograms() {
      this.processing = true;
      this.programs = [];

      const filters = { subsidy_program_id: this.$route.query.subsidyProgramId };
      const { data } = await this.api.program.index(this.providerId, filters);
      this.programs = data.map((program) => new ProgramParams(program).as_draft());
      this.processing = false;
      this.$emit('progress', this.programs.length > 0 ? 100 : 0);
    },

    async loadSubsidyPrograms() {
      const { data } = await this.api.organization.subsidy_program.index();
      this.subsidyPrograms = data;
    },

    openDraft() {
      const draft = this.getBlankProgram();
      this.$refs.programEditor.open(draft);
    },

    save(draft) {
      this.processing = true;
      const params = new ProgramParams(draft).as_json();
      this.api.program.updateOrCreate(
        this.providerId,
        params,
        () => {
          this.load();
          this.processing = false;
          this.$refs.programEditor.close();
          this.$eventBus.$emit('chime', 'Saved');
          this.$emit('refresh');
        },
        (error) => {
          this.processing = false;
          this.$eventBus.$emit('longChime', error.response.data.errors[0]);
        },
      );
    },
  },
};
</script>

<style>
.h-56 {
  height: 56px;
}

.w-150 {
  width: 150px;
}
</style>
