import Api from '@/shared/services/all_bright_finder';
import { ref, watch } from 'vue';

/**
 * Retrieves data from the `/organization/sent_emails` show endpoint.
 */
export default function useSentEmail(sentEmailId) {
  const query = ref({
    data: null,
    error: null,
    status: 'pending',
  });

  const cachedEmails = ref(new Map());

  watch(sentEmailId, (newValue) => {
    if (newValue) {
      getSentEmail(newValue);
    }
  });

  const getSentEmail = async (id) => {
    query.value = {
      data: null,
      status: 'pending',
      error: null,
    };

    // if the data is not in the cache, fetch it from the API
    if (!cachedEmails.value.has(id)) {
      try {
        const response = await Api.organization.sent_email.get(id);
        cachedEmails.value.set(id, response.data);
      } catch (error) {
        query.value = {
          data: null,
          status: 'error',
          error,
        };
        return;
      }
    }

    // always read from cached data
    query.value = {
      data: cachedEmails.value.get(id),
      status: 'success',
      error: null,
    };
  };

  if (sentEmailId.value) {
    getSentEmail(sentEmailId.value);
  }

  return query;
}
