<template>
  <v-card
    :to="{ name: 'SiteTemplateShow', params: { siteTemplateId: site_template.id } }"
    border
  >
    <v-card-text>
      <v-row>
        <v-col>
          <v-img
            :src="imgSrc"
            class="d-inline-block va-middle me-3"
            width="20px"
          />
          <span
            v-text="site_template.name"
            class="fs-18 fw-600 c-black va-middle"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    // eslint-disable-next-line vue/prop-name-casing
    site_template: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    imgSrc() {
      if (this.site_template.site_type === 'parent') {
        return this.$a.url('images.search');
      }
      if (this.site_template.site_type === 'manager') {
        return this.$a.url('images.providers');
      }
      if (this.site_template.site_type === 'specialist') {
        return this.$a.url('images.organizations');
      }
      return null;
    },
  },
};
</script>
