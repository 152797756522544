<template>
  <v-card
    :href="asset.url"
    target="_blank"
    border
    flat
    tile
  >
    <v-card-text>
      <v-row>
        <v-col
          class="d-flex align-center fs-18 word-break-word"
          cols="10"
          data-cy="document_name"
        >
          <div
            :class="typeClass"
            class="d-inline-block fiv-sqo"
          />
          <div
            v-text="asset.name"
            class="d-inline-block"
            style="overflow: none"
          />
        </v-col>
        <v-col
          class="d-flex align-center justify-end ta-right"
          cols="2"
        >
          <v-btn
            @click="destroy(asset)"
            :aria-label="$t('Delete asset')"
            :loading="processing"
            variant="text"
            icon
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Api from '@/specialist/services/bright_finder';

export default {
  compatConfig: { MODE: 2 },

  props: {
    asset: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      processing: false,
    };
  },
  computed: {
    typeClass() {
      const classStr = [];

      classStr.push('fiv-size-md me-4');

      classStr.push(['fiv-icon', this.asset.file.metadata.mime_type.split('/')[1]].join('-'));

      return classStr.join(' ');
    },
  },

  methods: {
    destroy() {
      this.processing = true;
      Api.member.asset.destroy(this.asset.id, () => {
        this.load();
      });
    },
  },
};
</script>
