<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-progress-linear indeterminate />
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  created() {
    const vm = this;
    this.$store.dispatch('identify', {
      success() {
        vm.$router.replace({ path: vm.$store.state.site.landing_page || '/tasks' });
      },
      failure() {
        vm.$router.replace({ path: '/' });
      },
    });
  },
};
</script>
