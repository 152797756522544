<template>
  <v-container v-if="member">
    <BreadcrumbTitle
      :back-back-name="$route.query.businessId ? 'Businesses' : null"
      :back-back-route="$route.query.businessId ? { name: 'BusinessIndex' } : null"
      :back-name="$route.query.businessId ? business?.name : 'Staff'"
      :back-route="
        $route.query.businessId
          ? { name: 'BusinessShow', params: { businessId: $route.query.businessId } }
          : { name: 'StaffIndex' }
      "
      :name="(member.name || '(Unknown name)') + (member.deactivated ? ' (Deactivated)' : '')"
    />

    <v-card
      class="mb-4"
      border
      flat
      tile
    >
      <v-card-title>
        {{ $t('Profile') }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row class="mb-3">
          <LabeledTextfield
            v-model="member.first_name"
            @input="changed = true"
            :readonly="!editor || member.deactivated"
            cols="6"
            message="First name"
          />

          <LabeledTextfield
            v-model="member.last_name"
            @input="changed = true"
            :readonly="!editor || member.deactivated"
            cols="6"
            message="Last name"
          />

          <LabeledTextfield
            v-model="member.email"
            @input="changed = true"
            :readonly="!editor || member.deactivated"
            cols="6"
            message="Email"
          />

          <LabeledTextfield
            v-model="member.phone"
            @input="changed = true"
            :readonly="!editor || member.deactivated"
            cols="6"
            mask="(###) ###-####"
            message="Phone"
          />
        </v-row>

        <template v-if="business">
          <div class="fs-16 fw-500">
            This user is a
            <strong
              v-if="member.deactivated"
              v-t="'deactivated'"
            />
            staff member at
            <router-link
              :to="{
                name: 'BusinessShow',
                params: { businessId: business.id },
              }"
            >
              {{ $t(business.name || 'Unknown name') }}
            </router-link>
          </div>
        </template>
      </v-card-text>
    </v-card>

    <v-card
      v-if="business && !member.deactivated"
      class="mb-4"
      border
      flat
      tile
    >
      <v-card-title>
        {{ $t('Access') }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <LabeledSimpleSelect
            v-model="member.business_role"
            @input="changed = true"
            :items="['Member', 'Admin']"
            :readonly="!editor || member.deactivated"
            message="Role"
          />
        </v-row>

        <v-row>
          <LabeledSimpleSelect
            v-model="member.provider_ids"
            @input="changed = true"
            :items="providers"
            :readonly="!editor || member.deactivated"
            item-title="name"
            item-value="id"
            message="Assigned locations"
            placeholder="All locations"
            chips
            deletable-chips
            multiple
          />
        </v-row>

        <template v-if="member.provider_ids.length === 0">
          <v-row>
            <v-col class="fs-16">
              <strong>{{ $t('Note') }}:</strong>
              {{
                $t(
                  'This staff member has not be assigned to any locations, so they have access to all the locations of this business. Assign locations to limit their access to only the locations desired.',
                )
              }}
            </v-col>
          </v-row>
        </template>

        <v-row>
          <LabeledSwitch
            v-model="member.business_messages_access"
            @input="changed = true"
            subtitle="Can access instant messages and respond to messages from organization staff and families."
            title="Messaging"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      v-if="!member.deactivated && isLearningAdmin"
      class="mb-4"
      border
      flat
      tile
    >
      <v-card-title>
        <span v-t="'Courses'" />
      </v-card-title>

      <v-divider />

      <v-card-text class="fs-16">
        <template v-if="courses.length">
          <StaffCourseCard
            v-for="course in courses"
            @destroy="loadMemberCourses"
            :key="course.id"
            :cohort="getCourseCohort(course)"
            :cohortCourseCompletion="getCourseCohortCourseCompletion(course)"
            :course="course"
            :course-completion="
              courseCompletions.find((courseCompletion) => courseCompletion.course_id === course.id)
            "
          />
        </template>

        <p v-else>
          {{ $t('No courses found') }}
        </p>
      </v-card-text>

      <v-divider class="mb-4" />

      <v-card-actions>
        <v-btn
          @click="$refs.newCourseCompletionDialog.open({})"
          color="primary"
        >
          <v-icon
            class="ml-n1 mr-2"
            start
          >
            add
          </v-icon>

          <span v-t="'Add'" />
        </v-btn>
      </v-card-actions>
    </v-card>

    <template v-if="editor">
      <v-dialog
        v-model="loginCodeDialogIsVisible"
        max-width="700"
      >
        <v-card
          border
          flat
          tile
        >
          <v-card-title>Login code</v-card-title>

          <v-divider />

          <v-card-text>
            <v-text-field
              @click:append="copyLoginCode"
              :model-value="loginCodeLink"
              append-icon="content_copy"
              variant="filled"
              readonly
            />
          </v-card-text>

          <v-divider class="my-6" />

          <v-card-actions>
            <v-btn
              @click="loginCodeDialogIsVisible = false"
              color="primary"
              block
            >
              {{ $t('Done') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <AccountSettings :member="member">
        <v-btn
          v-if="!member.deactivated"
          @click="createLoginCode"
          :loading="processing"
          class="mx-2"
          color="primary"
          prepend-icon="add_link"
          variant="outlined"
        >
          <span v-t="'Create temporary login link'" />
        </v-btn>
      </AccountSettings>
    </template>

    <ResourceDialog
      @save="createCourseCompletion"
      ref="newCourseCompletionDialog"
      :fields="newCourseCompletionFields"
      :processing="courseCompletionProcessing"
      title="Add completed course"
    />

    <SaveBar
      @cancel="load"
      @save="save"
      :model-value="changed"
      :processing="processing"
      flat
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import AccountSettings from '@/shared/components/AccountSettings.vue';
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import StaffCourseCard from '@/specialist/components/StaffCourseCard.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import { useRoute } from 'vue-router';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();
const eventBus = useEventBus();

const business = ref(null);
const changed = ref(false);
const cohortCourseCompletions = ref([]);
const cohorts = ref([]);
const courses = ref([]);
const courseCompletions = ref([]);
const courseCompletionProcessing = ref(false);
const loginCodeDialogIsVisible = ref(false);
const loginCodeLink = ref(null);
const member = ref(null);
const processing = ref(false);
const providers = ref([]);
const publicCourses = ref([]);
const newCourseCompletionDialog = ref(null);

const newCourseCompletionFields = computed(() => {
  const existingCourseIds = new Set(courses.value.map((course) => course.id));
  const joinableCourses = publicCourses.value.filter(
    (publicCourse) => !existingCourseIds.has(publicCourse.id),
  );

  return [
    {
      multiple: false,
      text: 'Public course',
      items: joinableCourses,
      itemText: 'name',
      itemValue: 'id',
      value: 'course_id',
    },
    {
      text: 'Date completed',
      type: 'date',
      value: 'completed_at',
    },
  ];
});

const isLearningAdmin = computed(() => {
  return store.state.profile?.org_learning_admin;
});

const editor = computed(() => {
  return store.state.profile.org_business_edit;
});

watch(() => route.params.memberId, load, { immediate: true });

function createCourseCompletion(courseCompletion) {
  courseCompletionProcessing.value = true;
  Api.organization.course_completion.create(
    {
      ...courseCompletion,
      member_id: route.params.memberId,
    },
    async (response) => {
      newCourseCompletionDialog.value.close();

      courseCompletions.value.push(response.data);
      await loadCourses();

      courseCompletionProcessing.value = false;
    },
    (error) => {
      courseCompletionProcessing.value = false;
      eventBus.error(error.response.data.errors[0]);
    },
  );
}

function createLoginCode() {
  Api.organization.login_code.create({ member_id: route.params.memberId }, (response) => {
    loginCodeLink.value = response?.data?.link;
    loginCodeDialogIsVisible.value = true;
  });
}

function copyLoginCode() {
  navigator.clipboard.writeText(loginCodeLink.value);
  eventBus.chime('Copied');
}

function getCourseCohort(course) {
  if (!course.restricted) return;
  if (!cohorts.value.length) return;

  return cohorts.value.find((cohort) => cohort.relationships.course.data.id === course.id);
}

function getCourseCohortCourseCompletion(course) {
  if (!cohortCourseCompletions.value.length) return;

  const cohort = getCourseCohort(course);
  if (!cohort) return;

  return cohortCourseCompletions.value.find(
    (cohortCourseCompletion) => cohortCourseCompletion.relationships.cohort.data.id === cohort.id,
  );
}

async function load() {
  changed.value = false;
  member.value = (await Api.organization.member.get(route.params.memberId)).data;

  if (member.value.business_id) {
    business.value = (await Api.organization.business.get(member.value.business_id)).data;

    const response = await Api.organization.provider.index({
      business_id: member.value.business_id,
      page_size: 200,
    });
    providers.value = response?.data;
  }

  if (isLearningAdmin.value) {
    await loadMemberCourses();
    await loadPublicCourses();
  }
}

async function loadCohortCourseCompletions() {
  const completedCourseIds = courseCompletions.value.map(
    (courseCompletion) => courseCompletion.course_id,
  );
  if (!completedCourseIds.length) return;

  const params = {
    filter: {
      course_completion: completedCourseIds.join(','),
    },
  };
  const response = await Api.cohortCourseCompletion.index(params);

  cohortCourseCompletions.value = response?.data?.data || [];
}

async function loadCohorts() {
  const filters = {
    filter: {
      member: route.params.memberId,
    },
  };
  const response = await Api.cohort.index(filters);

  cohorts.value = response?.data?.data || [];
}

async function loadCourseCompletions() {
  const response = await Api.organization.course_completion.index({
    member_id: route.params.memberId,
  });
  courseCompletions.value = response.data;
}

async function loadPublicCourses() {
  if (!isLearningAdmin.value) return;

  const response = await Api.public_api.organization.course.index();
  publicCourses.value = response?.data || [];
}

async function loadCourses() {
  const ids = [
    courseCompletions.value.map((courseCompletion) => courseCompletion.course_id),
    cohorts.value.map((cohort) => cohort.relationships.course.data.id),
  ]
    .flat()
    .join(',');

  const response = await Api.organization.course.index({
    page: 0,
    id: ids,
  });
  courses.value = response?.data || [];
}

async function loadMemberCourses() {
  if (!isLearningAdmin.value) return;

  await loadCohorts();
  await loadCourseCompletions();

  await Promise.allSettled([loadCourses(), loadCohortCourseCompletions()]);
}

function save() {
  processing.value = true;
  Api.organization.member.update(
    member.value,
    (response) => {
      member.value = response.data;
      processing.value = false;
      changed.value = false;
      eventBus.chime('Saved');
    },
    async (error) => {
      processing.value = false;
      eventBus.chime(error.response.data.errors[0]);
      await load();
    },
  );
}
</script>
