<template>
  <div>
    <div
      v-if="draftReview"
      elevation="2"
      tile
    >
      <div class="align-center bb-1 bc-very-light-grey d-flex pa-4">
        <v-btn
          @click="closeDraftReview"
          class="fs-16 fw-600"
          prepend-icon="west"
          variant="text"
        >
          {{ $t('All reviews') }}
        </v-btn>
        <template v-if="draftReview.id">
          <v-spacer />
          <v-btn
            @click="destroyReview"
            icon="delete"
            variant="text"
          />
        </template>
      </div>

      <div class="pa-4">
        <QuestionSet
          v-if="reviewSchema"
          v-model="draftReview"
          :elevation="0"
          :processing="processing"
          :questions="reviewQuestions"
          :schema="reviewSchema.definition"
          expanded
          flat
          hide-actions
          outlined-sections
          paddingless
          very-dense
        />

        <div
          v-for="question in verificationQuestions"
          :key="question.id"
          class="mb-6"
        >
          <FormQuestion
            :color="null"
            :elevation="0"
            :subtitle="question.verification_subtitle"
            :title="question.verification_title"
            hide-actions
            paddingless
            tile
            very-dense
          >
            <AttachmentUploader
              @uploaded="attachments.push($event)"
              :ref="['uploader', question.id, grant.id].join('')"
              :owner="{
                business_id: grant.business_id,
                type: 'Grant',
                id: grant.id,
                tag: ['review', grant.id, question.id].join('-'),
                tags: ['review', grant.id, question.id],
              }"
              class="mb-4"
              dense
            />
            <AttachmentList
              @delete="loadAttachments"
              :attachments="
                attachments.filter(
                  (attachment) => attachment.tag == ['review', grant.id, question.id].join('-'),
                )
              "
              dense
            />
          </FormQuestion>
        </div>

        <v-textarea
          v-model="draftReview.note"
          :placeholder="$t('Add optional note')"
          class="mb-4"
          variant="filled"
          hide-details
        />

        <v-row dense>
          <LabeledSimpleSelect
            v-model="draftReview.status"
            :items="reviewStatuses"
            active-class="bg-primary c-white"
            class="mb-4"
            message="Status"
            tile
          />
        </v-row>

        <div>
          <v-btn
            @click="publishReview"
            :disabled="!(reviewSchema && draftReview.status)"
            :loading="processing"
            class="mb-2"
            color="primary"
            block
          >
            {{ $t('Save and publish') }}
          </v-btn>
        </div>
      </div>
    </div>

    <v-card
      v-else
      class="fill-height"
      elevation="2"
      tile
    >
      <v-card-title class="bb-1 bc-very-light-grey fs-16 fw-600 pa-5 d-flex">
        <span tabindex="0">{{ $t('Reviews') }}</span>
        <v-spacer />
        <v-btn
          @click="$emit('close')"
          :aria-label="$t('Close Review')"
          class="focus-visible"
          icon="close"
          variant="text"
        />
      </v-card-title>

      <v-card-text>
        <div
          v-if="reviews.length == 0"
          class="fs-16 pa-6"
          tabindex="0"
        >
          {{ $t('No reviews submitted yet.') }}
        </div>
        <v-list
          v-if="reviews.length > 0"
          class="mb-3"
        >
          <template
            v-for="(review, index) in reviews"
            :key="index"
          >
            <v-divider v-show="index != 0" />

            <v-list-item lines="three">
              <v-list-item-title>
                {{ schemas.find((schema) => schema.id == review.schema_id).name }}
              </v-list-item-title>
              <v-list-item-subtitle class="pt-1">
                {{ review.member_name || $t('Unknown reviewer') }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="pt-1">
                <LongDate :date="review.created_at" />
              </v-list-item-subtitle>

              <div class="pt-3">
                <v-btn
                  @click="editReview(review)"
                  :color="reviewColor(review.published, review.status)"
                  :loading="processing"
                  size="large"
                >
                  {{ review.published ? review.status : $t('Draft') }}
                </v-btn>
              </div>
            </v-list-item>
          </template>
        </v-list>
        <v-btn
          @click="$refs.draftDialog.open()"
          color="primary"
          block
        >
          {{ $t('Add review') }}
        </v-btn>
      </v-card-text>
    </v-card>

    <ResourceDialog
      @save="createDraft"
      ref="draftDialog"
      :fields="draftFields"
      save-button-text="Draft"
      title="Draft review"
    />
  </div>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LinkedList from '@/shared/services/linked-list';
import LongDate from '@/shared/components/LongDate.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import { NEUTRAL_STATUSES, POSITIVE_STATUSES } from '@/specialist/services/statuses';

const STATUSES = ['Reviewed', 'Awaiting documents', 'Denied', 'Withdrawn', 'Approved'];

export default {
  compatConfig: { MODE: 3 },

  components: {
    AttachmentList,
    AttachmentUploader,
    LabeledSimpleSelect,
    LongDate,
    FormQuestion,
    ResourceDialog,
    QuestionSet,
  },

  props: {
    grant: {
      type: Object,
      default: null,
    },
    grantProgram: {
      type: Object,
      default: null,
    },
  },

  emits: ['close', 'published'],

  data() {
    return {
      attachments: [],
      draftFields: [
        {
          text: 'Select review type',
          value: 'schema_id',
          items: this.getSchemas(),
          itemText: 'name',
          itemValue: 'id',
          required: true,
        },
      ],
      draftReview: null,
      processing: false,
      reviewQuestions: [],
      reviews: [],
      reviewStatuses: STATUSES,
      reviewSchema: null,
      schemas: this.getSchemas(),
      verificationQuestions: [],
    };
  },

  watch: {
    reviewSchema(newVal) {
      if (newVal) {
        this.loadReviewQuestions();
        this.loadAttachments();
      } else {
        this.reviewQuestions = [];
      }
    },
  },

  created() {
    this.loadReviews();
  },

  methods: {
    closeDraftReview() {
      this.reviewSchema = null;
      this.draftReview = null;
    },

    async createDraft(draft) {
      this.processing = true;
      this.reviewSchema = this.$store.state.schemas[draft.schema_id];
      const params = {
        schema_id: this.reviewSchema.id,
        owner_id: this.grant.id,
        owner_type: 'Grant',
      };

      const resp = await Api.organization.review.create(params);
      this.processing = false;

      if (resp.status === 201) {
        this.draftReview = resp.data;
        this.draftReview.status = this.reviewStatuses[0];
        this.processing = false;
        this.$refs.draftDialog.close();
        this.loadReviews();
      }
    },

    async destroyReview() {
      if (!confirm('Are you sure you want to remove this review?')) return;

      this.processing = true;

      try {
        await Api.organization.review.destroy(this.draftReview.id);
        this.$eventBus.$emit('chime', 'Review removed');
      } finally {
        this.loadReviews();
        this.draftReview = null;
        this.processing = false;
      }
    },

    async editReview(review) {
      this.reviewSchema = this.$store.state.schemas[review.schema_id];
      this.draftReview = review;
      this.draftReview.status ||= this.reviewStatuses[0];
    },

    getSchemas() {
      return Object.values(this.$store.state.schemas)
        .filter(
          (schema) =>
            schema.data_type === 'Review' &&
            this.grantProgram.associated_schema_ids.includes(schema.id),
        )
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    },

    async loadAttachments() {
      const params = {
        owner_type: 'Grant',
        owner_id: this.grant.id,
      };
      const resp = await Api.organization.attachment.index(params);
      this.processing = false;
      if (resp?.status === 200) this.attachments = resp.data;
    },

    loadReviewQuestions() {
      Api.public_api.organization.question.index(
        { owner_id: this.reviewSchema.id, owner_type: 'Schema' },
        (resp) => {
          this.reviewQuestions = LinkedList.sort(resp.data);
          this.verificationQuestions = this.reviewQuestions.filter(
            (question) => question.verification,
          );
        },
      );
    },

    async loadReviews() {
      const { data } = await Api.organization.review.index({ owner_id: this.grant.id });
      this.reviews = data;
    },

    async publishReview() {
      this.processing = true;

      const params = {
        custom: this.draftReview.custom,
        note: this.draftReview.note,
        owner_id: this.grant.id,
        owner_type: 'Grant',
        published: true,
        status: this.draftReview.status,
      };

      const { status } = await Api.organization.review.update(this.draftReview.id, params);

      this.processing = false;
      if (status === 200) {
        this.loadReviews();
        this.draftReview = null;
        this.processing = false;
        this.$emit('published');
      }
    },

    reviewColor(published, status) {
      if (!published) return 'grey';
      if (NEUTRAL_STATUSES.includes(status)) return 'grey';
      if (POSITIVE_STATUSES.includes(status)) return 'green';
      return 'red';
    },
  },
};
</script>
