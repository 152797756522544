import '@/plugins/data-dog';
import Api from '@/shared/services/bright_finder';
import App from '@/specialist/App.vue';
import GoogleTagManagerLoader from '@/plugins/google_tag_manager_loader';
import assets from '@/shared/services/assets';
import customStyles from '@/plugins/custom-styles';
import i18n from '@/plugins/i18n';
import router from '@/specialist/router';
import store from '@/specialist/store';
import vuetify from '@/plugins/vuetify';
import '@/styles/main.scss';
import '@/plugins/uppy';
import { EventBus } from '@/plugins/event-bus';
import { EventSummary } from '@/shared/services/event_summary';
import { createApp, configureCompat } from 'vue';
import { isAuthenticated, needsMFA, needsPasswordReset } from '@/shared/services/auth';
import handleNativeClickEvent from '@/shared/utils/analytics/handleNativeClickEvent';

configureCompat({
  MODE: 3,
  RENDER_FUNCTION: false, // opt-in to Vue3 render function API changes
});

// Unfortunately, won't be usable until this issue is resolved: https://github.com/miragejs/miragejs/issues/1006
if (import.meta.env.VUE_ENABLE_MIRAGE === 'true') {
  const { makeServer } = require('@/shared/server');
  makeServer({ environment: 'development' });
}

router.beforeEach((to, _from, next) => {
  if (to.meta.public || to.path === '/sign_out') return next();

  let config = store.state.config;
  let profile = store.state.profile;
  if (isAuthenticated(store)) {
    if (needsPasswordReset(config, profile, to) && to.name !== 'RegisterPassword')
      return next({ name: 'RegisterPassword', query: to.query });
    if (!needsMFA(store)) return next();
  }

  setTimeout(() => {
    store.dispatch('identify', {
      success() {
        config = store.state.config;
        profile = store.state.profile;
        if (needsPasswordReset(config, profile, to) && to.name !== 'RegisterPassword') {
          return next({ name: 'RegisterPassword', query: to.query });
        }

        if (needsMFA(store) && !to.meta.registration) return next({ name: 'RegisterFactor' });

        next();
      },
      failure() {
        next({ path: '/' });
      },
    });
  }, 100);
});

Api.public_api.site.configuration.get((resp) => {
  const config = resp.data;
  window.api_url = config.api_url;
  window.organization_id = config.organization_id;
  window.site_id = config.site_id;

  const app = createApp(App);

  store.commit('setRole', 'specialist'); // Vue3 global access
  app.config.globalProperties.$role = 'specialist';
  app.config.globalProperties.$a = assets;
  app.config.globalProperties.$eventBus = EventBus;
  app.provide('eventBus', EventBus);
  app.config.globalProperties.window = window;

  app.use(i18n);
  app.use(router);
  app.use(store);
  app.use(vuetify);

  setConfig();

  app.mount('#app');

  resetVuetifyTheme();

  customStyles.generate(store.state.brand.colors);
  document.getElementById('favicon').setAttribute('href', config.favicon_url);

  document.addEventListener('click', (event) => {
    store.commit('resetTimeout');

    handleNativeClickEvent(event);

    if (!event.target.attributes['data-skip-event']) {
      const summary = new EventSummary(event);
      if (summary.target) {
        Api.user_event.create(summary.asJson());
      }
    }
  });

  if (store.state.site.google_tag_manager_id) {
    GoogleTagManagerLoader.activateGtm(
      window,
      document,
      'script',
      'dataLayer',
      store.state.site.google_tag_manager_id,
    );
  }

  function setConfig() {
    store.commit('setBrand', config.brand);
    store.commit('setConfig', config);
    store.commit('setFeatures', config.features);
    store.commit('setPages', config.pages);
    store.commit('setLanguages', config.languages);
    store.commit('setSchemas', config.schemas);
    store.commit('setSite', config.site);
    store.commit('setAgeGroups', config.age_groups);
    store.commit('setSiteWebpageId', config.site_webpage_id);
    store.commit('setUserSessionTimeout', config.user_session_timeout_minutes);
  }

  function resetVuetifyTheme() {
    vuetify.theme.themes.value.light.colors.primary = store.state.brand.colors.primary;
    vuetify.theme.themes.value.light.colors.secondary = store.state.brand.colors.secondary;
    vuetify.theme.themes.value.light.colors.accent = store.state.brand.colors.accent;
  }
});
