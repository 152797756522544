<template>
  <v-container
    v-if="collection"
    class="mxw-1200 pa-6"
  >
    <TitleBar
      @create="$refs.newDialog.open({})"
      class="mb-4"
      name="Terms agreements"
      createable
    />

    <v-card
      v-for="agreement in collection"
      :key="agreement.id"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <v-col>
            <router-link
              :to="{ name: 'TermsAgreementShow', params: { termsAgreementId: agreement.id } }"
              >{{ agreement.name }}</router-link
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <NullState
      v-if="collection.length == 0"
      @new="$refs.newDialog.open({})"
      new-button-title="New agreement"
    />

    <ResourceDialog
      @save="create"
      ref="newDialog"
      :fields="[{ text: 'Name', value: 'name' }]"
      save-button-text="Save"
      title="New terms agreement"
      close-on-save
    />
  </v-container>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ResourceDialog,
  },

  data() {
    return {
      collection: null,
    };
  },
  created() {
    this.load();
  },

  methods: {
    create(newVal) {
      Api.organization.termsAgreement.create(newVal, (resp) => {
        this.$router.push({
          name: 'TermsAgreementShow',
          params: { termsAgreementId: resp.data.id },
        });
      });
    },

    async load() {
      const response = await Api.organization.termsAgreement.index();
      this.collection = response.data;
    },
  },
};
</script>
