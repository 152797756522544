<template>
  <div
    v-if="agreement"
    fluid
  >
    <PageTitle
      :back-name="$t('Terms agreements')"
      :back-route="{ name: 'TermsAgreementIndex' }"
      :title="agreement.name"
      flat
      outlined
    />

    <v-container fluid>
      <v-card
        border
        flat
        tile
      >
        <v-card-text>
          <v-row>
            <LabeledTextfield
              v-model="agreement.title"
              message="Title"
              filled
            />
            <LabeledControl message="Content">
              <div
                id="editor"
                class="w-100pc br-0 mb-3"
              />
            </LabeledControl>
            <LabeledTextfield
              v-model="agreement.accept_label"
              message="Accept button label"
              filled
            />
          </v-row>
        </v-card-text>

        <v-divider class="mb-4" />

        <v-card-actions>
          <v-btn
            @click="save"
            color="primary"
            size="x-large"
            >{{ $t('Save') }}</v-btn
          >
          <v-spacer />
          <v-btn
            @click="destroy"
            color="red"
            variant="text"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import API from '@/shared/mixins/api';
import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css';

export default {
  compatConfig: { MODE: 2 },

  mixins: [API],

  data() {
    return {
      agreement: null,
    };
  },
  created() {
    this.load();
  },

  methods: {
    async destroy() {
      // eslint-disable-next-line no-alert
      if (!confirm('Are you sure you want to delete these terms?')) return;

      await this.api.organization.termsAgreement.destroy(this.$route.params.termsAgreementId);

      this.$router.push({ name: 'TermsAgreementIndex' });
    },

    async load() {
      const resp = await this.api.organization.termsAgreement.get(
        this.$route.params.termsAgreementId,
      );
      this.agreement = resp.data;
      this.loadEditor();
    },

    loadEditor() {
      setTimeout(() => {
        this.editor = new Editor({
          el: document.querySelector('#editor'),
          height: '300px',
          initialEditType: 'wysiwyg',
          initialValue: this.agreement.content || '',
          previewStyle: 'vertical',
        });
      }, 100);
    },

    save() {
      this.agreement.content = this.editor.getMarkdown().replaceAll('\\_', '_');
      this.api.organization.termsAgreement.update(this.agreement.id, this.agreement, (resp) => {
        this.agreement = resp.data;
        this.$eventBus.$emit('chime', 'Saved');
        // this.editor.reset()
      });
    },
  },
};
</script>
