<template>
  <v-card
    v-if="member"
    border
    tile
  >
    <v-card-title>
      {{ $t('Account actions') }}
    </v-card-title>

    <v-divider class="mb-2" />

    <v-card-text>
      <div>
        <template v-if="member.deactivated">
          <v-btn
            @click="toggleActivationStatus"
            :loading="processing"
            color="green"
            prepend-icon="unarchive"
          >
            {{ $t('Reactivate user') }}
          </v-btn>
        </template>

        <template v-else>
          <v-btn
            @click="toggleActivationStatus"
            :loading="processing"
            color="red"
            prepend-icon="archive"
          >
            {{ $t('Deactivate user') }}
          </v-btn>
        </template>

        <slot />
      </div>
    </v-card-text>

    <ConfirmDialog ref="confirmDialog" />
  </v-card>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';

const confirmDialog = ref(null);
const eventBus = useEventBus();
const router = useRouter();

const props = defineProps({
  member: {
    type: Object,
    default: () => {},
  },
});

const processing = ref(false);

async function toggleActivationStatus() {
  const pendingAction = props.member.deactivated ? 'reactivate' : 'deactivate';

  const updatedMember = {
    ...props.member,
    deactivated: !props.member.deactivated,
  };

  const confirm = await confirmDialog.value.confirmWithText(
    `Are you sure you want to ${pendingAction} this user?`,
  );
  if (!confirm) return;

  processing.value = true;
  const response = await Api.organization.member.update(updatedMember);

  processing.value = false;
  if (response?.status !== 200) {
    eventBus.chime(response.data.errors[0]);
  } else {
    props.member.deactivated = !props.member.deactivated;
    eventBus.chime(`User ${pendingAction}d`);
    router.go(-1);
  }
}
</script>
