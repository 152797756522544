<template>
  <v-card
    class="mb-8"
    border
    tile
  >
    <v-card-title>
      {{ $t(props.title) }}
    </v-card-title>

    <template v-if="subtitle">
      <v-card-text>
        {{ $t(props.subtitle) }}
      </v-card-text>
    </template>

    <v-divider />

    <v-card-text>
      <v-row
        v-for="(permission, index) in visiblePermissions"
        :key="permission.field"
        dense
      >
        <LabeledSwitch
          v-model="model[permission.field]"
          @input="$emit('change:permission', $event)"
          :divided-bottom="index !== visiblePermissions.length - 1"
          :hard-lock="isPermissionLocked(permission)"
          :hard-lock-message="permission.locked_tooltip"
          :subtitle="permission.subtitle"
          :title="permission.description"
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import _ from 'lodash';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';

defineEmits(['change:permission']);

const model = defineModel();
const props = defineProps({
  permissions: {
    type: Array,
    default: () => [],
  },
  subtitle: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
});

// Note: in order to do a watch on our modeled object that gives us an old and new value (instead of pointing to same object),
//       we have to define a "getter". This computed property provides that while narrowing our watch to fields with dependencies
const dependencyTriggerFieldsToWatch = computed(() => {
  const valuesOfPermissionsWithDependencies = {};
  for (const field of fieldsWithDependents.value) {
    valuesOfPermissionsWithDependencies[field] = model.value[field];
  }
  return valuesOfPermissionsWithDependencies;
});

const fieldsWithDependents = computed(() => {
  const permissionsWithDependents = props.permissions.filter(
    (permission) => Object.keys(permission.dependent_fields || {}).length > 0,
  );
  return permissionsWithDependents.map((permission) => permission.field);
});

const visiblePermissions = computed(() => {
  return props.permissions.filter(
    (permission) => !permission.conditional_field || model.value[permission.conditional_field],
  );
});

function isPermissionLocked(permission) {
  const lockedByFields = Object.keys(permission.locked_by || {});
  if (lockedByFields.length === 0) return false;

  for (const field of lockedByFields) {
    if (!!model.value[field] === permission.locked_by[field]) return true;
  }

  return false;
}

watch(
  dependencyTriggerFieldsToWatch,
  (newValue, oldValue) => {
    if (oldValue) {
      let fieldChangedToTrue = false;
      let permission = null;

      for (const field of Object.keys(newValue)) {
        // Note: currently only adding ability to update on change to true, potential to expand w/ specialist-permissions
        //       to specify changes based on to true or to false
        fieldChangedToTrue = newValue[field] && !oldValue[field];

        if (fieldChangedToTrue) {
          permission = props.permissions.find((permission) => permission.field === field);
          model.value = _.merge(model.value, permission.dependent_fields);
        }
      }
    }
  },
  { immediate: true, deep: true },
);
</script>
