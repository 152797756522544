<template>
  <div>
    <v-container
      v-if="draft?.attributes"
      class="px-0 py-0 bg-super-light-blue"
      fluid
    >
      <div class="mt-3 mx-3">
        <v-card
          class="bc-outlined-gray"
          border
          tile
        >
          <v-card-title>
            {{ $t('Settings') }}
          </v-card-title>

          <v-divider />

          <v-card-text>
            <LabeledTextfield
              v-model="draft.attributes.name"
              @change="changed = true"
              id="cohort-name"
              :hard-lock="!isLearningAdmin"
              :processing="processing"
              message="Name"
            />

            <v-col
              v-if="course && isLearningAdmin"
              cols="12"
            >
              <v-row dense>
                <v-col
                  class="labeled-input"
                  cols="12"
                >
                  {{ $t('Course') }}
                </v-col>

                <v-col class="fs-16 c-light-black">
                  <router-link
                    :to="{
                      name: 'CourseShow',
                      params: {
                        courseId: course.id,
                      },
                      query: {
                        tab: 'settings',
                      },
                    }"
                  >
                    {{ course.attributes.name }}
                  </router-link>
                </v-col>
              </v-row>
            </v-col>

            <LabeledAutocomplete
              v-model="draft.relationships.facilitator.data.id"
              @change="changed = true"
              id="cohort-facilitator"
              :hard-lock="!isLearningAdmin"
              :items="facilitatorOptions"
              :processing="processing"
              item-title="name"
              item-value="id"
              message="Facilitator"
              clearable
            />

            <LabeledDatePicker
              v-model="draft.attributes.start_date"
              @change="changed = true"
              id="cohort-start-date"
              :disabled="!isLearningAdmin"
              :processing="processing"
              message="Start date"
            />

            <LabeledDatePicker
              v-model="draft.attributes.end_date"
              @change="changed = true"
              id="cohort-end-date"
              :disabled="!isLearningAdmin"
              :processing="processing"
              message="End date"
            />

            <LabeledTextfield
              v-model="draft.attributes.invitation_code"
              @change="changed = true"
              id="cohort-invitation-code"
              :hard-lock="!isLearningAdmin"
              :processing="processing"
              message="Invitation code"
            />
          </v-card-text>
        </v-card>
      </div>

      <VerticalSpacer :min-height="50" />
    </v-container>

    <SaveBar
      @cancel="reset"
      @save="save"
      :model-value="changed"
      flat
    />
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import useEventBus from '@/shared/composables/useEventBus';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const route = useRoute();
const store = useStore();

const props = defineProps({
  course: {
    type: Object,
    default: null,
  },
  initialCohort: {
    type: Object,
    default: null,
  },
});

const changed = ref(false);
const cohort = ref(props.initialCohort);
const draft = reactive({});
const facilitatorOptions = ref([]);
const processing = ref(true);

const isLearningAdmin = computed(() => {
  return store.state.profile?.org_learning_admin;
});

async function load() {
  processing.value = true;

  reset();
  await loadMembers();

  processing.value = false;
}

async function loadMembers() {
  const { data } = await Api.organization.member.index({
    is_specialist: true,
  });
  facilitatorOptions.value = data;
}

function reset() {
  Object.assign(draft, JSON.parse(JSON.stringify(cohort.value || {})));
  if (!draft.relationships.facilitator.data) {
    draft.relationships.facilitator.data = { id: null };
  }

  changed.value = false;
}

async function save() {
  processing.value = true;

  const { data } = await Api.cohort.update(route.params.cohortId, draft, {
    include: 'facilitator',
  });

  cohort.value = data?.data;
  changed.value = false;

  eventBus.chime('Saved');

  processing.value = false;
}

onMounted(load);
</script>
