<template>
  <v-card
    class="fill-height"
    elevation="2"
    tile
  >
    <v-card-title class="bb-1 bc-very-light-grey fs-16 fw-600 pa-5 d-flex">
      <span tabindex="0">Notes</span>
      <v-spacer />
      <v-btn
        @click="$emit('close')"
        aria-label="Close Note"
        class="focus-visible"
        icon="close"
        variant="text"
      />
    </v-card-title>

    <v-card-text class="pt-4">
      <template v-if="notes.length == 0">
        <p class="fs-16">No notes created yet.</p>
      </template>

      <div
        v-for="note in notes"
        :key="note.id"
        class="mb-3"
        tabindex="0"
      >
        <div class="bg-super-light-blue pa-3 fs-16 fw-500">
          <div v-text="note.text" />
        </div>
        <LongDateTime
          :date="note.created_at"
          :prefix="[note.meta.member.first_name, note.meta.member.last_name].join(' ') + ' - '"
          class="fs-12"
        />
      </div>

      <v-divider class="my-6" />

      <v-textarea
        v-model="newNoteText"
        aria-label="Enter new note"
        tabindex="0"
        variant="filled"
      />
      <v-btn
        @click="createNote"
        :disabled="processing"
        color="primary"
        block
      >
        <span v-t="'Add note'" />
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import API from '@/shared/mixins/api';
import LongDateTime from '@/shared/components/LongDateTime.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    LongDateTime,
  },

  mixins: [API],

  props: {
    ownerType: {
      default: 'Form',
      type: String,
    },
    owner: {
      type: Object,
      default: null,
    },
  },

  emits: ['close'],

  data() {
    return {
      newNoteText: null,
      notes: [],
      processing: null,
    };
  },

  created() {
    this.loadNotes();
  },

  methods: {
    async createNote() {
      this.processing = true;
      const params = {
        owner_id: this.owner.id,
        owner_type: this.ownerType,
        text: this.newNoteText,
      };
      const resp = await this.api.organization.note
        .create(params)
        .catch((error) => this.$eventBus.$emit('error', error));
      this.processing = false;
      if (!resp?.data) return;

      this.newNoteText = null;
      this.notes.push(resp.data);
    },

    async loadNotes() {
      const resp = await this.api.organization.note.index({ owner_id: this.owner.id });
      this.notes = resp.data;
    },
  },
};
</script>
