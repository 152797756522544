<template>
  <v-card
    v-if="provider"
    class="pa-3"
    border
    tile
  >
    <div class="c-black">
      <v-row dense>
        <v-col cols="8">
          <v-row
            class="ma-0 pa-0"
            dense
          >
            <v-col
              class="d-flex align-center"
              cols="8"
            >
              <div class="d-flex align-center">
                <span
                  v-t="provider.name"
                  class="c-black fw-600 fs-20"
                  dense
                />
              </div>
            </v-col>
            <v-col
              v-if="enrollmentRequest"
              class="ta-right"
              cols="4"
            >
              <v-btn color="primary">{{
                $t('Status: ' + enrollmentRequest.status || 'New')
              }}</v-btn>
            </v-col>
          </v-row>

          <v-row
            class="ma-0 pa-0"
            dense
          >
            <v-col class="d-flex align-center">
              <div class="fs-16 fw-400">
                {{ providerAddress() }}
              </div>
              <span class="mx-3 fw-400 fw-14">|</span>
              <router-link
                :to="{ name: 'ProviderShow', params: { providerId: provider.id } }"
                class="fs-14 fw-400"
                color="secondary"
                target="_blank"
                small
              >
                <span v-t="'View profile'" />
              </router-link>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          v-if="!readonly"
          class="ta-right"
        >
          <v-btn
            @click="match"
            :loading="processing"
            color="primary"
            variant="outlined"
          >
            <span v-t="'Match'" />
          </v-btn>
        </v-col>
      </v-row>

      <template v-if="programs.length > 0">
        <v-divider class="my-4" />
        <ProgramMatchSelector
          v-for="program in programs"
          @reserve="reserve"
          :key="program.id"
          :openings="openings"
          :program="program"
          :readonly="readonly"
        />
      </template>
    </div>

    <v-dialog
      v-model="matchDialogIsVisible"
      max-width="600"
      scrim="transparent"
      outlined
    >
      <v-card
        border
        tile
      >
        <v-card-title>{{ $t('Confirm match') }}</v-card-title>
        <v-card-text>
          <div
            v-t="
              'Once you match the child, this opening will be reserved for this child. The provider will receive a notification to invite a family to enroll.'
            "
            class="fs-16 fw-500 mb-6"
          />
          <div
            v-if="selectedOpening"
            class="align-center b-1 bc-extra-light-gray d-flex my-6 pa-4"
          >
            <v-icon
              class="c-green me-2"
              size="24"
            >
              check_circle
            </v-icon>
            <span class="d-inline-block fs-16 fw-500">
              {{ selectedOpening.count - selectedOpening.reserved_count }}
              {{ $t('openings remaining') }}
            </span>
          </div>

          <div>
            <v-btn
              @click="confirmReservation()"
              :loading="processing"
              color="primary"
              size="x-large"
              block
            >
              Confirm match
            </v-btn>
            <v-btn
              @click="matchDialogIsVisible = false"
              size="x-large"
              variant="text"
              block
            >
              Cancel
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import AgeCalculations from '@/shared/mixins/age_calculations';
import Api from '@/shared/services/bright_finder';
import { formatAddress } from '@/shared/services/address';
import ProgramMatchSelector from '@/specialist/components/ProgramMatchSelector.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ProgramMatchSelector,
  },

  mixins: [AgeCalculations],

  props: {
    enrollmentRequest: {
      type: Object,
      default: null,
    },
    providerId: {
      type: String,
      default: null,
    },
    readonly: Boolean,
  },

  emits: ['reservation'],

  data() {
    return {
      enrollmentOpening: null,
      matchDialogIsVisible: null,
      openings: [],
      processing: false,
      programs: [],
      provider: null,
      selectedProgram: null,
      selectedOpening: null,
    };
  },

  created() {
    this.load();
  },

  methods: {
    confirmReservation() {
      this.processing = true;
      this.matchDialogIsVisible = false;
      this.$emit('reservation', [
        this.provider.id,
        this.selectedProgram.id,
        this.selectedOpening.id,
      ]);
    },

    load() {
      Api.public_api.provider.get(this.providerId, (resp) => {
        this.provider = resp.data;
        if (this.enrollmentRequest) {
          this.enrollmentOpening = resp.data.vacancies.find(
            (opening) => opening.id === this.enrollmentRequest.opening_id,
          );
        }
      });

      Api.public_api.organization.program.index({ provider_id: this.providerId }, (resp) => {
        this.programs = resp.data;
      });

      Api.provider.opening.index(this.providerId, (resp) => {
        this.openings = resp.data.filter((opening) => opening.reserved_count < opening.count);
      });
    },

    match() {
      this.processing = true;
      this.$emit('reservation', [this.provider.id]);
    },

    providerAddress() {
      if (!this.provider) return null;

      return formatAddress(this.provider.address1, this.provider.city, this.provider.zip);
    },

    reserve(newVal) {
      this.selectedProgram = newVal[0];
      this.selectedOpening = newVal[1];
      this.matchDialogIsVisible = true;
    },
  },
};
</script>
