<template>
  <div>
    <ResourceDialog
      @save="$emit('save', $event)"
      ref="editorDialog"
      :max-width="600"
      :processing="processing"
      title="More filters"
    >
      <template #form="{ localValue }">
        <v-row dense>
          <v-col>
            <DataItem
              v-for="(filter, index) in localValue"
              @remove="localValue.splice(index, 1)"
              :key="index"
              :header="getAliasNameForColumn(filter.key)"
              :name="getHeaderForFilter(filter)"
              block
            />

            <p
              v-if="localValue.length == 0"
              v-t="'No filters have been added yet.'"
              class="fs-14"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col class="d-flex">
            <v-btn
              @click="$refs.addDialog.open()"
              color="primary"
              variant="flat"
            >
              <span v-t="'Add'" />
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @save="handleAdd"
      ref="addDialog"
      title="Add filter"
      close-on-save
    >
      <template #form="{ localValue }">
        <LabeledSimpleSelect
          v-model="localValue.key"
          :item-title="(item) => `${item.data_type} - ${item.alias_name}`"
          :items="columns"
          item-value="key"
          message="Column"
        />
        <LabeledSimpleSelect
          v-model="localValue.operator"
          :items="operators"
          item-title="text"
          message="Operator"
          return-object
        />
        <template v-if="localValue.operator?.compareable">
          <LabeledTextfield
            v-model="localValue.value"
            message="Value"
          />
        </template>
      </template>
    </ResourceDialog>
  </div>
</template>

<script>
import DataItem from '@/shared/components/form/DataItem.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import Operators from '@/specialist/services/report-comparators';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    DataItem,
    LabeledSimpleSelect,
    LabeledTextfield,
    ResourceDialog,
  },

  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    processing: Boolean,
  },

  emits: ['save'],

  data() {
    return {
      operators: Object.values(Operators),
    };
  },

  methods: {
    getAliasNameForColumn(key) {
      return this.columns.find((column) => column.key === key)?.alias_name;
    },

    getHeaderForFilter(filter) {
      return [Operators[filter.operator].text, filter.value].join(' - ');
    },

    handleAdd(newVal) {
      const existingFilters = this.$refs.editorDialog.getLocalValue();
      const newFilter = { key: newVal.key, operator: newVal.operator.value, value: newVal.value };
      this.$refs.editorDialog.setLocalValue(existingFilters.concat([newFilter]));
    },

    open(existingValues) {
      this.$refs.editorDialog.open(existingValues);
    },

    close() {
      this.localValues = [];
      this.$refs.editorDialog.close();
    },
  },
};
</script>
