<template>
  <v-card
    id="agreement"
    class="mb-4"
    data-cy="agreements-card"
    elevation="0"
    border
    tile
  >
    <v-card-title class="tab-title">
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col
          cols="12"
          sm="8"
        >
          <span v-t="'Agreements'" />
        </v-col>
        <v-col
          class="d-flex align-center justify-end"
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="query"
            class="mxw-250 me-2"
            density="compact"
            prepend-inner-icon="search"
            variant="solo-filled"
            flat
            hide-details
            rounded
          />
          <v-btn
            @click="$refs.dialog.open({})"
            color="primary"
            variant="text"
            icon
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text class="mt-2 mb-4">
      <AgreementCard
        v-for="agreement in queriedCollection"
        @change="load"
        @destroy="load"
        @open="openAgreement(agreement)"
        :key="agreement.id"
        :agreement="agreement"
        :members="members"
      />

      <div
        v-if="collection.length == 0"
        class="fs-16"
      >
        No agreements created yet.
      </div>
    </v-card-text>

    <AgreementDialog
      @change="load"
      :api="api"
      role="organization"
    />

    <ResourceDialog
      @save="create"
      ref="dialog"
      :fields="[
        {
          text: 'Agreement type',
          value: 'schema_id',
          items: agreementSchemas,
          itemText: 'name',
          itemValue: 'id',
        },
        {
          text: 'Recipient',
          value: 'member_id',
          items: members,
          itemText: 'email',
          itemValue: 'id',
        },
      ]"
      :processing="processing"
      title="Add agreement"
    />
  </v-card>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import AgreementCard from '@/specialist/components/AgreementCard.vue';
import AgreementDialog from '@/shared/components/AgreementDialog.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    AgreementCard,
    AgreementDialog,
    ResourceDialog,
  },

  props: {
    api: {
      type: Object,
      default() {
        return Api;
      },
    },
    members: {
      type: Array,
      default: null,
    },
    providerId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      agreementSchemas: Object.values(this.$store.state.schemas).filter(
        (schema) => schema.data_type === 'Agreement',
      ),
      collection: [],
      query: null,
      processing: false,
    };
  },

  computed: {
    queriedCollection() {
      if (this.query) {
        return this.collection.filter((item) =>
          item.name.toLowerCase().includes(this.query.toLowerCase()),
        );
      }
      return this.collection;
    },
  },

  watch: {
    page() {
      this.load();
    },

    '$route.params.providerId': {
      immediate: true,
      handler() {
        this.load();
      },
    },
  },

  methods: {
    create(newResource) {
      this.processing = true;
      Api.organization.agreement.create(
        {
          ...newResource,
          owner_id: this.providerId,
          owner_type: 'Provider',
        },
        (resp) => {
          this.collection.push(resp.data);
          this.processing = false;
          this.$refs.dialog.close();
        },
        (err) => {
          this.processing = false;
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },

    load() {
      this.api.organization.agreement.index({ owner_id: this.providerId }, (resp) => {
        this.collection = resp.data;
      });
    },

    openAgreement(agreement) {
      this.$router.push({
        query: { ...this.$route.query, agreementId: agreement.id },
      });
    },
  },
};
</script>
