<template>
  <div>
    <ResourceDialog
      ref="dialog"
      :cancellable="false"
      :max-width="800"
      :processing="processing"
      save-button-text="Close"
      title="Edit seats"
      close-on-save
      closeable
      fullscreen
    >
      <template #form>
        <div
          v-for="seatType in seatTypes"
          :key="seatType.id"
          class="mb-8"
        >
          <v-row class="d-flex align-center">
            <v-col>
              <span class="fs-18 fw-600">{{ seatType.name }}</span>
            </v-col>
            <v-col class="d-flex justify-end">
              <span class="fs-18 fw-600"
                >{{ seats.filter((seat) => seat.seat_type_id == seatType.id).length }} total</span
              >
            </v-col>
          </v-row>

          <v-divider class="my-3" />

          <v-card
            v-for="seat in seats.filter((seat) => seat.seat_type_id == seatType.id)"
            :key="seat.id"
            border
            flat
            tile
          >
            <v-card-text class="py-2">
              <v-row dense>
                <v-col>
                  <div class="fs-14">
                    {{ seatTypes.find((st) => st.id == seat.seat_type_id).name }}
                  </div>
                  <div class="fs-12 c-light-black">#{{ seat.id }}</div>
                </v-col>

                <v-col class="d-flex justify-end">
                  <template v-if="seat.enrollment_id">
                    <v-btn
                      variant="text"
                      disabled
                      icon
                    >
                      <v-icon>radio_button_checked</v-icon>
                    </v-btn>
                  </template>

                  <template v-else>
                    <v-btn
                      @click="destroySeat(seat)"
                      :aria-label="$t('Remove seat')"
                      variant="text"
                      icon
                    >
                      <v-icon>close</v-icon>
                    </v-btn>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <template v-if="seats.filter((seat) => seat.seat_type_id == seatType.id).length == 0">
            <p class="fs-16">
              {{ $t('There are no assigned seats of this type.') }}
            </p>
          </template>
        </div>

        <v-row class="mt-4">
          <v-col>
            <v-btn
              @click="addSeat"
              color="primary"
            >
              <span v-t="'Add seat'" />
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @save="createSeat"
      ref="seatTypeSelectorDialog"
      :fields="[
        {
          text: 'Seat type',
          value: 'seat_type_id',
          items: seatTypes,
          itemText: 'name',
          itemValue: 'id',
        },
        {
          text: 'Number of seats to add',
          value: 'quantity',
        },
      ]"
      title="Select type"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import API from '@/shared/mixins/api';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ResourceDialog,
  },

  mixins: [API],

  props: {
    subsidyProgram: {
      type: Object,
      default: null,
    },
  },

  emits: ['change'],

  data() {
    return {
      processing: false,
      program: null,
      seats: [],
      seatTypes: [],
    };
  },

  methods: {
    addSeat() {
      if (this.seatTypes.length === 1) {
        this.createSeat({ seat_type_id: this.seatTypes[0].id });
      } else {
        this.$refs.seatTypeSelectorDialog.open();
      }
    },

    async createSeat(args) {
      const params = {
        subsidy_program_id: this.subsidyProgram.id,
        seat_type_id: args.seat_type_id,
        program_id: this.program.id,
        quantity: args.quantity,
      };
      this.processing = true;
      await this.api.organization.seat.create(params);
      await this.loadSeats();
      this.$refs.seatTypeSelectorDialog.close();
      this.processing = false;
      this.$emit('change');
    },

    async destroySeat(seat) {
      // eslint-disable-next-line no-alert
      if (!confirm('Are you sure you want to delete this seat?')) return;

      this.processing = true;
      await this.api.organization.seat
        .destroy(seat.id)
        .catch((error) => this.$eventBus.$emit('error', error));
      await this.loadSeats();
      this.processing = false;
      this.$emit('change');
    },

    async loadSeats() {
      const resp = await this.api.organization.seat.index(this.subsidyProgram.id, [
        this.program.id,
      ]);
      this.seats = _.orderBy(resp.data, [(seat) => seat.enrollment_id], ['asc']);
    },

    async loadSeatTypes() {
      const resp = await this.api.organization.subsidy_program.seat_type.index(
        this.subsidyProgram.id,
      );
      this.seatTypes = resp.data;
    },

    async open(program) {
      this.seats = [];
      this.seatTypes = [];

      if (!this.subsidyProgram) {
        this.$eventBus.$emit('You must select a valid program');
        return;
      }

      this.processing = true;
      this.program = program;
      this.$refs.dialog.open();

      await this.loadSeatTypes();
      await this.loadSeats();

      this.processing = false;
    },
  },
};
</script>
