<template>
  <ResourceDialog
    @save="create()"
    ref="dialog"
    :max-width="500"
    :save-button-disabled="disableCreate"
    save-button="Log activity"
    title="Log activity"
  >
    <template #form>
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="selectedGroupActivityType"
            :items="groupActivityTypeFilterItems"
            class="grey-placeholder"
            item-title="name"
            item-value="id"
            label="Activity type"
            variant="filled"
            hide-details
            return-object
          />
        </v-col>
        <v-col cols="6">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            min-width="290px"
          >
            <template #activator="{ props }">
              <v-text-field
                v-bind="props"
                v-model="formattedDateString"
                label="Date of activity"
                variant="filled"
                hide-details
                readonly
              />
            </template>
            <v-date-picker
              v-model="newGroupActivity.activity_date"
              @input="dateMenu = false"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="newGroupActivity.notes"
            class="grey-placeholder"
            placeholder="Notes"
            variant="filled"
            hide-details
          />
        </v-col>
      </v-row>
    </template>
  </ResourceDialog>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { useI18n } from 'vue-i18n';
import useEventBus from '@/shared/composables/useEventBus';

defineExpose({ open });

const eventBus = useEventBus();
const { locale } = useI18n();
const emit = defineEmits(['create']);

const props = defineProps({
  group: {
    type: Object,
    required: true,
  },
});

const dateMenu = ref(false);
const dialog = ref(null);
const newGroupActivity = ref({});
const processing = ref(false);
const selectedGroup = ref(null);
const selectedGroupActivityType = ref(null);
const groupActivityTypeFilterItems = ref([]);

const disableCreate = computed(() => {
  return processing.value || !selectedGroupActivityType.value || !selectedGroup.value;
});

const formattedDateString = computed(() => {
  if (!newGroupActivity.value.activity_date) return null;
  return new Date(newGroupActivity.value.activity_date).toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'utc',
  });
});

async function open() {
  selectedGroup.value = props.group;
  selectedGroupActivityType.value = null;
  newGroupActivity.value = {};
  dialog.value.open();

  const response = await api.organization.groupActivityType.index();
  groupActivityTypeFilterItems.value = response.data;
}

function create() {
  newGroupActivity.value.name = selectedGroupActivityType.value.name;
  processing.value = true;
  api.organization.group.group_activity.create(
    selectedGroup.value.id,
    newGroupActivity.value,
    (resp) => {
      dialog.value.close();
      processing.value = false;
      emit('create', resp.data);
      eventBus.chime('Activity successfully created');
    },
    (err) => {
      processing.value = false;
      eventBus.error(err);
    },
  );
}
</script>
