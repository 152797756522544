<template>
  <CaseReview
    :can-delete="true"
    :case-id="route.params.id"
    :case-repository="Api.organization.license"
    :case-type="caseType"
    :readonly="false"
  />
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import CaseReview from '@/specialist/components/CaseReview.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const caseType = ref('License');
</script>
