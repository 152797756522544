<template>
  <StandardCard
    @click:action:save="saveSchemaFunctionTest(schemaFunctionTest)"
    :actions="actions"
    :processing="processing"
    :title="schemaFunctionTest.name"
    class="mb-4"
    data-testid="schema-function-test"
  >
    <template #title-append>
      <div
        class="py-1"
        data-testid="schema-function-test-passing-icon"
      >
        <template v-if="schemaFunctionTest.passing">
          <v-icon
            aria-hidden="false"
            aria-label="Test is passing"
            color="success"
            role="img"
          >
            check_circle
          </v-icon>
        </template>
        <template v-else>
          <v-icon
            aria-hidden="false"
            aria-label="Test is failing"
            color="error"
            role="img"
          >
            error
          </v-icon>
        </template>
      </div>
      <ActionMenu
        @click:action:delete="handleDestroy"
        @click:action:edit-name="handleEditName"
        :items="editActions"
        button-icon="more_vert"
      />
    </template>
    <v-row>
      <LabeledFormulaField
        v-model="schemaFunctionTest.test_data"
        @update:model-value="handleChange"
        data-testid="schema-function-test-test-data"
        message="Test data"
      />

      <LabeledFormulaField
        v-model="schemaFunctionTest.test_mocks"
        @update:model-value="handleChange"
        data-testid="schema-function-test-test-mocks"
        message="Test mocks"
      />

      <LabeledFormulaField
        v-model="schemaFunctionTest.expected_value"
        @update:model-value="handleChange"
        cols="6"
        data-testid="schema-function-test-expected-value"
        message="Expected value"
      />

      <LabeledFormulaField
        v-model="schemaFunctionTest.last_actual_value"
        cols="6"
        data-testid="schema-function-test-last-actual-value"
        message="Last actual value"
        hard-lock
      />
    </v-row>

    <v-alert
      v-if="schemaFunctionTest.last_error"
      :text="schemaFunctionTest.last_error"
      class="mt-4"
      color="error"
      data-testid="schema-function-test-last-error"
      tile
    />

    <ResourceDialog
      @save="saveName"
      ref="editNameDialog"
      :fields="[{ value: 'name', text: 'Name', required: true }]"
      :processing="processing"
      title="Edit name"
    />

    <DecisionDialog
      @confirm="destroy"
      ref="destroyDecisionDialog"
      :processing="processing"
      confirm-button-text="Delete"
      content="Are you sure you want to delete this test?"
    />
  </StandardCard>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import DecisionDialog from '@/shared/components/DecisionDialog.vue';
import StandardCard from '@/shared/components/StandardCard.vue';
import LabeledFormulaField from '@/shared/components/form/LabeledFormulaField.vue';
import organizationApi from '@/specialist/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const editActions = [
  { event: 'edit-name', title: 'Edit name', icon: 'edit' },
  { event: 'delete', title: 'Delete', icon: 'delete' },
];

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['destroy']);

const changed = ref(false);
const destroyDecisionDialog = ref(null);
const editNameDialog = ref(null);
const schemaFunctionTest = ref(props.value);
const processing = ref(false);

const actions = computed(() => {
  if (!changed.value) return [];

  return [{ event: 'save', outlined: true, title: 'Save' }];
});

async function destroy() {
  processing.value = true;
  await organizationApi.organization.schema_function_test.destroy(schemaFunctionTest.value.id);
  processing.value = false;
  eventBus.chime('Deleted');
  emit('destroy');
}

function handleChange() {
  changed.value = true;
}

function handleEditName() {
  editNameDialog.value.open({ name: schemaFunctionTest.value.name });
}

function handleDestroy() {
  destroyDecisionDialog.value.open();
}

async function saveName(newVal) {
  processing.value = true;
  const resp = await organizationApi.organization.schema_function_test.update(
    schemaFunctionTest.value.id,
    { name: newVal.name },
  );
  processing.value = false;
  if (resp?.status != 200) return;
  schemaFunctionTest.value.name = newVal.name;
  editNameDialog.value.close();

  eventBus.chime('Saved');
}

async function saveSchemaFunctionTest(schemaFunctionTest) {
  let testData;
  let testMocks;
  let expectedValue;
  try {
    testData = JSON.stringify(JSON.parse(schemaFunctionTest.test_data));
  } catch (e) {
    eventBus.chime('Invalid JSON for test data: ' + e.message);
    return;
  }

  try {
    testMocks = JSON.stringify(JSON.parse(schemaFunctionTest.test_mocks));
  } catch (e) {
    eventBus.chime('Invalid JSON for test data: ' + e.message);
    return;
  }

  try {
    expectedValue = JSON.stringify(JSON.parse(schemaFunctionTest.expected_value));
  } catch (e) {
    eventBus.chime('Invalid JSON for expected value: ' + e.message);
    return;
  }

  processing.value = true;
  const resp = await organizationApi.organization.schema_function_test.update(
    schemaFunctionTest.id,
    {
      expected_value: expectedValue,
      test_data: testData,
      test_mocks: testMocks,
    },
  );
  processing.value = false;
  if (resp?.status != 200) return;

  changed.value = false;
  eventBus.chime('Saved');
}

watch(
  () => props.value,
  (value) => {
    schemaFunctionTest.value = value;
  },
  { deep: true },
);
</script>
