<template>
  <div fluid>
    <PageTitle
      title="New localized content template"
      flat
      outlined
    />

    <v-container
      v-if="organization"
      fluid
    >
      <v-card
        border
        flat
        tile
      >
        <v-card-text v-if="contentTemplate?.attributes?.communication_type === 'email'">
          <v-row>
            <LabeledTextfield
              v-model="localizedContentTemplate.subject"
              message="Subject"
              filled
              mandatory
            />
            <LabeledTextfield
              v-model="localizedContentTemplate.reply_to"
              message="Reply to"
              filled
              mandatory
            />
            <LabeledSimpleSelect
              v-model="localizedContentTemplate.locale"
              @change="handleLanguageSelection"
              :items="organization?.languages"
              class="mt-2"
              message="Language"
              chips
              deletable-chips
              mandatory
            />

            <LabeledControl message="Intro Text">
              <ToastEditor
                ref="introEditor"
                :initial-value="localizedContentTemplate.intro_text"
              />
            </LabeledControl>

            <LabeledControl message="Closing Text">
              <ToastEditor
                ref="closingEditor"
                :initial-value="localizedContentTemplate.closing_text"
              />
            </LabeledControl>
          </v-row>
        </v-card-text>

        <v-card-text v-if="contentTemplate?.attributes?.communication_type === 'text'">
          <v-row>
            <v-col>
              <LabeledTextfield
                v-model="localizedContentTemplate.subject"
                class="mt-2"
                message="Title"
                filled
                mandatory
              />
            </v-col>
            <v-col>
              <LabeledSimpleSelect
                v-model="localizedContentTemplate.locale"
                @change="handleLanguageSelection"
                :items="organization?.languages"
                class="mt-2"
                message="Language"
                chips
                deletable-chips
                mandatory
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <LabeledTextarea
                v-model="localizedContentTemplate.text_message_body"
                message="Text Message"
                mandatory
              />
              <span class="ml-5">{{ textBodyLength }} / 160 characters used</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider class="mb-4" />

        <v-card-actions>
          <v-btn
            @click="save"
            color="primary"
            size="x-large"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ToastEditor from '@/admin/components/ToastEditor.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledControl from '@/shared/components/form/LabeledControl.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();

const closingEditor = ref(null);
const contentTemplate = ref(null);
const contentTemplateId = ref(route.params.contentTemplateId);
const introEditor = ref(null);
const localizedContentTemplate = ref({
  subject: null,
  locale: null,
  reply_to: null,
  intro_text: null,
  closing_text: null,
  text_message_body: null,
});
const organization = ref(null);

onMounted(async () => {
  await loadOrganization();
  await loadContentTemplate();
});

const textBodyLength = computed(() => {
  return localizedContentTemplate.value.text_message_body?.length || 0;
});

function handleSetMarkdown() {
  localizedContentTemplate.value.intro_text = introEditor.value.getMarkdown();
  localizedContentTemplate.value.closing_text = closingEditor.value.getMarkdown();
}

async function loadOrganization() {
  await Api.organization.get((resp) => {
    organization.value = resp.data;
  });
}

async function loadContentTemplate() {
  const resp = await Api.organization.content_template.get(contentTemplateId.value);
  contentTemplate.value = resp.data.data;
}

function handleLanguageSelection(locale) {
  localizedContentTemplate.value.locale = locale;
}

async function save() {
  if (contentTemplate.value?.attributes?.communication_type === 'email') {
    handleSetMarkdown();
  }

  const resp = await Api.organization.localized_content_template.create(
    localizedContentTemplate.value,
    contentTemplateId.value,
  );
  if (resp?.status !== 201) return;

  router.push({
    name: 'LocalizedContentTemplateIndex',
    params: { contentTemplateId: contentTemplateId.value },
  });
  eventBus.chime('Localized content template created.');
}
</script>
