<template>
  <div v-if="schema">
    <PageTitle
      @create="load"
      :back-back-name="schema.name"
      :back-back-route="{
        name: 'SchemaShow',
        params: {
          schemaId: schema.id,
        },
      }"
      :back-route="{
        name: 'SchemaUploads',
        params: {
          schemaId: schema.id,
        },
      }"
      :title="upload.name"
      back-name="Syncs"
      create-icon="refresh"
      create-text="Refresh"
      createable
      outlined
    />

    <v-alert
      v-if="note"
      class="fs-16 fw-500 mb-0"
      color="primary"
      type="info"
      closable
      tile
    >
      <span>
        <strong> {{ $t('Note') }}: </strong>

        {{ $t(note) }}
      </span>
    </v-alert>

    <v-alert
      v-if="upload.last_upload_at"
      class="mb-0"
      type="success"
      closable
      tile
    >
      <span> {{ $t('Last run completed at') }}: </span>

      <LongDateTime :date="upload.last_upload_at" />

      <span class="mx-1">-</span>

      <span>
        {{ upload.count_added }} {{ $t('added') }} / {{ upload.count_updated }}
        {{ $t('updated') }} / {{ upload.count_error }} {{ $t('errors') }}
      </span>
    </v-alert>

    <v-alert
      v-if="upload.error_rows.length > 0"
      class="mb-0"
      type="error"
      closable
      tile
    >
      <ol>
        <li
          v-for="(error, index) in upload.error_rows"
          :key="index"
        >
          {{ error[0] }} ({{ error[1].join(',') }})
        </li>
      </ol>
    </v-alert>

    <v-container
      v-if="upload && schema"
      fluid
    >
      <v-card
        class="mb-4"
        border
        tile
      >
        <v-card-title>
          {{ $t('Settings') }}
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-row>
            <LabeledSwitch
              v-model="upload.enable_inserts"
              :processing="processing"
              subtitle="Allow upload to create new rows if existing matching rows are not found."
              title="Enable inserts"
            />
          </v-row>

          <v-row v-if="schema.data_type == 'Provider'">
            <LabeledSwitch
              v-model="upload.remove_missing_records_after_processing"
              :processing="processing"
              subtitle="Marks any records missing from upload source as inactive."
              title="Enable removal of missing records"
            />
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            @click="saveSettings"
            :loading="processing"
            color="primary"
            size="x-large"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
        class="mb-4"
        border
        tile
      >
        <v-card-title>
          {{ $t('Fields') }}
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-row class="fs-16 fw-600">
            <v-col>
              <span>{{ $t('Column') }}</span>
            </v-col>

            <v-col>
              <span>{{ $t('BridgeCare Field') }}</span>
            </v-col>
          </v-row>

          <v-row
            v-for="(header, index) in upload.headers"
            :key="index"
          >
            <v-col>
              <v-text-field
                :model-value="header"
                append-inner-icon="lock"
                variant="filled"
                hide-details
                readonly
              />
            </v-col>

            <v-col>
              <v-autocomplete
                v-model="upload.headers_map[header]"
                @update:model-value="changed = true"
                :items="propertyNames"
                variant="filled"
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            @click="saveHeadersMap"
            :disabled="!changed"
            :loading="processing"
            color="primary"
            size="x-large"
          >
            {{ $t('Save mapping') }}
          </v-btn>

          <v-spacer />

          <v-btn
            @click="run"
            :disabled="changed"
            :loading="processing"
            color="primary"
            size="x-large"
          >
            {{ $t('Process') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <template v-if="upload.remote_file_url">
        <v-card
          class="mb-4"
          border
          tile
        >
          <v-card-title>
            {{ $t('Remote file') }}
          </v-card-title>

          <v-divider />

          <v-card-text>
            <v-row>
              <LabeledTextfield
                v-model="upload.remote_file_url"
                message="URL"
              />
            </v-row>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-btn
              @click="saveRemoteFileUrl"
              :loading="processing"
              color="primary"
              size="x-large"
            >
              {{ $t('Save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>

      <template v-if="upload.sftp_file_name">
        <v-card
          class="mb-6"
          border
          tile
        >
          <v-card-title>
            {{ $t('SFTP') }}
          </v-card-title>

          <v-divider />

          <v-card-text>
            <v-row>
              <LabeledTextfield
                v-model="upload.sftp_file_name"
                message="File name"
              />

              <LabeledTextfield
                v-model="upload.sftp_host"
                message="Host"
              />

              <LabeledTextfield
                v-model="upload.sftp_username"
                message="Username"
                placeholder="******"
              />

              <LabeledTextfield
                v-model="upload.sftp_password"
                message="Password"
                placeholder="******"
              />
            </v-row>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-btn
              @click="saveSFTP"
              :loading="processing"
              color="primary"
              size="x-large"
            >
              {{ $t('Save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>

      <DangerBar
        @destroy="destroy"
        :processing="processing"
      />
    </v-container>
  </div>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/specialist/services/bright_finder';
import DangerBar from '@/shared/components/DangerBar.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';

const eventBus = useEventBus();
const router = useRouter();
const route = useRoute();

const changed = ref(false);
const processing = ref(false);
const schema = ref(null);
const upload = ref(null);

const note = computed(() => {
  let note = 'Requires 1 column that ';

  switch (schema.value.data_type) {
    case 'Child':
      note += 'maps to a Group and either a ID or custom identifier.';
      break;
    case 'Grant':
      note += 'maps to a business ID, either an ID or custom identifier, and a GrantProgamID.';
      break;
    case 'Group':
      note += 'is an ID or a custom identifier.';
      break;
    case 'Program':
      note += 'maps to either ProviderId or ID.';
      break;
    case 'Provider':
      note += 'matches to one of the following: ID, Source::*, or StateProviderId.';
      break;
    case 'Review':
      note += 'maps to a member ID, either an ID or custom identifier, and OwnerID/OwnerType.';
      break;
    default:
      note = null;
  }

  return note;
});

const propertyNames = computed(() => {
  if (!schema.value) return [];

  return ['Id'].concat(schema.value.reportable_fields).concat(schema.value.standardized_fields);
});

function destroy() {
  processing.value = true;

  Api.organization.upload.destroy(route.params.uploadId, () => {
    processing.value = false;
    router.push({ name: 'SchemaUploads', params: { schemaId: schema.value.id } });
  });
}

async function load() {
  processing.value = true;

  const response = await Api.organization.upload.get(route.params.uploadId);
  upload.value = response?.data;

  // If it's null it can't be used with v-model and properties can't be added to it
  if (upload.value.headers_map === null) upload.value.headers_map = {};

  await loadSchema(response?.data?.schema_id);
  if (Object.keys(upload.value.headers_map).length === 0) suggestHeaders();

  changed.value = false;
  processing.value = false;
}

async function loadSchema(id) {
  const response = await Api.organization.schema.get(id);
  schema.value = response?.data;
}

function saveHeadersMap() {
  processing.value = true;

  Api.organization.upload.update(
    upload.value.id,
    { headers_map: upload.value.headers_map },
    () => {
      changed.value = false;
      eventBus.chime('Saved');
      processing.value = false;
    },
    (error) => {
      eventBus.error(error);
      processing.value = false;
    },
  );
}

function saveRemoteFileUrl() {
  processing.value = true;

  const params = {
    remote_file_url: upload.value.remote_file_url,
  };
  Api.organization.upload.update(
    upload.value.id,
    params,
    () => {
      changed.value = false;
      eventBus.chime('Saved');
      processing.value = false;
    },
    (error) => {
      eventBus.error(error);
      processing.value = false;
    },
  );
}

function saveSettings() {
  processing.value = true;

  const params = {
    enable_inserts: upload.value.enable_inserts,
    remove_missing_records_after_processing: upload.value.remove_missing_records_after_processing,
  };
  Api.organization.upload.update(
    upload.value.id,
    params,
    () => {
      changed.value = false;
      eventBus.chime('Saved');
      processing.value = false;
    },
    (error) => {
      eventBus.error(error);
      processing.value = false;
    },
  );
}

function saveSFTP() {
  processing.value = true;

  const params = {
    sftp_file_name: upload.value.sftp_file_name,
    sftp_host: upload.value.sftp_host,
    sftp_username: upload.value.sftp_username,
    sftp_password: upload.value.sftp_password,
  };
  Api.organization.upload.update(
    upload.value.id,
    params,
    () => {
      changed.value = false;
      eventBus.chime('Saved');
      processing.value = false;
    },
    (error) => {
      eventBus.error(error);
      processing.value = false;
    },
  );
}

function suggestHeaders() {
  _.forEach(upload.value.headers, (header) => {
    const match = propertyNames.value.find((property) => property.text === header)?.value;

    if (match) upload.value.headers_map[header] = match;
  });

  if (Object.keys(upload.value.headers_map).length > 0) changed.value = true;
}

async function run() {
  processing.value = true;

  const response = await Api.organization.upload
    .run(upload.value.id)
    .catch((error) => eventBus.error(error));

  processing.value = false;
  if (!response) return;

  eventBus.chime('File is processing');
}

onMounted(load);
</script>
