<template>
  <v-card
    border
    tile
  >
    <v-card-title>
      <v-row class="d-flex align-center">
        <v-col>
          <span>{{ $t('Award') }}#{{ localValue.id.split('-')[0].toUpperCase() }}</span>
          <v-chip
            :color="localValue.submitted_at ? 'green' : 'grey-darken-1'"
            class="mb-2 ms-3"
            label
          >
            {{ localValue.submitted_at ? $t('Awarded') : $t('Draft') }}
          </v-chip>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            v-if="localValue.submitted_at && mode == 'edit'"
            @click="mode = 'summary'"
            :loading="processing"
            size="small"
            variant="outlined"
          >
            {{ $t('Cancel') }}
          </v-btn>

          <template v-if="!!localValue.submitted_at && mode != 'edit'">
            <v-btn
              @click="edit"
              :loading="processing"
              class="me-3"
              prepend-icon="edit"
              size="small"
              variant="outlined"
            >
              {{ $t('Edit') }}
            </v-btn>

            <v-btn
              v-if="mode == 'view'"
              @click="mode = 'summary'"
              size="small"
              variant="text"
            >
              {{ $t('Close') }}
            </v-btn>
            <v-btn
              v-else
              @click="view"
              :loading="processing"
              prepend-icon="visibility"
              size="small"
              variant="text"
            >
              {{ $t('View') }}
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </v-card-title>

    <div
      v-if="mode == 'summary'"
      class="pa-4"
    >
      <GrantAwardSummaryCard
        :award="localValue"
        :business="business"
        dense
      />
    </div>

    <v-card-text v-if="mode == 'edit' || mode == 'view'">
      <v-stepper-vertical
        v-model="step"
        :non-linear="mode == 'view'"
        flat
        hide-actions
      >
        <v-stepper-vertical-item
          :complete="step > 1"
          value="1"
        >
          <template #title>
            <div class="mb-2">
              {{ $t('Set award') }}
            </div>
            <div class="fs-14 c-light-black">
              {{ $t('Determine the award amount and parent co-pay.') }}
            </div>
            <v-card
              v-if="step > 1 || mode == 'view'"
              class="mt-4"
              border
              flat
              tile
            >
              <v-card-text>
                <v-row
                  class="d-flex align-center"
                  dense
                >
                  <LabeledCurrencyInput
                    v-model="localValue.total_award_amount"
                    @input="changed = true"
                    cols="12"
                    md="6"
                    message="Total award amount"
                    dense
                    hard-lock
                  />
                  <LabeledSimpleSelect
                    v-model="localValue.payments_count"
                    @input="changed = true"
                    :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                    cols="12"
                    md="6"
                    message="Number of payments"
                    dense
                    hard-lock
                  />
                </v-row>
              </v-card-text>
            </v-card>
          </template>

          <v-container>
            <v-row
              class="d-flex align-center"
              dense
            >
              <LabeledCurrencyInput
                v-model="localValue.total_award_amount"
                @input="changed = true"
                :hard-lock="mode == 'view'"
                cols="12"
                md="6"
                message="Total award amount"
              />
              <LabeledSimpleSelect
                v-model="localValue.payments_count"
                @input="changed = true"
                :hard-lock="mode == 'view'"
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                cols="12"
                md="6"
                message="Number of payments"
              />
            </v-row>

            <v-row
              v-if="mode == 'edit'"
              class="my-4"
            >
              <v-col class="d-flex justify-end">
                <v-btn
                  @click="continueToFunding()"
                  :disabled="!localValue.total_award_amount"
                  :loading="processing"
                  append-icon="east"
                  color="primary"
                >
                  {{ $t('Save and continue') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-vertical-item>

        <v-stepper-vertical-item
          :complete="step > 2"
          value="2"
        >
          <template #title>
            <v-row dense>
              <v-col
                cols="12"
                md="9"
              >
                <div class="mb-2">
                  {{ $t('Reserve funds') }}
                </div>
                <div class="fs-14 c-light-black">
                  {{ $t('Assign funding sources based on eligibility and fund priority.') }}
                </div>
              </v-col>
              <v-col
                v-if="localValue.reserved_at"
                class="d-flex justify-end"
                cols="12"
                md="3"
              >
                <v-chip color="primary">
                  <span class="me-1">{{ $t('Reserved') }}</span>
                  <LongDate :date="localValue.reserved_at" />
                </v-chip>
              </v-col>
            </v-row>

            <v-card
              v-if="step > 2 || mode == 'view'"
              class="mt-4"
              border
              flat
              tile
            >
              <v-card-text>
                <v-row class="fs-16">
                  <v-col>{{ $t('Source') }}</v-col>
                  <v-col>{{ $t('Available') }}</v-col>
                  <v-col>{{ $t('Amount') }}</v-col>
                  <v-col class="ta-right">
                    {{ $t('Balance') }}
                  </v-col>
                </v-row>

                <v-divider class="mt-2 mb-4" />

                <v-row
                  v-for="(fund, index) in eligibleFunds"
                  :key="fund.id"
                  class="d-flex align-center fs-16"
                >
                  <v-col>
                    <div class="fs-16 c-black">
                      <span class="me-1">{{ index + 1 }}.</span>
                      <span>{{ $t(fund.name) }}</span>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="fs-16 c-black">
                      <span class="me-1">{{ currency(fund.available_amount) }}</span>
                    </div>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model.number="fund.amount"
                      :append-icon="mode == 'view' ? 'lock' : null"
                      density="compact"
                      prefix="$"
                      variant="filled"
                      disabled
                      hide-details
                      readonly
                    />
                  </v-col>
                  <v-col class="ta-right">
                    {{ currency(getFundBalance(index)) }}
                  </v-col>
                </v-row>

                <v-divider class="my-4" />

                <v-row dense>
                  <v-col class="fs-16 fw-500">
                    {{ $t('Total award amount') }}
                  </v-col>
                  <v-col class="fs-16 ta-right">
                    {{ currency(localValue.total_award_amount) }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>

          <v-container>
            <p
              v-if="localValue.funds.length == 0"
              class="fs-16 my-4"
            >
              {{
                $t(
                  'No funding sources commmitted yet. Set an award amount and calculate funding to generate funding source commitments.',
                )
              }}
            </p>

            <v-row class="fs-16">
              <v-col>{{ $t('Source') }}</v-col>
              <v-col>{{ $t('Available') }}</v-col>
              <v-col>{{ $t('Amount') }}</v-col>
              <v-col class="ta-right">
                {{ $t('Balance') }}
              </v-col>
            </v-row>

            <v-divider class="mt-2 mb-4" />

            <v-row
              v-for="(fund, index) in eligibleFunds"
              :key="fund.id"
              class="d-flex align-center fs-16"
            >
              <v-col>
                <div class="fs-16 c-black">
                  <span class="me-1">{{ index + 1 }}.</span>
                  <span>{{ $t(fund.name) }}</span>
                </div>
              </v-col>
              <v-col>
                <div class="fs-16 c-black">
                  <span class="me-1">{{ currency(fund.available_amount) }}</span>
                </div>
              </v-col>
              <v-col>
                <v-text-field
                  v-model.number="fund.amount"
                  :append-icon="mode == 'view' ? 'lock' : null"
                  :disabled="mode == 'view'"
                  density="compact"
                  prefix="$"
                  variant="filled"
                  hide-details
                />
              </v-col>
              <v-col class="ta-right">
                {{ currency(getFundBalance(index)) }}
              </v-col>
            </v-row>

            <v-divider class="my-4" />

            <v-row dense>
              <v-col class="fs-16 fw-500">
                {{ $t('Total award amount') }}
              </v-col>
              <v-col class="fs-16 ta-right">
                {{ currency(localValue.total_award_amount) }}
              </v-col>
            </v-row>

            <v-row
              class="d-flex align-center"
              dense
            >
              <v-col class="fs-18 fw-600 underlined">
                {{ $t('Total funding amount') }}
              </v-col>
              <v-col class="d-flex align-center justify-end">
                <v-icon
                  v-if="fundingMatchesAward"
                  color="green"
                  icon="check_circle"
                  size="24"
                />
                <v-icon
                  v-else
                  color="red"
                  icon="error"
                  size="24"
                />

                <span class="ms-3 fw-600 underlined">{{ currency(totalFundingAmount) }}</span>
              </v-col>
            </v-row>

            <v-row
              v-if="mode == 'edit'"
              class="d-flex align-center my-4"
            >
              <v-col>
                <v-btn
                  @click="recalculateFunding()"
                  :loading="processing"
                  color="primary"
                  prepend-icon="refresh"
                >
                  {{ $t('Recalculate funding') }}
                </v-btn>
              </v-col>
              <v-col class="d-flex align-center justify-end">
                <v-btn
                  @click="back()"
                  :loading="processing"
                  class="me-3"
                  variant="outlined"
                >
                  {{ $t('Back') }}
                </v-btn>
                <v-btn
                  @click="continueToPayments()"
                  :disabled="!fundingMatchesAward"
                  :loading="processing"
                  append-icon="east"
                  color="primary"
                >
                  {{
                    localValue.reserved_at
                      ? $t('Update reservation and continue')
                      : $t('Reserve funds and continue')
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-vertical-item>

        <v-stepper-vertical-item
          :complete="step > 3"
          value="3"
        >
          <template #title>
            <div class="mb-2">
              {{ $t('Create payment schedules') }}
            </div>
            <div class="fs-14 c-light-black">
              {{ $t('Review and confirm payment schedule') }}
            </div>
            <v-card
              v-if="step > 3 || mode == 'view'"
              class="mt-4"
              border
              flat
              tile
            >
              <v-card-text>
                <p
                  v-if="localValue.funds.length == 0"
                  class="fs-16 my-4"
                >
                  {{
                    $t(
                      'No payment schedule has been created yet. Set an award amount to generate payment schedule.',
                    )
                  }}
                </p>

                <v-row class="d-flex align-center fs-16">
                  <v-col
                    class="ta-center"
                    cols="1"
                  >
                    #
                  </v-col>
                  <v-col cols="4">
                    {{ $t('Paid') }}
                  </v-col>
                  <v-col cols="4">
                    {{ $t('Funded') }}
                  </v-col>
                  <v-col
                    class="ta-right"
                    cols="3"
                  >
                    {{ $t('Balance') }}
                  </v-col>
                </v-row>

                <v-divider class="mt-2 mb-4" />

                <v-row
                  v-for="(payment, index) in localValue.scheduled_payments"
                  :key="index"
                  class="d-flex align-center fs-16"
                >
                  <v-col
                    class="ta-center"
                    cols="1"
                  >
                    {{ index + 1 }}
                  </v-col>
                  <v-col cols="4">
                    <labeled-date-picker
                      v-model="payment.date"
                      dense
                      readonly
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-row
                      dense
                      no-gutters
                    >
                      <v-text-field
                        v-model.number="payment.amount"
                        :append-icon="mode == 'view' ? 'lock' : null"
                        :disabled="mode == 'view'"
                        density="compact"
                        prefix="$"
                        variant="filled"
                        hide-details
                      />
                    </v-row>
                  </v-col>
                  <v-col
                    class="ta-right"
                    cols="3"
                  >
                    {{ currency(getBalance(index)) }}
                  </v-col>
                </v-row>

                <v-divider class="my-4" />

                <v-row dense>
                  <v-col class="fs-16 fw-500">
                    {{ $t('Total award amount') }}
                  </v-col>
                  <v-col class="fs-16 ta-right">
                    {{ currency(localValue.total_award_amount) }}
                  </v-col>
                </v-row>

                <v-row
                  class="d-flex align-center"
                  dense
                >
                  <v-col class="fs-18 fw-600 underlined">
                    {{ $t('Total award payments') }}
                  </v-col>
                  <v-col class="d-flex align-center justify-end">
                    <template v-if="paymentsMatchesAward">
                      <v-icon
                        color="green"
                        icon="check_circle"
                        size="24"
                      />
                    </template>
                    <template v-else>
                      <v-icon
                        color="red"
                        icon="error"
                        size="24"
                      />
                    </template>

                    <span class="ms-3 fw-600 underlined">
                      {{ currency(totalPaidAwardAmount) }}
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>

          <v-container>
            <p
              v-if="localValue.funds.length == 0"
              class="fs-16 my-4"
            >
              {{
                $t(
                  'No payment schedule has been created yet. Set an award amount to generate payment schedule.',
                )
              }}
            </p>

            <v-row class="d-flex align-center fs-16">
              <v-col
                class="ta-center"
                cols="1"
              >
                #
              </v-col>
              <v-col cols="4">
                {{ $t('Paid') }}
              </v-col>
              <v-col cols="4">
                {{ $t('Funded') }}
              </v-col>
              <v-col
                class="ta-right"
                cols="3"
              >
                {{ $t('Balance') }}
              </v-col>
            </v-row>

            <v-divider class="mt-2 mb-4" />

            <v-row
              v-for="(payment, index) in localValue.scheduled_payments"
              :key="index"
              class="d-flex align-center fs-16"
            >
              <v-col
                class="ta-center"
                cols="1"
              >
                {{ index + 1 }}
              </v-col>
              <v-col cols="4">
                <labeled-date-picker
                  v-model="payment.date"
                  dense
                />
              </v-col>
              <v-col cols="4">
                <v-row
                  dense
                  no-gutters
                >
                  <v-text-field
                    v-model.number="payment.amount"
                    :append-icon="mode == 'view' ? 'lock' : null"
                    :disabled="mode == 'view'"
                    density="compact"
                    prefix="$"
                    variant="filled"
                    hide-details
                  />
                </v-row>
              </v-col>
              <v-col
                class="ta-right"
                cols="3"
              >
                {{ currency(getBalance(index)) }}
              </v-col>
            </v-row>

            <v-divider class="my-4" />

            <v-row dense>
              <v-col class="fs-16 fw-500">
                {{ $t('Total award amount') }}
              </v-col>
              <v-col class="fs-16 ta-right">
                {{ currency(localValue.total_award_amount) }}
              </v-col>
            </v-row>

            <v-row
              class="d-flex align-center"
              dense
            >
              <v-col class="fs-18 fw-600 underlined">
                {{ $t('Total award payments') }}
              </v-col>
              <v-col class="d-flex align-center justify-end">
                <v-icon
                  v-if="paymentsMatchesAward"
                  color="green"
                  icon="check_circle"
                  size="24"
                />
                <v-icon
                  v-else
                  color="red"
                  icon="error"
                  size="24"
                />

                <span class="ms-3 fw-600 underlined">{{ currency(totalPaidAwardAmount) }}</span>
              </v-col>
            </v-row>

            <v-row v-if="mode == 'edit'">
              <v-col>
                <v-btn
                  @click="recalculatePayments()"
                  :loading="processing"
                  color="primary"
                  prepend-icon="refresh"
                >
                  {{ $t('Recalculate payments') }}
                </v-btn>
              </v-col>
              <v-col class="d-flex align-center justify-end">
                <v-btn
                  @click="back()"
                  :loading="processing"
                  class="me-3"
                  variant="outlined"
                >
                  {{ $t('Back') }}
                </v-btn>
                <v-btn
                  @click="continueToAgreements()"
                  :disabled="!paymentsMatchesAward"
                  :loading="processing"
                  append-icon="east"
                  color="primary"
                >
                  {{ $t('Save and continue') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-vertical-item>

        <v-stepper-vertical-item
          :complete="step > 4"
          value="4"
        >
          <template #title>
            <div class="mb-2">
              {{ $t('Agreements') }}
            </div>
            <div class="fs-14 c-light-black">
              {{ $t('Draft and send agreements to appropriate parties.') }}
            </div>

            <v-card
              v-if="step > 4 || mode == 'view'"
              class="mt-4"
              border
              flat
              tile
            >
              <v-card-text>
                <AgreementCard
                  v-for="agreement in agreements"
                  @change="loadAgreements"
                  @destroy="loadAgreements"
                  @open="$router.push({ query: { agreementId: agreement.id } })"
                  :key="agreement.id"
                  :agreement="agreement"
                  :locked="mode == 'view'"
                  :members="permittedMembers"
                  class="mb-3"
                />
              </v-card-text>
            </v-card>
          </template>

          <v-container>
            <p
              v-if="agreements.length == 0"
              class="fs-16 my-4"
            >
              {{ $t('No agreements assigned.') }}
            </p>

            <v-row class="mb-2">
              <v-col>
                <v-btn
                  @click="$refs.agreementDialog.open({})"
                  :disabled="mode == 'view' || !permittedMembers"
                  :loading="processing"
                  class="me-3"
                  color="primary"
                  prepend-icon="add"
                >
                  {{ $t('Add agreement') }}
                </v-btn>
                <v-btn
                  @click="loadAgreements"
                  :loading="processing"
                  color="primary"
                  prepend-icon="refresh"
                  variant="outlined"
                >
                  {{ $t('Refresh status') }}
                </v-btn>
              </v-col>
            </v-row>

            <AgreementCard
              v-for="agreement in agreements"
              @change="loadAgreements"
              @destroy="loadAgreements"
              @open="$router.push({ query: { agreementId: agreement.id } })"
              :key="agreement.id"
              :agreement="agreement"
              :locked="mode == 'view'"
              :members="permittedMembers"
              class="mb-3"
            />

            <ResourceDialog
              @save="createAgreement"
              ref="agreementDialog"
              :max-width="600"
              :processing="processing"
              title="Add agreement"
            >
              <template #form="{ localValue: localFormValue }">
                <v-row>
                  <LabeledSimpleSelect
                    v-model="localFormValue.schema_id"
                    :items="agreementSchemas"
                    item-title="name"
                    item-value="id"
                    message="Agreement type"
                  />
                </v-row>
                <v-row>
                  <v-col>
                    <v-row dense>
                      <v-col
                        class="labeled-input"
                        cols="12"
                      >
                        {{ $t('Recipient') }}
                      </v-col>
                      <v-col>
                        <v-autocomplete
                          v-model="localFormValue.member_id"
                          :items="permittedMembers"
                          data-cy="labeled-select"
                          item-title="name"
                          item-value="id"
                          variant="filled"
                          hide-details
                        >
                          <template #item="itemOptions">
                            <v-list-item v-bind="itemOptions.props">
                              <v-list-item-title>
                                {{ itemOptions.item.raw.name }}
                                <span
                                  v-if="itemOptions.item.raw.applicant"
                                  class="ms-1"
                                >
                                  ({{ $t('Applicant') }})
                                </span>
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
            </ResourceDialog>

            <v-row
              v-if="mode == 'edit'"
              class="mt-4"
            >
              <v-col class="ta-right">
                <v-btn
                  @click="back()"
                  :loading="processing"
                  class="me-3"
                  variant="outlined"
                >
                  {{ $t('Back') }}
                </v-btn>
                <v-btn
                  @click="continueToSummary()"
                  :loading="processing"
                  append-icon="east"
                  color="primary"
                >
                  {{ $t('Review') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-vertical-item>

        <v-stepper-step
          :complete="step > 5"
          value="5"
        >
          <template #title>
            <div class="mb-2">
              {{ $t('Finalize and submit') }}
            </div>
            <div class="fs-14 c-light-black">
              {{ $t('Review and confirm award amount and payment details') }}
            </div>

            <v-card
              v-if="step > 5 || mode == 'view'"
              class="mt-4"
              border
              flat
              tile
            >
              <v-card-text>
                <GrantAwardSummaryCard
                  :award="localValue"
                  :business="business"
                />
              </v-card-text>
            </v-card>
          </template>

          <v-container>
            <GrantAwardSummaryCard
              :award="localValue"
              :business="business"
            />

            <v-row class="d-flex align-center mt-4">
              <v-col class="ta-right">
                <v-btn
                  v-if="mode == 'view'"
                  @click="mode = 'summary'"
                  color="primary"
                >
                  {{ $t('Close') }}
                </v-btn>
                <template v-else>
                  <v-btn
                    @click="back()"
                    :loading="processing"
                    class="me-3"
                    variant="outlined"
                  >
                    {{ $t('Back') }}
                  </v-btn>
                  <v-btn
                    @click="submit()"
                    :loading="processing"
                    color="primary"
                  >
                    {{ $t('Finish and submit') }}
                  </v-btn>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-step>
      </v-stepper-vertical>
    </v-card-text>

    <v-card-actions>
      <v-btn
        @click="destroy"
        :loading="processing"
        class="me-3"
        color="red"
        prepend-icon="close"
        size="small"
        variant="text"
      >
        {{ $t('Cancel reservation') }}
      </v-btn>
    </v-card-actions>

    <AgreementDialog role="organization" />
    <ConfirmDialog ref="confirmDialog" />
  </v-card>
</template>

<script setup>
import { currency } from '@/plugins/filters';
import Api from '@/specialist/services/bright_finder';
import AgreementCard from '@/specialist/components/AgreementCard.vue';
import AgreementDialog from '@/shared/components/AgreementDialog.vue';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import GrantAwardSummaryCard from '@/specialist/components/grant/GrantAwardSummaryCard.vue';
import LabeledCurrencyInput from '@/shared/components/form/LabeledCurrencyInput.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';

const props = defineProps({
  api: {
    type: Object,
    default() {
      return Api;
    },
  },
  grantAward: {
    type: Object,
    default: null,
  },
  grantProgram: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['destroy']);
const eventBus = useEventBus();

const agreementDialog = ref(null);
const agreements = ref([]);
const business = ref(null);
const confirmDialog = ref(null);
const permittedMembers = ref(null);
const changed = ref(false);
const localValue = ref(JSON.parse(JSON.stringify(props.grantAward))); // TODO: Change to defineModel
const mode = ref(props.grantAward.submitted_at ? 'summary' : 'edit');
const processing = ref(false);
const schemas = ref([]);
const step = ref(props.grantAward.step);

const agreementSchemas = computed(() => {
  return schemas.value.filter(
    (schema) =>
      schema.data_type === 'Agreement' &&
      props.grantProgram.associated_schema_ids.includes(schema.id),
  );
});

const eligibleFunds = computed(() => localValue.value.funds.filter((fund) => fund.eligible));

const fundingMatchesAward = computed(
  () => parseFloat(totalFundingAmount.value) === parseFloat(localValue.value.total_award_amount),
);

const paymentsMatchesAward = computed(
  () => parseFloat(totalFundingAmount.value) === parseFloat(localValue.value.total_award_amount),
);

const totalFundingAmount = computed(() => {
  if (localValue.value.funds.length === 0) return 0;

  return Number(
    localValue.value.funds
      .map((item) => Number(item.amount))
      .reduce((amount, total) => amount + total, 0),
  ).toFixed(2);
});

const totalPaidAwardAmount = computed(() => {
  if (localValue.value.scheduled_payments.length === 0) return 0;

  return Number(
    localValue.value.scheduled_payments
      .map((item) => Number(item.amount))
      .reduce((amount, total) => amount + total, 0),
  ).toFixed(2);
});

onMounted(() => {
  loadAgreements();
  loadPermittedMembers();
  loadBusiness();
  loadSchemas();
});

function continueToAgreements() {
  localValue.value.funding_calculation_requested = false;
  localValue.value.funding_reservation_requested = false;
  localValue.value.payment_calculation_requested = false;
  saveAndContinue();
}

function continueToFunding() {
  localValue.value.funding_calculation_requested = true;
  localValue.value.funding_reservation_requested = false;
  localValue.value.payment_calculation_requested = false;
  saveAndContinue();
}

function continueToPayments() {
  localValue.value.funding_calculation_requested = false;
  localValue.value.funding_reservation_requested = true;
  localValue.value.payment_calculation_requested = true;
  saveAndContinue();
}

async function continueToSummary() {
  const agreementSigned =
    agreements.value.length > 0 && agreements.value.every((agreement) => !!agreement.submitted_at);

  if (
    agreementSigned ||
    (await confirmDialog.value.confirmWithText(
      'Are you sure you want to proceed without all agreements signed?',
    ))
  ) {
    saveAndContinue();
  }
}

function back() {
  step.value -= 1;
}

function createAgreement(newAg) {
  processing.value = true;
  props.api.organization.agreement.create(
    {
      ...newAg,
      owner_id: localValue.value.id,
      owner_type: 'GrantAward',
    },
    (resp) => {
      agreements.value.push(resp.data);
      processing.value = false;
      agreementDialog.value?.close();
    },
    (err) => {
      eventBus.error(err);
      processing.value = false;
    },
  );
}

async function destroy() {
  const confirm = await confirmDialog.value.confirmWithText(
    'Are you sure you want to delete this award? This cannot be undone.',
  );
  if (!confirm) return;

  processing.value = true;
  props.api.organization.grant_award.destroy(localValue.value.id, () => emit('destroy'));
}

function edit() {
  step.value = 1;
  mode.value = 'edit';
}

function getBalance(index) {
  const payments = localValue.value.scheduled_payments
    .slice(0, index + 1)
    .map((item) => Number(item.amount));
  const amountPaid = payments.reduce((amount, sum) => sum + amount, 0).toFixed(2);

  return localValue.value.total_award_amount - amountPaid;
}

function getFundBalance(index) {
  const funds = eligibleFunds.value.slice(0, index + 1).map((item) => Number(item.amount));
  const committedAmount = funds.reduce((amount, sum) => sum + amount, 0);
  return localValue.value.total_award_amount - committedAmount;
}

function loadAgreements() {
  processing.value = true;
  props.api.organization.agreement.index({ owner_id: localValue.value.id }, (resp) => {
    agreements.value = resp.data;
    processing.value = false;
  });
}

function loadBusiness() {
  props.api.organization.business.get(localValue.value.business_id, (resp) => {
    business.value = resp.data;
  });
}

function loadPermittedMembers() {
  props.api.organization.permission.index({ owner_id: localValue.value.grant_id }, (resp) => {
    permittedMembers.value = resp.data.map((permittedMember) => ({
      name: permittedMember.member_name,
      id: permittedMember.member_id,
      applicant: true,
    }));

    props.api.organization.member.index(
      {
        is_provider: true,
        business_id: localValue.value.business_id,
      },
      (memberResp) => {
        permittedMembers.value = permittedMembers.value.concat(
          memberResp.data.filter(
            (member) =>
              !permittedMembers.value.find((permittedMember) => permittedMember.id === member.id),
          ),
        );
      },
    );
  });
}

function loadSchemas() {
  props.api.public_api.organization.schema.index((resp) => {
    schemas.value = resp.data;
  });
}

function recalculateFunding() {
  localValue.value.funding_calculation_requested = true;
  save();
}

function recalculatePayments() {
  localValue.value.payments_calculation_requested = true;
  save();
}

function save(callback) {
  processing.value = true;
  const saveParams = {
    funding_calculation_requested: localValue.value.funding_calculation_requested,
    funding_reservation_requested: localValue.value.funding_reservation_requested,
    funds: localValue.value.funds,
    payment_calculation_requested: localValue.value.payment_calculation_requested,
    scheduled_payments: localValue.value.scheduled_payments,
    payments_count: localValue.value.payments_count,
    step: localValue.value.step,
    submit_requested: localValue.value.submit_requested,
    total_award_amount: localValue.value.total_award_amount,
  };

  props.api.organization.grant_award.update(
    localValue.value.id,
    saveParams,
    (resp) => {
      localValue.value = resp.data;
      localValue.value.funding_calculation_requested = false;
      localValue.value.funding_reservation_requested = false;
      localValue.value.award_calculation_requested = false;
      localValue.value.payment_calculation_requested = false;
      changed.value = false;
      processing.value = false;

      if (callback) callback();
    },
    (err) => {
      processing.value = false;
      eventBus.error(err);
    },
  );
}

function saveAndContinue() {
  localValue.value.step = step.value + 1;
  save(() => (step.value += 1));
}

function submit() {
  localValue.value.submit_requested = true;
  save(() => (mode.value = 'summary'));
}

function view() {
  step.value = 10;
  mode.value = 'view';
}
</script>
