<template>
  <v-container class="mxw-1200">
    <router-view />
  </v-container>
</template>

<script setup>
import { onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const routingItems = [
  {
    title: 'Settings',
    route: 'GrantProgramShow',
  },
  {
    title: 'Application',
    route: 'GrantProgramQuestions',
  },
  {
    title: 'Funding',
    route: 'GrantProgramEligibility',
  },
  {
    title: 'Stages',
    route: 'GrantProgramStages',
  },
  {
    title: 'Messaging',
    route: 'GrantProgramMessaging',
  },
];

onMounted(() => {
  store.commit('setNavigation', null);
  store.commit('setNavigation', [
    {
      icon: 'chevron_left',
      type: 'back',
      title: 'Back',
      route: 'GrantProgramIndex',
    },
    {
      type: 'section',
      items: routingItems.map((item) => ({
        ...item,
        params: { grantProgramId: route.params.grantProgramId },
      })),
    },
  ]);
});

onUnmounted(() => {
  store.commit('setNavigation', null);
});
</script>
