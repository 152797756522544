<template>
  <SchemaForm
    v-model="draft"
    @update:model-value="handleModelUpdated"
    :schema="statusFormSchema"
    class="mb-8"
    title="System statuses"
    flat
    outlined
  />

  <SaveBar
    @cancel="cancel"
    @save="save"
    :model-value="changed"
    :processing="processing"
  />
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import SchemaForm from '@/admin/components/schemas/SchemaForm.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const props = defineProps({ organization: { type: Object, default: () => ({}) } });

function getDraft() {
  const draftProps = JSON.parse(JSON.stringify(props.organization));
  return {
    excluded_provider_enrollment_statuses: draftProps.excluded_provider_enrollment_statuses,
    excluded_specialist_enrollment_statuses: draftProps.excluded_specialist_enrollment_statuses,
    attendance_statuses: draftProps.attendance_statuses,
    operating_statuses: draftProps.operating_statuses,
  };
}

function getStatusProperties(name, otherProps = null) {
  let props = {
    code: {
      cols: 4,
      title: 'Code',
      type: 'string',
    },
    color: {
      cols: 4,
      title: 'Color',
      type: 'string',
    },
    title: {
      cols: 4,
      title: 'Title',
      type: 'string',
    },
    ...otherProps,
  };

  return {
    type: 'array',
    title: name,
    items: {
      type: 'object',
      title: 'status',
      properties: props,
    },
  };
}

function cancel() {
  Object.assign(draft, getDraft());
  changed.value = false;
}

function save() {
  api.admin.organization.update(draft, () => {
    changed.value = false;
    eventBus.chime('Saved');
  });
}

function handleModelUpdated() {
  changed.value = true;
}

const statusFormSchema = {
  properties: {
    excluded_provider_enrollment_statuses: {
      type: 'array',
      title: 'Enrollment statuses excluded for providers',
      items: {
        type: 'string',
        title: 'status',
      },
    },
    excluded_specialist_enrollment_statuses: {
      type: 'array',
      title: 'Enrollment statuses excluded for specialists',
      items: {
        type: 'string',
        title: 'status',
      },
    },
    attendance_statuses: getStatusProperties('Attendance statuses', {
      absent: { type: 'boolean', title: 'Absent' },
    }),
    operating_statuses: getStatusProperties('Operating statuses', {
      closed: { type: 'boolean', title: 'Closed' },
    }),
  },
};

const processing = ref(false);
const changed = ref(false);
const draft = reactive(getDraft());
</script>
