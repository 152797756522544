<template>
  <ResourceDialog
    @save="saveReportFolder"
    ref="dialog"
    :processing="processing"
    :title="$t('Move Report to Report Folder')"
  >
    <template #form>
      <LabeledAutocomplete
        v-model="currentFolderId"
        :items="reportFolderItems"
        :loading="processing"
        item-title="name"
        item-value="id"
        placeholder="Type the name of a folder here"
      />
    </template>
  </ResourceDialog>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    LabeledAutocomplete,
    ResourceDialog,
  },

  props: {
    afterSave: {
      type: Function,
      default: () => {},
    },
    processing: {
      type: Boolean,
      default: true,
    },
    reportFolderItems: {
      type: Array,
      default: () => [],
    },
    selectedReport: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      currentFolderId: null,
      currentReport: null,
      internalProcessing: false,
    };
  },

  methods: {
    async saveReportFolder() {
      const resp = await Api.organization.report.update(this.currentReport.id, {
        report_folder_id: this.currentFolderId,
      });

      if (resp.status === 200) {
        this.$eventBus.$emit('chime', 'Report was moved');
        this.afterSave();
        this.close();
      } else {
        this.close();
      }
    },

    async open(report) {
      this.currentFolderId = null;
      this.currentReport = report;
      this.$refs.dialog.open(report);
    },

    close() {
      this.$refs.dialog?.close();
    },

    setFolder(folderId) {
      this.currentFolderId = folderId;
    },
  },
};
</script>
