<template>
  <router-view
    @refresh="load()"
    :site_template="site_template"
  />
</template>

<script>
import Api from '@/admin/services/bright_finder';

export default {
  compatConfig: { MODE: 2 },

  data() {
    return {
      site_template: null,
    };
  },

  watch: {
    // eslint-disable-next-line func-names
    '$route.params': function () {
      this.load();
    },
  },
  beforeUnmount() {
    this.$store.commit('setNavigation', null);
  },

  created() {
    this.load();
  },

  methods: {
    load() {
      Api.organization.siteTemplate.get(this.$route.params.siteTemplateId, (resp) => {
        this.site_template = resp.data;
        this.setNavigation();
      });
    },

    pageNavigationItems() {
      const pages = this.site_template.page_templates.map((page) => ({
        title: page.name,
        route: 'PageTemplateShow',
        params: { siteTemplateId: page.site_template_id, pageTemplateId: page.id },
      }));
      pages.push({
        title: 'Add Page',
        icon: 'add',
        route: 'PageTemplateNew',
        params: {
          siteTemplateId: this.site_template.id,
        },
      });
      return pages;
    },

    setNavigation() {
      this.$store.commit('setNavigation', [
        {
          icon: 'chevron_left',
          type: 'back',
          title: 'Back',
          route: 'SiteTemplateIndex',
        },
        {
          type: 'section',
          items: [
            {
              title: 'Settings',
              route: 'SiteTemplateShow',
              params: {
                siteTemplateId: this.site_template.id,
              },
            },
          ],
        },
        {
          type: 'section',
          header: 'Pages',
          items: this.pageNavigationItems(),
        },
      ]);
    },
  },
};
</script>
