<template>
  <v-container
    v-if="collection"
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <template v-if="collection.length > 0">
      <div class="mt-3 mx-3">
        <v-card
          class="bt-1 br-1 bl-1 bc-outlined-gray"
          flat
          tile
        >
          <v-card-text class="fs-15 py-3">
            <v-row dense>
              <v-col class="d-flex align-center">
                <p class="mb-0 py-1">
                  {{ $t('Displaying') }} <strong>{{ collection.length }}</strong>
                  {{ $t('results') }}, {{ $t('out of') }} <strong>{{ total }}</strong>
                  {{ $t('total results') }}.
                </p>
              </v-col>

              <v-btn
                @click="$refs.newCourseDialog.open()"
                color="primary"
                prepend-icon="add"
              >
                {{ $t('New course') }}
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

      <v-data-table-server
        @click:row="view"
        @update:page="handlePageChange"
        :footer-props="{
          showCurrentPage: true,
        }"
        :headers="tableHeaders"
        :hide-default-footer="pages === 0"
        :items="collection"
        :items-length="total"
        :items-per-page="pageSize"
        :page="filters.page"
        class="mx-3 b-radius-0 b-1 bc-outlined-gray fixed-height"
        item-key="id"
        disable-sort
        fixed-header
        hover
      >
        <template #item.published="{ item }">
          <v-chip
            :color="item.published ? 'light-green-lighten-3' : 'light-grey-lighten-3'"
            size="small"
          >
            {{ $t(item.published ? 'Published' : 'Draft') }}
          </v-chip>
        </template>

        <template #item.access="{ item }">
          <v-chip
            :color="item.restricted ? 'amber-lighten-3' : 'primary-lighten-3'"
            size="small"
          >
            {{ $t(item.restricted ? 'Cohorts' : 'Public') }}
          </v-chip>
        </template>

        <template #item.actions="{ item }">
          <div class="d-flex justify-end align-center">
            <ActionMenu
              @click:action:delete="destroy(item)"
              @click:action:view="view(null, { item })"
              :items="courseActionItems"
              button-icon="more_vert"
            />
          </div>
        </template>
      </v-data-table-server>
    </template>

    <NullState
      v-if="collection.length === 0"
      @new="$refs.newCourseDialog.open({})"
      new-button-title="New course"
    />

    <ResourceDialog
      @save="createNewCourse"
      ref="newCourseDialog"
      :fields="[{ text: 'Name', value: 'name' }]"
      :processing="processing"
      save-button-text="Create"
      title="Create new course"
    />
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import RouterHelper from '@/shared/mixins/RouterHelper';

const DEFAULT_PAGE_SIZE = 25;

export default {
  compatConfig: { MODE: 3 },

  components: {
    NullState,
    ResourceDialog,
  },

  mixins: [API, RouterHelper],

  data() {
    return {
      collection: null,
      courseActionItems: [
        { title: 'View', event: 'view' },
        { title: 'Delete', event: 'delete' },
      ],
      filters: this.getFiltersFromQuery({ paged: true }),
      pages: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      processing: false,
      tableHeaders: [
        {
          title: 'Name',
          value: 'name',
        },
        {
          title: 'Status',
          value: 'published',
          align: 'center',
        },
        {
          title: 'Access',
          value: 'access',
          align: 'center',
        },
        {
          title: 'Author',
          value: 'creator_name',
        },
        {
          title: 'Credits',
          value: 'credits',
          align: 'center',
        },
        {
          title: null,
          value: 'actions',
        },
      ],
      total: 0,
    };
  },

  created() {
    this.load();
  },

  methods: {
    async createNewCourse(newCourse) {
      this.processing = true;
      const params = {
        name: newCourse.name,
      };
      const response = await this.api.organization.course.create(params).catch((error) => {
        this.processing = false;
        this.$eventBus.$emit('chime', error.response.data.errors[0]);
      });

      this.$router.push({
        name: 'CourseShow',
        params: {
          courseId: response.data.id,
        },
      });
    },

    async handlePageChange(newValue) {
      if (newValue) this.filters.page = newValue;
      await this.updateQuery({ ...this.filters });
      this.load();
    },

    view(event, { item: course }) {
      this.$router.push({
        name: 'CourseShow',
        params: {
          courseId: course.id,
        },
      });
    },

    async destroy(course) {
      // eslint-disable-next-line no-alert
      if (confirm('Are you sure you want to delete this course? This cannot be undone.')) {
        await this.api.organization.course
          .destroy(course.id)
          .catch((error) => this.$eventBus.$emit('error', error.response.data.errors[0]));
        this.$eventBus.$emit('chime', 'Saved');
        this.load();
      }
    },

    async load() {
      this.processing = true;

      const response = await this.api.organization.course.index(this.filters);

      this.pages = parseInt(response.headers['x-page-count'] || 0, 10);
      this.total = parseInt(response.headers['x-total-count'] || 0, 10);
      this.pageSize = parseInt(response.headers['x-page-size'] || 0, 10);
      this.collection = response.data;
      this.processing = false;
    },
  },
};
</script>
