<template>
  <div>
    <ResourceDialog
      @close="close"
      @save="$emit('save', assignmentCompletion)"
      ref="commentsGraderDialog"
      :max-width="800"
      :title="title"
      cancellable
      close-on-save
      closeable
    >
      <template #form>
        <CommentsCard
          :assignment="assignment"
          :border="false"
          :comment-type="activityType"
          :learner="learner"
          :model-id="assignment.id"
          :titled="false"
          card-text-class="pa-0"
          model-type="assignment"
          readonly
          tile
        />

        <v-row
          v-if="assignmentCompletion"
          dense
        >
          <v-radio-group
            v-model="gradeModel"
            inline
          >
            <v-radio
              :label="$t('Ungraded')"
              :value="null"
              class="c-black mt-0 mb-1"
              color="primary"
            />
            <v-radio
              :label="$t('Pass')"
              :value="true"
              class="c-black mt-0 mb-1"
              color="primary"
            />
            <v-radio
              :label="$t('Fail')"
              :value="false"
              class="c-black mt-0 mb-1"
              color="primary"
            />
          </v-radio-group>
        </v-row>
      </template>
    </ResourceDialog>
  </div>
</template>

<script setup>
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import CommentsCard from '@/shared/components/learning/CommentsCard.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps({
  assignment: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['cancel', 'close', 'save']);

defineExpose({ open });

const assignmentCompletion = ref(null);
const activityType = ref(null);
const learner = ref(null);
const commentsGraderDialog = ref(null);

const gradeModel = computed({
  get: () => {
    switch (activityType.value) {
      case 'comment':
        return assignmentCompletion.value.required_comment_passed;
      case 'reply':
        return assignmentCompletion.value.required_comment_reply_passed;
      default:
        return {};
    }
  },

  set(value) {
    switch (activityType.value) {
      case 'comment':
        assignmentCompletion.value.required_comment_passed = value;
        break;
      case 'reply':
        assignmentCompletion.value.required_comment_reply_passed = value;
        break;
      default:
        break;
    }
  },
});

const title = computed(() => {
  let activityTitle;

  switch (activityType.value) {
    case 'comment':
      activityTitle = 'Comments';
      break;
    case 'reply':
      activityTitle = 'Replies';
      break;
    default:
      break;
  }

  return `${t(activityTitle)}: ${learner.value?.attributes?.name}`;
});

function close() {
  return emit('cancel');
}

async function open(currentLearner, currentAssignmentCompletion, currentActivityType) {
  learner.value = currentLearner;
  assignmentCompletion.value = currentAssignmentCompletion;
  activityType.value = currentActivityType;

  commentsGraderDialog.value.open();
}
</script>
