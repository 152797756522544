<template>
  <div v-if="schema && assignmentConfigurations">
    <BreadcrumbTitle
      :back-name="schema.name"
      name="Assignment configurations"
    />

    <v-divider class="my-8" />

    <v-card
      v-for="assignmentConfiguration in assignmentConfigurations"
      :key="assignmentConfiguration.id"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row class="d-flex align-center">
          <v-col>
            <span v-text="assignmentConfiguration.name" />
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn
              @click="edit(assignmentConfiguration)"
              class="me-3"
              color="primary"
              variant="flat"
            >
              Edit
            </v-btn>
            <v-btn
              @click="remove(assignmentConfiguration)"
              color="red"
              variant="outlined"
            >
              Delete
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <template v-if="assignmentConfigurations.length == 0">
      <NullState
        @new="draft"
        new-button-title="New"
      />
    </template>

    <template v-else>
      <v-btn
        @click="draft"
        class="mt-4"
        color="primary"
      >
        <span v-t="'New'" />
      </v-btn>
    </template>

    <ResourceDialog
      @save="save"
      ref="dialog"
      :fields="fields"
      :processing="processing"
      save-button-text="Save"
      title="New assignment configuration"
    >
      <template #append="{ localValue }">
        <h5
          v-t="'Conditions'"
          class="my-3"
        />
        <v-row
          v-for="(condition, index) in localValue.conditions"
          :key="index"
        >
          <LabeledAutocomplete
            v-model="condition.field"
            :items="fieldItems"
            :message="`Field ${index + 1}`"
          />
          <LabeledTextfield
            v-model="condition.value"
            :message="`Value ${index + 1}`"
          />
        </v-row>
      </template>
    </ResourceDialog>
  </div>
</template>

<script>
import API from '@/shared/mixins/api';
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    BreadcrumbTitle,
    LabeledAutocomplete,
    LabeledTextfield,
    NullState,
    ResourceDialog,
  },

  mixins: [API],

  data() {
    return {
      changed: false,
      processing: false,
      fieldItems: [],
      fields: [],
      assignmentConfigurations: null,
      schema: null,
      teams: [],
    };
  },

  created() {
    this.loadSchema();
    this.loadTeams();
    this.loadAssignmentConfigurations();
  },

  methods: {
    async draft() {
      await this.loadTeams();
      this.fields = this.getFields();
      this.saveMethod = this.create;
      this.$refs.dialog.open(
        {
          conditions: [{ field: null, value: null }],
          owner_id: this.$route.params.schemaId,
          owner_type: 'Schema',
          target_type: 'Team',
        },
        'New assignment configuration',
      );
    },

    async edit(assignmentConfiguration) {
      await this.loadTeams();
      this.fields = this.getFields();
      this.saveMethod = this.update;
      this.$refs.dialog.open(assignmentConfiguration, 'Edit assignment configuration');
    },

    getFields() {
      return [
        { text: 'Name', value: 'name', required: true },
        {
          text: 'Team',
          value: 'target_id',
          items: this.teams,
          itemText: 'name',
          itemValue: 'id',
          required: true,
        },
      ];
    },

    async loadAssignmentConfigurations() {
      const resp = await this.api.organization.assignment_configuration.index({
        owner_id: this.$route.params.schemaId,
      });
      this.assignmentConfigurations = resp.data;
    },

    async loadSchema() {
      const resp = await this.api.organization.schema.get(this.$route.params.schemaId);
      this.schema = resp.data;
      this.fieldItems = Object.keys(this.schema.definition.properties.custom.properties).map(
        (key) => `custom.${key}`,
      );
    },

    async loadTeams() {
      const resp = await this.api.organization.team.index();
      this.teams = resp.data;
    },

    async remove(assignmentConfiguration) {
      // eslint-disable-next-line no-alert
      if (!confirm('Are you sure you want to remove this assignment configuration type?')) return;
      this.processing = true;
      await this.api.organization.assignment_configuration.destroy(assignmentConfiguration);
      await this.loadAssignmentConfigurations();
      this.processing = false;
    },

    async save(newVal) {
      this.processing = true;
      let resp;
      if (newVal.id) {
        resp = await this.api.organization.assignment_configuration.update(newVal);
      } else {
        resp = await this.api.organization.assignment_configuration.create(newVal);
      }
      this.processing = false;
      if (!resp?.data) return;

      this.$refs.dialog.close();
      await this.loadAssignmentConfigurations();
    },
  },
};
</script>
