<template>
  <v-dialog
    v-model="createDialogIsVisible"
    max-width="768"
  >
    <v-card
      border
      tile
    >
      <v-card-title>
        {{ $t('Create new task') }}
      </v-card-title>

      <v-divider class="mb-6" />

      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-autocomplete
              v-model="selectedTaskType"
              :items="taskTypeFilterItems"
              class="grey-placeholder"
              data-cy="task_type"
              item-title="name"
              item-value="id"
              placeholder="Task type"
              variant="filled"
              hide-details
              return-object
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-autocomplete
              v-model="selectedOwner"
              v-model:search="specialistQuery"
              @update:search="specialistSearch"
              :items="specialistFilterItems"
              class="grey-placeholder"
              data-cy="assign_task_to"
              item-title="name"
              item-value="id"
              placeholder="Assign to"
              variant="filled"
              hide-details
              hide-no-data
              return-object
            />
          </v-col>
        </v-row>

        <v-row cols="6">
          <LabeledDatePicker
            v-model="newTask.due_date"
            :min="minimumDueDate"
            cols="6"
            placeholder-text="Due date (if applicable)"
          />
        </v-row>

        <v-row
          v-if="store.state.profile.org_providers_access || store.state.profile.org_groups_access"
        >
          <template v-if="store.state.profile.org_providers_access">
            <v-col
              v-if="provider"
              cols="6"
            >
              <v-select
                :items="[provider]"
                :model-value="provider"
                class="grey-placeholder"
                item-title="name"
                item-value="id"
                variant="filled"
                hide-details
                readonly
              />
            </v-col>

            <v-col
              v-else
              cols="6"
            >
              <v-autocomplete
                v-model="selectedProvider"
                v-model:search="providerQuery"
                @update:search="providerSearch"
                :items="providerFilterItems"
                class="grey-placeholder"
                data-cy="related_provider"
                item-title="name"
                item-value="id"
                placeholder="Related provider (if applicable)"
                variant="filled"
                hide-details
                hide-no-data
                return-object
              />
            </v-col>
          </template>

          <template v-if="store.state.profile.org_groups_access">
            <v-col
              v-if="group"
              cols="6"
            >
              <v-select
                :items="[group]"
                :model-value="group"
                class="grey-placeholder"
                item-title="name"
                item-value="id"
                variant="filled"
                hide-details
                readonly
              />
            </v-col>

            <v-col
              v-else
              cols="6"
            >
              <v-autocomplete
                v-model="selectedGroup"
                v-model:search="groupQuery"
                @update:search="groupSearch"
                :items="groupFilterItems"
                :placeholder="$t(`Related ${terms.group.toLowerCase()} (if applicable)`)"
                class="grey-placeholder"
                data-cy="related_family"
                item-title="name"
                item-value="id"
                variant="filled"
                hide-details
                hide-no-data
                return-object
              />
            </v-col>
          </template>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="newTask.description"
              class="grey-placeholder"
              data-cy="task_notes"
              placeholder="Notes"
              variant="filled"
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider class="mb-6" />

      <v-card-actions>
        <v-btn
          @click="createDialogIsVisible = false"
          size="x-large"
          variant="outlined"
        >
          {{ $t('Cancel') }}
        </v-btn>

        <v-spacer />

        <v-btn
          @click="create()"
          :disabled="disableCreate"
          color="primary"
          data-cy="create_task"
          size="x-large"
        >
          {{ $t('Create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import useTerms from '@/shared/composables/useTerms';
import { useStore } from 'vuex';

const store = useStore();
const { terms } = useTerms();

const props = defineProps({
  group: {
    default: null,
    type: Object,
  },
  minimumDueDate: {
    default: null,
    type: String,
  },
  provider: {
    default: null,
    type: Object,
  },
});

const specialistFilterItems = ref([]);
const specialistQuery = ref('');
const createDialogIsVisible = ref(false);
const groupFilterItems = ref([]);
const groupQuery = ref(null);
const newTask = ref({});
const processing = ref(false);
const providerFilterItems = ref([]);
const providerQuery = ref('');
const selectedGroup = ref(null);
const selectedOwner = ref(null);
const selectedProvider = ref(null);
const selectedTaskType = ref(null);
const taskTypeFilterItems = ref([]);

defineExpose({
  open,
});

const emit = defineEmits(['change']);

const disableCreate = computed(() => {
  return processing.value || !selectedTaskType.value;
});

async function create() {
  if (selectedGroup.value) newTask.value.group_id = selectedGroup.value.id;
  if (selectedOwner.value) newTask.value.owner_id = selectedOwner.value.id;
  if (selectedProvider.value) newTask.value.provider_id = selectedProvider.value.id;

  newTask.value.task_type_id = selectedTaskType.value.id;
  newTask.value.title = selectedTaskType.value.name;
  newTask.value.subtitle = selectedGroup.value?.name || selectedProvider.value?.name;
  newTask.value.status = 'open';
  processing.value = true;

  await Api.organization.task.create(newTask.value);
  createDialogIsVisible.value = false;

  processing.value = false;

  emit('change');
}

function groupSearch(newValue) {
  if (!newValue || newValue === '') {
    specialistFilterItems.value = [];
  } else {
    loadGroups();
  }
}

function loadGroups() {
  Api.organization.group.index({ query: groupQuery.value }, (response) => {
    groupFilterItems.value = response.data;
  });
}

function loadProviders() {
  Api.public_api.provider.index({ query: providerQuery.value }, (response) => {
    providerFilterItems.value = response.data;
  });
}

function loadSpecialists() {
  Api.organization.member.index(
    { is_specialist: true, query: specialistQuery.value },
    (response) => {
      specialistFilterItems.value = response.data;
    },
  );
}

async function open() {
  selectedGroup.value = props.group;
  selectedProvider.value = props.provider;
  selectedOwner.value = null;
  selectedTaskType.value = null;
  newTask.value = {};
  createDialogIsVisible.value = true;

  const response = await Api.organization.taskType.index();
  taskTypeFilterItems.value = response?.data;

  Api.organization.member.index({ is_specialist: true }, (response) => {
    specialistFilterItems.value = response.data;
  });
}

function providerSearch(newValue) {
  if (!newValue || newValue === '') {
    providerFilterItems.value = [];
  } else {
    loadProviders();
  }
}

function specialistSearch(newValue) {
  if (!newValue || newValue === '') {
    specialistFilterItems.value = [];
  } else {
    loadSpecialists();
  }
}
</script>
