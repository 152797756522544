<template>
  <div>
    <v-container
      class="px-0 py-0 bg-super-light-blue"
      fluid
    >
      <div class="mx-3">
        <v-card
          border
          tile
        >
          <v-card-title class="d-flex align-center">
            <span class="flex-1-0">
              {{ $t('Overview') }}
            </span>

            <TitleBarToggleButton
              @toggle="togglePublished"
              :enabled="draft.published"
              disabled-text="Unpublished"
              enabled-text="Published"
            />
          </v-card-title>

          <v-divider />

          <v-card-text>
            <v-row>
              <v-col>
                <LabeledTextfield
                  v-model="draft.name"
                  @update:model-value="changed = true"
                  message="Name"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <LabeledTextfield
                  v-model.number="draft.credits"
                  @update:model-value="changed = true"
                  message="Credits"
                />
              </v-col>

              <v-col cols="6">
                <v-row dense>
                  <v-col>
                    <span>
                      {{ $t('Access') }}
                    </span>
                  </v-col>

                  <LabeledSwitch
                    v-model="draft.restricted"
                    @update:model-value="changed = true"
                    :false-value="0"
                    :subtitle="cohortsSummary()"
                    :true-value="1"
                    title="Restrict this course to only learners within a cohort"
                    title-classes="fs-16 c-light-black mt-1 text-pre-wrap"
                  />
                </v-row>
              </v-col>
            </v-row>

            <v-row class="d-flex align-stretch">
              <v-col
                @click="$refs.assetFinder.open()"
                class="d-flex align-stretch justify-center"
                cols="4"
              >
                <div
                  class="pa-8 bg-super-light-blue d-flex align-center w-100pc justify-center b-dashed b-3 bc-primary pointer"
                >
                  <template v-if="draft.image_url">
                    <v-img
                      :src="draft.image_url"
                      max-height="300"
                    />
                  </template>

                  <template v-else>
                    <v-icon
                      icon="add_a_photo"
                      size="40"
                    />
                  </template>
                </div>
              </v-col>

              <v-col cols="8">
                <v-row>
                  <LabeledTextarea
                    v-model="draft.description"
                    @input="changed = true"
                    message="Description"
                  />
                </v-row>
              </v-col>
            </v-row>

            <v-row class="d-flex align-stretch">
              <v-col>
                <v-row dense>
                  <v-col>
                    <div class="mb-1">
                      {{ $t('Categories') }}
                    </div>

                    <div class="d-flex">
                      <v-autocomplete
                        v-model="draft.labels"
                        @update:model-value="saveLabelAssignment"
                        :items="courseManagerLabels"
                        item-title="name"
                        variant="solo"
                        chips
                        deleteable-chips
                        flat
                        hide-details
                        multiple
                        return-object
                        tile
                      />

                      <v-btn
                        @click="$refs.manageCategoriesDialog.open(courseManagerLabels)"
                        class="ms-4 mt-1"
                        color="primary"
                        size="large"
                      >
                        {{ $t('Manage categories') }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <ResourceDialog
          @save="updateCategories"
          ref="manageCategoriesDialog"
          :processing="processing"
          title="Manage categories"
        >
          <template #form="{ localValue }">
            <div>
              <div
                v-if="localValue.length === 0"
                class="mb-4"
              >
                <span>
                  {{ $t('No categories available') }}
                </span>
              </div>

              <v-card
                v-for="label in localValue"
                :key="label.id"
                class="pa-4 mb-4"
                border
                tile
              >
                <v-row>
                  <v-col>
                    <v-menu
                      location="left"
                      max-height="300"
                    >
                      <template #activator="menuOptions">
                        <v-btn
                          v-bind="menuOptions.props"
                          class="me-3"
                          size="small"
                          variant="text"
                          icon
                        >
                          <v-avatar
                            :color="label.color"
                            size="20"
                          />
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          v-for="color in colorOptions"
                          @click="changeColor(label, color)"
                          :key="color"
                        >
                          <template #prepend>
                            <v-avatar
                              :color="color"
                              size="20"
                            />
                          </template>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    <span v-text="label.name" />
                  </v-col>

                  <v-col class="ta-right">
                    <ActionMenu
                      @click:action:delete="deleteLabel(label)"
                      @click:action:edit="$refs.addEditLabelDialog.open(label)"
                      :items="[
                        {
                          avatar: 'edit',
                          title: 'Edit',
                          event: 'edit',
                        },
                        {
                          avatar: 'delete',
                          title: 'Delete',
                          event: 'delete',
                        },
                      ]"
                      button-icon="more_vert"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <v-btn
                @click="$refs.addEditLabelDialog.open({})"
                :disabled="processing"
                color="primary"
              >
                {{ $t('Add category') }}
              </v-btn>
            </div>
          </template>
        </ResourceDialog>

        <ResourceDialog
          @save="addEditLabel"
          ref="addEditLabelDialog"
          :fields="[
            {
              text: 'Name',
              value: 'name',
              required: true,
            },
            {
              text: 'Color',
              value: 'color',
              required: true,
              type: 'color-hex',
            },
          ]"
          :processing="processing"
          title="Add category"
        />

        <AssetFinder
          @save="
            draft.image_url = $event;
            save();
          "
          ref="assetFinder"
          :asset-url="draft.image_url"
        />

        <DangerBar
          @destroy="destroy"
          :processing="processing"
          class="my-3"
        />

        <SaveBar
          @cancel="reset"
          @save="save"
          :model-value="changed"
          :processing="processing"
          flat
        />
      </div>
    </v-container>
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import AssetFinder from '@/specialist/components/AssetFinder.vue';
import DangerBar from '@/shared/components/DangerBar.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import TitleBarToggleButton from '@/specialist/components/TitleBarToggleButton.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const props = defineProps({
  initialCourse: {
    type: Object,
    default: null,
  },
});

const addEditLabelDialog = ref(null);
const assetFinder = ref(null);
const changed = ref(false);
const cohorts = ref(null);
const course = ref(props.initialCourse);
const draft = reactive({});
const manageCategoriesDialog = ref(null);
const processing = ref(false);
const today = ref(new Date());
const courseManagerLabels = ref([]);

function cohortStatus(cohort) {
  if (!cohort?.attributes?.start_date || !cohort?.attributes?.end_date) return 'drafted';

  const startDate = new Date(cohort.attributes.start_date);
  const endDate = new Date(cohort.attributes.end_date);

  if (startDate > today.value) return 'upcoming';
  if (today.value > endDate) return 'complete';

  return 'active';
}

function cohortsSummary() {
  if (!cohorts.value) return '';

  const enableStatement = t('Enables graded assignment activities (e.g. homework, comments)');
  const cohortsStatement = t('This course is used by');
  const cohortsText = t('cohorts');

  const summary = `${enableStatement}\n ${cohortsStatement} ${cohorts.value.length} ${cohortsText}`;

  if (cohorts.value.length === 0) return summary;

  const counts = {
    active: 0,
    drafted: 0,
    upcoming: 0,
    complete: 0,
  };

  cohorts.value.forEach((cohort) => {
    const status = cohortStatus(cohort);
    counts[status] += 1;
  });

  const statuses = [];

  Object.entries(counts).forEach(([status, count]) => {
    if (count > 0) statuses.push(`${count} ${t(status)}`);
  });

  return `${summary}\n (${statuses.join(', ')})`;
}

function destroy() {
  if (!confirm('Are you sure you want to delete this course? This cannot be undone.')) return;

  processing.value = true;

  Api.organization.course.destroy(
    route.params.courseId,
    () => {
      router.push({
        name: 'LearningIndex',
        query: {
          tab: 'courses',
        },
      });
    },
    (error) => {
      eventBus.chime(error.response.data.errors[0]);
      processing.value = false;
    },
  );
}

async function load() {
  reset();
  await loadCohorts();
  await loadCourseManagerLabels();
}

async function loadCohorts() {
  const response = await Api.cohort.index({
    filter: {
      course: route.params.courseId,
    },
  });

  cohorts.value = response?.data?.data || [];
}

function reset() {
  Object.assign(draft, JSON.parse(JSON.stringify(course.value || {})));

  changed.value = false;
}

function save() {
  processing.value = true;

  Api.organization.course.update(
    course.value.id,
    draft,
    (response) => {
      course.value = response.data;
      changed.value = false;

      eventBus.chime('Saved');

      processing.value = false;
    },
    (error) => {
      processing.value = false;
      eventBus.chime(error.response.data.errors[0]);
    },
  );
}

function togglePublished() {
  draft.published = !draft.published;
  save();
}

async function deleteLabel(label) {
  if (
    !confirm(
      'You are about to delete a category. If this category is associated with any courses, it will be removed from those courses as well. This action cannot be undone. Are you sure you want to proceed?',
    )
  )
    return;

  processing.value = true;

  await Api.organization.label.destroy(label.id);
  await loadCourseManagerLabels();

  addEditLabelDialog.value.close();
  manageCategoriesDialog.value.close();

  processing.value = false;
}

async function loadCourseManagerLabels() {
  const { data } = await Api.organization.label.index({
    course_manager_id: course.value.course_manager_id,
  });
  courseManagerLabels.value = data;
}

async function addEditLabel(label) {
  processing.value = true;

  if (label.id) {
    await Api.organization.label.update(label.id, label);
  } else {
    await Api.organization.label.create({
      course_manager_id: course.value.course_manager_id,
      label,
    });
  }
  await loadCourseManagerLabels();

  addEditLabelDialog.value.close();
  manageCategoriesDialog.value.close();

  processing.value = false;
}

async function saveLabelAssignment(labels) {
  processing.value = true;

  const label_ids = labels.map((label) => label.id);
  await Api.organization.label_assignment.bulk_assign({
    label_assignment: {
      course_id: course.value.id,
      label_ids: label_ids,
    },
  });

  eventBus.chime('Saved');

  processing.value = false;
}

function updateCategories() {
  processing.value = false;

  addEditLabelDialog.value.close();
  manageCategoriesDialog.value.close();
}

onMounted(load);
</script>
