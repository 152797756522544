<template>
  <v-container class="mxw-1200">
    <router-view />
  </v-container>
</template>

<script>
import links from '@/admin/services/family-subsidy-program-links';

export default {
  compatConfig: { MODE: 2 },

  beforeUnmount() {
    this.$store.commit('setNavigation', null);
  },

  created() {
    this.load();
  },

  methods: {
    load() {
      this.$store.commit('setNavigation', null);
      this.$store.commit('setNavigation', [
        {
          icon: 'chevron_left',
          type: 'back',
          title: 'Back',
          route: 'FamilySubsidyProgramIndex',
        },
        {
          type: 'section',
          items: links.map((link) => ({ ...link, params: { id: this.$route.params.id } })),
        },
      ]);
    },
  },
};
</script>
