<template>
  <v-container
    v-if="familySubsidyProgram && familySubsidySchema"
    class="mxw-1200"
  >
    <TitleBar
      :back-name="familySubsidyProgram.name"
      :back-route="{ name: 'FamilySubsidyProgramIndex' }"
      name="Questions"
    />

    <v-expansion-panels
      v-model="open"
      class="mb-12"
      variant="accordion"
      flat
      multiple
      tile
    >
      <QuestionList
        @cancel="load"
        @change="load"
        @edit="edit($event)"
        @new="$refs.newQuestionDialog.open('FamilySubsidy', $event, true)"
        @new-section="$refs.newQuestionDialog.open('FamilySubsidy', $event, true, true)"
        @settings="settings($event)"
        :processing="processing"
        :questions="sortedEligibilityQuestions"
        :schemas="schemas"
        :selected-question="selectedQuestion"
        :verification="false"
        title="Eligibility questions"
      />

      <QuestionList
        @cancel="load"
        @change="load"
        @edit="edit($event)"
        @new="$refs.newQuestionDialog.open('FamilySubsidy', $event, false)"
        @settings="settings($event)"
        :processing="processing"
        :questions="sortedOtherQuestions"
        :schemas="schemas"
        :selected-question="selectedQuestion"
        :verification="false"
        title="Other questions"
      />

      <template v-if="familySubsidyProgram.enable_household_members">
        <v-expansion-panel
          :ripple="false"
          class="bg-transparent"
        >
          <v-expansion-panel-title disable-icon-rotate>
            <h2 class="text-h6 d-flex align-center">
              <v-avatar
                class="me-3"
                color="primary"
                size="30"
              >
                <span class="c-white fs-14">1</span>
              </v-avatar>
              <span v-t="'Add household members'" />
            </h2>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-card
              border
              tile
            >
              <v-card-text>
                <FormQuestion
                  :title="'List information for members in the household/family'"
                  dense
                  hide-actions
                  paddingless
                >
                  <HouseholdMembersCard
                    :value="[]"
                    staged
                  />
                </FormQuestion>
              </v-card-text>
            </v-card>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <QuestionList
          @cancel="load"
          @change="load"
          @edit="edit($event)"
          @new="$refs.newQuestionDialog.open('HouseholdMember', $event, false)"
          @settings="settings($event)"
          :processing="processing"
          :questions="sortedHouseholdMemberQuestions"
          :schemas="schemas"
          :selected-question="selectedQuestion"
          :verification="false"
          title="Household member questions"
        />
      </template>
    </v-expansion-panels>

    <NewQuestionDialog
      @created="load"
      ref="newQuestionDialog"
      :owner-id="familySubsidyProgram.id"
      :schema="familySubsidySchema"
      :schemas="schemas"
      owner-type="FamilySubsidyProgram"
    />

    <ProcessingOverlay :processing="processing" />
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import HouseholdMembersCard from '@/shared/components/household-members/HouseholdMembersCard.vue';
import LinkedList from '@/shared/services/linked-list';
import NewQuestionDialog from '@/shared/components/form/NewQuestionDialog.vue';
import ProcessingOverlay from '@/shared/components/ProcessingOverlay.vue';
import QuestionList from '@/admin/components/QuestionList.vue';
import TitleBar from '@/shared/components/TitleBar.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    FormQuestion,
    HouseholdMembersCard,
    NewQuestionDialog,
    ProcessingOverlay,
    QuestionList,
    TitleBar,
  },
  mixins: [API],

  data() {
    return {
      familySubsidyProgram: null,
      familySubsidySchema: null,
      householdMemberSchema: Object.values(this.$store.state.schemas).filter(
        (schema) => schema.data_type === 'HouseholdMember',
      )[0],
      open: [0, 1],
      processing: false,
      questions: [],
      schemas: [],
      selectedQuestion: null,
      sortedEligibilityQuestions: [],
      sortedOtherQuestions: [],
      sortedHouseholdMemberQuestions: [],
    };
  },

  created() {
    this.load();
  },

  methods: {
    async load() {
      this.processing = true;
      this.selectedQuestion = null;
      this.changed = false;
      await this.loadFamilySubsidyProgram();
      await this.loadSchema();
      await this.loadQuestions();
      this.processing = false;
    },

    async loadFamilySubsidyProgram() {
      const resp = await this.api.organization.family_subsidy_program.get(this.$route.params.id);
      this.familySubsidyProgram = resp.data;
    },

    async loadQuestions() {
      const filters = {
        owner_id: this.familySubsidyProgram.id,
        owner_type: 'FamilySubsidyProgram',
      };
      const resp = await this.api.public_api.organization.question.index(filters);
      this.questions = resp.data;
      this.sortedEligibilityQuestions = this.sortQuestions(this.questions, 'FamilySubsidy', true);
      this.sortedOtherQuestions = this.sortQuestions(this.questions, 'FamilySubsidy', false);
      this.sortedHouseholdMemberQuestions = this.sortQuestions(
        this.questions,
        'HouseholdMember',
        false,
      );
      this.processing = false;
    },

    async loadSchema() {
      const resp = await this.api.organization.schema.get(
        this.familySubsidyProgram.family_subsidy_schema_id,
      );
      this.familySubsidySchema = resp.data;
      this.schemas = [this.familySubsidySchema, this.householdMemberSchema];
    },

    edit(question) {
      this.selectedQuestion = question;
    },

    filterQuestionsForModel(questions, syncedModel) {
      return questions.filter((question) => question.synced_model === syncedModel);
    },

    sortQuestions(questions, syncedModel, eligibility) {
      return LinkedList.sort(
        this.filterQuestionsForModel(questions, syncedModel).filter(
          (question) => question.eligibility === eligibility,
        ),
      );
    },

    settings(question) {
      this.selectedQuestion = question;
      this.$refs.detailsDrawer.open();
    },
  },
};
</script>
