<template>
  <div>
    <transition-group :name="transitionName">
      <div
        v-for="(question, index) in questions"
        :key="keyName + '-' + index + question.id"
      >
        <div v-if="expanded || section == keyName + '-' + index">
          <template v-if="question.is_section">
            <v-card
              :class="index > 0 ? 'mt-6' : 'my-0'"
              class="bg-transparent"
            >
              <v-card-text class="bg-very-light-grey">
                <div class="fw-600 fs-16">
                  {{ $t(question.title) }}
                </div>
                <div class="fw-500 fs-16">
                  {{ $t(question.subtitle) }}
                </div>
              </v-card-text>
            </v-card>
          </template>

          <template v-else>
            <v-divider
              v-if="divided"
              :class="dividedClass"
            />

            <QuestionWithField
              v-if="question.format === 'text'"
              @back="$emit('back', index)"
              @change="$emit('change', $event)"
              @change:attachments="$emit('change:attachments')"
              @next="$emit('next', index)"
              :attachment-group-id="attachmentGroupId"
              :attachment-owner-id="attachmentOwnerId"
              :attachment-owner-type="attachmentOwnerType"
              :attachments="attachments"
              :autofocus="autofocus"
              :color="color"
              :condensed="condensed"
              :dense="dense"
              :display-answer="displayAnswer"
              :elevation="elevation"
              :expanded="expanded"
              :header="header"
              :hide-actions="hideActions"
              :hide-field="hideField"
              :inline-verification="inlineVerification"
              :landscape="landscape"
              :model-value="models[question.synced_model]"
              :outlined="outlined"
              :paddingless="paddingless"
              :processing="processing"
              :question="question"
              :readonly="readonly"
              :schema="
                schemas.find((schema) => schema.data_type == question.synced_model).definition
              "
              :show-indicators="showIndicators"
              :tile="tile"
              :very-dense="veryDense"
            />

            <DocumentQuestion
              v-if="question.format === 'document'"
              v-model="$attrs.modelValue"
              @back="$emit('back', index)"
              @change="$emit('change', $event)"
              @change:attachments="$emit('change:attachments')"
              @change:input="$emit('change:input', $event)"
              @click:notes="$emit('click:notes', question)"
              @next="$emit('next', index)"
              :attachment-group-id="attachmentGroupId"
              :attachment-owner-id="attachmentOwnerId"
              :attachment-owner-type="attachmentOwnerType"
              :attachment-readonly="attachmentReadonly"
              :attachment-status="attachmentStatus"
              :attachment-tags-supplements="attachmentTagsSupplements"
              :attachments="attachments"
              :autofocus="autofocus"
              :color="color"
              :condensed="condensed"
              :dense="dense"
              :display-notes="displayNotes"
              :elevation="elevation"
              :expanded="expanded"
              :header="header"
              :hide-actions="hideActions"
              :landscape="landscape"
              :notes-count="noteCount(question)"
              :outlined="outlined"
              :paddingless="paddingless"
              :processing="processing"
              :question="question"
              :readonly="readonly"
              :reversible="reversible"
              :schema="schema"
              :show-indicators="showIndicators"
              :tile="tile"
              :very-dense="veryDense"
            />
          </template>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import QuestionWithField from '@/shared/components/form/QuestionWithField.vue';
import DocumentQuestion from '@/shared/components/form/DocumentQuestion.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    QuestionWithField,
    DocumentQuestion,
  },

  props: {
    attachmentGroupId: {
      type: String,
      default: null,
    },
    attachmentOwnerId: {
      type: String,
      default: null,
    },
    attachmentOwnerType: {
      type: String,
      default: null,
    },
    attachmentReadonly: {
      type: Boolean,
      default: false,
    },
    attachmentTagsSupplements: {
      type: Array,
      default: () => [],
    },
    attachmentStatus: {
      type: Boolean,
      default: false,
    },
    attachments: {
      type: Array,
      default: () => [],
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    color: {
      default: 'transparent',
      type: String,
    },
    condensed: {
      type: Boolean,
      default: false,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    displayAnswer: {
      type: Boolean,
      default: false,
    },
    displayNotes: {
      type: Boolean,
      default: false,
    },
    veryDense: {
      type: Boolean,
      default: false,
    },
    divided: {
      default: false,
      type: Boolean,
    },
    elevation: {
      default: 0,
      type: Number,
    },
    expanded: {
      default: false,
      type: Boolean,
    },
    reversible: {
      type: Boolean,
      default: true,
    },
    header: {
      type: String,
      default: null,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    hideField: {
      type: Boolean,
      default: false,
    },
    showIndicators: {
      type: Boolean,
      default: false,
    },
    inlineVerification: {
      type: Boolean,
      default: false,
    },
    keyName: {
      type: String,
      default: null,
    },
    landscape: {
      type: Boolean,
      default: false,
    },
    models: {
      type: Object,
      default: () => null,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    paddingless: {
      type: Boolean,
      default: false,
    },
    processing: {
      default: false,
      type: Boolean,
    },
    questions: {
      type: Array,
      default: () => null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object,
      default: () => null,
    },
    schemas: {
      type: Array,
      default: () => null,
    },
    section: {
      type: String,
      default: null,
    },
    tile: {
      type: Boolean,
      default: false,
    },
    transitionName: {
      type: String,
      default: null,
    },
  },

  emits: ['back', 'change', 'change:attachments', 'change:input', 'click:notes', 'next'],

  computed: {
    dividedClass() {
      if (this.landscape) {
        return 'my-0';
      }
      if (this.dense) {
        return 'mb-4';
      }
      if (this.veryDense) {
        return 'mb-2';
      }
      return 'mb-6';
    },
  },

  methods: {
    noteCount() {
      // TODO: implement notes?
      // return (this.notes || []).filter((note) => note.question_id === question.id).length;
      return 0;
    },
  },
};
</script>
