<template>
  <v-container
    v-if="!isAnyReportFolderSelected"
    fluid
  >
    <v-row>
      <v-col
        v-for="folder in reportFolders"
        :key="folder.id"
        cols="12"
        lg="3"
        md="4"
        sm="6"
      >
        <v-card
          @click="reload"
          :class="{ active: isSelected(folder.id) }"
          :to="{ path: 'reports', query: { reportFolderId: folder.id } }"
          class="report-folder"
          border
          flat
          tile
        >
          <div class="d-flex">
            <v-card-title class="flex-1-1 text-wrap">
              {{ folder.name }}
            </v-card-title>
            <div class="mr-3 d-flex">
              <ActionMenu
                v-if="$store.state.profile.org_reporting_admin"
                @click:action:delete="destroyReportFolder(folder)"
                @click:action:edit="openReportFolderDialog(folder)"
                :items="reportFolderActions"
                button-color="black"
                button-icon="more_vert"
                class="align-center"
              />
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { useRoute } from 'vue-router';
import ActionMenu from '@/shared/components/ActionMenu.vue';

defineProps({
  destroyReportFolder: {
    type: Function,
    default: () => {
      throw new Error(
        'destroyReportFolder function not provided. Please provide a function to destroy a Report Folder.',
      );
    },
  },
  isAnyReportFolderSelected: {
    type: Boolean,
    default: false,
  },
  reload: {
    type: Function,
    default: () => {
      throw new Error(
        'reload function not provided. Please provide a function to reload data after a Report' +
          ' Folder has been selected.',
      );
    },
  },
  reportFolders: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['openDialog']);
const route = useRoute();

const isSelected = computed(() => (folderId) => {
  return route.query?.reportFolderId?.toString() === folderId?.toString();
});

const openReportFolderDialog = (folder) => {
  emit('openDialog', folder);
};

const reportFolderActions = [
  { event: 'edit', avatar: 'edit', title: 'View/edit' },
  { event: 'delete', avatar: 'delete', title: 'Delete' },
];
</script>

<style scoped>
.report-folder {
  min-height: 122px; /* Necessary to keep height from janking screen when the card is clicked. */
}
</style>
