<template>
  <v-container
    v-if="cohort"
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <div class="mt-3 mx-3">
      <v-card
        border
        flat
        tile
      >
        <v-card-text class="fs-15 py-3">
          <v-row dense>
            <v-col class="d-flex align-center">
              <p class="mb-0 py-1">
                {{ $t('Displaying') }} <strong>{{ collection.length }}</strong> {{ $t('results') }},
                {{ $t('out of') }} <strong>{{ totalItems }}</strong> {{ $t('total results') }}.
              </p>
            </v-col>

            <template v-if="$store.state.profile?.org_learning_admin">
              <v-btn
                @click="$refs.invitationCodeDialog.open()"
                class="me-3"
                variant="outlined"
                tracked
              >
                <span>{{ $t('Invitation code') }}</span>
              </v-btn>

              <v-btn
                @click="updateQuery({ action: 'add-learner' })"
                id="add-learner-btn"
                color="primary"
                prepend-icon="add"
                tracked
              >
                {{ $t('Add learner') }}
              </v-btn>
            </template>
          </v-row>

          <NullState
            v-if="collection.length === 0"
            hide-new-button
          />
        </v-card-text>
      </v-card>
    </div>

    <v-data-table
      v-if="collection.length > 0"
      :headers="tableHeaders"
      :hide-default-footer="pages === 0"
      :items="collection"
      :items-length="totalItems"
      class="mx-3 b-radius-0 b-1 bt-0 bc-outlined-gray fixed-height"
      item-key="id"
      disable-sort
      fixed-header
    >
      <template #item.status>
        <v-chip
          color="light-green-lighten-3"
          size="small"
        >
          {{ $t('Active') }}
        </v-chip>
      </template>

      <template #item.certificate_status="{ item }">
        <div :with="(certificateStatus = learnerCertificateStatus(item))">
          <template v-if="certificateStatus === true">
            <v-chip
              color="light-green-lighten-3"
              size="small"
            >
              {{ $t('Awarded') }}
            </v-chip>
          </template>

          <template v-else-if="certificateStatus === false">
            <v-chip
              color="red-lighten-3"
              size="small"
            >
              {{ $t('Revoked') }}
            </v-chip>
          </template>

          <template v-else-if="!getLearnerCourseCompletion(item)">
            <v-chip
              color="grey-lighten-3"
              size="small"
            >
              {{ $t('Not Started') }}
            </v-chip>
          </template>

          <template v-else>
            <v-chip
              color="grey-lighten-3"
              size="small"
            >
              {{ $t('Not Awarded') }}
            </v-chip>
          </template>
        </div>
      </template>

      <template #item.lessons="{ item }">
        {{ learnerLessonCount(item) || 0 }} / {{ lessonsCount }}
      </template>

      <template #item.assignments="{ item }">
        {{ learnerAssignmentCount(item) || 0 }} / {{ assignmentsCount }}
      </template>

      <template #item.quizzes="{ item }">
        {{ learnerLessonCount(item, true) || 0 }} / {{ quizzesCount }}
      </template>

      <template
        v-if="actionsAccess"
        #item.actions="{ item }"
      >
        <div
          class="d-flex justify-end align-center"
          data-testid="learner-actions"
        >
          <ActionMenu
            @click:action:award="updateCertificate(item, true)"
            @click:action:delete="remove(item)"
            @click:action:revoke="updateCertificate(item, false)"
            :items="learnerActionItems(item)"
            button-icon="more_vert"
          />
        </div>
      </template>
    </v-data-table>

    <AddLearnerDialog
      @add="addLearners"
      :existing-learners="collection"
    />

    <ResourceDialog
      @save="saveInvitationCode"
      ref="invitationCodeDialog"
      save-button-text="Save"
      title="Invitation code"
      closeable
    >
      <template #form>
        <v-row>
          <LabeledTextfield
            v-model="cohort.attributes.invitation_code"
            id="cohort-invite-code"
            description="Share the code below to allow members to apply for this cohort"
          />
        </v-row>
      </template>
    </ResourceDialog>

    <ConfirmDialog ref="confirmDialog" />
  </v-container>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/specialist/services/bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import AddLearnerDialog from '@/specialist/views/learning/AddLearnerDialog.vue';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import NullState from '@/shared/components/NullState.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import { useStore } from 'vuex';

const eventBus = useEventBus();

const store = useStore();
const { updateQuery } = useRouterHelper();

const props = defineProps({
  initialIncludedData: {
    type: Array,
    default: null,
  },
  initialCohort: {
    type: Object,
    default: null,
  },
});

const assignmentCompletions = ref(null);
const assignments = ref(null);
const cohort = ref(null);
const cohortCourseCompletions = ref(null);
const collection = ref([]);
const confirmDialog = ref(null);
const course = ref(null);
const courseCompletions = ref(null);
const includedData = ref(null);
const lessonCompletions = ref(null);
const lessons = ref(null);
const pages = ref(1);
const tableHeaders = ref([
  {
    title: 'Name',
    value: 'attributes.name',
    align: 'center,',
  },
  {
    title: 'Status',
    value: 'status',
    align: 'center',
  },
  {
    title: 'Certificate Status',
    value: 'certificate_status',
    align: 'center',
  },
  {
    title: 'Lessons',
    value: 'lessons',
    align: 'center',
  },
  {
    title: 'Assignments',
    value: 'assignments',
    align: 'center',
  },
  {
    title: 'Quizzes',
    value: 'quizzes',
    align: 'center',
  },
  {
    title: '',
    value: 'actions',
  },
]);
const totalItems = ref(0);

const actionsAccess = computed(() => {
  return store.state.profile.org_learning_admin;
});

const assignmentsCount = computed(() => {
  return assignments.value?.length || '';
});

const quizzesCount = computed(() => {
  if (!lessons.value) return '';
  if (!lessons.value.length) return 0;

  return lessons.value.reduce((acc, lesson) => acc + (lesson?.quiz_schema_id ? 1 : 0), 0);
});

const lessonsCount = computed(() => {
  return lessons.value?.length || '';
});

onMounted(async () => {
  await load(props.initialCohort, props.initialIncludedData);
  await loadLessons();
  await loadAssignments();
  await loadAssignmentCompletions();
});

async function addLearners(members) {
  const newLearners = members.map((member) => ({ id: member.id, type: 'member' }));
  const updatedLearners = collection.value.concat(newLearners);

  const successChimeMessage = newLearners.length > 1 ? 'Learners added' : 'Learner added';

  await update(updatedLearners, successChimeMessage);
}

function learnerAssignmentCount(learner) {
  if (
    !assignments.value?.length ||
    !courseCompletions.value?.length ||
    !assignmentCompletions.value?.length
  )
    return '';

  const learnerCourseCompletion = getLearnerCourseCompletion(learner);

  if (!learnerCourseCompletion) return 0;

  return assignments.value.reduce((count, assignment) => {
    const learnerAssignmentCompletion = assignmentCompletions.value.find(
      (completion) =>
        completion.assignment_id === assignment.id &&
        completion.course_completion_id === learnerCourseCompletion.id,
    );

    if (learnerAssignmentCompletion?.completed) return count + 1;

    return count;
  }, 0);
}

function learnerActionItems(item) {
  const actionItems = [
    {
      title: 'Remove',
      event: 'delete',
    },
  ];

  const courseCompletion = getLearnerCourseCompletion(item);
  const certificateStatus = learnerCertificateStatus(item, courseCompletion);

  if (certificateStatus === true) {
    actionItems.unshift({
      title: 'Revoke certificate',
      event: 'revoke',
    });
  } else if (courseCompletion) {
    actionItems.unshift({
      title: 'Award certificate',
      event: 'award',
    });
  }

  return actionItems;
}

function learnerCertificateStatus(learner, learnerCourseCompletion = null) {
  const cohortCourseCompletion = learnerCohortCourseCompletion(learner, learnerCourseCompletion);

  return cohortCourseCompletion?.attributes?.awarded;
}

function learnerCohortCourseCompletion(learner, learnerCourseCompletion = null) {
  if (!courseCompletions.value?.length || !cohortCourseCompletions.value?.length) return;

  if (!learnerCourseCompletion) learnerCourseCompletion = getLearnerCourseCompletion(learner);
  if (!learnerCourseCompletion) return;

  return (
    cohortCourseCompletions.value.find(
      (cohortCourseCompletion) =>
        cohortCourseCompletion.relationships.course_completion.data.id ===
        learnerCourseCompletion.id,
    ) || null
  );
}

function getLearnerCourseCompletion(learner) {
  if (!courseCompletions.value?.length) return;

  return (
    courseCompletions.value.find((courseCompletion) => courseCompletion.member_id === learner.id) ||
    null
  );
}

function learnerLessonCount(learner, onlyQuizzes = false) {
  if (
    !lessons.value?.length ||
    !courseCompletions.value?.length ||
    !lessonCompletions.value?.length
  )
    return '';

  const learnerCourseCompletion = getLearnerCourseCompletion(learner);

  if (!learnerCourseCompletion) return 0;

  return lessons.value.reduce((count, lesson) => {
    if (onlyQuizzes && !lesson.quiz_schema_id) return count;

    const learnerLessonCompletion = lessonCompletions.value.find(
      (completion) =>
        completion.lesson_id === lesson.id &&
        completion.course_completion_id === learnerCourseCompletion.id,
    );

    if (learnerLessonCompletion?.completed) return count + 1;

    return count;
  }, 0);
}

async function load(initialCohort, initialIncludedData) {
  cohort.value = initialCohort;
  includedData.value = initialIncludedData;
  collection.value = initialIncludedData?.filter((item) => item.type === 'member') || [];

  const courses = includedData.value?.filter((item) => item.type === 'course') || [];
  if (courses.length) {
    course.value = courses[0];

    await loadCourseCompletions();
    await loadLessonCompletions();
    await loadCohortCourseCompletions();
  }

  totalItems.value = collection.value.length;
}

async function loadAssignmentCompletions() {
  assignmentCompletions.value = [];

  await Promise.all(
    assignments.value.map(async (assignment) => {
      const assignmentCompletionsResponse = await Api.organization.assignmentCompletion.index({
        assignment_id: assignment.id,
      });
      const newAssignmentCompletions = assignmentCompletionsResponse?.data || [];

      assignmentCompletions.value = assignmentCompletions.value.concat(newAssignmentCompletions);
    }),
  );
}

async function loadAssignments() {
  assignments.value = [];

  await Promise.all(
    lessons.value.map(async (lesson) => {
      const assignmentResponse = await Api.public_api.organization.assignment.index({
        lesson_id: lesson.id,
      });
      const newAssignments = assignmentResponse?.data || null;

      assignments.value = assignments.value.concat(newAssignments);
    }),
  );
}

async function loadCohortCourseCompletions() {
  const params = {
    filter: {
      cohort: cohort.value.id,
    },
  };
  const response = await Api.cohortCourseCompletion.index(params);

  cohortCourseCompletions.value = response?.data?.data || [];
}

async function loadCourseCompletions() {
  const learnerIds = collection.value.map((learner) => learner.id);
  let response = {};
  if (learnerIds.length > 100) {
    response = await Api.organization.course_completion.index({
      course_id: course.value.id,
    });
    response = { data: response.data.filter((cc) => learnerIds.includes(cc.member_id)) };
  } else {
    response = await Api.organization.course_completion.index({
      course_id: course.value.id,
      member_id: collection.value.map((learner) => learner.id),
    });
  }

  courseCompletions.value = response?.data || [];
}

async function loadLessons() {
  const response = await Api.organization.lesson.index(course.value.id);
  lessons.value = response?.data || [];
}

async function loadLessonCompletions() {
  lessonCompletions.value = [];

  await Promise.all(
    courseCompletions.value.map(async (courseCompletion) => {
      const response = await Api.organization.lessonCompletion.index({
        course_completion_id: courseCompletion.id,
      });
      const newLessonCompletions = response?.data || null;

      lessonCompletions.value = lessonCompletions.value.concat(newLessonCompletions);
    }),
  );
}

async function remove(learner) {
  if (
    await confirmDialog.value.confirmWithText(
      'Are you sure you want to remove this learner from the cohort?',
    )
  ) {
    const existingLearners = cohort.value?.relationships?.members?.data || [];
    const newLearners = existingLearners.filter(
      (existingLearner) => existingLearner.id !== learner.id,
    );

    await update(newLearners, 'Learner removed');
  }
}

async function updateCertificate(learner, awarded) {
  const courseCompletion = getLearnerCourseCompletion(learner);

  if (!courseCompletion) {
    eventBus.chime('Cannot award certificate when course has not been started by learner');
    return;
  }

  const cohortCourseCompletion = learnerCohortCourseCompletion(learner, courseCompletion);
  const attributes = { awarded };
  let response;

  if (!cohortCourseCompletion) {
    const params = {
      data: {
        type: 'cohortCourseCompletion',
        attributes,
        relationships: {
          cohort: {
            data: {
              type: 'cohort',
              id: cohort.value.id,
            },
          },
          course_completion: {
            data: {
              type: 'courseCompletion',
              id: courseCompletion.id,
            },
          },
        },
      },
    };
    response = await Api.cohortCourseCompletion.create(params);
  } else {
    response = await Api.cohortCourseCompletion.update(cohortCourseCompletion.id, { attributes });
  }

  if ([200, 201].includes(response?.status)) {
    await loadCohortCourseCompletions();
    eventBus.chime(`Certificate status updated for ${learner.attributes.name}`);
  } else {
    eventBus.chime(response?.data?.errors[0] || 'An error occurred');
  }
}

async function saveInvitationCode() {
  const { data } = await Api.cohort.update(
    cohort.value.id,
    {
      attributes: {
        invitation_code: cohort.value.attributes.invitation_code || null,
      },
    },
    { include: 'members' },
  );
  cohort.value = data?.data;
  eventBus.chime('Saved');
}

async function update(learners, successChimeMessage) {
  const response = await Api.cohort.update(
    cohort.value.id,
    {
      relationships: {
        members: {
          data: _.uniqBy(learners, 'id'),
        },
      },
    },
    { include: 'members' },
  );

  if (response.status === 200) {
    eventBus.chime(successChimeMessage);
    await load(response?.data?.data, response?.data?.included);
  } else {
    eventBus.chime(response?.data?.errors[0] || 'An error occurred');
  }
}
</script>
