<template>
  <div>
    <ResourceDialog
      @close="updateQuery({ memberId: null, action: null })"
      @save="save($event)"
      ref="memberDialog"
      :fields="fields"
      :max-width="600"
      :processing="processing"
      :save-button-text="buttonText"
      :title="titleText"
    >
      <template #append="{ localValue }">
        <template v-if="localValue.terms_agreement_id">
          <v-row>
            <v-col>
              <template v-if="localValue.terms_agreement_accepted_at">
                <v-btn
                  :href="link"
                  color="primary"
                  target="_blank"
                  variant="outlined"
                >
                  {{ $t('Download agreement') }}
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  @click="openTermsAgreement(localValue.terms_agreement_id)"
                  color="primary"
                  variant="outlined"
                >
                  {{ $t('Review Terms of Service') }}
                </v-btn>
              </template>
            </v-col>
          </v-row>
        </template>
      </template>
    </ResourceDialog>

    <ContentDialog
      ref="agreementDialog"
      :max-width="800"
    >
      <template #content="{ data }">
        <MarkdownContent
          v-if="data"
          :content="data.termsAgreement.content_html"
          class="fs-16"
        />
      </template>
    </ContentDialog>
  </div>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import ContentDialog from '@/shared/components/ContentDialog.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute } from 'vue-router';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const route = useRoute();
const store = useStore();
const { updateQuery } = useRouterHelper();

const props = defineProps({
  groupId: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['change']);

const agreementDialog = ref(null);
const memberDialog = ref(null);
const member = ref(false);
const processing = ref(false);
const sites = ref([]);
const termsAgreements = ref([]);

const buttonText = computed(() => {
  return member.value ? 'Save' : 'Send invitation';
});

const fields = computed(() => {
  const fieldsAry = [
    {
      cols: '6',
      text: 'First name',
      value: 'first_name',
      required: true,
    },
    {
      cols: '6',
      text: 'Last name',
      value: 'last_name',
      required: true,
    },
    {
      cols: '6',
      text: 'Email',
      value: 'email',
      required: false,
    },
    {
      cols: '6',
      mask: '(###) ###-####',
      text: 'Phone',
      value: 'phone',
      required: false,
    },
    {
      cols: '12',
      text: 'Primary site',
      value: 'site_id',
      required: true,
      items: sites.value,
      itemText: 'name',
      itemValue: 'id',
    },
    {
      cols: '12',
      text: 'Language preference',
      value: 'default_locale',
      items: store.state.config.languages,
      itemText: 'text',
      itemValue: 'value',
      required: false,
    },
  ];

  if (termsAgreements.value.length > 0) {
    fieldsAry.push({
      cols: '12',
      text: 'Applicable Terms of Service',
      value: 'terms_agreement_id',
      items: termsAgreements.value,
      itemText: 'name',
      itemValue: 'id',
      required: true,
    });
    fieldsAry.push({
      cols: '12',
      text: 'I accept the Terms of Service',
      type: 'boolean',
      value: 'terms_agreement_accepted',
      required: true,
    });
  }

  fieldsAry.push({
    cols: '12',
    hidden: (localObject) => !localObject.value?.phone,
    text: 'I consent on behalf of the member to receive communication by text message to the cellphone number provided above',
    type: 'boolean',
    value: 'text_opted_in',
  });

  return fieldsAry;
});

const link = computed(() => {
  if (!member.value?.terms_agreement_id || !member.value?.terms_agreement_accepted_at) return null;

  const params = {
    filename: encodeURIComponent('TermsAgreement.pdf'),
    time: member.value.terms_agreement_accepted_at,
    name: member.value.name,
    locale: member.value.default_locale,
  };
  return api.organization.termsAgreement.downloadUrl(member.value.terms_agreement_id, params);
});

const titleText = computed(() => {
  return member.value ? 'Edit member' : 'Invite member';
});

watch(
  () => route.query.memberId,
  async (newVal) => {
    if (newVal) {
      await loadMember();
      await loadSites();
      await loadTermsAgreements();
    } else if (memberDialog.value) {
      memberDialog.value.close();
    }
  },
);

watch(
  () => route.query.action,
  async () => {
    await checkAction();
  },
);

async function checkAction() {
  if (route.query.action === 'create-member') {
    await loadSites();
    await loadTermsAgreements();
    member.value = null;
    memberDialog.value.open({ site_id: sites.value[0]?.id });
  } else {
    memberDialog.value.close();
  }
}

async function loadMember() {
  const { data } = await api.organization.member.get(route.query.memberId);
  member.value = data;
  memberDialog.value.open(member.value);
}

async function loadSites() {
  const resp = await api.organization.site.index({ site_type: 'parent' });
  sites.value = resp.data;
}

async function loadTermsAgreements() {
  const { data } = await api.organization.termsAgreement.index({});
  termsAgreements.value = data;
}

function openTermsAgreement(termsAgreementId) {
  const termsAgreement = termsAgreements.value.find((ta) => ta.id === termsAgreementId);
  agreementDialog.value.open({ termsAgreement });
}

async function save(newVal) {
  if (!newVal.email && !newVal.phone) {
    eventBus.chime('Phone or email required');
    return;
  }
  processing.value = true;
  const params = {
    ...newVal,
    group_id: props.groupId,
    is_parent: true,
  };

  let resp;
  if (newVal.id) {
    resp = await api.organization.member.update(params);
  } else {
    resp = await api.organization.member.create(params);
  }
  processing.value = false;
  if (!resp?.data) return;

  member.value = resp.data;
  memberDialog.value?.close();
  emit('change', member.value);
}
</script>
