<template>
  <div v-if="cohort">
    <v-row
      class="bg-white border-b mt-0"
      dense
    >
      <v-col cols="4">
        <v-card
          class="px-6 py-4"
          flat
          rounded
        >
          <v-img
            v-if="course?.image_url"
            :src="course?.image_url"
            class="bg-super-light-grey b-1 bc-outlined-gray"
            height="200"
          />

          <v-icon
            v-else
            class="bg-super-light-grey b-1 bc-outlined-gray"
            size="x-large"
            style="height: 200px; width: 100%"
          >
            image
          </v-icon>
        </v-card>
      </v-col>

      <v-col cols="8">
        <div class="px-4 py-3">
          <div
            class="text-h4"
            style="overflow: hidden"
          >
            <div v-text="course?.name" />
          </div>

          <div class="text-subtitle-1">
            <MarkdownContent :content="course?.description || 'No description'" />
          </div>
        </div>
      </v-col>
    </v-row>

    <CourseLessonCard
      v-for="(lesson, index) in lessons"
      @open="openCohortLesson($event)"
      :key="'lessonCard' + index"
      :cohort="cohort"
      :cohort-lesson="findCohortLesson(lesson)"
      :course="course"
      :index="`${index + 1}`"
      :lesson="lesson"
    />

    <ResourceDialog
      @save="save"
      ref="cohortLessonDialog"
      title="Update lesson"
      close-on-save
    >
      <template #form>
        <v-row>
          <v-col v-if="isLearningAdmin">
            <p>
              {{ $t('Name') }}
            </p>

            <router-link
              :to="{
                name: 'LessonShow',
                params: {
                  courseId: course?.id,
                  lessonId: openedLesson.id,
                },
              }"
            >
              {{ openedLesson.name }}
            </router-link>
          </v-col>

          <LabeledDatePicker
            v-model="openedCohortLesson.attributes.start_date"
            cols="12"
            message="Start date"
          />

          <LabeledDatePicker
            v-model="openedCohortLesson.attributes.end_date"
            cols="12"
            message="End date"
          />

          <LabeledSwitch
            v-model="openedCohortLesson.attributes.lock_after_end_date"
            cols="12"
            title="Lock after end date"
          />
        </v-row>
      </template>
    </ResourceDialog>
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import CourseLessonCard from '@/specialist/views/learning/course/CourseLessonCard.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import { useStore } from 'vuex';
import useEventBus from '@/shared/composables/useEventBus';
import { ResourceDialog } from '@/shared/components';

const eventBus = useEventBus();
const store = useStore();

const props = defineProps({
  cohort: {
    type: Object,
    default: null,
  },
});

const cohortLessons = ref(null);
const cohortLessonDialog = ref(null);
const course = ref(null);
const lessons = ref(null);
const processing = ref(true);
const openedCohortLesson = ref({
  attributes: {
    start_date: null,
    end_date: null,
  },
});
const openedLesson = ref({
  name: null,
});

const isLearningAdmin = computed(() => {
  return store.state.profile?.org_learning_admin;
});

onMounted(load);

function findCohortLesson(lesson) {
  return cohortLessons.value?.find(
    (cohortLesson) => cohortLesson.relationships.lesson.data.id === lesson.id,
  );
}

async function load() {
  processing.value = true;
  const courseId = props.cohort.relationships.course.data.id;

  await loadCourse(courseId);
  await loadLessons(courseId);
  await loadCohortLessons();

  processing.value = false;
}

async function loadCohortLessons() {
  const response = await Api.cohortLesson.index({
    filter: {
      cohort: props.cohort.id,
    },
    include: 'lesson',
  });
  cohortLessons.value = response?.data.data || [];
}

async function loadCourse(id) {
  const { data } = await Api.organization.course.get(id);
  course.value = data;
  store.commit('setCourse', course);
}

async function loadLessons(courseId) {
  await Api.organization.lesson.index(courseId, (response) => {
    lessons.value = response?.data;
    store.commit('setLessons', lessons);
  });
}

async function openCohortLesson(openParams) {
  const id = openParams.cohortLesson?.id;

  if (id) {
    openedCohortLesson.value = openParams.cohortLesson;
  } else {
    openedCohortLesson.value = {
      attributes: {
        start_date: null,
        end_date: null,
        lock_after_end_date: false,
      },
    };
  }

  openedLesson.value = openParams.lesson;

  cohortLessonDialog.value.open();
}

async function save() {
  let response;

  const attributes = {
    start_date: openedCohortLesson.value.attributes.start_date,
    end_date: openedCohortLesson.value.attributes.end_date,
    lock_after_end_date: openedCohortLesson.value.attributes.lock_after_end_date,
  };

  const id = openedCohortLesson.value?.id;

  if (id) {
    response = await Api.cohortLesson.update(id, { attributes });
  } else {
    const params = {
      data: {
        type: 'cohortLesson',
        attributes,
        relationships: {
          cohort: {
            data: {
              type: 'cohort',
              id: props.cohort.id,
            },
          },
          lesson: {
            data: {
              type: 'lesson',
              id: openedLesson.value.id,
            },
          },
        },
      },
    };
    response = await Api.cohortLesson.create(params);
  }

  if ([200, 201].includes(response?.status)) {
    await loadCohortLessons();
    eventBus.chime('Lesson saved');
  } else {
    eventBus.error(response?.data?.errors[0] || 'An error occurred');
  }
}
</script>
