<template>
  <v-container
    v-if="sites"
    class="mxw-1200 pa-6"
  >
    <TitleBar
      @create="router.push({ name: 'SiteNew' })"
      create-text="New site"
      name="Sites"
      createable
    />

    <NullState
      v-if="sites.length === 0"
      title="No sites found"
    />

    <v-row
      v-for="(site, index) in sites"
      :key="index"
    >
      <v-col>
        <SitePreview :site="site" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import NullState from '@/shared/components/NullState.vue';
import SitePreview from '@/admin/components/sites/SitePreview.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();

const sites = ref(null);

function load() {
  store.commit('setNavigation', null);
  Api.organization.site.index({}, (response) => {
    sites.value = response.data;
  });
}

onMounted(load);
</script>
