<template>
  <v-container
    class="px-0 pt-0"
    fluid
  >
    <div class="mt-3 mx-3 flex">
      <iframe
        v-if="streamlitToken"
        :src="iframeSrc"
        class="w-100 border bc-very-light-grey"
        style="height: 80vh"
      />
    </div>
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';

const streamlitToken = ref(null);

const iframeSrc = computed(() => {
  return `https://streamlit-development-c7dcc9aaa41f.herokuapp.com/?page=supply&token=${streamlitToken.value}`;
});

async function load() {
  const response = await Api.organization.jsonWebToken.create();
  streamlitToken.value = response?.data.token;
}

onMounted(load);
</script>
