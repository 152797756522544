import Api from '@/shared/services/all_bright_finder';
import { ref } from 'vue';

/**
 * Retrieves data from the `/organization/group_communications` index endpoint.
 */
export default function useGroupCommunications(groupId) {
  const query = ref({
    data: [],
    error: null,
    status: 'pending',
  });

  const getGroupCommunications = async () => {
    try {
      const response = await Api.organization.group_communications.index({
        group_communication: {
          group_id: groupId,
        },
      });

      query.value.status = 'success';
      query.value.data = response.data;
    } catch (error) {
      query.value.status = 'error';
      query.value.error = error;
    }
  };

  getGroupCommunications();

  return query;
}
