<template>
  <v-container
    v-if="site_template"
    fluid
  >
    <v-row class="mb-1">
      <v-col>
        <div
          @click="$router.push({ name: 'SiteTemplateIndex' })"
          class="fw-600 bc-h5 d-inline-block me-2 bc-link-hover"
        >
          Templates
        </div>
        <div class="d-inline-block me-2">
          <v-icon class="fs-26 c-black va-sub"> chevron_right </v-icon>
        </div>
        <div
          v-text="site_template.name"
          class="fw-600 bc-h5 d-inline-block me-2"
        />
      </v-col>
    </v-row>
    <SiteTemplateForm
      @refresh="$emit('refresh')"
      :site_template="site_template"
      class="mb-4"
    />
    <SiteTemplateConfig
      :site_template="site_template"
      class="mb-4"
    />
    <SiteTemplateDanger :site_template="site_template" />
  </v-container>
</template>

<script>
import SiteTemplateConfig from '@/admin/components/site-templates/SiteTemplateConfig.vue';
import SiteTemplateDanger from '@/admin/components/site-templates/SiteTemplateDanger.vue';
import SiteTemplateForm from '@/admin/components/site-templates/SiteTemplateForm.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    SiteTemplateConfig,
    SiteTemplateDanger,
    SiteTemplateForm,
  },

  props: {
    // eslint-disable-next-line vue/prop-name-casing
    site_template: {
      type: Object,
      default: null,
    },
  },

  emits: ['refresh'],
};
</script>
