<template>
  <span>
    <template v-if="appliedLabels.length == 0">
      <v-btn
        @click="$emit('edit')"
        :aria-label="$t('Add label')"
        :disabled="disabled"
        size="small"
        variant="text"
        icon
      >
        <v-icon>add_circle</v-icon>
      </v-btn>
    </template>
    <v-tooltip
      v-for="label in appliedLabels"
      v-else
      :key="label.name"
      :color="label.color || 'grey'"
      location="bottom"
      center
    >
      <template #activator="{ props }">
        <v-btn
          @click="$emit('edit')"
          v-bind="props"
          :aria-label="$t('Edit labels')"
          :disabled="disabled"
          max-height="28"
          max-width="28"
          variant="text"
          icon
        >
          <v-avatar
            :color="label.color || 'grey'"
            size="20"
          />
        </v-btn>
      </template>
      <span>{{ label.name }}</span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    appliedLabels: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
  },

  emits: ['edit'],
};
</script>
