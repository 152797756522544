<template>
  <div v-if="subsidyProgram && groupSchema && childSchema">
    <BreadcrumbTitle
      :back-name="subsidyProgram.name"
      name="Application"
    />

    <div class="py-4">
      <v-card
        class="mb-4"
        border
        flat
        tile
      >
        <v-card-text>
          <FormQuestion
            :subtitle="
              subsidyProgram.home_address_subtitle ||
              'Let\'s start with your family\'s primary home address.'
            "
            :title="
              subsidyProgram.home_address_title || 'Welcome! We\'ll make it fast and easy to apply.'
            "
            dense
            hide-actions
          >
            <v-row dense>
              <LabeledTextfield
                data-cy="address"
                message="Address"
              />
              <LabeledTextfield
                data-cy="city"
                md="4"
                message="City"
              />
              <LabeledSimpleSelect
                :items="[]"
                data-cy="state-input"
                md="4"
                message="State"
              />
              <LabeledTextfield
                data-cy="zip"
                mask="#####"
                md="4"
                message="Zip"
              />
            </v-row>
            <v-row
              v-if="subsidyProgram.enable_homeless_address_option"
              dense
            >
              <v-checkbox
                :label="
                  $t(
                    subsidyProgram.homeless_attestation ||
                      'My family is experiencing homelessness.',
                  )
                "
              />
            </v-row>
          </FormQuestion>
        </v-card-text>
      </v-card>

      <v-card
        class="mb-4"
        border
        flat
        tile
      >
        <v-card-text>
          <FormQuestion
            :subtitle="subsidyProgram.child_subtitle"
            :title="subsidyProgram.child_title || 'Add your children'"
            dense
            hide-actions
          >
            <SubsidyChildren
              @change="children = $event"
              :children="[]"
              :subsidies="[]"
              staged
            />
          </FormQuestion>
        </v-card-text>
      </v-card>
    </div>

    <v-row class="mb-4">
      <v-col class="ta-right">
        <ActionMenu
          @click:action:applicant="role = 'Applicant'"
          @click:action:reviewer="role = 'Reviewer'"
          :button-title="'Viewing as ' + role"
          :items="roles"
          button-color="primary"
          button-icon="expand_more"
          button-icon-side="right"
          button-outlined
        />
      </v-col>
    </v-row>

    <template v-if="role == 'Applicant'">
      <v-expansion-panels
        v-model="openPanels"
        class="mb-12"
        variant="accordion"
        flat
        multiple
        tile
      >
        <QuestionList
          @cancel="load"
          @change="load"
          @edit="edit($event)"
          @new="$refs.newQuestionDialog.open('Group', $event, true)"
          @settings="settings($event)"
          :processing="processing"
          :questions="groupEligibilityQuestions"
          :schemas="[groupSchema, childSchema]"
          :sections="false"
          :selected-question="selectedQuestion"
          title="Family eligibility questions"
          eligibility
        />

        <QuestionList
          @cancel="load"
          @change="load"
          @edit="edit($event)"
          @new="$refs.newQuestionDialog.open('Child', $event, true)"
          @settings="settings($event)"
          :processing="processing"
          :questions="childEligibilityQuestions"
          :schemas="[groupSchema, childSchema]"
          :sections="false"
          :selected-question="selectedQuestion"
          title="Child eligibility questions"
          eligibility
        />

        <QuestionList
          @cancel="load"
          @change="load"
          @edit="edit($event)"
          @new="$refs.newQuestionDialog.open('Group', $event)"
          @settings="settings($event)"
          :processing="processing"
          :questions="preferenceSelectionEnabled ? generalGroupQuestions : groupQuestions"
          :schemas="[groupSchema, childSchema]"
          :sections="false"
          :selected-question="selectedQuestion"
          data-testid="general-group-section"
          title="General family questions"
          eligibility
        />

        <QuestionList
          @cancel="load"
          @change="load"
          @edit="edit($event)"
          @new="$refs.newQuestionDialog.open('Child', $event)"
          @settings="settings($event)"
          :processing="processing"
          :questions="preferenceSelectionEnabled ? generalChildQuestions : childQuestions"
          :schemas="[groupSchema, childSchema]"
          :sections="false"
          :selected-question="selectedQuestion"
          data-testid="general-child-section"
          title="General child questions"
          eligibility
        />

        <template v-if="preferenceSelectionEnabled">
          <QuestionList
            @cancel="load"
            @change="load"
            @edit="edit($event)"
            @new="$refs.newQuestionDialog.open('Group', $event, false, false, true)"
            @settings="settings($event)"
            :processing="processing"
            :questions="postPreferenceSelectionGroupQuestions"
            :schemas="[groupSchema, childSchema]"
            :sections="false"
            :selected-question="selectedQuestion"
            data-testid="post-preference-selection-group-section"
            title="Post preference selection family questions"
            eligibility
          />
          <QuestionList
            @cancel="load"
            @change="load"
            @edit="edit($event)"
            @new="$refs.newQuestionDialog.open('Child', $event, false, false, true)"
            @settings="settings($event)"
            :processing="processing"
            :questions="postPreferenceSelectionChildQuestions"
            :schemas="[groupSchema, childSchema]"
            :sections="false"
            :selected-question="selectedQuestion"
            data-testid="post-preference-selection-child-section"
            title="Post preference selection child questions"
            eligibility
          />
        </template>
      </v-expansion-panels>
    </template>

    <template v-if="role == 'Reviewer'">
      <div
        v-for="(section, sectionIndex) in subsidyProgram.reviewer_question_sequence"
        :key="sectionIndex"
      >
        <v-card
          class="mb-4"
          border
          flat
          tile
        >
          <v-card-title>
            <v-row class="d-flex align-center">
              <v-col>
                <span
                  @click="
                    $refs.renameSectionDialog.open({ title: section.title, index: sectionIndex })
                  "
                  v-text="section.title"
                  class="hover-underlined pointer"
                />
              </v-col>
              <v-col class="ta-right">
                <v-btn
                  @click="deleteSection(sectionIndex)"
                  variant="text"
                  icon
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>

        <LinearQuestionList
          @cancel="load"
          @change="load"
          @edit="edit($event)"
          @move="move(sectionIndex, $event)"
          @new="$refs.newQuestionDialog.open(null, $event, true)"
          @settings="settings($event)"
          :processing="processing"
          :questions="sortLinearQuestions(questions, section.questions)"
          :schemas="[groupSchema, childSchema]"
          :selected-question="selectedQuestion"
        />
      </div>
      <v-btn
        @click="$refs.addSectionDialog.open({})"
        color="primary"
      >
        <span v-t="'Add section'" />
      </v-btn>
    </template>

    <ResourceDialog
      @save="addSection"
      ref="addSectionDialog"
      :fields="[{ text: 'Title', value: 'title' }]"
      :processing="processing"
      title="Add section"
    />

    <ResourceDialog
      @save="renameSection"
      ref="renameSectionDialog"
      :fields="[{ text: 'Title', value: 'title' }]"
      :processing="processing"
      title="Rename section"
    />

    <NewQuestionDialog
      @created="load"
      ref="newQuestionDialog"
      :owner-id="subsidyProgram.id"
      :schemas="[groupSchema, childSchema]"
      owner-type="SubsidyProgram"
    />

    <ProcessingOverlay :processing="processing" />
  </div>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import LinearQuestionList from '@/admin/components/LinearQuestionList.vue';
import NewQuestionDialog from '@/shared/components/form/NewQuestionDialog.vue';
import QuestionList from '@/admin/components/QuestionList.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SubsidyChildren from '@/parent/components/subsidy/SubsidyChildren.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import ProcessingOverlay from '@/shared/components/ProcessingOverlay.vue';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import useSubsidy from '@/shared/composables/useSubsidy';
import { useRoute } from 'vue-router';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();
const route = useRoute();

const changed = ref(false);
const openPanels = ref([0, 1, 2, 3, 4, 5]);
const processing = ref(false);
const role = ref('Applicant');
const selectedQuestion = ref(null);
const subsidyProgram = ref(null);
const {
  childEligibilityQuestions,
  childQuestions,
  childSchema,
  generalChildQuestions,
  generalGroupQuestions,
  groupEligibilityQuestions,
  groupQuestions,
  groupSchema,
  loadQuestions,
  loadSchemas,
  postPreferenceSelectionChildQuestions,
  postPreferenceSelectionGroupQuestions,
  questions,
  sortLinearQuestions,
} = useSubsidy({ subsidyProgram, includeUnpublished: true });

const addSectionDialog = ref(null);
const renameSectionDialog = ref(null);
const detailsDrawer = ref(null);

const roles = computed(() => {
  return [
    {
      event: 'applicant',
      title: 'Applicant',
      action: role.value === 'Applicant' ? 'check' : null,
    },
    { event: 'reviewer', title: 'Reviewer', action: role.value === 'Reviewer' ? 'check' : null },
  ];
});

const preferenceSelectionEnabled = computed(() => {
  return (
    subsidyProgram.value.allow_enrolled ||
    subsidyProgram.value.allow_preference ||
    subsidyProgram.value.allow_program_preference
  );
});

onMounted(async () => {
  await load();
});

function addSection(newVal) {
  processing.value = true;
  subsidyProgram.value.reviewer_question_sequence.push({ title: newVal.title, questions: [] });
  saveReviewerSequence();
}

function deleteSection(sectionIndex) {
  if (subsidyProgram.value.reviewer_question_sequence[sectionIndex].questions.length > 0) {
    eventBus.chime('You must remove all questions from a section before you can remove it.');
    return;
  }

  if (confirm('Are you sure you want to remove this section')) {
    subsidyProgram.value.reviewer_question_sequence.splice(sectionIndex, 1);
    saveReviewerSequence();
  }
}

function edit(question) {
  selectedQuestion.value = question;
}

async function load() {
  changed.value = false;

  processing.value = true;

  selectedQuestion.value = null;

  await loadSubsidyProgram();
  await loadQuestions(() => {
    processing.value = false;
  });
}

async function loadSubsidyProgram() {
  const resp = await Api.organization.subsidy_program.get(route.params.subsidyProgramId);

  if (resp.data) {
    subsidyProgram.value = resp.data;
    await loadSchemas();
  }
}

function move(sectionIndex, event) {
  const fromIndex = event[0];
  const direction = event[1];
  const currentSection = subsidyProgram.value.reviewer_question_sequence[sectionIndex];
  const movingQuestion = currentSection.questions[fromIndex];
  const newQuestionIndex = fromIndex + direction;
  if (newQuestionIndex >= currentSection.questions.length) {
    const nextSection = subsidyProgram.value.reviewer_question_sequence[sectionIndex + 1];
    if (nextSection) {
      currentSection.questions.splice(fromIndex, 1);
      nextSection.questions.unshift(movingQuestion);
    }
  } else if (newQuestionIndex < 0) {
    const previousSection = subsidyProgram.value.reviewer_question_sequence[sectionIndex - 1];
    if (previousSection) {
      currentSection.questions.splice(fromIndex, 1);
      previousSection.questions.push(movingQuestion);
    }
  } else {
    currentSection.questions.splice(fromIndex, 1);
    currentSection.questions.splice(newQuestionIndex, 0, movingQuestion);
  }
  saveReviewerSequence();
}

function renameSection(newVal) {
  subsidyProgram.value.reviewer_question_sequence[newVal.index].title = newVal.title;
  saveReviewerSequence();
}

function saveReviewerSequence() {
  processing.value = true;
  Api.organization.subsidy_program.update(
    route.params.subsidyProgramId,
    { reviewer_question_sequence: subsidyProgram.value.reviewer_question_sequence },
    (resp) => {
      processing.value = false;
      addSectionDialog.value.close();
      renameSectionDialog.value.close();
      subsidyProgram.value = resp.data;
      changed.value = false;
    },
    (err) => {
      processing.value = false;
      eventBus.chime(err.response.data.errors[0]);
    },
  );
}

function settings(question) {
  selectedQuestion.value = question;
  detailsDrawer.value.open();
}
</script>
