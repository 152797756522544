<template>
  <v-container
    v-if="brands"
    class="mxw-1200 pa-6"
  >
    <TitleBar
      @create="$router.push({ name: 'BrandNew' })"
      create-text="Create brand"
      name="Brands"
      createable
      dense
    />

    <v-row
      v-for="brand in brands"
      :key="brand.id"
    >
      <v-col>
        <v-card
          @click="$router.push({ name: 'BrandShow', params: { brandId: brand.id } })"
          :ripple="false"
          class="px-4 py-4"
          border
          flat
          tile
        >
          <v-row dense>
            <v-col class="c-black fs-20 fw-500">
              {{ brand.name }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-img
                :src="brand.logo_url"
                class="d-inline-block me-4"
                height="100"
                max-width="200"
              />
              <div :style="getBrandColorStyles(brand.primary_color)" />
              <div :style="getBrandColorStyles(brand.secondary_color)" />
              <div :style="getBrandColorStyles(brand.accent_color)" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <NullState
      v-if="brands.length === 0"
      title="You do not have any brands."
    />
  </v-container>
</template>

<script setup>
import Api from '@/admin/services/bright_finder';
import NullState from '@/shared/components/NullState.vue';
import TitleBar from '@/shared/components/TitleBar.vue';

const brands = ref(null);

onMounted(() => {
  void loadBrands();
});

async function loadBrands() {
  const response = await Api.organization.brand.index();
  brands.value = response.data;
}

function getBrandColorStyles(color) {
  return `border-radius: 8px; margin-right: 10px; display: inline-block; height: 100px; width: 100px; background-color: ${color};`;
}
</script>
