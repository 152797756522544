<template>
  <ResourceDialog
    @save="saveReportFolder"
    ref="dialog"
    :processing="processing || internalProcessing"
    :save-button-disabled="!isFormValid"
    title="Report Folder"
  >
    <template #form="{ localValue }">
      <LabeledTextfield
        v-model="localValue.name"
        :handle-change="setIsFormValid()"
        message="Name"
        placeholder="Type the report name here"
        mandatory
      />
      <v-divider class="my-4" />
      <LabeledAutocomplete
        v-model="reportSearchStringModel"
        @input="(reportId) => addReport(reportId)"
        @update:search="(query) => fetchUnassociatedReports(query)"
        :items="getUnassociatedReports()"
        :loading="internalProcessing"
        item-title="name"
        item-value="id"
        message="Reports"
        placeholder="Type the name of a report here"
      />
      <div class="px-3">
        <v-chip-group column>
          <v-chip
            v-for="report in localValue.reports"
            @click:close="removeReport(report.id)"
            :key="report.id"
            class="mr-2 mt-3"
            closable
            pill
          >
            {{ report.name }}
          </v-chip>
        </v-chip-group>
        <p
          v-if="shouldShowNoReportsMessage()"
          class="text-body-1"
        >
          No reports associated with this folder. Type the name of a report above and click on it to
          add one.
        </p>
      </div>
      <v-divider class="my-4" />
      <LabeledAutocomplete
        v-model="teamSearchStringModel"
        @input="(teamId) => addTeam(teamId)"
        @update:search="(query) => fetchTeams(query)"
        :items="getUnassociatedTeams()"
        :loading="internalProcessing"
        item-title="name"
        item-value="id"
        message="Teams"
        placeholder="Type the name of a team here"
      />
      <div class="px-3">
        <v-chip-group column>
          <v-chip
            v-for="team in localValue.teams"
            @click:close="removeTeam(team.id)"
            :key="team.id"
            class="mr-2 mt-3"
            closable
            pill
          >
            {{ team.name }}
          </v-chip>
        </v-chip-group>
        <p
          v-if="shouldShowNoTeamsMessage()"
          class="text-body-1"
        >
          No teams associated with this folder. Type the name of a team above and click on it to add
          one.
        </p>
      </div>
    </template>
  </ResourceDialog>
</template>

<script>
import _ from 'lodash';

import Api from '@/specialist/services/bright_finder';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    LabeledAutocomplete,
    LabeledTextfield,
    ResourceDialog,
  },

  props: {
    afterSave: {
      type: Function,
      default: () => {},
    },
    api: {
      type: Object,
      default: () => Api,
    },
    processing: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return this.getInitialData();
  },

  async created() {
    this.fetchUnassociatedReports = _.debounce(async (query) => {
      if (query) {
        this.internalProcessing = true;
        this.unassociatedReports = (
          await this.api.organization.report.index({ query, scoped_to_folder: false })
        )?.data;
        this.internalProcessing = false;
      }
    }, 250);
    this.fetchTeams = _.debounce(async (query) => {
      if (query) {
        this.internalProcessing = true;
        this.teams = (await this.api.organization.team.index({ query }))?.data;
        this.internalProcessing = false;
      }
    }, 250);
  },

  methods: {
    async addReport(reportId) {
      if (_.isEmpty(reportId)) return;
      // There's a bug causing this callback to be called twice, so we have to remove the duplicate ID that's
      // being added when this function is called the second time. The bug seems like it has something to do with
      // a watcher triggering an update, which causes this function to be called again. However, after some digging
      // I still wasn't able to figure out what the problem was with our watchers, so rather than burn a ton of time,
      // I opted to just remove the duplicate for when it's called a second time since we're not making any API calls
      // until the save button is clicked in the dialog.
      const newReport = (await this.api.organization.report.get(reportId))?.data;
      const reports = _.uniqBy([...this.getLocalValue().reports, newReport], 'id');
      this.$refs.dialog.setLocalValue({
        ...this.getLocalValue(),
        reports,
      });
      this.reportSearchStringModel = null;
    },

    async addTeam(teamId) {
      if (_.isEmpty(teamId)) return;
      const newTeam = (await this.api.organization.team.get(teamId))?.data;
      // There's a bug causing this callback to be called twice, so we have to remove the duplicate ID that's
      // being added when this function is called the second time. The bug seems like it has something to do with
      // a watcher triggering an update, which causes this function to be called again. However, after some digging
      // I still wasn't able to figure out what the problem was with our watchers, so rather than burn a ton of time,
      // I opted to just remove the duplicate for when it's called a second time since we're not making any API calls
      // until the save button is clicked in the dialog.
      const teams = _.uniqBy([...this.getLocalValue().teams, newTeam], 'id');
      this.$refs.dialog.setLocalValue({
        ...this.getLocalValue(),
        teams,
      });
    },

    close() {
      this.$refs.dialog?.close();
    },

    getInitialData() {
      return {
        dialog: null,
        folder: null,
        internalProcessing: false,
        isFormValid: false,
        reportSearchStringModel: null,
        teams: [],
        teamSearchStringModel: null,
        unassociatedReports: [],
      };
    },

    getLocalValue() {
      return _.defaults(this.$refs?.dialog?.getLocalValue(), {
        name: null,
        reports: [],
        teams: [],
      });
    },

    getUnassociatedReports() {
      return _.without(this.unassociatedReports, (report) =>
        _.find(this.getLocalValue().reports, { id: report.id }),
      );
    },

    getUnassociatedTeams() {
      return _.without(this.teams, (team) => _.find(this.getLocalValue().teams, { id: team.id }));
    },

    isUpdating() {
      return !_.isNil(this.getLocalValue().id);
    },

    async open(folder) {
      this.resetData();
      this.$refs.dialog.open(folder);
    },

    removeReport(reportId) {
      const reports = _.filter(this.getLocalValue().reports, (report) => report.id !== reportId);
      this.$refs.dialog.setLocalValue({
        ...this.getLocalValue(),
        reports,
      });
      this.reportSearchStringModel = null;
    },

    removeTeam(teamId) {
      if (_.isEmpty(teamId)) return;

      // There's a bug causing this callback to be called twice, so we have to remove the duplicate ID that's
      // being added when this function is called the second time. The bug seems like it has something to do with
      // a watcher triggering an update, which causes this function to be called again. However, after some digging
      // I still wasn't able to figure out what the problem was with our watchers, so rather than burn a ton of time,
      // I opted to just remove the duplicate for when it's called a second time since we're not making any API calls
      // until the save button is clicked in the dialog.
      const teams = _.filter(this.getLocalValue().teams, (team) => team.id !== teamId);
      this.$refs.dialog.setLocalValue({
        ...this.$refs.dialog.getLocalValue(),
        teams,
      });
      this.teamSearchStringModel = null;
    },

    resetData() {
      const dataKeys = _.keys(this.getInitialData());
      const initialData = this.getInitialData();
      _.each(dataKeys, (key) => {
        this[key] = initialData[key];
      });
    },

    async saveReportFolder() {
      try {
        this.internalProcessing = true;
        if (this.isUpdating()) {
          await this.api.organization.reportFolder.update(this.getLocalValue());
        } else {
          await this.api.organization.reportFolder.create(this.getLocalValue());
        }
        await this.afterSave();
        this.close();
      } finally {
        this.internalProcessing = false;
      }
    },

    setIsFormValid() {
      this.isFormValid = !_.isNil(this.getLocalValue().name);
    },

    shouldShowNoReportsMessage() {
      return this.getLocalValue().reports.length === 0 && this.isUpdating();
    },

    shouldShowNoTeamsMessage() {
      return this.getLocalValue().teams.length === 0 && this.isUpdating();
    },
  },
};
</script>
