<template>
  <ResourceDialog
    @cancel="reset"
    @save="emit('save', draft)"
    ref="gradeDialog"
    :title="learner?.attributes.name"
    close-on-save
    closeable
  >
    <template #form>
      <template v-if="loaded && !processing">
        <AttachmentList
          :attachments="[attachment]"
          :processing="processing"
          hide-remove
        />

        <v-row dense>
          <v-radio-group
            v-model="draft.homework_file_upload_passed"
            class="ps-4"
            inline
          >
            <v-radio
              :label="$t('Ungraded')"
              :value="null"
              class="c-black mt-0 mb-1"
              color="primary"
            />

            <v-radio
              :label="$t('Pass')"
              :value="true"
              class="c-black mt-0 mb-1"
              color="primary"
            />

            <v-radio
              :label="$t('Fail')"
              :value="false"
              class="c-black mt-0 mb-1"
              color="primary"
            />
          </v-radio-group>
        </v-row>
      </template>
    </template>
  </ResourceDialog>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

const emit = defineEmits(['save']);

defineExpose({ open });

const props = defineProps({
  assignment: {
    type: Object,
    default: null,
  },
});

const attachment = ref(null);
const completion = ref(null);
const draft = reactive({});
const gradeDialog = ref(null);
const learner = ref(null);
const processing = ref(false);

const loaded = computed(() => {
  return props.assignment && attachment.value && draft;
});

async function loadAttachment() {
  const response = await Api.organization.attachment.get(completion.value.homework_attachment_id);
  attachment.value = response?.data;
}

async function open(dialogLearner, dialogCompletion) {
  processing.value = true;

  learner.value = dialogLearner;
  completion.value = dialogCompletion;

  reset();
  await loadAttachment();
  gradeDialog.value.open();

  processing.value = false;
}

function reset() {
  Object.assign(draft, JSON.parse(JSON.stringify(completion.value || {})));
}
</script>
