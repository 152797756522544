<template>
  <v-card
    class="pa-0"
    elevation="0"
  >
    <v-card-text>
      <v-row>
        <v-col class="fs-16 c-black fw-600"> Who are you searching for? </v-col>
      </v-row>

      <v-row>
        <v-col class="pb-2">
          <v-autocomplete
            v-model="group"
            v-model:search="query"
            :items="groups"
            item-title="name"
            item-value="id"
            label="Client"
            prepend-inner-icon="people_alt"
            variant="outlined"
            hide-details
            hide-no-data
            return-object
          />
          <div class="pt-2 ta-right">
            <a
              @click="group = query = null"
              class="c-primary underlined fs-14"
              color="primary"
              href="javascript:"
              >Clear</a
            >
          </div>
        </v-col>
      </v-row>

      <div v-if="value && value.id">
        <v-row class="bt-1 bc-very-light-gray mt-3">
          <v-col>
            <div class="c-black fs-16 fw-600 mb-1">Contact Information</div>
            <div
              v-text="value.primary_phone || 'No phone listed.'"
              class="bc-text-body c-black mb-1"
            />
            <div
              v-text="value.primary_email || 'No email listed.'"
              class="bc-text-body c-black"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="c-black fs-16 fw-600 mb-1">Hours of care needed</div>
            <div v-if="value.meta && value.meta.hours_summary">
              <div
                v-for="(summary, index) in value.meta.hours_summary"
                v-text="summary"
                :key="index"
                class="bc-text-body c-black mb-1"
              />
            </div>
            <div v-else>
              <div class="bc-text-body c-black mb-1">No care schedule specified.</div>
            </div>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <div class="c-black fs-16 fw-600 mb-1">Additional information</div>
            <div
              v-text="value.notes || 'No additional information provided.'"
              class="c-black bc-text-body"
            />
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import Api from '@/specialist/services/bright_finder';

export default {
  compatConfig: { MODE: 2 },

  props: {
    value: {
      type: Object,
      default: null,
    },
  },

  emits: ['change'],

  data() {
    return {
      query: null,
      group: this.value,
      groups: [],
    };
  },

  watch: {
    query() {
      this.loadGroups();
    },

    group() {
      this.$emit('change', this.group);
    },
  },

  created() {
    this.loadGroups();
  },

  methods: {
    loadGroups() {
      const vm = this;
      const params = { order: 'updated' };
      if (vm.query) {
        params.query = vm.query;
      }
      Api.organization.group.index(params, (resp) => {
        vm.groups = resp.data;
      });
    },
  },
};
</script>
