<template>
  <v-card
    :ripple="false"
    class="ma-6"
    border
  >
    <v-row class="pa-2 px-6">
      <v-col
        class="d-flex align-center"
        cols="6"
      >
        <div>
          <v-card-subtitle class="pl-0 pb-0 text-h7 font-weight-light">
            {{ $t('Section') }} {{ index }}
          </v-card-subtitle>

          <v-card-title class="pl-0 pt-0 text-h6 font-weight-black">
            {{ $t(lesson?.name) }}
          </v-card-title>
        </div>
      </v-col>

      <v-col
        class="d-flex align-center justify-end"
        cols="6"
      >
        <div class="mr-5 fs-15">
          <div v-if="cohortLesson?.attributes?.start_date">
            <span class="fw-600">{{ $t('Starts') }}: </span>

            <LongDate
              :date="cohortLesson.attributes.start_date"
              short-month
            />
          </div>

          <div v-if="cohortLesson?.attributes?.end_date">
            <span class="fw-600"> {{ $t('Ends') }}: </span>

            <LongDate
              :date="cohortLesson.attributes.end_date"
              short-month
            />
          </div>
        </div>

        <v-btn
          @click="$emit('open', { cohortLesson, lesson })"
          icon="more_vert"
          variant="text"
        />
      </v-col>
    </v-row>

    <v-divider />

    <v-card-text>
      <v-card
        v-for="assignment in assignments"
        :key="assignment.id"
        :to="{
          name: 'CohortCourseLesson',
          params: {
            assignmentId: assignment.id,
            cohortId: cohort.id,
            courseId: course.id,
            lessonId: lesson?.id,
          },
        }"
        class="mb-3 pa-4 rounded bcf-grey-lightest"
        color=""
        border
      >
        <div class="d-flex">
          <div class="mr-auto align-self-center">
            {{ $t(assignment.name) }}
          </div>

          <v-icon
            icon="chevron_right"
            size="24"
          />
        </div>
      </v-card>

      <v-card
        v-if="quiz"
        class="mb-3 pa-4 rounded bcf-grey-lightest"
        border
      >
        <div class="d-flex">
          <div class="mr-auto align-self-center">
            <v-icon
              icon="quiz"
              size="24"
            />

            {{ quiz.name }}
          </div>
        </div>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import { useStore } from 'vuex';

const store = useStore();

const assignments = ref([]);
const quiz = ref(null);

const props = defineProps({
  cohort: {
    type: Object,
    default: null,
  },
  cohortLesson: {
    type: Object,
    default: null,
  },
  course: {
    type: Object,
    default: null,
  },
  index: {
    type: String,
    default: null,
  },
  lesson: {
    type: Object,
    default: null,
  },
});

defineEmits(['open']);

async function load() {
  const response = await Api.public_api.organization.assignment.index({
    lesson_id: props.lesson?.id,
  });
  assignments.value = response?.data;

  if (!props.lesson) return;

  store.commit('setAssignments', props.lesson.id, assignments.value);
  const quizSchemaId = props.lesson.quiz_schema_id;
  if (!quizSchemaId) return;

  Api.public_api.organization.schema.get(quizSchemaId, (schemaResponse) => {
    quiz.value = schemaResponse?.data;
  });
}

onMounted(load);
</script>
