<template>
  <v-container
    v-if="familySubsidyProgram && groupSchema && familySubsidySchema"
    class="mxw-1200"
  >
    <TitleBar
      :back-name="familySubsidyProgram.name"
      :back-route="{ name: 'FamilySubsidyProgramShow' }"
      name="Eligibility"
    >
      <template #actions>
        <v-btn
          @click="updateQuery({ action: 'new' })"
          color="primary"
        >
          <span v-t="'New'" />
        </v-btn>
      </template>
    </TitleBar>

    <NullState
      v-if="fundingSources.length == 0"
      @new="draftFundingSource"
    />

    <v-row
      v-for="source in fundingSources"
      :key="source.id"
      class="mb-4"
    >
      <v-col>
        <FundingSourceCard
          @destroy="loadFundingSources"
          @move="reorder(source, $event)"
          :display-index="getDisplayPositionIndex(source)"
          :schemas="schemas"
          :source="source"
          :total-count="fundingSources.length"
          ordered
        />
      </v-col>
    </v-row>

    <ResourceDialog
      @save="createFundingSource"
      :fields="fundingSourceFields"
      :processing="processing"
      action="new"
      save-button-text="Create"
      title="New funding source"
    />
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import FundingSourceCard from '@/admin/components/FundingSourceCard.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import RouterHelper from '@/shared/mixins/RouterHelper';

const OWNER_TYPE = 'FamilySubsidyProgram';

export default {
  compatConfig: { MODE: 2 },

  components: {
    FundingSourceCard,
    NullState,
    ResourceDialog,
  },

  mixins: [API, RouterHelper],

  data() {
    return {
      groupSchema: null,
      familySubsidySchema: null,
      familySubsidyProgram: null,
      funds: [],
      fundingSources: [],
      newName: null,
      processing: false,
    };
  },

  computed: {
    fundingSourceFields() {
      return [
        { required: true, text: 'Name', value: 'name' },
        {
          required: true,
          text: 'Fund',
          value: 'fund_id',
          items: this.funds,
          itemText: 'name',
          itemValue: 'id',
        },
      ];
    },

    schemas() {
      return [this.groupSchema, this.familySubsidySchema];
    },
  },

  async created() {
    await this.loadFundingSources();
    await this.loadFamilySubsidyProgram();
    await this.loadSchemas();
    await this.loadFunds();
  },

  methods: {
    async createFundingSource(newVal) {
      this.processing = true;
      const resp = await this.api.organization.funding_source.create({
        ...newVal,
        owner_id: this.$route.params.id,
        owner_type: OWNER_TYPE,
      });
      this.processing = false;
      await this.updateQuery({ action: null });
      this.fundingSources.push(resp.data);
    },

    async draftFundingSource() {
      await this.updateQuery({ action: 'new' });
    },

    getDisplayPositionIndex(source) {
      if (source.display_order !== null) return source.display_order - 1;

      return this.fundingSources.indexOf(source);
    },

    async loadFunds() {
      const resp = await this.api.organization.fund.index({});
      this.funds = resp.data;
    },

    async loadFundingSources() {
      const resp = await this.api.organization.funding_source.index({
        owner_id: this.$route.params.id,
      });
      this.fundingSources = resp.data;
    },

    async loadSchemas() {
      let resp;
      resp = await this.api.organization.schema.get(this.familySubsidyProgram.group_schema_id);
      this.groupSchema = resp.data;
      resp = await this.api.organization.schema.get(
        this.familySubsidyProgram.family_subsidy_schema_id,
      );
      this.familySubsidySchema = resp.data;
    },

    async loadFamilySubsidyProgram() {
      const resp = await this.api.organization.family_subsidy_program.get(this.$route.params.id);
      this.familySubsidyProgram = resp.data;
    },

    async reorder(source, displayOrderChange) {
      const params = {
        display_order_was: source.display_order,
        display_order: this.getDisplayPositionIndex(source) + 1 + displayOrderChange,
      };

      await this.api.organization.funding_source.reorder(source.id, params);
      this.loadFundingSources();
    },
  },
};
</script>
