<template>
  <v-card
    border
    tile
  >
    <v-card-title>
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col cols="11">
          <span>
            {{ apiKey.name || 'Unnamed key' }}
          </span>
        </v-col>

        <v-col class="d-flex justify-end">
          <ActionMenu
            @click:action:delete="destroy"
            @click:action:edit="$emit('edit')"
            :items="[
              {
                avatar: 'edit',
                title: 'Edit',
                event: 'edit',
              },
              {
                avatar: 'delete',
                title: 'Delete',
                event: 'delete',
              },
            ]"
            button-icon="more_vert"
            x-small
          />
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider />

    <v-card-text>
      <span class="fs-14 c-light-black">
        <LongDate
          :date="apiKey.created_at"
          prefix="Created"
        />
      </span>

      <v-divider class="my-3" />

      <div>
        <span class="fs-16 me-2"> {{ $t('ID') }}: </span>

        <v-chip label>
          {{ apiKey.id }}
        </v-chip>
      </div>

      <v-divider class="my-3" />

      <div>
        <span class="fs-16 me-2"> {{ $t('Token') }}: </span>

        <template v-if="tokenRevealed">
          <v-chip label>
            {{ apiKey.token }}
          </v-chip>
        </template>

        <template v-else>
          <v-btn
            @click="tokenRevealed = true"
            color="primary"
            size="small"
            variant="outlined"
          >
            {{ $t('Reveal token') }}
          </v-btn>
        </template>
      </div>

      <v-divider class="my-3" />

      <v-row dense>
        <v-col class="fs-14">
          {{ $t('Permissions') }}
        </v-col>
      </v-row>

      <v-row
        class="mt-0"
        dense
      >
        <v-col>
          <template v-if="apiKey.permissions.length == 0">
            <v-chip color="red">
              {{ $t('No restrictions') }}
            </v-chip>
          </template>

          <v-chip
            v-for="permission in apiKey.permissions"
            :key="permission"
            class="me-2 mt-2"
          >
            <span>
              {{ $t(permission) }}
            </span>
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import Api from '@/admin/services/bright_finder';

const props = defineProps({
  apiKey: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['destroy', 'edit']);

const tokenRevealed = ref(false);

function destroy() {
  if (confirm('Are you sure you want to delete this API Key? This cannot be undone.')) {
    Api.admin.api_key.destroy(props.apiKey.id, () => emit('destroy'));
  }
}
</script>
