<template>
  <v-container
    v-if="loaded"
    class="mxw-1200 pa-6"
  >
    <v-row class="mb-3">
      <v-col>
        <div class="fw-600 bc-h5">Terms</div>
      </v-col>
    </v-row>

    <SchemaForm
      v-model="terms"
      @input="handleInput"
      :schema="generalTermsFormSchema"
      class="mb-8"
      title="General terms"
      flat
      outlined
    />

    <SchemaForm
      v-model="terms"
      @input="handleInput"
      :schema="enrollmentStatusTermsFormSchema"
      class="mb-8"
      title="System statuses"
      flat
      outlined
    />

    <SaveBar
      @cancel="loadTerms"
      @save="save"
      :model-value="changed"
      :processing="processing"
    />
  </v-container>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import SchemaForm from '@/admin/components/schemas/SchemaForm.vue';
import enrollmentStatusTermsFormSchema from '@/admin/services/enrollment-status-terms-form-schema';
import generalTermsFormSchema from '@/admin/services/general-terms-form-schema';
import SaveBar from '@/shared/components/form/SaveBar.vue';

const changed = ref(false);
const loaded = ref(false);
const terms = reactive({});
const processing = ref(false);

async function loadTerms() {
  const { data } = await api.admin.organization.get();
  Object.assign(terms, data.terms);
  loaded.value = true;
  changed.value = false;
}

async function save() {
  processing.value = true;
  const resp = await api.admin.organization.update({ terms: terms });
  processing.value = false;
  if (resp.status !== 200) return;

  Object.assign(terms, resp.data.terms);
  changed.value = false;
}

function handleInput() {
  changed.value = true;
}

onMounted(loadTerms);
</script>
