<template>
  <v-card
    class="mb-4"
    data-testid="eventAlert"
    border
    flat
    tile
  >
    <v-card-text>
      <v-row class="mb-3">
        <v-col
          class="fs-12 fw-400"
          cols="5"
        >
          ID: {{ eventAlertConfiguration.id }}
        </v-col>
        <LabeledSwitch
          v-model="eventAlertConfiguration.enabled"
          cols="2"
          data-testid="eventAlert.enabled"
          title="Enabled"
        />
        <LabeledSwitch
          v-model="eventAlertConfiguration.require_condition_consistency"
          cols="5"
          data-testid="eventAlert.requireConditionConsistency"
          title="Send if conditions have not changed"
        />
      </v-row>

      <v-row class="mb-3">
        <LabeledSimpleSelect
          v-model="eventAlertConfiguration.site_id"
          :items="sites"
          cols="4"
          item-title="name"
          item-value="id"
          message="Site"
        />
        <LabeledSimpleSelect
          v-model="eventAlertConfiguration.event_name"
          :items="owner.event_names"
          cols="4"
          message="Event name"
        />
        <LabeledSimpleSelect
          v-model="eventAlertConfiguration.event_value"
          :disabled="!isEventNameSelected"
          :items="eventValueOptions"
          cols="4"
          message="Event value"
        />
      </v-row>

      <v-row class="mb-3">
        <LabeledSimpleSelect
          v-model="eventAlertConfiguration.recipient_role"
          :items="recipientRoleOptions"
          cols="4"
          message="Recipient role"
        />
        <LabeledAutocomplete
          v-model="eventAlertConfiguration.report_id"
          @update:search="searchReports"
          :items="reportOptions"
          :multiple="false"
          :tooltip-text="REPORT_TOOLTIP_TEXT"
          cols="4"
          item-title="name"
          item-value="id"
          message="Report filter"
          placeholder="Type a report name..."
          clearable
          filled
        />
        <LabeledSimpleSelect
          v-model="eventAlertConfiguration.time_delay"
          :items="timeDelayOptions"
          cols="4"
          item-title="key"
          item-value="value"
          message="Time delay"
        />
      </v-row>

      <v-card border>
        <v-card-text>
          <v-row>
            <LabeledTextfield
              v-model="eventAlertConfiguration.email_subject"
              message="Subject"
            />
            <LabeledTextarea
              v-model="eventAlertConfiguration.email_body"
              data-cy="event-alert_email-body"
              message="Body"
            />
            <LabeledTextarea
              v-model="eventAlertConfiguration.text_message"
              message="Text message"
            />
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>

    <v-card-actions>
      <v-btn
        @click="destroyEventAlertConfiguration()"
        color="red"
        variant="text"
      >
        {{ $t('Delete') }}
      </v-btn>

      <v-spacer />

      <v-btn
        @click="saveEventAlertConfiguration()"
        :disabled="!isFormValid"
        color="primary"
      >
        <span data-testid="save">{{ $t('Save') }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/specialist/services/bright_finder';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import useEventBus from '@/shared/composables/useEventBus';

const REPORT_TOOLTIP_TEXT =
  'Reports must contain Member.ID to be associated with an event alert. ' +
  'If you do not see your report, ensure that it is configured correctly.';

const eventBus = useEventBus();
const eventAlertConfiguration = defineModel();

const props = defineProps({
  owner: {
    type: Object,
    required: true,
  },
  sites: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['updated']);

const associatedReport = ref(null);
const reports = ref([]);

onMounted(async () => {
  if (eventAlertConfiguration.value.report_id) {
    const { data } = await Api.organization.report.get(eventAlertConfiguration.value.report_id);
    associatedReport.value = data;
  }
});

watch(
  () => eventAlertConfiguration.value.report_id,
  (newVal) => {
    if (newVal) {
      associatedReport.value = reports.value.find((report) => report.id === newVal);
    } else {
      associatedReport.value = null;
    }
  },
);

const eventValueOptions = computed(() => {
  return props.owner.event_values[eventAlertConfiguration.value.event_name];
});

const isEventNameSelected = computed(() => {
  return !_.isEmpty(eventAlertConfiguration.value.event_name);
});

const isFormValid = computed(() => {
  const trimmedEmailBody = eventAlertConfiguration.value?.email_body?.trim();
  return !_.isEmpty(trimmedEmailBody) && !_.isEmpty(eventAlertConfiguration.value.recipient_role);
});

const recipientRoleOptions = computed(() => {
  return props.owner.recipient_roles[eventAlertConfiguration.value.event_name];
});

const reportOptions = computed(() => {
  if (!associatedReport.value) return reports.value;
  if (reports.value.find((report) => report.id === associatedReport.value.id)) return reports.value;
  return [associatedReport.value, ...reports.value];
});

const timeDelayOptions = computed(() => {
  return props.owner.time_delays || [];
});

async function destroyEventAlertConfiguration() {
  // eslint-disable-next-line no-alert
  if (confirm('Are you sure you want to remove this alert?')) {
    await Api.organization.eventAlertConfiguration.destroy(eventAlertConfiguration.value.id);
    eventBus.chime('Deleted');
    emit('updated');
  }
}

async function saveEventAlertConfiguration() {
  await Api.organization.eventAlertConfiguration.update(
    eventAlertConfiguration.value.id,
    eventAlertConfiguration.value,
  );
  eventBus.chime('Saved');
  emit('updated');
}

async function searchReports(query) {
  const { data } = await Api.organization.report.index({ query, textable: true });
  reports.value = data;
}
</script>
