<template>
  <v-container
    v-if="summaryConfigurations && schemas"
    class="mxw-1200 pa-6"
  >
    <v-row>
      <v-col>
        <h1
          v-t="'Summaries'"
          class="fw-600 bc-h5"
        />
      </v-col>
      <template v-if="summaryConfigurations.length > 0">
        <v-col class="ta-right">
          <v-btn
            @click="$refs.createDialog.open()"
            color="primary"
          >
            <span v-t="'New'" />
          </v-btn>
        </v-col>
      </template>
    </v-row>

    <template v-if="summaryConfigurations.length == 0">
      <NullState
        @new="$refs.createDialog.open()"
        title="You do not have any summaries"
        hide-new-button
      />
    </template>

    <v-row
      v-for="(summaryConfiguration, index) in summaryConfigurations"
      :key="index"
    >
      <v-col>
        <v-card
          border
          flat
          tile
        >
          <v-card-text>
            <v-row class="d-flex align-center">
              <v-col>
                <router-link
                  :to="{
                    name: 'SummaryConfigurationShow',
                    params: { id: summaryConfiguration.id },
                  }"
                >
                  <span v-text="summaryConfiguration.name" />
                </router-link>
              </v-col>
              <v-col class="d-flex justify-end">
                <v-btn
                  @click="destroy(summaryConfiguration)"
                  :aria-label="$t('Remove summary configuration')"
                  variant="text"
                  icon
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ResourceDialog
      @save="create"
      ref="createDialog"
      :fields="[
        { text: 'Name', value: 'name', required: true },
        {
          text: 'Schema',
          value: 'summary_schema_id',
          items: schemas,
          itemText: 'name',
          itemValue: 'id',
          required: true,
        },
      ]"
      title="New summary"
    />
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ResourceDialog,
  },

  mixins: [API],

  data() {
    return {
      summaryConfigurations: null,
      schemas: null,
    };
  },

  created() {
    this.load();
    this.loadSchemas();
  },

  methods: {
    async create(newVal) {
      await this.api.organization.summary_configuration.create(newVal);
      this.$refs.createDialog.close();
      this.load();
    },

    async destroy(summaryConfiguration) {
      // eslint-disable-next-line no-alert
      if (!confirm(`Are you sure you want to destroy ${summaryConfiguration.name}?`)) return;

      await this.api.organization.summary_configuration.destroy(summaryConfiguration.id);
      this.load();
    },

    async load() {
      this.summaryConfigurations = (
        await this.api.organization.summary_configuration.index({})
      ).data;
    },

    async loadSchemas() {
      this.schemas = (await this.api.organization.schema.index({ data_type: 'Summary' })).data;
    },
  },
};
</script>
