<template>
  <div v-if="group">
    <PageTitle>
      <TitleBar>
        <template #name>
          <span class="fs-18 fw-600 fs-24">{{ group.name }}</span>
          <span class="ms-4 fs-18 fw-500 c-light-black">#{{ group.external_id }}</span>
        </template>
        <template #actions>
          <template v-if="showSearchButton">
            <v-btn
              :disabled="searchDisabled"
              :to="{ name: 'ProviderSearch', query: { group_id: group.id } }"
              color="secondary"
              prepend-icon="search"
              variant="flat"
            >
              <template #prepend>
                <v-icon size="20" />
              </template>
              <span>{{ $t('New search') }}</span>
            </v-btn>
          </template>
          <template v-if="actions.length > 0">
            <ActionMenu
              @click:action:delete="destroy"
              :items="actions"
              button-class="ms-3"
              button-icon="more_vert"
              x-small
            />
          </template>
        </template>
      </TitleBar>
    </PageTitle>

    <v-toolbar class="bb-1">
      <v-tabs slider-color="primary">
        <v-tab
          v-for="(tabItem, index) in tabs"
          :key="index"
          :data-cy="['tab', tabItem[0]].join('-')"
          :to="{ query: { tab: tabItem[0] } }"
          class="c-black fs-16 ls-normal tt-none"
          exact
        >
          {{ $t(tabItem[1]) }}
        </v-tab>
      </v-tabs>
    </v-toolbar>

    <v-container
      class="bg-super-light-blue"
      fluid
    >
      <v-window v-model="tab">
        <v-window-item
          class="bg-transparent"
          value="profile"
        >
          <Profile
            @change="changed = true"
            :group="group"
          />
        </v-window-item>

        <v-window-item
          class="bg-transparent"
          value="applications"
        >
          <Applications
            :api="api"
            :group-id="group.id"
          />
        </v-window-item>

        <v-window-item
          class="bg-transparent"
          value="children"
        >
          <Children
            :api="api"
            :group-id="group.id"
          />
        </v-window-item>

        <v-window-item
          class="bg-transparent"
          value="members"
        >
          <MemberTable
            :api="api"
            :group-id="group.id"
          />
        </v-window-item>

        <v-window-item
          class="bg-transparent"
          value="documents"
        >
          <v-card
            data-testid="documents"
            border
            flat
            tile
          >
            <v-card-title>{{ $t('Documents') }}</v-card-title>
            <v-divider />
            <v-card-text>
              <AttachmentUploader
                v-if="canEdit"
                @uploaded="attachments.push($event)"
                ref="attachment_uploader"
                :owner="{ group_id: group.id }"
                class="mb-4"
              />
              <AttachmentList
                @delete="load"
                :attachments="attachments"
                :categories="['Enrollment', 'Verification', 'Billing', 'Other']"
                :readonly="!canEdit"
                confidential
              />
            </v-card-text>
          </v-card>
        </v-window-item>

        <v-window-item
          class="bg-transparent"
          value="notes"
        >
          <Notes :group="group" />
        </v-window-item>

        <v-window-item
          class="bg-transparent"
          value="activities"
        >
          <Activities :api="api" />
        </v-window-item>
      </v-window>
    </v-container>
  </div>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Activities from '@/specialist/components/group/Activities.vue';
import Applications from '@/specialist/components/group/Applications.vue';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import Children from '@/specialist/components/group/Children.vue';
import MemberTable from '@/specialist/components/group/MemberTable.vue';
import Notes from '@/specialist/components/group/Notes.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import Profile from '@/specialist/components/group/Profile.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();

const attachments = ref([]);
const changed = ref(false);
const group = ref(null);
const tab = ref(route.query.tab);

const actions = computed(() => {
  if (!group.value) return [];

  const actionArray = [];
  if (store.state.profile.org_groups_delete) {
    actionArray.push({ event: 'delete', avatar: 'delete', title: 'Delete family' });
  }
  return actionArray;
});

const canEdit = computed(() => {
  return store.state.profile.org_groups_edit;
});

const hasGroupsAccess = computed(() => {
  // Meant to restrict org_subsidies_agent role
  return store.state.profile.org_groups_access;
});

const searchDisabled = computed(() => {
  if (!hasGroupsAccess.value) return true;

  const hasPrimaryName = group.value.primary_first_name && group.value.primary_last_name;
  const hasAnAddress =
    group.value.formatted_home_address ||
    group.value.formatted_work_address ||
    group.value.formatted_other_address;

  return !hasPrimaryName || !hasAnAddress;
});

const showSearchButton = computed(() => {
  return store.state.pages.Group.features.enable_search && hasGroupsAccess.value;
});

const tabs = computed(() => {
  const tabOptions = [['profile', 'Profile']];
  if (store.state.profile.org_family_subsidies_access) {
    tabOptions.push(['applications', 'Applications']);
  }
  tabOptions.push(['children', 'Children']);
  tabOptions.push(['members', 'Members']);
  if (hasGroupsAccess.value) {
    tabOptions.push(['documents', 'Documents']);
    tabOptions.push(['notes', 'Notes']);
    tabOptions.push(['activities', 'Activities']);
  }

  return tabOptions;
});

watch(
  () => route.params.groupId,
  async () => {
    await load();
  },
  { immediate: true },
);

watch(
  () => route.query.tab,
  (newVal) => {
    tab.value = newVal;
  },
  { immediate: true },
);

onMounted(() => {
  router.push({ query: { tab: 'profile' } });
});

async function destroy() {
  if (!confirm('Are you sure you want to delete this family? This cannot be undone.')) return;

  const response = await api.organization.group
    .destroy(route.params.groupId)
    .catch((error) => eventBus.error(error));
  if (!response?.data) return;

  eventBus.chime('Deleted');
  router.replace({ name: 'GroupIndex' });
}

async function load() {
  loadGroup();
  await loadAttachments();
}

async function loadAttachments() {
  const params = {
    group_id: route.params.groupId,
  };
  const resp = await api.organization.attachment.index(params);
  if (resp?.status !== 200) return;

  attachments.value = resp.data;
}

function loadGroup() {
  api.organization.group.get(route.params.groupId, (resp) => {
    group.value = resp.data;
  });
}
</script>
