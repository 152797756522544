<template>
  <v-card
    v-if="metric"
    class="w-100pc h-100pc oy-auto"
    border
    tile
  >
    <v-card-title class="fs-20 fw-500 pb-4">
      <span>{{ metric.name }}</span>
    </v-card-title>

    <v-card-text class="no-drag">
      <div v-if="isCanvasChart">
        <canvas :id="chartId" />
      </div>

      <template v-else>
        <div
          v-if="metric.chart_type === 'count'"
          class="fs-32 fw-600"
        >
          <span>{{ metric.value }}</span>
        </div>

        <template v-if="metric.chart_type === 'sum'">
          <template v-if="metric.role">
            <template v-if="metric.role === 'currency-us'">
              <div class="fs-32 fw-600">
                {{ currency(metric.value) }}
              </div>
            </template>
          </template>

          <div
            v-else
            class="fs-32 fw-600"
          >
            {{ metric.value }}
          </div>
        </template>

        <div v-if="metric.chart_type === 'table'">
          <v-data-table
            :headers="tableHeaders"
            :items="metric.value"
          />
        </div>
      </template>
    </v-card-text>

    <div style="position: absolute; bottom: 10px; right: 10px">
      <v-icon
        v-if="!readonly"
        class="draggable-handle"
        icon="drag_indicator"
      />
    </div>
    <div style="position: absolute; top: 10px; right: 10px">
      <v-menu
        max-width="500"
        offset="5"
      >
        <template #activator="{ props }">
          <v-icon
            v-if="!readonly"
            v-bind="props"
            class="no-drag"
            icon="more_vert"
          />
        </template>

        <v-list>
          <v-list-item @click="$emit('edit')">
            {{ $t('Edit') }}
          </v-list-item>

          <v-list-item @click="$emit('duplicate')">
            {{ $t('Duplicate') }}
          </v-list-item>

          <v-list-item @click="$emit('destroy')">
            {{ $t('Remove') }}
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-card>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  compatConfig: { MODE: 3 },

  props: {
    metric: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['destroy', 'duplicate', 'edit'],

  data() {
    return {
      chart: null,
    };
  },

  computed: {
    tableHeaders() {
      if (this.metric.chart_type === 'table' && this.metric.value.length > 0) {
        return Object.keys(this.metric.value[0]).map((header) => ({ title: header, key: header }));
      }
      return [];
    },

    isCanvasChart() {
      if (this.metric) {
        return ['bar', 'pie-donut', 'time_series', 'pie'].includes(this.metric.chart_type);
      }
      return false;
    },

    chartId() {
      return ['chart', this.metric.id].join('-');
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    'metric.start_date': function () {
      this.loadChart();
    },
  },

  mounted() {
    this.loadChart();
  },

  methods: {
    getChartData() {
      if (!this.metric.value) return;

      return {
        data: {
          labels:
            this.metric.chart_type === 'time_series'
              ? this.metric.value.map((item) => item.date)
              : Object.keys(this.metric.value),
          datasets: [
            {
              data:
                this.metric.chart_type === 'time_series'
                  ? this.metric.value.map((item) => item.value)
                  : Object.values(this.metric.value),
              borderColor: [
                '#29B6F6',
                '#26C6DA',
                '#26A69A',
                '#66BB6A',
                '#9CCC65',
                '#D4E157',
                '#FFEE58',
                '#FFCA28',
              ],
              backgroundColor: [
                '#B3E5FC',
                '#B2EBF2',
                '#B2DFDB',
                '#C8E6C9',
                '#DCEDC8',
                '#F0F4C3',
                '#FFF9C4',
                '#FFECB3',
              ],
              cutout: this.metric.chart_type === 'pie-donut' ? '50%' : '',
            },
          ],
        },
        options: {
          borderWidth: this.metric.chart_type === 'line' ? 4 : 1,
          tension: 0.3,
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              titleFont: { size: 18, weight: 'bold' },
              bodyFont: { size: 16, weight: 'bold' },
              padding: 16,
            },
          },
        },
        type:
          this.metric.chart_type === 'time_series' ? 'line' : this.metric.chart_type.split('-')[0],
      };
    },

    loadChart() {
      if (this.isCanvasChart) {
        if (this.chart) {
          this.chart.destroy();
        }
        const ctx = document.getElementById(this.chartId);
        this.chart = new Chart(ctx, this.getChartData());
      }
    },
  },
};
</script>
