<!-- eslint-disable vue/no-mutating-props, vue/multi-word-component-names -->
<template>
  <v-card
    id="settings"
    class="mb-4"
    data-cy="settings-card"
    border
    flat
    tile
  >
    <v-card-title class="tab-title">
      <span v-t="'Settings'" />
    </v-card-title>

    <v-divider />

    <v-card-text class="my-4">
      <v-row class="d-flex align-center">
        <v-col class="fw-500 fs-16">
          {{ $t('Messaging enabled') }}
        </v-col>
        <v-col class="ta-right d-flex justify-end">
          <v-switch
            v-model="provider.inquiries_enabled"
            @update:model-value="$emit('change')"
            :label="$t(provider.inquiries_enabled ? 'Yes' : 'No')"
            class="mt-0"
            density="compact"
            hide-details
            inset
          />
        </v-col>
      </v-row>

      <v-row class="d-flex align-center">
        <v-col class="fw-500 fs-16">
          {{ $t('Tours enabled') }}
        </v-col>
        <v-col class="ta-right d-flex justify-end">
          <v-switch
            v-model="provider.tours_enabled"
            @update:model-value="$emit('change')"
            :label="$t(provider.tours_enabled ? 'Yes' : 'No')"
            class="mt-0"
            density="compact"
            hide-details
            inset
          />
        </v-col>
      </v-row>

      <v-row>
        <LabeledSimpleSelect
          v-model="provider.excluded_subsidy_program_ids"
          @change="$emit('change')"
          :items="subsidyPrograms"
          cols="6"
          item-title="name"
          item-value="id"
          message="Excluded from the following Subsidy Programs"
          chips
          deletable-chips
          multiple
        />
      </v-row>

      <v-divider class="my-4" />

      <v-row>
        <LabeledSimpleSelect
          v-model="provider.parent_provider_id"
          @change="$emit('change')"
          :items="providers"
          cols="6"
          data-cy="provider-parent-selector"
          item-title="name"
          item-value="id"
          message="Parent location"
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import API from '@/shared/mixins/api';

export default {
  compatConfig: { MODE: 2 },

  mixins: [API],

  props: {
    provider: {
      type: Object,
      default: null,
    },
    providers: {
      type: Array,
      default: null,
    },
  },

  emits: ['change'],

  data() {
    return {
      subsidyPrograms: [],
    };
  },

  created() {
    this.loadSubsidyPrograms();
  },

  methods: {
    async loadSubsidyPrograms() {
      const resp = await this.api.organization.subsidy_program.index();
      this.subsidyPrograms = resp.data;
    },
  },
};
</script>
