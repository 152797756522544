<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle>
      <TitleBar :name="terms.forms" />
    </PageTitle>

    <v-toolbar
      class="mb-3 bb-1 bc-extra-light-gray"
      elevation="0"
      extension-height="60"
      extended
    >
      <v-row class="d-flex align-center px-4">
        <template v-if="schemas.length > 0">
          <v-col class="mxw-400">
            <v-select
              v-model="selectedSchema"
              @update:model-value="handleSchemaChange"
              :aria-label="selectedSchema?.name"
              :items="schemas"
              data-cy="schemas_list"
              density="compact"
              item-title="name"
              item-value="id"
              variant="filled"
              hide-details
              return-object
            />
          </v-col>
        </template>
      </v-row>

      <template
        v-if="selectedSchema"
        #extension
      >
        <div class="d-flex flex-grow-1 px-4">
          <FilterMenu
            :active="filters.statusIds?.length > 0"
            classes="d-none d-md-inline-flex"
            title="Status"
          >
            <template #card>
              <v-autocomplete
                v-model="filters.statusIds"
                @update:model-value="reload(1)"
                :aria-label="$t('Filter by status')"
                :disabled="processing"
                :item-title="(item) => $t(item)"
                :items="statusOptions"
                :menu="true"
                :placeholder="$t('Filter by status')"
                density="compact"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            :active="filters.teamIds?.length > 0"
            classes="d-none d-md-inline-flex"
            title="Team"
            dense
          >
            <template #card>
              <v-autocomplete
                v-model="filters.teamIds"
                @update:model-value="reload(1)"
                :aria-label="$t('Filter by team name')"
                :disabled="processing"
                :item-title="(item) => $t(item.name)"
                :items="teams"
                :menu="true"
                :placeholder="$t('Filter by team name')"
                density="compact"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            :active="filters.reviewerIds?.length > 0"
            classes="d-none d-md-inline-flex"
            title="Reviewer"
            dense
          >
            <template #card>
              <v-autocomplete
                v-model="filters.reviewerIds"
                @update:model-value="reload(1)"
                :aria-label="$t('Filter by reviewer name')"
                :disabled="processing"
                :items="reviewers"
                :menu="true"
                :placeholder="$t('Filter by reviewer name')"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="selectedSchema.enable_stages"
            :active="filters.stageIds?.length > 0"
            classes="d-none d-md-inline-flex"
            title="Stage"
            dense
          >
            <template #card>
              <v-autocomplete
                v-model="filters.stageIds"
                @update:model-value="reload(1)"
                :aria-label="$t('Filter by stage title')"
                :items="selectedSchema.stages"
                :menu="true"
                :placeholder="$t('Filter by stage title')"
                density="compact"
                item-title="title"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="labels.length > 0"
            :active="filters.labelIds?.length > 0"
            classes="d-none d-md-inline-flex"
            title="Label"
            paddingless
          >
            <template #card>
              <v-autocomplete
                v-model="filters.labelIds"
                @update:model-value="reload(1)"
                :aria-label="$t('Filter by label name')"
                :closable-chips="true"
                :disabled="processing"
                :items="labels"
                :menu="true"
                :placeholder="$t('Filter by label name')"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              >
                <template #chip="{ item, props }">
                  <v-chip v-bind="props">
                    <template #prepend>
                      <v-avatar
                        :color="item.raw.color"
                        size="5"
                        start
                      />
                    </template>
                    {{ item.raw.name }}
                  </v-chip>
                </template>
                <template #item="{ item, props }">
                  <v-list-item
                    v-bind="props"
                    :title="$t(item.raw.name)"
                  >
                    <template #prepend="{ isSelected }">
                      <v-checkbox-btn
                        :key="item.value"
                        :model-value="isSelected"
                        :ripple="false"
                        tabindex="-1"
                      />
                    </template>
                    <template #append>
                      <v-avatar
                        :color="item.raw.color"
                        size="20"
                      />
                    </template>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </template>
          </FilterMenu>

          <!-- TODO: Replace with TeamEditor -->
          <v-dialog
            v-model="assignTeamDialogIsVisible"
            max-width="400"
            scrim="transparent"
          >
            <v-card
              border
              flat
              tile
            >
              <v-card-title>{{ $t('Assign') }}</v-card-title>
              <v-divider class="mb-2" />
              <v-card-text class="py-3">
                <v-row dense>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="assignTeamTo"
                      :items="teams"
                      item-title="name"
                      item-value="id"
                      label="Assign to"
                      placeholder="Assign to"
                      variant="filled"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="my-2" />
              <v-card-actions>
                <v-btn
                  @click="assignTeamDialogIsVisible = false"
                  variant="text"
                >
                  {{ $t('Cancel') }}
                </v-btn>
                <v-spacer />
                <v-btn
                  @click="assignTeam()"
                  :disabled="!assignTeamTo"
                  color="primary"
                >
                  {{ $t('Assign') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- TODO: Replace with ReviewerEditor -->
          <v-dialog
            v-model="assignReviewerDialogIsVisible"
            max-width="400"
            scrim="transparent"
          >
            <v-card
              border
              flat
              tile
            >
              <v-card-title>{{ $t('Assign') }}</v-card-title>
              <v-divider class="mb-2" />
              <v-card-text class="py-3">
                <v-row dense>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="assignReviewerTo"
                      :items="reviewers"
                      :label="$t('Assign to')"
                      :placeholder="$t('Assign to')"
                      item-title="name"
                      item-value="id"
                      variant="filled"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="my-2" />
              <v-card-actions>
                <v-btn
                  @click="assignReviewerDialogIsVisible = false"
                  variant="text"
                >
                  {{ $t('Cancel') }}
                </v-btn>
                <v-spacer />
                <v-btn
                  @click="assignReviewer()"
                  :disabled="!assignReviewerTo"
                  color="primary"
                >
                  {{ $t('Assign') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-spacer />

          <v-btn
            @click="assignTeamDialogIsVisible = true"
            :disabled="!anySelectedItems"
            class="me-3"
            color="primary"
          >
            {{ $t('Assign team') }}
          </v-btn>

          <v-btn
            @click="assignReviewerDialogIsVisible = true"
            :disabled="!anySelectedItems"
            class="me-3"
            color="primary"
          >
            {{ $t('Assign member') }}
          </v-btn>

          <v-btn
            v-if="selectedSchemaIsRequestable"
            @click="createNewSpecialistForm()"
            color="primary"
            data-cy="new_btn"
          >
            <v-icon icon="add" />
            {{ $t('New') }}
          </v-btn>
        </div>
      </template>
    </v-toolbar>

    <div
      v-if="processing"
      class="mxw-800 mx-auto py-12 ta-center"
    >
      <v-progress-circular
        color="primary"
        size="75"
        indeterminate
      />
    </div>

    <v-container
      v-else
      class="px-3 py-0"
      fluid
    >
      <v-card
        class="bb-1 bc-extra-light-gray pa-0"
        border
        flat
        tile
      >
        <v-card-text class="pa-3">
          <v-card
            class="bb-1 bc-extra-light-gray mb-4"
            flat
            tile
          >
            <v-card-text class="pt-0 px-0 pb-3 fs-15">
              <v-row dense>
                <v-col class="d-flex align-center">
                  <template v-if="filterSummary">
                    <v-icon
                      icon="filter_alt"
                      start
                    />
                    <span class="me-1">{{ $t('Filtering by') }}:</span>
                    <span class="fw-600">{{ $t(filterSummary) }}</span>
                    <span>.&nbsp;</span>
                  </template>
                  <span>
                    {{ $t('Displaying') }}
                    <strong>{{ collection.length }}</strong>
                    {{ $t('results') }}, {{ $t('out of') }}
                    <strong>{{ total }}</strong>
                    {{ $t('total results') }}.
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-row
            v-if="selectedSchema"
            dense
          >
            <v-col
              class="fw-600 fs-14 c-black"
              cols="2"
            >
              <div class="d-flex align-center fw-600 fs-14 c-black">
                <v-checkbox-btn
                  v-model="selectedAll"
                  aria-label="Select all forms"
                  class="d-inline-flex me-2 flex-0-0"
                  density="compact"
                  false-icon="check_box_outline_blank"
                  true-icon="check_box"
                />
                <span class="fs-14 fw-600 c-black">
                  {{ $t('ID') }}
                </span>
              </div>
            </v-col>

            <v-col class="fw-600 fs-14 c-black">{{ $t('Submitter') }}</v-col>

            <v-col class="fw-600 fs-14 c-black ta-center">{{ $t('Team') }}</v-col>

            <v-col class="fw-600 fs-14 c-black ta-center">{{ $t('Assigned') }}</v-col>

            <v-col class="ta-center">
              <v-btn
                @click="toggleSort('submitted_at')"
                class="h-inherit pa-0 ma-0"
                variant="text"
              >
                <span class="fs-14 fw-600 c-black">{{ $t('Submitted') }}</span>
                <v-icon class="c-black">
                  {{
                    sortDirection['submitted_at'] == 'desc' ? 'arrow_drop_down' : 'arrow_drop_up'
                  }}
                </v-icon>
              </v-btn>
            </v-col>

            <v-col class="ta-center">
              <v-btn
                @click="toggleSort('status')"
                class="h-inherit"
                variant="text"
              >
                <span class="fs-14 fw-600 c-black">
                  {{ $t('Status') }}
                </span>
                <v-icon class="c-black">
                  {{ sortDirection['status'] == 'desc' ? 'arrow_drop_down' : 'arrow_drop_up' }}
                </v-icon>
              </v-btn>
            </v-col>

            <v-col class="fw-600 fs-14 c-black ta-center">{{ $t('Enrollment') }}</v-col>

            <v-col class="fw-600 fs-14 c-black ta-center">{{ $t('Provider') }}</v-col>

            <v-col
              v-if="selectedSchema.enable_stages"
              class="ta-center"
            >
              <span class="fs-14 fw-600 c-black">
                {{ $t('Stage') }}
              </span>
            </v-col>

            <v-col
              v-if="labels.length > 0"
              class="ta-center"
            >
              <span class="fs-14 fw-600 c-black">
                {{ $t('Labels') }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card
        v-for="(form, index) in collection"
        :key="index"
        class="pointer bb-1 bc-extra-light-gray pa-0"
        border
        flat
        tile
      >
        <v-card-text class="pa-3">
          <v-row
            class="d-flex align-center"
            dense
          >
            <v-col
              class="c-black fs-14 fw-500"
              cols="2"
            >
              <div class="d-flex align-center fw-500 fs-14 c-black">
                <v-checkbox-btn
                  v-model="selectedItems[form.id]"
                  @update:model-value="checkSelectedItems()"
                  :aria-label="$t('Select') + ' ' + form.name"
                  class="d-inline-flex me-2 flex-0-0"
                  density="compact"
                  false-icon="check_box_outline_blank"
                  true-icon="check_box"
                />
                <router-link
                  :to="getFormLink(form.id)"
                  data-cy="form-id"
                >
                  <span v-text="form.id.split('-')[0]"></span>
                </router-link>
              </div>
            </v-col>

            <v-col class="c-black fs-14 fw-500">
              <span v-text="getFormMemberName(form)"></span>
            </v-col>

            <v-col class="c-black fs-14 fw-500 ta-center">
              <v-tooltip
                v-if="getFormTeamName(form)"
                location="bottom"
                center
              >
                <template #activator="{ props }">
                  <v-chip
                    @click:close="removeReviewer(form, true)"
                    v-bind="props"
                    :closable="$store.state.profile.org_providers_admin"
                    :disabled="processing"
                    close-icon="close"
                    size="small"
                  >
                    <InitialedText :value="getFormTeamName(form)" />
                  </v-chip>
                </template>

                <span v-text="getFormTeamName(form)"></span>
              </v-tooltip>
            </v-col>

            <v-col class="c-black fs-14 fw-500 ta-center">
              <v-tooltip
                v-if="getFormReviewerName(form)"
                location="bottom"
                center
              >
                <template #activator="{ props }">
                  <v-chip
                    @click:close="removeReviewer(form, false)"
                    v-bind="props"
                    :closable="$store.state.profile.org_providers_admin"
                    :disabled="processing"
                    close-icon="close"
                    size="small"
                  >
                    <InitialedText :value="getFormReviewerName(form)" />
                  </v-chip>
                </template>

                <span v-text="getFormReviewerName(form)"></span>
              </v-tooltip>
            </v-col>

            <v-col class="c-black fs-14 fw-500 ta-center">
              <LongDate
                :date="form.attributes?.submitted_at"
                short-month
              />
            </v-col>

            <v-col class="c-black fs-14 fw-500 ta-center">
              {{ $t(form.attributes?.status) }}
            </v-col>

            <v-col class="c-black fs-14 fw-500 ta-center">
              <div @contextmenu.prevent="openFormSubsidyInNewTab(form)">
                <router-link
                  v-if="formHasEnrollmentAndSubsidy(form)"
                  :ref="`subsidyLink-${getFormSubsidyId(form)}`"
                  :to="{ name: 'SubsidyShow', params: { id: getFormSubsidyId(form) } }"
                  tracked
                >
                  {{ $t(getFormEnrollmentName(form)) }}
                </router-link>
                <span v-else>
                  {{ $t(getFormEnrollmentName(form)) }}
                </span>
              </div>
            </v-col>

            <v-col class="c-black fs-14 fw-500 ta-center">
              <div @contextmenu.prevent="openFormProviderInNewTab(form)">
                <router-link
                  v-if="getFormProviderId(form)"
                  :ref="`providerLink-${getFormProviderId(form)}`"
                  :to="{ name: 'ProviderShow', params: { providerId: getFormProviderId(form) } }"
                  tracked
                >
                  {{ $t(getFormProviderName(form)) }}
                </router-link>
                <span v-else>
                  {{ $t(getFormProviderName(form)) }}
                </span>
              </div>
            </v-col>

            <v-col
              v-if="selectedSchema.enable_stages"
              class="c-black fs-14 fw-500 ta-center"
            >
              <template v-if="form.attributes?.stage_id">
                {{ $t(stages[form.attributes?.stage_id].title) }}
              </template>
            </v-col>

            <v-col
              v-if="labels.length > 0"
              class="c-black fs-14 fw-500 ta-center"
            >
              <LabelValue
                @edit="$refs.labelDialog.open(form)"
                :applied-labels="getFormLabels(form)"
                :disabled="processing"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <NullState v-if="!processing && collection.length === 0" />

      <v-pagination
        v-show="pages > 1"
        v-model="filters.page"
        @update:model-value="reload"
        :length="pages"
        :total-visible="8"
        class="mt-4"
      />

      <VerticalSpacer :min-height="60" />
    </v-container>

    <LabelDialog
      @change="updateEditedLabels"
      @close="loadForms()"
      ref="labelDialog"
      :program-labels="labels"
      label-owner-type="Form"
    />
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import InitialedText from '@/shared/components/InitialedText.vue';
import LabelDialog from '@/specialist/components/LabelDialog.vue';
import LabelValue from '@/specialist/components/LabelValue.vue';
import LongDate from '@/shared/components/LongDate.vue';
import NullState from '@/shared/components/NullState.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import RouterHelper from '@/shared/mixins/RouterHelper';
import {
  STATIC_STATUSES,
  NEUTRAL_STATUSES,
  POSITIVE_STATUSES,
} from '@/specialist/services/statuses';
import TitleBar from '@/shared/components/TitleBar.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import _ from 'lodash';
import Terms from '@/shared/mixins/terms';

export default {
  compatConfig: { MODE: 3 },

  components: {
    FilterMenu,
    InitialedText,
    LabelDialog,
    LabelValue,
    LongDate,
    NullState,
    PageTitle,
    TitleBar,
    VerticalSpacer,
  },

  mixins: [API, RouterHelper, Terms],

  data() {
    return {
      anySelectedItems: false,
      assignTeamDialogIsVisible: false,
      assignTeamTo: null,
      assignReviewerDialogIsVisible: false,
      assignReviewerTo: null,
      collection: [],
      enrollments: [],
      filters: this.getDefaultFilters(),
      filterSummary: null,
      members: [],
      pages: 1,
      processing: false,
      providers: [],
      searchCounter: 0,
      selectedAll: false,
      selectedItems: {},
      selectedSchema: null,
      sort: null,
      sortDirection: {
        status: 'desc',
        submitted_at: 'desc',
      },
      status: null,
      schemas: [],
      reviewers: [],
      statuses: STATIC_STATUSES.concat(NEUTRAL_STATUSES, POSITIVE_STATUSES),
      labels: [],
      teams: [],
      total: 0,
    };
  },

  computed: {
    selectedSchemaIsRequestable() {
      return this.selectedSchema.meta.specialist_requestable;
    },

    stages() {
      if (!this.selectedSchema) return {};

      const schemaStages = {};

      this.selectedSchema.stages.forEach((stage) => {
        schemaStages[stage.id] = stage;
      });

      return schemaStages;
    },

    statusOptions() {
      const removedStatuses = ['Waitlist'];
      return this.statuses.filter((status) => !removedStatuses.includes(status));
    },
  },

  watch: {
    selectedAll(newVal) {
      this.setAll(newVal);
      this.checkSelectedItems();
    },
  },

  created() {
    this.load();
  },

  methods: {
    async assignTeam() {
      this.processing = true;

      await Promise.all(
        Object.keys(this.selectedItems).map(async (selectedItemId) => {
          if (this.selectedItems[selectedItemId]) {
            const response = await this.api.organization.form.update(selectedItemId, {
              team_id: this.assignTeamTo,
            });

            const index = this.collection.findIndex(
              (collectionItem) => collectionItem.id === selectedItemId,
            );
            this.collection[index] = response.data;
          }
        }),
      );

      this.assignTeamDialogIsVisible = false;
      this.assignTeamTo = null; // TODO: temporary clear until we convert v-dialog into resource dialog
      this.selectedAll = false;
      this.setAll(false);
      this.processing = false;
      this.checkSelectedItems();
      this.reload(1);
    },

    async assignReviewer() {
      this.processing = true;

      await Promise.all(
        Object.keys(this.selectedItems).map(async (selectedItemId) => {
          if (this.selectedItems[selectedItemId]) {
            const response = await this.api.organization.form.update(selectedItemId, {
              reviewer_id: this.assignReviewerTo,
            });

            const index = this.collection.findIndex(
              (collectionItem) => collectionItem.id === selectedItemId,
            );
            this.collection[index] = response.data;
          }
        }),
      );

      this.assignReviewerDialogIsVisible = false;
      this.assignReviewerTo = null; // TODO: temporary clear until we convert v-dialog into resource dialog
      this.selectedAll = false;
      this.setAll(false);
      this.processing = false;
      this.checkSelectedItems();
      this.reload(1);
    },

    async createNewSpecialistForm() {
      const params = {
        schema_id: this.selectedSchema.id,
      };
      const { data } = await this.api.organization.form.create(params);
      const destination = this.$store.state.profile.org_forms_review ? 'FormReview' : 'FormShow';
      this.$router.push({ name: destination, params: { formId: data.id } });
    },

    getFormMemberName(form) {
      const memberId = form?.relationships?.member?.data?.id || null;
      if (!memberId) return '';

      const formMember = this.members.find((member) => member.id === memberId);

      return formMember?.attributes?.name || '';
    },

    getFormLabels(form) {
      const labelIds = form?.relationships?.labels?.data?.map((label) => label.id) || [];
      if (labelIds.length === 0) return [];

      return this.labels?.filter((label) => labelIds.includes(label.id)) || [];
    },

    // Provider column functions
    openFormProviderInNewTab(form) {
      const providerId = this.getFormProviderId(form) || null;
      if (!providerId) return;

      const url = this.$refs[`providerLink-${providerId}`][0].$el.href;
      window.open(url, '_blank');
    },

    getFormProvider(form) {
      const providerId = form?.relationships?.provider?.data?.id || null;
      if (!providerId) return '';

      const formProvider = this.providers.find((provider) => provider.id === providerId);

      return formProvider || {};
    },

    getFormProviderId(form) {
      return this.getFormProvider(form)?.id || '';
    },

    getFormProviderName(form) {
      return this.getFormProvider(form)?.attributes?.name || '';
    },

    // Reviewer columnn function
    getFormReviewerName(form) {
      const reviewerId = form?.relationships?.reviewer?.data?.id || null;
      if (!reviewerId) return '';

      const formReviewer = this.reviewers.find((reviewer) => reviewer.id === reviewerId);

      return formReviewer?.name || '';
    },

    // Team column function
    getFormTeamName(form) {
      const teamId = form?.relationships?.team?.data?.id || null;
      if (!teamId) return '';

      const formTeam = this.teams.find((team) => team.id === teamId);

      return formTeam?.name || '';
    },

    // Enrollment column functions
    formHasEnrollmentAndSubsidy(form) {
      const enrollmentId = form?.relationships?.enrollment?.data?.id || null;
      const subsidyId = this.getFormSubsidyId(form) || null;

      return enrollmentId && subsidyId;
    },

    openFormSubsidyInNewTab(form) {
      const subsidyId = this.getFormSubsidyId(form) || null;
      if (!subsidyId) return;

      const url = this.$refs[`subsidyLink-${subsidyId}`][0].$el.href;
      window.open(url, '_blank');
    },

    getFormEnrollment(form) {
      const enrollmentId = form?.relationships?.enrollment?.data?.id || null;
      if (!enrollmentId) return '';

      const formEnrollment = this.enrollments.find((enrollment) => enrollment.id === enrollmentId);

      return formEnrollment || {};
    },

    getFormSubsidyId(form) {
      return form?.relationships?.subsidy?.data?.id || '';
    },

    getFormEnrollmentName(form) {
      return this.getFormEnrollment(form)?.attributes?.name || '';
    },

    getDefaultFilters() {
      return {
        label: null,
        statusIds: [],
        schemaId: null,
        filter: null,
        page: 1,
        query: null,
        labelIds: [],
        stageIds: [],
        reviewerIds: [],
        teamIds: [],
      };
    },

    getFilterSummary() {
      const appliedFilters = [];
      if (this.filters.statusIds?.length > 0)
        appliedFilters.push(this.filters.statusIds.join(', '));

      if (this.filters.teamIds?.length > 0) {
        this.filters.teamIds.forEach((teamId) => {
          appliedFilters.push(this.teams.find((team) => team.id === teamId).name);
        });
      }

      if (this.filters.reviewerIds?.length > 0) {
        this.filters.reviewerIds.forEach((reviewerId) => {
          appliedFilters.push(this.reviewers?.find((reviewer) => reviewer.id === reviewerId).name);
        });
      }

      if (this.filters.stageIds?.length > 0) {
        this.filters.stageIds.forEach((stageId) => {
          appliedFilters.push(
            this.selectedSchema?.stages?.find((stage) => stage.id === stageId).title,
          );
        });
      }

      if (this.filters.labelIds?.length > 0) {
        this.filters.labelIds.forEach((labelId) => {
          appliedFilters.push(this.labels?.find((label) => label.id === labelId).name);
        });
      }

      return appliedFilters.length > 0 ? appliedFilters.join(', ') : null;
    },

    getFormLink(formId) {
      if (this.$store.state.profile.org_forms_review) {
        return { name: 'FormReview', params: { formId } };
      }
      return { name: 'FormShow', params: { formId } };
    },

    handleSchemaChange() {
      this.filters = this.getDefaultFilters();
      this.filters.schemaId = this.selectedSchema.id;
      this.loadSchemaLabels();
      this.reload(1);
    },

    async load() {
      this.collection.splice(0);
      this.processing = true;

      let unorderedSchemas = Object.values(this.$store.state.schemas).filter(
        (schema) => schema.data_type === 'Form',
      );

      this.schemas = _.orderBy(unorderedSchemas, [(schema) => schema.name.toLowerCase()], ['asc']);
      this.loadFilters();
      await this.updateQuery({ ...this.filters });
      this.$store.commit('setFilters', ['form', this.filters]);

      await this.loadSchemaLabels();
      await this.loadTeams();
      await this.loadReviewers();

      this.loadForms();
    },

    loadFilters() {
      const queryFilters = { ...this.$route.query };
      const storedFilters = this.$store.state.filters.form || {};

      let filtersHash;
      if (Object.keys(queryFilters).length > 1) {
        filtersHash = queryFilters;
      } else if (Object.keys(storedFilters).length > 1) {
        filtersHash = storedFilters;
      } else {
        filtersHash = {
          schemaId: this.schemas[0].id,
        };
      }

      const newFilters = _.merge(this.getDefaultFilters, filtersHash);

      const defaultFilters = this.getDefaultFilters();
      Object.keys(defaultFilters).forEach((key) => {
        if (!_.isString(newFilters[key])) return;

        if (_.isArray(defaultFilters[key])) newFilters[key] = [newFilters[key]];

        if (_.isNumber(defaultFilters[key])) newFilters[key] = parseInt(newFilters[key], 10);
      }, this);

      Object.keys(newFilters).forEach((key) => {
        this.filters[key] = newFilters[key];
      }, this);

      this.selectedSchema = this.schemas.find((sp) => sp.id === this.filters.schemaId);
    },

    async loadReviewers() {
      const response = await this.api.organization.member.index({ is_specialist: true });
      this.reviewers = response?.data || [];
    },

    async loadForms() {
      this.processing = true;

      this.filterSummary = this.getFilterSummary();
      this.searchCounter += 1;
      const currentSearchCounter = this.searchCounter;

      let sort = null;

      if (this.filters.sort_dir && this.filters.sort) {
        sort = (this.filters.sort_dir === 'desc' ? '-' : '') + this.filters.sort;
      }

      const params = {
        filter: {
          reviewer: this.filters.reviewerIds?.toString() || null,
          schema: this.filters.schemaId || null,
          stage_id: this.filters.stageIds?.toString() || null,
          status: this.filters.statusIds?.toString() || null,
          team: this.filters.teamIds?.toString() || null,
        },
        include: 'enrollment,member,provider',
        page: {
          limit: 10,
          offset: (this.filters.page - 1) * 10,
        },
        sort,
      };

      if (this.$store.state.profile.org_settings_access) {
        params.filter.labels = this.filters.labelIds?.toString() || null;
        params.include += ',labels';
      }

      const response = await this.api.organization.form.indexVersion2(params);

      if (this.searchCounter !== currentSearchCounter) return;

      this.pages = response?.data?.meta?.total_pages || 0;
      this.total = response?.data?.meta?.total_count || 0;

      this.collection = response?.data?.data;
      this.members = response?.data?.included?.filter((item) => item.type === 'member');
      this.providers = response?.data?.included?.filter((item) => item.type === 'provider');
      this.enrollments = response?.data?.included?.filter((item) => item.type === 'enrollment');

      this.selectedItems = {};
      this.processing = false;
    },

    async loadSchemaLabels() {
      if (!this.$store.state.profile.org_settings_access) return;

      const response = await this.api.organization.label.index({
        schema_id: this.selectedSchema?.id,
      });

      this.labels = response?.data || [];
    },

    async loadTeams() {
      const response = await this.api.organization.team.index();
      this.teams = response?.data || [];
    },

    async reload(page) {
      if (page) this.filters.page = page;
      await this.updateQuery({ ...this.filters });
      this.$store.commit('setFilters', ['form', this.filters]);
      this.loadForms();
    },

    async removeReviewer(form, isTeam) {
      this.processing = true;
      const params = {
        team_id: isTeam ? null : form.team_id,
        reviewer_id: isTeam ? form.reviewer_id : null,
      };

      await this.api.organization.form.update(form.id, params);

      this.loadForms();
    },

    setAll(val) {
      this.collection.forEach((form) => {
        this.selectedItems[form.id] = val;
      }, this);
    },

    toggleSort(field) {
      this.sort = field;

      Object.keys(this.sortDirection).forEach((key) => {
        if (field !== key) this.sortDirection[key] = 'desc';
      });

      if (this.sortDirection[field] === 'desc') {
        this.sortDirection[field] = 'asc';
      } else {
        this.sortDirection[field] = 'desc';
      }

      this.filters.sort = this.sort;
      this.filters.sort_dir = this.sortDirection[this.sort];

      this.reload(1);
    },

    updateEditedLabels(form) {
      const index = this.collection.findIndex((item) => item.id === form.id);
      this.collection[index] = form;
    },

    checkSelectedItems() {
      this.anySelectedItems = Object.values(this.selectedItems).some((value) => value === true);
    },
  },
};
</script>
