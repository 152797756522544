<template>
  <div v-if="subsidyProgram">
    <BreadcrumbTitle
      :back-name="subsidyProgram.name"
      :back-route="{
        name: 'ChildSubsidyProgramShow',
        params: { subsidyProgramId: subsidyProgram.id },
      }"
      name="IEP Referrals"
    />

    <v-card
      border
      flat
    >
      <v-card-title>
        <span>Under construction</span>
      </v-card-title>
      <v-card-text>
        <p>This is a new feature set and more will be coming soon.</p>

        <p>
          For now, you can upload new IEP referrals using the button below and view existing
          referrals by creating an IEP Referral report. The file must have the following columns:
        </p>

        <ol class="mb-4">
          <li>parent_email</li>
          <li>child_first_name</li>
          <li>child_dob - format YYYY-MM-DD</li>
          <li>team_id</li>
          <li>program_id</li>
        </ol>

        <p>To archive referrals, upload file with columns:</p>
        <ol class="mb-4">
          <li>id - id of existing referral; pull from report</li>
          <li>archived - text = "true"</li>
        </ol>
        <p>File must be CSV format.</p>

        <v-row>
          <v-col>
            <v-file-input
              v-model="file"
              accept=".csv"
              label="Upload IEP Referrals"
            />
            <v-btn
              @click="upload"
              :disabled="!file"
              color="primary"
              variant="flat"
              tile
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import API from '@/shared/mixins/api';

export default {
  compatConfig: { MODE: 2 },

  mixins: [API],

  data() {
    return {
      file: null,
      subsidyProgram: null,
    };
  },

  created() {
    this.subsidyProgramId = this.$route.params.subsidyProgramId;
    this.load();
  },

  methods: {
    async upload() {
      const reader = new FileReader();
      const vm = this;
      reader.readAsText(this.file, 'UTF-8');
      reader.onload = (evt) => {
        const params = {
          file: evt.target.result,
          subsidy_program_id: vm.subsidyProgramId,
        };
        vm.api.organization.iep_referral
          .upload(params)
          .then(() => {
            vm.$eventBus.$emit('chime', 'IEP Referrals uploaded successfully');
          })
          .catch(() => {
            vm.$eventBus.$emit('chime', 'Failed to upload IEP Referrals');
          });
      };
    },

    async load() {
      this.changed = false;
      this.subsidyProgram = (
        await this.api.organization.subsidy_program.get(this.subsidyProgramId)
      ).data;
    },
  },
};
</script>
