<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle>
      <v-btn
        v-if="isAnyReportFolderSelected()"
        :to="{ name: 'ReportIndex' }"
        class="ms-3"
        variant="text"
        icon
      >
        <v-icon
          class="c-black"
          icon="west"
          size="28"
        />
      </v-btn>
      <div
        v-text="getTitle()"
        class="ms-4 me-3 d-inline-block fs-22 fw-600"
        data-cy="page-title"
      />
      <ActionMenu
        v-if="$store.state.profile.org_reporting_admin && isAnyReportFolderSelected()"
        @click:action:delete="destroyReportFolder()"
        @click:action:edit="openEditReportFolderDialog()"
        :items="getReportFolderActionItems()"
        button-class="ms-3"
        button-icon="more_vert"
        x-small
      />
    </PageTitle>

    <v-toolbar
      class="mb-3 px-4 bb-1"
      flat
    >
      <v-row>
        <v-col>
          <v-text-field
            v-model="query"
            :aria-label="$t('Search reports by name')"
            :placeholder="$t('Search reports by name')"
            data-cy="search-bar"
            data-testid="report.search"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
          />
        </v-col>
        <v-col class="ta-right">
          <v-btn
            v-if="$store.state.profile.org_reporting_edit"
            @click="openNewReportFolderDialog()"
            class="mr-4"
            color="primary"
            elevation="0"
            prepend-icon="add"
          >
            {{ $t('New folder') }}
          </v-btn>
          <v-btn
            v-if="$store.state.profile.org_reporting_edit"
            @click="dialog = true"
            color="primary"
            elevation="0"
            prepend-icon="add"
          >
            {{ $t('New report') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>

    <ReportFolders
      @open-dialog="$refs.reportFolderDialog.open($event)"
      :destroy-report-folder="destroyReportFolder"
      :is-any-report-folder-selected="isAnyReportFolderSelected()"
      :reload="load"
      :report-folders="reportFolders"
    />

    <v-container
      class="px-3 py-0"
      data-testid="reportList"
      fluid
    >
      <v-card
        v-if="reports.length > 0"
        class="bb-1 bc-very-light-grey pa-0"
        border
        flat
        tile
      >
        <v-card-text>
          <v-row data-testid="reportList.header">
            <v-col
              class="fw-600 fs-16 c-black d-none d-md-flex"
              cols="3"
            >
              {{ $t('Name') }}
            </v-col>
            <v-col class="fw-600 fs-16 c-black d-none d-md-flex">
              {{ $t('Type') }}
            </v-col>
            <v-col class="fw-600 fs-16 c-black d-none d-md-flex">
              {{ $t('Schema') }}
            </v-col>
            <v-col class="fw-600 fs-16 c-black d-none d-md-flex">
              {{ $t('Created') }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <NullState
        v-else
        @new="dialog = true"
        :hide-new-button="!$store.state.profile.org_reporting_edit"
        title="No reports found"
      />

      <v-card
        v-for="(reportItem, index) in reports"
        :key="index"
        :to="{ name: 'ReportShow', params: { reportId: reportItem.id } }"
        class="pointer bt-0 bc-very-light-grey pa-0"
        border
        flat
        tile
      >
        <v-card-text>
          <v-row class="d-flex align-center">
            <v-col
              class="c-black fs-16 fw-500"
              cols="3"
              data-cy="report-name"
            >
              <span v-text="reportItem.name" />
            </v-col>
            <v-col class="c-black fs-16 fw-500">
              <span v-text="getDataType(reportItem.data_type)" />
            </v-col>
            <v-col class="c-black fs-16 fw-500">
              {{ $t(reportItem.data_type) }}
            </v-col>
            <v-col class="c-black fs-16 fw-500">
              <LongDate :date="reportItem.created_at" />
            </v-col>
            <v-col
              cols="1"
              data-cy="kebab"
            >
              <ActionMenu
                v-if="$store.state.profile.org_reporting_admin"
                @click:action:delete="openDestroyReportDialog(reportItem)"
                @click:action:move="openMoveReportDialog(reportItem)"
                :items="getReportActions()"
                button-class="ms-3"
                button-icon="more_vert"
                x-small
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-pagination
        v-show="pages > 1"
        v-model="page"
        :length="pages"
        :total-visible="8"
        class="mt-4"
      />

      <VerticalSpacer :min-height="50" />

      <v-dialog
        v-model="dialog"
        scrim="transparent"
        width="400px"
      >
        <v-card
          v-if="schemas"
          border
          flat
          tile
        >
          <v-card-title>{{ $t('New report') }}</v-card-title>

          <v-divider class="mb-3" />

          <v-card-text>
            <v-row>
              <LabeledTextfield
                v-model="report.name"
                message="Name"
              />

              <LabeledAutocomplete
                v-model="report.data_type"
                :items="dataTypes"
                :multiple="false"
                message="Report type"
              />

              <LabeledAutocomplete
                v-if="showSchemaSelector"
                v-model="report.schema_id"
                :items="relevantSchemas"
                :multiple="false"
                item-title="name"
                item-value="id"
                message="Schema"
              />
            </v-row>
          </v-card-text>

          <v-divider class="mb-3" />

          <v-card-actions>
            <v-btn
              @click="dialog = false"
              :loading="processing"
              color="primary"
              size="x-large"
              variant="text"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-spacer />
            <v-btn
              @click="create"
              :disabled="!report.name || !report.data_type"
              :loading="processing"
              color="primary"
              size="x-large"
            >
              {{ $t('Create') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <ReportFolderDialog
      ref="reportFolderDialog"
      :after-save="load"
      :api="api"
      :processing="processing"
      :reports="reports"
      :teams="teams"
    />
    <ReportFolderMoveDialog
      ref="moveReportDialog"
      :after-save="load"
      :processing="processing"
      :report-folder-items="reportFolders"
      :selected-report="currentReport"
    />
    <DecisionDialog
      @confirm="deleteReport"
      ref="deleteReportDialog"
      :after-save="load"
      :content="reportDeleteDialogContent()"
      :processing="processing"
      confirm-button-text="Delete Report"
      title="Delete Report"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Api from '@/specialist/services/bright_finder';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import DecisionDialog from '@/shared/components/DecisionDialog.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LongDate from '@/shared/components/LongDate.vue';
import NullState from '@/shared/components/NullState.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import ReportFolderMoveDialog from '@/specialist/components/report-folders/ReportFolderMoveDialog.vue';
import ReportFolderDialog from '@/specialist/components/report-folders/ReportFolderDialog.vue';
import ReportFolders from '@/specialist/components/report-folders/ReportFolders.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    ActionMenu,
    LabeledAutocomplete,
    DecisionDialog,
    LabeledTextfield,
    LongDate,
    NullState,
    PageTitle,
    ReportFolderDialog,
    ReportFolderMoveDialog,
    ReportFolders,
    VerticalSpacer,
  },

  props: {
    api: {
      type: Object,
      default: () => Api,
    },
  },

  data() {
    return {
      currentReport: null,
      dataTypes: [],
      dialog: false,
      processing: false,
      schemas: [],
      reportFolderDialog: null,
      reportFolders: [],
      reports: [],
      report: { data_type: null, name: null, schema_id: null },
      moveFolderDialog: null,
      page: 1,
      pages: 1,
      query: null,
      queryCount: 0,
      teams: [],
    };
  },

  computed: {
    relevantSchemas() {
      if (this.report.data_type) {
        return this.schemas.filter(this.relevantSchema);
      }

      return [];
    },

    showSchemaSelector() {
      return (
        this.report.data_type &&
        this.dataTypes.find((dt) => dt.value == this.report.data_type).schematized
      );
    },
  },

  watch: {
    page() {
      this.load();
    },

    query() {
      this.load();
    },

    // eslint-disable-next-line func-names
    'report.data_type': function () {
      this.report.schema_id = null;
    },

    '$route.params': {
      async handler() {
        this.procesing = true;
        await this.load();
        this.procesing = false;
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    buildReportIndexParams() {
      const params = {
        order: 'created',
        page: this.page,
        query: this.query,
      };
      if (_.isEmpty(this.query)) {
        params.report_folder_id = this.$route.query.reportFolderId;
        params.scoped_to_folder = this.isAnyReportFolderSelected();
      }
      return params;
    },

    create() {
      this.processing = true;
      this.api.organization.report.create(
        this.report,
        (resp) => {
          this.$router.push({ name: 'ReportShow', params: { reportId: resp.data.id } });
        },
        (err) => {
          this.processing = false;
          this.$eventBus.$emit('error', err);
        },
      );
    },

    async destroyReportFolder(folder) {
      this.processing = true;
      await this.api.organization.reportFolder.destroy(folder || this.getSelectedReportFolder());

      if (folder) {
        await this.load();
      } else {
        this.$router.push({ name: 'ReportIndex' });
        await this.loadReports();
      }
      this.processing = false;
    },

    getReportFolderActionItems() {
      return [
        { event: 'edit', avatar: 'edit', title: 'Edit folder' },
        { event: 'delete', avatar: 'delete', title: 'Delete folder' },
      ];
    },

    getReportActions() {
      return [
        { event: 'move', avatar: 'edit', title: 'Move' },
        { event: 'delete', avatar: 'delete', title: 'Delete' },
      ];
    },

    getSelectedReportFolder() {
      return (
        _.find(
          this.reportFolders,
          (reportFolder) =>
            reportFolder?.id?.toString() === this.$route?.query?.reportFolderId?.toString(),
        ) || {}
      );
    },

    getDataType(dataType) {
      return _.find(this.dataTypes, { value: dataType })?.text;
    },

    getTitle() {
      return this.isAnyReportFolderSelected() ? this.getSelectedReportFolder().name : 'Reports';
    },

    isAnyReportFolderSelected() {
      return !_.isNil(this.$route.query?.reportFolderId);
    },

    async load() {
      this.procesing = true;
      this.reports = [];
      this.queryCount += 1;

      if (this.dataTypes.length === 0) await this.loadReportDataTypes();
      await this.loadReports();
      await this.loadReportFolders();
      await this.loadSchemas();

      this.processing = false;
    },

    async loadReportDataTypes() {
      this.dataTypes = (await this.api.organization.reportDataType.index()).data;
    },

    async loadReports() {
      const currentQueryCount = this.queryCount;
      const response = await this.api.organization.report.index(this.buildReportIndexParams());

      if (this.queryCount === currentQueryCount) {
        this.pages = parseInt(response.headers['x-page-count'] || 1, 10);
        this.reports = response.data;
      }
    },

    async loadReportFolders() {
      const response = await this.api.organization.reportFolder.index({
        include: ['reports', 'teams'],
      });
      this.reportFolders = response.data;
    },

    async loadSchemas() {
      const response = await this.api.public_api.organization.schema.index();
      this.schemas = response.data;
    },

    async openNewReportFolderDialog() {
      await this.$refs.reportFolderDialog.open(this.getSelectedReportFolder());
    },

    async openEditReportFolderDialog() {
      await this.$refs.reportFolderDialog.open(this.getSelectedReportFolder());
    },

    async openMoveReportDialog(report) {
      this.currentReport = report;
      await this.$refs.moveReportDialog.open(report);
    },

    async openDestroyReportDialog(report) {
      this.currentReport = report;
      await this.$refs.deleteReportDialog.open();
    },

    relevantSchema(schema) {
      if (!schema.id) return false;

      return (
        this.report.data_type === schema.data_type ||
        (this.report.data_type === 'ChildData' && schema.data_type === 'Child') ||
        (this.report.data_type === 'GroupData' && schema.data_type === 'Group')
      );
    },

    shouldShowReportFolderColumn() {
      return this.isAnyReportFolderSelected() && !_.isEmpty(this.query);
    },

    reportDeleteDialogContent() {
      const reportName = this?.currentReport?.name;
      return `Are you sure you want to delete the "${reportName}" report? This action cannot be undone.`;
    },

    async deleteReport() {
      const resp = await Api.organization.report.destroy(this.currentReport.id);

      if (resp.status === 200) {
        this.$router.push({ name: 'ReportIndex' });
        this.loadReports();
      } else {
        this.$eventBus.$emit('error', 'Error occurred');
      }

      await this.$refs.deleteReportDialog.close();
      this.$eventBus.$emit('chime', 'Report was deleted');
    },
  },
};
</script>
