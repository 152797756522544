export default [
  {
    icon: 'public',
    title: 'Sites',
    route: 'SiteIndex',
  },
  {
    icon: 'article',
    title: 'Pages',
    route: 'WebpageIndex',
  },
  {
    icon: 'palette',
    title: 'Brands',
    route: 'BrandIndex',
  },
  {
    icon: 'image',
    title: 'Assets',
    route: 'AssetIndex',
  },
  {
    icon: 'language',
    title: 'Translations',
    route: 'TranslationIndex',
  },
  {
    icon: 'subtitles',
    title: 'Terms',
    route: 'TermIndex',
  },
  {
    icon: 'handshake',
    title: 'Agreements',
    route: 'TermsAgreementIndex',
  },
];
