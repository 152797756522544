<template>
  <v-container
    v-if="team"
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      @create="$refs.newTeamMemberDialog.open({})"
      :back-route="{ name: 'TeamIndex' }"
      :title="team.name"
      back-name="Teams"
      create-text="Add member"
      createable
    />

    <v-toolbar class="mb-3 px-4 bb-1">
      <v-row>
        <v-col class="mxw-340">
          <v-text-field
            v-model="filters.query"
            :aria-label="$t('Filter members by name or email')"
            :placeholder="$t('Filter members by name or email')"
            color="secondary"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
            tracked
          />
        </v-col>
      </v-row>
    </v-toolbar>

    <v-data-table-server
      @click:row="handleRowClick"
      :headers="tableHeaders"
      :items="filteredItems"
      :loading="processing"
      class="mt-3 mx-3 b-radius-0 b-1 bc-outlined-gray"
      no-data-text="No members found"
      fixed-header
      hide-default-footer
      hover
    >
      <template #item.actions="{ item }">
        <div class="d-flex justify-end align-center">
          <ActionMenu
            @click:action:delete="destroy(item)"
            :items="[{ title: 'Remove', event: 'delete' }]"
            button-icon="more_vert"
          />
        </div>
      </template>
    </v-data-table-server>

    <VerticalSpacer :min-height="14" />

    <ResourceDialog
      @save="create"
      ref="newTeamMemberDialog"
      :fields="newMemberFields"
      :processing="processing"
      title="Add member to team"
    />
  </v-container>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Api from '@/specialist/services/bright_finder';
import PageTitle from '@/shared/components/PageTitle.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { useRoute, useRouter } from 'vue-router';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();

const tableHeaders = ref([
  {
    title: 'Name',
    value: 'meta.member.name',
  },
  {
    title: 'Email',
    value: 'meta.member.email',
  },
  {
    title: '',
    value: 'actions',
  },
]);

const filters = ref({
  query: null,
});
const items = ref([]);
const newTeamMemberDialog = ref(null);
const processing = ref(true);
const specialists = ref([]);
const team = ref(null);

// Basic search functionality, FE only
const filteredItems = computed(() => {
  if (!filters.value.query) return items.value;

  return items.value.filter((item) => {
    const nameMatches = item.meta.member.name
      .toLowerCase()
      .includes(filters.value.query.toLowerCase());
    const emailMatches = item.meta.member.email
      .toLowerCase()
      .includes(filters.value.query.toLowerCase());
    return nameMatches || emailMatches;
  });
});

const newMemberCandidates = computed(() => {
  return specialists.value.filter(
    (specialist) => !items.value.find((teamMember) => teamMember.member_id === specialist.id),
  );
});

const newMemberFields = computed(() => {
  return [
    {
      text: 'New member',
      value: 'member_id',
      items: newMemberCandidates.value,
      itemText: 'name',
      itemValue: 'id',
    },
  ];
});

async function create(member) {
  processing.value = true;

  const response = await Api.organization.teamMembership.create({
    ...member,
    team_id: route.params.id,
  });

  if (response.status !== 201) return;

  newTeamMemberDialog.value.close();
  await loadTeamMemberships();

  processing.value = false;
}

async function destroy(member) {
  // eslint-disable-next-line no-alert
  if (!confirm('Are you sure you want to remove this member from the team?')) return;

  const response = await Api.organization.teamMembership.destroy(member.id);
  if (response.status !== 200) return;

  await loadTeamMemberships();
  eventBus.chime('Removed');
}

function handleRowClick(event, { item }) {
  router.push({
    name: 'SpecialistShow',
    params: { id: item.member_id },
  });
}

async function load() {
  await loadTeam();
  await loadTeamMemberships();
  await loadSpecialists();

  processing.value = false;
}

async function loadSpecialists() {
  const response = await Api.organization.member.index({ is_specialist: true });
  specialists.value = response.data;
}

async function loadTeam() {
  const response = await Api.organization.team.get(route.params.id);
  team.value = response.data;
}

async function loadTeamMemberships() {
  const response = await Api.organization.teamMembership.index({ team_id: route.params.id });
  items.value = response.data;
}

onMounted(load);
</script>
