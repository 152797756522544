<template>
  <v-container
    v-if="collection"
    class="mxw-1200 pa-6"
  >
    <TitleBar :name="($route.query.name || $route.query.data_type) + ' schemas'" />

    <NullState
      v-if="collection.length == 0"
      @new="$refs.newDialog.open({})"
      new-button-title="New schema"
    />

    <ResourceDialog
      @save="create"
      ref="newDialog"
      :fields="[{ text: 'Name', value: 'name' }]"
      :processing="processing"
      title="New schema"
    />

    <v-row
      v-for="(schema, index) in collection"
      :key="index"
    >
      <v-col>
        <v-card
          :to="{ name: 'SchemaShow', params: { schemaId: schema.id } }"
          border
          flat
          tile
        >
          <v-card-text>
            <v-row class="d-flex align-center">
              <v-col
                class="fs-18 fw-600 c-black"
                cols="8"
              >
                {{ schema.name }}
              </v-col>
              <v-col
                class="fs-16 c-ligh-black ta-right"
                cols="4"
              >
                {{ schema.owned_count + ' records' }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <NewButton
      v-if="collection.length > 0"
      @click="$refs.newDialog.open({})"
      :processing="processing"
      class="mt-4"
      text="New schema"
    />
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ResourceDialog,
  },

  mixins: [API],
  data() {
    return {
      processing: false,
      collection: null,
    };
  },

  watch: {
    '$route.query.data_type': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.loadSchemas();
        }
      },
    },
  },

  methods: {
    create(newSchema) {
      this.processing = true;
      this.api.organization.schema.create(
        { data_type: this.$route.query.data_type, name: newSchema.name },
        (resp) => {
          this.processing = false;
          this.changed = false;
          this.$router.push({ name: 'SchemaShow', params: { schemaId: resp.data.id } });
        },
        (err) => {
          this.processing = false;
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },

    async loadSchemas() {
      const { data } = await this.api.organization.schema.index({
        data_type: this.$route.query.data_type,
      });
      this.collection = data;
    },
  },
};
</script>
