<template>
  <v-row
    v-if="!hidden"
    class="mb-4 d-flex align-center"
    dense
  >
    <v-col cols="6">
      <v-textarea
        v-model="draftTranslation.key"
        @update:model-value="changed = true"
        placeholder="English text"
        rows="3"
        variant="filled"
        hide-details
      />
    </v-col>
    <v-col
      class="d-flex align-center"
      cols="6"
    >
      <v-textarea
        v-model="draftTranslation.value"
        @update:model-value="changed = true"
        rows="3"
        variant="filled"
        hide-details
      />

      <v-btn
        v-show="!draftTranslation.id || changed"
        @click="save"
        :disabled="!draftTranslation.key || !draftTranslation.value"
        :loading="processing"
        class="ms-3"
        color="primary"
      >
        Save
      </v-btn>

      <template v-if="draftTranslation.id">
        <v-btn
          @click="destroy"
          :loading="processing"
          class="ms-2"
          color="primary"
          variant="text"
          icon
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-col>
    <v-col>
      <span class="fs-12">#{{ translation.id }}</span>
    </v-col>
  </v-row>
</template>

<script>
import Api from '@/specialist/services/bright_finder';

export default {
  compatConfig: { MODE: 3 },

  props: {
    default: {
      type: String,
      default: null,
    },
    translation: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      canceled: false,
      changed: false,
      draftTranslation: {},
      hidden: false,
      processing: false,
    };
  },
  created() {
    this.draftTranslation = JSON.parse(JSON.stringify(this.translation));
  },

  methods: {
    destroy() {
      this.processing = true;
      Api.organization.translation.destroy(this.draftTranslation.id, () => {
        this.processing = false;
        this.hidden = true;
      });
    },

    save() {
      this.processing = true;
      Api.organization.translation.updateOrCreate(
        this.draftTranslation,
        (resp) => {
          this.draftTranslation = resp.data;
          this.changed = false;
          this.processing = false;
        },
        (err) => {
          this.processing = false;
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },
  },
};
</script>
