<template>
  <v-container
    v-if="schema"
    class="mxw-1200"
  >
    <BreadcrumbTitle
      :back-name="schema.name"
      name="Syncs"
    />

    <v-row
      v-for="(upload, index) in uploads"
      :key="index"
    >
      <v-col>
        <v-card
          @click="$router.push({ name: 'UploadShow', params: { uploadId: upload.id } })"
          border
          flat
          tile
        >
          <v-card-text>
            <v-row>
              <v-col
                class="fs-18 fw-600 c-black"
                cols="12"
                md="6"
              >
                {{ upload.name }}
              </v-col>
              <v-col
                class="fs-16 c-light-black ta-right"
                cols="12"
                md="6"
              >
                <LongDateTime :date="upload.updated_at" />
                <span class="mx-1">-</span>
                <span
                  >{{ upload.count_added }} added / {{ upload.count_updated }} updated /
                  {{ upload.count_error }} errors</span
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      v-if="uploads.length == 0"
      class="d-flex justify-center px-2"
    >
      <v-col
        class="mb-8"
        cols="3"
      >
        <v-img :src="$a.url('icons.nullstate')" />
      </v-col>
      <v-col
        class="ta-center"
        cols="12"
      >
        <v-btn
          :to="{ name: 'UploadNew', query: { schemaId: schema.id } }"
          color="primary"
          prepend-icon="add"
          size="x-large"
        >
          {{ $t('New sync') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row
      v-else
      class="mt-4"
      dense
    >
      <v-col>
        <v-btn
          :to="{ name: 'UploadNew', query: { schemaId: schema.id } }"
          color="primary"
          size="large"
        >
          New sync
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import LongDateTime from '@/shared/components/LongDateTime.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LongDateTime,
  },

  data() {
    return {
      schema: null,
      uploads: [],
    };
  },

  watch: {
    '$route.params.schemaId': {
      immediate: true,
      handler() {
        this.loadSchema();
        this.loadUploads();
      },
    },
  },

  methods: {
    async loadSchema() {
      const { data } = await Api.organization.schema.get(this.$route.params.schemaId);
      this.schema = data;
    },

    async loadUploads() {
      const { data } = await Api.organization.upload.index({
        schema_id: this.$route.params.schemaId,
      });
      this.uploads = data;
    },
  },
};
</script>
