const toAcronym = (string) => {
  if (!string) {
    return '';
  }
  return string
    .split(' ')
    .map((word) => word[0])
    .join('')
    .toUpperCase();
};

const toSentenceCase = (string = '') => {
  // https://github.com/huynhsamha/js-convert-case/blob/master/src/modules/js-sentencecase/index.ts
  if (!string) return '';

  const textcase = String(string)
    .replace(/^[^A-Za-z0-9]*|[^A-Za-z0-9]*$/g, '')
    .replace(/([a-z])([A-Z])/g, (m, a, b) => `${a}_${b.toLowerCase()}`)
    .replace(/[^A-Za-z0-9]+|_+/g, ' ')
    .toLowerCase();

  return textcase.charAt(0).toUpperCase() + textcase.slice(1);
};

export { toAcronym, toSentenceCase };
