<template>
  <v-card-text class="pt-4 px-0">
    <span
      v-if="showHeader"
      class="fw-600 mb-2 d-block"
    >
      {{ $t('Messages') }}
    </span>

    <template v-if="canViewMessages">
      <p
        v-if="messages.length === 0"
        class="c-light-black fs-14"
      >
        {{ $t('No messages sent yet.') }}
      </p>

      <SubsidyMessage
        v-for="message in messages"
        :key="message.id"
        v-intersect="{
          handler: () => readMessage(message.id),
          options: { threshold: [1.0] },
        }"
        :message="message"
        class="mb-3"
        tabindex="0"
      />

      <template v-if="canSendMessages">
        <v-divider class="my-6" />

        <div
          id="editor"
          class="w-100pc br-0 mb-3"
        />

        <v-btn
          @click="templateDialog.open()"
          :loading="processing"
          append-icon="expand_more"
          class="mb-2"
          color="primary"
          variant="outlined"
          block
        >
          {{ $t('Select template') }}
        </v-btn>

        <v-btn
          @click="createMessage"
          :loading="processing"
          color="primary"
          block
        >
          {{ $t('Send message') }}
        </v-btn>
      </template>
    </template>

    <template v-else>
      <NullState
        :image-height="90"
        title="You do not have permission to view instant Messages."
      />
    </template>
  </v-card-text>

  <ResourceDialog
    ref="templateDialog"
    :max-width="600"
    title="Select a template"
  >
    <template #form>
      <v-btn
        v-for="template in messageTemplates"
        @click="selectTemplate(template)"
        :key="template.id"
        class="mb-1"
        variant="outlined"
        block
        tile
      >
        {{ template.name }}
      </v-btn>
    </template>
  </ResourceDialog>
</template>

<script setup>
import '@toast-ui/editor/dist/toastui-editor.css';
import Api from '@/shared/services/all_bright_finder';
import Editor from '@toast-ui/editor';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SubsidyMessage from './SubsidyMessage.vue';
import useEventBus from '@/shared/composables/useEventBus';

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const store = useStore();
const eventBus = useEventBus();
const { t } = useI18n();

let editor = null;

const props = defineProps({
  messageTemplates: {
    type: Array,
    default: null,
  },
  subsidy: {
    type: Object,
    default: null,
  },
  showHeader: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['messageRead']);

const messages = ref([]);
const unreadMessages = ref([]);
const newMessageText = ref(null);
const processing = ref(null);
const templateDialog = ref(null);

const canSendMessages = computed(() => store.state.profile?.org_instant_messaging_create);

const canViewMessages = computed(() => store.state.profile?.org_instant_messaging_access);

async function createMessage() {
  processing.value = true;
  newMessageText.value = editor.getMarkdown().replaceAll('\\_', '_').replaceAll('\n', '<br>');

  const response = await Api.organization.message
    .create({ group_id: props.subsidy.group_id, text: newMessageText.value })
    .catch((error) => eventBus.error(error));

  processing.value = false;
  if (!response?.data) return;

  editor.reset();
  newMessageText.value = null;
  messages.value.push(response.data);
}

async function readMessage(id) {
  await Api.organization.message.get(id);
  unreadMessages.value = unreadMessages.value.filter((message) => message.id !== id);
  emit('messageRead');
}

async function loadMessages() {
  const response = await Api.organization.message.index({
    days: 10000,
    group_id: props.subsidy.group_id,
  });
  messages.value = response.data.reverse();
  const unreadMessagesFromOthers = messages.value.filter(
    (message) => !!message.read_at && message.member_id !== store.state.profile.id,
  );
  unreadMessages.value = unreadMessagesFromOthers.filter(
    (message) => !message.meta.member_is_specialist,
  );
  unreadMessages.value.forEach((message) => readMessage(message.id));
}

function selectTemplate(template) {
  editor.insertText(template.content);
  templateDialog.value.close();
}

onMounted(async () => {
  await loadMessages();
  setTimeout(() => {
    editor = new Editor({
      el: document.querySelector('#editor'),
      height: '300px',
      initialEditType: 'wysiwyg',
      // Bug: https://github.com/nhn/tui.editor/issues/3206
      initialValue: ' ',
      placeholder: t('Type message here'),
      previewStyle: 'vertical',
    });
  }, 100);
});
</script>
