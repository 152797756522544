<template>
  <v-card
    border
    tile
  >
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col>
          <v-row dense>
            <v-col>
              <router-link
                :to="formRoute"
                class="fs-16 fw-600"
              >
                {{ $t($store.state.schemas[form.schema_id]?.name) }}
              </router-link>
            </v-col>
          </v-row>
          <v-row
            v-if="context"
            dense
          >
            <v-col class="fs-14">
              <span v-text="context" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <LongDateTime
                :date="form.submitted_at || form.reviewed_at || form.created_at"
                :prefix="datePrefix"
                class="fs-14"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col class="ta-right">
          <ActionMenu
            @click:action:status="$emit('status', $event)"
            :button-color="buttonColor"
            :button-title="form.status || 'Draft'"
            :dark="reviewed"
            :items="statusActions"
            button-icon-side="right"
            button-outlined
            left
          />

          <ActionMenu
            @click:action:destroy="$emit('destroy')"
            :items="actionItems"
            button-icon="more_vert"
            left
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  back: {
    default: 'provider',
    type: String,
  },
  form: {
    type: Object,
    default: null,
  },
  provider: {
    type: Object,
    default: null,
  },
  programs: {
    type: Array,
    default: null,
  },
});

defineEmits(['destroy', 'status']);

const enrollment = ref(null);

const actionItems = computed(() => {
  const actions = [
    {
      avatar: 'open_in_new',
      link: formRoute.value,
      title: 'Open',
    },
  ];

  if (!store.state.profile.org_enrollments_agent) {
    actions.push({ event: 'destroy', avatar: 'delete', title: 'Delete' });
  }

  return actions;
});

const formRoute = computed(() => {
  // const destination = store.state.profile.org_forms_review ? 'FormReview' : 'FormShow';
  return { name: 'FormShow', params: { formId: props.form?.id }, query: { back: props.back } };
});

const buttonColor = computed(() => {
  if (props.form.status === 'Approved') return 'green';
  if (props.form.status === 'Denied') return 'red';
  return 'primary';
});

const context = computed(() => {
  if (enrollment.value) {
    return [
      props.programs.find((program) => program.id === enrollment.value.program_id)?.name ||
        props.provider.name,
      enrollment.value.name,
    ]
      .filter((item) => !!item)
      .join(' | ');
  }
  return null;
});

const datePrefix = computed(() => {
  if (props.form.reviewed_at) return 'Reviewed on';
  if (props.form.submitted_at) return 'Submitted on';
  return 'Created';
});

const statusActions = computed(() => {
  if (store.state.profile.org_enrollments_agent) return [];

  return [
    {
      event: 'status',
      avatar: 'done',
      title: props.form.status === 'Approved' ? 'Approved' : 'Approve',
    },
    {
      event: 'status',
      avatar: 'remove_done',
      title: props.form.status === 'Denied' ? 'Denied' : 'Deny',
    },
  ];
});

const reviewed = computed(() => ['Approved', 'Denied'].includes(props.form.status));

async function loadEnrollment() {
  if (props.form?.enrollment_id) {
    const { data } = await Api.organization.enrollment.get(props.form.enrollment_id);
    enrollment.value = data;
  }
}

onMounted(async () => {
  await loadEnrollment();
});
</script>
