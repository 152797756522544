<template>
  <v-card
    id="family-member-table"
    border
    flat
    tile
  >
    <v-card-title>
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col>
          {{ $t('Members') }}
        </v-col>
        <v-col
          v-if="enableMemberInvite && !isAgent"
          class="d-flex justify-end"
        >
          <v-btn
            @click="updateQuery({ action: 'create-member' })"
            color="primary"
            prepend-icon="add"
            variant="flat"
          >
            {{ $t('Invite new member') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />

    <v-card-text>
      <v-data-table
        :headers="tableHeaders"
        :hide-default-footer="members.length == 0"
        :hide-default-header="members.length == 0"
        :items="members"
        :loading="processing"
        item-key="id"
        disable-sort
        fixed-header
      >
        <template #item.terms_agreement_accepted_at="{ item }">
          <LongDate :date="item.terms_agreement_accepted_at" />
        </template>

        <template #item.text_opted_in_at="{ item }">
          <LongDate :date="item.text_opted_in_at" />
        </template>

        <template #item.actions="{ item: member }">
          <div class="d-flex justify-end align-center">
            <ActionMenu
              @click:action:delete="destroy(member)"
              @click:action:edit="updateQuery({ memberId: member.id })"
              :items="actionItems"
              button-icon="more_vert"
              label="member-action-menu"
            />
          </div>
        </template>

        <template #no-data>
          <NullState
            class="my-4"
            title="Family has no members."
          />
        </template>
      </v-data-table>
    </v-card-text>

    <MemberEditor
      @change="loadMembers"
      :api="api"
      :group-id="groupId"
    />
  </v-card>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import LongDate from '@/shared/components/LongDate.vue';
import MemberEditor from '@/specialist/components/group/MemberEditor.vue';
import NullState from '@/shared/components/NullState.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useI18n } from 'vue-i18n';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const store = useStore();
const { t } = useI18n();
const { updateQuery } = useRouterHelper();

const props = defineProps({
  groupId: {
    type: String,
    default: null,
  },
});

const members = ref([]);
const processing = ref(false);

const actionItems = computed(() => {
  const actions = [{ event: 'edit', title: 'Edit' }];
  if (store.state.profile.org_groups_delete) {
    actions.push({ event: 'delete', title: 'Delete' });
  }
  return actions;
});

const enableMemberInvite = computed(() => {
  return store.state.profile.org_groups_create || store.state.profile.org_subsidies_agent;
});

const isAgent = computed(() => {
  return store.state.profile.org_enrollments_agent && store.state.profile.org_subsidies_agent;
});

const tableHeaders = computed(() => {
  return [
    { title: t('Name'), value: 'name' },
    { title: t('Email'), value: 'email' },
    { title: t('Phone'), value: 'phone' },
    { title: t('Accepted terms'), value: 'terms_agreement_accepted_at' },
    { title: t('Text opt-in'), value: 'text_opted_in_at' },
    { title: t('Actions'), value: 'actions' },
  ];
});

watch(
  () => props.groupId,
  async (newVal) => {
    if (!newVal) return;

    await loadMembers();
  },
  { immediate: true },
);

function destroy(member) {
  // eslint-disable-next-line no-restricted-globals, no-alert
  if (confirm(`Are you sure you want to delete ${member.name}?`)) {
    api.organization.member.destroy(
      member.id,
      () => loadMembers(),
      (err) => eventBus.error(err),
    );
  }
}

async function loadMembers() {
  processing.value = true;

  const { data } = await api.organization.member.index({ group_id: props.groupId });
  members.value = data;
  processing.value = false;
}
</script>

<style>
#family-member-table {
  .v-data-table-header__content {
    font-weight: 800;
  }
}
</style>
