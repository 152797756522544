<template>
  <v-card
    class="mb-4"
    border
    tile
  >
    <v-card-title class="fs-22 fw-600">
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col
          cols="12"
          role="heading"
          sm="6"
          >{{ $t('Application') }}</v-col
        >
        <v-col
          v-if="loaded"
          class="ta-right"
          cols="12"
          sm="6"
        >
          <v-btn
            :disabled="!subsidy.submitted_at"
            :href="link"
            color="primary"
            prepend-icon="download"
            variant="outlined"
          >
            <span
              v-if="$vuetify.display.mdAndUp"
              v-t="'Download'"
            />
          </v-btn>
          <v-menu
            v-model="isSettingsMenuOpen"
            :close-on-content-click="false"
            :min-width="250"
            max-width="300"
            offset="5"
          >
            <template
              v-if="shouldShowSettingsButton"
              #activator="{ props }"
            >
              <v-btn
                v-bind="props"
                class="ms-3"
                data-testid="settings-button"
                icon="settings"
                variant="text"
              />
            </template>

            <v-list
              class="bc-light-gray b-1 py-0"
              tile
            >
              <template v-if="subsidyProgramEnablesReviewerMode">
                <v-divider />

                <v-list-item>
                  <v-list-item-title>{{ $t('View as reviewer') }}</v-list-item-title>
                  <template #append>
                    <v-switch
                      v-model="reviewModeEnabled"
                      @update:model-value="handleReviewModeChange"
                      class="ms-4"
                      hide-details
                      inset
                    />
                  </template>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider class="mb-3" />
    <v-card-text>
      <template v-if="!loaded">
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </template>
      <template v-else>
        <v-form
          v-model="formValid"
          @submit.prevent
          ref="subsidyForm"
        >
          <template v-if="reviewModeEnabled">
            <div
              v-for="(section, sectionIndex) in subsidyProgram.reviewer_question_sequence"
              :key="sectionIndex"
            >
              <v-card
                @click="toggleSection(sectionIndex)"
                :id="`application_section_${sectionIndex}`"
                class="mb-4"
                border
                flat
                tile
              >
                <v-card-title>
                  <v-row class="d-flex align-center">
                    <v-col>
                      {{ section.title }}
                    </v-col>
                    <v-col class="ta-right">
                      <v-btn
                        :aria-label="$t('Expand section')"
                        variant="text"
                        icon
                      >
                        <v-icon>
                          {{ expanded(sectionIndex) ? 'expand_less' : 'expand_more' }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>

              <LinearQuestionSet
                v-show="expanded(sectionIndex)"
                @change="handleChange"
                @change:attachments="loadAttachments()"
                :attachment-group-id="group.id"
                :attachment-owner-id="subsidyProgram.id"
                :attachment-owner-type="'SubsidyProgram'"
                :attachments="attachments"
                :color="null"
                :elevation="0"
                :models="models"
                :processing="processing"
                :questions="sortLinearQuestions(reviewerQuestions, section.questions)"
                :readonly="!canEditApplication"
                :schemas="[groupSchema, childSchema]"
                condensed
                expanded
                hide-actions
                outlined
                show-indicators
                tile
                very-dense
              />
            </div>
          </template>
          <template v-else>
            <v-card
              class="mb-4"
              elevation="0"
              border
              tile
            >
              <v-card-text>
                <v-row class="fs-16">
                  <LabeledTextfield
                    v-model="child.first_name"
                    @input="handleMandatoryFieldChanged"
                    :aria-label="$t('Enter a first name')"
                    :readonly="!canEditApplication"
                    cols="4"
                    message="First name"
                    mandatory
                    very-dense
                  />
                  <LabeledTextfield
                    v-model="child.middle_name"
                    @change="handleChange"
                    :aria-label="$t('Enter a middle name')"
                    :readonly="!canEditApplication"
                    cols="4"
                    message="Middle name"
                    very-dense
                  />
                  <LabeledTextfield
                    v-model="child.last_name"
                    @input="handleMandatoryFieldChanged"
                    :aria-label="$t('Enter a last name')"
                    :readonly="!canEditApplication"
                    cols="4"
                    message="Last name"
                    mandatory
                    very-dense
                  />
                </v-row>
                <v-row class="fs-16 mb-2">
                  <LabeledDateOfBirth
                    v-model="child.dob"
                    @change="handleMandatoryFieldChanged"
                    :aria-label="$t('Enter a date of birth')"
                    :readonly="!canEditApplication"
                    cols="12"
                    message="Date of birth"
                    mandatory
                    very-dense
                  />
                </v-row>
                <v-btn
                  :to="{ name: 'GroupShow', params: { groupId: subsidy.group_id } }"
                  class="px-0"
                  color="primary"
                  target="_blank"
                  variant="text"
                  >{{ $t('View family information') }}</v-btn
                >
              </v-card-text>
            </v-card>

            <FormQuestion
              :color="null"
              :elevation="0"
              :subtitle="subsidyProgram.home_address_subtitle"
              :title="subsidyProgram.home_address_title || 'Family\'s primary home address'"
              class="mb-4"
              hide-actions
              outlined
              tile
              very-dense
            >
              <v-row>
                <LabeledTextfield
                  v-model="group.home_address"
                  @input="handleMandatoryFieldChanged"
                  :mandatory="!group.experiencing_homelessness"
                  :readonly="!canEditApplication"
                  message="Address"
                  very-dense
                />
                <LabeledTextfield
                  v-model="group.home_city"
                  @input="handleMandatoryFieldChanged"
                  :mandatory="!group.experiencing_homelessness"
                  :readonly="!canEditApplication"
                  md="4"
                  message="City"
                  very-dense
                />
                <LabeledSimpleSelect
                  v-model="group.home_state"
                  @change="handleMandatoryFieldChanged"
                  :items="$a.assets.states"
                  :mandatory="!group.experiencing_homelessness"
                  :readonly="!canEditApplication"
                  md="4"
                  message="State"
                  placeholder="Select one"
                  very-dense
                />
                <LabeledTextfield
                  v-model="group.home_zip"
                  @input="handleMandatoryFieldChanged"
                  :mandatory="!group.experiencing_homelessness"
                  :readonly="!canEditApplication"
                  md="4"
                  message="Zip"
                  very-dense
                />
              </v-row>
              <v-row
                v-if="subsidyProgram.enable_homeless_address_option"
                dense
              >
                <v-checkbox
                  v-model="group.experiencing_homelessness"
                  @update:model-value="handleChange"
                  :label="
                    $t(
                      subsidyProgram.homeless_attestation ||
                        'My family is experiencing homelessness.',
                    )
                  "
                  :readonly="!canEditApplication"
                />
              </v-row>
            </FormQuestion>

            <QuestionSet
              v-model="group"
              @change="handleChange"
              @change:attachments="loadAttachments()"
              :attachment-group-id="group.id"
              :attachment-owner-id="subsidyProgram.id"
              :attachment-owner-type="'SubsidyProgram'"
              :attachments="attachments"
              :color="null"
              :elevation="0"
              :processing="processing"
              :questions="groupEligibilityQuestions"
              :readonly="!canEditApplication"
              :schema="groupSchema.definition"
              condensed
              expanded
              hide-actions
              outlined
              show-indicators
              tile
              very-dense
            />

            <QuestionSet
              v-model="child"
              @change="handleChange"
              @change:attachments="loadAttachments()"
              :attachment-group-id="group.id"
              :attachment-owner-id="subsidyProgram.id"
              :attachment-owner-type="'SubsidyProgram'"
              :attachment-tags-supplements="[subsidy.id]"
              :attachments="attachments"
              :color="null"
              :elevation="0"
              :header="$t('Child - ') + child.first_name"
              :processing="processing"
              :questions="childEligibilityQuestions"
              :readonly="!canEditApplication"
              :schema="childSchema.definition"
              condensed
              expanded
              hide-actions
              outlined
              show-indicators
              tile
              very-dense
            />

            <QuestionSet
              v-model="group"
              @change="handleChange"
              @change:attachments="loadAttachments()"
              :attachment-group-id="group.id"
              :attachment-owner-id="subsidyProgram.id"
              :attachment-owner-type="'SubsidyProgram'"
              :attachments="attachments"
              :color="null"
              :elevation="0"
              :processing="processing"
              :questions="groupOtherQuestions"
              :readonly="!canEditApplication"
              :schema="groupSchema.definition"
              condensed
              expanded
              hide-actions
              outlined
              show-indicators
              tile
              very-dense
            />

            <QuestionSet
              v-model="child"
              @change="handleChange"
              @change:attachments="loadAttachments()"
              :attachment-group-id="group.id"
              :attachment-owner-id="subsidyProgram.id"
              :attachment-owner-type="'SubsidyProgram'"
              :attachment-tags-supplements="[subsidy.id]"
              :attachments="attachments"
              :color="null"
              :elevation="0"
              :header="$t('Child - ') + child.first_name"
              :processing="processing"
              :questions="childOtherQuestions"
              :readonly="!canEditApplication"
              :schema="childSchema.definition"
              condensed
              expanded
              hide-actions
              outlined
              show-indicators
              tile
              very-dense
            />
          </template>
        </v-form>

        <v-card
          @click="documentsExpanded = !documentsExpanded"
          id="application_section_documents"
          class="mb-4"
          border
          flat
          tile
        >
          <v-card-title>
            <v-row class="d-flex align-center">
              <v-col>
                {{ $t('Documents') }}
              </v-col>
              <v-col class="ta-right">
                <v-btn
                  :aria-label="$t('Display documentation')"
                  variant="text"
                  icon
                >
                  <v-icon>
                    {{ documentsExpanded ? 'expand_less' : 'expand_more' }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
        <div v-show="documentsExpanded">
          <div
            v-for="question in groupVerificationQuestions"
            :key="question.id"
            class="mb-6"
          >
            <FormQuestion
              :color="null"
              :elevation="0"
              :mandatory="question.mandatory"
              :subtitle="question.verification_subtitle"
              :title="question.verification_title"
              dense
              hide-actions
              outlined
              show-indicators
              tile
              very-dense
            >
              <AttachmentUploader
                @uploaded="attachments.push($event)"
                :ref="['uploader', question.id].join('')"
                :owner="{
                  group_id: subsidy.group_id,
                  type: 'SubsidyProgram',
                  id: subsidyProgram.id,
                  tag: question.id,
                  tags: [subsidy.id, question.id],
                }"
                class="mb-4"
              />
              <AttachmentList
                @change="loadAttachments()"
                @delete="loadAttachments"
                :attachments="attachments.filter((attachment) => attachment.tag == question.id)"
                class="mb-6"
              />
            </FormQuestion>
          </div>

          <div
            v-for="question in childVerificationQuestions"
            :key="question.id"
            class="mb-6"
          >
            <FormQuestion
              :color="null"
              :elevation="0"
              :mandatory="question.mandatory"
              :subtitle="question.verification_subtitle"
              :title="question.verification_title"
              hide-actions
              outlined
              show-indicators
              tile
              very-dense
            >
              <AttachmentUploader
                @uploaded="attachments.push($event)"
                :ref="['uploader', question.id, child.id].join('')"
                :owner="{
                  group_id: subsidy.group_id,
                  type: 'SubsidyProgram',
                  id: subsidyProgram.id,
                  tag: child.id + question.id,
                  tags: [child.id, subsidy.id, question.id],
                }"
                class="mb-4"
              />
              <AttachmentList
                @delete="loadAttachments"
                :attachments="
                  attachments.filter((attachment) => attachment.tag == child.id + question.id)
                "
                class="mb-6"
              />
            </FormQuestion>
          </div>

          <div v-if="subsidyProgram.verify_child">
            <v-divider class="my-4" />
            <FormQuestion
              :color="null"
              :elevation="0"
              :mandatory="subsidyProgram.verify_child_mandatory"
              :subtitle="subsidyProgram.verify_child_subtitle"
              :title="subsidyProgram.verify_child_title"
              class="mb-6"
              hide-actions
              outlined
              show-indicators
              tile
              very-dense
            >
              <AttachmentUploader
                @uploaded="attachments.push($event)"
                :owner="{
                  group_id: subsidy.group_id,
                  type: 'SubsidyProgram',
                  id: subsidyProgram.id,
                  tag: 'child-documents-' + child.id,
                  tags: ['child-documents', child.id, subsidy.id],
                }"
                class="mb-4"
              />
              <AttachmentList
                @delete="loadAttachments"
                :attachments="
                  attachments.filter(
                    (attachment) => attachment.tag == 'child-documents-' + child.id,
                  )
                "
                :empty-label="$t('No documents provided.')"
                :processing="processing"
              />
            </FormQuestion>
          </div>

          <template v-if="subsidyProgram.verify_home_address">
            <FormQuestion
              :color="null"
              :elevation="0"
              :mandatory="subsidyProgram.verify_home_address_mandatory"
              :subtitle="
                subsidyProgram.verify_home_address_subtitle ||
                'Ex. A copy of current lease, proof of homeownership, or utility bill (with service or premise address listed) such as your bill for gas, electric, water, or cable.'
              "
              :title="
                subsidyProgram.verifiy_home_address_title ||
                'Upload a document to verify your current address'
              "
              class="mb-6"
              dense
              hide-actions
              no-print
              outlined
              show-indicators
              tile
              very-dense
            >
              <AttachmentUploader
                @uploaded="attachments.push($event)"
                ref="uploader_residency"
                :owner="{
                  group_id: subsidy.group_id,
                  type: 'SubsidyProgram',
                  id: subsidyProgram.id,
                  tag: 'proof-of-residency',
                  tags: ['proof-of-residency', subsidy.id],
                }"
                class="mb-4"
              />
              <AttachmentList
                @delete="loadAttachments"
                :attachments="
                  attachments.filter((attachment) => attachment.tag == 'proof-of-residency')
                "
                class="mb-6"
              />
            </FormQuestion>
          </template>
        </div>

        <template v-if="isEsignDisplayed">
          <v-card
            @click="attestationExpanded = !attestationExpanded"
            class="mb-4"
            border
            flat
            tile
          >
            <v-card-title>
              <v-row class="d-flex align-center">
                <v-col>
                  {{ $t('Attestation') }}
                </v-col>
                <v-col class="ta-right">
                  <v-btn
                    :aria-label="$t('Display attestation')"
                    variant="text"
                    icon
                  >
                    <v-icon>
                      {{ attestationExpanded ? 'expand_less' : 'expand_more' }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>

          <template v-if="attestationExpanded">
            <v-card
              class="bg-super-light-gray bc-light-gray"
              border
              flat
              tile
            >
              <v-card-text>
                <AttestationRecord :revision="lastRevision" />
                <v-row class="fs-14">
                  <v-col>
                    <p class="mb-0">
                      {{ [lastRevision.author_name, lastRevision.author_email].join(', ') }}
                    </p>
                    <p class="mb-0">
                      <LongDateTime
                        :date="lastRevision.created_at"
                        prefix="Signed "
                      />
                    </p>
                    <p class="mb-0">ID #{{ lastRevision.id }}</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </template>

        <template v-if="!subsidy.submitted_at && needsEnrollment">
          <SubsidyEnrollment
            @createdEnrollment="(enrollment) => $emit('createdEnrollment', enrollment)"
            @loadEnrollments="(enrollments) => $emit('loadEnrollments', enrollments)"
            @updateSubsidy="(subsidy) => $emit('updateSubsidy', subsidy)"
            :enrollments="enrollments"
            :subsidy="subsidy"
            :subsidy-program="subsidyProgram"
          />
        </template>
        <v-card
          border
          flat
          tile
        >
          <SubmittedSubsidyMemo
            :border="false"
            :created-at="subsidy.created_at"
            :created-by="getCreatedByInfo()"
            :owner-id="subsidy.id"
            :owner-type="'Subsidy'"
            :submitted-at="subsidy.submitted_at"
          />
        </v-card>
        <template v-if="displayEnrollmentsMissing && !submittable">
          <v-alert
            v-model="displayEnrollmentsMissing"
            :timeout="-1"
            center="true"
            class="my-3"
            color="primary"
            tile
          >
            <p
              v-if="needsEnrollment && enrollments.length === 0"
              v-t="'Must assign at least one enrollment before submission'"
              class="fs-16 fw-500 c-white"
            />
            <p
              v-if="needsEnrollment && enrollments.length > 0"
              v-t="'Must assign a program to each enrollment before submission'"
              class="fs-16 fw-500 c-white"
            />
          </v-alert>
        </template>
        <template v-if="!subsidy.submitted_at">
          <v-row class="my-3">
            <v-col>
              <v-btn
                @click="validateAndSubmit"
                :disabled="changed"
                :loading="processing"
                color="primary"
                block
                >{{ $t('Submit') }}</v-btn
              >
            </v-col>
          </v-row>
        </template>
        <SaveBar
          v-if="changed"
          @cancel="cancel"
          @save="save"
          :modelValue="changed"
          :processing="processing"
          flat
        />
        <SnackBar
          v-if="shouldShowFormErrors"
          @goToListItem="goToError"
          :listItems="formErrors"
          :model-value="true"
          :timeout="-1"
          text="Please correct the following errors:"
          z-index="100"
        />
      </template>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Api from '@/specialist/services/bright_finder';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import AttestationRecord from '@/shared/components/form/AttestationRecord.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import LabeledDateOfBirth from '@/shared/components/form/LabeledDateOfBirth.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LinearQuestionSet from '@/shared/components/form/LinearQuestionSet.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import SnackBar from '@/shared/components/SnackBar.vue';
import SubmittedSubsidyMemo from '@/specialist/components/subsidy/SubmittedSubsidyMemo.vue';
import SubsidyApplicationData from '@/shared/services/subsidy/application-data.js';
import SubsidyEnrollment from '@/specialist/components/subsidy/SubsidyEnrollment.vue';
import useEventBus from '@/shared/composables/useEventBus';
import useSubsidy from '@/shared/composables/useSubsidy';
import useSubsidyData from '@/shared/composables/useSubsidyData';

const eventBus = useEventBus();
const route = useRoute();
const store = useStore();

const emit = defineEmits(['createdEnrollment', 'loadEnrollments', 'submit', 'updateSubsidy']);

const props = defineProps({
  enrollments: {
    type: Array,
    default: null,
  },
});

const child = defineModel('childData');
const group = defineModel('groupData');
const reviewModeEnabled = defineModel('reviewModeEnabled');
const subsidy = defineModel('subsidy');
const subsidyProgram = defineModel('subsidyProgram');

const attachments = ref(null);
const attestationExpanded = ref(false);
const changed = ref(false);
const displayEnrollmentsMissing = ref(false);
const documentsExpanded = ref(false);
const expandedSections = ref([]);
const firstRevision = ref(null);
const formErrors = ref([]);
const formValid = ref(true);
const includeUnpublished = computed(() => !store.state.profile.org_subsidies_agent);
const isSettingsMenuOpen = ref(false);
const lastRevision = ref(null);
const processing = ref(false);
const revisions = ref(null);
const submittable = ref(false);
const subsidyForm = ref(null);
const subsidyId = ref(subsidy.value.id);

const {
  childEligibilityQuestions,
  childOtherQuestions,
  childSchema,
  childVerificationQuestions,
  groupEligibilityQuestions,
  groupOtherQuestions,
  groupSchema,
  groupVerificationQuestions,
  loadChildAndGroupQuestions,
  loadSubsidyProgramSchemas,
  loadVerificationQuestions,
} = useSubsidyData(includeUnpublished, subsidyId, subsidyProgram);

const { loadReviewModeQuestions, reviewerQuestions, sortLinearQuestions } = useSubsidy({
  subsidyProgram,
});

const canEditApplication = computed(() => {
  if (!props.subsidy) return false;

  const { locked } = props.subsidy;
  const { profile } = store.state;

  return (
    (!locked && (profile.org_subsidies_edit || profile.org_subsidies_agent)) ||
    (locked && profile.org_subsidies_edit && profile.org_subsidies_admin)
  );
});

const canEditEnrollments = computed(() => {
  if (!canEditApplication.value) return false;

  const { profile } = store.state;
  const { locked } = props.subsidy;

  return (
    (!locked && (profile.org_enrollments_edit || profile.org_enrollments_agent)) ||
    (locked && profile.org_enrollments_edit && profile.org_enrollments_admin)
  );
});

const isEsignDisplayed = computed(() => {
  return subsidyProgram.value.enable_esign && lastRevision.value?.author_signed_name;
});

const link = computed(() => {
  const order = reviewModeEnabled.value ? 'reviewer' : 'parent';
  return Api.organization.subsidy.downloadUrl(route.params.id, 'Application', order);
});

const loaded = computed(
  () => groupSchema.value && childSchema.value && revisions.value && attachments.value,
);

const models = computed(() => ({
  Child: child.value,
  Group: group.value,
}));

const needsEnrollment = computed(() => {
  return subsidyProgram.value.allow_enrolled || subsidyProgram.value.allow_preference;
});

const shouldShowFormErrors = computed(() => {
  return formValid.value === false && formErrors.value.length > 0;
});

const shouldShowSettingsButton = computed(() => subsidyProgramEnablesReviewerMode.value);

const subsidyProgramEnablesReviewerMode = computed(
  () => !!subsidyProgram.value.enable_reviewer_sequence,
);

function goToError(error) {
  openAllSections();
  nextTick(() => {
    document.getElementById(error.ref).scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  });
}

function cancel() {
  window.location.reload();
  changed.value = false;
}

function expanded(sectionIndex) {
  return expandedSections.value.includes(sectionIndex);
}

function getCreatedByInfo() {
  if (!props.subsidy?.third_party_application) return null;

  const thirdPartyName = [
    subsidy.value.third_party_first_name,
    subsidy.value.third_party_last_name,
  ].join(' ');

  return [
    [thirdPartyName, subsidy.value.third_party_email].join(', '),
    [group.value.name, group.value.primary_email].join(', '),
  ].join(' on behalf of ');
}

function handleChange() {
  changed.value = true;
}

async function handleReviewModeChange(newVal) {
  isSettingsMenuOpen.value = false;
  reviewModeEnabled.value = newVal;
  await loadQuestions(newVal); // reviewModeEnabled.value doesn't immediately return the new value, so pass in newVal
}

async function loadAttachments() {
  const params = {
    owner_type: 'SubsidyProgram',
    owner_id: subsidy.value.subsidy_program_id,
    group_id: subsidy.value.group_id,
  };
  const resp = await Api.organization.attachment.index(params);
  if (resp?.status !== 200) return;
  attachments.value = resp.data;
}

async function loadQuestions(inReviewMode) {
  // Immediately after reviewModeEnabled is set, we read back the old value, which seems to be an
  // issue with 2-way data binding. Maybe it will be resolved when we get out of compatibility mode
  // and we can switch back from passing in a param to just reading reviewModeEnabled's value
  if (inReviewMode) {
    loadReviewModeQuestions();
    await loadVerificationQuestions();
  } else {
    await loadChildAndGroupQuestions();
  }
}

function loadRevisions() {
  Api.organization.revision.index(
    {
      owner_id: subsidy.value.id,
      owner_type: 'Subsidy',
    },
    (resp) => {
      revisions.value = resp.data;
      firstRevision.value = resp.data[resp.data.length - 1];
      [lastRevision.value] = resp.data.filter(
        (revision) => revision.data.submitted_at && !revision.data.submitted_at[0],
      );
    },
  );
}

function handleMandatoryFieldChanged(value) {
  const isIncomplete = value == null || value.toString().length === 0;
  setSubmittable(isIncomplete);
  changed.value = !isIncomplete;
}

// Section handling
function openSection(section) {
  if (section.title === 'Documents') {
    documentsExpanded.value = true;
    expandedSections.value.splice(0);
    setTimeout(() => {
      document.getElementById('application_section_documents').scrollIntoView();
    }, 100);
  } else {
    documentsExpanded.value = false;
    toggleSection(section.index, true);
    setTimeout(() => {
      document.getElementById(`application_section_${section.index}`).scrollIntoView();
    }, 100);
  }
}

function openAllSections() {
  subsidyProgram.value.reviewer_question_sequence.forEach((_section, sectionIndex) => {
    if (!expandedSections.value.includes(sectionIndex)) {
      toggleSection(sectionIndex);
    }
  });
}

function toggleSection(sectionIndex, closeOthers = false) {
  if (closeOthers) {
    expandedSections.value = [sectionIndex];
  } else if (expandedSections.value.includes(sectionIndex)) {
    expandedSections.value.splice(expandedSections.value.indexOf(sectionIndex), 1);
  } else {
    expandedSections.value.push(sectionIndex);
  }
}

// Saving changes
// We explicitly reset child.value and group.value with the api responses to trigger reactivity.
// It's possible that our vue2 child components break reactivity.
async function saveChildAppData() {
  const response = await SubsidyApplicationData.updateChildData(subsidy.value.id, child.value);
  if (!response) return false;

  child.value = response.data;
  return true;
}

async function saveGroupAppData() {
  const response = await SubsidyApplicationData.updateGroupData(subsidy.value.id, group.value);
  if (!response) return false;

  group.value = response.data;
  return true;
}

async function save() {
  processing.value = true;

  const childAppUpdated = await saveChildAppData();
  if (!childAppUpdated) {
    processing.value = false;
    return;
  }

  const groupAppUpdated = await saveGroupAppData();
  if (!groupAppUpdated) {
    processing.value = false;
    return;
  }

  if (!reviewModeEnabled.value) {
    await loadChildAndGroupQuestions();
  }

  emit('updateSubsidy', subsidy.value);

  processing.value = false;
  changed.value = false;
  eventBus.chime('Saved');
}

// Submit handling
function attemptSubmit() {
  if (submittable.value) {
    emit('submit');
  } else {
    displayEnrollmentsMissing.value = true;
  }
}

function setSubmittable() {
  submittable.value = true;

  if (
    !subsidyProgram.value.allow_preference &&
    !subsidyProgram.value.allow_enrolled &&
    !subsidyProgram.value.allow_program_preference
  )
    return;

  if (props.enrollments.length >= 1 && canEditEnrollments.value) {
    if (!subsidyProgram.value.allow_program_preference) return;
    submittable.value = props.enrollments.every((enrollment) => enrollment.program_id !== null);
    return;
  }

  submittable.value = false; // missing enrollments
}

async function validateAndSubmit() {
  if (!subsidyProgram.value.enable_admin_mandatory_field_enforcement_on_submit === true) {
    if (changed.value) await save();
    attemptSubmit();
    return;
  }

  formErrors.value = [];
  const validation = await subsidyForm.value.validate();
  formValid.value = validation.valid;

  if (validation.valid) {
    if (changed.value) await save();
    attemptSubmit();
  } else {
    validation.errors.forEach((input) => {
      input.errorMessages.forEach((inputError) => {
        formErrors.value.push({
          ref: input.id,
          message: inputError,
        });
      });
    });
  }
}

defineExpose({
  openSection,
});

watch(() => props.enrollments, setSubmittable, { deep: true });

onMounted(async () => {
  loadSubsidyProgramSchemas();
  setSubmittable();
  await loadQuestions(reviewModeEnabled.value);
  await loadAttachments();
  loadRevisions();
});
</script>
