<!-- eslint-disable vue/no-mutating-props, vue/multi-word-component-names -->
<template>
  <div>
    <div
      v-for="(child, index) in search.children"
      :key="index"
      class="mb-5"
    >
      <p class="fs-18 fw-600 c-black mb-3">
        {{ $t('Child') }} {{ index + 1 }} - {{ search.children[index].first_name }}
      </p>

      <v-row>
        <v-col
          cols="12"
          lg="6"
          md="6"
        >
          <v-text-field
            v-model="search.children[index].first_name"
            :label="$t('First name')"
            color="primary"
            variant="filled"
            hide-details
          />
        </v-col>
      </v-row>

      <v-row dense>
        <LabeledDateOfBirth
          v-model="search.children[index].dob"
          cols="12"
          message="Date of birth"
          very-dense
        />
        <LabeledDateOfCare
          v-model="search.children[index].care_needed_date"
          cols="12"
          message="Date care needed"
          very-dense
        />
        <v-col cols="12">
          <v-checkbox
            v-model="child.included"
            :label="'Include ' + (child.first_name || 'child') + ' in search'"
            class="mt-0"
            hide-details
          />
        </v-col>
      </v-row>
    </div>

    <v-row class="ta-center">
      <v-col cols="12">
        <v-btn
          @click="removeChild"
          :disabled="search.children.length == 1"
          class="px-4"
          color="primary"
          variant="text"
          icon
        >
          <v-icon class="c-primary fs-36"> remove_circle </v-icon>
        </v-btn>
        <v-btn
          @click="addChild"
          class="px-4"
          color="primary"
          variant="text"
          icon
        >
          <v-icon
            class="c-primary fs-36"
            color="primary"
          >
            add_circle
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LabeledDateOfBirth from '@/shared/components/form/LabeledDateOfBirth.vue';
import LabeledDateOfCare from '@/shared/components/form/LabeledDateOfCare.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LabeledDateOfBirth,
    LabeledDateOfCare,
  },

  props: {
    search: {
      type: Object,
      default: null,
    },
  },

  methods: {
    addChild() {
      // eslint-disable-next-line vue/no-mutating-props
      this.search.children.push({ included: true });
    },

    removeChild() {
      // eslint-disable-next-line vue/no-mutating-props
      this.search.children.pop();
    },
  },
};
</script>
