<template>
  <v-container
    class="px-0 pt-0"
    fluid
  >
    <div class="mt-3 mx-3">
      <h1>src/specialist/views/data/DataAvailability.vue</h1>
    </div>
  </v-container>
</template>

<script setup></script>
