<template>
  <v-container
    v-if="collection"
    class="mxw-1200 pa-6"
  >
    <v-row>
      <v-col>
        <div class="fw-600 fs-24">
          {{ $t('Grant programs') }}
        </div>
      </v-col>
      <v-col
        v-if="collection.length > 0"
        class="ta-right"
      >
        <v-btn
          @click="$refs.newDialog.open()"
          color="primary"
          prepend-icon="add"
        >
          {{ $t('New grant program') }}
        </v-btn>
      </v-col>
    </v-row>

    <NullState
      v-if="collection.length == 0"
      @new="$refs.newDialog.open()"
      new-button-title="Create your first grant program"
    />

    <v-row>
      <v-col
        v-for="grantProgram in collection"
        :key="grantProgram.id"
        cols="12"
      >
        <v-card
          @click="
            $router.push({ name: 'GrantProgramShow', params: { grantProgramId: grantProgram.id } })
          "
          :ripple="false"
          border
          flat
          tile
        >
          <v-card-title class="fs-18 fw-600 constrained">
            {{ $t(grantProgram.name) }}
          </v-card-title>
          <v-card-text>
            <div
              v-text="grantProgram.description || '...'"
              class="c-black fs-16 fw-500 constrained"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ResourceDialog
      @save="create"
      ref="newDialog"
      :fields="[{ text: 'Name', value: 'name', required: true }]"
      :processing="processing"
      title="New grant program"
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const collection = ref(null);
const processing = ref(false);

onMounted(() => void loadGrantPrograms());

async function create(newGrantProgram) {
  processing.value = true;
  const resp = await Api.organization.grant_program.create(newGrantProgram);

  processing.value = false;
  if (resp?.data?.id) {
    router.push({ name: 'GrantProgramShow', params: { grantProgramId: resp.data.id } });
  }
}

async function loadGrantPrograms() {
  const { data } = await Api.organization.grant_program.index();
  collection.value = data;
}
</script>
