export default [
  {
    icon: 'person',
    title: 'Children',
    route: 'SubsidyIndex',
  },
  {
    icon: 'supervisor_account',
    title: 'Families',
    route: 'FamilySubsidyIndex',
  },
];
