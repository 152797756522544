<template>
  <v-row class="dense d-flex align-center mb-6">
    <v-col cols="10">
      <h1 class="d-flex align-center fw-600 fs-22">
        <template v-if="backBackRoute">
          <a
            @click="$router.push(backBackRoute)"
            v-text="backBackName"
            class="me-2 c-black bc-link-hover"
          />
          <span class="me-2">/</span>
        </template>

        <template v-else-if="backBackName">
          <span
            v-text="backBackName"
            class="me-2"
          />
          <span class="me-2">/</span>
        </template>

        <template v-if="backRoute">
          <a
            @click="$router.push(backRoute)"
            v-text="backName"
            class="me-2 c-black bc-link-hover"
          />
          <span class="me-2">/</span>
        </template>

        <template v-else-if="backName">
          <span
            v-text="backName"
            class="me-2"
          />
          <span class="me-2">/</span>
        </template>

        <span v-t="name" />
      </h1>
    </v-col>
    <v-col
      class="d-flex justify-end"
      cols="2"
    >
      <slot name="actions" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    backBackName: {
      type: String,
      default: null,
    },
    backBackRoute: {
      type: Object,
      default: null,
    },
    backName: {
      type: String,
      default: null,
    },
    backRoute: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
};
</script>
