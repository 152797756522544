export default [
  {
    title: 'Settings',
    route: 'FamilySubsidyProgramShow',
  },
  {
    title: 'Application',
    route: 'FamilySubsidyProgramQuestions',
  },
  {
    title: 'Eligibility',
    route: 'FamilySubsidyProgramFunding',
  },
  {
    title: 'Stages',
    route: 'FamilySubsidyProgramStages',
  },
  {
    title: 'Assignments',
    route: 'FamilySubsidyProgramAssignments',
  },
  {
    title: 'Messaging',
    route: 'FamilySubsidyProgramMessaging',
  },
];
