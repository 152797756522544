<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-card
    v-if="subsidies"
    id="family-applications"
    border
    flat
    tile
  >
    <v-card-title>
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col>
          {{ $t('Applications') }}
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            @click="updateQuery({ action: 'create-application' })"
            color="primary"
            prepend-icon="add"
            variant="flat"
          >
            {{ $t('New application') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />

    <v-card-text>
      <v-data-table
        @click:row="goToApplication"
        :headers="tableHeaders"
        :hide-default-footer="subsidies.length == 0"
        :hide-default-header="subsidies.length == 0"
        :items="subsidies"
        item-key="id"
        disable-sort
        fixed-header
      >
        <template #item.created_at="{ item }">
          <LongDate :date="item.created_at" />
        </template>

        <template #item.submitted_at="{ item }">
          <LongDate :date="item.submitted_at" />
        </template>

        <template #no-data>
          <NullState
            class="my-4"
            title="Family has no applications."
          />
        </template>
      </v-data-table>
    </v-card-text>

    <ResourceDialog
      @save="create"
      :fields="[
        {
          text: 'Application type',
          value: 'family_subsidy_program_id',
          items: familySubsidyPrograms,
          itemText: 'name',
          itemValue: 'id',
        },
      ]"
      action="create-application"
      save-button-text="Start"
      title="New"
    />
  </v-card>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import LongDate from '@/shared/components/LongDate.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import useRouterHelper from '@/shared/composables/useRouterHelper';

const eventBus = useEventBus();
const router = useRouter();
const { t } = useI18n();
const { updateQuery } = useRouterHelper();

const props = defineProps({
  groupId: {
    type: String,
    default: null,
  },
});

const familySubsidyPrograms = ref([]);
const subsidies = ref([]);

const tableHeaders = computed(() => {
  return [
    { title: t('Type'), value: 'family_subsidy_program_name' },
    { title: t('Created'), value: 'created_at' },
    { title: t('Submitted'), value: 'submitted_at' },
    { title: t('Status'), value: 'status' },
  ];
});

watch(
  () => props.groupId,
  async (newVal) => {
    if (!newVal) return;

    await loadFamilySubsidyPrograms();
    await loadFamilySubsidies();
  },
  { immediate: true },
);

async function create(newVal) {
  const resp = await api.organization.family_subsidy
    .create({ ...newVal, group_id: props.groupId })
    .catch((error) => eventBus.error(error));
  if (!resp?.data) return;

  await updateQuery({ action: null });
  router.push({ name: 'FamilySubsidyShow', params: { id: resp.data.id } });
}

function goToApplication(event, { item: subsidy }) {
  router.push({ name: 'FamilySubsidyShow', params: { id: subsidy.id } });
}

async function loadFamilySubsidyPrograms() {
  const resp = await api.organization.family_subsidy_program.index();
  familySubsidyPrograms.value = resp.data;
}

async function loadFamilySubsidies() {
  const resp = await api.organization.family_subsidy.index({ group_id: props.groupId });
  subsidies.value = resp.data;
}
</script>

<style>
#family-applications {
  .v-data-table-header__content {
    font-weight: 800;
  }
}
</style>
