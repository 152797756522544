<template>
  <v-card
    border
    tile
  >
    <v-card-title tag="h3">
      {{ $t('Domains') }}
    </v-card-title>

    <v-divider />

    <v-card-text>
      <v-row
        class="mb-4"
        dense
      >
        <v-col cols="12">
          <div class="fs-16 fw-500 c-black">
            {{ $t('What domains will the site use?') }}
          </div>
        </v-col>

        <v-col>
          <DataItem
            v-for="(domain, index) in localValue.domains"
            @remove="removeDomain(domain)"
            :key="domain + index"
            :name="domain"
          />

          <template v-if="localValue.domains.length == 0">
            <p class="fs-14">
              {{ $t('No domains have been set') }}
            </p>
          </template>
        </v-col>

        <v-col cols="12">
          <v-btn
            @click="domainDialog.open()"
            color="primary"
          >
            {{ $t('Add a domain') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <div class="fs-16 fw-500 c-black">
            {{ $t('What API URL will the site use?') }}
          </div>
        </v-col>

        <v-col
          class="mb-4"
          cols="12"
        >
          <v-text-field
            v-model="localValue.api_base_url"
            @update:model-value="changed = true"
            placeholder="https://api.getbridgecare.com/api"
            variant="filled"
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-text>

    <ResourceDialog
      @save="addNewDomain"
      ref="domainDialog"
      :fields="[{ text: 'Domain', value: 'newDomain' }]"
      title="Add a domain"
    />

    <SaveBar
      @cancel="reset"
      @save="save"
      :model-value="changed"
      :processing="processing"
      flat
    />
  </v-card>
</template>

<script setup>
import organizationApi from '@/specialist/services/bright_finder';
import DataItem from '@/shared/components/form/DataItem.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const props = defineProps({
  site: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['refresh']);

const changed = ref(false);
const domainDialog = ref(null);
const localValue = ref(JSON.parse(JSON.stringify(props.site)));
const processing = ref(false);

function addNewDomain(newValue) {
  if (!localValue.value.domains.includes(newValue.newDomain)) {
    localValue.value.domains.push(newValue.newDomain);
    changed.value = true;
    domainDialog.value.close();
  }
}

function save() {
  processing.value = true;
  organizationApi.organization.site.update(
    localValue.value.id,
    {
      api_base_url: localValue.value.api_base_url,
      domains: localValue.value.domains,
    },
    () => {
      eventBus.chime('Saved');
      emit('refresh');
      processing.value = false;
      changed.value = false;
    },
    (error) => {
      eventBus.chime(error.response.data.errors[0]);
      processing.value = false;
    },
  );
}

function removeDomain(domain) {
  const index = localValue.value.domains.indexOf(domain);
  // if index is found, return correct domain
  if (index !== -1) {
    localValue.value.domains.splice(index, 1);
    changed.value = true;
  }
}

function reset() {
  localValue.value = JSON.parse(JSON.stringify(props.site));
  changed.value = false;
}
</script>
