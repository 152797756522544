export default [
  {
    title: 'Settings',
    route: 'EligibilityProgramShow',
  },
  {
    title: 'Eligibility',
    route: 'EligibilityProgramFunding',
  },
];
