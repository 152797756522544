<template>
  <v-card
    :border="outlined"
    :elevation="elevation"
    class="mb-4"
    break-after
    tile
  >
    <v-card-title
      :class="{ 'bb-1 bc-very-light-grey': !outlined }"
      class="d-flex align-center"
    >
      <h2
        :aria-label="[name, $t(overallText)].join(', ')"
        class="focus-very-visible"
        tabindex="0"
      >
        <span
          v-text="name"
          class="me-3 fs-20 fw-500"
        />

        <v-chip :color="overallColor">
          <v-icon
            color="white"
            role="presentation"
            aria-hidden
            start
          >
            {{ overallIcon }}
          </v-icon>
          <span>{{ overallText }}</span>
        </v-chip>

        <v-chip
          v-if="$role == 'specialist' && grantProgram && grantProgram.enable_scoring"
          @click="$emit('editScore')"
          class="ms-3"
        >
          <v-icon
            role="presentation"
            aria-hidden
            start
          >
            assessment
          </v-icon>
          <span>Score: {{ grant.score }}</span>
        </v-chip>
      </h2>

      <v-spacer />

      <v-btn
        @click="criteriaIsVisible = !criteriaIsVisible"
        :aria-label="$t('See why, expand to learn more')"
        class="focus-very-visible"
        variant="text"
      >
        <span class="fs-16 c-light-black">
          {{ $t('See why') }}
        </span>
        <v-icon
          class="c-light-black"
          end
        >
          expand_more
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text
      v-show="criteriaIsVisible"
      class="pb-4"
    >
      <div
        v-if="Object.keys(overallResults).length > 0"
        class="mb-4"
      >
        <div
          v-for="result in overallResults"
          :key="'overall-' + result[0]"
          :aria-label="
            [$t('Criteria'), $t(result[1] ? 'passing' : 'failing'), result[0]].join(', ')
          "
          class="d-flex align-center mb-1"
          tabindex="0"
        >
          <v-icon
            :color="result[1] == true ? 'green' : result[1] == false ? 'red' : 'grey'"
            size="20"
            start
          >
            {{
              result[1] == true ? 'check_circle' : result[1] == false ? 'cancel' : 'check_circle'
            }}
          </v-icon>
          <span class="fs-16">
            {{ $t(result[0]) }}
          </span>
        </div>
      </div>

      <div
        v-for="(source, index) in grant.funding_sources"
        v-show="
          source.criteria_results.filter((result) => !overallResults[result.message]).length > 0
        "
        :key="index"
        class="mt-3"
      >
        <div class="d-flex align-start mb-0">
          <div>
            <div
              :aria-label="[$t('Funding source'), source.name].join(': ')"
              class="fs-18 fw-600 mb-0"
              tabindex="0"
            >
              {{ $t(source.name) }}
            </div>
            <div
              class="fs-16 c-light-black mb-2"
              tabindex="0"
            >
              {{ $t(source.description) }}
            </div>
          </div>
        </div>

        <div
          v-for="result in source.criteria_results.filter(
            (criteriaResult) => !overallResults[criteriaResult.message],
          )"
          :key="result.message"
          :aria-label="
            [$t('Criteria'), $t(result.passes ? 'passing' : 'failing'), result.message].join(', ')
          "
          class="d-flex align-center mb-1"
          tabindex="0"
        >
          <v-icon
            :color="result.passes == true ? 'green' : result.passes == false ? 'red' : 'grey'"
            size="20"
            start
          >
            {{
              result.passes == true
                ? 'check_circle'
                : result.passes == false
                  ? 'cancel'
                  : 'check_circle'
            }}
          </v-icon>
          <span class="fs-16">
            {{ $t(result.message) }}
          </span>
        </div>

        <div
          v-if="index + 1 < grant.funding_sources.length"
          class="px-2"
        >
          <v-divider class="mt-4" />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  compatConfig: { MODE: 3 },

  props: {
    elevation: {
      default: 2,
      type: Number,
    },
    expanded: {
      default: false,
      type: Boolean,
    },
    explanation: {
      default: false,
      type: Boolean,
    },
    name: {
      type: String,
      default: null,
    },
    grant: {
      type: Object,
      default: null,
    },
    grantProgram: {
      type: Object,
      default: null,
    },
    outlined: {
      default: false,
      type: Boolean,
    },
  },

  emits: ['editScore'],

  data() {
    return {
      criteriaIsVisible: this.expanded,
      overallResults: {},
    };
  },
  computed: {
    overallColor() {
      return this.grant.projected_eligibility ? 'green' : 'red';
    },

    overallIcon() {
      return this.grant.projected_eligibility ? 'check_circle' : 'cancel';
    },

    overallText() {
      return this.grant.projected_eligibility ? this.$t('Eligible') : this.$t('Not eligible');
    },
  },

  created() {
    const pastResults = {};
    this.overallResults = {};
    if (this.grant.funding_sources.length > 1) {
      this.grant.funding_sources.forEach((source) => {
        source.criteria_results.forEach((result) => {
          if (pastResults[result.message]) {
            pastResults[result.message] = [
              [result.message, result.passes],
              pastResults[result.message][1] + 1,
            ];
          } else {
            pastResults[result.message] = [[result.message, result.passes], 1];
          }
        });
      });
      Object.entries(pastResults).forEach((entry) => {
        if (entry[1][1] === this.grant.funding_sources.length) {
          // eslint-disable-next-line prefer-destructuring
          this.overallResults[entry[0]] = entry[1][0];
        }
      });
    }
  },
};
</script>
