<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle :title="terms.groups" />

    <v-toolbar
      class="mb-3 bb-1"
      flat
    >
      <v-row class="d-flex align-center px-4">
        <v-col class="d-flex align-center justify-start">
          <v-text-field
            v-model="query"
            @keyup.enter="load"
            id="search_link"
            :aria-label="$t('Filter results by name')"
            :placeholder="$t('Filter results by name')"
            class="mxw-340"
            color="primary"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
            tracked
          />
          <v-btn
            @click="load"
            :loading="processing"
            color="primary"
            prepend-icon="search"
            variant="flat"
            tile
          >
            <span v-t="'Search'" />
          </v-btn>
          <template v-if="query">
            <v-btn
              @click="resetQuery"
              color="primary"
              variant="text"
            >
              <span v-t="'Clear'" />
            </v-btn>
          </template>
        </v-col>
        <v-col class="d-flex align-center justify-end">
          <v-btn
            v-if="$store.state.profile.org_groups_create"
            @click="$refs.newGroupDialog.open()"
            color="primary"
            data-cy="new-family"
            elevation="0"
            prepend-icon="add"
            variant="flat"
          >
            <span v-t="`New ${terms.group.toLowerCase()}`" />
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-container
      class="px-3 py-0"
      fluid
    >
      <v-card
        class="bb-1 pa-0"
        border
        flat
        tile
      >
        <v-card-text>
          <v-row>
            <TableHeader
              cols="3"
              title="Name"
            />
            <TableHeader
              cols="3"
              title="Email"
            />
            <TableHeader
              cols="2"
              title="Phone"
            />
            <TableHeader
              cols="3"
              title="Location"
            />
            <TableHeader
              cols="1"
              title="Created"
            />
          </v-row>
        </v-card-text>
      </v-card>

      <GroupRow
        v-for="group in groups"
        :key="group.id"
        :group="group"
      />

      <template v-if="groups.length == 0">
        <NullState hide-new-button />
      </template>

      <v-pagination
        v-show="pages > 1"
        v-model="page"
        :length="pages"
        :total-visible="8"
        class="mt-4"
      />

      <VerticalSpacer />
    </v-container>

    <ResourceDialog
      @save="create"
      ref="newGroupDialog"
      :fields="newGroupFields"
      :processing="processing"
      title="New family"
      close-on-save
    />
  </v-container>
</template>
<script>
import API from '@/shared/mixins/api';
import GroupRow from '@/specialist/components/group/GroupRow.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import RouterHelper from '@/shared/mixins/RouterHelper';
import Terms from '@/shared/mixins/terms';

export default {
  compatConfig: { MODE: 2 },

  components: {
    GroupRow,
    ResourceDialog,
  },

  mixins: [API, RouterHelper, Terms],

  data() {
    return {
      newGroupFields: [
        { text: 'Parent/guardian first name', value: 'primary_first_name' },
        { text: 'Parent/guardian last name', value: 'primary_last_name' },
      ],
      schemas: Object.values(this.$store.state.schemas).filter(
        (schema) => schema.data_type === 'Group',
      ),
      groups: [],
      page: 1,
      pages: 1,
      processing: false,
      query: this.$route.query.query,
    };
  },

  watch: {
    page() {
      this.load();
    },

    selectedGroup(val) {
      if (val) {
        this.$router.push({ name: 'GroupShow', params: { groupId: val.id } });
        this.query = null;
        this.queriedGroups = [];
        this.selectedGroup = null;
      }
    },
  },

  created() {
    this.load();
  },

  methods: {
    create(newVal) {
      this.processing = true;
      const name = [newVal.primary_first_name, newVal.primary_last_name].join(' ');
      this.api.organization.group.create(
        { ...newVal, name },
        (resp) => {
          this.$router.push({ name: 'GroupShow', params: { groupId: resp.data.id } });
          this.processing = false;
        },
        (err) => {
          this.$eventBus.$emit('error', err);
          this.processing = false;
        },
      );
    },

    async load() {
      await this.updateQuery({ query: this.query });
      this.processing = true;
      const resp = await this.api.organization.group.index({
        query: this.query,
        order: 'created',
        page: this.page,
      });
      this.pages = parseInt(resp.headers['x-page-count'] || 0, 10);
      this.groups = resp.data;
      this.processing = false;
    },

    resetQuery() {
      this.query = null;
      this.load();
    },
  },
};
</script>
