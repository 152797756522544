<template>
  <v-toolbar
    v-if="customView"
    class="mb-3 bb-1"
    extension-height="60"
    dense
    extended
  >
    <v-row class="d-flex align-center px-4">
      <v-col class="mxw-400">
        <v-select
          v-model="selectedSubsidyProgram"
          @update:model-value="handleSubsidyProgramChange"
          :aria-label="selectedSubsidyProgram?.name"
          :items="subsidyPrograms"
          density="compact"
          item-title="name"
          item-value="id"
          variant="filled"
          hide-details
          return-object
        />
      </v-col>
      <v-col class="mxw-400 d-flex align-center">
        <v-text-field
          v-model="search"
          @click:clear="clearSearch"
          @keyup.enter="setSearch"
          :aria-label="$t('Filter results by name')"
          :placeholder="$t('Filter results by name')"
          color="primary"
          density="compact"
          prepend-inner-icon="search"
          variant="filled"
          clearable
          hide-details
        />
        <v-btn
          @click="setSearch"
          color="primary"
          variant="flat"
        >
          <span v-t="'Search'" />
        </v-btn>
      </v-col>
    </v-row>

    <template
      v-if="selectedSubsidyProgram"
      #extension
    >
      <div class="d-flex flex-grow-1 px-4">
        <FilterMenu
          :active="selectedStatuses.length > 0"
          classes="d-md-inline-flex"
          test-id="status-filter-button"
          title="Status"
          paddingless
        >
          <template #card>
            <v-autocomplete
              v-model="selectedStatuses"
              @update:model-value="handleFilterChange"
              :aria-label="$t('Filter by status')"
              :disabled="processing"
              :item-title="(item) => $t(item)"
              :items="statuses"
              :menu="true"
              :placeholder="$t('Filter by status')"
              density="compact"
              prepend-inner-icon="search"
              variant="filled"
              autofocus
              chips
              clearable
              multiple
            />
          </template>
        </FilterMenu>

        <FilterMenu
          v-if="selectedSubsidyProgram.enable_team_review && teams.length > 0"
          :active="selectedTeams.length > 0"
          classes="d-md-inline-flex"
          test-id="team-filter-button"
          title="Team"
          paddingless
        >
          <template #card>
            <v-autocomplete
              v-model="selectedTeams"
              @update:model-value="handleFilterChange"
              :aria-label="$t('Filter by team name')"
              :disabled="processing"
              :item-title="(item) => $t(item.name)"
              :items="teams"
              :menu="true"
              :placeholder="$t('Filter by team name')"
              density="compact"
              item-value="id"
              prepend-inner-icon="search"
              variant="filled"
              autofocus
              chips
              clearable
              multiple
            />
          </template>
        </FilterMenu>

        <FilterMenu
          v-if="selectedSubsidyProgram.enable_individual_review && reviewers.length > 0"
          :active="selectedReviewers.length > 0"
          classes="d-md-inline-flex"
          title="Assignment"
          paddingless
        >
          <template #card>
            <v-autocomplete
              v-model="selectedReviewers"
              @update:model-value="handleFilterChange"
              :aria-label="$t('Filter by reviewer name')"
              :disabled="processing"
              :items="reviewers"
              :menu="true"
              :placeholder="$t('Filter by reviewer name')"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="search"
              variant="filled"
              autofocus
              chips
              clearable
              multiple
            />
          </template>
        </FilterMenu>

        <FilterMenu
          v-if="displayStages"
          :active="selectedStages.length > 0"
          classes="d-md-inline-flex"
          title="Stage"
          paddingless
        >
          <template #card>
            <v-autocomplete
              v-model="selectedStages"
              @update:model-value="handleFilterChange"
              :aria-label="$t('Filter by stage title')"
              :item-title="(item) => $t(item.title)"
              :items="stages"
              :menu="true"
              :placeholder="$t('Filter by stage title')"
              density="compact"
              item-value="id"
              prepend-inner-icon="search"
              variant="filled"
              autofocus
              chips
              clearable
              multiple
            />
          </template>
        </FilterMenu>

        <FilterMenu
          v-if="displayLabels"
          :active="selectedLabels.length > 0"
          classes="d-md-inline-flex"
          title="Label"
          paddingless
        >
          <template #card>
            <v-autocomplete
              v-model="selectedLabels"
              @update:model-value="handleFilterChange"
              :aria-label="$t('Filter by label name')"
              :disabled="processing"
              :items="labels"
              :menu="true"
              :placeholder="$t('Filter by label name')"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="search"
              variant="filled"
              autofocus
              chips
              clearable
              multiple
            >
              <template #chip="{ item, props }">
                <v-chip v-bind="props">
                  <template #prepend>
                    <v-avatar
                      :color="item.raw.color"
                      start
                    />
                  </template>
                  {{ item.raw.name }}
                </v-chip>
              </template>
              <template #item="{ item, props }">
                <v-list-item
                  v-bind="props"
                  :title="$t(item.raw.name)"
                >
                  <template #prepend="{ isSelected }">
                    <v-checkbox-btn
                      :key="item.value"
                      :model-value="isSelected"
                      :ripple="false"
                      tabindex="-1"
                    />
                  </template>
                  <template #append>
                    <v-avatar
                      :color="item.raw.color"
                      size="20"
                    />
                  </template>
                </v-list-item>
              </template>
            </v-autocomplete>
          </template>
        </FilterMenu>

        <template
          v-if="
            customView.attributes.columns.length > 0 || customView.attributes.filters.length > 0
          "
        >
          <v-btn
            @click="$emit('edit:custom-view:filters')"
            :class="filtersButtonClass"
            variant="outlined"
            rounded
          >
            <span v-t="'More filters'" />
            <v-icon class="ms-2"> filter_list </v-icon>
          </v-btn>
        </template>

        <v-spacer />

        <v-btn
          v-show="itemsSelected"
          @click="$emit('edit:team')"
          class="me-3"
          color="primary"
        >
          <span v-t="'Assign team'" />
        </v-btn>

        <v-btn
          v-show="itemsSelected"
          @click="$emit('edit:reviewer')"
          class="me-3"
          color="primary"
          variant="text"
          icon
        >
          <span v-t="'Assign member'" />
        </v-btn>

        <VerticalDivider />

        <v-btn
          @click="$emit('edit:custom-view:columns')"
          :class="columnsButtonClass"
          class="ms-2"
          variant="outlined"
        >
          <v-icon class="me-2"> tune </v-icon>
          <span v-t="'Manage columns'" />
          <v-avatar
            v-if="customView.attributes.columns.length > 0"
            class="ms-2"
            color="primary"
            size="24"
            rounded
          >
            <span
              v-text="customView.attributes.columns.length"
              class="c-white"
            />
          </v-avatar>
        </v-btn>

        <template v-if="itemsSelected">
          <ActionMenu
            @click:action:download="$emit('download')"
            @click:action:update:stage="$emit('edit:stage')"
            :items="[
              { title: 'Download', event: 'download' },
              { title: 'Update stage', event: 'update:stage' },
            ]"
            button-class="ms-3"
            button-icon="more_vert"
          />
        </template>
      </div>
    </template>
  </v-toolbar>
</template>

<script>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import VerticalDivider from '@/shared/components/VerticalDivider.vue';

export default {
  components: {
    ActionMenu,
    FilterMenu,
    VerticalDivider,
  },

  compatConfig: { MODE: 3 },

  props: {
    customView: {
      type: Object,
      default: null,
    },
    customViews: {
      default: () => [],
      type: Array,
    },
    itemsSelected: Boolean,
    labels: {
      default: () => [],
      type: Array,
    },
    processing: Boolean,
    reviewers: {
      default: () => [],
      type: Array,
    },
    subsidyPrograms: {
      default: () => [],
      type: Array,
    },
    teams: {
      default: () => [],
      type: Array,
    },
  },

  emits: [
    'change:custom-view',
    'change:filters',
    'change:subsidy-program',
    'download',
    'edit:custom-view:columns',
    'edit:custom-view:filters',
    'edit:custom-view:sorts',
    'edit:reviewer',
    'edit:stage',
    'edit:team',
    'search',
    'update:page',
  ],

  data() {
    return {
      search: null,
      selectedCustomView: null,
      selectedLabels: [],
      selectedReviewers: [],
      selectedStages: [],
      selectedStatuses: [],
      selectedSubsidyProgram: null,
      selectedTeams: [],
    };
  },

  computed: {
    columnsButtonClass() {
      return this.getHighlightedClass(this.customView.attributes.columns.length);
    },

    displayLabels() {
      return this.$store.state.profile.org_subsidies_access && this.labels.length > 0;
    },

    displayStages() {
      return (
        this.$store.state.profile.org_subsidies_access &&
        this.selectedSubsidyProgram.enable_stages &&
        this.selectedSubsidyProgram.stages.length > 0
      );
    },

    filtersButtonClass() {
      return this.getHighlightedClass(this.customView.attributes.filters.length);
    },

    sortsButtonClass() {
      return this.getHighlightedClass(this.customView.attributes.sorts.length);
    },

    stages() {
      return this.selectedSubsidyProgram?.stages || [];
    },

    statuses() {
      return this.selectedSubsidyProgram?.statuses || [];
    },
  },

  watch: {
    customViews(newVal) {
      if (!newVal[0]) return;

      [this.selectedCustomView] = newVal;
      this.handleCustomViewChange(this.selectedCustomView);
    },

    subsidyPrograms(newVal) {
      if (!newVal[0]) return;
      const subsidyProgramQueryId = this.$route.query?.subsidyProgramId;

      if (this.$store.state.lastViewedSubsidyProgramId) {
        this.selectedSubsidyProgram = newVal.find(
          (sp) => sp.id === this.$store.state.lastViewedSubsidyProgramId,
        );
      }
      if (subsidyProgramQueryId) {
        this.selectedSubsidyProgram = newVal.find((sp) => sp.id === subsidyProgramQueryId);
      } else {
        [this.selectedSubsidyProgram] = newVal;
      }

      this.handleSubsidyProgramChange(this.selectedSubsidyProgram);
    },
  },

  mounted() {
    if (localStorage.getItem('custom_subsidy_search')) {
      try {
        this.search = JSON.parse(localStorage.custom_subsidy_search);
        this.$emit('search', this.search);
      } catch (_error) {
        localStorage.removeItem('custom_subsidy_search');
      }
    }
  },

  methods: {
    clearSearch() {
      this.search = null;
      localStorage.removeItem('custom_subsidy_search');
      this.$emit('search', null);
    },

    setSearch() {
      localStorage.setItem('custom_subsidy_search', JSON.stringify(this.search));
      this.$emit('update:page', 1);
      this.$emit('search', this.search);
    },

    getFiltersAsCustomViewParams() {
      return {
        label_ids: this.selectedLabels,
        reviewer_ids: this.selectedReviewers,
        stage_ids: this.selectedStages,
        statuses: this.selectedStatuses,
        team_ids: this.selectedTeams,
      };
    },

    getHighlightedClass(length) {
      return length > 0 ? 'bc-primary-light bg-primary-extra-light' : 'bg-white bc-very-light-gray';
    },

    handleCustomViewChange(newVal) {
      this.$emit('change:custom-view', newVal);
      this.selectedLabels = newVal.attributes.label_ids;
      this.selectedReviewers = newVal.attributes.reviewer_ids;
      this.selectedStages = newVal.attributes.stage_ids;
      this.selectedStatuses = newVal.attributes.statuses;
      this.selectedTeams = newVal.attributes.team_ids;
    },

    handleFilterChange() {
      this.$emit('update:page', 1);
      this.$emit('change:filters', this.getFiltersAsCustomViewParams());
    },

    handleSubsidyProgramChange(newVal) {
      this.selectedCustomView = null;
      this.$store.commit('setViewedSubsidyProgram', newVal.id);
      this.$emit('change:subsidy-program', newVal);
    },
  },
};
</script>
