<template>
  <v-container
    v-if="orgSettingsAccess"
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      @create="$refs.newSpecialistDialog.open({})"
      create-text="New member"
      title="Members"
      createable
    />

    <v-toolbar
      class="mb-3 px-4 bb-1"
      extension-height="60"
    >
      <v-row>
        <v-col class="mxw-340">
          <v-text-field
            v-model="filters.query"
            @update:model-value="load()"
            :aria-label="$t('Search members')"
            :placeholder="$t('Search members')"
            color="secondary"
            data-testid="member.search"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
            tracked
          />
        </v-col>
      </v-row>

      <template #extension>
        <v-row
          class="d-flex align-center"
          dense
        >
          <v-col>
            <FilterMenu
              :active="!!filters.team_id"
              classes="d-none d-md-inline-flex"
              title="Team"
            >
              <template #card>
                <v-autocomplete
                  v-model="filters.team_id"
                  @update:model-value="load()"
                  :aria-label="$t('Filter by team')"
                  :disabled="processing"
                  :items="allTeams"
                  :menu="true"
                  :placeholder="$t('Filter by team')"
                  density="compact"
                  item-title="name"
                  item-value="id"
                  prepend-inner-icon="search"
                  variant="solo-filled"
                  autofocus
                  clearable
                  flat
                  hide-details
                />
              </template>
            </FilterMenu>
          </v-col>
        </v-row>
      </template>
    </v-toolbar>

    <v-data-table-server
      @click:row="handleRowClick"
      @update:page="handlePageChange"
      :headers="tableHeaders"
      :items="items"
      :items-length="totalItemCount"
      :items-per-page="pageSize"
      :loading="processing"
      class="mx-3 b-radius-0 b-1 bc-outlined-gray"
      no-data-text="No members found"
      fixed-header
      hover
    >
      <template #item.name="{ item }">
        <span>
          {{ item.name }}
        </span>

        <v-chip
          v-if="item.deactivated"
          class="ml-2"
          color="red"
          size="small"
        >
          {{ $t('Deactivated') }}
        </v-chip>
      </template>

      <template #item.role="{ item }">
        <v-icon
          :color="item.org_settings_access ? 'primary' : '#8B04CF'"
          :icon="item.org_settings_access ? 'admin_panel_settings' : 'person'"
          class="me-3"
        />

        <span>
          {{ $t(item.org_settings_access ? 'Admin' : 'Member') }}
        </span>
      </template>

      <template #item.joined="{ item }">
        <LongDateTime :date="item.created_at" />
      </template>
    </v-data-table-server>

    <VerticalSpacer :min-height="14" />

    <ResourceDialog
      @save="create"
      ref="newSpecialistDialog"
      :fields="[
        { text: 'First name', value: 'first_name' },
        { text: 'Last name', value: 'last_name' },
        { text: 'Email', value: 'email' },
      ]"
      :processing="processing"
      title="Create new member"
    />
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const { updateQuery } = useRouterHelper();

const eventBus = useEventBus();
const router = useRouter();
const store = useStore();

const allTeams = ref([]);
const filters = ref({
  is_specialist: true,
  page: 1,
  query: null,
  team_id: null,
});
const filterSummary = ref(null);
const pageSize = ref(500);
const processing = ref(false);
const searchCounter = ref(0);
const items = ref([]);
const tableHeaders = ref([
  {
    title: 'Name',
    value: 'name',
  },
  {
    title: 'Email',
    value: 'email',
  },
  {
    title: 'Role',
    value: 'role',
  },
  {
    title: 'Joined',
    value: 'joined',
    align: 'end',
  },
]);
const totalItemCount = ref(0);

const orgSettingsAccess = computed(() => {
  return store.state.profile.org_settings_access;
});

watch(
  orgSettingsAccess,
  (newValue) => {
    if (!newValue && router.currentRoute.path !== '/') router.push('/');
  },
  { immediate: true },
);

async function handlePageChange(newValue) {
  if (newValue) filters.value.page = newValue;
  await updateQuery({ ...filters.value });
  await load();
}

function handleRowClick(event, { item }) {
  router.push({
    name: 'SpecialistShow',
    params: { id: item.id },
  });
}

async function load() {
  processing.value = true;

  const promises = [loadTeams(), loadSpecialists()];
  await Promise.all(promises);

  processing.value = false;
}

async function loadSpecialists() {
  if (orgSettingsAccess.value) {
    filterSummary.value = getFilterSummary();
    searchCounter.value += 1;
    const currentSearchCounter = searchCounter.value;

    const response = await Api.organization.member.index(filters.value);
    if (searchCounter.value !== currentSearchCounter) return;

    items.value = response.data;
    totalItemCount.value = parseInt(response.headers['x-total-count']);
    pageSize.value = parseInt(response.headers['x-page-size']);
  }
}

async function loadTeams() {
  if (allTeams.value.length > 0) return;

  const response = await Api.organization.team.index();
  allTeams.value = response.data;
}

function create(specialist) {
  processing.value = true;
  Api.admin.member.create(
    { ...specialist, is_specialist: true },
    (response) => {
      router.push({
        name: 'SpecialistShow',
        params: { id: response.data.id },
      });
    },
    (error) => {
      processing.value = false;
      eventBus.chime(error.response.data.errors.join('. '));
    },
  );
}

function getFilterSummary() {
  const appliedFilters = [];

  if (filters.value.team_id) {
    appliedFilters.push(allTeams.value.find((team) => team.id === filters.value.team_id).name);
  }

  return appliedFilters.length > 0 ? appliedFilters.join(', ') : null;
}

onMounted(load);
</script>
