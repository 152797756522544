<template>
  <v-container v-if="collection">
    <TitleBar
      @create="$refs.newDialog.open({})"
      @query="load($event)"
      class="mb-4"
      name="Assets"
      createable
      searchable
    />

    <AssetRow
      v-for="asset in collection"
      :key="asset.id"
      :asset="asset"
      class="mb-2"
    />

    <NullState
      v-if="collection.length == 0"
      @new="$refs.newDialog.open({})"
      new-button-title="New asset"
    />

    <NewButton
      v-if="collection.length > 0"
      @click="$refs.newDialog.open({})"
      class="mt-4"
      text="New asset"
    />

    <ResourceDialog
      ref="newDialog"
      save-button-text="Done"
      title="New asset"
      close-on-save
    >
      <template #form>
        <div class="pb-4 text-body-1">
          <span id="asset-upload-extensions-supported">{{
            $t('The following File Extensions are supported:')
          }}</span>
          <ul
            aria-labelledby="asset-upload-extensions-supported"
            class="file-extensions-list pl-0 py-2"
          >
            <li
              v-for="fileType of supportedFileTypes"
              :key="fileType"
            >
              {{ fileType }}
            </li>
          </ul>
          <span>{{ $t('*Cannot be used as Learning content') }}</span>
        </div>
        <dashboard
          :props="uppyProps"
          :uppy="uppy"
        />
      </template>
    </ResourceDialog>

    <v-pagination
      v-show="pages > 1"
      v-model="page"
      :length="pages"
      :total-visible="8"
      class="mt-4"
    />
  </v-container>
</template>

<script>
import { scrollTo } from 'vuetify/lib/composables/goto';
import Api from '@/specialist/services/bright_finder';
import AssetRow from '@/specialist/components/AssetRow.vue';
import NewButton from '@/shared/components/NewButton.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import XHRUpload from '@uppy/xhr-upload';
import Uppy from '@uppy/core';
import UppyUtils from '@/shared/mixins/uppy_utils';
import { UNSUPPORTED_LMS_EXTENSIONS, ALL_SUPPORTED_EXTENSIONS } from '@/shared/assets/constants';
import { Dashboard } from '@uppy/vue';

/**
 * Derives a sortable char code from file extension strings, allowing some to be
 * always sorted to the bottom
 */
function getExtensionSortValue(extension) {
  const charValue = extension.charCodeAt(0);
  // keep unsupported LMS extensions at the bottom of the sorted list
  return UNSUPPORTED_LMS_EXTENSIONS.has(extension) ? -1 * charValue : charValue;
}

export default {
  compatConfig: { MODE: 2 },

  components: {
    AssetRow,
    Dashboard,
    NewButton,
    NullState,
    ResourceDialog,
    TitleBar,
  },
  mixins: [UppyUtils],

  data() {
    return {
      collection: null,
      page: 1,
      pages: null,
      queryCount: 0,
      uppy: null,
      uppyProps: {
        proudlyDisplayPoweredByUppy: false,
        inline: true,
        height: 200,
        width: '100%',
      },
      supportedFileTypes: [...ALL_SUPPORTED_EXTENSIONS]
        .sort((a, b) => {
          const charA = getExtensionSortValue(a);
          const charB = getExtensionSortValue(b);

          if (charA > 0 && charB > 0) {
            // both are supported, alpha sort by char code
            return charA - charB;
          }
          if (charA < 0 && charB < 0) {
            // neither are supported, alpha sort by char code in reverse due to
            // negative numbers
            return charB - charA;
          }
          if (charA > 0 && charB < 0) {
            // a is supported, b is not. move b down.
            return -1;
          }
          if (charA < 0 && charB > 0) {
            // b is supported, a is not. move a down.
            return 1;
          }
          // they are equal
          return 0;
        })
        .map((extension) =>
          UNSUPPORTED_LMS_EXTENSIONS.has(extension) ? `.${extension}*` : `.${extension}`,
        ),
    };
  },

  watch: {
    page() {
      this.load();
      scrollTo(0);
    },
  },

  beforeUnmount() {
    this.uppy.close();
  },

  created() {
    this.load();

    this.uppy = new Uppy({
      restrictions: {
        // see: https://uppy.io/docs/uppy/#restrictions
        allowedFileTypes: ALL_SUPPORTED_EXTENSIONS.map((extension) => `.${extension}`),
      },
    }).use(XHRUpload, {
      endpoint: this.getUploadAPIEndpoint(),
    });

    this.uppy.on('upload-success', (file, evt) => {
      const asset = {
        file: {
          id: evt.body.id, // remove the Shrine storage prefix
          storage: 'cache',
          metadata: {
            size: file.size,
            filename: file.name,
            mime_type: file.type,
          },
        },
      };

      Api.organization.asset.create(asset, () => {
        this.$refs.newDialog.close();
        this.load();
      });
    });
  },

  methods: {
    load(query = null) {
      this.queryCount += 1;
      const localQuery = this.queryCount;
      Api.organization.asset.index({ page: this.page, query }, (resp) => {
        if (this.queryCount === localQuery) {
          this.collection = resp.data;
          this.pages = parseInt(resp.headers['x-page-count'] || 0, 10);
        }
      });
    },
  },
};
</script>

<style scoped>
.file-extensions-list {
  list-style: none;
  column-count: 4;
}
</style>
