<template>
  <v-container
    v-if="program"
    class="mxw-1200"
  >
    <TitleBar
      :back-name="program.name"
      :back-route="backRoute"
      name="Messaging"
    />

    <v-tabs
      v-model="tab"
      bg-color="transparent"
      grow
    >
      <v-tab value="task-message-templates">
        {{ $t('Message templates') }}
      </v-tab>

      <v-tab value="task-event-alerts">
        {{ $t('Event alerts') }}
      </v-tab>
    </v-tabs>

    <v-window
      v-model="tab"
      class="mt-4"
    >
      <v-window-item value="task-message-templates">
        <MessageTemplates
          @change="$emit('change', $event)"
          @newMessageTemplate="$emit('newMessageTemplate', $event)"
          :message-templates="program.message_templates"
          :processing="processing"
        />
      </v-window-item>

      <v-window-item value="task-event-alerts">
        <EventAlertConfigurations
          v-if="!processing"
          :owner="program"
          :owner-type="ownerType"
          header-title=""
        />
      </v-window-item>
    </v-window>

    <SaveBar
      @cancel="$emit('load')"
      @save="$emit('save')"
      :model-value="changed"
      flat
    />
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import EventAlertConfigurations from '@/admin/components/event-alert-configurations/EventAlertConfigurations.vue';
import MessageTemplates from '@/admin/components/MessageTemplates.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import TitleBar from '@/shared/components/TitleBar.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    EventAlertConfigurations,
    MessageTemplates,
    SaveBar,
    TitleBar,
  },

  mixins: [API],

  props: {
    backRoute: {
      type: Object,
      default: null,
    },
    changed: Boolean,
    ownerType: {
      type: String,
      default: null,
    },
    processing: Boolean,
    program: {
      type: Object,
      default: null,
    },
  },

  emits: ['change', 'load', 'newMessageTemplate', 'save'],

  data() {
    return {
      tab: 'task-message-templates',
    };
  },
};
</script>
