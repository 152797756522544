export default [
  {
    title: 'Accreditations',
    route: 'SchemaIndex',
    query: {
      data_type: 'Accreditation',
    },
  },
  {
    title: 'Agreements',
    route: 'SchemaIndex',
    query: {
      data_type: 'Agreement',
    },
  },
  {
    title: 'Assessments',
    route: 'SchemaIndex',
    query: {
      data_type: 'Assessment',
    },
  },
  {
    title: 'Attendance logs',
    route: 'SchemaIndex',
    query: {
      data_type: 'AttendanceLog',
    },
  },
  {
    title: 'Background checks',
    route: 'SchemaIndex',
    query: {
      data_type: 'BackgroundCheck',
    },
  },
  {
    title: 'Business',
    route: 'SchemaIndex',
    query: {
      data_type: 'Business',
    },
  },
  {
    title: 'Children',
    route: 'SchemaIndex',
    query: {
      data_type: 'Child',
    },
  },
  {
    title: 'Claims',
    route: 'SchemaIndex',
    query: {
      data_type: 'Claim',
    },
  },
  {
    title: 'Complaints',
    route: 'SchemaIndex',
    query: {
      data_type: 'Complaint',
    },
  },
  {
    title: 'Eligibility screeners',
    route: 'SchemaIndex',
    query: {
      data_type: 'EligibilityScreener',
    },
  },
  {
    title: 'Enrollments',
    route: 'SchemaIndex',
    query: {
      data_type: 'Enrollment',
    },
  },
  {
    title: 'Extract',
    route: 'SchemaIndex',
    query: {
      data_type: 'Extract',
    },
  },
  {
    title: 'Family subsidies',
    route: 'SchemaIndex',
    query: {
      name: 'Family subsidies',
      data_type: 'FamilySubsidy',
    },
  },
  {
    title: 'Folders',
    route: 'SchemaIndex',
    query: {
      data_type: 'Folder',
    },
  },
  {
    title: 'Forms',
    route: 'SchemaIndex',
    query: {
      data_type: 'Form',
    },
  },
  {
    title: 'Funds transfers',
    route: 'SchemaIndex',
    query: {
      data_type: 'FundsTransfer',
    },
  },
  {
    title: 'Grants',
    route: 'SchemaIndex',
    query: {
      name: 'Grant',
      data_type: 'Grant',
    },
  },
  {
    title: 'Groups',
    route: 'SchemaIndex',
    query: {
      name: 'Group',
      data_type: 'Group',
    },
  },
  {
    title: 'Household members',
    route: 'SchemaIndex',
    query: {
      data_type: 'HouseholdMember',
    },
  },
  {
    title: 'Inspections',
    route: 'SchemaIndex',
    query: {
      data_type: 'Inspection',
    },
  },
  {
    title: 'Licenses',
    route: 'SchemaIndex',
    query: {
      data_type: 'License',
    },
  },
  {
    title: 'Operating logs',
    route: 'SchemaIndex',
    query: {
      data_type: 'OperatingLog',
    },
  },
  {
    title: 'Payments',
    route: 'SchemaIndex',
    query: {
      data_type: 'Payment',
    },
  },
  {
    title: 'Payment adjustments',
    route: 'SchemaIndex',
    query: {
      name: 'Payment adjustment',
      data_type: 'PaymentAdjustment',
    },
  },
  {
    title: 'Providers',
    route: 'SchemaIndex',
    query: {
      data_type: 'Provider',
    },
  },
  {
    title: 'Programs',
    route: 'SchemaIndex',
    query: {
      data_type: 'Program',
    },
  },
  {
    title: 'Quizzes',
    route: 'SchemaIndex',
    query: {
      data_type: 'Quiz',
    },
  },
  {
    title: 'Reviews',
    route: 'SchemaIndex',
    query: {
      data_type: 'Review',
    },
  },
  {
    title: 'Summaries',
    route: 'SchemaIndex',
    query: {
      data_type: 'Summary',
    },
  },
  {
    title: 'Surveys',
    route: 'SchemaIndex',
    query: {
      data_type: 'Survey',
    },
  },
  {
    title: 'Vaults',
    route: 'SchemaIndex',
    query: {
      data_type: 'Vault',
    },
  },
  {
    title: 'Violations',
    route: 'SchemaIndex',
    query: {
      data_type: 'Violation',
    },
  },
  {
    title: 'Worksheets',
    route: 'SchemaIndex',
    query: {
      data_type: 'Worksheet',
    },
  },
];
