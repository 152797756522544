<template>
  <div v-if="schema">
    <BreadcrumbTitle
      :back-name="schema.name"
      name="Stages"
    />

    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <LabeledSwitch
            v-model="schema.enable_stages"
            @input="changed = true"
            subtitle="You can customize your review process by creating stages for each step in your workflow."
            title="Enable stages"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <NullState
      v-if="schema.stages.length == 0"
      @new="$refs.newStageDialog.open({})"
      :new-button-disabled="!schema.enable_stages"
      new-button-title="New stage"
    />

    <div
      v-for="(stage, index) in schema.stages"
      :key="index"
      class="mb-4"
    >
      <StageCard
        @change="changed = true"
        @move="reorder(index, index + $event)"
        @remove="remove(index)"
        :features="[]"
        :index="index"
        :length="schema.stages.length"
        :owners="ownerItems"
        :stage="stage"
        :teams="teams"
      />
    </div>

    <v-btn
      v-if="schema.stages.length > 0"
      @click="$refs.newStageDialog.open({})"
      class="mt-4"
      color="primary"
    >
      New
    </v-btn>

    <ResourceDialog
      @save="addStage"
      ref="newStageDialog"
      :fields="stageValues"
      :processing="processing"
      title="New stage"
    />

    <VerticalSpacer :min-height="24" />

    <SaveBar
      @cancel="load"
      @save="save"
      :model-value="changed"
      :processing="processing"
      flat
    />
  </div>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import StageCard from '@/specialist/components/StageCard.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ResourceDialog,
    StageCard,
  },

  data() {
    return {
      changed: true,
      draftDialogIsVisible: false,
      newOwnerId: null,
      newTitle: null,
      ownerItems: [],
      processing: false,
      schema: null,
      teams: [],
    };
  },

  computed: {
    stageValues() {
      return [
        { text: 'Title', value: 'title' },
        {
          text: 'Stage owner',
          value: 'owner_id',
          items: this.ownerItems,
          itemText: 'name',
          itemValue: 'id',
        },
      ];
    },
  },

  created() {
    this.processing = true;
    this.load();
  },

  methods: {
    addStage(stage) {
      this.schema.stages.push({ ...stage, id: uuidv4() });
      this.$refs.newStageDialog.close();
      this.changed = true;
    },

    load() {
      this.changed = false;
      this.loadSchema();
      Api.organization.member.index({ is_specialist: true }, (resp) => {
        this.ownerItems = resp.data;
      });
      Api.organization.team.index({}, (resp) => {
        this.teams = resp.data;
      });
    },

    async loadSchema() {
      this.processing = true;
      const { data } = await Api.organization.schema.get(this.$route.params.schemaId);
      this.schema = data;
      this.processing = false;
      this.changed = false;
    },

    remove(index) {
      // eslint-disable-next-line no-alert
      if (confirm('Are you sure you want to remove this stage?')) {
        this.schema.stages.splice(index, 1);
        this.changed = true;
        this.save();
      }
    },

    reorder(fromIndex, toIndex) {
      const element = this.schema.stages[fromIndex];
      this.schema.stages.splice(fromIndex, 1);
      this.schema.stages.splice(toIndex, 0, element);
      this.changed = true;
    },

    save() {
      this.processing = true;
      Api.organization.schema.update(
        this.$route.params.schemaId,
        { enable_stages: this.schema.enable_stages, stages: this.schema.stages },
        (resp) => {
          this.processing = false;
          this.schema = resp.data;
          this.changed = false;
        },
        (err) => {
          this.load();
          this.processing = false;
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },
  },
};
</script>
