<template>
  <div>
    <v-row class="d-flex align-center mb-2">
      <v-col
        cols="12"
        sm="3"
      >
        <v-select
          v-model="language"
          @update:model-value="loadEventAlertConfigurations()"
          :items="languages"
          :loading="loadingLanguages"
          data-cy="event-alert_language"
          data-testid="event-alert_language"
          density="compact"
          variant="outlined"
          hide-details
        />
      </v-col>

      <v-spacer />

      <v-col
        :class="$vuetify.display.smAndUp ? 'ta-right' : ''"
        cols="12"
        sm="3"
      >
        <v-btn
          v-if="eventAlertConfigurations.length > 0"
          @click="$refs.newEventAlertConfigurationDialog.open({})"
          color="primary"
          prepend-icon="add"
        >
          {{ $t('New event alert') }}
        </v-btn>
      </v-col>
    </v-row>

    <template
      v-for="(item, index) in eventAlertConfigurations"
      :key="item.id"
    >
      <EventAlertConfiguration
        v-model="eventAlertConfigurations[index]"
        @updated="loadEventAlertConfigurations()"
        :owner="owner"
        :sites="sites"
      />
    </template>

    <NullState
      v-if="eventAlertConfigurations.length == 0"
      @new="$refs.newEventAlertConfigurationDialog.open({})"
      new-button-title="New event alert"
    />

    <ResourceDialog
      @save="createEventAlertConfiguration"
      ref="newEventAlertConfigurationDialog"
      :fields="getEventAlertConfigurationFields()"
      :processing="processing"
      title="Create new event alert"
    />
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import EventAlertConfiguration from '@/admin/components/event-alert-configurations/EventAlertConfiguration.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const props = defineProps({
  owner: {
    type: Object,
    default: () => {},
  },
  ownerType: {
    type: String,
    default: '',
  },
});

const eventAlertConfigurations = ref([]);
const language = ref('en');
const languages = ref([]);
const loadingLanguages = ref(false);
const newEventAlertConfigurationDialog = ref(null);
const processing = ref(true);
const sites = ref([]);

onMounted(async () => {
  const promises = [loadEventAlertConfigurations(), loadLanguages(), loadSites()];
  await Promise.all(promises);
  processing.value = false;
});

async function createEventAlertConfiguration(newVal) {
  processing.value = true;
  const response = await Api.organization.eventAlertConfiguration.create({
    ...newVal,
    owner_id: props.owner.id,
    owner_type: props.ownerType,
  });
  eventAlertConfigurations.value.push(response.data);
  newEventAlertConfigurationDialog.value?.close();
  eventBus.chime('Created');
  processing.value = false;
}

function getEventAlertConfigurationFields() {
  return [
    {
      text: 'Site',
      value: 'site_id',
      items: sites.value,
      itemText: 'name',
      itemValue: 'id',
      required: true,
    },
    {
      text: 'Event name',
      value: 'event_name',
      items: getEventNames(),
      required: true,
    },
    {
      text: 'Language',
      value: 'language',
      items: languages.value,
      required: true,
    },
  ];
}

function getEventNames() {
  return props.owner.event_names || [];
}

async function loadEventAlertConfigurations() {
  const response = await Api.organization.eventAlertConfiguration.index({
    owner_id: props.owner.id,
    language: language.value,
  });
  eventAlertConfigurations.value = response.data;
}

async function loadLanguages() {
  loadingLanguages.value = true;
  const response = await Api.organization.get();
  languages.value = response.data.languages;
  loadingLanguages.value = false;
}

async function loadSites() {
  const response = await Api.organization.site.index({});
  sites.value = response.data;
}
</script>
