<template>
  <v-container
    v-if="screenerProgram"
    class="mxw-1200"
  >
    <TitleBar
      :back-route="{ name: 'ScreenerProgramIndex' }"
      :name="screenerProgram.name"
      back-name="Screener programs"
    />
    <v-card
      class="mb-8"
      border
      flat
      tile
    >
    </v-card>

    <v-expansion-panels
      v-model="open"
      class="mb-12"
      variant="accordion"
      flat
      multiple
      tile
    >
      <QuestionList
        @cancel="load"
        @change="load"
        @edit="edit($event)"
        @new="$refs.newQuestionDialog.open('EligibilityScreener', $event, true)"
        @settings="settings($event)"
        :processing="processing"
        :questions="questions"
        :schemas="[eligibilityScreenerSchema]"
        :sections="false"
        :selected-question="selectedQuestion"
        title="Screener program questions"
      />
    </v-expansion-panels>

    <NewQuestionDialog
      @created="load"
      ref="newQuestionDialog"
      :owner-id="screenerProgram.id"
      :schemas="[eligibilityScreenerSchema]"
      owner-type="ScreenerProgram"
    />
  </v-container>
</template>

<script>
import Api from '@/shared/services/all_bright_finder';
import LinkedList from '@/shared/services/linked-list';
import NewQuestionDialog from '@/shared/components/form/NewQuestionDialog.vue';
import QuestionList from '@/admin/components/QuestionList.vue';
import TitleBar from '@/shared/components/TitleBar.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    NewQuestionDialog,
    QuestionList,
    TitleBar,
  },

  data() {
    return {
      changed: false,
      processing: false,
      eligibilityScreenerSchema: null,
      questions: [],
      selectedQuestion: null,
      screenerProgram: null,
      open: true,
    };
  },

  props: {
    api: {
      type: Object,
      default() {
        return Api;
      },
    },
  },

  created() {
    this.load();
  },

  methods: {
    async load() {
      const response = await Api.organization.screenerProgram.get(this.$route.params.id);
      this.screenerProgram = response?.data;
      this.loadQuestions();
      this.loadSchemas();
    },

    loadQuestions() {
      this.api.public_api.organization.question.index(
        { owner_id: this.screenerProgram.id, owner_type: 'ScreenerProgram' },
        (response) => {
          return (this.questions = LinkedList.sort(response.data));
        },
      );
    },

    loadSchemas() {
      this.api.public_api.organization.schema.index((resp) => {
        this.eligibilityScreenerSchema = resp.data.find(
          (schema) => schema.id == this.screenerProgram.eligibility_screener_schema_id,
        );
      });
    },

    edit(question) {
      this.selectedQuestion = question;
    },

    settings(question) {
      this.selectedQuestion = question;
      this.$refs.detailsDrawer.open();
    },
  },
};
</script>
