<template>
  <v-container
    v-if="eligibilityProgram"
    class="mxw-1200"
  >
    <TitleBar
      :back-route="{ name: 'EligibilityProgramIndex' }"
      :name="eligibilityProgram.name"
      back-name="Eligibility programs"
    />

    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-title>
        {{ $t('Overview') }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <LabeledTextfield
          v-model="draftEligibilityProgram.name"
          @input="changed = true"
          :message="$t('Name')"
        />
        <LabeledTextfield
          v-model="draftEligibilityProgram.description"
          @input="changed = true"
          :message="$t('Description')"
        />
        <LabeledTextfield
          v-model="draftEligibilityProgram.cta_button_text"
          @input="changed = true"
          :message="$t('Call-to-action button text')"
        />
        <LabeledTextfield
          v-model="draftEligibilityProgram.cta_button_link"
          @input="changed = true"
          :message="$t('Call-to-action link')"
        />
      </v-card-text>
    </v-card>

    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-title>
        {{ $t('Data mappings') }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <LabeledSimpleSelect
          v-model="draftEligibilityProgram.eligibilityScreenerSchema.id"
          @input="changed = true"
          :items="eligibilityScreenerSchemas"
          :multiple="false"
          item-title="name"
          item-value="id"
          message="Eligibility screener schema"
        />
      </v-card-text>
    </v-card>

    <DangerBar
      @destroy="destroy"
      :processing="processing"
    />

    <SaveBar
      @cancel="cancel"
      @save="save"
      :model-value="changed"
      :processing="processing"
      flat
    />
  </v-container>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';

import Api from '@/specialist/services/bright_finder';
import DangerBar from '@/shared/components/DangerBar.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import TitleBar from '@/shared/components/TitleBar.vue';

const changed = ref(false);
const emit = defineEmits(['chime']);
const eligibilityProgram = ref(null);
const draftEligibilityProgram = ref(null);
const eligibilityScreenerSchemas = ref([]);
const processing = ref(false);
const route = useRoute();
const router = useRouter();

function cancel() {
  setDraft();
  changed.value = false;
}

async function destroy() {
  if (!confirm('Are you sure you want to destroy this eligibility program?')) return;

  processing.value = true;

  const response = await Api.organization.eligibilityProgram.destroy(eligibilityProgram.value.id);

  processing.value = false;

  if (![200, 204].includes(response?.status)) return;

  emit('chime', 'Eligibility program deleted');
  await router.push({ name: 'EligibilityProgramIndex' });
}

async function load() {
  const { data: response } = await Api.organization.eligibilityProgram.get(route.params.id);
  eligibilityProgram.value = response;
  setDraft();

  await loadEligibilityScreenerSchemas();
}

async function loadEligibilityScreenerSchemas() {
  const response = await Api.organization.schema.index({ data_type: 'EligibilityScreener' });
  if (response?.data) eligibilityScreenerSchemas.value = response?.data;
}

async function save() {
  try {
    processing.value = true;

    await Api.organization.eligibilityProgram.update(draftEligibilityProgram.value);
    await load();
    emit('chime', 'Saved');
  } finally {
    changed.value = false;
    processing.value = false;
  }
}

function setDraft() {
  draftEligibilityProgram.value = { ...eligibilityProgram.value };
}

onMounted(load);
</script>
