<template>
  <v-container class="mxw-1200">
    <router-view />
  </v-container>
</template>

<script setup>
import links from '@/admin/services/eligibility-program-links';
import { onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const route = useRoute();
const store = useStore();

async function load() {
  resetNavigation();
  store.commit('setNavigation', [
    {
      icon: 'chevron_left',
      type: 'back',
      title: 'Back',
      route: 'EligibilityProgramIndex',
    },
    {
      type: 'section',
      items: links.map((link) => ({ ...link, params: { id: route.params.id } })),
    },
  ]);
}

function resetNavigation() {
  store.commit('setNavigation', null);
}

onMounted(load);
onUnmounted(resetNavigation);
</script>
