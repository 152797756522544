<template>
  <div>
    <span class="me-2 d-inline-block flex-none fs-18 fw-500">{{ property.alias || field }}</span>
    <span class="me-2 d-inline-block flex-none">:: {{ field }},</span>
    <i>
      <span v-text="property.type" />
      <span v-if="property.format">:{{ property.format }}</span>
      <span v-if="property.role">:{{ property.role }}</span>
      <span v-if="property.readOnly">, readonly</span>
    </i>
    <strong v-if="property.reportingAlias"> - ({{ property.reportingAlias }} in reports)</strong>
  </div>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    field: {
      type: String,
      default: null,
    },
    property: {
      type: Object,
      default: null,
    },
  },
};
</script>
