<template>
  <v-card
    border
    flat
    tile
  >
    <v-card-title tag="h3">
      {{ t('Data Schemas') }}
    </v-card-title>

    <v-divider />

    <v-card-text>
      <v-row>
        <v-col
          class="fs-18 fw-500 c-black"
          cols="12"
        >
          {{ t('Which data schemas do you want to include?') }}
        </v-col>

        <v-col
          v-if="site_schemas"
          class="mb-6"
          cols="12"
        >
          <DataItem
            v-for="(site_schema, index) in site_schemas"
            @click="
              router.push({ name: 'SchemaShow', params: { schemaId: site_schema.schema.id } })
            "
            @remove="removeSiteSchema(site_schema.id)"
            :key="index"
            :header="site_schema.schema.data_type"
            :name="site_schema.schema.name"
          />
        </v-col>

        <v-col cols="12">
          <v-btn
            @click="schemaDialog.open()"
            color="primary"
          >
            {{ t('Add Schema') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <ResourceDialog
      @save="addSiteSchema"
      ref="schemaDialog"
      save-button-text="Add"
      title="Add Schema"
      close-on-save
    >
      <template #form>
        <v-select
          v-model="selectedSchemaId"
          :items="unassignedSchemas"
          item-title="name"
          item-value="id"
          variant="filled"
          hide-details
        />
      </template>
    </ResourceDialog>
  </v-card>
</template>

<script setup>
import organizationApi from '@/specialist/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import DataItem from '@/shared/components/form/DataItem.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const emit = defineEmits(['error', 'refresh']);

const props = defineProps({
  site: {
    type: Object,
    required: true,
  },
});

const eventBus = useEventBus();
const { t } = useI18n();
const router = useRouter();

const schemas = ref(null);
const schemaDialog = ref(false);
const selectedSchemaId = ref(null);
const site_schemas = ref(null);

const unassignedSchemas = computed(() => {
  if (schemas.value && site_schemas.value) {
    return (schemas.value ?? []).filter((schema) => {
      return !(site_schemas.value ?? []).some((site_schema) => site_schema.schema.id === schema.id);
    });
  }
  return [];
});

function addSiteSchema() {
  schemaDialog.value.close();
  organizationApi.organization.site.site_schema.create(
    props.site.id,
    { schema_id: selectedSchemaId.value },
    () => {
      loadSiteSchemas();
      emit('refresh');
      selectedSchemaId.value = null;
    },
    (err) => {
      eventBus.error(err);
    },
  );
}

async function loadSchemas() {
  const { data } = await organizationApi.organization.schema.index({});
  schemas.value = data;
}

function loadSiteSchemas() {
  organizationApi.organization.site.site_schema.index(props.site.id, (resp) => {
    site_schemas.value = resp.data;
  });
}

function removeSiteSchema(siteSchemaId) {
  organizationApi.organization.site.site_schema.destroy(props.site.id, siteSchemaId, () =>
    loadSiteSchemas(),
  );
}

onMounted(() => {
  loadSiteSchemas();
  void loadSchemas();
});
</script>
