<template>
  <v-card
    class="mb-4"
    border
    tile
  >
    <v-card-title>
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col class="fs-22 fw-600"> Funding </v-col>
        <v-col class="ta-right">
          <v-btn
            @click="createSubsidyAward"
            :loading="processing"
            color="primary"
          >
            <v-icon start> add </v-icon>
            <span v-t="'Draft award'" />
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider class="mb-0" />

    <NullState
      v-if="subsidyAwards.length == 0"
      @new="createSubsidyAward"
      :processing="processing"
      class="mt-6"
      new-button-title="Draft award"
    />

    <v-card-text>
      <SubsidyAwardCard
        v-for="(subsidyAward, index) in subsidyAwards"
        @destroy="load()"
        :key="index"
        :subsidy-award="subsidyAward"
        :subsidy-program="subsidyProgram"
        class="mb-4"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import api from '@/specialist/services/bright_finder.js';
import NullState from '@/shared/components/NullState.vue';
import SubsidyAwardCard from '@/specialist/components/subsidy/SubsidyAwardCard.vue';

const props = defineProps({
  subsidy: {
    type: Object,
    default: null,
  },
  subsidyProgram: {
    type: Object,
    default: null,
  },
});

const processing = ref(false);
const subsidyAwards = ref([]);

onMounted(async () => {
  await load();
});

async function createSubsidyAward() {
  processing.value = true;
  const resp = await api.organization.subsidy_award.create({
    subsidy_id: props.subsidy.id,
    award_calculation_requested: true,
  });
  processing.value = false;
  if (!resp.status == 201) return;

  subsidyAwards.value.push(resp.data);
}

async function load() {
  const resp = await api.organization.subsidy_award.index({ subsidy_id: props.subsidy.id });
  subsidyAwards.value = resp.data;
}
</script>
