<template>
  <v-container
    v-if="familySubsidyProgram"
    class="mxw-1200"
  >
    <TitleBar
      :back-name="familySubsidyProgram.name"
      :back-route="{ name: 'FamilySubsidyProgramShow' }"
      name="Stages"
    >
      <template #actions>
        <template v-if="familySubsidyProgram.enable_stages">
          <v-btn
            @click="$refs.newStageDialog.open({})"
            color="primary"
          >
            <span v-t="'New'" />
          </v-btn>
        </template>
      </template>
    </TitleBar>

    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <LabeledSwitch
            v-model="familySubsidyProgram.enable_stages"
            @input="changed = true"
            subtitle="You can customize your application review process by creating stages for each step in your workflow."
            title="Enable stages"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <template v-if="familySubsidyProgram.stages.length == 0">
      <NullState
        @new="$refs.newStageDialog.open({})"
        :new-button-disabled="!familySubsidyProgram.enable_stages"
        new-button-title="New stage"
      />
    </template>

    <div
      v-for="(stage, index) in familySubsidyProgram.stages"
      :key="index"
      class="mb-4"
    >
      <StageCard
        @change="changed = true"
        @move="reorder(index, index + $event)"
        @remove="remove(index)"
        :features="features"
        :index="index"
        :length="familySubsidyProgram.stages.length"
        :owners="members"
        :stage="stage"
        :teams="teams"
      />
    </div>

    <ResourceDialog
      @save="addStage"
      ref="newStageDialog"
      :fields="stageValues"
      :processing="processing"
      title="New stage"
    />

    <VerticalSpacer :min-height="24" />

    <SaveBar
      @cancel="load"
      @save="save"
      :model-value="changed"
      :processing="processing"
      flat
    />
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import StageCard from '@/specialist/components/StageCard.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import NullState from '@/shared/components/NullState.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LabeledSwitch,
    NullState,
    ResourceDialog,
    SaveBar,
    StageCard,
    TitleBar,
    VerticalSpacer,
  },
  mixins: [API],

  data() {
    return {
      changed: false,
      draftDialogIsVisible: false,
      familySubsidyProgram: null,
      features: [],
      newOwnerId: null,
      newTitle: null,
      members: [],
      processing: false,
      stageValues: [{ text: 'Title', value: 'title' }],
      teams: [],
    };
  },

  created() {
    this.load();
  },

  methods: {
    addStage(stage) {
      this.familySubsidyProgram.stages.push({ ...stage, id: uuidv4() });
      this.$refs.newStageDialog.close();
      this.changed = true;
    },

    getEnabledFeatures() {
      const feats = [
        { title: 'Enable application', field: 'enable_application' },
        { title: 'Enable reviews', field: 'enable_reviews' },
      ];

      if (this.familySubsidyProgram.enable_claims)
        feats.push({ title: 'Enable claims', field: 'enable_claims' });

      return feats;
    },

    async load() {
      this.loadMembers();
      this.loadTeams();
      await this.loadFamilySubsidyProgram();
      this.features = this.getEnabledFeatures();
      this.changed = false;
    },

    async loadFamilySubsidyProgram() {
      const resp = await this.api.organization.family_subsidy_program.get(this.$route.params.id);
      this.familySubsidyProgram = resp.data;
    },

    async loadMembers() {
      const resp = await this.api.organization.member.index({ is_specialist: true });
      this.members = resp.data;
    },

    async loadTeams() {
      const resp = await this.api.organization.team.index();
      this.teams = resp.data;
    },

    async remove(index) {
      // eslint-disable-next-line no-alert
      if (!confirm('Are you sure you want to remove this stage?')) return;

      this.familySubsidyProgram.stages.splice(index, 1);
      this.changed = true;
      await this.save();
    },

    reorder(fromIndex, toIndex) {
      const element = this.familySubsidyProgram.stages[fromIndex];
      this.familySubsidyProgram.stages.splice(fromIndex, 1);
      this.familySubsidyProgram.stages.splice(toIndex, 0, element);
      this.changed = true;
    },

    async save() {
      this.processing = true;
      const params = {
        id: this.familySubsidyProgram.id,
        enable_stages: this.familySubsidyProgram.enable_stages,
        stages: this.familySubsidyProgram.stages,
      };
      const resp = await this.api.organization.family_subsidy_program
        .update(params)
        .catch((error) => this.$eventBus.$emit('error', error));
      this.processing = false;

      if (resp?.data) this.changed = false;
    },
  },
};
</script>
