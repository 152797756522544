import axios from 'axios';

import { handleError } from '@/shared/services/api';

export default {
  baseUrl() {
    return `${window.api_url}/organization/participants`;
  },

  create(participant) {
    return axios.post(this.baseUrl(), { participant }).catch(handleError);
  },

  destroy(id) {
    return axios.delete(`${this.baseUrl()}/${id}`).catch(handleError);
  },

  index(params) {
    return axios.get(this.baseUrl(), { params }).catch(handleError);
  },
};
