export default [
  {
    title: 'Settings',
    route: 'ChildSubsidyProgramShow',
  },
  {
    title: 'Application',
    route: 'ChildSubsidyProgramQuestions',
  },
  {
    title: 'Funding',
    route: 'ChildSubsidyProgramFunding',
  },
  {
    title: 'Stages',
    route: 'ChildSubsidyProgramStages',
  },
  {
    title: 'Seat types',
    route: 'ChildSubsidyProgramSeats',
  },
  {
    title: 'Assignments',
    route: 'ChildSubsidyProgramAssignments',
  },
  {
    title: 'Messaging',
    route: 'ChildSubsidyProgramMessaging',
  },
];
