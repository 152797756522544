<template>
  <div v-if="schema">
    <BreadcrumbTitle
      :back-name="schema.name"
      name="Event alerts"
    />

    <EventAlertConfigurations
      v-if="!processing"
      :owner="schema"
      owner-type="Schema"
    />
  </div>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import EventAlertConfigurations from '@/admin/components/event-alert-configurations/EventAlertConfigurations.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const processing = ref(true);
const schema = ref(null);

onMounted(async () => {
  await loadSchema();
});

async function loadSchema() {
  const resp = await api.organization.schema.get(route.params.schemaId);
  schema.value = resp.data;
  processing.value = false;
}
</script>
