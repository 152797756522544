export const DEFERRED_ACCEPTANCE_SUBSIDY_STATUSES = [
  'Submitted',
  'Approved',
  'Awaiting documents',
  'In review',
  'Reviewed',
  'Waitlist',
];
export const STATIC_STATUSES = ['In progress', 'Submitted'];
export const NEUTRAL_STATUSES = [
  'Reviewed',
  'Awaiting documents',
  'Denied',
  'Withdrawn',
  'Waitlist',
];
export const POSITIVE_STATUSES = ['Approved'];

export const FORM_REVIEW_STATUSES = [
  'Reviewed',
  'Awaiting documents',
  'Denied',
  'Withdrawn',
  'Approved',
];
