<template>
  <v-alert
    :icon="request.approved ? 'how_to_reg' : 'person_add'"
    border="start"
    border-color="primary"
    color="surface"
    type="info"
  >
    <div>
      <div
        v-if="request.member_name"
        class="fs-22 fw-500"
      >
        {{ request.member_name }}
      </div>
      <div class="fs-22 fw-500">
        {{ request.member_email }}
      </div>
      <div
        v-if="request.provider_name"
        class="fs-16 fw-500"
      >
        Access requested to: {{ request.provider_name }}
      </div>
      <div v-if="request.approver_name">
        <span class="me-1"> Approved by {{ request.approver_name }} on </span>
        <LongDateTime :date="request.approved_at" />
      </div>
      <v-alert
        v-if="request.request_reason"
        class="mt-2 py-1"
        color="surface-light"
        icon="info"
      >
        <div>
          <p>{{ request.request_reason }}</p>
        </div>
      </v-alert>
    </div>
    <template #append>
      <template v-if="!request.approved">
        <v-btn
          @click="approveRequest()"
          :loading="processing"
          color="green"
        >
          {{ $t('Approve') }}
        </v-btn>
        <v-btn
          @click="denyRequest()"
          :loading="processing"
          class="ms-3"
          color="red"
        >
          {{ $t('Deny') }}
        </v-btn>
      </template>
    </template>
  </v-alert>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const props = defineProps({
  request: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['approved', 'destroy']);

const processing = ref(false);

async function approveRequest() {
  // eslint-disable-next-line no-alert
  if (confirm('Are you sure you want to approve business access for this user?')) {
    processing.value = true;
    const resp = await api.organization.access_request.update(props.request.id, { approved: true });
    processing.value = false;
    if (resp?.status === 200) emit('approved');
  }
}

async function denyRequest() {
  // eslint-disable-next-line no-alert
  if (confirm('Are you sure you want to deny this business access for this user?')) {
    processing.value = true;
    const resp = await api.organization.access_request.destroy(props.request.id);
    processing.value = false;
    if (resp?.status === 200) {
      emit('destroy');
      eventBus.chime('Request denied');
    }
  }
}
</script>
