<template>
  <div v-if="draft && schemas">
    <div v-if="draft.id">
      <div v-if="draft.definition">
        <template v-if="draft.definition.properties.custom">
          <v-card
            class="mb-4"
            color="transparent"
            dense
            flat
          >
            <SchemaElement
              @change="updateElement('custom', $event)"
              :definition="draft.definition"
              :depth="0"
              :details="false"
              :index="0"
              :is-child="false"
              :schemas="schemas"
              field="custom"
              title="Custom fields"
              children-removable
              open-now
            />
          </v-card>
        </template>

        <v-divider class="my-6" />

        <v-card
          v-if="draft.definition"
          class="mb-4"
          color="transparent"
          dense
          flat
        >
          <v-card-title class="fs-20 fw-500 px-0"> Standard fields </v-card-title>
          <v-card-text class="px-0 bg-white py-0">
            <v-expansion-panels
              class="bc-outlined-grey b-1"
              flat
              tile
            >
              <SchemaElement
                v-for="(entry, index) in standardEntries"
                @change="updateElement(entry[0], $event)"
                :key="index"
                :definition="draft.definition"
                :depth="1"
                :field="entry[0]"
                :index="index"
                :schemas="schemas"
                children-removable
                lock-format
                lock-type
              />
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </div>

      <SaveBar
        @cancel="cancel"
        @save="save"
        :model-value="changed"
        :processing="processing"
      />
    </div>
  </div>
</template>

<script>
import baseApi from '@/shared/services/bright_finder';
import organizationApi from '@/specialist/services/bright_finder';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import SchemaElement from '@/admin/components/schemas/SchemaElement.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    SaveBar,
    SchemaElement,
  },

  props: {
    schema: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      changed: false,
      draft: null,
      standardEntries: null,
      processing: false,
      schemas: null,
    };
  },

  created() {
    this.load();
    this.setDraft(this.schema);
  },

  methods: {
    cancel() {
      window.location.reload();
    },

    load() {
      baseApi.public_api.organization.schema.index((resp) => {
        this.schemas = resp.data;
      });
    },

    save() {
      this.processing = true;
      organizationApi.organization.schema.update(
        this.draft.id,
        this.draft,
        () => {
          this.processing = false;
          this.changed = false;
        },
        (err) => {
          this.processing = false;
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },

    setDraft(schema) {
      this.draft = null;
      this.standardEnteries = null;
      this.processing = true;

      setTimeout(() => {
        this.draft = JSON.parse(JSON.stringify(schema));
        if (this.draft.definition) {
          this.standardEntries = Object.entries(this.draft.definition.properties).filter(
            (entry) => !['custom', 'sources'].includes(entry[0]),
          );
        }
        this.processing = false;
        this.changed = false;
      }, 250);
    },

    updateElement(element, newVal) {
      this.draft.definition.properties[element] = newVal;
      this.changed = true;
    },
  },
};
</script>
