<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <div class="fs-18 fw-600 c-black">
          {{ $t('Name') }}
        </div>
      </v-col>
      <v-col
        class="mb-3"
        cols="12"
      >
        <v-text-field
          v-model="draft.name"
          placeholder="Name"
          variant="filled"
          hide-details
        />
      </v-col>
      <v-col
        class="mb-3"
        cols="12"
        md="4"
      >
        <div class="fs-18 fw-600 c-black">
          {{ $t('Primary color') }}
        </div>
        <v-text-field
          v-model="draft.primary_color"
          :append-inner-icon="primaryColorPassing ? 'check' : ''"
          placeholder="Primary color"
          variant="filled"
          hide-details
        />
      </v-col>
      <v-col
        class="mb-3"
        cols="12"
        md="4"
      >
        <div class="fs-18 fw-600 c-black">
          {{ $t('Secondary color') }}
        </div>
        <v-text-field
          v-model="draft.secondary_color"
          placeholder="Secondary color"
          variant="filled"
          hide-details
        />
      </v-col>
      <v-col
        class="mb-3"
        cols="12"
        md="4"
      >
        <div class="fs-18 fw-600 c-black">
          {{ $t('Accent color') }}
        </div>
        <v-text-field
          v-model="draft.accent_color"
          placeholder="Accent color"
          variant="filled"
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <div class="fs-18 fw-600 c-black">
          {{ $t('Logo URL') }}
        </div>
      </v-col>
      <v-col
        class="mb-3"
        cols="12"
      >
        <v-text-field
          v-model="draft.logo_url"
          placeholder="Logo URL"
          variant="filled"
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <div class="fs-18 fw-600 c-black">
          {{ $t('PNG Logo URL') }}
        </div>
      </v-col>
      <v-col
        class="mb-3"
        cols="12"
      >
        <v-text-field
          v-model="draft.logo_png_url"
          placeholder="Mobile logo URL"
          variant="filled"
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <div class="fs-18 fw-600 c-black">
          {{ $t('Mobile logo URL') }}
        </div>
      </v-col>
      <v-col
        class="mb-3"
        cols="12"
      >
        <v-text-field
          v-model="draft.logo_small_url"
          placeholder="Mobile logo URL"
          variant="filled"
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <div class="fs-18 fw-600 c-black">
          {{ $t('Favicon URL') }}
        </div>
      </v-col>
      <v-col
        class="mb-3"
        cols="12"
      >
        <v-text-field
          v-model="draft.favicon_url"
          placeholder="Favicon URL"
          variant="filled"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-end">
        <v-btn
          @click="save()"
          :disabled="!primaryColorPassing || processing"
          color="primary"
          size="x-large"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import Api from '@/admin/services/bright_finder';
// import axios from 'axios';
import useEventBus from '@/shared/composables/useEventBus';

const emit = defineEmits(['save']);

const props = defineProps({
  brand: {
    type: Object,
    required: true,
  },
});

const draft = ref({});
const eventBus = useEventBus();
const processing = ref(false);
const primaryColorPassing = ref(true);

// watch(
//   () => draft.value.primary_color,
//   (newVal) => {
//     if (newVal?.length === 7) {
//       checkColorContrast(newVal);
//     } else {
//       primaryColorPassing.value = true;
//       // primaryColorPassing.value = false
//     }
//   },
// );

onMounted(() => {
  draft.value = Object.assign(props.brand);
});

// function checkColorContrast(color) {
//   const colorCheckUrl = `https://webaim.org/resources/contrastchecker/?bcolor=FFFFFF&fcolor=${color.replace('#', '')}&api`;
//   axios
//     .get(colorCheckUrl, { withCredentials: false })
//     .then((resp) => {
//       if (resp.data.AAA === 'pass') {
//         primaryColorPassing.value = true;
//       } else {
//         primaryColorPassing.value = true;
//         // primaryColorPassing.value = false
//       }
//     })
//     .catch(() => {
//       eventBus.chime('Unknown error');
//       primaryColorPassing.value = true;
//     });
// }

async function save() {
  processing.value = true;
  let resp = null;
  if (draft.value.id) {
    resp = await Api.organization.brand.update(draft.value.id, draft.value);
  } else {
    resp = await Api.organization.brand.create(draft.value);
  }
  processing.value = false;
  if (resp?.status === 200 || resp?.status === 201) {
    eventBus.chime('Saved');
    emit('save');
  }
}
</script>
