import DateParser from '@/shared/services/date_parser';

export default class SearchFromGroupParams {
  constructor(group, schema_id) {
    this.group = group;
    this.schema_id = schema_id;
    const children = group.children.filter((child) => child.dob);
    this.children = DateParser.sortYoungestDobFirst(children);
  }

  asJson() {
    return {
      children: this.children,
      destination: this.location('work'),
      formatted_origin:
        this.group.formatted_home_address ||
        this.group.formatted_work_address ||
        this.group.formatted_other_address,
      group_id: this.group.id,
      location_type: 'home',
      origin: this.location('home') || this.location('work') || this.location('other'),
      schema_id: this.schema_id,
      travel_mode: this.group.travel_mode || 'DRIVING',
    };
  }

  location(prefix) {
    if (this.group[`${prefix}_latitude`]) {
      return {
        address: this.group[`${prefix}_address`],
        city: this.group[`${prefix}_city`],
        latitude: this.group[`${prefix}_latitude`],
        longitude: this.group[`${prefix}_longitude`],
        state: this.group[`${prefix}_state`],
        zip: this.group[`${prefix}_zip`],
      };
    }
    return {};
  }
}
