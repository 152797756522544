<template>
  <v-container
    v-if="loaded"
    class="mxw-1200"
  >
    <TitleBar
      :back-route="{ name: 'ScreenerProgramIndex' }"
      :name="screenerProgram.name"
      back-name="Screener programs"
    />

    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-title>
        {{ $t('Overview') }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <LabeledTextfield
          v-model="draftScreenerProgram.name"
          @input="changed = true"
          message="Name"
        />

        <LabeledTextarea
          v-model="draftScreenerProgram.description"
          @input="changed = true"
          message="Description"
        />
        <!-- TODO: Add Markdown Regex so it appears only when Markdown is in field -->
        <div
          v-if="draftScreenerProgram.description"
          class="mt-4 ml-4"
        >
          <span class="mr-2 d-flex">Markdown preview - Description</span>
          <v-card
            class="fs-16 bg-surface-light mt-2 mb-2 pb-4 pl-4 pt-4"
            tile
          >
            <MarkdownContent :content="draftScreenerProgram.description" />
          </v-card>
        </div>

        <LabeledSimpleSelect
          v-model="draftScreenerProgram.eligibility_screener_schema_id"
          @input="changed = true"
          :items="eligibilityScreenerSchemas"
          :multiple="false"
          item-title="name"
          item-value="id"
          message="Eligibility screener schema"
        />

        <LabeledTextfield
          v-model="draftScreenerProgram.create_account_cta_button_text"
          @input="changed = true"
          message="Create account call-to-action button text"
        />

        <LabeledTextfield
          v-model="draftScreenerProgram.results_page_title"
          @input="changed = true"
          message="Results page title"
        />
        <LabeledTextfield
          v-model="draftScreenerProgram.results_page_subtitle"
          @input="changed = true"
          message="Results page subtitle"
        />

        <div class="mt-4 ml-4">
          <span class="mr-2 d-flex">Markdown preview - Results page subtitle</span>
          <v-card
            class="fs-16 bg-surface-light mt-2 mb-2 pb-4 pl-4 pt-4"
            tile
          >
            <MarkdownContent :content="draftScreenerProgram.results_page_subtitle" />
          </v-card>
        </div>

        <LabeledTextfield
          v-model="draftScreenerProgram.eligible_programs_title"
          @input="changed = true"
          message="Eligible programs title"
        />

        <LabeledTextfield
          v-model="draftScreenerProgram.eligible_programs_subtitle"
          @input="changed = true"
          message="Eligible programs subtitle"
        />

        <div
          v-if="draftScreenerProgram.eligible_programs_subtitle"
          class="mt-4 ml-4"
        >
          <span class="mr-2 d-flex">Markdown preview - Eligible programs subtitle</span>
          <v-card
            class="fs-16 bg-surface-light mt-2 mb-2 pb-4 pl-4 pt-4"
            tile
          >
            <MarkdownContent :content="draftScreenerProgram.eligible_programs_subtitle" />
          </v-card>
        </div>

        <LabeledTextfield
          v-model="draftScreenerProgram.ineligible_programs_title"
          @input="changed = true"
          message="Ineligible programs title"
        />

        <LabeledTextfield
          v-model="draftScreenerProgram.ineligible_programs_subtitle"
          @input="changed = true"
          message="Ineligible programs subtitle"
        />
        <div
          v-if="draftScreenerProgram.ineligible_programs_subtitle"
          class="mt-4 ml-4"
        >
          <span class="mr-2 d-flex">Markdown preview - Ineligible programs subtitle</span>
          <v-card
            class="fs-16 bg-surface-light mt-2 mb-2 pb-4 pl-4 pt-4"
            tile
          >
            <MarkdownContent :content="draftScreenerProgram.ineligible_programs_subtitle" />
          </v-card>
        </div>
      </v-card-text>
    </v-card>

    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-title>
        {{ $t('Data Mappings') }}
      </v-card-title>

      <v-divider />

      <LabeledSimpleSelect
        v-model="draftScreenerProgram.eligibility_program_ids"
        @change="changed = true"
        :items="eligibilityPrograms"
        :message="$t('Eligibility Programs')"
        item-title="name"
        item-value="id"
        multiple
      />
    </v-card>

    <DangerBar
      @destroy="destroy"
      :processing="processing"
    />

    <SaveBar
      @cancel="cancel"
      @save="save"
      :model-value="changed"
      :processing="processing"
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import DangerBar from '@/shared/components/DangerBar.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import TitleBar from '@/shared/components/TitleBar.vue';

import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();

const changed = ref(false);
const draftScreenerProgram = ref(null);
const eligibilityPrograms = ref([]);
const eligibilityScreenerSchemas = ref([]);
const loaded = ref(false);
const screenerProgram = ref(null);
const processing = ref(false);

function cancel() {
  setDraft();
  changed.value = false;
}

async function destroy() {
  if (!confirm('Are you sure you want to destroy this screener program?')) return;

  processing.value = true;

  const response = await Api.organization.screenerProgram.destroy(screenerProgram.value);

  processing.value = false;

  if (response?.status !== 200) return;

  eventBus.chime('Deleted');
  await router.push({ name: 'ScreenerProgramIndex' });
}

async function load() {
  processing.value = true;

  const response = await Api.organization.screenerProgram.get(route.params.id);
  screenerProgram.value = response?.data;
  setDraft();

  await Promise.all([loadEligibilityPrograms(), loadEligibilityScreenerSchemas()]);

  processing.value = false;
  loaded.value = true;
}

async function loadEligibilityPrograms() {
  const response = await Api.organization.eligibilityProgram.index();
  if (response?.data) eligibilityPrograms.value = response?.data;
}

async function loadEligibilityScreenerSchemas() {
  const response = await Api.organization.schema.index({ data_type: 'EligibilityScreener' });
  if (response?.data) eligibilityScreenerSchemas.value = response?.data;
}

async function save() {
  try {
    processing.value = true;

    await Api.organization.screenerProgram.update(draftScreenerProgram.value);
    await load();
    eventBus.chime('Saved');
  } finally {
    changed.value = false;
    processing.value = false;
  }
}

function setDraft() {
  draftScreenerProgram.value = { ...screenerProgram.value };
}

onMounted(load);
</script>
