<template>
  <div>
    <v-card
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <LabeledControl message="Participants">
            <div
              @click="addParticipants"
              class="bg-extra-light-gray px-2 py-2 pointer mnh-48"
            >
              <v-chip
                v-for="(participant, index) in participants"
                @click:close="destroyParticipant(participant, index)"
                :key="index + participant.id"
                class="me-1"
                closable
              >
                {{ participant.participating_member.name }}
              </v-chip>
              <template v-if="participants.length == 0">
                <v-chip style="visibility: hidden"> No participants </v-chip>
              </template>
            </div>
          </LabeledControl>
        </v-row>

        <ResourceDialog
          @save="createParticipant"
          ref="dialog"
          :processing="processing"
          title="Add participant"
        >
          <template #form>
            <v-row>
              <LabeledSimpleSelect
                v-model="newParticipantId"
                :items="members"
                item-title="name"
                item-value="id"
              />
            </v-row>
          </template>
        </ResourceDialog>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import ParticipantApi from '@/specialist/services/api/participant';
import LabeledControl from '@/shared/components/form/LabeledControl.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    LabeledControl,
    LabeledSimpleSelect,
    ResourceDialog,
  },
  props: {
    businessId: {
      type: String,
      default: null,
    },
    participatableId: {
      type: String,
      default: null,
    },
    participatableType: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      members: [],
      newParticipantId: null,
      participants: [],
      processing: false,
    };
  },

  async mounted() {
    await Promise.all([this.loadParticipants(), this.loadPotentialParticipants()]);
  },

  methods: {
    addParticipants() {
      this.$refs.dialog.open();
    },

    async createParticipant() {
      this.processing = true;
      const { data } = await ParticipantApi.create({
        participatable_id: this.participatableId,
        participatable_type: this.participatableType,
        participating_member_id: this.newParticipantId,
      });
      this.participants.push(data);
      this.processing = false;
      this.$refs.dialog.close();
    },

    async destroyParticipant(participant, index) {
      // eslint-disable-next-line no-alert
      if (confirm('Are you sure you want to delete this participant?')) {
        this.processing = true;
        await ParticipantApi.destroy(participant.id);
        this.participants.splice(index, 1);
        this.processing = false;
      }
    },

    async loadParticipants() {
      const { data } = await ParticipantApi.index({
        participatable_id: this.participatableId,
        participatable_type: this.participatableType,
      });
      this.participants = data;
    },

    async loadPotentialParticipants() {
      const { data } = await Api.organization.member.index({ business_id: this.businessId });
      this.members = data;
    },
  },
};
</script>
