<template>
  <v-container
    v-if="messageTemplate && organization && selectedLanguage"
    class="pa-6 mxw-1400"
  >
    <TitleBar
      :back-route="{ name: 'MessageTemplateIndex' }"
      :name="messageTemplate.name"
      back-name="Message templates"
    />

    <v-card
      class="mb-6"
      border
      tile
    >
      <v-card-title>
        {{ $t('Settings') }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <LabeledTextfield
            v-model="messageTemplate.name"
            @input="changed = true"
            message="Name of the template"
          />
        </v-row>

        <v-row>
          <LabeledTextarea
            v-model="messageTemplate.test_data"
            @input="changed = true"
            message="Test data"
          />
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          @click="save"
          :disabled="!changed"
          color="primary"
          size="large"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      class="mb-6"
      border
      tile
    >
      <v-card-title>
        {{ $t('Content') }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="selectedLanguage"
              :items="sortedLanguages()"
              variant="filled"
              hide-details
            />
          </v-col>
        </v-row>

        <v-row>
          <LabeledTextfield
            v-model="
              messageTemplate.subjects.find((subject) => subject.lang == selectedLanguage).content
            "
            @input="changed = true"
            message="Subject"
          />
        </v-row>

        <v-row>
          <LabeledTextarea
            v-model="messageTemplate.text.find((text) => text.lang == selectedLanguage).content"
            @blur="changed = true"
            message="Text content"
          />
        </v-row>

        <v-row>
          <v-col class="labeled-input">
            {{ $t('HTML Content') }}
          </v-col>

          <v-col class="labeled-input">
            {{ $t('HTML Preview') }}
          </v-col>
        </v-row>

        <v-row
          style="min-height: 800px"
          dense
        >
          <v-col cols="6">
            <prism-editor
              v-model="messageTemplate.html.find((html) => html.lang == selectedLanguage).content"
              :highlight="highlighter"
              class="my-editor"
            />
          </v-col>

          <v-col
            v-if="messageTemplate.html.find((html) => html.lang == selectedLanguage).content"
            cols="6"
          >
            <iframe
              id="preview_iframe"
              :src="htmlPreviewUrl"
              class="bc-very-light-gray b-1 w-100pc h-100pc"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          @click="save"
          color="primary"
          size="large"
        >
          {{ $t('Save changes') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <DangerBar
      @destroy="destroy"
      :processing="processing"
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere
// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles
import { useRoute, useRouter } from 'vue-router';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();

const changed = ref(false);
const messageTemplate = ref(null);
const organization = ref(null);
const processing = ref(false);
const selectedLanguage = ref(null);

const htmlPreviewUrl = computed(() => {
  return Api.organization.messageTemplate.htmlPreviewUrl(
    route.params.messageTemplateId,
    selectedLanguage.value,
  );
});

onMounted(async () => {
  await load();
});

function destroy() {
  if (confirm('Are you sure you want to delete this template?')) {
    Api.organization.messageTemplate.destroy(route.params.messageTemplateId, () => {
      router.replace({ name: 'MessageTemplateIndex' });
    });
  }
}

function highlighter(code) {
  return highlight(code, languages.html); // languages.<insert language> to return html with markup
}

async function load() {
  await loadOrganization();
  await loadMessageTemplate();
}

async function loadMessageTemplate() {
  Api.organization.messageTemplate.get(route.params.messageTemplateId, (resp) => {
    messageTemplate.value = resp.data;
  });
}

async function loadOrganization() {
  const response = await Api.organization.get();

  organization.value = response.data;
  selectedLanguage.value = organization.value.languages[0].value;
}

function save() {
  Api.organization.messageTemplate.update(
    messageTemplate.value.id,
    messageTemplate.value,
    () => {
      eventBus.chime('Saved.');
      document.getElementById('preview_iframe').src = document.getElementById('preview_iframe').src;
    },
    (err) => {
      if (err?.response) {
        eventBus.chime(err.response.data.errors[0]);
      }
    },
  );
}

function sortedLanguages() {
  const compareFunction = (a, b) => a.text.localeCompare(b.text);

  return organization.value.languages.sort(compareFunction).map((lang) => {
    return {
      text: lang.text,
      value: lang.value,
    };
  });
}
</script>

<style>
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: rgba(0, 0, 0, 0.04);
  color: #38beb5;

  /* you must provide font-family font-size line-height. Example: */
  font-family:
    Fira code,
    Fira Mono,
    Consolas,
    Menlo,
    Courier,
    monospace;
  font-size: 12px;
  line-height: 1.5;
  padding: 10px;
}

.token.punctuation {
  color: #38beb5;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
