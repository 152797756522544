<template>
  <v-card
    class="fill-height"
    elevation="2"
    tile
  >
    <v-card-title class="d-flex border-b pb-0">
      <v-tabs
        v-model="tab"
        align-tabs="center"
        class="mt-1 flex-grow-1"
      >
        <v-tooltip location="bottom">
          <template #activator="{ props }">
            <v-tab
              v-bind="props"
              class="pa-1"
              value="messages"
            >
              <v-icon
                aria-label="Messages"
                class="material-icons-outlined"
                icon="chat"
              />
            </v-tab>
          </template>
          <span>Messages</span>
        </v-tooltip>

        <v-tooltip location="bottom">
          <template #activator="{ props }">
            <v-tab
              v-bind="props"
              class="pa-1"
              value="eventAlerts"
            >
              <v-icon
                aria-label="Event alerts"
                icon="notifications_active"
              />
            </v-tab>
          </template>
          <span>Event alerts</span>
        </v-tooltip>
        <v-tooltip location="bottom">
          <template #activator="{ props }">
            <v-tab
              v-bind="props"
              class="pa-1"
              value="announcements"
            >
              <v-icon
                aria-label="Announcements"
                icon="campaign"
              />
            </v-tab>
          </template>
          <span>Announcements</span>
        </v-tooltip>
      </v-tabs>
      <v-btn
        @click="$emit('close')"
        aria-label="Close Communications"
        class="focus-visible ml-auto"
        variant="text"
        icon
      >
        <v-icon icon="close" />
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="messages">
          <slot name="messages"></slot>
        </v-tabs-window-item>

        <v-tabs-window-item value="eventAlerts">
          <div class="d-flex flex-column ga-3 pt-4">
            <span class="fw-600">Event alerts</span>
            <SubsidyCommunication
              v-for="communication in eventAlertContextData"
              @preview-email="openPreview('email', $event)"
              @preview-sms="openPreview('sms', $event)"
              :key="communication.context.context_id"
              :communication="communication"
            />
            <NullState
              v-if="
                groupCommunicationsQuery.status !== 'pending' && eventAlertContextData.length === 0
              "
              description="This application hasn't received any Announcements."
              title="You have no Event alerts"
            />
          </div>
        </v-tabs-window-item>

        <v-tabs-window-item value="announcements">
          <div class="d-flex flex-column ga-3 pt-4">
            <span class="fw-600">Announcements</span>
            <v-card
              border
              tile
            >
              <v-card-title>
                <v-icon
                  class="me-3"
                  color="primary"
                  icon="info"
                />

                <span class="c-light-black fs-16 fw-500 wrap-text hyphens-none">
                  {{
                    $t(
                      'This section contains all announcements the family has received. Some may not be directly related to this application and could be linked to another application submitted by the family.',
                    )
                  }}
                </span>
              </v-card-title>
            </v-card>

            <SubsidyCommunication
              v-for="communication in announcementContextData"
              @preview-email="openPreview('email', $event)"
              @preview-sms="openPreview('sms', $event)"
              :key="communication.context.context_id"
              :communication="communication"
            />

            <NullState
              v-if="
                groupCommunicationsQuery.status !== 'pending' &&
                announcementContextData.length === 0
              "
              description="This application hasn't received any Announcements."
              title="You have no Announcements"
            />
          </div>
        </v-tabs-window-item>

        <v-tabs-window-item value="preview">
          <v-btn
            @click="onPreviewBackClick"
            class="ma-2"
            prepend-icon="arrow_back"
            variant="text"
          >
            {{ $t('Back') }}
          </v-btn>

          <div
            v-if="sentEmailQuery.status !== 'pending' && previewType === 'email'"
            class="pa-sm-2 pa-md-12"
          >
            <div class="font-weight-bold pb-6">
              <span>{{ sentEmailQuery.data.subject }}</span>
            </div>

            <div v-html="sentEmailQuery.data.body" />
          </div>

          <div
            v-else-if="sentEmailQuery.status !== 'pending' && previewType === 'sms'"
            class="pa-sm-2 pa-md-12"
          >
            <div>{{ sentEmailQuery.data.text }}</div>
          </div>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-card>
</template>

<script setup>
import NullState from '@/shared/components/NullState.vue';
import useSegment from '@/shared/composables/analytics/useSegment';
import SubsidyCommunication from '@/specialist/components/subsidy/SubsidyCommunication.vue';
import useGroupCommunications from '@/specialist/composables/group-communications/useGroupCommunications';
import useSentEmail from '@/specialist/composables/sent-emails/useSentEmail';
import { watch } from 'vue';

const props = defineProps({
  groupId: {
    type: String,
    required: true,
  },
});

const segment = useSegment();
const groupCommunicationsQuery = useGroupCommunications(props.groupId);

const sentEmailId = ref(null);
const tab = ref('messages');
const previewType = ref(null);
const onPreviewBackClick = ref(() => {});

const sentEmailQuery = useSentEmail(sentEmailId);

watch(
  tab,
  (newValue, oldValue) => {
    segment.track('Subsidy Communications Tab Viewed', {
      groupId: props.groupId,
      previousTab: oldValue,
      tab: newValue,
    });
  },
  { immediate: true },
);

function openPreview(type, communicationId) {
  // begin fetching the new email
  sentEmailId.value = communicationId; // Note: this will change when we add support for SentSMS

  // preserve the prior location so someone can click back
  const previousTabValue = tab.value;
  onPreviewBackClick.value = () => (tab.value = previousTabValue);

  // open the preview tab
  previewType.value = type;
  tab.value = 'preview';
}

const eventAlertContextData = computed(() => {
  return groupCommunicationsQuery.value.data.filter(
    (communication) => communication.context.context_type === 'EventAlert',
  );
});

const announcementContextData = computed(() => {
  return groupCommunicationsQuery.value.data.filter(
    (communication) => communication.context.context_type === 'Announcement',
  );
});
</script>
