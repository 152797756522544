<template>
  <v-card class="pa-6">
    <v-row dense>
      <v-col cols="12">
        <div class="fs-28 fw-600 c-black mb-3">Danger</div>
      </v-col>
      <v-col
        class="mb-3"
        cols="12"
      >
        <div class="fs-18 fw-600 c-black">Be careful! This cannot be undone.</div>
      </v-col>
      <v-col cols="12">
        <v-btn
          @click="destroy()"
          color="red"
          rounded
        >
          Delete page
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Api from '@/admin/services/bright_finder';

export default {
  compatConfig: { MODE: 2 },

  props: {
    // eslint-disable-next-line vue/prop-name-casing
    site_template: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    page_template: {
      type: Object,
      required: true,
    },
  },

  methods: {
    destroy() {
      const vm = this;
      vm.processing = true;
      Api.organization.siteTemplate.pageTemplate.destroy(
        vm.site_template.id,
        vm.page_template.id,
        () => {
          vm.$router.push({
            name: 'SiteTemplateShow',
            params: { siteTemplateId: vm.site_template.id },
          });
        },
        (err) => {
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },
  },
};
</script>
