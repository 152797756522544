<template>
  <v-card
    id="forms"
    class="mb-4"
    data-cy="forms-card"
    border
    flat
    tile
  >
    <v-card-title class="tab-title">
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col
          class="d-flex align-center"
          cols="12"
          sm="6"
        >
          {{ $t('Forms') }}
        </v-col>
        <v-col
          class="d-flex align-center justify-end"
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="formQuery"
            @update:model-value="debouncedLoadForms()"
            class="mxw-250 me-2"
            density="compact"
            prepend-inner-icon="search"
            variant="solo-filled"
            flat
            hide-details
            rounded
          />
          <v-btn
            @click="draft"
            color="primary"
            icon="add"
            variant="text"
          />
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider class="mb-4" />

    <v-card-text>
      <FormCard
        v-for="form in forms"
        @destroy="destroy(form)"
        @status="setStatus(form, $event)"
        :key="form.id"
        :form="form"
        :programs="programs"
        :provider="provider"
        class="mb-3"
      />

      <v-row v-if="forms.length == 0">
        <v-col
          class="fs-16"
          data-cy="forms-empty"
        >
          {{ $t('No forms attached.') }}
        </v-col>
      </v-row>

      <v-pagination
        v-show="pages > 1"
        v-model="page"
        @update:model-value="load"
        :length="pages"
        :total-visible="8"
        class="mt-4"
        rounded
      />
    </v-card-text>

    <v-dialog
      v-model="newFormDialogVisible"
      max-width="600"
    >
      <v-card>
        <v-card-title>{{ $t('New form') }}</v-card-title>
        <v-card-text>
          <v-select
            v-model="newSchemaId"
            :items="
              Object.values($store.state.schemas).filter((schema) => schema.data_type == 'Form')
            "
            item-title="name"
            item-value="id"
            variant="filled"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="newFormDialogVisible = false"
            variant="text"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="createForm"
            :disabled="!newSchemaId"
            :loading="processing"
            color="primary"
          >
            {{ $t('Create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/specialist/services/bright_finder';
import FormCard from '@/specialist/components/forms/FormCard.vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const props = defineProps({
  provider: {
    type: Object,
    default: null,
  },
});

const forms = ref([]);
const formQuery = ref(null);
const newFormDialogVisible = ref(false);
const newSchemaId = ref(null);
const page = ref(1);
const pages = ref(null);
const processing = ref(false);
const programs = ref([]);

watch(
  () => route.params.providerId,
  () => load(),
  { immediate: true },
);

const debouncedLoadForms = _.debounce(() => {
  void loadForms();
}, 500);

async function createForm() {
  processing.value = true;
  const params = {
    provider_id: props.provider.id,
    schema_id: newSchemaId.value,
  };
  const { data } = await Api.organization.form.create(params);

  processing.value = false;

  // const destination = store.state.profile.org_forms_review ? 'FormReview' : 'FormShow';
  router.push({ name: 'FormShow', params: { formId: data.id }, query: { back: 'provider' } });
}

async function destroy(form) {
  if (!confirm('Are you sure you want to destroy this form?')) return;

  await Api.organization.form.destroy(form.id);
  load();
}

function draft() {
  newFormDialogVisible.value = true;
}

function load() {
  void loadForms();
  void loadPrograms();
}

async function loadForms() {
  const params = {
    page: page.value,
    provider_id: props.provider.id,
  };
  if (formQuery.value) params.query = formQuery.value;
  const resp = await Api.organization.form.index(params);
  pages.value = parseInt(resp.headers['x-page-count'] || 0, 10);
  forms.value = resp.data;
}

async function loadPrograms() {
  const params = { provider_id: props.provider.id };
  const { data } = await Api.public_api.organization.program.index(params);
  programs.value = data;
}

function setStatus(form, newVal) {
  let status;
  if (newVal.title === 'Approve') status = 'Approved';
  else if (newVal.title === 'Deny') status = 'Denied';

  void updateForm(form.id, { status });
}

async function updateForm(formId, newVal) {
  await Api.organization.form.update(formId, newVal);
  void load();
}
</script>
