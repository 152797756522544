<template>
  <v-container
    v-if="webpages"
    class="mxw-1200 pa-6"
  >
    <v-row>
      <v-col>
        <div class="fw-600 bc-h5">Pages</div>
      </v-col>
      <v-col
        v-if="webpages.length > 0"
        class="ta-right"
      >
        <v-btn
          @click="$refs.newWebpageDialog.open({})"
          color="primary"
        >
          Create page
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="loaded && webpages.length == 0"
      class="d-flex justify-center"
      style="margin-top: 15%"
    >
      <v-col class="ta-center">
        <div class="fw-600 fs-20 mb-4">You do not have any pages.</div>
        <v-btn
          @click="$refs.newWebpageDialog.open({})"
          color="primary"
          size="x-large"
          rounded
        >
          Create page
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="webpage in webpages"
        :key="webpage.id"
        :data-testid="webpage.name"
        cols="12"
      >
        <v-card
          @click="$router.push({ name: 'WebpageShow', params: { webpageId: webpage.id } })"
          :ripple="false"
          border
          flat
          tile
        >
          <v-card-title class="fs-18 fw-600 constrained d-flex">
            {{ webpage.name }}
            <v-spacer />
            <ActionMenu
              @click:action:duplicate="duplicate(webpage.id)"
              :items="[{ event: 'duplicate', title: 'Duplicate page' }]"
              button-class="ms-3"
              button-icon="more_vert"
              label="Actions"
              x-small
            />
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <ResourceDialog
      @save="create"
      ref="newWebpageDialog"
      :fields="[{ text: 'Name', value: 'name' }]"
      :processing="processing"
      title="New page"
    />
  </v-container>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Api from '@/specialist/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
const router = useRouter();
const store = useStore();

const processing = ref(false);
const webpages = ref([]);
const loaded = shallowRef(false);

async function create(newVal) {
  processing.value = true;
  const resp = await Api.organization.webpage.create(newVal);
  processing.value = false;
  if (resp.status !== 201) return;

  router.push({ name: 'WebpageShow', params: { webpageId: resp.data.id } });
}

async function load() {
  const { data } = await Api.organization.webpage.index();
  webpages.value.push(...data);
  loaded.value = true;
}

async function duplicate(webpageId) {
  const resp = await Api.organization.webpage.duplicate(webpageId);
  router.push({ name: 'WebpageShow', params: { webpageId: resp.data.id } });
}

onMounted(async () => {
  store.commit('setNavigation', null);
  await load();
});
</script>
